import React from 'react';
import css from './ServicePage.css';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';

import { Avatar, NamedLink, SecondaryButton } from '../../components';

const SectionServiceAuthor = props => {
  const {
    profileTitle,
    profileId,
    author,
    authorName,
    isTask,
    isService,
    isSupportPlan,
    // isAdmin,
    // isPremiumUser,
    // isOwnListing,
  } = props;

  if (!isTask && !isService) {
    return null;
  }

  if (!profileId && !isSupportPlan) {
    return (
      <div className={css.sectionServiceAuthor}>
        <p className={css.smallPrintHeading}>Provided by</p>
        <FormattedMessage id="SectionServiceAuthor.loadingProfile" />
      </div>
    );
  }

  const expertWrapper = data => {
    return (
      <NamedLink
        name="ExpertPage"
        params={{ id: profileId, slug: createSlug(profileTitle), category: 'experts' }}
      >
        {data}
      </NamedLink>
    );
  };
  console.log('author', author);
  return (
    <div className={css.sectionServiceAuthor}>
      <div className={css.serviceAvatarWrapper}>
        {!isSupportPlan ? (
          <>
            {expertWrapper(
              <div>
                <div className={css.serviceAvatarAspectWrapper}>
                  <Avatar
                    user={author}
                    className={css.serviceAvatarDesktop}
                    initialsClassName={css.serviceInitialsDesktop}
                    disableProfileLink
                  />

                  <div className={css.viewExpertButtonWrapper}>
                    <SecondaryButton className={css.viewExpertButton}>
                      <FormattedMessage
                        id="SectionServiceAuthor.viewExpert"
                        values={{ br: <br /> }}
                      />
                    </SecondaryButton>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div className={css.serviceExpertName}>{authorName}</div>
            </div>
            {/* <div>
              <div className={css.serviceExpertName}>{author.attributes.profile.bio}</div>
            </div> */}
          </>
        ) : (
          <div className={css.supportPlanProvider}>
            <div className={css.serviceAvatarAspectWrapper}>
              <Avatar
                user={author}
                className={css.serviceAvatarDesktop}
                initialsClassName={css.serviceInitialsDesktop}
                disableProfileLink
              />
            </div>
            <div className={css.serviceExpertTitle}>SoftwareSupp</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionServiceAuthor;
