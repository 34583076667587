/* eslint-disable jsx-a11y/no-static-element-interactions */
// BASED ON ListingPage
import React, { Component } from 'react';
import { ArrowLeft } from 'react-feather';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sendAdminSummaryOffer, sendNewMessageMail } from '../../util/triggerMail';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { formatMoney } from '../../util/currency';
import { LISTING_STATE_CLOSED, LISTING_CATEGORY_JOB } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createResourceLocatorString } from '../../util/routes';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  Modal,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  InlineTextButton,
  PrimaryButton,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { sendOffer, setInitialValues } from './NewOfferPage.duck';
import css from './NewOfferPage.css';
import {
  incrementOffersCount,
  changeProjectState,
  includeOfferMetadata,
  sendOfferEmails,
  sendNotification,
  inboxSendMessage,
} from '../../util/api';
import NewOfferForm from '../../forms/NewOfferForm/NewOfferForm';

const { UUID, Money } = sdkTypes;

export class NewOfferPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commission: 0.5,
      formEnabled: true,
      shouldRedirect: false,
      isModalOpen: false,
      currentFormValues: {},
      formMinimumPriceNotReached: false,
      isAdminOffer: false,
      jobScope: [],
      submitting: false,
      isJobPremium:false
    };
    this.onSubmitOffer = this.onSubmitOffer.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onSubmitNewOfferForm = this.onSubmitNewOfferForm.bind(this);
    this.parseScope = this.parseScope.bind(this);
    this.setIsModalOpen = this.setIsModalOpen.bind(this);
    this.setCurrentFormValues = this.setCurrentFormValues.bind(this);
    this.proceedWithOffer = this.proceedWithOffer.bind(this);
    this.parseTotalPrice = this.parseTotalPrice.bind(this);
    this.parsePriceWithCommission = this.parsePriceWithCommission.bind(this);
  }

  componentDidMount() {

    const isAuthenticated = this.props?.isAuthenticated;
    const isJobPremium = this.props?.location?.state?.jobListing?.attributes?.publicData?.isPremium;
    const jobListing = this.props?.location?.state?.jobListing;
    const listingCategory = jobListing?.attributes?.publicData?.category;
    const isJobListing = listingCategory === LISTING_CATEGORY_JOB;
    const jobListingBudget = jobListing?.attributes?.price;
    if (!jobListing || !isJobListing || !isAuthenticated) {
      this.setState({ shouldRedirect: true });
      return;
    }
    this.setState({isJobPremium:this.props?.location?.state?.jobListing?.attributes?.publicData?.isPremium})
    const isAdmin = this.props?.location?.state?.isAdmin;
    this.setState({
      isAdminOffer: isAdmin,
      jobListingBudget: jobListingBudget,
    });
    const isCurrentlyClosed = jobListing?.attributes?.state === LISTING_STATE_CLOSED;
    const commission = jobListing?.attributes?.publicData?.commission
      ? jobListing.attributes.publicData.commission
      : 0.5;
    if (isCurrentlyClosed) {
      this.setState({
        formEnabled: false,
      });
      return;
    }
    this.setState({
      commission: commission,
      jobScope: jobListing.attributes.publicData.scope,
    });
  }

  setIsModalOpen(value) {
    this.setState({
      isModalOpen: value,
    });
  }

  setCurrentFormValues(values) {
    this.setState({
      currentFormValues: values,
    });
  }

  goBack() {
    const { history } = this.props;
    if (history.length > 1) {
      history.goBack();
    }
  }

  parseTotalPrice(values) {
    let scopeEntries = Object.entries(values).filter(entryArray => {
      return entryArray[0].includes('scope');
    });
    let scopePrices = scopeEntries.filter(entry => {
      return entry[0].includes('PriceInput') && entry[1] !== null;
    });
    let scopeTotal = scopePrices.reduce((acc, price) => {
      return acc + price[1].amount;
    }, 0);
    return scopeTotal;
  }

  parseScope(values, commission) {
    // New Scope Parsing, output: {scope, price, milestones}
    let scopeEntries = Object.entries(values).filter(entryArray => {
      return entryArray[0].includes('scope');
    });
    let scopeTaskIds = scopeEntries
      .filter(entry => {
        return entry[0].includes('scopeTaskNameInput') && entry[1] !== '';
      })
      .map(entry => {
        return parseInt(entry[0].replace('scopeTaskNameInput', ''));
      })
      .sort((a, b) => {
        return a - b; // integers sort
      });
    let scope = [];
    let totalPrice = 0;
    let howManyTasks = scopeTaskIds.length;
    for (let i = 0; i < howManyTasks; i++) {
      let scopeTaskName = scopeEntries.find(entry => {
        return entry[0].includes('scopeTaskNameInput' + scopeTaskIds[i]);
      })[1];
      let scopeTaskTime = scopeEntries.find(entry => {
        return entry[0].includes('scopeTaskTimeInput' + scopeTaskIds[i]);
      });
      scopeTaskTime = !!scopeTaskTime ? parseInt(scopeTaskTime[1]) : null;
      let scopeTaskPrice = scopeEntries.find(entry => {
        return entry[0].includes('scopeTaskPriceInput' + scopeTaskIds[i]);
      });
      scopeTaskPrice = !!scopeTaskPrice ? scopeTaskPrice[1]?.amount / 100 : null;
      if (scopeTaskPrice) {
        totalPrice += scopeTaskPrice * 100;
      }
      let scopeSubtaskIds = scopeEntries
        .filter(entry => {
          return (
            entry[0].includes('scopeTask' + scopeTaskIds[i] + 'SubtaskNameInput') && entry[1] !== ''
          );
        })
        .map(entry => {
          return parseInt(entry[0].replace('scopeTask' + scopeTaskIds[i] + 'SubtaskNameInput', ''));
        })
        .sort((a, b) => {
          return a - b; // integers sort
        });
      let howManySubtasks = scopeSubtaskIds.length;
      let scopeSubtasks = [];
      for (let j = 0; j < howManySubtasks; j++) {
        let scopeSubtaskName = scopeEntries.find(entry => {
          return entry[0].includes(
            'scopeTask' + scopeTaskIds[i] + 'SubtaskNameInput' + scopeSubtaskIds[j]
          );
        })[1];
        let scopeSubtaskTime = scopeEntries.find(entry => {
          return entry[0].includes(
            'scopeTask' + scopeTaskIds[i] + 'SubtaskTimeInput' + scopeSubtaskIds[j]
          );
        });
        scopeSubtaskTime =
          !!scopeSubtaskTime && scopeSubtaskTime.length > 1 ? parseInt(scopeSubtaskTime[1]) : null;
        let scopeSubtaskPrice = scopeEntries.find(entry => {
          return entry[0].includes(
            'scopeTask' + scopeTaskIds[i] + 'SubtaskPriceInput' + scopeSubtaskIds[j]
          );
        });
        scopeSubtaskPrice =
          !!scopeSubtaskPrice && scopeSubtaskPrice.length > 1
            ? scopeSubtaskPrice[1]?.amount / 100
            : null;
        let scopeSubtaskItem = {};
        if (!!scopeSubtaskName) {
          scopeSubtaskItem['name'] = scopeSubtaskName;
        }
        if (!!scopeSubtaskTime) {
          scopeSubtaskItem['time'] = scopeSubtaskTime;
        }
        if (!!scopeSubtaskPrice) {
          scopeSubtaskItem['price'] = scopeSubtaskPrice;
        }
        scopeSubtasks.push(scopeSubtaskItem);
      }
      let scopeTask = {
        name: scopeTaskName,
        time: scopeTaskTime,
        price: scopeTaskPrice,
        subtasks: scopeSubtasks,
      };
      scope.push(scopeTask);
    }
    totalPrice = Math.trunc(totalPrice / (1 - commission) / 100) * 100;
    let price = new Money(totalPrice, config.currency);
    return { scope, price };
  }

  parsePriceWithCommission(price, commission) {
    let priceAmount = price.amount;
    let finalPrice = Math.trunc(priceAmount / (1 - commission) / 100) * 100;
    return new Money(finalPrice, config.currency);
  }

  parseAdminScope(values) {
    // Admin Scope Parsing
    let scopeEntries = Object.entries(values).filter(entryArray => {
      return entryArray[0].includes('scope');
    });
    let scopeNames = scopeEntries
      .filter(entry => {
        return entry[0].includes('scopeNameInput') && entry[1] !== '';
      })
      .sort((a, b) => {
        return a[0].localeCompare(b[0]);
      });
    let howManyScopeItems = scopeNames.length;
    let scope = [];
    let scopeItem = {};
    for (let i = 0; i < howManyScopeItems; i++) {
      if (scopeNames[i] && scopeNames[i] !== '') {
        scopeItem['task'] = scopeNames[i][1];
        scope.push(scopeItem);
        scopeItem = {};
      }
    }
    return scope;
  }

  onSubmitNewOfferForm(values, form) {
    
    
    values['estimatedPriceSummary'] = this.state.jobListingBudget;
    
    if (
      !this.state.isAdminOffer &&
      values['estimatedPriceSummary'].amount < config.expertOfferMinimumPriceSubUnits
    ) {
      this.setState({
        formMinimumPriceNotReached: true,
      });
      return;
    } else {
      this.setState({
        formMinimumPriceNotReached: false,
      });
    }
    this.setIsModalOpen(true);
    this.setCurrentFormValues(values);
  }

  proceedWithOffer() {
    if (this.state.submitting) {
      this.setIsModalOpen(false);
      return;
    }
    this.setState({ submitting: true });
    // proceed with offer
    let values = this.state.currentFormValues;

    let jobListing = this.props.location.state.jobListing;
    if (this.state.isAdminOffer) {
      let { scope } = this.parseScope(values, this.state.commission);
      let offerValues = {
        offer: values.offer,
        scope: scope,
        estimatedTimeMin: parseInt(values.estimatedTimeMin),
        estimatedTimeMax: parseInt(values.estimatedTimeMax),
        estimatedPriceMin: values.estimatedPriceMin,
        estimatedPriceMax: values.estimatedPriceMax,
        offerValidity: values.offerValidity,
        offerPaymentDetails: values.offerPaymentDetails,
        offerContractInfo: values.offerContractInfo,
        offerContact: values.offerContact,
        offerContactMail: values.offerContactMail,
        offerContactPhone: values.offerContactPhone,
        offerAttachment: values.uploadedFileLink,
        offerSelectedFiles: values.selectedFiles
      };
      this.onSubmitOffer(offerValues, jobListing);
    } else {
      let { scope } = this.parseScope(values, this.state.commission);
      let price = this.parsePriceWithCommission(
        values.estimatedPriceSummary,
        this.state.commission
      );
      price = values.estimatedPriceSummary
      let offerValues = {
        offer: values.offer,
        scope: scope,
        price: price,
        estimatedTime: parseInt(values.estimatedTime),
        offerAttachment: values.uploadedFileLink,
        offerSelectedFiles:values.selectedFiles
      };

      this.onSubmitOffer(offerValues, jobListing);
    }
  }

  onSubmitOffer(values, listing) {
    const {
      intl,
      history,
      params,
      onSendOffer,
      currentUser,
      currentUserHasExpertListing,
      currentUserExpertListing,
      productSoftware,
      productSkills,
    } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    // const currentUserHasStripeConnected =
    // currentUser && currentUser.attributes?.profile.publicData?.hasStripeConnected;
    const adminId = process.env.REACT_APP_ADMIN_USER_ID;

    let {
      offer,
      scope,
      price,
      estimatedTime,
      estimatedTimeMin,
      estimatedTimeMax,
      estimatedPriceMin,
      estimatedPriceMax,
      offerValidity,
      offerPaymentDetails,
      offerContact,
      offerContractInfo,
      offerContactMail,
      offerContactPhone,
      offerAttachment,
      offerSelectedFiles
    } = values;


    let data;
    if (this.state.isAdminOffer) {
      data = {
        description: offer,
        price: estimatedPriceMin?.amount / 100,
        scope: scope,
        estimatedTimeMin: estimatedTimeMin,
        estimatedTimeMax: estimatedTimeMax,
        estimatedPriceMin: estimatedPriceMin?.amount / 100,
        estimatedPriceMax: estimatedPriceMax?.amount / 100,
        offerValidity: offerValidity,
        offerPaymentDetails: offerPaymentDetails,
        offerContractInfo: offerContractInfo,
        offerContact: offerContact,
        offerContactMail: offerContactMail,
        offerContactPhone: offerContactPhone,
        offerAttachment: offerAttachment,
        offerSelectedFiles: offerSelectedFiles,
        isAdmin: true,
      };
      price = new Money(estimatedPriceMin.amount, config.currency);
    } else {
      data = {
        description: offer,
        price: price.amount / 100,
        scope: scope,
        estimatedTime: estimatedTime,
        offerAttachment: offerAttachment,
        offerSelectedFiles: offerSelectedFiles
      };
    }

    if (
      (currentUserHasExpertListing && currentUserExpertListing.state !== LISTING_STATE_CLOSED) ||
      this.state.isAdminOffer
    ) {
      if (!this.state.isAdminOffer) {
        const body = {
          toUserId: adminId,
          from: currentUser.id.uuid,
          content: 'Offer about: ' + listing.attributes.title + ': ' + offer,
          groupId: null,
        };
        const userInboxToken = currentUser.attributes.profile.privateData.inboxToken;
        inboxSendMessage(userInboxToken, body);
        const conversationLink = `/conversation/${currentUser.id.uuid}`;
        sendNewMessageMail(
          body.toUserId,
          currentUser.attributes.profile.firstName,
          'Offer about: ' + listing.attributes.title + ': ' + offer,
          conversationLink
        );
      }
      onSendOffer(listingId, price)
        .then(async txId => {
          // include scope to transaction metadata through Integration API
          const offerScopeData = {
            softwareConfig: productSoftware,
            skillsConfig: productSkills,
            checkOffersCountId: listingId,
            txId: txId.uuid,
            data,
          };
          includeOfferMetadata(offerScopeData)
            .then(() => {
              // Increment number of offers for job listing
              const incrementOffersData = {
                userId: currentUser.id.uuid,
                offerId: txId.uuid,
                listingId: listingId.uuid,
                offerValue: price?.amount / 100,
                shouldSwap: false,
                oldValue: 0,
              };
              let saleLink = createResourceLocatorString(
                'SaleDetailsPage',
                routes,
                { id: txId.uuid },
                {}
              );
              let orderLink = createResourceLocatorString(
                'OrderDetailsPage',
                routes,
                { id: txId.uuid },
                {}
              );

              if (!this.state.isAdminOffer) {
                //send mail with PDF
                sendOfferEmails({
                  currentUser,
                  listing,
                  orderLink,
                  saleLink,
                  author: listing.author,
                  data,
                });
                
                var jobStatus
                if(listing.attributes?.publicData?.isPremium) {
                  jobStatus = "awaiting"
                } else {
                  jobStatus = "open"
                }
                
                if(listing.attributes?.publicData?.repeatOffer){
                  jobStatus = "open"
                }
                incrementOffersCount(incrementOffersData).catch(error => {});
                const changeProjectStateBody = {
                  userId: currentUser.id.uuid,
                  listingId: listing.id.uuid,
                  state: jobStatus,
                };
                changeProjectState(changeProjectStateBody);
              } else {
                sendAdminSummaryOffer(listing, saleLink);
                const changeProjectStateBody = {
                  userId: currentUser.id.uuid,
                  listingId: listing.id.uuid,
                  state: jobStatus,
                };
                changeProjectState(changeProjectStateBody);
              }
              // send notification
              let userToken = currentUser.attributes.profile.privateData.inboxToken;
              let notificationPrice;
              if (this.state.isAdminOffer) {
                notificationPrice = formatMoney(intl, estimatedPriceMin).slice(0, -3);
                if (estimatedPriceMax?.amount > 0) {
                  notificationPrice += ' - ' + formatMoney(intl, estimatedPriceMax).slice(0, -3);
                }
              } else {
                // notificationPrice = formatMoney(intl, price).slice(0, -3);
                notificationPrice = null;
              }
              sendNotification(userToken, {
                toUserId: listing.author.id.uuid, // ok
                from: currentUser.id.uuid, // ok
                title: `New offer from ${currentUser.attributes.profile.firstName}`, // ok
                subtitle: `Project: ${listing.attributes.title}`, // ok
                price: notificationPrice, // ok
                status: `New offer`, // ok
                redirectUrl: `\\sale-details\\${txId.uuid}`, // ok
                type: `NEW OFFER`, // ok
                shouldDisappear: false, // ok
              });
              
              this.setState({ submitting: false });
              //Redirect to OrderDetailsPage
              history.push(orderLink);
            })
            .catch(err => {
              console.log('err: ', err);
              this.setState({ submitting: false });
            });
        })
        .catch(err => {
          console.log('err: ', err);
          this.setState({ submitting: false });
        });
    } else {
      // show Error before submitting form
      console.log('Error validating user.');
      this.setState({ submitting: false });
    }
  }

  render() {
    const {
      scrollingDisabled,
      currentUser,
      currentUserExpertListing,
      onManageDisableScrolling,
    } = this.props;

    if (this.state.shouldRedirect) {
      return <NamedRedirect name="SearchPage" />;
    }

    let panelTitle = <FormattedMessage id={`NewOfferPage.pageTitle`} />;
    const topbar = <TopbarContainer />;
    const siteTitle = 'Send new offer | SoftwareSupp.com';

    if (this.state.isAdminOffer) {
    panelTitle = <FormattedMessage id={`NewOfferPage.pageTitleAdmin`} />;
  }

  return (
    <Page title={siteTitle} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <Modal
            id="SendNewOfferModal"
            isOpen={this.state.isModalOpen}
            onClose={() => this.setIsModalOpen(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.modalContainer}
              usePortal
            >
              <div>
                <div className={css.modalTextWrapper}>
                  <FormattedMessage id="NewOfferPage.infoText" values={{ br: <br /> }} />
                </div>
                <PrimaryButton
                  className={css.infoAcceptButton}
                  onClick={() => this.proceedWithOffer()}
                  disabled={this.state.submitting}
                  inProgress={this.state.submitting}
                >
                  <FormattedMessage id="NewOfferPage.infoAcceptButton" />
                </PrimaryButton>
              </div>
            </Modal>

            <div className={css.mainWrapper}>
              <div className={css.pageWrapper}>
                <div className={css.rowsWrapper}>
                  <div className={css.goBack}>
                    <InlineTextButton className={css.goBackButton} onClick={() => this.goBack()}>
                      <ArrowLeft className={css.goBackIcon} />
                      Back
                    </InlineTextButton>
                  </div>
                  <div className={css.firstRow}>
                    <div className={css.mainContentWrapper}>
                      <h1 className={css.title}>{panelTitle}</h1>
                     
                      <NewOfferForm
                        className={css.form}
                        initialValues={{}}
                        onSubmit={this.onSubmitNewOfferForm}
                        messageFormatting={'Expert'}
                        currentUser={currentUser}
                        currentUserExpertListing={currentUserExpertListing}
                        commission={this.state.commission}
                        disabled={!this.state.formEnabled}
                        formMinimumPriceNotReached={this.state.formMinimumPriceNotReached}
                        isAdminOffer={this.state.isAdminOffer}
                        jobListingBudget={this.state.jobListingBudget}
                        jobScope={this.state.jobScope}
                        inProgress={this.state.submitting}
                        isJobPremium={this.state.isJobPremium}
                      />
                    </div>
                  </div>
                  <div className={css.secondRow}></div>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

NewOfferPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasExpertListing,
    currentUserExpertListing,
  } = state.user;
  const { productSoftware, productSkills } = state.marketplaceData;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasExpertListing,
    currentUserExpertListing,
    productSkills,
    productSoftware,
  };
};

const mapDispatchToProps = dispatch => ({
  onSendOffer: (listingId, price, data) => dispatch(sendOffer(listingId, price, data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const NewOfferPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(NewOfferPageComponent);

NewOfferPage.setInitialValues = initialValues => setInitialValues(initialValues);

export default NewOfferPage;
