/* eslint-disable */
import React, { Component } from 'react';
import { array, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import { propTypes, LISTING_CATEGORY_JOB } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
  approveListing,
  sendNotification,
  generateNewUser,
  generateNewUser_async,
  generateInvoice,
  RemoveFormData,
} from '../../util/api';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import {
  sendNewReferralListingInfo,
  sendReferralCodeUsed,
  sendNewSoftOrSkillRequest,
  sendListingCreatedMail,
  sendListingPublishedAfterApprovalMail,
  sendNewListingNotification,
} from '../../util/triggerMail';
import { ListingLink } from '../../components';
import { EditProjectForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './EditProjectWizard.css';
import { addMultipleSelectValues } from '../../util/api';

const { Money } = sdkTypes;

function sendInvoice(currentUser) {
  try {
    if (currentUser.attributes.profile) {
      const dataInvoice = {
        currency: 'usd',
        invoiceData: [
          {
            price: 30000,
            projectName: 'Plan - Deposit',
          },
        ],
        userId: currentUser.id.uuid,
        depositListingId: null,
        version: 'task',
        isDeposit: true,
        hourlyPlanId: null,
        customer: currentUser.attributes.profile.privateData.stripeAccount[0] ?? '',
        isPoland: false,
        previousBalance: -30000,
      };

      if (currentUser.attributes.profile.privateData.stripeAccount[0].length > 2) {
        //generateInvoice(dataInvoice);
      }
    }
  } catch (e) {}
}

async function CrealteAndLog(registerData, history) {
  try {
    const generateValues = registerData;
    let res = await generateNewUser_async(generateValues);
    if (res.flag === 'success') {
      const path = createResourceLocatorString('AutoLoginPage', routeConfiguration(), {});

      history.push(path, {
        loginValues: generateValues.loginValues,
        payload: res.payload,
        quickLogin: true,
        // noSignupQuestion: true,
      });
    }
  } catch (err) {
    console.log('err', err);
  }
}

// Create a new or edit listing through EditListingWizard
class EditProjectWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      showDraftModal: false,
      portalRoot: null,
      initialValues: {},
      initialValuesLoaded: {},
      paymentModalOpen: false,
      amount: null,
      listingToOpen: '',
    };
    this.handleProceedWithListing = this.handleProceedWithListing.bind(this);
    this.parseJobScope = this.parseJobScope.bind(this);
  }

  componentDidUpdate() {
    let initialValuesLoaded = this.state.initialValuesLoaded;

    const {
      listing,
      referralNumber,
      initialValuesFromClientPath,
      productSoftware,
      productSkills,
    } = this.props;
    const currentListing = ensureOwnListing(listing);
    const {
      geolocation,
      description,
      title,
      price,
      publicData,
      privateData,
    } = currentListing.attributes;
    const ensureReferralNumber = referralNumber ? referralNumber : privateData?.referral;
    if (!initialValuesLoaded.current) {
      const { location, scope, softwares, primarySoftware, skills, linkJobDetails } =
        initialValuesFromClientPath ?? publicData;

      const initialSoftwares = softwares
        ? softwares.map(soft => {
            const foundSoft = productSoftware.find(foundSoft => foundSoft.key === soft);
            if (foundSoft)
              return {
                value: foundSoft.key,
                label: foundSoft.label,
              };
            else
              return {
                value: soft,
                label: soft,
              };
          })
        : null;

      const initialSkills = skills
        ? skills.map(skill => {
            const foundSkill = productSkills.find(foundSkill => foundSkill.key === skill);
            if (foundSkill)
              return {
                value: foundSkill.key,
                label: foundSkill.label,
              };
            else
              return {
                value: skill,
                label: skill,
              };
          })
        : null;

      const foundPrimarySoft = productSoftware.find(foundSoft => foundSoft.key === primarySoftware);

      const locationFieldsPresent = location && location.address && geolocation;
      const { address } = location ?? {};

      const initialValues = {
        formId: currentListing?.id
          ? ''
          : Date.now().toString() + '_' + (Math.floor(Math.random() * 1000) + 1).toString(),
        title: initialValuesFromClientPath?.title ?? title,
        description: initialValuesFromClientPath?.description ?? description,
        price,
        referral: ensureReferralNumber,
        primarySoftware: foundPrimarySoft
          ? { value: foundPrimarySoft.key, label: foundPrimarySoft.label }
          : null,
        software: initialSoftwares,
        skills: initialSkills,
        linkJobDetails,
        location: locationFieldsPresent
          ? {
              search: address,
              selectedPlace: { address, origin: geolocation },
            }
          : null,
        scope,
      };
      if (scope) {
        for (let i = 0; i < scope?.length; i++) {
          initialValues[`scopeTaskNameInput${i}`] = scope[i].task;
          for (let j = 0; j < scope[i]?.subtasks?.length; j++) {
            initialValues[`scopeTask${i}SubtaskNameInput${j}`] = scope[i].subtasks[j].name;
          }
        }
      }
      initialValuesLoaded.current = true;
      this.setState({ initialValues: initialValues, initialValuesLoaded: initialValuesLoaded });
    }
  }

  parseJobScope(values) {
    let scopeEntries = Object.entries(values).filter(entryArray => {
      return entryArray[0].includes('scope');
    });
    let scopeTaskIds = scopeEntries
      .filter(entry => {
        return entry[0].includes('scopeTaskNameInput') && !!entry[1] && entry[1] !== '';
      })
      .map(entry => {
        return parseInt(entry[0].replace('scopeTaskNameInput', ''));
      })
      .sort((a, b) => {
        return a - b; // integers sort
      });
    let scopeTaskNames = scopeEntries
      .filter(entry => {
        return entry[0].includes('scopeTaskNameInput') && !!entry[1] && entry[1] !== '';
      })
      .sort((a, b) => {
        return a[0].localeCompare(b[0]);
      });
    let jobHowManyScopeItems = scopeTaskIds.length;
    let scope = [];
    for (let i = 0; i < jobHowManyScopeItems; i++) {
      let scopeSubtaskIds = scopeEntries
        .filter(entry => {
          return (
            entry[0].includes('scopeTask' + scopeTaskIds[i] + 'SubtaskNameInput') && entry[1] !== ''
          );
        })
        .map(entry => {
          return parseInt(entry[0].replace('scopeTask' + scopeTaskIds[i] + 'SubtaskNameInput', ''));
        })
        .sort((a, b) => {
          return a - b; // integers sort
        });
      let howManySubtasks = scopeSubtaskIds.length;
      let scopeSubtasks = [];
      for (let j = 0; j < howManySubtasks; j++) {
        let scopeSubtaskName = scopeEntries.find(entry => {
          return entry[0].includes(
            'scopeTask' + scopeTaskIds[i] + 'SubtaskNameInput' + scopeSubtaskIds[j]
          );
        })[1];
        let scopeSubtaskItem = {};
        if (!!scopeSubtaskName) {
          scopeSubtaskItem['name'] = scopeSubtaskName;
        }
        scopeSubtasks.push(scopeSubtaskItem);
      }
      scope.push({
        task: scopeTaskNames[i][1],
        subtasks: scopeSubtasks,
      });
    }
    return scope;
  }

  handleProceedWithListing(values, saveAsDraft) {
    const { currentUser, history, listing, type, referralNumber, productSoftware } = this.props;

    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;

    const {
      title,
      description,
      primarySoftware,
      budget,
      fname,
      lname,
      email,
      countryCode,
      phone,
      linkJobDetails,
      location,
      password,
    } = values;

    if (currentUser && isNewURI) {
      sendNotification(currentUser.attributes.profile.privateData.inboxToken, {
        toUserId: currentUser.id.uuid,
        from: process.env.REACT_APP_PROJECT_MANAGER_USER_ID, // ok
        title: `You have created a project!`, // ok
        subtitle: `What's next? Start your plan.`, // ok
        status: `What's next?`,
        redirectUrl: `\\center/getStarted?step=2`, // ok
        type: `PROCESS`, // ok
        shouldDisappear: false,
      });
    }

    // wysylanie id do potwierdzenia

    const requestedPrimarySoftware =
      !primarySoftware || productSoftware.find(soft => soft.key === primarySoftware.value)
        ? null
        : primarySoftware.value;

    const requestedOtherSoftware = values.software
      ? values.software
          .filter(soft => {
            return soft?.__isNew__;
          })
          .map(soft => {
            return soft.value;
          })
      : [];

    const primarySoftwareKey =
      requestedPrimarySoftware || !primarySoftware ? 'other' : primarySoftware.value;

    let softwareArray = values.software
      ? values.software
          .filter(isUndefined => {
            if (!isUndefined) return false;
            return true;
          })
          .map(soft => {
            if (soft?.__isNew__) {
              return createSlug(soft.value);
            }
            return soft.value;
          })
      : [primarySoftwareKey];
    if (softwareArray && !softwareArray.find(soft => soft === primarySoftwareKey)) {
      softwareArray.unshift(primarySoftwareKey);
    } else if (
      softwareArray &&
      requestedPrimarySoftware &&
      !softwareArray.find(soft => soft === 'other')
    ) {
      softwareArray.unshift('other');
    }

    const skillsArray = values.skills?.map(skill => {
      if (skill?.__isNew__) {
        return createSlug(skill.value);
      }
      return skill.value;
    });

    const requestedSkills = values.skills
      ? values.skills
          .filter(skill => {
            return skill?.__isNew__;
          })
          .map(soft => {
            return soft.value;
          })
      : null;

    if (requestedSkills?.length > 0) {
      const requestSoftwareOrSkillBody = {
        userMail: email ?? currentUser?.attributes.email,
        listingTitle: title,
        listingCategory: LISTING_CATEGORY_JOB,
      };
      sendNewSoftOrSkillRequest(requestSoftwareOrSkillBody, requestedSkills, false);
      const addMultipleSelectValuesBody = requestedSkills.map(skill => {
        return {
          type: 'skill',
          label: skill,
          key: createSlug(skill),
          authorId: currentUser?.id.uuid,
          authorMail: currentUser?.attributes.email ?? email,
        };
      });
      addMultipleSelectValues({ selectValues: addMultipleSelectValuesBody });
    }

    if (requestedPrimarySoftware || requestedOtherSoftware?.length > 0) {
      const requestSoftwareOrSkillBody = {
        userMail: email ?? currentUser?.attributes.email,
        listingTitle: title,
        listingCategory: LISTING_CATEGORY_JOB,
      };
      sendNewSoftOrSkillRequest(
        requestSoftwareOrSkillBody,
        [requestedPrimarySoftware, ...requestedOtherSoftware],
        true
      );
      const addMultipleSelectValuesBody = [requestedPrimarySoftware, ...requestedOtherSoftware].map(
        software => {
          return {
            type: 'software',
            label: software,
            key: createSlug(software),
            authorId: currentUser?.id.uuid,
            authorMail: currentUser?.attributes.email ?? email,
          };
        }
      );
      addMultipleSelectValues({ selectValues: addMultipleSelectValuesBody });
    }

    // Job Scope Parsing
    const jobScope = this.parseJobScope(values);

    const address = location?.selectedPlace?.address;
    const origin = location?.selectedPlace?.origin;

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);

    // debugger;
    var jobType = currentUser?.attributes?.profile?.publicData?.isPremium === true ? true : false


    const listingValues = {
      title: title.trim(),
      description,
      publicData: {
        category: LISTING_CATEGORY_JOB,
        state: isNewURI ? 'open' : listing.attributes.publicData.state,
        softwares: softwareArray,
        primarySoftware: primarySoftwareKey,
        primarySoftwareSearch: primarySoftwareKey,
        scope: jobScope,
        private: false,
        skills: skillsArray,
        linkJobDetails,
        isPremium: jobType,
        location: address ? { address } : null,
        commission: 0.5,
        expires: expirationDate.toString().slice(4, 15),
      },
      privateData: {
        referral: referralNumber,
      },
    };

    const loginValues = {
      fname,
      lname,
      email,
      countryCode,
      phone,
      password,
    };

    if (budget) {
      listingValues.price = budget;
    }

    if (origin) {
      listingValues.geolocation = origin;
    } else if (this.state.initialValues?.location) {
      listingValues.geolocation = null;
    }

    if (currentUser) {
      return listingValues;
    } else {
      CrealteAndLog({ loginValues, listingValues }, history);
    }
  }

  render() {
    const {
      params,
      listing,
      errors,
      currentUser,
      currentUserHasExpertListing,
      referralNumber,
      history,
      onCreateListingDraft,
      onUpdateListing,
      handlePublishListing,
      type,
      onRemoveImage,
      onImageUpload,
      images,
      updateInProgress,
      currentTab,
      onGoToNextTab,
      onGoToPrevTab,
      onCreateTicketsFromListing,
      productSkills,
      productSoftware,
      currentUserStripeAccounts,
      tabArray,
      currentTabIndex,
    } = this.props;

    const currentListing = ensureOwnListing(listing);
    const ensureReferralNumber = referralNumber
      ? referralNumber
      : currentListing.attributes.privateData?.referral;
    const isListingStatusNew = currentListing.attributes.state ? false : true;
    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );
    const currentUserExists = !!currentUser;
    const isAdmin =
      currentUser && currentUser.id
        ? currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID
        : null;

    const initialPrice = currentListing.attributes.price
      ? new Money(currentListing.attributes.price.amount, config.currency)
      : null;

    // Sprawdzanie czy projekt jest edytowany
    const editProject = currentListing.id ? true : false;

    const panelTitle = currentListing.id ? (
      <FormattedMessage
        id="EditProjectWizard.title"
        values={{
          listingTitle: (
            <ListingLink listing={currentListing}>
              <FormattedMessage id="EditProjectWizard.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditProjectWizard.createListingTitle" />
    );

    const imageIds = images => {
      return images ? images.map(img => img.imageId || img.id) : null;
    };

    const onSubmit = async (updateValues, saveAsDraft) => {
      // Normalize images for API call

      let usdAccount = currentUserStripeAccounts?.find(user => user.currency === 'usd');
      // let plnAccount = currentUserStripeAccounts?.find(user => user.currency === 'pln');
      const userHasBalance = usdAccount?.balance < -30000;

      const { images: updatedImages, ...otherValues } = updateValues;

      const imageProperty = typeof images !== 'undefined' ? { images: imageIds(images) } : {};
      const updateValuesWithImages = { ...otherValues, ...imageProperty };
      const { intl } = this.props;
      if (updateValuesWithImages?.price === undefined) {
        updateValuesWithImages.price = new Money(30000, config.currency);
      }
      let modalInfoOpen = updateValues?.price?.amount > 9000000000;
      if (userHasBalance) {
        modalInfoOpen = modalInfoOpen && updateValues?.price?.amount > -usdAccount?.balance;
      }

      if (modalInfoOpen) {
        this.setState({ paymentModalOpen: true });
        this.setState({ amount: updateValues.price.amount });
      }

      if (isNewListingFlow) {
        sendInvoice(currentUser);

        const onUpsertListingDraft = isNewURI ? onCreateListingDraft : onUpdateListing;

        const upsertValues = isNewURI
          ? updateValuesWithImages
          : {
              ...updateValuesWithImages,
              id: currentListing.id,
            };
        onUpsertListingDraft(upsertValues)
          .then(response => {
            const listing = response.data.data;
            const listingParams = {
              id: listing.id.uuid,
              slug: createSlug(listing.attributes.title),
              category: 'jobs',
            };

            onCreateTicketsFromListing(currentUser.id.uuid, response.data.data.id).then(() => {
              if (saveAsDraft) {
                listingParams.variant = LISTING_PAGE_DRAFT_VARIANT;
                this.setState({ listingToOpen: listing.id.uuid });
                if (!modalInfoOpen) {
                  history.push(
                    createResourceLocatorString(
                      'ListingPageVariant',
                      routeConfiguration(),
                      listingParams,
                      {}
                    )
                  );
                }
              } else {
                handlePublishListing(response.data.data.id).then(data => {
                  if (referralNumber) {
                    sendNewReferralListingInfo(listing);
                    sendReferralCodeUsed(listing);
                  }

                  if (userHasBalance) {
                    approveListing({
                      userId: currentUser?.id.uuid,
                      listingId: listing.id.uuid,
                      stripeAccount:
                        currentUser?.attributes?.profile?.privateData?.stripeAccount ?? [],
                    })
                      .then(() => {
                        // listingParams.variant = LI;
                        this.setState({ listingToOpen: listing.id.uuid });
                        if (!modalInfoOpen) {
                          history.push(
                            createResourceLocatorString('ProjectBoardPage', routeConfiguration(), {
                              id: listing.id.uuid,
                            }),
                            {
                              modalInfoOpen: modalInfoOpen,
                            }
                          );
                        }

                        const listingSoftwares = listing.attributes.publicData.softwares;
                        const marketplaceSofts = listingSoftwares
                          ? listingSoftwares
                              .map(soft => {
                                const foundSoft = productSoftware.find(
                                  foundSoft => foundSoft.key === soft
                                );
                                if (foundSoft) return soft;
                                return null;
                              })
                              .filter(soft => !!soft)
                          : null;
                        sendNewListingNotification(listing, marketplaceSofts, intl, true);

                        sendListingPublishedAfterApprovalMail(
                          listing,
                          LISTING_CATEGORY_JOB,
                          currentUser?.id.uuid
                        );
                      })
                      .catch(error => console.log(error));
                  } else {
                    sendListingCreatedMail(listing, LISTING_CATEGORY_JOB, currentUser);
                    listingParams.variant = LISTING_PAGE_PENDING_APPROVAL_VARIANT;
                    this.setState({ listingToOpen: listing.id.uuid });
                    if (!modalInfoOpen) {
                      history.push(
                        createResourceLocatorString(
                          'ListingPageVariant',
                          routeConfiguration(),
                          listingParams
                        ),
                        {
                          modalInfoOpen: modalInfoOpen,
                        }
                      );
                    }
                  }
                });
              }
            });
          })
          .catch(e => {
            // No need for extra actions
          });
      } else {
        delete updateValuesWithImages.publicData.expires;
        onUpdateListing({ ...updateValuesWithImages, id: currentListing.id }).then(data => {
          if (
            updateValuesWithImages.publicData.primarySoftware !==
              listing.attributes.publicData.primarySoftware &&
            currentListing.attributes.state === 'published'
          ) {
            const listingSoftwares = updateValuesWithImages.publicData.softwares;
            const marketplaceSofts = listingSoftwares
              ? listingSoftwares
                  .map(soft => {
                    const foundSoft = productSoftware.find(foundSoft => foundSoft.key === soft);
                    if (foundSoft) return soft;
                    return null;
                  })
                  .filter(soft => !!soft)
              : null;
            sendNewListingNotification(
              {
                id: currentListing.id,
                attributes: updateValuesWithImages,
              },
              marketplaceSofts,
              intl,
              true
            );
          }
          const listingParams = {
            id: currentListing.id.uuid,
            slug: createSlug(currentListing.attributes.title),
            variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
            category: 'jobs',
          };
          history.push(
            createResourceLocatorString('ListingPageVariant', routeConfiguration(), listingParams),
            {
              modalInfoOpen: modalInfoOpen,
            }
          );
        });
      }
    };

    const saveActionMessage = (
      <FormattedMessage
        id={
          currentListing.id && !isNewListingFlow
            ? 'EditProjectWizard.updateAndGoTo'
            : 'EditProjectWizard.publishAndGoTo'
        }
      />
    );

    const createDraftMessage = <FormattedMessage id="EditProjectWizard.createDraftAndGoTo" />;

    return (
      <>
        <EditProjectForm
          className={css.root}
          initialValues={this.state.initialValues}
          initialPrice={initialPrice}
          productSoftware={productSoftware}
          productSkills={productSkills}
          name="Project"
          saveActionMsg={saveActionMessage}
          createDraftMessage={createDraftMessage}
          onSubmit={values => {
            if (values?.formId != '') {
              RemoveFormData(values);
            }
            currentUser
              ? onSubmit(this.handleProceedWithListing(values))
              : this.handleProceedWithListing(values);
          }}
          fetchErrors={errors}
          referral={ensureReferralNumber ? config.custom.referral : null}
          budget={config.custom.budget}
          category={config.custom.category}
          userId={config.custom.userId}
          currentUserExists={currentUserExists}
          currentUserHasExpertListing={currentUserHasExpertListing}
          isAdmin={isAdmin}
          isListingStatusNew={isListingStatusNew}
          parseJobScope={this.parseJobScope}
          // onSaveAsDraft={values => currentUser
          //   ? onSubmit(this.handleProceedWithListing(values), true)
          //   : this.handleProceedWithListing(values, true)}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          images={images}
          isNew={isNewURI}
          panelTitle={panelTitle}
          editProject={editProject}
          updateInProgress={updateInProgress}
          currentTab={currentTab}
          currentTabIndex={currentTabIndex}
          tabArray={tabArray}
          onGoToNextTab={onGoToNextTab}
          onGoToPrevTab={onGoToPrevTab}
        />
        {/* <CustomProjectModal
          id="CustomProjectModal"
          isOpen={this.state.paymentModalOpen}
          amount={this.state.amount}
          history={history}
          onCloseModal={() => {
            this.setState({ paymentModalOpen: false });
            history.push(
              createResourceLocatorString('ProjectBoardPage', routeConfiguration(), {
                id: this.state.listingToOpen,
              }),
              {}
            );
          }}
          onManageDisableScrolling={this.props.onManageDisableScrolling}
        /> */}
      </>
    );
  }
}

EditProjectWizard.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  listing: null,
  updateInProgress: false,
};

EditProjectWizard.propTypes = {
  id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
  }).isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,

  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { currentUser, currentUserStripeAccounts } = state.user;
  const { productSoftware, productSkills } = state.marketplaceData;
  return {
    productSoftware,
    productSkills,
    currentUserStripeAccounts,
    currentUser,
  };
};

export default compose(withViewport, injectIntl, connect(mapStateToProps))(EditProjectWizard);
