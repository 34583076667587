import React from 'react';
import { PrimaryButton } from '../../components';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import css from './ProductItemsApprovalPage.css';

const ApprovalItem = props => {
    const { item, onApproveItem } = props;
    const { authorId, authorMail, label } = item;

    return (
        <div className={css.approvalCard}>
            <div>
                <div>{label}</div>
                <div>
                    {!!authorId
                        && <FormattedMessage id="ProductItemsApprovalPage.authorIdLabel" values={{ id: authorId, br: <br/> }} />}
                </div>
                <div>
                    {!!authorMail
                        && <FormattedMessage id="ProductItemsApprovalPage.authorEmailLabel" values={{ email: authorMail, br: <br/> }} />}
                </div>
            </div>

            <PrimaryButton className={css.approveButton} onClick={onApproveItem}>
                <FormattedMessage id="ProductItemsApprovalPage.approve" />
            </PrimaryButton>
        </div>
    );
};

export default injectIntl(ApprovalItem);