import React from 'react'
import { FormattedMessage } from '../../util/reactIntl';
import css from './JobsListPage.css';
import { PrimaryChip } from '../../components/Chip/Chip';



const IsPremium = (props) => {
  const jobIsPremium = props.jobPior
  return null
  //  return jobIsPremium ? (
  //   <PrimaryChip className={css.premiumChip}>
  //     <FormattedMessage id="ListingPage.isPremium" />
  //   </PrimaryChip>
  // ) : null
}

export default IsPremium;
