import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { sendNewMessageMail } from '../../util/triggerMail';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { ArrowLeft } from 'react-feather';
import { SendMessageFileForm } from '../../forms';
import { getUser } from './ConversationPage.duck';
import InboxSDK from '../../util/inbox/InboxSDK';
import { isSafari } from '../../util/userAgent';
import { sortConversationByDate } from './sortUtil';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { 
  inboxReadConversationGroupProfiles,
  inboxGetGroupMembers,
  inboxAddGroupMembers,
  inboxRemoveGroupMembers,
  inboxGetUserDisplayNames,
  getAllUserIds
} from '../../util/api';
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';
import { TiInfo } from 'react-icons/ti';
import {
  Page,
  Sidebar,
  InlineTextButton,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  OptionAvatar,
  PrimaryButton,
  Footer,
  IconSpinner,
} from '../../components';
import { TopbarContainer } from '../../containers';

import css from './ConversationPage.css';
import Message from './Message';
import OwnMessage from './OwnMessage';

const HOW_MANY_PER_PAGE = 10;
const MAX_MOBILE_SCREEN_WIDTH = 1025;

export const ConversationPageComponent = props => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    history,
    intl,
    params,
    location,
    scrollingDisabled,
    onGetUser,
  } = props;

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [inbox, setInbox] = useState();
  const [conversationType, setConversationType] = useState();
  const [counterpartUser, setCounterpartUser] = useState({loaded: false});
  const [counterpartGroup, setCounterpartGroup] = useState({group: [], loaded: false});
  const [groupName, setGroupName] = useState('');
  const [conversationData, setConversationData] = useState([]);
  const [conversationLoaded, setConversationLoaded] = useState(false);
  const [sendMessageInProgress, setSendMessageInProgress] = useState(false);
  // const [sendMessageError, setSendMessageError] = useState('');
  const [refreshingMessages, setRefreshingMessages] = useState({status: false});
  const [loadingMoreMessages, setLoadingMoreMessages] = useState({status: false});
  const [refreshFormAttachments, setRefreshFormAttachments] = useState(0); // counter
  const [userPermissions, setUserPermissions] = useState();
  const [groupEditMode, setGroupEditMode] = useState();
  const [notAMember, setNotAMember] = useState(false);
  const [groupLoadingFailed, setGroupLoadingFailed] = useState(false);

  const [addSelectOptions, setAddSelectOptions] = useState([]);
  const [removeSelectOptions, setRemoveSelectOptions] = useState([]);
  const [editSelectOptions, setEditSelectOptions] = useState([]);
  const [editSelectedUsers, setEditSelectedUsers] = useState([]);
  const [editGroupInProgress, setEditGroupInProgress] = useState(false);
  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);
  useEffect(() => {
    if((typeof window) !== 'undefined') {
      setShouldRenderReactSelect(true);
    }
  },[]);

  const getCounterpartData = async (convData, conversationType, id, userToken, userId, userCanChatWith, onGetUser) => {
    if(conversationType === 'singular') {
      const counterpart = await onGetUser(id);
      const counterpartObj = {
        id: id,
        name: counterpart.data.attributes.profile.displayName.split(' ')[0],
        imgUrl: counterpart.included ? counterpart.included[0].attributes.variants['square-small'].url : null,
        loaded: true,
      };
      setCounterpartUser(counterpartObj);
    } else if(conversationType === 'group') {
      const userIds = convData.map((el) => el.from);
      const {success, groupName, data, memberProfiles} = await inboxReadConversationGroupProfiles(userToken, {groupId: id, userIds: userIds});
      if(success) {
        const memberIds = memberProfiles.map((el) => el.userId);
        if(memberIds.includes(currentUser.id.uuid) === false) {
          setNotAMember(true);
          return;
        }
        setGroupName(groupName);
        setCounterpartGroup({
          group: data,
          loaded: true
        });
        loadChatGroup(userToken, userCanChatWith, memberProfiles.map((el) => el.userId));
        const newRemoveOptions = memberProfiles.filter((el) => {
          return el.userId !== userId; 
        }).map((el) => {
          return {
            value: el.userId,
            label: el.displayName,
            chipLabel: el.displayName,
            imgUrl: el.imgUrl,
            abbreviatedName: el.abbreviatedName
          };
        });
        setRemoveSelectOptions(newRemoveOptions);
      }
    }
  }

  const loadPermissions = async (conversationType, id, userToken, userId) => {
    if(conversationType === 'group') {
      const {success, data} = await inboxGetGroupMembers(userToken, {groupId: id});
      if(success) {
        const userMember = data.filter((member) => {
          return member.userId === userId;
        });
        if(userMember?.length > 0) {
          setUserPermissions(userMember[0].permissions);
        }
      }
    }
  }

  useEffect(() => {
    if(!!currentUser && !!currentUser.attributes.profile?.privateData?.inboxToken && params?.id) {
      const convType = location?.pathname?.includes('/groupconversation') ? 'group' : 'singular'
      let userId = currentUser.id.uuid;
      let userToken = currentUser.attributes.profile.privateData.inboxToken;
      let userCanChatWith = currentUser?.attributes?.profile?.publicData?.canChatWith;
      let inboxSdk = new InboxSDK(userId, userToken, params?.id, convType);
      setConversationType(convType);
      loadPermissions(convType, params?.id, userToken, userId);
      setInbox(inboxSdk);
      readConversationAndCounterpartData(inboxSdk, pageNumber, convType, params?.id, userToken, userId, userCanChatWith, onGetUser);
    }
  }, [currentUser, params.id, location.pathname, onGetUser]);

  useEffect(() => {
    updateEditSelectOptions(groupEditMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSelectOptions, removeSelectOptions]);

  const readConversationAndCounterpartData = async (inboxSdk, pageNumber, convType, convId, userToken, userId, userCanChatWith, onGetUser) => {
    let { success, data } = await inboxSdk.readConversation(pageNumber, HOW_MANY_PER_PAGE);
    if(success) {
      setConversationData(sortConversationByDate(data));
      getCounterpartData(data, convType, convId, userToken, userId, userCanChatWith, onGetUser);
    } else {
      setGroupLoadingFailed(true);
    }
    setConversationLoaded(true);
  }

  const loadChatGroup = async (userToken, userCanChatWith, currentGroupUserIds) => {
    let userChatGroup = userCanChatWith;
    if(userChatGroup?.includes('all')) {
      let { userIds } = await getAllUserIds();
      userChatGroup = userIds;
    }
    if(userChatGroup?.length > 0) {
      let userDisplayNames = await inboxGetUserDisplayNames(userToken, {userIds: userChatGroup});
      const newAddOptions = userDisplayNames.filter((user) => {
        return currentGroupUserIds.includes(user.userId) === false;
      }).map((user) => {
        return {
          value: user.userId,
          label: user.displayName,
          chipLabel: user.displayName,
          imgUrl: user.imgUrl,
          abbreviatedName: user.abbreviatedName
        }
      });
      setAddSelectOptions(newAddOptions);
    }
  }

  const onRefreshMessages = async () => {
    setRefreshingMessages({status: true});
    setTimeout(() => {
      if(!refreshingMessages.status) {
        setRefreshingMessages({status: false});
      }
    }, 800);
    if(!!inbox) {
      // reads only most current messages from first page
      setPageNumber(0);
      const { data } = await inbox.readConversation(0, HOW_MANY_PER_PAGE);
      setRefreshingMessages({status: false});
      setConversationData(sortConversationByDate(data));
      setConversationLoaded(true);
      if (typeof window !== 'undefined' && window.innerWidth > MAX_MOBILE_SCREEN_WIDTH) {
        scrollToElement('messagesBottom', 'smooth');
      }
    }
  }

  const onLoadMore = async () => {
    setLoadingMoreMessages({status: true});
    setTimeout(() => {
      if(!loadingMoreMessages.status) {
        setLoadingMoreMessages({status: false});
      }
    }, 1000);
    if(!!inbox) {
      const { data } = await inbox.readConversation(pageNumber+1, HOW_MANY_PER_PAGE);
      if(!(data?.length > 0)) {
        toast("Sorry, there are no more messages.", {icon: <TiInfo color={'GoldenRod'} />, style: {marginTop: '100px', marginLeft: '300px'}});
        setLoadingMoreMessages({status: false});
        setConversationLoaded(true);
        return;
      }
      setPageNumber((current) => current+1);
      setConversationData((current) => {
        return sortConversationByDate([
          ...current,
          ...data
        ]);
      });
      setLoadingMoreMessages({status: false});
      setConversationLoaded(true);
      if (typeof window !== 'undefined' && window.innerWidth > MAX_MOBILE_SCREEN_WIDTH) {
        scrollToElement('messagesTop', 'smooth');
      }
    }
  }

  const parseMessages = (messages) => {
    const currentUserId = currentUser.id.uuid;
    return messages.map((message) => {
      const peer = conversationType === 'singular' ? counterpartUser : counterpartGroup?.group?.filter((cg) => cg.userId === message.from)[0];
      return {
        id: message.id.toString(),
        peerImgUrl: peer?.imgUrl,
        peerName: conversationType === 'singular' ? peer?.name : peer?.displayName,
        date: message.createdAt,
        content: message.content,
        status: message.status,
        isOwn: message.from === currentUserId,
        attachments: message.attachments
      };
    })
  }

  const renderMessage = (message) => {
    return (
      <li id={`msg-${message.id}`} key={`msg-${message.id}`} className={css.messageItem}>
        {message.isOwn ? 
          <OwnMessage message={message} intl={intl} />
        :
          <Message message={message} intl={intl} />
        }
      </li>
    );
  };

  const renderConversation = (messages) => {
    return (
    <>
      <ul className={css.messagesBox}>
        <li id="messagesBottom"/>
        {messages.reverse().map((msg) => {
          return renderMessage(msg);
        })}
        <li id="messagesTop"/>
      </ul>
    </>
    );
  }

  // const { tab } = params;
  // const step = location?.state?.step

  const scrollToElement = (elementId, behavior = 'smooth') => {
    const selector = `#${elementId}`;
    const el = document.querySelector(selector);
    const isItSafari = isSafari();
    if (typeof window !== 'undefined' && !!el) {
      if(isItSafari) {
        el.scrollIntoView({
          block: 'end',
          //behavior: behavior, // not working on safari
        });
      } else {
        el.scrollIntoView({
          block: 'end',
          behavior: behavior
        }); 
      }
    }
  }

  const onMessageSubmit = async (values, form) => {
    setSendMessageInProgress(true);
    const message = !!(values?.message) ? values.message.trim() : null;
    const isSendingFiles = values?.files?.length > 0;
    if (!message && !(isSendingFiles)) {
      setSendMessageInProgress(false);
      return;
    }
    if(!!inbox) {
      let result;
      if(isSendingFiles) {
        const formData = new FormData();
        // eslint-disable-next-line 
        for (const file of values.files) {
          formData.append('files', file, file.name);
        }
        formData.append('message', message);
        result = await inbox.sendDataMessage(values.files, values.files.length, message);
      } else {
        result = await inbox.sendTextMessage(message);
      }
      if(result?.success) { // @TODO: this line is potential pitfall when unexpected error occurs
        sendNotifications();
        const mailRecipient = counterpartUser.id;
        const messageAuthorName = currentUser.attributes.profile.firstName;
        if(inbox.conversationType === 'singular') {
          const conversationLink = `/conversation/${currentUser.id.uuid}`;
          sendNewMessageMail(mailRecipient, messageAuthorName, message, conversationLink);
        } else if(inbox.conversationType === 'group' && result.message?.groupRecipients?.length > 0) {
          const conversationLink = `/groupconversation/${params?.id}`;
          let groupRecipients = result.message.groupRecipients.map((el) => el.userId).filter((el) => el !== currentUser.id.uuid);
          // eslint-disable-next-line 
          for(const recipient of groupRecipients) {
            sendNewMessageMail(recipient, messageAuthorName, message, conversationLink);
          }
        }
        setConversationData((prevData) => {
          return sortConversationByDate([
            ...prevData,
            result?.message
          ]);
        });
      }
      setTimeout(() => {
        form.reset();
        setRefreshFormAttachments(prev => prev+1);
        setSendMessageInProgress(false);
        // scrollToElement('submitContainer', 'smooth');
      }, 100);
    }
  }

  const sendNotifications = () => {
    if(!inbox) return;
    if(inbox?.conversationType === 'singular') {
      inbox.sendSingularMessageNotification(currentUser?.attributes?.profile?.firstName ?? "Unknown");
    } else if(inbox?.conversationType === 'group') {
      // eslint-disable-next-line 
      for(let groupMember of counterpartGroup?.group) {
        if(groupMember.userId !== currentUser.id.uuid) {
          inbox.sendGroupMessageNotification(groupMember.userId, groupName);
        }
      }
    }
  }

  const onEditMode = (modeName) => {
    if(groupEditMode === modeName) {
      setGroupEditMode();
      setEditSelectOptions([]);
    } else {
      setGroupEditMode(modeName);
      setEditSelectedUsers([]);
      updateEditSelectOptions(modeName);
    }
  }

  const updateEditSelectOptions = (modeName) => {
    switch(modeName) {
      case 'add':
        setEditSelectOptions(addSelectOptions);
        break;
      case 'remove':
        setEditSelectOptions(removeSelectOptions);
        break;
      default: 
        break;
    }
  }

  if (shouldRenderReactSelect) {
    var { default: Select, components } = require('react-select');
    var formatOptionLabel = ({ value, label, imgUrl, abbreviatedName }) => (
      <div style={{ display: "flex" }}>
        <div className={css.itemAvatar}>
          <OptionAvatar imgUrl={imgUrl} userName={label} abbreviatedName={abbreviatedName} />
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {label}
        </div>
      </div>
    );
    var MultiValue = props => (
      <components.MultiValue {...props}>
        {props.data.chipLabel}
      </components.MultiValue>
    );
    var SingleValue = props => (
      <components.SingleValue {...props}>
        {props.data.chipLabel}
      </components.SingleValue>
    );
  }

  const customSelectStyles = {
    container: (provided, state) => ({
      ...provided,
      fontSize: '14px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '14px',
    }),
  };

  const groupEditSubmitButtonTitle = groupEditMode === 'add' ? 'Add users' : 'Remove users';
  const onSubmitGroupEdit = async () => {
    let userToken = currentUser?.attributes?.profile?.privateData?.inboxToken;
    if(((editSelectedUsers?.length > 0) === false) || (!userToken)) {
      return;
    }
    setEditGroupInProgress(true);
    const selectedUserIds = editSelectedUsers.map((el) => el.value);
    let result;
    if(groupEditMode === 'add') {
      result = await inboxAddGroupMembers(userToken, {groupId: params?.id, memberIds: selectedUserIds});
    } else if(groupEditMode === 'remove') {
      result = await inboxRemoveGroupMembers(userToken, {groupId: params?.id, memberIds: selectedUserIds});
    }
    setEditGroupInProgress(false);
    if(result?.success && result?.groupName) {
      setGroupName(result.groupName);
      setEditSelectedUsers([]);
      let userId = currentUser?.id.uuid;
      let userCanChatWith = currentUser?.attributes?.profile?.publicData?.canChatWith;
      getCounterpartData(conversationData, conversationType, params?.id, userToken, userId, userCanChatWith, onGetUser);
    }
  }

  const messagesTitle = intl.formatMessage({ id: 'MessagesPage.messagesTitle' });
  const title = messagesTitle;

  // const error = false ? (
  //   <p className={css.error}>
  //     <FormattedMessage id="InboxPage.fetchFailed" />
  //   </p>
  // ) : null;
  const isAdmin = currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;
  const isCounterpartAdmin = params?.id === process.env.REACT_APP_ADMIN_USER_ID;

  let displayedUserName = counterpartUser.name ?? '';
  if(isCounterpartAdmin) {
    displayedUserName = 'SoftwareSupp';
  }
  let conversationTitle;
  let sendMessagePlaceholder;
  if(conversationType === 'group') {
    conversationTitle = 'Group conversation';
    if(groupName?.length > 0) {
      conversationTitle = groupName;
    }
    sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: 'group' }
    );
  } else {
    conversationTitle = counterpartUser.loaded ? 'Your conversation with ' + displayedUserName : 'Your conversation';
    sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: displayedUserName }
    );
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}>
          <Sidebar 
            tab={'messages'}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
          <Toaster />
          <div className={css.mainContainer}>
            {conversationLoaded && !notAMember && !groupLoadingFailed ?
              <>
                <div className={css.conversationContainer}>
                  <InlineTextButton className={css.goBackButton} onClick={() => {
                    history.push(createResourceLocatorString('MessagesPage', routeConfiguration(), {}, {}));
                  }}>
                    <ArrowLeft className={css.goBackIcon} />
                    <FormattedMessage id="ConversationPage.goBack" />
                  </InlineTextButton>
                  <div className={css.headingWrapper}>
                    <div className={css.headingTitle}>
                      {conversationTitle}
                    </div>
                    {(userPermissions === 1 || userPermissions === 3) &&
                      <div className={css.headingButtonWrapper} onClick={() => {onEditMode('add')}}>
                        {'Add'}
                      </div>
                    }
                    {(userPermissions === 2 || userPermissions === 3) &&
                      <div className={css.headingButtonWrapper} onClick={() => {onEditMode('remove')}}>
                        {'Remove'}
                      </div>
                    }
                  </div>
                  {(!!groupEditMode) &&
                    <div className={css.groupEditWrapper}>
                      <div className={css.selectWrapper}>
                        <Select 
                          styles={customSelectStyles}
                          formatOptionLabel={formatOptionLabel}
                          components={{ SingleValue, MultiValue }}
                          options={editSelectOptions}
                          isMulti
                          value={editSelectedUsers}
                          onChange={(v) => {setEditSelectedUsers(v)}}
                          placeholder={'Select or type...'}
                        />
                      </div>
                      <div className={css.selectButtonWrapper}>
                        <PrimaryButton
                          className={css.groupEditSubmitButton}
                          disabled={((editSelectedUsers?.length > 0) === false) || editGroupInProgress}
                          onClick={() => onSubmitGroupEdit()}
                        >
                          {groupEditSubmitButtonTitle}
                        </PrimaryButton>
                        <div className={css.groupEditSpinner}>
                          {editGroupInProgress &&
                            <IconSpinner className={css.refreshSpinnerx} />
                          }
                        </div>
                      </div>
                    </div>
                  }
                  <div className={css.chatLoadMoreContainer}>
                    <div className={css.chatLoadMore} onClick={onLoadMore}>
                      {!loadingMoreMessages.status ? 
                        <span>{'Load more messages'}</span>
                      :
                        <IconSpinner className={css.refreshSpinnerx} />
                      }
                    </div>
                  </div>
                  <div className={css.chatContainer}>
                    {!!conversationData && 
                      renderConversation(parseMessages(conversationData))
                    }
                  </div>
                </div>
                {(counterpartUser?.loaded || counterpartGroup?.loaded) ?
                  <SendMessageFileForm
                    formId={'TransactionPanel.SendMessageForm'}
                    rootClassName={css.sendMessageForm}
                    messagePlaceholder={sendMessagePlaceholder}
                    inProgress={sendMessageInProgress}
                    //sendMessageError={sendMessageError}
                    onFocus={() => {}}
                    onBlur={() => {}}
                    onSubmit={onMessageSubmit}
                    shouldDisplayRefresh
                    refreshInProgress={refreshingMessages.status}
                    onRefresh={onRefreshMessages}
                    refreshFormAttachments={refreshFormAttachments}
                  />
                : (conversationType === 'singular') ? 
                  <p>{'We are sorry. User does not exist or was deleted.'}</p>
                : 
                  <p>{'We are sorry. Group is disabled.'}</p>
                }
              </>
            : (groupLoadingFailed === true) ?
            <p>
              <FormattedMessage id="ConversationPage.groupLoadingFailed" />
            </p>
            : (notAMember === true) ?
              <p>
                <FormattedMessage id="ConversationPage.notAMember" />
              </p>
            :
              <p>
                <FormattedMessage id="ConversationPage.loadingMessage" />
              </p>
            }
          </div>
        {/* {error} */}
        {/* {!isHome ? pagingLinks : null} */}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer 
            currentUser = {currentUser} />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ConversationPageComponent.defaultProps = {
  currentUser: null,
};

ConversationPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;
  return {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onGetUser: (userId) => dispatch(getUser(userId)),
});

const ConversationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ConversationPageComponent);

export default ConversationPage;
