/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { func, shape } from 'prop-types';
import { injectIntl } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { sendSubscriptionInfo } from '../../util/triggerMail';
import { addSubscription, approveListing } from '../../util/api';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import { fetchUserInvoices } from '../../ducks/stripe.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { adjustBalance } from '../../util/api';
import { createStripeCustomer, generateInvoice } from '../../util/api';
import {
  createServiceProjectSequence,
  updateExistingStripeCustomer,
  pay,
  createNewStripeCustomer,
  currentUserHasNonClosedListings,
  paymentRequest,
  paymentSuccess,
} from '../../ducks/stripe.duck';

import routeConfiguration from '../../routeConfiguration';
import { parse, LISTING_PAGE_PENDING_APPROVAL_VARIANT } from '../../util/urlHelpers';
import {
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  Modal,
  PrimaryButton,
  SecondaryButton,
  SubscriptionPanel,
  PaymentModalNew as PaymentModal,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { fetchCurrentUser } from '../../ducks/user.duck';

import { compareData, createUpdateBody } from '../../util/stripe';

import css from './SubscriptionPage.css';

export const SubscriptionPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    history,
    location,
    onManageDisableScrolling,
    currentUserStripeAccounts,
    createStripeCustomerInProgress,
    createStripePaymentInProgress,
    userInvoices,
    onFetchUserInvoices,
  } = props;

  const { redirectType, depositListingId } = parse(location.search);
  const [blockedUserModalOpen, setBlockedUserModalOpen] = useState(redirectType === 'block');
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [version, setVersion] = useState();
  const [paymentUrl, setPaymentUrl] = useState('');
  useEffect(() => {
    if (
      location.search === '?basic' ||
      location.search === '?support' ||
      location.search === '?starter' ||
      location.search === '?task' ||
      depositListingId
    ) {
      setPaymentModalOpen(true);
      location.search === '?starter' && setVersion('starter');
      location.search === '?basic' && setVersion('basic');
      location.search === '?support' && setVersion('support');
      location.search === '?task' && setVersion('task');
      !!depositListingId && setVersion('deposit');
    }
    if (currentUserStripeAccounts) {
      let ids = currentUserStripeAccounts.map(account => {
        return account.id;
      });
      if (ids.length > 0 && (userInvoices === null || userInvoices === undefined)) {
        onFetchUserInvoices({ customers: ids });
      }
    }
  }, [currentUserStripeAccounts, location, depositListingId, userInvoices]);

  const onRedirect = path => {
    history.push(createResourceLocatorString(path, routeConfiguration(), {}, {}));
  };

  const user = ensureCurrentUser(currentUser);

  const onSubmitSupportPlan = async values => {
    const {
      onCreateServiceProjectSequence,
      onUpdateStripeCustomer,
      onPay,
      onCreateStripeCustomer,
      onCurrentUserHasNonClosedListings,
      currentUser,
      onPaymentSuccess,
      onPaymentRequest,
    } = props;

    const isTask = version === 'task';
    const isBasic = version === 'basic';
    let isSupport = version === 'support';
    const isStarter = version === 'starter';
    let listingTitle = '';
    let priceAmount = 0;
    switch (version) {
      case 'task':
        listingTitle = 'task';
        priceAmount = 10000;
        break;
      case 'starter':
        listingTitle = 'starter';
        priceAmount = 30000;
        break;
      case 'basic':
        listingTitle = 'basic';
        priceAmount = 50000;
        break;
      case 'support':
        listingTitle = 'support';
        priceAmount = 100000;
        break;
    }
    // let priceAmount = isSupport ? 100000 : isStarter ? 30000 : 50000;

    
    

    if (currentUser) {
      const currentUserHasNonClosedListing = await onCurrentUserHasNonClosedListings();

      const subscriptionData = {
        userId: user.id?.uuid,
        data: {
          start: Date().toString(),
          end: null,
          version: version,
          confirmed: false,
        },
      };

      const { depositBalance } = values;

      addSubscription(subscriptionData);
      sendSubscriptionInfo(user, version);

      if (version === 'depositBalance') {
        isSupport = true;

        listingTitle = 'balance';

        priceAmount =
          values.currency === 'pln'
            ? (depositBalance / config.plnUsdFactor) * 100
            : depositBalance * 100;
      }

      // by default Safari blocks open window requests if there's a long timestamp between user click and window open handler

      let hourlyPlanId;

      if (!depositListingId && !currentUserHasNonClosedListing) {
        const supportPlanVersion = listingTitle;

        hourlyPlanId = await onCreateServiceProjectSequence(supportPlanVersion);
      }

      if (!createStripeCustomerInProgress && !createStripePaymentInProgress) {
        let stripeAccount = currentUserStripeAccounts ?? null;
        if (stripeAccount && !Array.isArray(stripeAccount)) {
          stripeAccount = [stripeAccount];
        }

        const invoiceData = [
          {
            price: priceAmount,
            projectName: intl.formatMessage({
              id: isBasic
                ? 'SubscriptionPage.basicInvoiceTitle'
                : isSupport
                ? 'SubscriptionPage.supportInvoiceTitle'
                : isStarter
                ? 'SubscriptionPage.starterInvoiceTitle'
                : 'SubscriptionPage.depositInvoiceTitle',
            }),
          },
        ];
        let usdAccount = stripeAccount?.find(
          user => user.currency === 'usd' || user.currency === null
        );
        let plnAccount = stripeAccount?.find(user => user.currency === 'pln');
        let currentUserMatchingAccount = stripeAccount?.find(
          user => user.currency === (values.currency ?? 'usd') || user.currency === null
        );
        if ((usdAccount || plnAccount) && !currentUserHasNonClosedListing) {
          if (usdAccount?.balance || plnAccount?.balance) {
            approveListing({
              userId: currentUser?.id.uuid,
              listingId: hourlyPlanId,
            }).catch(error => console.log(error));
          }
        }

        let projectBoardLink = null;
        if (depositListingId || hourlyPlanId) {
          projectBoardLink = createResourceLocatorString(
            depositListingId ? 'ProjectBoardPageVariant' : 'ProjectBoardPage',
            routeConfiguration(),
            depositListingId
              ? { id: depositListingId, variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT }
              : { id: hourlyPlanId },
            {}
          );
        }

        let billingLink = createResourceLocatorString('BillingPage', routeConfiguration(), {}, {});

        const paymentBody = {
          data: {
            addEmail: values.addEmail ?? "",
            currency: values.currency ?? 'usd',
            invoiceData,
            userId: currentUser.id.uuid,
            depositListingId,
            version,
            isDeposit: true,
            hourlyPlanId,
          },
        };

        let currentUserUsdAccount = usdAccount;
        const usdBalance = currentUserUsdAccount?.balance;
        if (currentUserUsdAccount) {
          if (currentUserUsdAccount.balance > 0) {
            await adjustBalance(currentUser.attributes.profile.privateData.inboxToken, {
              amount: -usdBalance,
              currency: 'usd',
              customer: currentUserUsdAccount.id,
            });
          }
        }
        if (currentUserMatchingAccount) {
          if (compareData(currentUserMatchingAccount, currentUser, values)) {
            currentUserMatchingAccount = await onUpdateStripeCustomer({
              customer: currentUserMatchingAccount.id,
              ...createUpdateBody(values, currentUser),
            });
            if (stripeAccount.length > 1) {
              const secondAccount = stripeAccount?.find(
                user => user.currency !== (values.currency ?? 'usd') && user.currency !== null
              );
              await onUpdateStripeCustomer({
                customer: secondAccount.id,
                ...createUpdateBody(values, currentUser),
              });
            }
          }
          paymentBody.data.customer = currentUserMatchingAccount.id;
          paymentBody.data.isPoland = currentUserMatchingAccount.address?.country === 'PL';
          paymentBody.data.previousBalance = currentUserMatchingAccount.balance;
          onPay(paymentBody).then(url => {
            if(url!==undefined){
              window.open(url, '_blanc');
            }
            if (currentUserUsdAccount) {
              if (currentUserUsdAccount.balance > 0) {
                adjustBalance(currentUser.attributes.profile.privateData.inboxToken, {
                  amount: usdBalance,
                  currency: 'usd',
                  customer: currentUserUsdAccount.id,
                });
              }
            }
            if(userInvoices.find(invoice => invoice.status==="open")===undefined){
              history.push(billingLink, { payment: true, link: projectBoardLink });
            }
            else{
              history.push(billingLink, { paymentModalOpen: true, unpaidInvoice: true, link: projectBoardLink });
            }
          });
        } else {
          const body = createUpdateBody(values, currentUser);
          onCreateStripeCustomer(body, stripeAccount).then(response => {
            paymentBody.data.customer = response.id;
            paymentBody.data.isPoland = response.address?.country === 'PL';
            onPay(paymentBody).then(url => {
              if(url!==undefined){
                window.open(url, '_blanc');
              }
              if (currentUserUsdAccount) {
                if (currentUserUsdAccount.balance > 0) {
                  adjustBalance(currentUser.attributes.profile.privateData.inboxToken, {
                    amount: usdBalance,
                    currency: 'usd',
                    customer: currentUserUsdAccount.id,
                  });
                }
              }
              if(userInvoices.find(invoice => invoice.status==="open")===undefined){
                history.push(billingLink, { payment: true, link: projectBoardLink });
              }
              else{
                history.push(billingLink, { paymentModalOpen: true, unpaidInvoice: true, link: projectBoardLink });
              }
            });
          });
        }
      }
    } else {
      const invoiceData = [
        {
          price: priceAmount,
          projectName: intl.formatMessage({
            id: isBasic
              ? 'SubscriptionPage.basicInvoiceTitle'
              : isSupport
              ? 'SubscriptionPage.supportInvoiceTitle'
              : isStarter
              ? 'SubscriptionPage.starterInvoiceTitle'
              : 'SubscriptionPage.depositInvoiceTitle',
          }),
        },
      ];
      const paymentBody = {
        addEmail: values.addEmail ?? "",
        version: version,
        currency: values.currency ?? 'usd',
        invoiceData
      };


      const body = createUpdateBody(values, null);
      onPaymentRequest();
      createStripeCustomer(body).then(response => {
        paymentBody.customer = response.id;
        paymentBody.isPoland = response.address?.country === 'PL';
        
        generateInvoice(paymentBody).then(response => {
          window.open(response.hosted_invoice_url);
          setPaymentUrl(response.hosted_invoice_url);
          onPaymentSuccess();
        });
      });
    }
  };

  const title = intl.formatMessage({ id: 'SubscriptionPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutWrapperTopbar>
        <TopbarContainer currentPage="SubscriptionPage" />
        <UserNav currentUser={currentUser} selectedPageName="SubscriptionPage" />
      </LayoutWrapperTopbar>
      <LayoutWrapperMain className={css.layoutWrapper}>
        <SubscriptionPanel
          onRedirect={onRedirect}
          onSubmitSupportPlan={version => {
            setPaymentModalOpen(true);
            setVersion(version);
          }}
        />
        <Modal
          id="AuthenticationPage.userBlockedModal"
          isOpen={blockedUserModalOpen}
          onClose={() => setBlockedUserModalOpen(false)}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.termsWrapper}>
            <h2 className={css.termsHeading}>
              <FormattedMessage id="SubscriptionPage.userBlockedModalTitle" />
            </h2>
            <FormattedMessage
              id="SubscriptionPage.userBlockedModalContent"
              values={{ br: <br /> }}
            />
            <div className={css.buttonGroup}>
              <PrimaryButton
                className={css.modalButton}
                onClick={() =>
                  history.push(
                    createResourceLocatorString('NewListingPage', routeConfiguration(), {}, {})
                  )
                }
              >
                <FormattedMessage id="SubscriptionPage.postJob" />
              </PrimaryButton>
              <SecondaryButton
                className={css.modalButton}
                onClick={() => {
                  setBlockedUserModalOpen(false);
                  history.push(
                    createResourceLocatorString('SubscriptionPage', routeConfiguration(), {}, {})
                  );
                }}
              >
                <FormattedMessage id="SubscriptionPage.buySupportPlan" />
              </SecondaryButton>
              <SecondaryButton
                className={css.modalButton}
                onClick={() =>
                  history.push(
                    createResourceLocatorString(
                      'NewListingExpertPage',
                      routeConfiguration(),
                      {},
                      {}
                    )
                  )
                }
              >
                <FormattedMessage id="SubscriptionPage.createExpertProfile" />
              </SecondaryButton>
            </div>
          </div>
        </Modal>
        <PaymentModal
          id="PaymentModal"
          version={version}
          contentClassName={css.enquiryModalContent}
          isOpen={paymentModalOpen}
          paymentUrl={paymentUrl}
          userInvoices={userInvoices}
          onCloseModal={() => setPaymentModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmit={values => onSubmitSupportPlan(values)}
          currentUser={currentUser}
          currentUserStripeAccounts={currentUserStripeAccounts}
          createStripePaymentInProgress={createStripePaymentInProgress}
          createStripeCustomerInProgress={createStripeCustomerInProgress}
        />
      </LayoutWrapperMain>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </Page>
  );
};

SubscriptionPageComponent.defaultProps = {
  currentUser: null,
};
SubscriptionPageComponent.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserStripeAccounts } = state.user;
  const { createStripeCustomerInProgress, createStripePaymentInProgress, userInvoices } = state.stripe;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    userInvoices,
    currentUser,
    currentUserStripeAccounts,
    createStripeCustomerInProgress,
    createStripePaymentInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onCreateServiceProjectSequence: version => dispatch(createServiceProjectSequence(version)),
  onFetchUserInvoices: body => dispatch(fetchUserInvoices(body)),
  onPaymentRequest: () => dispatch(paymentRequest()),
  onPaymentSuccess: () => dispatch(paymentSuccess()),
  onUpdateStripeCustomer: body => dispatch(updateExistingStripeCustomer(body)),
  onPay: body => dispatch(pay(body)),
  onCurrentUserHasNonClosedListings: body => dispatch(currentUserHasNonClosedListings(body)),
  onCreateStripeCustomer: (body, stripeAccount) =>
    dispatch(createNewStripeCustomer(body, stripeAccount)),
});

const SubscriptionPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SubscriptionPageComponent);

SubscriptionPage.loadData = () => {
  return fetchCurrentUser();
};

export default SubscriptionPage;
