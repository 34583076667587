import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import { MenuItem, TextField, Box } from '@material-ui/core';
import { Autocomplete }  from '@material-ui/lab'
import getCountryCodesWithPhoneCodes from '../../translations/countryCodesWithPhoneCodes'

import css from './SignupForm.css';


const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {
  const [countryPhone, setCountryPhone] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');


  return(
  <FinalForm
  mutators={{
    setValue: ([field, value], state, { changeValue }) => {
      changeValue(state, field, () => value)
    }
  }}
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        form,
        intl,
        onOpenTermsOfService,
        showWhyLogin,
        initialValues,
      } = fieldRenderProps;

      const countries = getCountryCodesWithPhoneCodes();
      const asterisk = <span className={css.asterisk}>*</span>;
      // email
      const emailLabel = intl.formatMessage({ id: 'SignupForm.emailLabel' }, { asterisk });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({ id: 'SignupForm.passwordLabel' }, { asterisk });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      //phone
      const phoneLabel = intl.formatMessage({
        id: 'SignupForm.phoneLabel',
      });
      const phonePlaceholder = intl.formatMessage({
        id: 'SignupForm.phonePlaceholder',
      });

      const countryCodes = require('country-codes-list');
      const countryCodeList = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}');

      // firstName
      const firstNameLabel = intl.formatMessage({ id: 'SignupForm.firstNameLabel' }, { asterisk });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({ id: 'SignupForm.lastNameLabel' }, { asterisk });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const accountTypeRequired = validators.required("Please select account type");

      const invalidPhoneMessage = initialValues?.phone ? (intl.formatMessage({
        id: 'SignupForm.invalidPhone',
      })) : phoneNumber === "" ? (intl.formatMessage({
        id: 'SignupForm.invalidPhone',
      })) : intl.formatMessage({
        id: 'SignupForm.noCC',
      });
      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      const signupText = (
        <p className={css.signupText}>
          <FormattedMessage id={'SignupForm.signupInfo'} />
        </p>
      );

      const signupTextMail = (
        <p className={css.signupTextMail}>
          <FormattedMessage id={'SignupForm.clientPathWhySignupInfo'} />
        </p>
      );

      return (
        <Form className={classes} onSubmit={values => handleSubmit(values)}>
          <div>
            {showWhyLogin ? signupTextMail : signupText}

            <div className={css.bulletTitle}>
              <label>Account type:</label>
            </div>
            <div className={css.bulletContainer}>
              <div className={css.singleBulletItem}>
                <Field
                  name="type"
                  component="input"
                  type="radio"
                  value="customer"
                  className=""
                  id="customer"
                  validate={accountTypeRequired}
                  />
                  <label className={css.typeButton} htmlFor="customer">
                      Customer
                  </label>
              </div>

              <div className={css.singleBulletItem}>  <Field
                  name="type"
                  component="input"
                  type="radio"
                  value="freelancer"
                  className=""
                  id="freelancer"
                  validate={accountTypeRequired}
                  />
                  <label className={css.typeButton} htmlFor="freelancer">
                      Freelancer
                  </label>
              </div>
            </div>

            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <div className={typeof document !== 'undefined' ?
            !document?.getElementById('phone')?.classList.contains("FieldTextInput_inputError__GA57-")
            ? css.phone : css.errorPhone : css.phone}>
              <label for="phone" className={css.phoneLabel}>{phoneLabel}</label>
              <Autocomplete
                id={formId ? `${formId}.countryCodeac` : 'countryCodeac'}
                name="countryCodeac"
                options={countries}
                autoHighlight
                disableClearable
                //popupIcon={''}
                getOptionLabel={(option) =>  option.phone || countryPhone || ''}
                filterOptions={(options, state)=>{
                    if(state.inputValue!==''){
                      if(isNaN(state.inputValue)){
                        let filteredOptions = []
                        filteredOptions=options.filter((option)=>(option.label).toLowerCase().includes((state.inputValue).toLowerCase()));
                        return filteredOptions;
                      }
                      else{
                        let filteredOptions = []
                        filteredOptions=options.filter((option)=>option.phone.includes(state.inputValue));
                        return filteredOptions;
                      }
                    }
                    else{return options;}
                  }
                }
                getOptionSelected={(option, value) => `+${option.phone}` === value }
                defaultValue={countries.find(option => `+${option.phone}` === initialValues?.countryCode)}
                onChange={(event, newValue) => {
                  if(newValue?.phone){
                    form.mutators.setValue('countryCode',`+${newValue?.phone}`);
                    setCountryPhone(`+${newValue?.phone}`);
                    if(newValue?.phone!==null&&phoneNumber!==null&&typeof window!=='undefined'){
                      let phoneInput=document?.getElementById('phone');
                      phoneInput.classList.remove("FieldTextInput_inputError__GA57-")
                      let phoneError = document?.getElementsByClassName("ValidationError_root__1SXA9")
                      for(let i=0; i<phoneError.length; i++){
                        if (phoneError[i].innerHTML===invalidPhoneMessage){
                          phoneError[i].style.visibility='hidden';
                          break;
                        }
                      }
                    }
                  }
                }}
                renderOption={(option, props) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, color:'rgba(74, 74, 74, 0.9)' }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    className={countryPhone ==="" ? css.countryCodePlaceholder : css.countryCode}
                    id={formId ? `${formId}.countryCodes` : 'countryCodes'}
                    name="countryCodes"
                    value={countryPhone}
                    {...params}
                    label=""
                    placeholder='+00'
                    SelectProps={{renderValue: option => option }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        countryPhone==="" ? (<div className={css.countryArrow}>{params.InputProps.endAdornment.props.children}</div>)
                        : (null)
                      ),
                      endAdornment: null
                    }}>
                    {Object.entries(countryCodeList).map((option, index) =>
                      <MenuItem key={index} className={css.countryOption} value={option[1].substr(option[1].indexOf('+'), option[1].length)}>
                        {option[1]}
                      </MenuItem>
                    )}
                    </TextField>
                )}
              />
              <FieldTextInput
                className={css.phoneNumber}
                type="text"
                id={formId ? `${formId}.phone` : 'phone'}
                name="phone"
                placeholder={phonePlaceholder}
                onKeyUp={(event) => {setPhoneNumber(event.target.value)}}
                onBlur={(event) => {setPhoneNumber(event.target.value);setPhoneNumber(event.target.value)}}
                //validate={!!initialValues?.countryCode && countryPhone==='' ? validators.phoneRequired(invalidPhoneMessage,initialValues?.countryCode) :
                //validators.phoneRequired(invalidPhoneMessage,countryPhone)}
              />
            </div>
            <Field
                id={formId ? `${formId}.countryCode` : 'countryCode'}
                component="input"
                className={css.hidden}
                //validate={!!initialValues?.phone && phoneNumber==='' ? validators.phoneRequired(invalidPhoneMessage,initialValues?.phone) :
                //validators.phoneRequired(invalidPhoneMessage,phoneNumber)}
                type="text"
                name="countryCode"
                value={countryPhone}
              />
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
          </div>

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
)};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
