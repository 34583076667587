export const RECEIVERS_OPTIONS = [
  {
    value: 'experts',
    label: 'Experts - Approved'
  },
  {
    value: 'expertsPendingApproval',
    label: 'Experts - Pending Approval'
  },
  {
    value: 'clientsProjectsOpen',
    label: 'Clients - projects open'
  },
  {
    value: 'clientsProjectsAwaiting',
    label: 'Clients - projects awaiting'
  },
  {
    value: 'clientsProjectsInProgress',
    label: 'Clients - projects in progress'
  },
  {
    value: 'clientsPendingApproval',
    label: 'Clients - pending approval'
  },
  {
    value: 'usersWithoutListings',
    label: 'Users - without listings'
  },
  {
    value: 'usersWithTicketsOnly',
    label: 'Users - with tickets only'
  }
];