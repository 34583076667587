import React from 'react';
import { bool} from 'prop-types';
import classNames from 'classnames';
import { intlShape } from '../../util/reactIntl';

import { propTypes, DATE_TYPE_DATETIME } from '../../util/types';
import { BookingTimeInfo } from '../../components';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import css from './CenterPage.css';

// Functional component as internal helper to print BookingTimeInfo if that is needed
export const BookingInfoMaybe = props => {
    const { bookingClassName, isOrder, intl, tx, unitType } = props;
    
    const timeZone = typeof window !== 'undefined'? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';
  
    // If you want to show the booking price after the booking time on CenterPage you can
    // add the price after the BookingTimeInfo component. You can get the price by uncommenting
    // sthe following lines:
  
    // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
    // const price = bookingPrice ? formatMoney(intl, bookingPrice) : null;
  
    // Remember to also add formatMoney function from 'util/currency.js' and add this after BookingTimeInfo:
    // <div className={css.itemPrice}>{price}</div>
  
    return (
      <div className={classNames(css.bookingInfoWrapper, bookingClassName)}>
        <BookingTimeInfo
          bookingClassName={bookingClassName}
          isOrder={isOrder}
          intl={intl}
          tx={tx}
          unitType={unitType}
          dateType={DATE_TYPE_DATETIME}
          timeZone={timeZone}
        />
        
      </div>
    );
  };
  
  BookingInfoMaybe.propTypes = {
    intl: intlShape.isRequired,
    isOrder: bool.isRequired,
    tx: propTypes.transaction.isRequired,
    unitType: propTypes.bookingUnitType.isRequired,
  };