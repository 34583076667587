import React, { Component } from 'react';
import { func, shape, array } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';

import { Tooltip } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { maxLength, required, composeValidators } from '../../util/validators';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { reportTimeFormat } from '../../util/validators'
import {

  PrimaryButton,
  Form,
  FieldTextInput,
  DateSelector,
  AddImages,
  ValidationError,
  FieldSelect,
} from '../../components';

import css from './EditTicketForm.css';

const TITLE_MAX_LENGTH = 120;
const ACCEPT_IMAGES = 'image/*';

export class EditTicketFormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      imageUploadRequested: false,
    };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  onImageUploadHandler(file) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props.onImageUpload({ id: `${file.name}_${Date.now()}`, file })
      this.setState({ imageUploadRequested: false });
    }
  }

  render() {
    return (
      <FinalForm
        key="ticketform"
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            disabled,
            ready,
            rootClassName,
            className,
            handleSubmit,
            pristine,
            updated,
            updateInProgress,
            fetchErrors,
            intl,
            form,
            images,
            onImageUploadHandler,
            onRemoveImage,
            listing,
            imageUploadRequested,
            isOwnListing,
            isDraft,
            priorityOptions,
            mobileMargins,
            isExpertOnProject,
            initialValues
          } = formRenderProps;

          // if (!isOwnListing && !userConnectedToTicket && !isNotOwnPrivateListing) {
          //   return <div className={css.noRightsToEdit}>
          //     <FormattedMessage id="EditTicketForm.noRightsToEdit" />
          //   </div>
          // }
          const reportTimeFormatValidator = reportTimeFormat(
            intl.formatMessage({
              id: 'EditTicketForm.completionHoursErrorFormat',
            })
          );

  
          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = disabled || submitInProgress || pristine;

          const { updateListingError, showListingsError } = fetchErrors || {};
          const errorMessage = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          const tooltipRequired = (
            <Tooltip title={<FormattedMessage id="EditProjectForm.sectionRequired" values={{ name: 'Title' }} />}>
              <span className={css.error}>*</span>
            </Tooltip>
          );

          const titleMessage = intl.formatMessage(
            { id: "EditTicketForm.title" },
            { tooltip: tooltipRequired }
          );
          const titlePlaceholderMessage = intl.formatMessage({ id: "EditTicketForm.titlePlaceholder" });
          const titleRequiredMessage = intl.formatMessage({ id: "EditTicketForm.titleRequired" });
          const priorityMessage = intl.formatMessage({ id: "EditTicketForm.priority" });
          const priorityPlaceholderMessage = intl.formatMessage({ id: "EditTicketForm.priorityPlaceholder" });

          const hoursMessage = type => intl.formatMessage({ id: `EditTicketForm.${type}Hours` });
          const hoursPlaceholder = type => intl.formatMessage({ id: `EditTicketForm.${type}HoursPlaceholder` });

          const maxLengthMessage = intl.formatMessage(
            { id: 'EditTicketForm.maxLength' },
            {
              maxLength: TITLE_MAX_LENGTH,
            }
          );
          const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

          const descriptionMessage = intl.formatMessage({
            id: "EditTicketForm.description",
          });
          const descriptionPlaceholderMessage = intl.formatMessage({
            id: "EditTicketForm.descriptionPlaceholder",
          });
          const saveButtonMessage = intl.formatMessage({
            id: "EditTicketForm.save",
          });

          

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditTicketForm.chooseImage" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditTicketForm.imageTypes" />
              </span>
            </span>
          );

     

          const titleField =
            <FieldTextInput
              id="title"
              name="title"
              key="title"
              className={css.title}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus
            />

          const descriptionField =
            <FieldTextInput
              id="description"
              name="description"
              key="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
              isTicket
              isDescription
            />

          const dateField =
            <div className={css.selectDate} key="deliveryDate">
              <DateSelector
                intl={intl}
                form={form}
                mobileMargins={!mobileMargins}
                startDatePlaceholder={initialValues?.deliveryDate?.length > 8
                  ? initialValues?.deliveryDate.slice(0, -6)
                  : initialValues?.deliveryDate
                }
              />
            </div>

          const imagesField =
            <AddImages
              className={css.imagesField}
              images={images}
              key="images"
              thumbnailClassName={css.thumbnail}
              savedImageAltText={intl.formatMessage({
                id: 'EditTicketForm.savedImageAltText',
              })}
              softwares={listing.attributes.publicData.softwares}
              onRemoveImage={onRemoveImage}
            >
              <Field
                id="addImage"
                name="addImage"
                accept={ACCEPT_IMAGES}
                form={null}
                label={chooseImageText}
                type="file"
                disabled={imageUploadRequested}
              >
                {fieldprops => {
                  const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                  const { name, type } = input;
                  const onChange = e => {
                    const file = e.target.files[0];
                    form.change(`addImage`, file);
                    form.blur(`addImage`);
                    onImageUploadHandler(file);
                  };
                  const inputProps = { accept, id: name, name, onChange, type };
                  return (
                    <div className={css.addImageWrapper}>
                      <div className={css.aspectRatioWrapper}>
                        {fieldDisabled ? null : (
                          <input {...inputProps} className={css.addImageInput} />
                        )}
                        <label htmlFor={name} className={css.addImage}>
                          {label}
                        </label>
                      </div>
                    </div>
                  );
                }}
              </Field>

              <Field
                component={props => {
                  const { input, meta } = props;
                  return (
                    <div className={css.imageRequiredWrapper}>
                      <input {...input} />
                      <ValidationError fieldMeta={meta} />
                    </div>
                  );
                }}
                name="images"
                type="hidden"
              />
            </AddImages>

          const priorityField =
            <FieldSelect
              className={css.priority}
              name="priority"
              id="priority"
              key="priority"
              label={priorityMessage}
            >
              <option value="">{priorityPlaceholderMessage}</option>
              {priorityOptions.map(c => (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              ))}
            </FieldSelect>

          const hoursField = (type) => {
            
            let validationFunc = reportTimeFormatValidator;
            return (
              <FieldTextInput
                className={css.hoursModal}
                name={`${type}Hours`}
                id={`${type}Hours`}
                key={`${type}Hours`}
                label={hoursMessage(type)}
                type="textarea"
                validate={validationFunc}
                placeholder={hoursPlaceholder(type)}
              />
            );

          }

          return (
            <Form key="shortForm" className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              {errorMessageShowListing}
              {isOwnListing ? (
                [
                  titleField,
                  descriptionField,
                  <div key="priorityfield"  className={css.oneRowFields}>
                    {priorityField}
                    {dateField}
                    {hoursField('estimation')}
                    {hoursField('completion')}
                  </div>,
                  imagesField,
                ]
                ) : ( isExpertOnProject ? (
                  [
                  titleField,
                  descriptionField,
                  <div key="priorityfield"  className={css.oneRowFields}>
                    {priorityField}
                    {dateField}
                    {hoursField('estimation')}
                    {hoursField('completion')}
                  </div>,
                  imagesField,
                  ]
                  ):(
                    <div className={css.oneRowFields}>
                      {dateField}
                      {hoursField('estimation')}
                      {hoursField('completion')}
                    </div>
                  )
                )}

              {!isDraft ? (

                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveButtonMessage}
                </PrimaryButton>
              ) : null}
            </Form>
          );
        }}
      />);
  }
}

EditTicketFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditTicketFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditTicketFormComponent);
