import React from 'react';
import { string } from 'prop-types';
import Mailchimp from 'react-mailchimp-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaLinkedIn,
  IconSocialMediaTwitter,
  IconSocialMediaYouTube,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import { Container, Row, Col } from 'react-bootstrap';

import css from './Footer.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteYouTubePage, siteLinkedInPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToYt = intl.formatMessage({ id: 'Footer.goToYouTube' });
  const goToLinkedIn = intl.formatMessage({ id: 'Footer.goToLinkedIn' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const ytLink = siteYouTubePage ? (
    <ExternalLink key="linkToYouTube" href={siteYouTubePage} className={css.icon} title={goToYt}>
      <IconSocialMediaYouTube />
    </ExternalLink>
  ) : null;

  const liLink = siteLinkedInPage ? (
    <ExternalLink
      key="linkToLinkedIn"
      href={siteLinkedInPage}
      className={css.icon}
      title={goToLinkedIn}
    >
      <IconSocialMediaLinkedIn />
    </ExternalLink>
  ) : null;

  const fbLink = siteYouTubePage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  return [fbLink, twitterLink, liLink, ytLink].filter(v => v != null);
};

// const software = [
//   'pipedrive',
//   'zoho',
//   'hubSpot',
//   'salesforce',
//   'shopify',
//   'zapier',
//   'monday',
//   'webflow',
//   'gsuite',
//   'twilio',
//   'klaviyo',
//   'wordpress',
//   'office',
// ]

// const categories = [
//   "crmSupport",
//   "eCommerce",
//   "softareDev",
//   "projectManagement",
//   "analyticsSetup",
//   "emailSystem",
//   "telephony",
//   "marketignAutomation",
// ]

const Footer = props => {
  const { rootClassName, className, intl, currentUser } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  // const expertsLinks = software.map(expert =>
  //   <li className={css.listItem} key={expert}>
  //     <NamedLink name="SearchPage_experts_software" className={css.softwareLink} params={{software: expert}}>
  //       {expert} Experts
  //     </NamedLink>
  //   </li>
  // );

  // const jobsLinks = software.map(job =>
  //   <li className={css.listItem} key={job}>
  //     <NamedLink name="SearchPage_jobs_software" className={css.softwareLink} params={{software: job}}>
  //       {job} Jobs
  //     </NamedLink>
  //   </li>
  // );

  // const categoriesLinks = categories.map(category =>
  //   <li className={css.listItem}>
  //     <NamedLink name="NewListingPage" className={css.category}>
  //       <FormattedMessage id={`Footer.${category}`} />
  //     </NamedLink>
  //   </li>
  //   )

  const termsLink = (
    <NamedLink name="TermsOfServicePage">
      <FormattedMessage id="JobApplicationForm.termsOfUse" />
    </NamedLink>
  );

  const privacyLink = (
    <NamedLink name="PrivacyPolicyPage">
      <FormattedMessage id="JobApplicationForm.privacyPolicy" />
    </NamedLink>
  );

  return (
    <div className={classes} id="footer">
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}></div>
          <div className={css.links}>
            <Container>
              <Row>
                <Col xs={12} md={8}>
                  <a href="/" className={css.logoLink}>
                    <span className={css.logo}>
                      <Logo className={css.logoWidth} format="desktop" />
                    </span>
                  </a>

                  <div className={css.organizationInfo}>
                    <p className={css.organizationDescription}>
                      <FormattedMessage id="Footer.organizationDescription" />
                    </p>
                    <p className={css.organizationCopyright}>
                      {/* <NamedLink name="SearchPage" className={css.copyrightLink}> */}
                      <FormattedMessage id="Footer.copyright" />
                      <br />
                      <a href="mailto:support@softwaresupp.com">
                        <FormattedMessage id="Footer.mail" />
                      </a>
                      <br />
                      <FormattedMessage id="Footer.phone" />
                      <br />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://calendly.com/softwaresupp/software-consultation"
                      >
                        <FormattedMessage id="Footer.calendly" />
                      </a>
                      <br />
                      <>
                        <NamedLink name="NewListingExpertPage">
                          <FormattedMessage id="Footer.toNewListingPageExpert" />
                        </NamedLink>
                      </>
                    </p>
                  </div>
                  <div className="d-none d-md-block">{socialMediaLinks}</div>
                </Col>
                <Col xs={12} md={4}>
                  <p className={css.subscribe}>Subscribe to updates and discounts</p>
                  <div className={css.mailChimp}>
                    <Mailchimp
                      action="https://picksaas.us16.list-manage.com/subscribe/post?u=0a811ad254e7cd14718599e3a&amp;id=bdf0cfd955"
                      fields={[
                        {
                          name: 'EMAIL',
                          placeholder: 'Your email adress...',
                          type: 'email',
                          required: true,
                        },
                      ]}
                    />
                    <p className={css.subscriptionTerms}>
                      <FormattedMessage
                        id="Footer.subscriptionTerms"
                        values={{ terms: termsLink, privacy: privacyLink }}
                      />
                    </p>
                  </div>
                </Col>
                <Col className="d-block d-md-none" xs={12}>
                  {socialMediaLinks}
                </Col>
              </Row>
            </Container>
          </div>

          {/* <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href="https://softwaresupp.com" className={css.link}>
                    <FormattedMessage id="Footer.home" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/app" className={css.legalLink}>
                    <FormattedMessage id="Footer.browse" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/pricing" className={css.legalLink}>
                    <FormattedMessage id="Footer.pricing" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="NewListingExpertPage" className={css.legalLink}>
                    <FormattedMessage id="Footer.toNewListingPageExpert" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <a href="/softwaresupp-features" className={css.legalLink}>
                    <FormattedMessage id="Footer.forCustomers" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/softwaresupp-expert" className={css.legalLink}>
                    <FormattedMessage id="Footer.forFreelancers" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/softwaresupp-verification" className={css.legalLink}>
                    <FormattedMessage id="Footer.verificationProcess" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/softwaresupp-quality" className={css.legalLink}>
                    <FormattedMessage id="Footer.qualityMonitoring" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/escrow-payments" className={css.legalLink}>
                    <FormattedMessage id="Footer.escrowPayments" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/crm-integration-guide" className={css.legalLink}>
                    <FormattedMessage id="Footer.integrationGuide" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/ecommerce-integration-guide" className={css.legalLink}>
                    <FormattedMessage id="Footer.ecommerceGuide" />
                  </a>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href="/l/newJob" className={css.link}>
                    <FormattedMessage id="Footer.services" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/crm-experts" className={css.category}>
                    <FormattedMessage id={'Footer.crmSupport'} />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/pipedrive-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServicePipedriveConsulting" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/zoho-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceZohoConsulting" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/hubspot-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceHubspotConsulting" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/salesforce-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceSalesforce" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/ecommerce-experts" className={css.category}>
                    <FormattedMessage id={'Footer.eCommerce'} />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/shopify-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceShopifyConsulting" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/woocommerce-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceWoocommerceConsulting" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/magento-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceMagentoConsulting" />
                  </a>
                </li>
                
                <li className={css.listItem}>
                  <a href="/webflow-website-building" className={css.category}>
                    <FormattedMessage id={'Footer.websiteBuilding'} />
                  </a>
                </li>
                <li className={css.listItem}>
                <a href="/webflow-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceWebflow" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/wordpress-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceWordpress" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/chatbot-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceChatbot" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/l/newJob" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceSharetribe" />
                  </a>
                </li>

                {categoriesLinks[2]}
                <li className={css.listItem}>
                  <a href="/integration-experts" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceZapierIntegration" />
                  </a>
                </li>

                {categoriesLinks[3]}
                <li className={css.listItem}>
                  <a href="/l/newJob" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceMondayIntegration" values={{br: <br/>}}/>
                  </a>
                </li>
                
                {categoriesLinks[5]}
                <li className={css.listItem}>
                  <a href="/l/newJob" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceGSuite" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/l/newJob" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServicesOffice" />
                  </a>
                </li>

                {categoriesLinks[6]}
                <li className={css.listItem}>
                  <a href="/l/newJob" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceTwilio" />
                  </a>
                </li>
                {categoriesLinks[7]}
                {categoriesLinks[8]}
                <li className={css.listItem}>
                  <a href="/l/newJob" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceKlaviyo" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/l/newJob" className={css.legalLink}>
                    <FormattedMessage id="Footer.ServiceActiveCampaign" />
                  </a>
                </li>
              </ul>
            </div>
            <div className={css.searchesExperts}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href="/softwaresupp-features" className={css.link}>
                    <FormattedMessage id="Footer.experts" />
                  </a>
                </li>
                {expertsLinks}
                <li className={css.listItem} key={'googleAnalytics'}>
                  <NamedLink name="SearchPage_experts_software" className={css.softwareLink} params={{software: 'googleAnalytics'}}>
                    Google Analytics<br/>experts
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searchesJobs}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href="/softwaresupp-expert" className={css.link}>
                    <FormattedMessage id="Footer.jobs" />
                  </a>
                </li>
                {jobsLinks}
                <li className={css.listItem} key={'googleAnalytics'}>
                  <NamedLink name="SearchPage_jobs_software" className={css.softwareLink} params={{software: 'googleAnalytics'}}>
                    Google Analytics<br/>jobs
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li className={css.listItem}>
                    <NamedLink name="NewListingPage" className={css.link}>
                      <FormattedMessage id="Footer.newProject" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="LoginPage" className={css.terms}>
                      <FormattedMessage id="Footer.logIn" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      className={css.terms}
                      name='ExpertPage'
                      params={{
                        id: process.env.REACT_APP_ADMIN_BOOKING_LISTING_ID,
                        slug: createSlug('Michael'),
                        category: 'experts'
                      }}
                      state={{ openBookModal: true }}
                    >
                        <FormattedMessage id="Footer.bookCall" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <a target="_blank"
                      rel="noopener noreferrer"
                      href="/categories/customers"
                      className={css.terms}>
                      <FormattedMessage id="Footer.customers" />
                    </a>
                  </li>
                  <li className={css.listItem}>
                    <a href="/blog" className={css.terms}>
                      <FormattedMessage id="Footer.blog" />
                    </a>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="ReferralSystemPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.toReferral" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <a href="/careers" className={css.terms}>
                      <FormattedMessage id="Footer.career" />
                    </a>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div> */}
        </div>
        {/* <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="SearchPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyrightMobile" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div> */}
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
