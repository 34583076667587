import React from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
} from 'react-share';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import { createSlug } from '../../util/urlHelpers';
import { AiOutlineMail } from 'react-icons/ai';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

import css from './SectionShareMaybe.css';

const ICON_SIZE = 16;

const SectionShareMaybe = props => {
    const {
        isApproved,
        listingTitle,
        listingId,
        listingType,
        className
    } = props;

    const shareRootURL = config.shareRootURL;
    const title = createSlug(listingTitle);
    const id = listingId;
    let url, shareTitle, wrapperClass;
    switch (listingType) {
        case 'service':
            url = `${shareRootURL}/services/${title}/${id}`;
            shareTitle = `ServicePage.shareTitle`;
            wrapperClass = css.shareContentRegular;
            break;
        case 'job':
            url = `${shareRootURL}/jobs/${title}/${id}`;
            shareTitle = `ListingPage.shareTitle`;
            wrapperClass = css.shareContentRegular;
            break;
        case 'expert':
            url = `${shareRootURL}/experts/${title}/${id}`;
            shareTitle = `ExpertPage.shareTitle`;
            wrapperClass = css.shareContentAdjusted;
            break;
        default:
            break;
    }

    return isApproved ? (
        <div className={className}>
            <div className={wrapperClass}>
                <p className={css.shareTitle}>
                    <FormattedMessage id={shareTitle} />
                </p>

                <div className={css.shareButtonsPanel}>
                    <FacebookShareButton
                        url={url}
                        hashtag={'SoftwareSupp'}
                        className={css.shareButton}
                    >
                        <FaFacebookF className={css.shareIcon} size={ICON_SIZE - 2} />
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={url}
                        hashtags={['SoftwareSupp']}
                        className={css.shareButton}
                    >
                        <FaTwitter className={css.shareIcon} size={ICON_SIZE} />
                    </TwitterShareButton>

                    <LinkedinShareButton
                        url={url}
                        className={css.shareButton}
                    >
                        <FaLinkedinIn className={css.shareIcon} size={ICON_SIZE} />
                    </LinkedinShareButton>

                    <EmailShareButton
                        url={url}
                        className={css.shareButton}
                    >
                        <AiOutlineMail className={css.shareIcon} size={ICON_SIZE} />
                    </EmailShareButton>
                </div>
            </div>

        </div>)
    : null
}

export default SectionShareMaybe;