import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
const querystring = require('querystring');

export const apiBaseUrl = () => {
  const port = process.env.PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: below note refers to directory present in ftw-daily
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  try {
    return window.fetch(url, options).then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');

      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        return res.json().then(data => {
          let e = new Error();
          e = Object.assign(e, data);

          throw e;
        });
      }
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
  } catch (e) {
    console.log(e);
  }
};

const postWithToken = (path, token, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
      authorization: 'Bearer ' + token,
    },
    body: serialize(body),
  };
  try {
    return window.fetch(url, options).then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        return res.json().then(data => {
          let e = new Error();
          e = Object.assign(e, data);

          throw e;
        });
      }
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
  } catch (e) {
    console.log(e);
  }
};

const postData = (path, files, body, token) => {
  const url = `${apiBaseUrl()}${path}`;
  const formData = new FormData();
  // eslint-disable-next-line
  for (const file of files) {
    formData.append('files', file, file.name);
  }
  // eslint-disable-next-line
  for (let [key, value] of Object.entries(body)) {
    formData.append(key, value);
  }
  const options = {
    method: 'POST',
    credentials: 'include',
    body: formData,
  };
  if (!!token?.include) {
    options['headers'] = {
      authorization: 'Bearer ' + token?.userToken,
    };
  }

  return window.fetch(url, options).then(res => {
    return res.json();
  });
};

// can be used in the future
// eslint-disable-next-line
const get = (path, query) => {
  const queryString = querystring.stringify(query);
  const url = `${apiBaseUrl()}${path}?${queryString}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };
  return window
    .fetch(url, options)
    .then(res => {
      if (res.status >= 400) {
        const e = new Error('Local API request failed');
        e.apiResponse = res;
        throw e;
      }
      return res;
    })
    .then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
};

const uploadToS3 = async (path, data) => {

    // const url = `${apiBaseUrl()}${path}`;
    // let formData = new FormData();
    // formData.append("cvfile", data.target.files[0])
    // formData.append("name", "cvfile")
    // formData.append('prefix', 'cv')

    // const axiosRes = await axios.post(url, formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   }
    // })
    // return axiosRes
}

// sendgrid methods
export const sendMail = body => {
  return post('/api/send-mail', body);
};

export const sendTemplateMail = body => {
  return post('/api/send-template-mail', body);
};

export const cancelScheduledSend = body => {
  return post('/api/cancel-scheduled-mail', body);
};

// INTEGRATION API methods
export const incrementOffersCount = body => {
  return post('/api/incrementOffersCountOnJobListing', body);
};

export const approveListing = body => {
  return post('/api/approveListing', body);
};

export const queryAllowedUsers = body => {
  return post('/api/queryAllowedUsers', body);
};

export const queryProjectBudgets = (token, body) => {
  return postWithToken('/api/queryProjectBudgets', token, body);
};
export const updateProjectBudget = (token, body) => {
  return postWithToken('/api/updateProjectBudget', token, body);
};

export const updateProject = (token, body) => {
  return postWithToken('/api/updateProject', token, body);
};

export const inviteUser = body => {
  return post('/api/inviteUser', body);
};

export const fetchProject = body => {
  return post('/api/fetchProject', body);
};

export const fetchInvitedToProjectTransactions = body => {
  return post('/api/fetchInvitedToProjectTransactions', body);
};

export const addUserToTicket = body => {
  return post('/api/addUserToTicket', body);
};

export const sendTicketNotifications = body => {
  return post('/api/send-ticket-notifications', body);
};

export const addKeywordsToAdmin = body => {
  return post('/api/addKeywordsToAdmin', body);
};

export const triggerMail = body => {
  return post('/api/triggerMail', body);
};

export const queryOwnListings = body => {
  return post('/api/queryOwnListings', body);
};

export const queryUserBudgetInfo = body => {
  return post('/api/queryUserBudgetInfo', body);
};

export const changeTicketData = body => {
  return post('/api/changeTicketData', body);
};

export const changeProjectState = body => {
  return post('/api/changeProjectState', body);
};

export const closeJobAndCancelTransactions = body => {
  return post('/api/closeJobAndCancelTransactions', body);
};

export const addSubscription = body => {
  return post('/api/addSubscription', body);
};

export const answerSignupQuestion = body => {
  return post('/api/answerSignupQuestion', body);
};

export const addRepo = body => {
  return post('/api/addRepo', body);
};

export const sendUpvoteToExpert = body => {
  return post('/api/sendUpvoteToExpert', body);
};

export const updateVerifiedSoftwares = body => {
  return post('/api/updateVerifiedSoftwares', body);
};

export const negotiateOffer = body => {
  return post('/api/negotiateOffer', body);
};

export const editAdminOffer = body => {
  return post('/api/editAdminOffer', body);
};

export const acceptNegotiatedOffer = body => {
  return post('/api/acceptNegotiatedOffer', body);
};

export const includeOfferMetadata = body => {
  return post('/api/includeOfferMetadata', body);
};

export const sendOfferEmails = body => {
  return post('/api/sendOfferEmails', body);
};
export const addProjectTransactionIdToOffer = body => {
  return post('/api/addProjectTransactionIdToOffer', body);
};

export const getUserFullNames = body => {
  return post('/api/getUserFullNames', body);
};

export const getAllUserIds = body => {
  return post('/api/getAllUserIds', body);
};

export const generateToken = body => {
  return post('/api/generate-token', body);
};

export const updateProjectScope = body => {
  return post('/api/updateProjectScope', body);
};

export const updateUserSources = body => {
  return post('/api/updateUserSources', body);
};

export const sendSummaryOffer = body => {
  return post('/api/send-summary-offer', body);
};

export const addInboxTokenToUser = body => {
  return post('/api/db/inbox/addInboxTokenToUser', body);
};

export const updateContactsByTwowayBinding = body => {
  return post('/api/db/inbox/updateContactsByTwowayBinding', body);
};

export const examSaveAttempt = body => {
  return post('/api/examSaveAttempt', body);
};

export const examSaveResult = body => {
  return post('/api/examSaveResult', body);
};

export const updateInboxUserProfile = (token, body) => {
  return postWithToken('/api/db/inbox/updateUserProfile', token, body);
};

export const inboxGetUserDisplayNames = (token, body) => {
  return postWithToken('/api/db/inbox/getUserDisplayNames', token, body);
};

export const getExpertTitlesByUserIds = body => {
  return post('/api/getExpertTitlesByUserIds', body);
};

export const addSelectValue = body => {
  return post('/api/db/select/addSelectValue', body);
};

export const addMultipleSelectValues = body => {
  return post('/api/db/select/addMultipleSelectValues', body);
};

export const getAllSelectValues = body => {
  return post('/api/db/select/getAllSelectValues', body);
};

export const modifyApprovalForSelectValue = body => {
  return post('/api/db/select/modifyApprovalForSelectValue', body);
};

export const inboxReadMessageSummary = (token, body) => {
  return postWithToken('/api/db/inbox/readMessageSummary', token, body);
};

export const inboxReadConversation = (token, body) => {
  return postWithToken('/api/db/inbox/readConversation', token, body);
};

export const inboxSendMessage = (token, body) => {
  return postWithToken('/api/db/inbox/sendMessage', token, body);
};

export const inboxSendBulkMessage = (token, body) => {
  return postWithToken('/api/db/inbox/sendBulkMessage', token, body);
};

export const inboxUpdateContactsByTwowayBinding = (token, body) => {
  return postWithToken('/api/db/inbox/updateContactsByTwowayBinding', token, body);
};

export const inboxUpdateContactsForProject = (token, body) => {
  return postWithToken('/api/db/inbox/updateContactsForProject', token, body);
};

export const checkIfNewUserInvitedAndUpdateContacts = (token, body) => {
  return postWithToken('/api/db/inbox/checkIfNewUserInvitedAndUpdateContacts', token, body);
};

export const inboxCreateGroupAddMembers = (token, body) => {
  return postWithToken('/api/db/inbox/createGroupAddMembers', token, body);
};

export const inboxReadConversationGroupProfiles = (token, body) => {
  return postWithToken('/api/db/inbox/readConversationGroupProfiles', token, body);
};

export const inboxGetGroupMembers = (token, body) => {
  return postWithToken('/api/db/inbox/getGroupMembers', token, body);
};

export const getUserHasUnreadNotifications = (token, body) => {
  return postWithToken('/api/db/notifications/getUserHasUnreadNotifications', token, body);
};

export const inboxAddGroupMembers = (token, body) => {
  return postWithToken('/api/db/inbox/addGroupMembers', token, body);
};

export const inboxRemoveGroupMembers = (token, body) => {
  return postWithToken('/api/db/inbox/removeGroupMembers', token, body);
};

export const inboxSendDataMessage = (token, files, body) => {
  return postData('/api/db/inbox/sendMessageWithFiles', files, body, {
    include: true,
    userToken: token,
  });
};

export const uploadVerificationDocument = (token, files, body) => {
  return postData('/api/db/files/sendVerificationDocument', files, body, {
    include: true,
    userToken: token,
  });
};

export const sendNotification = (token, body) => {
  return postWithToken('/api/db/notifications/sendNotification', token, body);
};

export const getNotificationSummaryForUser = (token, body) => {
  return postWithToken('/api/db/notifications/getNotificationSummaryForUser', token, body);
};

export const markNotificationAsOpened = (token, body) => {
  return postWithToken('/api/db/notifications/markNotificationAsOpened', token, body);
};

export const sendBulkMessageText = (token, body) => {
  return postWithToken('/api/db/inbox/sendBulkMessageText', token, body);
};

export const sendBulkMessageData = (token, files, body) => {
  return postData('/api/db/inbox/sendBulkMessageData', files, body, {
    include: true,
    userToken: token,
  });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const facebookget = body => {
  return get('/api/auth/facebook', body);
};

export const getFavicon = body => {
  return post('/api/get-favicon', body);
};

export const generatePDF = body => {
  return post('/api/generate-pdf', body);
};

export const exportToCSV = body => {
  return post('/api/export-to-csv', body);
};

//payments

export const createStripeCustomer = body => {
  return post('/api/payments/customer', body);
};

export const createStripeViban = body => {
  return post('/api/payments/customer/viban', body);
};

export const getStripeCustomer = body => {
  return get('/api/payments/customer', body);
};

export const updateStripeCustomer = body => {
  return post('/api/payments/customer/update', body);
};

export const generateInvoice = body => {
  return post('/api/payments/invoice/generate', body);
};

export const getInvoice = body => {
  return get('/api/payments/invoice', body);
};

export const getUser = (token, body) => {
  return postWithToken('/api/queryUser', token, body);
};

export const generateNewUser = body => {
  return post('/api/auth/generateNewUser', body);
};

export const generateNewUser_async = body => {
  return post('/api/auth/generateNewUser_async', body);
};

export const adjustBalance = (token, body) => {
  return postWithToken('/api/payments/customer/balance', token, body);
};

export const getUserInvoices = body => {
  return post('/api/payments/invoice/list', body);
};

export const getUserPayouts = body => {
  return get('/api/payments/payouts/list', body);
};

export const connectCalendarGoogle = body => {
  return post('/api/google-calendar', body);
};

export const filterQueryExpert = body => {
  return post('/api/filter-query-expert', body);
};

export const filterQueryJob = body => {
  return post('/api/filter-query-job', body);
};


export const stripeInfo = (token, body) => {
  return postWithToken('/api/stripe-info', token, body);
};

export const userUpdate = (token, body) => {
  return postWithToken('/api/user-update', token, body);
};

export const ifUserExists = body => {
  return post('/api/user-if-exists', body);
};

export const getUserDataById = body => {
  return post('/api/get-user', body);
};


export const anonymization = (token, body) => {
  return postWithToken('/api/anonymization', token, body);
};

export const SendFormData = body => {
  let payload = { form: body };
  return post('/api/auto-formdata-save', payload);
};
export const RemoveFormData = body => {
  let payload = { form: body };
  return post('/api/auto-formdata-remove', payload);
};

export const s3uploadFile = body => {
  let payload = body;
  return uploadToS3('/api/upload', payload)
}

export const getExpertData = body => {
  let payload = body;
  return post('/api/get-expert', payload)
}
