import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { MdClose } from 'react-icons/md';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
// import { useLocalStorage } from '../../util/hooks';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes, LISTING_CATEGORY_EXPERT } from '../../util/types';
import { createSlug, LISTING_PAGE_PARAM_TYPE_EDIT } from '../../util/urlHelpers';
// import routeConfiguration from '../../routeConfiguration';
// import { createResourceLocatorString } from '../../util/routes';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  PrimaryButton,
} from '../../components';

import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserExpertListing,
    currentCategory,
    currentLocation,
    shouldDisplayNotificationDot,
    intl,
    isAuthenticated,
    onLogout,
    userHasBalance,
    forceRerender,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [referralVisible, setReferralVisible] = useState(true);
  // if (typeof window !== 'undefined') {
  //   setReferralVisible(window.localStorage.getItem('referralVisible'))
  // }

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const expertListingId = currentUserExpertListing ? currentUserExpertListing.id.uuid : null;
  const expertListingSlug = currentUserExpertListing
    ? createSlug(currentUserExpertListing.attributes.title)
    : 'No Expert Listing';
  const currentUserHasStripeAccounts =
    currentUser?.attributes.profile.privateData.stripeAccount || currentUser?.stripeCustomer;
  const classes = classNames(rootClassName || css.root, className);

  const notificationDot = shouldDisplayNotificationDot ? (
    <div className={css.notificationDot} />
  ) : null;
  let centerTab = 'getStarted';
  if (currentUserHasExpertListing) {
    centerTab = 'orders';
  } else if (currentUserHasListings) {
    centerTab = null;
  }

  const centerLink = authenticatedOnClientSide ? (
    <NamedLink
      className={
        currentPage && currentPage.includes('Center') ? css.topbarLinkActive : css.signupLink
      }
      name={currentUserHasListings ? 'NotificationsPage' : 'CenterPage'}
      params={{ tab: centerTab }}
    >
      <span className={css.center}>
        <FormattedMessage id="TopbarDesktop.center" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenuExpertListingLink =
    !currentUserHasExpertListing && !currentUserHasUnpublishedExpertListing ? (
      <MenuItem key="NewListingExpertPage">
        <NamedLink
          className={classNames(
            css.createListingExpertLink,
            currentPageClass('NewListingExpertPage')
          )}
          name="NewListingExpertPage"
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.createListingExpert" />
        </NamedLink>
      </MenuItem>
    ) : (
      <MenuItem key="NewListingExpertPage">
        <NamedLink
          className={classNames(
            css.createListingExpertLink,
            currentPageClass('NewListingExpertPage')
          )}
          name="EditListingPage"
          params={{
            id: expertListingId,
            slug: expertListingSlug,
            type: LISTING_PAGE_PARAM_TYPE_EDIT,
            tab: 'description',
            category: LISTING_CATEGORY_EXPERT,
          }}
          category={LISTING_CATEGORY_EXPERT}
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.editListingExpert" />
        </NamedLink>
      </MenuItem>
    );

  // Przycisk tworzenia servisu w TopBar
  const profileMenuServiceListingLink =
    currentUserHasExpertListing || currentUserHasUnpublishedExpertListing ? (
      <MenuItem key="NewServicePage">
        <NamedLink
          className={classNames(css.createListingExpertLink, currentPageClass('NewServicePage'))}
          name="NewServicePage"
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.createService" />
        </NamedLink>
      </MenuItem>
    ) : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="Get started">
          <NamedLink
            className={classNames(css.yourListingsLink)}
            name="CenterPage"
            params={{ tab: 'getStarted' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.onboarding" />
          </NamedLink>
        </MenuItem>
        {currentUserHasStripeAccounts ? (
          <MenuItem key="Billing">
            <NamedLink
              className={classNames(css.yourListingsLink)}
              name="BillingPage"
              params={{ tab: 'billingPage' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="BillingPage.title" />
            </NamedLink>
          </MenuItem>
        ) : null}
        <MenuItem key="Subscription">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('SubscriptionPage'))}
            name="SubscriptionPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.subscriptionLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ReferralSystem">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ReferralSystemPage'))}
            name="ReferralSystemPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.referralSystemLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        {profileMenuExpertListingLink}
        {/* {profileMenuServiceListingLink} */}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="LoginPage"
      className={
        currentLocation?.pathname.includes('login') || currentLocation?.pathname.includes('signup')
          ? css.topbarLinkActive
          : css.signupLink
      }
    >
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const postJobLink = !isAuthenticatedOrJustHydrated ? (
    <PrimaryButton className={css.ctaButton}>
    <span onClick={() => {
          window.open('https://calendly.com/softwaresupp/software-consultation', '_blank');
        }}>
      Hire Talent
    </span>
    </PrimaryButton>
  ) : null;

  const projectsLink = (
    <NamedLink
      className={currentCategory?.includes('job') ? css.topbarLinkActive : css.signupLink}
      name="SearchPage_jobs"
    >
      <span className={css.center}>
        <FormattedMessage id="TopbarDesktop.jobs" />
      </span>
    </NamedLink>
  );

  const expertsLink = (
    <NamedLink
      className={currentCategory?.includes('expert') ? css.topbarLinkActive : css.signupLink}
      name="SearchPage_experts"
    >
      <span className={css.center}>
        <FormattedMessage id="TopbarDesktop.experts" />
      </span>
    </NamedLink>
  );

  const bookLink = !isAuthenticatedOrJustHydrated ? (
    <span className={css.signupLink}>
      <span
        className={classNames(css.bookLink)}
        onClick={() => {
          window.open('https://calendly.com/softwaresupp/software-consultation', '_blank');
        }}
      >
        <FormattedMessage id="TopbarDesktop.book" />
      </span>
    </span>
  ) : null;

  const supportPlansLink = (
    <NamedLink name="SubscriptionPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.pricing" />
      </span>
    </NamedLink>
  );

  const billing_page = (
    <NamedLink className={css.signupLink} name="BillingPage" params={{ tab: 'billingPage' }}>
      <span className={css.signup}>
        <FormattedMessage id="BillingPage.title" />
      </span>
    </NamedLink>
  );

  const logoComponent = authenticatedOnClientSide ? (
    <NamedLink className={css.logoLink} name="SearchPage">
      <Logo
        format="desktop"
        className={css.logo}
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
      />
    </NamedLink>
  ) : (
    <a href="/" className={css.logoLink}>
      <Logo
        format="desktop"
        className={css.logo}
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
      />
    </a>
  );

  const slogan = (
    <span className={css.subtitle}>
      <FormattedMessage id="TopbarDesktop.slogan" />
    </span>
  );

  const referralBeam = referralVisible ? (
    <div className={css.referral}>
      <a className={css.referralText} href="https://calendly.com/softwaresupp/software-consultation" target="_blank">
        Book a call, get started with SoftwareSupport.
      </a>
      <MdClose
        className={css.closeIcon}
        onClick={() => {
          forceRerender();
          setReferralVisible(false);
        }}
      />
    </div>
  ) : null;

  const userIsAdmin = currentUser?.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;
  if (userIsAdmin || currentUser?.attributes.profile.publicData?.isPremiumUser) {
    return (
      <nav className={css.nav}>
        {currentPage === 'SearchPage' ? referralBeam : null}
        <ul className={classNames(css.list, classes)}>
          <li key="uniqueNavLinkKey1" className={classNames(css.listElement)}>
            {logoComponent}
          </li>
          <li key="uniqueNavLinkKey2" className={classNames(css.listElement, css.marginR)}>
            {slogan}
          </li>

          <li key="uniqueNavLinkKey4" className={classNames(css.listElement)}>
            {projectsLink}
          </li>

          <li key="uniqueNavLinkKey5" className={classNames(css.listElement)}>
            {expertsLink}
          </li>
          {/* {currentUser ? (
            <li key="uniqueNavLinkKey6" className={classNames(css.listElement)}>
              {servicesListingsLink}
            </li>
          ) : null} */}
          {currentUserHasStripeAccounts ? (
            <li key="uniqueNavLinkKey7" className={classNames(css.listElement)}>
              {billing_page}
            </li>
          ) : null}

          <li key="uniqueNavLinkKey8" className={classNames(css.listElement)}>
            {supportPlansLink}
          </li>

          <li key="uniqueNavLinkKey9" className={classNames(css.listElement)}>
            {bookLink}
          </li>
          <li key="uniqueNavLinkKey10" className={classNames(css.listElement)}>
            {centerLink}
          </li>
          <li key="uniqueNavLinkKey11" className={classNames(css.listElement)}>
            {profileMenu}
          </li>
          <li key="uniqueNavLinkKey12" className={classNames(css.listElement)}>
            {loginLink}
          </li>
          <li key="uniqueNavLinkKey13" className={classNames(css.listElement)}>
            {postJobLink}
          </li>
        </ul>
      </nav>
    );
  } else {
    return (
      <nav className={css.nav}>
        {currentPage === 'SearchPage' ? referralBeam : null}
        <ul className={classNames(css.list, classes)}>
          <li key="uniqueNavLinkKey1" className={classNames(css.listElement)}>
            {logoComponent}
          </li>
          <li key="uniqueNavLinkKey2" className={classNames(css.listElement, css.marginR)}>
            {slogan}
          </li>

          {currentUserHasExpertListing && userHasBalance ? (
            <li key="uniqueNavLinkKey3" className={classNames(css.listElement)}>
              {projectsLink}
            </li>
          ) : null}
          {/* {currentUser ? (
            <li key="uniqueNavLinkKey4" className={classNames(css.listElement)}>
              {servicesListingsLink}
            </li>
          ) : null} */}
          {currentUserHasStripeAccounts ? (
            <li key="uniqueNavLinkKey5" className={classNames(css.listElement)}>
              {billing_page}
            </li>
          ) : null}

          <li key="uniqueNavLinkKey6" className={classNames(css.listElement)}>
            {supportPlansLink}
          </li>

          <li key="uniqueNavLinkKey7" className={classNames(css.listElement)}>
            {bookLink}
          </li>
          <li key="uniqueNavLinkKey8" className={classNames(css.listElement)}>
            {centerLink}
          </li>
          <li key="uniqueNavLinkKey9" className={classNames(css.listElement)}>
            {profileMenu}
          </li>
          <li key="uniqueNavLinkKey10" className={classNames(css.listElement)}>
            {loginLink}
          </li>
          <li key="uniqueNavLinkKey11" className={classNames(css.listElement)}>
            {postJobLink}
          </li>
        </ul>
      </nav>
    );
  }
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
