import React, { useState, useEffect, useRef } from 'react';
import css from './InfoSidePanel.css';
import classNames from 'classnames';

import PanelExpertPage from './panel-pages/PanelExpertPage';
import { EXPERT_DATA } from './panel-pages/PanelExpertData';

const InfoSidePanel = props => {

    const [panelPageNumber, setPanelPageNumber] = useState(0);
    const [fadeIn, setFadeIn] = useState(false);
    const [fadeOut, setFadeOut] = useState();
    const [fadeDirection, setFadeDirection] = useState('');

    const minPageNumber = 0;
    const maxPageNumber = EXPERT_DATA.length-1;
    const proofPageRef = useRef(panelPageNumber);
    proofPageRef.current = panelPageNumber;

    useEffect(() => {
        // set random page for start
        const randomTestimonial = Math.round(Math.random() * maxPageNumber) + minPageNumber;
        
        setPanelPageNumber(randomTestimonial);
      },[maxPageNumber]);

    const animateChangePageNumber = (nextPage, direction) => {
        setFadeDirection(direction);
        setFadeOut(true);
        setTimeout(() => {
            setFadeOut(false);
            setPanelPageNumber(nextPage);
            setFadeIn(true);
            setTimeout(() => {
                setFadeIn(false);
            }, 400);
        }, 400);
    }

    const handleLeftButton = () => {
        const minPageNumber = 0;
        if(panelPageNumber-1 >= minPageNumber) {
            animateChangePageNumber(panelPageNumber-1, 'left');
        } else if(panelPageNumber-1 < minPageNumber) {
            animateChangePageNumber(maxPageNumber, 'left');
        }
    }

    const handleRightButton = () => {
        const maxPageNumber = EXPERT_DATA.length-1;
        if(panelPageNumber+1 <= maxPageNumber) {
            animateChangePageNumber(panelPageNumber+1, 'right');
        } else if(panelPageNumber+1 > maxPageNumber) {
            animateChangePageNumber(0, 'right');
        }
    }

    return (
        <div className={classNames(props.projectForm ? css.sidePanelProjectForm : css.sidePanel)}>
            <div className={css.sidePanelWrapper}>
                <div 
                    className={classNames(
                        css.sideContent, 
                        props.projectForm && css.sideContentNotFixed,
                        props.isBeamVisible && css.sideContentWithBeam
                    )}>
                    {
                        <PanelExpertPage 
                            pageNumber={panelPageNumber} 
                            handleLeftButton={handleLeftButton} 
                            handleRightButton={handleRightButton} 
                            fadeIn={fadeIn} 
                            fadeOut={fadeOut} 
                            fadeDirection={fadeDirection} 
                            projectForm={props.projectForm} 
                        />
                    }
                
                </div>
            </div>
        </div>
    );
};

export default InfoSidePanel;