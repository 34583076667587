import pick from 'lodash/pick';
import config from '../../config';
import { storableError } from '../../util/errors';
import { 
  PRE_TRANSITION_SEND_OFFER, 
 } from '../../util/transaction';
import { fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SEND_OFFER_REQUEST = 'app/ManageListingsPage/SEND_OFFER_REQUEST';
export const SEND_OFFER_SUCCESS = 'app/ManageListingsPage/SEND_OFFER_SUCCESS';
export const SEND_OFFER_ERROR = 'app/ManageListingsPage/SEND_OFFER_EXPERT';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  id: null,
  fetchedExpertProfileForService: null,
  fetchedExpertServices: null,
  enquiryModalOpenForListingId: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };
    case SEND_OFFER_REQUEST:
      return { ...state, sendOfferInProgress: true, sendOfferError: null };
    case SEND_OFFER_SUCCESS:
      return { ...state, sendOfferInProgress: false };
    case SEND_OFFER_ERROR:
      return { ...state, sendOfferInProgress: false, sendOfferError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const sendOfferRequest = () => ({ type: SEND_OFFER_REQUEST });
export const sendOfferSuccess = () => ({ type: SEND_OFFER_SUCCESS });
export const sendOfferError = e => ({ type: SEND_OFFER_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const sendOffer = (listingId, price) => (dispatch, getState, sdk) => {
  dispatch(sendOfferRequest());
  const bodyParams = {
    transition: PRE_TRANSITION_SEND_OFFER,
    processAlias: config.preBookingProcessAlias,
    params: {
      listingId,
      negotiatedTotal: price
    },
  };

  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;
      dispatch(sendOfferSuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return transactionId;
    })
    .catch(e => {
      dispatch(sendOfferError(storableError(e)));
      throw e;
    });
};