import React from 'react';
import dayjs from 'dayjs';
import css from './BillingPage.css';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import { injectIntl } from 'react-intl';

const { Money } = types;

const PayoutsPanel = ({ payouts, intl }) => {

  let totalAmount = 0;
  payouts.forEach(payout => {
    totalAmount += payout.amount;
  });
  return (
    <div className={css.adjustmentsWrapper}>
      <h3>Payouts total: {formatMoney(intl, new Money(totalAmount * -1, 'USD'))}</h3>
      <ul>
        {payouts.map(payout => {
          const amount = formatMoney(intl, new Money(payout.amount * -1, payout.currency.toUpperCase()));
          return (
            <li key={payout.id} className={css.adjustmentsItems}>
              <div>
                Date: {dayjs.unix(payout.created).format('MMM D, YYYY')}
              </div>
              <div>
                Amount: {amount}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default injectIntl(PayoutsPanel);
