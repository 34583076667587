import React from 'react';
import { AvatarMedium } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';

import css from './ListingPage.css';

const SectionOffersMaybe = props => {
  const { offers, offersLoading, history } = props;

  return (
    <div className={css.offersContainer}>
      <div className={css.offerSectionTitle}><FormattedMessage id="ListingPage.offersTitle" /></div>
      {offers?.length > 0 ?
        offers.map(offer => {
          const offerId = offer.id.uuid;
          const offerAuthorName = offer.customer.attributes.profile.displayName?.split(' ')[0];
          const price = offer.attributes.payinTotal.amount/100;
          const date = offer.attributes.createdAt.toString().slice(4,10);

          return offerAuthorName ? (
            <div 
              className={css.offerCard}
              onClick={() => {
                return history.push(createResourceLocatorString(
                  'SaleDetailsPage',
                  routeConfiguration(), 
                  {id: offerId}, 
                  {})
                ) 
              }}
            >
              <div className={css.offerCardFirstRow}>
                <div className={css.offerCardInfo}>
                  <AvatarMedium
                      user={offer.customer}
                      className={css.avatarOffer}
                      // initialsClassName={css.avatarOffer}
                      disableProfileLink
                    />
                  <div className={css.offerCardAuthorName}>
                    {offerAuthorName}
                    <div className={css.offerDate}>
                      <FormattedMessage id="ListingPage.offerDate" values={{date: date}}/>
                    </div>
                    <div className={css.offerPriceValue}>${price}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : null;
        })
      : !offersLoading ?
        <div className={css.noOffers}>
          <FormattedMessage id="ListingPage.noOffersYet"/>
        </div>
      : 
        <div className={css.noOffers}>
          <FormattedMessage id="ListingPage.loadingOffers"/>
        </div>
      }
    </div>
  );
};

export default SectionOffersMaybe;