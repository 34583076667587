import React, { Component } from 'react';
import { array, bool, func, number, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  InfoSidePanel, 
  SearchMap, 
  ModalInMobile,
  LayoutWrapperFooter,
  Footer,
  Page
 } from '../../components';
import { TopbarContainer } from '../../containers';
import { addKeywordsToAdmin } from '../../util/api';

// import marketplaceFacebookImage from '../../assets/softwaresuppMarketplace-1200x630.png';
// import marketplaceTwitterImage from '../../assets/softwaresuppMarketplace-600x314.png';

import { searchListings, searchMapListings, setActiveListing, saveUserKeywordSearch } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.css';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SHOW_MAP_BREAKPOINT = 1024; // Map is not shown by default on screen below 1024px width.
const SHOW_SIDE_PANEL_BREAKPOINT = 1280; // InfoSidePanel is not shown on screen below 1280px width.
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export class SearchPageComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isSearchMapOpenOnDesktop: false,
      isMobileModalOpen: false,
      isSocialProofOpen: true,
      isSocialProofClosedInSession: false,
      isBeamVisible: true,
      forceRerender: 0,
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      searchUserQuery: [],
    };
    this.searchMapListingsInProgress = false;
    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.forceRerender = this.forceRerender.bind(this);
    this.handleNewKeywordSearch = this.handleNewKeywordSearch.bind(this);
  }

  handleResize = () => this.setState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });

  componentDidMount() {
    this.handleResize(); // initial state width and height
    window.addEventListener('resize', this.handleResize);
    let isClosedInSession = false;
    if(typeof window !== 'undefined' && window.sessionStorage) {
      isClosedInSession = window.sessionStorage.getItem('sessionSoftwareSuppSocialProofClosed');
    }
    isClosedInSession = isClosedInSession ? true : false; 
    this.setState({
      isSocialProofClosedInSession: isClosedInSession,
    });
    let isBeamVisible = true;
    if(typeof window !== 'undefined' && window.localStorage) {
      isBeamVisible = window.localStorage.getItem('referralVisible');
    }
    isBeamVisible = isBeamVisible ? false : true; 
    this.setState({
      isBeamVisible: isBeamVisible,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
            
    if (prevState.forceRerender !== this.state.forceRerender)
    {
      let isBeamVisible = true;
      if(typeof window !== 'undefined' && window.localStorage) {
        isBeamVisible = window.localStorage.getItem('referralVisible');
      }
      isBeamVisible = isBeamVisible ? false : true; 
      this.setState({
        isBeamVisible: isBeamVisible,
      });
    }
  }

  forceRerender() {
    this.setState({
      forceRerender: this.forceRerender + 1,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  filters() {
    const {
      certificateConfig,
      productSoftware: softwareConfig,
      productSkills: skillsConfig,
      productIndustries: industriesConfig,
      scaleConfig,
      priceFilterConfig,
      projectsCountFilterConfig,
      experienceFilterConfig,
      paidFilterConfig,
      keywordFilterConfig,
      dateRangeLengthFilterConfig,
      estimatedTimeFilterConfig,
      serviceCategoriesFilterConfig,
      languageFilterConfig
    } = this.props;
    // Note: "certificate" and "yogaStyles" filters are not actually filtering anything by default.
    // Currently, if you want to use them, we need to manually configure them to be available
    // for search queries. Read more from extended data document:
    // https://www.sharetribe.com/docs/references/extended-data/#data-schema

    return {
      priceFilter: {
        paramName: 'price',
        config: priceFilterConfig,
      },
      paidFilter: {
        paramName: 'paid',
        config: paidFilterConfig,
      },
      dateRangeLengthFilter: {
        paramName: 'dates',
        minDurationParamName: 'minDuration',
        config: dateRangeLengthFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
      experienceFilter: {
        paramName: 'pub_experience',
        config: experienceFilterConfig,
      },
      projectsCountFilter: {
        paramName: 'pub_projectsCount',
        config: projectsCountFilterConfig,
      },
      certificateFilter: {
        paramName: 'pub_certificate',
        options: certificateConfig.filter(c => !c.hideFromFilters),
      },
      yogaStylesFilter: {
        paramName: 'pub_softwares',
        options: softwareConfig,
      },
      skillsFilter: {
        paramName: 'pub_skills',
        options: skillsConfig,
      },
      industriesFilter: {
        paramName: 'pub_industries',
        options: industriesConfig,
      },
      scaleFilter: {
        paramName: 'pub_scale',
        options: scaleConfig,
      },
      estimatedTimeFilter: {
        paramName: 'pub_estimatedTime',
        config: estimatedTimeFilterConfig,
      },
      serviceCategoriesFilter: {
        paramName: 'pub_serviceCategories',
        config: serviceCategoriesFilterConfig,
      },
      languageFilter: {
        paramName: 'pub_languages',
        config: languageFilterConfig,
      },
    };
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    // Commented intentionally, maybe one day it will be needed 
    /* 
      const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath; 
    */

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged) {
      const { history, location } = this.props;

      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });
      const pathNameArray = location.pathname.split('/')
      const software = pathNameArray[2] ? pathNameArray[2] : null;

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const url = location.pathname.toString().split('/');
      const category =
        url.includes('experts') ?
          'expert' :
          url.includes('jobs') ?
            'job' :
              url.includes('services') ?
                'service' :
                null;

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, this.filters()),
      };
      software ?
      history.push(createResourceLocatorString(`SearchPage_${category}s_software`, routeConfiguration(), {software: software}, searchParams))
      :
      category ?
      history.push(createResourceLocatorString(`SearchPage_${category}s`, routeConfiguration(), {}, searchParams))
      :
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
      }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  onCloseSocialProofModal() {
    this.setState({ isSocialProofOpen: false });
    this.setState({ isSocialProofClosedInSession: true});
    if(typeof window !== 'undefined' && window.sessionStorage) {
      window.sessionStorage.setItem('sessionSoftwareSuppSocialProofClosed', true);
    }
  }

  handleNewKeywordSearch(values) {
    const { onSaveUserKeywordSearch, currentUser, history } = this.props;
    let data = [[values, ' ' + new Date().toString().slice(0, 24)]];
    if (!currentUser) {
      addKeywordsToAdmin({ userId: process.env.REACT_APP_ADMIN_USER_ID, keywords: data }).then(() =>
        history.push(createResourceLocatorString('NewListingPage', routeConfiguration(), {}, {}), {
          from: createResourceLocatorString(
            'SearchPage',
            routeConfiguration(),
            {},
            { keywords: values }
          ),
        })
      );
    }
    else {
      if (this.state.paramsQueryString)
        data = data.concat(this.state.paramsQueryString);
      else if (currentUser.attributes.profile.publicData.keywords)
        data = data.concat(currentUser.attributes.profile.publicData.keywords);
      if (data.length > 20)
        data.pop();
      this.setState({ paramsQueryString: data })
      onSaveUserKeywordSearch(data);
    }
  }

  render() {
    const {
      intl,
      listings,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      currentUserExpertListing,
      currentUser,
      currentUserStripeAccounts,
      productSoftware,
      productSkills,
      productIndustries,
      fetchingExpertListingInProgress
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, sort, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const filters = this.filters();

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;
    // const canonicalRootURL = config.canonicalRootURL;

    // const facebookImages = [
    //   {
    //     name: 'facebook',
    //     url: `${canonicalRootURL}${marketplaceFacebookImage}`,
    //     width: 1200,
    //     height: 630,
    //   },
    // ];
    // const twitterImages = [
    //   {
    //     name: 'twitter',
    //     url: `${canonicalRootURL}${marketplaceTwitterImage}`,
    //     width: 600,
    //     height: 314,
    //   },
    // ];

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);

    const isMobileLayout = this.state.windowWidth < MODAL_BREAKPOINT;
    const isMediumScreen = this.state.windowWidth < SHOW_MAP_BREAKPOINT;
    const isMediumLargeScreen = this.state.windowWidth < SHOW_SIDE_PANEL_BREAKPOINT;
    const shouldShowSearchMap =
      (!isMobileLayout && this.state.isSearchMapOpenOnDesktop) || (isMobileLayout && this.state.isSearchMapOpenOnMobile) || (isMediumScreen && this.state.isSearchMapOpenOnMobile);
    const shouldShowSidePanel = !isMobileLayout && !isMediumScreen && !isMediumLargeScreen;
    
    const onMapDesktopIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState((prevState) => ({ isSearchMapOpenOnDesktop: !prevState.isSearchMapOpenOnDesktop }));
    }

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParams,
      intl,
      productSoftware,
      productSkills,
      productIndustries,
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;


    const url = location.pathname.toString().split('/');
    const category =
    url.includes('experts') ?
      'expert' :
      url.includes('jobs') ?
        'job' :
          url.includes('services') ?
            'service' :
            null;
    
    const software = searchParams ? searchParams.pub_software : null;
    
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */

    console.log(title)
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
        // facebookImages={facebookImages}
        // twitterImages={twitterImages}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentCategory={category}
          currentSearchParams={urlQueryParams}
          forceRerender={this.forceRerender}
          saveUserKeywordSearch={this.handleNewKeywordSearch}
        />
        <div className={this.state.isBeamVisible === true ? css.containerWithBeam : css.container}>
          <MainPanel
            urlQueryParams={validQueryParams}
            sort={sort}
            listings={listings}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            onMapIconClick={onMapIconClick}
            onMapDesktopIconClick={onMapDesktopIconClick}
            pagination={pagination}
            searchParamsForPagination={location}
            showAsModalMaxWidth={SHOW_MAP_BREAKPOINT}
            isMobileLayout={isMobileLayout}
            isMediumScreen={isMediumScreen}
            currentUserStripeAccounts={currentUserStripeAccounts}
            productSoftware={productSoftware}
            productSkills={productSkills}
            productIndustries={productIndustries}
            primaryFilters={{
              priceFilter: filters.priceFilter,
              paidFilter: filters.paidFilter,
              dateRangeLengthFilter: filters.dateRangeLengthFilter,
              keywordFilter: filters.keywordFilter,
              experienceFilter: filters.experienceFilter,
              projectsCountFilter: filters.projectsCountFilter,
              yogaStylesFilter: filters.yogaStylesFilter,
              estimatedTimeFilter: filters.estimatedTimeFilter,
              serviceCategoriesFilter: filters.serviceCategoriesFilter,
              languageFilter: filters.languageFilter,
            }}
            secondaryFilters={{
              skillsFilter: filters.skillsFilter,
              certificateFilter: filters.certificateFilter,
              industriesFilter: filters.industriesFilter,
            }}
            category={category}
            software={software}
            currentUserExpertListing={currentUserExpertListing ? currentUserExpertListing : null}
            currentUser={currentUser ? currentUser : null}
            saveUserKeywordSearch={this.handleNewKeywordSearch}
            canShowResults={fetchingExpertListingInProgress === false}
          />
          {shouldShowSearchMap ? (
            <ModalInMobile
              className={css.mapPanel}
              id="SearchPage.map"
              isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
              onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
              showAsModalMaxWidth={SHOW_MAP_BREAKPOINT}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.mapWrapper}>
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                  location={location}
                  listings={mapListings || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                />
              </div>
            </ModalInMobile>
          ) : shouldShowSidePanel ? (
            <InfoSidePanel
              isBeamVisible={this.state.isBeamVisible}
              showLightbox={this.props.showLightbox}
            />
          ) : null}
        </div>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  certificateConfig: config.custom.certificate,
  priceFilterConfig: config.custom.priceFilterConfig,
  paidFilterConfig: config.custom.paidFilterConfig,
  estimatedTimeFilterConfig: config.custom.estimatedTimeFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  experienceFilterConfig: config.custom.experienceFilterConfig,
  projectsCountFilterConfig: config.custom.projectsCountFilterConfig,
  serviceCategoriesFilterConfig: config.custom.serviceCategoriesConfig,
  languageFilterConfig: config.custom.languageConfig,
  dateRangeLengthFilterConfig: config.custom.dateRangeLengthFilterConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  certificateConfig: array,
  skillsConfig: array,
  serviceCategoriesFilterConfig: object,
  languageFilterConfig: object,
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  experienceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  projectsCountFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  paidFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  estimatedTimeFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  dateRangeLengthFilterConfig: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage;
  const {
    currentUserExpertListing,
    currentUser,
    currentUserStripeAccounts,
    fetchingExpertListingInProgress
  } = state.user;
  const {
    productSoftware,
    productSkills,
    productIndustries,
  } = state.marketplaceData;

  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentUserExpertListing,
    currentUser,
    currentUserStripeAccounts,
    fetchingExpertListingInProgress,
    productSoftware,
    productSkills,
    productIndustries,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onSaveUserKeywordSearch: (keywords) => dispatch(saveUserKeywordSearch(keywords)),
  onSearchListings: (searchQuery, queryResults) => dispatch(searchListings(searchQuery, queryResults))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search, pathname) => {
  const pub_software = params.software;
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const url = pathname.toString().split('/')
  const category =
  url.includes('experts') ?
    'expert' :
    url.includes('jobs') ?
      'job' :
        url.includes('services') ?
          'service' :
          null;

  return searchListings({
    ...rest,
    ...originMaybe,
    category,
    pub_software,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'author.profileImage', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'createdAt'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x','variants.square-small'],
    'fields.profileImage': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default SearchPage;
