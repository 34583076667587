import React from 'react';
import Chip from '../../components/Chip/Chip';
import { HighlightedChip, PrimaryChip } from '../../components/Chip/Chip';
import { NamedLink } from '../../components';
import { LISTING_CATEGORY_EXPERT, LISTING_CATEGORY_SERVICE } from '../../util/types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './ListingCard.css';

const SectionSoftwaresMaybe = props => {
  const {
    category,
    softwares,
    urlQueryParams,
    searchedSoftware,
    serviceCategories,
    skills,
    industries,
    productSoftware,
    productSkills,
    productIndustries,
  } = props;
  const searchedSoftwares = searchedSoftware
    ? new Array(searchedSoftware)
    : urlQueryParams
    ? urlQueryParams['pub_softwares']
    : undefined;
  const searchedSoftwareLabels = searchedSoftwares
    ? productSoftware.filter(s => searchedSoftwares.includes(s.key))
    : undefined;
  const slicedSoftwares = softwares ? softwares.slice(0, 9) : [];
  const softwaresLabels = productSoftware.filter(s => slicedSoftwares.includes(s.key));
  const slicedSkills = skills ? skills.slice(0, 9) : [];
  const skillsLabels = productSkills.filter(s => slicedSkills.includes(s.key));
  const slicedIndustries = industries ? industries.slice(0, 9) : [];
  const industriesLabels = productIndustries.filter(s => slicedIndustries.includes(s.key));

  const renderSoftwares = () => {
    let softwaresList = [];
    let searchLabelsList = [];
    let highlightedSoftwareLabels = [];
    if (searchedSoftwareLabels) {
      searchLabelsList = searchedSoftwareLabels.map(s => s.label);
      highlightedSoftwareLabels = searchedSoftwareLabels.map(software => {
        return (
          <NamedLink
            name={`SearchPage_${category}s`}
            to={{
              search: `pub_softwares=${software.key}`,
            }}
          >
            {' '}
            <HighlightedChip key={'h' + software.label}>{software.label}</HighlightedChip>
          </NamedLink>
        );
      });
    }
    const regularSoftwareLabels = softwaresLabels.map(software => {
      if (!searchLabelsList.includes(software.label)) {
        return (
          <NamedLink
            name={`SearchPage_${category}s`}
            to={{
              search: `pub_softwares=${software.key}`,
            }}
          >
            <Chip key={software.label}>{software.label}</Chip>
          </NamedLink>
        );
      }
      return null;
    });
    softwaresList = searchedSoftwareLabels
      ? [...highlightedSoftwareLabels, ...regularSoftwareLabels]
      : [...regularSoftwareLabels];
    return softwaresList;
  };

  const renderServiceCategories = () => {
    let serviceCategoriesList = [];
    if (category === LISTING_CATEGORY_SERVICE && serviceCategories?.length > 0) {
      serviceCategoriesList = serviceCategories.map((item, index) => {
        return <PrimaryChip key={'serviceCategory' + index}>{item}</PrimaryChip>;
      });
    }
    return serviceCategoriesList;
  };

  const renderSkills = () => {
    let skillsList = [];
    if (category === LISTING_CATEGORY_EXPERT && skillsLabels?.length > 0) {
      skillsList = skillsLabels.map(item => {
        return (
          <NamedLink
            name={`SearchPage_experts`}
            to={{
              search: `pub_skills=${item.key}`,
            }}
          >
            <PrimaryChip key={item.label}>{item.label}</PrimaryChip>
          </NamedLink>
        );
      });
    }
    return skillsList;
  };

  const renderIndustries = () => {
    let industriesList = [];
    if (category === LISTING_CATEGORY_EXPERT && industriesLabels?.length > 0) {
      industriesList = industriesLabels.map((item, index) => {
        return (
          <NamedLink
            name={`SearchPage_experts`}
            to={{
              search: `pub_industries=${item.key}`,
            }}
          >
            <HighlightedChip key={item.label}>{item.label}</HighlightedChip>
          </NamedLink>
        );
      });
    }
    return industriesList;
  };

  return softwares ? (
    <div className={css.sectionSoftwares}>
      {[...renderSoftwares(), ...renderSkills(), ...renderServiceCategories(), ...renderIndustries()]}
    </div>
  ) : null;
};

const mapStateToProps = state => {
  const { productSoftware, productSkills, productIndustries } = state.marketplaceData;
  return {
    productSoftware,
    productSkills,
    productIndustries,
  };
};

export default compose(connect(mapStateToProps))(SectionSoftwaresMaybe);
