import React, { Component } from 'react';
import { array, bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { parse, stringify } from '../../util/urlHelpers';
import {
  createResourceLocatorString,
  findRouteByRouteName,
  canonicalRoutePath,
} from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Button,
  LimitedAccessBanner,
  Logo,
  Modal,
  TopbarDesktop,
  TopbarMobileMenu,
  NamedRedirect,
  ExternalLink
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import MenuIcon from './MenuIcon';

import css from './Topbar.css';

const dev = process.env.REACT_APP_ENV === 'development';
const MS_PER_MINUTE = 60000;
//medium multiplier is set to 2 minutes on development and one day in production
const mediumMultiplier = (dev ? 2 : 60 * 24) * MS_PER_MINUTE;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams, saveUserKeywordSearch } = this.props;
    const keywords = values.keywords;
    const { history } = this.props;
    const searchParams = {
      ...currentSearchParams,
      keywords,
    };
    saveUserKeywordSearch(keywords);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  componentDidUpdate() {
    const {
      userHasProjectInvitations,
      currentUserHasProjects,
      currentUserHasLegacyProjects,
      currentUserHasListings,
      currentUserListing,
      currentUserHasExpertListing,
      currentUserHasUnpublishedExpertListing,
      currentUserExpertListing,
      currentUserUnpublishedListing,
      currentUserHasUnpublishedListing,
      currentUser,
      currentUserStripeAccounts,
      location,
      history,
    } = this.props;

    const currentPath = canonicalRoutePath(routeConfiguration(), location, false);
    const allowedRoutes = [
      findRouteByRouteName('SubscriptionPage', routeConfiguration()).path,
      findRouteByRouteName('ProfileSettingsPage', routeConfiguration()).path,
      '/draft/expert/',
      '/draft/project/',
      '/new/expert/',
      '/new/project/',
      `${process.env.REACT_APP_BASIC_SUPPORT_PLAN_ID}`,
      `${process.env.REACT_APP_SUPPORT_SUPPORT_PLAN_ID}`,
      `${process.env.REACT_APP_STARTER_SUPPORT_PLAN_ID}`,
      '/order-details/',
      '/checkout',
      '/login',
      '/signup',
      '/l/new-project/',
    ];

    const today = new Date();

    const timestampListingPublish = currentUserHasListings
      ? (today.getTime() - currentUserListing?.attributes.createdAt.getTime()) / mediumMultiplier
      : 31;
    const timestampListingPendingApproval = currentUserUnpublishedListing
      ? (today.getTime() - currentUserUnpublishedListing?.attributes.createdAt.getTime()) /
        mediumMultiplier
      : 15;
    const timestampUserCreation = currentUser
      ? (today.getTime() - currentUser?.attributes.createdAt.getTime()) / mediumMultiplier
      : null;
    const timestampExpertProfileCreation = currentUserExpertListing
      ? (today.getTime() - currentUserExpertListing?.attributes.createdAt.getTime()) /
        mediumMultiplier
      : null;

    const userHasBalance = !!currentUserStripeAccounts?.find(
      account => account?.account_balance < 0
    );

    // console.log(currentUserHasExpertListing === false);
    // console.log(Math.round(timestampListingPublish) > 2);
    // console.log(currentUserHasListings === false)
    // console.log(Math.round(timestampListingPendingApproval) > 1)
    // console.log(currentUserHasUnpublishedListing === false);
    // console.log(Math.round(timestampExpertProfileCreation) > 1);
    // console.log(currentUserHasUnpublishedExpertListing === false);
    // console.log(currentUserHasProjects === false);
    // console.log(userHasProjectInvitations === false);
    // console.log(Math.round(timestampUserCreation) > 1);
    if (!currentUser?.attributes.profile.publicData.isPremiumUser) {
      if (
        !allowedRoutes.find(route => currentPath.includes(route)) &&
        ((currentUserHasExpertListing === false &&
          ((Math.floor(timestampListingPublish) > 31 && currentUserHasListings === true) ||
            currentUserHasListings === false) &&
          ((Math.floor(timestampListingPendingApproval) > 31 &&
            currentUserHasUnpublishedListing === true) ||
            currentUserHasUnpublishedListing === false) &&
          ((Math.floor(timestampExpertProfileCreation) > 14 &&
            currentUserHasUnpublishedExpertListing === true) ||
            currentUserHasUnpublishedExpertListing === false) &&
          currentUserHasProjects === false &&
          currentUserHasLegacyProjects === false &&
          userHasProjectInvitations === false &&
          Math.floor(timestampUserCreation) > 30 &&
          !userHasBalance && Array.isArray(currentUserStripeAccounts) &&
          currentUser?.attributes.profile.publicData.isBlocked !== 'false') ||
          currentUser?.attributes.profile.publicData.isBlocked === 'true')
      ) {
        history.push(
          createResourceLocatorString(
            'SubscriptionPage',
            routeConfiguration(),
            {},
            { redirectType: 'block' }
          )
        );
      }
    }
  }

  handleLogout() {
    const { onLogout, logoutInProgress } = this.props;

    if (!logoutInProgress) {
      onLogout().then(() => {
        // const path = pathByRouteName('SearchPage', routeConfiguration(), to={ search: 'pub_category=expert' });

        // // In production we ensure that data is really lost,
        // // but in development mode we use stored values for debugging
        // if (config.dev) {
        //   history.push(path);
        // } else if (typeof window !== 'undefined') {
        //   window.location = path;
        // }

        return <NamedRedirect name="SearchPage" />;
      });
    }
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasExpertListing,
      currentUserHasUnpublishedExpertListing,
      currentUserListing,
      currentUserExpertListing,
      currentUserListingFetched,
      currentCategory,
      currentPage,
      shouldDisplayNotificationDot,
      intl,
      location,
      onManageDisableScrolling,
      showGenericError,
      forceRerender,
      history,
      currentUserStripeAccounts,
    } = this.props;

    const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const isMobileMenuOpen = mobilemenu === 'open';
    const isMobileSearchOpen = mobilesearch === 'open';
    const userHasBalance = !!currentUserStripeAccounts?.find(
      account => account?.account_balance < 0
    );
    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUserHasExpertListing={currentUserHasExpertListing}
        currentUserHasUnpublishedExpertListing={currentUserHasUnpublishedExpertListing}
        currentUserListing={currentUserListing}
        currentUserExpertListing={currentUserExpertListing}
        currentUserListingFetched={currentUserListingFetched}
        currentUser={currentUser}
        history={history}
        userHasBalance={userHasBalance}
        onLogout={this.handleLogout}
        shouldDisplayNotificationDot={shouldDisplayNotificationDot}
        currentPage={currentPage}
      />
    );

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {/* {notificationDot} */}
          </Button>
          {/* <NamedLink
            className={css.home}
            name="SearchPage"
            title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
          > */}
          <div className={css.logoBox}>
            <ExternalLink className={css.logoLink} href={'https://softwaresupp.com'}>
              <Logo format="mobile" />
            </ExternalLink>
            <p className={css.subtitle}>
              <FormattedMessage id="TopbarDesktop.slogan" />
            </p>
          </div>

          {/* </NamedLink> */}
          {/* <Button
            rootClassName={css.searchMenu}
            onClick={this.handleMobileSearchOpen}
            title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
          >
            <SearchIcon className={css.searchMenuIcon} />
          </Button> */}
          <div className={css.emptyBox}></div>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUserHasExpertListing={currentUserHasExpertListing}
            currentUserHasUnpublishedExpertListing={currentUserHasUnpublishedExpertListing}
            currentUserListing={currentUserListing}
            currentUserExpertListing={currentUserExpertListing}
            currentUserListingFetched={currentUserListingFetched}
            currentCategory={currentCategory}
            currentUser={currentUser}
            currentPage={currentPage}
            currentLocation={location}
            userHasBalance={userHasBalance}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            shouldDisplayNotificationDot={shouldDisplayNotificationDot}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            forceRerender={forceRerender}
            history={history}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          containerClassName={css.modalContainer}
          scrollLayerClassName={css.modalScrollLayer}
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          scrollLayerClassName={css.modalScrollLayer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            {/* {!isMobileLayout ? <SearchIcon className={css.searchMenuIcon} /> : null} */}
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>
        {/* <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        /> */}

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
