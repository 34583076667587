import React from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap';
import { SecondaryButton } from '../../components';
//import { updateStripeCustomer } from '../../util/api';
import { NamedLink } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
// import AddBudgetForm from './AddBudgetForm';
import css from './BillingPage.css';
import { formatMoney } from '../../util/currency';
import { BiChart, BiCheckSquare, BiMessageAltDetail, BiPlusCircle } from 'react-icons/bi';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
const { Money } = sdkTypes;

const ProjectItem = props => {
  const {
    intl,
    project,
    // onManageDisableScrolling,
    // currentUser,
    // setReload,
    pub_state,
    // onFetchCurrentUserStripeAccounts,
    history,
  } = props;

  // const [openAddBudgetModal, setOpenAddBudgetModal] = useState(false);
  // const [inProgress, setInProgress] = useState(false);
  //const [errorFlag, setErrorFlag] = useState(false);
  //console.dir(['project',project],{depth:10});
  const usedBudget = formatMoney(
    intl,
    new Money(project.used_budget ? project.used_budget : 0, 'USD')
  );
  const plannedBudget = formatMoney(
    intl,
    new Money(project.plannedBudget ? project.plannedBudget : 0, 'USD')
  );
  // const estimatedBudget = formatMoney(
  //   intl,
  //   new Money(project.estimatedBudget ? project.estimatedBudget : 0, 'USD')
  // );
  // const expertPrice = formatMoney(intl, new Money(project.expertPrice, 'USD'));

  // const spentTime = calculateToFormat(project.time);
  // const plnAccount = userStripeAccounts?.find(account => account.currency === 'pln') ?? {
  //   balance: 0,
  //   currency: 'pln',
  // };
  // const usdAccount = userStripeAccounts?.find(account => account.currency === 'usd') ?? {
  //   balance: 0,
  //   currency: 'usd',
  // };

  // const balance = -(
  //   usdAccount.balance +
  //   plnAccount.balance / process.env.REACT_APP_DEFAULT_CONVERSION_VALUE
  // );

  // const balanceUpdate = async amount => {
  //   const plnFlag = amount > -usdAccount?.balance ? true : false;
  //   let amountPLN = 0;
  //   let amountUSD = amount;

  //   if (plnFlag) {
  //     amountUSD = -usdAccount.balance;
  //     amountPLN = (amount + usdAccount.balance) * process.env.REACT_APP_DEFAULT_CONVERSION_VALUE;
  //   }

  //   try {
  //     if (usdAccount && amountUSD > 0) {
  //       await updateStripeCustomer({
  //         customer: usdAccount.id,
  //         balance: usdAccount.balance + amountUSD,
  //       });
  //     }
  //     if (plnAccount && plnFlag && amountPLN > 0) {
  //       await updateStripeCustomer({
  //         customer: plnAccount.id,
  //         balance: plnAccount.balance + amountPLN,
  //       });
  //     }
  //   } catch (e) {
  //     setErrorFlag(true);
  //   }
  // };
  // const budgetUpdate = async data => {
  //   setInProgress(true);
  //   const { depositBalance } = data;
  //   let amount = depositBalance * 100;
  //   let amoutWithTax = amount;
  //   let body = {
  //     id: project.id,
  //     price: project.plannedBudget + amoutWithTax,
  //   };
  //   try {
  //     await updateProjectBudget(currentUser.attributes.profile.privateData.inboxToken, body);
  //     await balanceUpdate(amount);
  //     setInProgress(false);
  //     setReload(true);
  //     onFetchCurrentUserStripeAccounts(currentUser);
  //     // setOpenAddBudgetModal(false);
  //   } catch (e) {
  //     setErrorFlag(true);
  //     setReload(true);
  //     // setOpenAddBudgetModal(false);
  //     setInProgress(false);
  //   }
  // };

  let path = createResourceLocatorString('ProjectBoardPage', routeConfiguration(), {
    id: project.id?.uuid ? project.id?.uuid : project.id,
  });
  if (pub_state === 'awaiting') {
    path = path + '/pending-approval';
  }
  let icon =
    pub_state === 'ongoing' ? (
      <BiChart
        onClick={() => {
          history.push(path);
        }}
        size={50}
      />
    ) : pub_state === 'open' ? (
      <BiMessageAltDetail
        onClick={() => {
          history.push(path);
        }}
        size={50}
      />
    ) : pub_state === 'closed' ? (
      <BiCheckSquare
        onClick={() => {
          history.push(path);
        }}
        size={50}
      />
    ) : (
      <BiPlusCircle
        onClick={() => {
          history.push(path);
        }}
        size={50}
      />
    );

  return (
    <div className={css.listWrapper}>
      <div
        className={css.title}
        onClick={() => {
          history.push(path);
        }}
      >
        {/* {project.title ? project?.title : project.attributes.title} */}
        {/* {pub_state === 'ongoing' &&
        project.used_budget >= project.plannedBudget &&
        project.plannedBudget > 1000 ? (
          <div className={css.error}>
            <FormattedMessage className={css.error} id={'BillingPage.budgetOver'} />
          </div>
        ) : null} */}
      </div>

      <div className={css.invoiceItemContainer}>
        <div className={css.chartContainer}>
          {pub_state === 'ongoing' && (
            <>
              <Row>
                <Col xs={12} md={6}>
                  <div className={css.projectSubHeader}><FormattedMessage id="BillingPage.budgetBalance" /></div>
                  {project.plannedBudget >= 1000 ? (
                    <div className={css.projectSubText}>
                      <FormattedMessage id={'BillingPage.plannedBudget'} values={{ plannedBudget }} />
                    </div>) : null}

                  <div className={css.projectSubText}>
                    <FormattedMessage id={'BillingPage.usedBudget'} values={{ usedBudget }} />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className={css.projectSubHeader}><FormattedMessage id="BillingPage.workingHours" /></div>
                </Col>
                <Col xs={12}>
                  <div className={css.projectSubHeader}> Freelancers </div>
                </Col>
                <Col xs={12}>
                  <div className={css.toBoardLink}>
                  <a  onClick={() => {
                  history.push(path);
                }}>Go to project board  </a>
                </div>
                </Col>
              </Row>
            </>
          )}
          


          {pub_state === 'open' && (
            
              <div className={css.space}>
                <FormattedMessage id={'BillingPage.open'} />
              </div>
          )}
          {pub_state === 'awaiting' && (
            
              <div className={css.space}>
                <FormattedMessage id={'BillingPage.awaiting'} />
              </div>
            )}
          {pub_state === 'closed' && (
           
              <div className={css.space}>
                <FormattedMessage id={'BillingPage.closed'} />
              </div>
          )}
        </div>
        {pub_state === 'ongoing' && (
          <div key={project.id} >
            {/* <SecondaryButton className={css.button} onClick={() => setOpenAddBudgetModal(true)}>
              <FormattedMessage id={'BillingPage.addBudget'} />
            </SecondaryButton> */}
          </div>
        )}

        {pub_state === 'open' && (
          <div key={project.id} >
            <NamedLink key="notificationPageLink" name="NotificationsPage" params={{}}>
              <SecondaryButton className={classNames(css.button, css.buttonExtend)}>
                <FormattedMessage id="BillingPage.checkNotifications" />
              </SecondaryButton>
            </NamedLink>
          </div>
        )}

        {pub_state === 'awaiting' && (
          <div key={project.id} >
            {/* <SecondaryButton className={css.button} onClick={() => increaseBalance(true)}>
              <FormattedMessage id={'BillingPage.increaseBalance'} />
            </SecondaryButton> */}
            <NamedLink key="subscriptionPageLink" name="SubscriptionPage" params={{}}>
              <SecondaryButton className={classNames(css.button, css.buttonExtend)}>
                <FormattedMessage id="BillingPage.getHourlyPlan" />
              </SecondaryButton>
            </NamedLink>
          </div>
        )}
        {pub_state === 'closed' && (
          <div key={project.id} >
            <NamedLink
              key="ProjectBoardLink"
              name="ProjectBoardPage"
              params={{ id: project.id.uuid }}
            >
              <SecondaryButton className={classNames(css.button, css.buttonExtend)}>
                <FormattedMessage id="BillingPage.projectBoard" />
              </SecondaryButton>
            </NamedLink>
          </div>
        )}
        {/* <Modal
          id="PaymentModal"
          // containerClassName={classes}
          // contentClassName={css.modalContent}
          onManageDisableScrolling={onManageDisableScrolling}
          isOpen={openAddBudgetModal}
          onClose={() => {
            setOpenAddBudgetModal(false);
          }}
        >
          <AddBudgetForm
            errorFlag={errorFlag}
            balance={balance}
            onSubmit={values => {
              budgetUpdate(values);
            }}
            inProgress={inProgress}
          />
        </Modal> */}
    </div>
    </div>
  );
};

export default injectIntl(ProjectItem);
