import React from 'react';
import { Edit, ArrowDownCircle } from 'react-feather';
import classNames from 'classnames';
import { bool, oneOfType, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
  propTypes,
} from '../../util/types';
import { NamedLink, PrimaryButton, SecondaryButton } from '../../components';

import css from './SideActionBarMaybe.css';

const MODAL_BREAKPOINT = 400;

export const SideActionBarMaybe = props => {
  const {
    isOwnListing,
    listing,
    editParams,
    publicData,
    currentUser,
    isSolved,
    windowWidth,
    isMobile,
    isPendingApprovalVariant,
    onCreateProjectFromService,
  } = props;
  const state = listing.attributes.state;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const category = publicData.category;

  const message = isDraft ? 'ServicePage.finishListing' : 'ServicePage.editListing';


  if (isMobile && windowWidth > MODAL_BREAKPOINT) {
    return null;
  }

  return (
    <div className={windowWidth > MODAL_BREAKPOINT ? css.root : css.mobileBottomPanel}>
      <div
        className={
          isPendingApprovalVariant
            ? css.sectionSideActionBarPendingApproval
            : css.sectionSideActionBar
        }
      >
        {isPendingApproval && currentUser ? (
          <div className={css.actionBar}>
            <p className={css.closedListingText}>
              <FormattedMessage id="ServicePage.pendingApprovalListing" />
            </p>
          </div>
        ) : null}
        {(isClosed || isSolved) && currentUser ? (
          <div className={css.actionBar}>
            <p className={css.closedListingText}>
              <FormattedMessage id="ServicePage.closedListing" />
            </p>
          </div>
        ) : null}
        {isOwnListing ? (
          <>
            <div className={css.sectionCTA}>
              <NamedLink
                className={css.editListingLink}
                name="EditListingPage"
                params={editParams}
                category={category}
              >
                <SecondaryButton className={css.editButton}>
                  <Edit className={css.editIconFeather} />
                  <FormattedMessage id={message} />
                </SecondaryButton>
              </NamedLink>
            </div>
            <div className={css.sectionCTA}>
              <a
                className={css.editListingLink}
                href={'/getserviceframe?userid=' + currentUser.id.uuid}
              >
                <PrimaryButton className={classNames(css.editButton, css.getFrameButton)}>
                  <ArrowDownCircle className={css.editIconFeather} />
                  <FormattedMessage id={'BookingPanel.getFrameLink'} />
                </PrimaryButton>
              </a>
            </div>
          </>
        ) : null}

        <div className={css.sectionCTA}>
          <PrimaryButton className={css.buyServiceButton} onClick={onCreateProjectFromService}>
            <FormattedMessage id="ServicePage.createProjectFromService" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

SideActionBarMaybe.propTypes = {
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  editParams: object.isRequired,
};

SideActionBarMaybe.displayName = 'SideActionBarMaybe';

export default SideActionBarMaybe;
