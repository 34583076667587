import React from 'react';
import { Container, Row, Col, Tabs, Tab, Accordion } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';

import { SecondaryButton } from '../../components';
import { formatMoney } from '../../util/currency';

import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BillingPage.css';
import { BiDetail } from 'react-icons/bi';
const { Money } = sdkTypes;

const InvoiceItem = props => {
  const { intl, invoice } = props;

  const amount = formatMoney(intl, new Money(invoice.amount_due, invoice.currency.toUpperCase()));
  const status = invoice.status;

  return (
    <Container>

    <Row>
      <Col xs={2} md={1}>
        <div>
          <BiDetail size={40} />
        </div>
      </Col>
      <Col xs={10} md={11}>
          <Row>
<Col xs={6} md={3}>
        <div>
          <div className={css.field}>
            <FormattedMessage
              id={'BillingPage.invoiceNumber'}

            />
          </div>

          <div className={css.field}>
            {invoice.number}
          </div>
        </div>
      </Col>

      <Col xs={6} md={2}>
        <div className={css.mobileInline}>

          <div className={css.field}>
            <FormattedMessage id={'BillingPage.statusOfInvoice'} />
          </div>
          <div className={css.field}>
            {status}
          </div>
        </div>
      </Col>
      <Col xs={6} md={3}>
        <div className={css.mobileInline}>
          <div className={css.field}>
            <FormattedMessage id={'BillingPage.amountOfInvoice'} />
          </div>
          <div className={css.field}>{amount}</div>
        </div>
      </Col>
      <Col xs={6} md={3}>
        <SecondaryButton
          className={css.button}
          onClick={() => (window.location.href = invoice.hosted_invoice_url)}
        >
          <FormattedMessage
            id={
              invoice.status === 'paid'
                ? 'BillingPage.InvoiceDetails'
                : 'BillingPage.PayTheInvoice'
            }
          />
        </SecondaryButton>
      </Col>
          </Row>
      </Col>
      
    </Row>
    </Container>
  );
};

export default injectIntl(InvoiceItem);
