import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { LISTING_CATEGORY_SERVICE } from '../../util/types';
import config from '../../config';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const FETCH_ADMIN_SERVICE_REQUEST = 'app/StartProjectPage/FETCH_ADMIN_SERVICE_REQUEST';
export const FETCH_ADMIN_SERVICE_SUCCESS = 'app/ProjectBoardPage/FETCH_ADMIN_SERVICE_SUCCESS';
export const FETCH_ADMIN_SERVICE_ERROR = 'app/ProjectBoardPage/FETCH_ADMIN_SERVICE_ERROR';

export const FETCH_REST_OF_SERVICES_REQUEST = 'app/StartProjectPage/FETCH_REST_OF_SERVICES_REQUEST';
export const FETCH_REST_OF_SERVICES_SUCCESS = 'app/ProjectBoardPage/FETCH_REST_OF_SERVICES_SUCCESS';
export const FETCH_REST_OF_SERVICES_ERROR = 'app/ProjectBoardPage/FETCH_REST_OF_SERVICES_ERROR';

export const FETCH_SERVICE_CATEGORIES_REQUEST = 'app/StartProjectPage/FETCH_SERVICE_CATEGORIES_REQUEST';
export const FETCH_SERVICE_CATEGORIES_SUCCESS = 'app/StartProjectPage/FETCH_SERVICE_CATEGORIES_SUCCESS';
export const FETCH_SERVICE_CATEGORIES_ERROR = 'app/StartProjectPage/FETCH_SERVICE_CATEGORIES_ERROR';

// ================ Reducer ================ //

const initialState = {
    adminListingId: null,
    restOfServiceIds: [],
    searchInProgress: false,
    noFoundServices: false
};

const SERVICES = process.env.REACT_APP_ENV === 'development'
    ? {
        'CRM': '6051dd87-5b21-4c2a-b3ab-95fc2f71dead',
        'Websites': '6051ddd0-f48e-46cd-ae34-e84b982d5b55',
        'E-commerce store': '6051dd08-2294-4804-a5dd-71529dd64e57',
        'Integration': '6051df06-f918-4efa-a57a-41001c23b783',
        'Marketing automation': '6051debd-ea7a-43a1-9e24-7dedc7a12728',
    } : {
        'CRM': '60479fbb-bf8d-4c7c-9429-7fc9f7bd6e44',
        'Website': '6047ae2c-a5a9-4b95-a964-55662739edf4',
        'E-commerce store': '6025ad90-b313-444e-9485-2de49f182f71',
        'Integration': '6047ecb3-3905-412b-aac7-5acccc264ae7',
        'Marketing automation': '6047ea7c-c0c6-4c34-aa0f-2804e959230e',
    }

const resultIds = data => data.data.map(l => l.id);

const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
}

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {

    case FETCH_ADMIN_SERVICE_REQUEST:
      return { ...state, adminListingId: payload.id, showListingError: null };
    case FETCH_ADMIN_SERVICE_SUCCESS:
      return {
        ...state,
        noAdminService: !payload.data
      };
    case FETCH_ADMIN_SERVICE_ERROR:
      return { ...state, showListingError: payload };

      case FETCH_REST_OF_SERVICES_REQUEST:
          return {
              ...state,
              searchParams: payload.searchParams,
              searchInProgress: true,
              searchListingsError: null,
          };
      case FETCH_REST_OF_SERVICES_SUCCESS:
          return {
              ...state,
              restOfServiceIds: resultIds(payload.data),
              noFoundServices: resultIds(payload.data).length === 0,
              searchInProgress: false,
          };
      case FETCH_REST_OF_SERVICES_ERROR:
          // eslint-disable-next-line no-console
          console.error(payload);
          return { 
            ...state, 
            searchInProgress: false, 
            searchListingsError: payload,
            noFoundServices: true
          };

    case FETCH_SERVICE_CATEGORIES_REQUEST:
      return {
        ...state,
        fetchingCategoriesInProgress: true,
      };
    case FETCH_SERVICE_CATEGORIES_SUCCESS:
      return {
        ...state,
        fetchingCategoriesInProgress: false,
        serviceCategories: payload,
      }
    case FETCH_SERVICE_CATEGORIES_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchingCategoriesInProgress: false
      }

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const fetchAdminServiceRequest = id => ({
  type: FETCH_ADMIN_SERVICE_REQUEST,
  payload: { id },
});
export const fetchAdminServiceSuccess = data => ({
  type: FETCH_ADMIN_SERVICE_SUCCESS,
  payload: { data },
});
export const fetchAdminServiceError = e => ({
  type: FETCH_ADMIN_SERVICE_ERROR,
  error: true,
  payload: e,
});

export const fetchRestOfServicesRequest = searchParams => ({
    type: FETCH_REST_OF_SERVICES_REQUEST,
    payload: { searchParams },
});
export const fetchRestOfServicesSuccess = response => ({
    type: FETCH_REST_OF_SERVICES_SUCCESS,
    payload: { data: response.data },
});
export const fetchRestOfServicesError = e => ({
    type: FETCH_REST_OF_SERVICES_ERROR,
    error: true,
    payload: e,
});

export const fetchServiceCategoriesRequest = () => ({
  type: FETCH_SERVICE_CATEGORIES_REQUEST,
});
export const fetchServiceCategoriesSuccess = response => ({
  type: FETCH_SERVICE_CATEGORIES_SUCCESS,
  payload: response,
});
export const fetchServiceCategoriesError = e => ({
  type: FETCH_SERVICE_CATEGORIES_ERROR,
  error: true,
  payload: e,
});
  

// ================ Thunks ================ //

export const fetchAdminService = category => (dispatch, getState, sdk) => {
    const serviceId = SERVICES[category];
  dispatch(fetchAdminServiceRequest(new UUID(serviceId)));
  const params = {
    id: new UUID(serviceId),
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  return sdk.listings.show(params)
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      dispatch(fetchAdminServiceSuccess(data));
      return data;
    })
    .catch(e => {
      dispatch(fetchAdminServiceError(storableError(e)));
    });
};

export const fetchRestOfServices = category => (dispatch, getState, sdk) => {

    const pub_serviceCategories = config.custom.serviceCategoriesConfig.options
        ?.find(serviceCategory => serviceCategory.label === category)?.key;

        if (pub_serviceCategories) {
    const searchParams = {
        pub_category: LISTING_CATEGORY_SERVICE,
        pub_serviceCategories,
        include: ['author', 'author.profileImage'],
        'fields.image': [
            // Listing page
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.landscape-crop4x',
            'variants.landscape-crop6x',

            // Social media
            'variants.facebook',
            'variants.twitter',

            // Image carousel
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-large',
            'variants.scaled-xlarge',

            // Avatars
            'variants.square-small',
            'variants.square-small2x',
        ],
        per_page: 10,
        page: 1
    }

    dispatch(fetchRestOfServicesRequest(searchParams));

    return sdk.listings
      .query(searchParams)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchRestOfServicesSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(fetchRestOfServicesError(storableError(e)));
        throw e;
      });
  }
  else {
    dispatch(fetchRestOfServicesSuccess({ data: { data: [] } }));
  }
};

export const fetchServiceCategories = () => async (dispatch, getState, sdk) => {
  dispatch(fetchServiceCategoriesRequest())
  let responseLength = null;
  let services = [];
  let additionalServices = []
  try {
    additionalServices = await sdk.listings.query({ pub_category: 'service' })
    services = additionalServices.data.data
    responseLength = services.length;
    while (responseLength === 100) {
      additionalServices = await sdk.listings.query({ pub_category: 'service', page: Math.round(services.length / 100) + 1 })
      responseLength = additionalServices.data.data.length;
      services = services.concat(additionalServices.data.data);
    }
    const serviceCategories = services
      ?.filter(
        service =>
          service.id.uuid !== process.env.REACT_APP_BASIC_SUPPORT_PLAN_ID &&
          service.id.uuid !== process.env.REACT_APP_SUPPORT_SUPPORT_PLAN_ID &&
          service.id.uuid !== process.env.REACT_APP_STARTER_SUPPORT_PLAN_ID
      )
      ?.map(service => service.attributes.publicData.serviceCategories)
      .flat()
      .filter(category => !!category)
      .filter(distinct);
    dispatch(fetchServiceCategoriesSuccess(serviceCategories));
  } catch(err) {
    console.error(err)
    dispatch(fetchServiceCategoriesError(storableError(err)));
  }
}


export const loadData = (params, search, pathname) => dispatch => {
  return dispatch(fetchServiceCategories());
};