export const parseTime = value => {

  
  if (value !== '' && value !== undefined) {
    let times = value.split(' ');
    let hours = times[0].replace('h', '');
    let minutes = parseInt(hours) * 60;
    if (times[1]) {
      minutes += parseInt(times[1].replace('m', ''));
    }

    return minutes;
  } else return null;
};

export const calculateToFormat = value => {
   if (value > 0) {
    let hours = parseInt(value / 60)
    let minutes = value - hours*60
    return `${hours}h ${minutes}m`
   }else {
     if (value === undefined) {
       return null
     } else {
      return `${value}h`;
     }
     
   }
};
