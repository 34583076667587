import React from 'react';
import classNames from 'classnames';
import { Avatar } from '../../components';
import { dateToReadableString } from '../../util/inbox/dateUtil';
import css from './NotificationsPage.css';

const shortText = (text, maxLength) => {
  if(text?.length > 0) {
    let shortedText = text.substring(0, maxLength);
    shortedText = shortedText.substring(0, shortedText.lastIndexOf(" ")) + "...";
    return text.length > maxLength ? shortedText : text;
  } else {
    return text;
  }
}

export const NotificationItem = props => {

  const { id, title, subtitle, price, status, type, redirectUrl, createdAt, wasOpened, counterpartProfile, includedLastMessage } = props.data;
  const { displayName, abbreviatedName, imgUrl } = counterpartProfile;
  const { linkOpenedHandler } = props;

  const displayedDate = dateToReadableString(createdAt);
  const displayedUserName = displayName.split(" ")[0];
  const isMessageNotification = type.includes("MESSAGE") && !!includedLastMessage;
  const displayedPrice = price ?? '';
  
  const displayedTitle = title ?? '';
  let displayedSubtitle; 
  if(isMessageNotification) {
    const containsAttachments = (includedLastMessage?.attachmentsQty > 0);
    const lastMessage = includedLastMessage?.content;
    displayedSubtitle = (containsAttachments && !lastMessage) ? 'Attachment.' : shortText(lastMessage, 70);
  } else {
    displayedSubtitle = subtitle;
  }

  const rowNotificationDot = !wasOpened ? <div className={css.notificationDot} /> : null;
  const titleClass = !wasOpened ? classNames(css.itemUsername, css.nameEmphasized) : classNames(css.itemUsername, css.nameNotEmphasized);

  //const isAdminListing = listing.id.uuid === process.env.REACT_APP_ADMIN_PM_LISTING_ID;

  const linkClasses = classNames(css.itemLink);

  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        {!imgUrl ?
          <Avatar user={{attributes: {profile: {abbreviatedName: abbreviatedName}}}} />
        :
          <Avatar onlyImgName={displayedUserName} onlyImgUrl={imgUrl} disableProfileLink/>
        }
      </div>
      <div 
        className={linkClasses} 
        onClick={() => { 
          linkOpenedHandler(wasOpened, id, redirectUrl)
        }}
      >
        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
        <div className={css.itemInfo}>
          <div className={titleClass}>
            {displayedTitle}
          </div>
          <div className={css.listingTitle}>
            {displayedSubtitle}
          </div>
        </div>
        <div className={css.price}>
          {displayedPrice}
        </div>
        <div className={css.itemState}>
          <div className={classNames(css.stateName, css.stateActionNeeded)}>
            {status}
          </div>
          <div className={classNames(css.lastTransitionedAt, css.lastTransitionedAtNotEmphasized)}>
            {displayedDate}
          </div>
        </div>
      </div>
    </div>
  );
};