import React from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { IconSpinner } from '../../components';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import css from './BillingPage.css';

const { Money } = sdkTypes;

const UserBalancePanel = props => {
  const { intl, userStripeAccounts, currentUserHasStripeAccounts, ongoingProjects } = props;

  if (currentUserHasStripeAccounts && !userStripeAccounts) {
    return (
      <div>
        <div className={css.stripeAccountsContent}>
          <IconSpinner />
        </div>
        <p className={css.loadingInfo}>
          <FormattedMessage id="BillingPage.loadingBalance" />
        </p>
      </div>
    );
  }

  const usdAccount = userStripeAccounts?.find(account => account.currency === 'usd') ?? {
    balance: 0,
    currency: 'usd',
  };

  let budget =
    ongoingProjects.length === 0
      ? 0
      : ongoingProjects.length === 1
      ? ongoingProjects[0].used_budget
      : ongoingProjects.reduce((prev, curr) => prev + curr.used_budget, 0);

  const { balance, currency } = usdAccount;

  const displayOverall = formatMoney(
    intl,
    new Money(balance === 0 ? balance : -balance, currency.toUpperCase())
  );

  const usedBudget = formatMoney(intl, new Money(budget, currency.toUpperCase()));
  const balancePanel = (
    <div className={css.balancePanel}>
      <div className={css.balancePanelTitle}>
          <FormattedMessage id={'BillingPage.yourBalance'} />
      </div>
      <div className={css.balanceAmount}>
        <FormattedMessage
          id={'BillingPage.availableBalance'}
          values={{ balance: displayOverall }}
        />
        {/* <Tooltip */}
        {/*   interactive */}
        {/*   title={ */}
        {/*     <FormattedMessage */}
        {/*       id="BillingPage.balanceParts" */}
        {/*       values={{ usedBudget: usedBudget, br: <br /> }} */}
        {/*     /> */}
        {/*   } */}
        {/* > */}
        {/*   <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} /> */}
        {/* </Tooltip> */}
        <div className={css.balanceAmmountInfo}>
          <FormattedMessage id={'BillingPage.balanceDescription'} values={{}} />
        </div>
      </div>
    </div>
  );

  return currentUserHasStripeAccounts ? <div>{balancePanel}</div> : null;
};

export default injectIntl(UserBalancePanel);






