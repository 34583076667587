/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureListing, ensureTransaction, ensureUser } from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { sendOfferAccepted, newMeetingEmail, sendListingClosed } from '../../util/triggerMail';
import config from '../../config';
import {
  NamedRedirect,
  TransactionPanel,
  Page,
  LayoutSideNavigation,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Sidebar,
} from '../../components';
import { TopbarContainer } from '../../containers';
import {
  fetchInvitedToProjectTransactions,
  closeJobAndCancelTransactions,
  adjustBalance,
  updateProjectBudget,
} from '../../util/api';

import {
  acceptOffer,
  loadData,
  setInitialValues,
  fetchMoreMessages,
  queryUserListings,
  finishBooking,
  addExpertToProject,
} from './TransactionPage.duck';
import {
  pay,
  createNewStripeCustomer,
  updateExistingStripeCustomer,
  fetchListingHasInvoice,
  createServiceProjectSequence,
} from '../../ducks/stripe.duck';
import { requestBooking } from '../ExpertPage/ExpertPage.duck';
import css from './TransactionPage.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const VIEWER = 'viewer';

const { Money } = sdkTypes;

// TransactionPage handles data loading for Sale and Order views to transaction pages in Center.
export const TransactionPageComponent = props => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    fetchMessagesError,
    fetchMessagesInProgress,
    totalMessagePages,
    oldestMessagePageFetched,
    fetchTransactionError,
    history,
    intl,
    messages,
    onManageDisableScrolling,
    onShowMoreMessages,
    params,
    scrollingDisabled,
    transaction,
    transactionRole,
    acceptInProgress,
    acceptSaleError,
    declineInProgress,
    onAcceptOffer,
    onFinishBooking,
    onQueryUserListings,
    processTransitions,
    expertListing,
    requestPaymentInProgress,
    requestPaymentError,
    location,
    onRequestBooking,
    currentUserExpertListing,
    onPay,
    onCreateStripeCustomer,
    currentUserStripeAccounts,
    onUpdateStripeCustomer,
    onAddExpertToProject,
    createStripeCustomerInProgress,
    createStripePaymentInProgress,
    onFetchListingHasInvoice,
    listingInvoiceUrl,
    fetchingInvoiceInProgress,
    onCreateServiceProjectSequence,
    ...rest
  } = props;

  const { depositListingId } = location?.state ?? {};

  const isViewer = transactionRole === VIEWER;

  const [invitedToProjectTransactions, setInvitedToProjectTransactions] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [userNotAllowed, setUserNotAllowed] = useState(false);
  const [acceptingInProgress, setAcceptingInProgress] = useState(false);
  

  const fixIntegrationApiReponse = transaction => {
    const { createdAt, lastTransitionedAt } = transaction.attributes;
    const listing = transaction.listing;
    transaction.attributes.createdAt = new Date(createdAt);
    transaction.attributes.lastTransitionedAt = new Date(lastTransitionedAt);
    transaction.listing.attributes.price = listing.attributes.price
      ? new Money(listing.attributes.price.amount, config.currency)
      : null;
    transaction.attributes.transitions = transaction.attributes.transitions.map(transition => {
      return {
        transition: transition.transition,
        by: transition.by,
        createdAt: new Date(transition.createdAt),
      };
    });
    return transaction;
  };

  useEffect(() => {
    let isMounted = false;
    let isCancelled = false;
    if (isMounted) {
      let fetchingInvitedTransactions = null;
      async function fetchTransactions() {
        if (!isCancelled) {
          if (
            isViewer &&
            fetchingInvitedTransactions === null &&
            !invitedToProjectTransactions &&
            currentUser
          ) {
            const { id } = props.params;
            const body = {
              userId: currentUser.id.uuid,
              email: currentUser.attributes.email,
              transactionId: id,
            };
            fetchingInvitedTransactions = true;
            fetchInvitedToProjectTransactions(body)
              .then(result => {
                if (!isCancelled) {
                  setInvitedToProjectTransactions(fixIntegrationApiReponse(result));
                  fetchingInvitedTransactions = false;
                }
              })
              .catch(e => setUserNotAllowed(true));
          }
        }
      }
      fetchTransactions();
    }

    return () => {
      isCancelled = true;

      isMounted = true;
    };
  }, [props.params, currentUser, isViewer, invitedToProjectTransactions, location.pathname]);

  const transactionPrimary = invitedToProjectTransactions
    ? ensureTransaction(null)
    : ensureTransaction(transaction);

  const currentTransaction = invitedToProjectTransactions
    ? invitedToProjectTransactions
    : transactionPrimary;

  const currentListing = ensureListing(currentTransaction.listing);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;

  const currentCustomer = ensureUser(currentTransaction.customer);
  const lastTransitionTime = currentTransaction.attributes?.lastTransitionedAt;
  const currentCustomerStripe =
    (currentCustomer &&
      currentCustomer.attributes.profile.publicData &&
      currentCustomer.attributes.profile.publicData.hasStripeConnected) ||
    (lastTransitionTime &&
      lastTransitionTime instanceof Date &&
      lastTransitionTime?.getTime() > new Date('2020-10-01').getTime());

  const currentProvider = ensureUser(currentTransaction.provider);

  const currentProviderStripe =
    currentProvider && currentProvider.attributes.profile.publicData
      ? currentProvider.attributes.profile.publicData.hasStripeConnected
      : null;

  if ((isProviderRole || isViewer) && currentTransaction.id && !expertListing) {
    onQueryUserListings(currentTransaction.customer.id.uuid);
  }

  const handleRequestBooking = (values, freeCall) => {
    const routes = routeConfiguration();

    const authorId = currentListing.id.uuid;
    const privilegedExperts = currentUser.attributes.profile.publicData
      ? currentUser.attributes.profile.publicData.priviligedExperts
      : null;
    const currentUserIsPrivileged = privilegedExperts
      ? !!privilegedExperts.find(expertId => expertId === authorId)
      : false;

    const { bookingStartTime, bookingEndTime, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    const bookingData = {
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      ...restOfValues,
    };

    const initialValues = {
      listing: currentListing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
      negotiatedTotal:
        currentUserIsPrivileged || freeCall || isAdmin ? null : currentListing.attributes.price,
    };

    onRequestBooking(initialValues).then(transaction => {
      const orderLink = createResourceLocatorString(
        'OrderDetailsPage',
        routes,
        { id: transaction.id.uuid },
        {}
      );
      const saleLink = createResourceLocatorString(
        'SaleDetailsPage',
        routes,
        { id: transaction.id.uuid },
        {}
      );
      newMeetingEmail(currentUser, currentListing, orderLink, saleLink, bookingStart);
      //Redirect to OrderDetailsPage
      history.push(orderLink);
    });
  };

  const onSubmitRedirect = (negotiatedOfferId, fetchedExpertListing) => {
    if (!acceptingInProgress) {
      setAcceptingInProgress(true);
      const routes = routeConfiguration();
      const commission = currentListing.attributes.publicData?.commission ?? 0.5;
      let negotiatedTotal = currentTransaction.attributes.payinTotal;
      if (!isNaN(negotiatedOfferId)) {
        if (negotiatedOfferId === -1) {
          // new money
          negotiatedTotal = new Money(
            currentTransaction.attributes.metadata.originalOffer.price * 100,
            config.currency
          );
        } else {
          negotiatedTotal = new Money(
            currentTransaction.attributes.metadata.negotiationHistory[negotiatedOfferId]
              .priceSummary * 100,
            config.currency
          );
        }
      }
      const commissionPrice = new Money(negotiatedTotal.amount / (1 - commission), config.currency);
      if (currentTransaction.attributes.metadata?.projectTransactionId) {
        history.push(
          createResourceLocatorString(
            'OrderDetailsPage',
            routes,
            { id: currentTransaction.attributes.metadata?.projectTransactionId },
            {}
          )
        );
      } else {
        sendOfferAccepted(
          negotiatedTotal,
          commissionPrice,
          currentTransaction.listing.attributes.title,
          currentTransaction.provider,
          currentTransaction.customer,
          expertListing?.listingId.data
            ? expertListing.listingId.data[0].attributes.title
            : fetchedExpertListing.attributes.title,
          intl
        );
        if (isProviderRole && !currentTransaction.booking) {
          const initialValues = {
            listing: expertListing ? expertListing.listingId.data[0] : fetchedExpertListing,
            negotiatedTotal,
            listingIdPaidFor: currentListing.id.uuid,
            commission,
            listingCategory: currentListing.attributes.publicData.category,
            listingTitle: listingTitle,
            offerTransactionId: currentTransaction.id.uuid,
            negotiatedOfferId: negotiatedOfferId,
          };

          onAcceptOffer(initialValues, currentTransaction.id).then(async () => {
            const closeJobBody = {
              userId: currentUser.id.uuid,
              listingId: currentListing.id.uuid,
              state: 'inProgress',
            };

            let usdAccount = currentUserStripeAccounts?.find(el => {
              return el.currency === 'usd' || el.currency === 'null';
            });
            let plnAccount = currentUserStripeAccounts?.find(el => {
              return el.currency === 'pln';
            });

            let payment_account = plnAccount ? plnAccount : usdAccount;

            if (negotiatedTotal.amount + usdAccount.balance > 0) {
              await onPay({
                data: {
                  listingId: currentListing.id.uuid,
                  isDeposit: true,
                  userId: currentUser.id.uuid,
                  invoiceData: [
                    {
                      projectName: 'Payment for project: ' + currentListing.attributes.title,
                      price: negotiatedTotal.amount + usdAccount.balance,
                    },
                  ],
                  currency: payment_account.currency,
                  isPoland: payment_account.address?.country === 'PL',
                  customer: payment_account.id,
                },
              });
            }

            history.push(
              createResourceLocatorString(
                'ProjectBoardPage',
                routes,
                { id: currentListing.id.uuid },
                {}
              )
            );

            sendListingClosed(currentListing, currentUser);
            closeJobAndCancelTransactions(closeJobBody);
          });
        }
      }

      const expertId = expertListing
        ? expertListing?.listingId.data[0].id.uuid
        : fetchedExpertListing?.id.uuid;
      onAddExpertToProject(currentListing.id.uuid, expertId, currentTransaction.id.uuid);

      setAcceptingInProgress(false);
    }
  };

  const deletedListingTitle = intl.formatMessage({
    id: 'TransactionPage.deletedListing',
  });
  const listingTitle = currentListing.attributes.deleted
    ? deletedListingTitle
    : currentListing.attributes.title;

  // Redirect users with someone else's direct link to their own center/sales or center/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    (currentTransaction.attributes.lineItems || isViewer) &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    (!fetchTransactionError || isViewer);

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="CenterPage" params={{ tab: 'sales' }} />;
  } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="CenterPage" params={{ tab: 'orders' }} />;
  }

  const detailsClassName = classNames(css.tabContent, css.tabContentVisible);

  const fetchErrorMessage =
    isCustomerRole || isViewer
      ? 'TransactionPage.fetchOrderFailed'
      : 'TransactionPage.fetchSaleFailed';
  const loadingMessage =
    isCustomerRole || isViewer
      ? 'TransactionPage.loadingOrderData'
      : 'TransactionPage.loadingSaleData';

  const userNotAllowedMessage = 'TransactionPage.userNotAllowedError';

  const loadingOrFailedFetching = fetchTransactionError ? (
    <p className={css.error}>
      <FormattedMessage id={`${fetchErrorMessage}`} />
    </p>
  ) : userNotAllowed ? (
    <p className={css.error}>
      <FormattedMessage id={`${userNotAllowedMessage}`} />
    </p>
  ) : (
    <p className={css.loading}>
      <FormattedMessage id={`${loadingMessage}`} />
    </p>
  );

  const initialMessageFailed = !!(
    initialMessageFailedToTransaction &&
    currentTransaction.id &&
    initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  );

  const isAdmin =
    currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;
  const isUser =
    currentUser &&
    currentUser.id &&
    currentListing.attributes.publicData &&
    currentListing.attributes.publicData.allowedUser &&
    !!currentListing.attributes.publicData.allowedUser.find(
      userMail => userMail === currentUser.attributes.email
    );
  // TransactionPanel is presentational component
  // that currently handles showing everything inside layout's main view area.
  const panel = isDataAvailable ? (
    <TransactionPanel
      className={detailsClassName}
      currentUser={currentUser}
      transaction={currentTransaction}
      expertListing={expertListing}
      fetchMessagesInProgress={fetchMessagesInProgress}
      totalMessagePages={totalMessagePages}
      oldestMessagePageFetched={oldestMessagePageFetched}
      messages={messages}
      initialMessageFailed={initialMessageFailed}
      savePaymentMethodFailed={savePaymentMethodFailed}
      fetchMessagesError={fetchMessagesError}
      onManageDisableScrolling={onManageDisableScrolling}
      onShowMoreMessages={onShowMoreMessages}
      transactionRole={transactionRole}
      acceptInProgress={acceptInProgress}
      completeInProgress={acceptInProgress}
      declineInProgress={declineInProgress}
      requestPaymentInProgress={requestPaymentInProgress}
      requestPaymentError={requestPaymentError}
      acceptSaleError={acceptSaleError}
      nextTransitions={processTransitions}
      onSubmitRedirect={negotiatedOfferId => {
        if (!expertListing || !expertListing.data)
          onQueryUserListings(currentTransaction.customer.id.uuid).then(expertListing =>
            onSubmitRedirect(negotiatedOfferId, expertListing.data.data[0])
          );
        else onSubmitRedirect(negotiatedOfferId);
      }}
      isAdmin={isAdmin}
      isUser={isUser}
      currentCustomerStripe={currentCustomerStripe}
      currentProviderStripe={currentProviderStripe}
      onFinishBooking={() =>
        onFinishBooking(currentTransaction.id).then(() => window.location.reload(false))
      }
      history={history}
      onRequestBooking={handleRequestBooking}
      relatedExpertOffers={props?.location?.state?.expertOffers} // pushed from CenterPage
      currentUserExpertListing={currentUserExpertListing}
      onPay={onPay}
      onCreateStripeCustomer={onCreateStripeCustomer}
      currentUserStripeAccounts={currentUserStripeAccounts}
      onUpdateStripeCustomer={onUpdateStripeCustomer}
      createStripeCustomerInProgress={createStripeCustomerInProgress}
      createStripePaymentInProgress={createStripePaymentInProgress}
      onFetchListingHasInvoice={onFetchListingHasInvoice}
      listingInvoiceUrl={listingInvoiceUrl}
      depositListingId={depositListingId}
      fetchingInvoiceInProgress={fetchingInvoiceInProgress}
      onCreateServiceProjectSequence={onCreateServiceProjectSequence}
      {...rest}
    />
  ) : (
    loadingOrFailedFetching
  );

  return (
    <Page
      title={intl.formatMessage({ id: 'TransactionPage.title' }, { title: listingTitle })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav
          className={classNames(
            currentUser ? css.navigation : css.navigationNone,
            !sidebarVisible && css.navigationHidden
          )}
        >
          <Sidebar
            tab={null}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
        </LayoutWrapperSideNav>
        <LayoutWrapperMain className={css.layout}>
          <div className={css.root}>{panel}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

TransactionPageComponent.defaultProps = {
  currentUser: null,
  fetchTransactionError: null,
  requestPaymentError: null,
  acceptSaleError: null,
  transaction: null,
  fetchMessagesError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  isExpertListing: null,
  expertListing: null,
  requestPaymentInProgress: false,
};

TransactionPageComponent.propTypes = {
  params: shape({ id: string }).isRequired,
  transactionRole: oneOf([PROVIDER, CUSTOMER, VIEWER]).isRequired,
  currentUser: propTypes.currentUser,
  fetchTransactionError: propTypes.error,
  acceptSaleError: propTypes.error,
  acceptInProgress: bool.isRequired,
  completeInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  onAcceptOffer: func.isRequired,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  fetchMessagesError: propTypes.error,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailedToTransaction: propTypes.uuid,
  savePaymentMethodFailed: bool,
  requestPaymentInProgress: bool.isRequired,
  requestPaymentError: propTypes.error,
  onShowMoreMessages: func.isRequired,
  callSetInitialValues: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchTransactionError,
    acceptSaleError,
    acceptInProgress,
    completeInProgress,
    declineInProgress,
    transactionRef,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    processTransitions,
    expertListing,
  } = state.TransactionPage;
  const {
    requestPaymentInProgress,
    createStripePaymentInProgress,
    createStripeCustomerInProgress,
    listingInvoiceUrl,
    fetchingInvoiceInProgress,
  } = state.stripe;
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserExpertListing,
    currentUserStripeAccounts,
  } = state.user;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    getListing,
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    fetchTransactionError,
    acceptSaleError,
    acceptInProgress,
    completeInProgress,
    declineInProgress,
    requestPaymentInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    processTransitions,
    expertListing,
    currentUserExpertListing,
    currentUserStripeAccounts,
    createStripePaymentInProgress,
    createStripeCustomerInProgress,
    listingInvoiceUrl,
    fetchingInvoiceInProgress,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddExpertToProject: (listingId, expertId, transactionId) =>
      dispatch(addExpertToProject(listingId, expertId, transactionId)),
    onQueryUserListings: userId => dispatch(queryUserListings(userId)),
    onAcceptOffer: (listingId, currentTransactionId) =>
      dispatch(acceptOffer(listingId, currentTransactionId)),
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onFinishBooking: transactionId => dispatch(finishBooking(transactionId)),
    onRequestBooking: orderParams => dispatch(requestBooking(orderParams)),
    onPay: body => dispatch(pay(body)),
    onCreateStripeCustomer: (body, stripeAccount) =>
      dispatch(createNewStripeCustomer(body, stripeAccount)),
    onCreateServiceProjectSequence: version => dispatch(createServiceProjectSequence(version)),
    onUpdateStripeCustomer: body => dispatch(updateExistingStripeCustomer(body)),
    onFetchListingHasInvoice: (listingId, isDeposit) =>
      dispatch(fetchListingHasInvoice(listingId, isDeposit)),
  };
};

const TransactionPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TransactionPageComponent);

TransactionPage.loadData = loadData;
TransactionPage.setInitialValues = setInitialValues;

export default TransactionPage;
