/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { DATE_TYPE_DATETIME } from '../../util/types';
import { BookingBreakdown, PrimaryButton } from '../../components';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import css from './TransactionPanel.css';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    showWhereby,
  } = props;
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  

  const timeZone = typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  let urlMeeting;
  if (sessionStorage.getItem('urlMeeting') !== null) {
    urlMeeting = sessionStorage.getItem('urlMeeting');
    //sessionStorage.removeItem('urlMeeting');
  }
  //console.log(urlMeeting);

  return loaded ? (
    <div className={classes}>
      <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={config.bookingUnitType}
        transaction={transaction}
        booking={transaction.booking}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
        showWhereby={showWhereby}
      />
      <div className={css.actionButtonWrapper}>
        <div className={css.desktopActionButtons}>
          <div className={css.actionButtons}>
            {showWhereby ? (
              <a className={css.wherebyLink} href={urlMeeting} target="_blank">
                <PrimaryButton>
                  <FormattedMessage id="BreakdownMaybe.goToWhereby" />
                </PrimaryButton>
              </a>
            ) : null}
          </div>
        </div>
        <div className={css.mobileActionButtons}>
          <div className={css.actionButtons}>
            {showWhereby ? (
              <a className={css.wherebyLink} href={urlMeeting} target="_blank">
                <PrimaryButton>
                  <FormattedMessage id="BreakdownMaybe.goToWhereby" />
                </PrimaryButton>
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default BreakdownMaybe;
