/* eslint-disable */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { array, bool, func, shape, string, oneOf } from 'prop-types';
import { ArrowLeft } from 'react-feather';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { GoCheck } from 'react-icons/go';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  Modal,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperMain,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  InlineTextButton,
  AcceptMeetingRequest,
  PrimaryButton,
  MessagesPanel,
  Sidebar,
  ClientPathModal,
} from '../../components';
import { SolutionForm } from '../../forms';
import { TopbarContainer, AuthenticationPage } from '../../containers';
import { NamedLink, SecondaryButton } from '../../components';

import {
  loadData,
  setInitialValues,
  showUser,
  fetchConnectedTransaction,
  showMessageOwner,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionExpertiseMaybe from './SectionExpertiseMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionSkillsMaybe from './SectionSkillsMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionOffersMaybe from './SectionOffersMaybe';
import SectionJobScopeMaybe from './SectionJobScopeMaybe/SectionJobScopeMaybe';
import SectionShareMaybe from '../../components/SectionShareMaybe/SectionShareMaybe';
import css from './ListingPage.css';
import general_expert from './media/general_expert.png';
import magento_expert from './media/magento_expert.png';
import zoho_expert from './media/zoho_expert.png';
import zapier_expert from './media/zapier_expert.png';
import shopify_expert from './media/shopify_expert.png';
import salesforce_expert from './media/salesforce_expert.png';
import hubspot_expert from './media/hubspot_expert.png';
import webflow_expert from './media/webflow_expert.png';
import wordpress_expert from './media/wordpress_expert.png';
import monday_expert from './media/monday_expert.png';
import aws_expert from './media/aws_expert.png';
import javascript_expert from './media/javascript_expert.png';
import net_expert from './media/net_expert.png';
import php_expert from './media/php_expert.png';
import powerBI_expert from './media/powerBI_expert.png';
import python_expert from './media/python_expert.png';
import react_expert from './media/react_expert.png';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  sendSummaryOffer,
  changeTicketData,
  addUserToTicket,
  inboxUpdateContactsByTwowayBinding,
  cancelScheduledSend,
  getOpenGraphImg
} from '../../util/api';
import {
  sendListingClosed,
  sendListingClosedUser,
  newAnswer,
  newAnswerNotOwn,
  newMention,
} from '../../util/triggerMail';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios'


const { Money } = sdkTypes;
const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};

const unregisteredUserTooltip = (
  <>
    <FormattedMessage id="BookingTimeForm.postJob" />
    <Tooltip
      interactive
      title={<FormattedMessage id="BookingPanel.benefitsListingExpert" values={{ br: <br /> }} />}
    >
      <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
    </Tooltip>
  </>
);

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

// //converting project expiration date to Dat

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      currentListing: {},
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      listingState: null,
      conversationItems: [],
      sidebarVisible: false,
      paymentSuccessModalSeen: false,
      isAskWarningModalOpen: false,
      clientPathModalOpen: false,
      clientPathModalWasOpenned: false,
      anchorEl: null,
      counterRender: 0,
    };

    this.onContactUser = this.onContactUser.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleChangeTicketAnswer = this.handleChangeTicketAnswer.bind(this);
    this.handleNewMention = this.handleNewMention.bind(this);
    this.handleSolution = this.handleSolution.bind(this);
    this.handleSidebarVisibility = this.handleSidebarVisibility.bind(this);
    this.onOpenProjectBoard = this.onOpenProjectBoard.bind(this);
    this.onAskForDetails = this.onAskForDetails.bind(this);
    this.openDropMenu = this.handleClick.bind(this);
    this.closeDropMenu = this.handleClose.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const listingId = new UUID(this.props.params.id);
      const isPendingApprovalVariant =
        this.props.params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
      const isDraftVariant = this.props.params.variant === LISTING_PAGE_DRAFT_VARIANT;
      const currentListing =
        isPendingApprovalVariant || isDraftVariant
          ? ensureOwnListing(this.props.getOwnListing(listingId))
          : ensureListing(this.props.getListing(listingId));
      const shouldLoadAnswers = currentListing?.attributes.publicData.state !== 'open';

    
      if (
        (currentListing?.attributes.publicData.state === 'open' ||
          currentListing?.attributes.publicData.state === 'closed' ||
          isPendingApprovalVariant) &&
        !this.state.clientPathModalWasOpenned
      ) {
        this.setState({ clientPathModalOpen: true });
        this.setState({ clientPathModalWasOpenned: true });
      }

      if (
        shouldLoadAnswers &&
        currentListing?.attributes.publicData.answers?.length > 0 &&
        this.state.conversationItems.length === 0
      ) {
        const { fetchedTicketAnswers, onShowAnswerAuthor, onShowUser } = this.props;
        const answers = await fetchedTicketAnswers;
        let conversationItems = [];
        if (answers) {
          for (let i = 0; i < answers.length; i++) {
            if (!answers[i].type) {
              const foundSameExpert = !!answers[i].expertId
                ? conversationItems.find(
                    answer => answer.listing?.data.id.uuid === answers[i].expertId
                  )
                : null;
              const listingData =
                answers[i].expertId && foundSameExpert
                  ? { data: foundSameExpert.listing }
                  : answers[i].expertId
                  ? await onShowAnswerAuthor(answers[i].expertId, true)
                  : null;
              const userId = new UUID(`${answers[i].userId}`);
              const foundSameUser = conversationItems.find(
                answer => answer.userId === answers[i].userId
              );
              const userData =
                answers[i].userId && !foundSameUser ? await onShowUser(userId) : null;
              conversationItems[i] = {
                userId: answers[i].userId,
                listing: listingData ? listingData.data : null,
                user:
                  answers[i].userId && foundSameUser
                    ? foundSameUser.user
                    : answers[i].userId
                    ? userData.data
                    : null,
                solution: answers[i].solution,
                date: answers[i].date,
                txId: answers[i].txId,
              };
            } else conversationItems[i] = answers[i];
          }
        }
        this.setState({ conversationItems: conversationItems });
      }

      const {
        onFetchConnectedTransaction,
        expert,
        expertLoaded,
        expertLoadingInProgress,
      } = this.props;
      const isProjectInProgress = !!currentListing.attributes.publicData.transactionId;
      if (isProjectInProgress && !expert && !expertLoaded && !expertLoadingInProgress) {
        onFetchConnectedTransaction(new UUID(currentListing.attributes.publicData.transactionId));
      }
    }
  }

  goBack(listing) {
    const { history } = this.props;
    const routes = routeConfiguration();

    const category = listing.attributes.publicData.category;

    if (history.length > 1) {
      history.goBack();
    } else
      history.push(
        createResourceLocatorString('SearchPage', routes, {}, { pub_category: category })
      );
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  onAskForDetails() {
    const { currentUser } = this.props;
    if (typeof window !== 'undefined' && window.localStorage) {
      let askForDetailsWarningStatus = window.localStorage.getItem('askForDetailsWarningStatus');
      if (currentUser.attributes.profile.publicData?.isVerified) {
        if (!askForDetailsWarningStatus) {
          this.setState({ isAskWarningModalOpen: true });
          this.onContactUser();
        }
      } else {
        window.location.href = 'https://calendly.com/softwaresupp/softwaresupp-recruitment';
      }
    }
  }

  onContactUser() {
    // ARCHIVE INBOX
    // remove askForDetails warning when user accepted its content
    // if(typeof window !== 'undefined' && window.localStorage) {
    //   window.localStorage.setItem('askForDetailsWarningStatus', true);
    //   this.setState({isAskWarningModalOpen: false});
    // }
    // const { currentUser, history, callSetInitialValues, params, location } = this.props;
    // if (!currentUser) {
    //   const state = { from: `${location.pathname}${location.search}${location.hash}` };
    //   // We need to log in before showing the modal, but first we need to ensure
    //   // that modal does open when user is redirected back to this listingpage
    //   callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });
    //   // signup and return back to listingPage.
    //   history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    // } else {
    //   this.setState({ enquiryModalOpen: true });
    // }

    // NEW INBOX
    // remove askForDetails warning when user accepted its content
    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem('askForDetailsWarningStatus', true);
    }
    const { currentUser, history, params, location, getListing } = this.props;
    const id = new UUID(params.id);
    const currentListing = getListing(id);
    const authorId = currentListing?.author.id.uuid;

    if (!currentUser) {
      const state = { from: `${location.pathname}` };
      // signup and return back to listingPage.
    history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
  } else if (!!authorId) {
    const inboxToken = currentUser?.attributes?.profile?.privateData?.inboxToken;
    inboxUpdateContactsByTwowayBinding(inboxToken, {
      userId1: currentUser.id.uuid,
      userId2: authorId,
    });
    history.push(
      createResourceLocatorString('ConversationPage', routeConfiguration(), { id: authorId }, {})
    );
  }
}

// ADDING ANSWER TO PROJECT
handleSolution(values, form, isOwnListing, expertConnectedToTicket) {
  const { params: rawParams, currentUser, currentUserExpertListing, getListing } = this.props;
  const id = new UUID(rawParams.id);
  const currentListing = getListing(id);
  const { solution } = values;

  if (!solution || solution?.length === 0) return null;

  const formattedOffer = solution.replace(/(\r\n|\n|\r)/gm, '\n');

  const expertsAnswered =
    currentListing.attributes.publicData.answers
      ?.filter(answer => !!answer.expertUserId && answer.expertUserId !== currentUser.id.uuid)
      ?.map(expertAnswer => expertAnswer.expertUserId)
      ?.filter(distinct) ?? [];

  const ticketSolution = {
    listingId: rawParams.id,
    userId: currentUser.id.uuid,
    type: 'add-answer',
    body: expertConnectedToTicket
      ? {
          solution: formattedOffer,
          expertId: currentUserExpertListing.id.uuid,
          date: new Date().toString(),
          expertUserId: currentUser.id.uuid,
        }
      : {
          userId: currentUser.id.uuid,
          solution: formattedOffer,
          date: new Date().toString(),
        },
  };
  changeTicketData(ticketSolution).then(() => {
    this.handleNewMention(formattedOffer, currentListing);
    const currentAnswers = this.state.conversationItems;
    ticketSolution.body.listing = currentUserExpertListing;
    ticketSolution.body.user = currentUserExpertListing ? null : currentUser;
    currentAnswers.push(ticketSolution.body);
    this.setState({ conversationItems: currentAnswers });

    if (form) setTimeout(form.reset);

    newAnswer(currentListing, solution, currentUser);
    if (
      expertsAnswered?.length > 0 ||
      currentListing.attributes.publicData.invitedUsers?.length > 0
    )
      newAnswerNotOwn(
        currentListing,
        formattedOffer,
        !expertConnectedToTicket
          ? currentUser.attributes.profile.displayName.slice(0, -2)
          : currentUserExpertListing.attributes.title,
        expertsAnswered
      );
  });
}

handleNewMention(formattedOffer, currentListing) {
  const { currentUser } = this.props;
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  const mailRegex = /([A-Za-z0-9_\-+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})/gi; //eslint-disable-line

  const mentionedEmails = formattedOffer
    .split(nonWhiteSpaceSequence)
    .map(word => {
      if (word.match(mailRegex)) {
        return word.match(mailRegex);
      }
      return null;
    })
    ?.filter(mail => !!mail)
    ?.flat(1);

  const formattedMails = currentListing?.attributes.publicData.invitedUsers
    ? mentionedEmails.filter(
        mail =>
          !currentListing.attributes.publicData.invitedUsers.find(
            currentUser => mail === currentUser
          )
      )
    : mentionedEmails;

  if (formattedMails?.length === 0) {
    const body = {
      userId: currentUser.id.uuid,
      listingId: currentListing.id.uuid,
      emails: formattedMails,
      isProject: true,
    };

    let mentionMailed = newMention(
      mentionedEmails,
      currentListing,
      currentUser,
      formattedOffer,
      true
    );
    if (mentionMailed === undefined) {
      addUserToTicket(body);
    }
  }
}

handleChangeTicketAnswer(index, type, body, currentListing, currentUser) {
  const changeTicketBody = {
    index,
    type,
    listingId: currentListing.id.uuid,
    userId: currentUser.id.uuid,
    body,
  };
  changeTicketData(changeTicketBody).then(() => {
    if (type === 'delete')
      this.setState({
        conversationItems: this.state.conversationItems.filter(
          (answer, i) => i > index || i < index
        ),
      });
    else {
      const answers = this.state.conversationItems;
      answers[index].completionHours = body.completionHours;
      this.setState({ answers: answers });
    }
  });
}

handleSidebarVisibility(state) {
  this.setState({ sidebarVisible: state });
}

onOpenProjectBoard() {
  const { params: rawParams, history } = this.props;
  const listingId = new UUID(rawParams.id);
  history.push(
    createResourceLocatorString(
      'ProjectBoardPage',
      routeConfiguration(),
      { id: listingId.uuid },
      {}
    )
  );
}

render() {
  const {
    unitType,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    productSoftware: softwareConfig,
    productSkills: skillsStylesConfig,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserExpertListing,
    authScopes,
    offers,
    offersLoading,
    allExperts,
    softwareExperts,
    // onBuyService,
    history,
    productSoftware,
    productSkills,
  } = this.props;

  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));
  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');

  const listingType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = 'description';

  const category = currentListing?.attributes.publicData?.category;
  const params = { slug: listingSlug, category: `${category}s`, ...rawParams };

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    budget = '',
    scale = '',
    publicData,
  } = currentListing.attributes;


  const expertsProfiles = [
    {
      software: 'pipedrive',
      img: general_expert,
      link: '/experts/pawel-kusina/5f52472a-db9a-44bf-b419-a0afbd9cf03b',
    },
    {
      software: 'other',
      img: general_expert,
      link: '/experts/pawel-kusina/5f52472a-db9a-44bf-b419-a0afbd9cf03b',
    },
    {
      software: 'magento',
      img: magento_expert,
      link: '/experts/ranaprathap-madhala/60758a41-9899-4b56-a2b9-3e89f1b1adcd',
    },
    {
      software: 'salesforce',
      img: salesforce_expert,
      link: '/experts/jeff/5f7e3fd5-f6f8-4cd4-9b9f-f30e7dfad275',
    },
    {
      software: 'zapier',
      img: zapier_expert,
      link: '/experts/mohammad-zubair-sadiek/606f6fc7-ebaf-4b84-a728-fa783dcdd724',
    },
    {
      software: 'zoho',
      img: zoho_expert,
      link: '/experts/divya/5ff6ce73-4bf5-4dbc-8984-6ff57a100b85',
    },
    {
      software: 'hubSpot',
      img: hubspot_expert,
      link: '/experts/roman-yakovchuk/60c32c56-5fef-4410-b2be-65ec808f6172',
    },
    {
      software: 'shopify',
      img: shopify_expert,
      link: '/experts/mark-tristan-patena/60c8797d-e289-43e4-aa72-b36f71846aca',
    },
    {
      software: 'webflow',
      img: webflow_expert,
      link: '/experts/slawosz-hodura/6040c8df-c782-45a4-9de5-cb8c65de575b',
    },
    {
      software: 'wordpress',
      img: wordpress_expert,
      link: '/experts/maricon-espinosa/623be1dc-12a5-4b73-ac80-554b21a147bc',
    },
    {
      software: 'woocommerce',
      img: wordpress_expert,
      link: '/experts/maricon-espinosa/623be1dc-12a5-4b73-ac80-554b21a147bc',
    },
    {
      software: 'monday',
      img: monday_expert,
      link: '/experts/tim-little/6153635c-1d8c-4603-8f8f-97b1cd168d44',
    },
    {
      software: 'aws',
      img: aws_expert,
      link: '/experts/maks-piechota/5fd0f7c3-2d08-4622-81b9-565085f49d8f',
    },
    {
      software: 'javascript',
      img: javascript_expert,
      link: '/experts/mateusz-krawczyk/62e7bd9f-1227-4b49-84f7-a1d1da249a3d',
    },
    {
      software: 'net',
      img: net_expert,
      link: '/experts/jarek-danielak/60b52b61-057f-4f51-b5c0-628e65966d0e',
    },
    {
      software: 'php',
      img: php_expert,
      link: '/experts/mateusz-niziolek/61f3b3a6-bd1b-45e2-aff5-3309fea6575d',
    },
    {
      software: 'powerBI',
      img: powerBI_expert,
      link: '/experts/ewelina-pietryga/5fda3b2f-3182-49a4-a585-4184b9e2478b',
    },
    {
      software: 'python',
      img: python_expert,
      link: '/experts/rieser-stern/626a8816-10ad-4346-a074-7738e3da0df8',
    },
    {
      software: 'react',
      img: react_expert,
      link: '/experts/jan-szymanski/6159b860-fec5-4e37-802f-c8b96d1ffc2b',
    },
  ];

  let filteredExpertProfile = null;
  if (publicData?.softwares) {
    filteredExpertProfile = expertsProfiles.filter(
      expert => expert.software === publicData?.softwares[0]
    )[0];
  }

  let expertProfile = filteredExpertProfile ? filteredExpertProfile : expertsProfiles[0];

  const messageFormatting = '';
  const isSolved = publicData.state === 'closed';
  const isAwaiting = publicData.state === 'awaiting';
  const isClosed = currentListing.attributes.state === 'closed';

  const isOpen = currentListing.attributes.state === 'published';
  const isInProgress =
    publicData.state === 'inProgress' || publicData.state === 'serviceInProgress';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // Zmiana Kroku dla zamknietego projektu

  let PathStep = 1;
  if (isOpen && !isSolved) {
    PathStep = 2;
  }

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="JobPage" params={params} search={location.search} />;
  }

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const bookingTitle = <FormattedMessage id="ListingPage.bookingTitle" />;

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found

    return <AuthenticationPage />;
  } else if (showListingError) {
    // Other error in fetching listing

    const errorTitle = intl.formatMessage({
      id: `ListingPage.errorLoadingListingTitle${messageFormatting}`,
    });

    return (
      <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage
                  id={`ListingPage.errorLoadingListingMessage${messageFormatting}`}
                />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: `ListingPage.loadingListingTitle`,
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id={`ListingPage.loadingListingMessage`} />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const isAdmin =
      currentUser && currentUser.id
        ? currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID
        : null;
    const isUser =
      userAndListingAuthorAvailable &&
      (!!currentListing.attributes.publicData.allowedUser?.find(
        userMail => userMail === currentUser.attributes.email
      ) ||
        this.state.allowedUsers?.find(userMail => userMail === currentUser.attributes.email));
    const isUserPrivilegedWithCommunicationFlag = currentUser?.attributes?.profile?.publicData?.adminPrivileges?.includes(
      'openCommunication'
    );
    const showContactUser =
      (isUser || isAdmin || isUserPrivilegedWithCommunicationFlag) &&
      authorAvailable &&
      (!currentUser || (currentUser && !isOwnListing));
    const currentUserHasStripeConnected =
      currentUser && currentUser.attributes?.profile.publicData?.hasStripeConnected;
    const expertListingIsPendingApproval = currentUserHasUnpublishedExpertListing;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
    const priceForExpert = price ? new Money(price?.amount, 'USD') : null;
    let { formattedPrice } = priceData(
      !currentUser || currentUserHasExpertListing ? priceForExpert : price,
      intl
    );
    formattedPrice = formattedPrice?.slice(0, formattedPrice.length - 3); // cut decimal part from price
    const onAdminOffer = () => {
      const isAdmin = true;
      handleBookingSubmitJobOffer(isAdmin);
    };

    const onCancelSummaryOffer = () => {
      const body = {
        batchId: currentListing.attributes.publicData.batch?.id,
        updateListingBody: {
          id: currentListing.id.uuid,
          publicData: {
            state: 'open',
            batch: {
              canceled: true,
            },
          },
        },
      };
      cancelScheduledSend(body);
    };

    const onSendSummaryOffer = () => {
      const body = {
        listingId: listingId.uuid,
        softwareConfig: productSoftware,
        skillsConfig: productSkills,
        sendImmediately: true,
      };
      sendSummaryOffer(body);
    };

    const handleBookingSubmitJobOffer = (isAdmin = false) => {
      const listingId = new UUID(this.props.params.id);
      const isPendingApprovalVariant =
        this.props.params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
      const isDraftVariant = this.props.params.variant === LISTING_PAGE_DRAFT_VARIANT;
      const currentListing =
        isPendingApprovalVariant || isDraftVariant
          ? ensureOwnListing(this.props.getOwnListing(listingId))
          : ensureListing(this.props.getListing(listingId));
      this.props.history.push(
        createResourceLocatorString(
          'NewOfferPage',
          routeConfiguration(),
          { id: this.props.params.id },
          {}
        ),
        { jobListing: currentListing, isAdmin: isAdmin }
      );
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);



    if(currentListing?.id?.uuid){
      if(this.state.counterRender == 0){
        this.setState({counterRender:1})
        let postId = currentListing.id.uuid;
        let postPrice = currentListing.attributes.price.amount / 100
        let image
        if(currentListing.images[0]?.attributes?.variants?.facebook?.url) {
          image = {'image':currentListing.images[0].attributes.variants['scaled-large'].url}
        }
        let basicPayload = {
          "id": postId,
          "title": currentListing.attributes.title,
          "budget": "20-50$",
          "type": "hourly rate",
        }

      axios.post(`https://softwaresupp.com/api/opengraph-add`,JSON.stringify({...basicPayload, ...image}), {
  headers: {
    // Overwrite Axios's automatically set Content-Type
    'Content-Type': 'application/transit+json'
  }}).then( res => {
        })
      } 
            
      }

    const opImage = "https://softwaresupp.s3.eu-central-1.amazonaws.com/og/"+ currentListing.id.uuid +".png"
    const facebookImages = [{
    "height": 630,
    "width": 1200,
    "url": opImage,
    "name": "facebook"}]
    // const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = [{
    "height": 630,
    "width": 1200,
    "url": opImage,
    "name": "twitter"}]
    // const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = [opImage];
    // const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'ListingPage.schemaTitle' }, { title, siteTitle });

    const unitTranslationKey = 'ListingPage.priceRange';
    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    const onChangeState = async () => {
      const currentState = this.state.listingState ?? currentListing.attributes.publicData.state;
      const stateToUpdate = currentState !== 'closed' ? 'closed' : 'open';
      changeTicketData({
        listingId: currentListing.id.uuid,
        userId: currentUser.id.uuid,
        type: 'change-state',
        body: { state: stateToUpdate },
      });
      await sleep(500);
      this.setState({ listingState: stateToUpdate });
      sendListingClosed(currentListing, currentAuthor);
      if (authScopes && authScopes[0] === 'user:limited')
        sendListingClosedUser(currentListing, currentAuthor);
    };

    const jobScope = currentListing.attributes.publicData.scope;

    const userConnectedToProject = !!currentListing.attributes.publicData.invitedUsers?.find(
      mail => mail === currentUser?.attributes.email
    );
    const expertConnectedToProject =
      Array.isArray(currentListing.attributes.publicData.expert) &&
      currentListing.attributes.publicData.expert?.includes(currentUserExpertListing?.id.uuid);

    const showConversation =
      (isOwnListing || userConnectedToProject || expertConnectedToProject) &&
      (isInProgress || isSolved);

    const projectBoardAllowed =
      showConversation ||
      currentUser?.attributes.profile.publicData.adminPrivileges?.includes('browse-project-boards');

    const showPaymentModal =
      (location?.state?.paymentSuccess || params.slug === 'paymentSuccessful') &&
      !this.state.paymentSuccessModalSeen;
    const showContactExpert = params.slug === 'paymentSuccessful';

    const sidebarVisible = this.state.sidebarVisible;
    const projectStatus = currentListing.attributes.publicData.state;

    let currentTab;
    if (isOwnListing && !isInProgress) {
      currentTab = 'projects-posted';
    } else if (showConversation) {
      currentTab = 'projects-ongoing';
    } else if (isOwnListing && projectStatus === 'closed') {
      currentTab = 'projects-completed';
    }

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        scale={scale}
        budget={budget}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSideNavigation className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperSideNav
            className={classNames(
              currentUser ? css.navigation : css.navigationNone,
              !sidebarVisible && css.navigationHidden
            )}
          >
            <Sidebar
              tab={currentTab}
              isAdmin={isAdmin}
              isExpert={currentUserHasExpertListing}
              isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
              isVisible={this.state.sidebarVisible}
              setVisibility={this.handleSidebarVisibility}
            />
          </LayoutWrapperSideNav>
          <LayoutWrapperMain className={css.NewContainerStyle}>
            <Row>
              <Col md={9} className={classNames(css.mobileOrder)}>
                <Container fluid className={classNames(css.containerPadding)}>
                  <Row>
                    <Col sx>
                      <InlineTextButton
                        className={css.goBackButton}
                        onClick={() => this.goBack(currentListing)}
                      >
                        <ArrowLeft className={css.goBackIcon} />
                        Back
                      </InlineTextButton>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <SectionExpertiseMaybe richTitle={richTitle} />
                    </Col>
                    <Col xs={12}>
                      <div className={classNames(css.project_info, 'd-flex', 'align-items-center')}>
                        <div className={css.projectInfoItem}>
                          <span className={css.header_gray_color}>
                            <FormattedMessage id="ListingPage.datePublished" />
                          </span>
                          <span className={css.dateValue}>
                            {currentListing.attributes.createdAt.toString().slice(4, 15)}
                          </span>
                        </div>

                        <div className={css.projectInfoItem}>
                          <span className={css.header_gray_color}>
                            <FormattedMessage id="ListingPage.dateValid" values={{ br: '' }} />
                          </span>
                          <span className={css.dateValue}>{publicData.expires}</span>
                        </div>
                        {!isInProgress ? (
                          <div className={css.projectInfoItem}>
                            <span className={css.header_gray_color}>
                              <FormattedMessage id={unitTranslationKey} />
                            </span>

                            <span className={css.priceValue}>
                              $20-$50/h 

                            </span>
                            {/* <span className={formattedPrice ? css.priceValue : css.priceValueUnset}> */}
                            {/*   {formattedPrice ? ( */}
                            {/*     formattedPrice */}
                            {/*   ) : ( */}
                            {/*     <FormattedMessage id={'ListingCard.unknownBudget'} /> */}
                            {/*   )} */}
                            {/* </span> */}
                          </div>
                        ) : null}

                        {/* <div className={css.projectInfoItem}> */}
                        {/*   <FormattedMessage id="ListingPage.type" values={{ br: '' }} /> */}
                        {/*   <span className={css.dateValue}>{'hourly rate'}</span> */}
                        {/* </div> */}
                      </div>
                    </Col>
                    <div className={css.devider}></div>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <SectionDescriptionMaybe
                        description={description}
                        jobDetailsLink={publicData.linkJobDetails}
                        isJobListing
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <SectionImages
                        title={title}
                        listing={currentListing}
                        imageCarouselOpen={this.state.imageCarouselOpen}
                        onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                        handleViewPhotosClick={handleViewPhotosClick}
                        onManageDisableScrolling={onManageDisableScrolling}
                        isAdmin={isAdmin}
                        hasExpertProfile={currentUserHasExpertListing}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <SectionJobScopeMaybe scope={jobScope} />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <SectionFeaturesMaybe options={softwareConfig} publicData={publicData} />
                      <SectionSkillsMaybe options={skillsStylesConfig} publicData={publicData} />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <SectionMapMaybe
                        geolocation={geolocation}
                        publicData={publicData}
                        listingId={currentListing.id}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <SectionShareMaybe
                        messageFormatting={messageFormatting}
                        listingTitle={title}
                        isApproved={isApproved}
                        listingCategory={category}
                        listingId={currentListing.id.uuid}
                        listingType={'job'}
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>

              <Col md={3} className={css.rightColumnNew}>
                <Container>
                  <Row>
                    <Col xs={12}>
                      {/* Przyciski */}
                      {/* {currentUser && isOwnListing ? (
                        <div classnames={css.MenuContainer}>
                          <button onClick={this.openDropMenu} className={css.MenuButton}>
                            Menage Your Project
                          </button>

                          {Boolean(this.state.anchorEl) ? (
                            <div className={css.MenuDrop}>
                              <ul>
                                {isPendingApprovalVariant ? (
                                  <li>
                                    <a href={'/' + currentListing.id.uuid + '/board'}>
                                      Open Project Board
                                    </a>
                                  </li>
                                ) : null}

                                {!isPendingApprovalVariant && isOwnListing ? (
                                  <li>
                                    <a href={'/' + currentListing.id.uuid + '/board'}>
                                      Open Project Board
                                    </a>
                                  </li>
                                ) : null}
                                {isOwnListing ? (
                                  <li>
                                    <a
                                      href={
                                        '/' +
                                        createSlug(currentListing.attributes.title) +
                                        '/' +
                                        currentListing.id.uuid +
                                        '/edit/job'
                                      }
                                    >
                                      Edit project
                                    </a>
                                  </li>
                                ) : null}

                         
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      ) : null} */}

                      <BookingPanel
                        handleNewJobOffer={handleBookingSubmitJobOffer}
                        className={css.bookingPanel}
                        listing={currentListing}
                        isOwnListing={isOwnListing}
                        unitType={unitType}
                        onRequestBooking={this.handleRequestBooking}
                        title={bookingTitle}
                        authorDisplayName={authorDisplayName}
                        onManageDisableScrolling={onManageDisableScrolling}
                        isUser={isUser}
                        isAdmin={isAdmin}
                        hasExpertProfile={currentUserHasExpertListing}
                        expertStack={
                          currentUserHasExpertListing
                            ? currentUserExpertListing.attributes.publicData.softwares
                            : null
                        }
                        currentUser={currentUser}
                        handleShowRejectOfferModal={this.handleShowRejectOfferModal}
                        handleCloseRejectOfferModal={this.handleCloseRejectOfferModal}
                        currentUserHasStripeConnected={currentUserHasStripeConnected}
                        expertListingIsPendingApproval={expertListingIsPendingApproval}
                        showContactUser={showContactUser}
                        showContactExpert={showContactExpert}
                        onContactUser={this.onAskForDetails}
                        onAskForDetails={this.onAskForDetails}
                        onAdminOffer={onAdminOffer}
                        onCancelSummaryOffer={onCancelSummaryOffer}
                        onSendSummaryOffer={onSendSummaryOffer}
                        editParams={{
                          id: listingId.uuid,
                          slug: listingSlug,
                          type: listingType,
                          tab: listingTab,
                          category,
                        }}
                        messageFormatting={messageFormatting}
                        isSolved={
                          this.state.listingState !== null
                            ? this.state.listingState === 'closed'
                            : isSolved
                        }
                        onChangeState={onChangeState}
                        openBookModal={location?.state?.openBookModal}
                        isFreeBooking={!!location?.state?.freeBooking}
                        projectBoardAllowed={projectBoardAllowed}
                        onOpenProjectBoard={this.onOpenProjectBoard}
                        onOpenClientPathModal={() => this.setState({ clientPathModalOpen: true })}
                        allExperts={allExperts}
                        softwareExperts={softwareExperts}
                      />
                    </Col>
                    <Col xs={12}>
                      {/* Oferty */}

                      {isOwnListing && !isInProgress && !isSolved && !isPendingApprovalVariant ? (
                        <SectionOffersMaybe
                          offers={offers}
                          offersLoading={offersLoading}
                          history={this.props.history}
                        />
                      ) : null}
                    </Col>
                    {isOwnListing && !isInProgress && !isSolved && offers.length === 0 ? (
                      <Col xs={12}>
                        <>
                          <div className={css.adminOfferValues}>
                            <div className={css.adminOfferValuesTitleWrapper}>
                              <strong className={css.adminOfferValuesTitle}>
                                <FormattedMessage
                                  id={'BookingPanel.platformValuesTitle'}
                                  values={{ br: <br /> }}
                                />
                              </strong>
                            </div>
                            <FormattedMessage
                              id={'BookingPanel.platformValues'}
                              values={{
                                br: <br />,
                                c: <GoCheck size={'1.2em'} />,
                              }}
                            />
                          </div>
                        </>
                      </Col>
                    ) : null}

                    {!isInProgress && !isOwnListing ? (
                    <Col>
                      <div className={css.benefits}>
                          <div className={css.benefitsHeader}>
                            Benefits of joining SoftwareSupport
                          </div>
                          <div className={css.benefitsList}>
                            <ul>
                              <li><img src={"https://softwaresupp.com/s3/assets/check.png"} />Guaranteed payment</li>
                              <li><img src={"https://softwaresupp.com/s3/assets/check.png"} />24 hours to get work</li>
                              <li><img src={"https://softwaresupp.com/s3/assets/check.png"} />20 new jobs every week</li>
                            </ul>
                          </div>

                      </div>
                    </Col> ) : null }
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {showConversation ||
                      currentUser?.attributes.profile.publicData.adminPrivileges?.includes(
                        'show-conversation-panels'
                      ) ? (
                        <div className={css.messages} id="messages">
                          <MessagesPanel
                            answers={
                              this.state.conversationItems ? this.state.conversationItems : null
                            }
                            currentListing={currentListing}
                            isOwnListing={isOwnListing}
                            currentUserExpertListing={
                              currentUserHasExpertListing ? currentUserExpertListing : null
                            }
                            currentUser={currentUser}
                            isSubscriptionActive={true}
                            currentUserHasExpertListing={currentUserHasExpertListing}
                            isSolved={isSolved}
                            onChangeTicketAnswer={(index, type, body) =>
                              this.handleChangeTicketAnswer(
                                index,
                                type,
                                body,
                                currentListing,
                                currentUser
                              )
                            }
                            isProject
                          />
                          {currentUser && !isClosed && !isSolved && !isAwaiting ? (
                            <SolutionForm
                              className={css.applicationForm}
                              formId="BookingPanel"
                              submitButtonWrapperClassName={css.submitButtonWrapper}
                              onSubmit={(values, form) =>
                                this.handleSolution(
                                  values,
                                  form,
                                  isOwnListing,
                                  expertConnectedToProject
                                )
                              }
                              isOwnListing={isOwnListing}
                              hasExpertProfile={currentUserHasExpertListing}
                              expertListingIsPendingApproval={
                                currentUserExpertListing?.attributes.state ===
                                LISTING_STATE_PENDING_APPROVAL
                              }
                              userConnectedToTicket={userConnectedToProject}
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>

            {/* <SectionLinksMaybe
                        linkedin={publicData ? publicData.linkLinkedin : null}
                        calendly={publicData ? publicData.linkCalendly : null}
                        web={publicData ? publicData.linkWeb : null}
                      /> */}

            {/* {!isInProgress  ? <div className={css.dates}></div> : null} */}

            {/* <SectionTagsMaybe
                        softwares={publicData.softwares}
                        skills={publicData.skills}
                      /> */}
            {/* <SectionLinksJobMaybe
                        className={css.jobLinkMobile}
                        jobDetailsLink={publicData ? publicData.linkJobDetails : null}
                        projectBoardAllowed={projectBoardAllowed}
                        onOpenProjectBoard={this.onOpenProjectBoard}
                      /> */}

            <Modal
              id="ListingPage.askWarningModal"
              contentClassName={css.enquiryModalContent}
              isOpen={this.state.isAskWarningModalOpen}
              onClose={() => this.setState({ isAskWarningModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              usePortal
            >
              <div>
                <div className={css.modalTextWrapper}>
                  <FormattedMessage
                    id="ListingPage.infoAskForDetailsWarning"
                    values={{ br: <br /> }}
                  />
                </div>
                <PrimaryButton
                  className={css.infoAcceptButton}
                  onClick={() => this.onContactUser()}
                >
                  <FormattedMessage id="ListingPage.infoAcceptButton" />
                </PrimaryButton>
              </div>
            </Modal>
            <Modal
              id="ListingPage.paymentSuccess"
              contentClassName={css.enquiryModalContent}
              isOpen={showPaymentModal}
              onClose={() => this.setState({ paymentSuccessModalSeen: true })}
              onManageDisableScrolling={onManageDisableScrolling}
              usePortal
            >
              <div className={css.addServiceModal}>
                <h1>Great success, {currentUser?.attributes.profile.displayName.slice(0, -2)}</h1>
                <p className={css.addServiceModalText}>
                  Your payment was successful. Your dedicated SoftwareSupp expert started working on
                  your project.
                  <br />
                  You can follow the progress on the Project Board. There you have access to tickets
                  along with their status and number of completed hours.
                </p>
                <PrimaryButton className={css.adminOfferButton} onClick={this.onOpenProjectBoard}>
                  <AiOutlineFundProjectionScreen size={28} className={css.openProjectBoardIcon} />
                  {'\u00A0'}
                  {'\u00A0'}
                  <FormattedMessage id="ListingPage.goToProjectBoard" />
                </PrimaryButton>
              </div>
            </Modal>
            <ClientPathModal
              // Modal ktory pokazuje się po wejsciu na projekt.
              id="ListingPage.clientPath"
              step={PathStep}
              listingId={currentListing.id.uuid}
              offersCount={currentListing.attributes.publicData?.offersCount}
              isPendingApproval={isPendingApprovalVariant}
              contentClassName={css.enquiryModalContent}
              isOpen={
                this.state.clientPathModalOpen &&
                isOwnListing &&
                (isPendingApprovalVariant || isOpen)
              }
              onCloseModal={() => this.setState({ clientPathModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              listingInvoice={currentListing.attributes.privateData?.depositInvoice}
              allExperts={allExperts}
              softwareExperts={softwareExperts}
              // onBuyService={onBuyService}
              primarySoftware={currentListing?.attributes.publicData?.primarySoftware}
              history={history}
              usePortal
            />
            {/* )} */}

            {!isInProgress ? (
              <AcceptMeetingRequest
                className={css.modalBooking}
                listing={currentListing}
                onManageDisableScrolling={onManageDisableScrolling}
                handleNewJobOffer={handleBookingSubmitJobOffer}
                isUser={isUser}
                isAdmin={isAdmin}
                isOwnListing={isOwnListing}
                hasExpertProfile={currentUserHasExpertListing}
                currentUser={currentUser}
                handleShowRejectOfferModal={this.handleShowRejectOfferModal}
                handleCloseRejectOfferModal={this.handleCloseRejectOfferModal}
                isJobListing={true}
                messageFormatting={messageFormatting}
                expertStack={
                  currentUserHasExpertListing
                    ? currentUserExpertListing.attributes.publicData.softwares
                    : null
                }
                currentUserHasStripeConnected={currentUserHasStripeConnected}
                expertListingIsPendingApproval={expertListingIsPendingApproval}
                showContactUser={showContactUser}
                onContactUser={this.onAskForDetails}
                onAskForDetails={this.onAskForDetails}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                  category,
                }}
                isSolved={isSolved}
              />
            ) : null}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  offers: [],
  fetchOffersError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  skillsStylesConfig: array,
  softwareSonfig: array,
};

const mapStateToProps = state => {
  const { isAuthenticated, authScopes } = state.Auth;
  const {
    showListingError,
    enquiryModalOpenForListingId,
    fetchedExpertServices,
    expert,
    expertLoaded,
    expertLoadingInProgress,
    fetchedTicketAnswers,
    offers,
    offersLoading,
    fetchOffersError,
    allExperts,
    softwareExperts,
  } = state.ListingPage;
  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasExpertListing,
    currentUserExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;
  const { productSoftware, productSkills } = state.marketplaceData;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasExpertListing,
    currentUserExpertListing,
    fetchedExpertServices,
    authScopes,
    expert,
    expertLoaded,
    expertLoadingInProgress,
    fetchedTicketAnswers,
    offers,
    offersLoading,
    fetchOffersError,
    currentUserHasUnpublishedExpertListing,
    allExperts,
    softwareExperts,
    productSoftware,
    productSkills,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onShowUser: userId => dispatch(showUser(userId)),
  onFetchConnectedTransaction: listingId => dispatch(fetchConnectedTransaction(listingId)),
  onShowAnswerAuthor: listingId => dispatch(showMessageOwner(listingId)),
  // onBuyService: (data) => dispatch(buyService(data))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
