/**
 * Usage without sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w" />
 *
 * Usage with sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *     sizes="(max-width: 600px) 100vw, 50vw"
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w"
 *     sizes="(max-width: 600px) 100vw, 50vw" />
 *
 *   // This means that below 600px image will take as many pixels there are available on current
 *   // viewport width (100vw) - and above that image will only take 50% of the page width.
 *   // Browser decides which image it will fetch based on current screen size.
 *
 * NOTE: for all the possible image variant names and their respective
 * sizes, see the API documentation.
 */

import React from 'react';
import { arrayOf, string, oneOfType, object } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import css from './ResponsiveImage.css';

const ResponsiveImage = props => {
  const { className, rootClassName, alt, noImageMessage, image, software, variants, onlyImgUrl, ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (!onlyImgUrl && (image == null || variants.length === 0)) {

    let softwareClass = null;

    if (software) {
      switch (software) {
        case "pipedrive":
          softwareClass = css.pipedriveImage;
          break;
        case "zoho":
          softwareClass = css.zohoImage;
          break;
        case "hubSpot":
          softwareClass = css.hubspotImage;
          break;
        case "shopify":
          softwareClass = css.shopifyImage;
          break;
        case "magento":
          softwareClass = css.magentoImage;
          break;
        case "powerBI":
          softwareClass = css.powerBIImage;
          break;
        case "monday":
          softwareClass = css.mondayImage;
          break;
        case "livechat":
          softwareClass = css.livechatImage;
          break;
        case "woodpecker":
          softwareClass = css.woodpeckerImage;
          break;
        case "zapier":
          softwareClass = css.zapierImage;
          break;
        case "salesforce":
          softwareClass = css.salesforceImage;
          break;
        case "msDynamics":
          softwareClass = css.msDynamicsImage;
          break;
        case "bigCommerce":
          softwareClass = css.bigCommerceImage;
          break;
        case "sap":
          softwareClass = css.sapImage;
          break;
        case "office":
          softwareClass = css.officeImage;
          break;
        case "tableau":
          softwareClass = css.tableauImage;
          break;
        case "googleAnalytics":
          softwareClass = css.gaImage;
          break;
        case "gsuite":
          softwareClass = css.gSuiteImage;
          break;
        case "matomo":
          softwareClass = css.matomoImage;
          break;
        case "piwik":
          softwareClass = css.piwikImage;
          break;
        case "twilio":
          softwareClass = css.twilioImage;
          break;
        case "klaviyo":
          softwareClass = css.klaviyoImage;
          break;
        case "integromat":
          softwareClass = css.integromatImage;
          break;
        case "autopilot":
          softwareClass = css.autopilotImage;
          break;
        case "sharetribe":
          softwareClass = css.sharetribeImage;
          break;
        case "webflow":
          softwareClass = css.webflowImage;
          break;
        case "figma":
          softwareClass = css.figmaImage;
          break;
        case "adobexd":
          softwareClass = css.adobexdImage;
          break;
        case "invision":
          softwareClass = css.invisionImage;
          break;
        case "wordpress":
          softwareClass = css.wordpressImage;
          break;
        case "googleAds":
          softwareClass = css.googleAdsImage;
          break;
        case "typeform":
          softwareClass = css.typeformImage;
          break;
        case "airtable":
          softwareClass = css.airtableImage; 
          break;
        case "clickup":
          softwareClass = css.clickupImage;
          break;
        case "nocrm":
          softwareClass = css.nocrmImage;
          break;
        case "woocommerce":
          softwareClass = css.woocommerceImage;
          break;
        case "paperform":
          softwareClass = css.paperformImage;
          break;
        case "hyperise":
          softwareClass = css.hyperiseImage;
          break;
        case "prestashop":
          softwareClass = css.prestashopImage;
          break;
        case "addicnet":
          softwareClass = css.addicnetImage;
          break;
        case "satismeter":
          softwareClass = css.satismeterImage;
          break;
        case "chatbot":
          softwareClass = css.chatbotImage;
          break;
        case "vouchery":
          softwareClass = css.voucheryImage;
          break;
        case "ringOver":
          softwareClass = css.ringOverImage;
          break;
        case "harvestr":
          softwareClass = css.harvestrImage;
          break;
        case "mailchimp":
          softwareClass = css.mailchimpImage;
          break;
        case "livestorm":
          softwareClass = css.livestormImage;
          break;
        case "activeCampaign":
            softwareClass = css.activeCampaignImage;
            break;
        case "slack":
          softwareClass = css.slackImage;
          break;
        case "sketch":
          softwareClass = css.sketchImage;
          break;
        case "pandaDoc": 
        softwareClass = css.pandaDocImage;
          break;
        case "intercom":
          softwareClass = css.intercomImage;
          break;
        case "facebookAds":
          softwareClass = css.facebookAdsImage;
          break;
        case "notion":
          softwareClass = css.notionImage;
          break;
        case "asana":
          softwareClass = css.asanaImage;
          break;
        case "atlassian":
          softwareClass = css.atlassianImage;
          break;
        case "jira":
          softwareClass = css.jiraImage;
          break;
        case "aws":
          softwareClass = css.awsImage;
          break;
        case "adobeIllustrator":
          softwareClass = css.adobeIllustratorImage;
          break;
        case "adobePhotoshop":
          softwareClass = css.adobePhotoshopImage;
          break;
        case "googleDocs":
          softwareClass = css.googleDocsImage;
          break;
        case "linkedInAds":
          softwareClass = css.linkedInAdsImage;
          break;
        case "microsoftAzure":
          softwareClass = css.microsoftAzureImage;
          break;
        case "marketo":
          softwareClass = css.marketoImage;
          break;
        case "mautic":
          softwareClass = css.mauticImage;
          break;
        case "other":
          softwareClass = css.softwaresuppImage;
          break;
        default:
          softwareClass = css.softwaresuppImage;
      }
    }
    else {
      softwareClass = css.softwaresuppImage;
    }

    const softwareClasses = classNames(rootClassName || css.root, css.noImageContainer, softwareClass, className);
      return (
        <div className={softwareClasses}></div>
      );
    /* eslint-enable jsx-a11y/img-redundant-alt */
  }

  let srcSet;
  if(!!onlyImgUrl) {
    srcSet = onlyImgUrl;
  } else {
    const imageVariants = image?.attributes?.variants;
    srcSet = variants
      .map(variantName => {
        const variant = imageVariants[variantName];
  
        if (!variant) {
          // Variant not available (most like just not loaded yet)
          return null;
        }
        return `${variant.url} ${variant.width}w`;
      })
      .filter(v => v != null)
      .join(', ');
  }

  const imgProps = {
    className: classes,
    alt,
     srcSet,
    ...rest,
  };

  // alt prop already defined above
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...imgProps} />;
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  software: null,
  noImageMessage: null,
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: oneOfType([propTypes.image, object]),

  variants: arrayOf(string).isRequired,
  noImageMessage: string,
};

export default ResponsiveImage;
