import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { ResponsiveImage } from '../../components';

import css from './SearchMapLabel.css';

class SearchMapLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(isSameListing && hasSamePrice && hasSameActiveStatus && hasSameRefreshToken);
  }

  render() {
    const { className, rootClassName, intl, listing, onListingClicked, isActive } = this.props;
    const currentListing = ensureListing(listing);
    const { title } = currentListing.attributes;
    
    const classes = classNames(rootClassName || css.root, className);
    const classesExpert = classNames(css.root, className);
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });

    if (listing.attributes.publicData.category === "expert") {
      return (
        <button className={classesExpert} onClick={() => onListingClicked(currentListing)}>
          <div className={css.caretShadow} />
          <div className={listing.author.profileImage ? css.imageLabelExpert : css.imageLabel}>
            {listing.author.profileImage ?
              <img className={css.profileImage} alt={title} srcSet={listing.author.profileImage.attributes.variants["square-small"].url}/>
              :
              <div className={classNames(css.profileImageEmpty)}>
                {title}
              </div>
            }
          </div>
          <div className={caretClasses} />
        </button>
      )
    }

    return (
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        <div className={css.caretShadow} />
        {/* <div className={priceLabelClasses}>{(price && price.amount > 0) || budget ? formattedPrice:
        <FormattedMessage id="ListingCard.unknownBudget" />
         }</div> */}
        <ResponsiveImage
          rootClassName={css.imageLabel}
          alt={listing.attributes.title}
          noImageMessage={intl.formatMessage({ id: 'SearchMapInfoCard.noImage' })}
          software={listing.attributes.publicData.primarySoftware || listing.attributes.publicData.softwares[0]}
          variants={['landscape-crop', 'landscape-crop2x']}
          sizes="250px"
        />
        <div className={caretClasses} />
      </button>
    );
  }
}

SearchMapLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

const { func, string } = PropTypes;

SearchMapLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapLabel);
