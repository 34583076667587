import React, { useState, useEffect } from 'react';
import { updateVerifiedSoftwares } from '../../util/api';
import { FormattedMessage } from '../../util/reactIntl';
import Button from '../../components/Button/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './ExpertPage.css';

const VendorVerificationPanel = props => {
  const {
    currentUser,
    currentListing,
    expertVerifiedSoftwares,
    verifiedSoftwaresCallback,
    isOwnListing,
    productSoftware
  } = props;

  const vendorSoftwares = currentUser?.attributes?.profile?.publicData?.vendorSoftwares;
  const expertSoftwares = currentListing?.attributes?.publicData?.softwares;
  const isUserVendor = !!(vendorSoftwares?.length > 0);
  const matchingVendorSoftwaresToVerify = isUserVendor ? expertSoftwares?.filter((soft) => {
    return vendorSoftwares.includes(soft) && !expertVerifiedSoftwares?.includes(soft);
  }) : null;
  const matchingVendorSoftwaresToCancel = isUserVendor ? expertVerifiedSoftwares?.filter((soft) => {
    return vendorSoftwares.includes(soft);
  }) : null;
  const showVendorVerificationPanel = isUserVendor && !isOwnListing;
  const showVendorVerificationPanelAsEmpty =
    ((!matchingVendorSoftwaresToVerify || matchingVendorSoftwaresToVerify?.length === 0)
      && (!matchingVendorSoftwaresToCancel || matchingVendorSoftwaresToCancel?.length === 0))
  const vendorApiIds = {
    vendorId: currentUser?.id?.uuid,
    expertListingId: currentListing?.id?.uuid
  };

  const [chosenToVerifyVendorSoftwares, setChosenToVerifyVendorSoftwares] = useState([]);
  const [chosenToCancelVendorSoftwares, setChosenToCancelVendorSoftwares] = useState([]);

  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);
  useEffect(() => {
    if ((typeof window) !== 'undefined') {
      setShouldRenderReactSelect(true);
    }
  }, []);

  if (shouldRenderReactSelect) {
    var { default: Select } = require('react-select');
  }

  const mapToSelect = (chosenSoftwares) => {
    return chosenSoftwares?.length ? (
      productSoftware
        .filter(cs => chosenSoftwares.includes(cs.key))
        .map((item) => {
          return {
            value: item.key,
            label: item.label,
          }
        })
    ) : [];
  }

  const mapSoftwaresToSelectOptions = (matchingVendorSoftwares) => {
    return matchingVendorSoftwares?.length ? (
      productSoftware
        .filter(s => matchingVendorSoftwares.includes(s.key))
        .map((item) => {
          return {
            value: item.key,
            label: item.label,
          }
        })
    ) : null;
  }
  const vendorVerifySelectOptions = mapSoftwaresToSelectOptions(matchingVendorSoftwaresToVerify);
  const vendorCancelSelectOptions = mapSoftwaresToSelectOptions(matchingVendorSoftwaresToCancel);

  const handleVendorSelectChange = (softwaresArray, stateValue, stateSetFunction) => {
    if (softwaresArray?.length > 0) {
      stateSetFunction(softwaresArray.map((soft) => {
        return soft.value;
      }));
    } else if ((softwaresArray?.length === 0 || !softwaresArray) && stateValue?.length !== 0) {
      stateSetFunction([]);
    }
  }

  const handleVendorVerifyButton = () => {
    if (chosenToVerifyVendorSoftwares?.length > 0) {
      const requestData = {
        vendorApiIds: vendorApiIds,
        softwares: chosenToVerifyVendorSoftwares,
        action: "add"
      }
      updateVerifiedSoftwares(requestData)
        .catch(error => {
          return;
        });
      setChosenToVerifyVendorSoftwares([]);
      verifiedSoftwaresCallback(chosenToVerifyVendorSoftwares, "add");
    }
  }

  const handleVendorCancelButton = () => {
    if (chosenToCancelVendorSoftwares?.length > 0) {
      const requestData = {
        vendorApiIds: vendorApiIds,
        softwares: chosenToCancelVendorSoftwares,
        action: "remove"
      }
      updateVerifiedSoftwares(requestData)
        .catch(error => {
          return;
        });
      setChosenToCancelVendorSoftwares([]);
      verifiedSoftwaresCallback(chosenToCancelVendorSoftwares, "remove");
    }
  }

  return showVendorVerificationPanel && shouldRenderReactSelect ?
    <div className={css.vendorPanelWrapper}>
      <p className={css.vendorPanelTitle}>
        <FormattedMessage id="BookingPanel.vendorPanelTitle" />
      </p>
      {showVendorVerificationPanelAsEmpty &&
        <p className={css.vendorPanelSubtitle}>
          <FormattedMessage id="BookingPanel.vendorPanelEmpty" />
        </p>
      }
      {(vendorVerifySelectOptions && vendorVerifySelectOptions.length > 0) &&
        <div className={css.vendorVerifyWrapper}>
          <p className={css.vendorPanelSubtitle}>
            <FormattedMessage id="BookingPanel.vendorPanelSubtitleVerify" />
          </p>
          <div className={css.vendorPanelSelectWrapper}>
            <Select options={vendorVerifySelectOptions} isMulti value={mapToSelect(chosenToVerifyVendorSoftwares)}
              onChange={(v) => { handleVendorSelectChange(v, chosenToVerifyVendorSoftwares, setChosenToVerifyVendorSoftwares) }} />
          </div>
          <div className={css.vendorPanelButtonWrapper}>
            <Button disabled={chosenToVerifyVendorSoftwares?.length === 0}
              className={css.vendorVerifyButton} onClick={handleVendorVerifyButton}>
              <FormattedMessage id="BookingPanel.vendorVerifyButton" />
            </Button>
          </div>
        </div>
      }
      {(vendorCancelSelectOptions && vendorCancelSelectOptions.length > 0) &&
        <div className={css.vendorCancelWrapper}>
          <p className={css.vendorPanelSubtitle}>
            <FormattedMessage id="BookingPanel.vendorPanelSubtitleCancel" />
          </p>
          <div className={css.vendorPanelSelectWrapper}>
            <Select options={vendorCancelSelectOptions} isMulti value={mapToSelect(chosenToCancelVendorSoftwares)}
              onChange={(v) => { handleVendorSelectChange(v, chosenToCancelVendorSoftwares, setChosenToCancelVendorSoftwares) }} />
          </div>
          <div className={css.vendorPanelButtonWrapper}>
            <Button disabled={chosenToCancelVendorSoftwares?.length === 0}
              className={css.vendorVerifyButton} onClick={handleVendorCancelButton}>
              <FormattedMessage id="BookingPanel.vendorCancelButton" />
            </Button>
          </div>
        </div>
      }
    </div>
    : null
};

const mapStateToProps = state => {
  const {
    productSoftware
  } = state.marketplaceData;
  return {
    productSoftware  
  }
}

export default compose(
  connect(mapStateToProps)
)(VendorVerificationPanel);