import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink } from '../../components';
import { MdContentCopy } from 'react-icons/md';

import css from './TransactionPanel.css';

export const HEADING_ACCEPTED = 'accepted';
export const HEADING_CANCELED = 'canceled';
export const HEADING_OFFER_SENT = 'offer-sent';
export const HEADING_PRE_ACCEPTED = 'pre-accepted';
export const HEADING_BOOKING_REQUESTED = 'booking-requested';
export const HEADING_BOOKING_ACCEPTED = 'booking-accepted';
export const HEADING_BOOKING_DECLINED = 'booking-declined';
export const HEADING_BOOKING_CANCELED = 'booking-canceled';
export const HEADING_CANCELED_OFFER = 'offer-canceled';
export const HEADING_ACCEPTED_NOT_PAID = 'accepted-not-paid';
export const HEADING_PAID = 'paid';

const createListingLink = (listingId, label, listingCategory, listingDeleted, searchParams = {}, className = '') => {
  if (!listingDeleted && ['job', 'expert', 'ticket', 'service'].includes(listingCategory)) {
    const params = { id: listingId, slug: createSlug(label), category: `${listingCategory}s` };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink
        className={className}
        name={`${listingCategory.charAt(0).toUpperCase() + listingCategory.slice(1)}Page`}
        params={params}
        to={to}
      >
        {label}
      </NamedLink>
    );
  } else if (!['job', 'expert', 'ticket', 'service'].includes(listingCategory)) {
    return listingCategory;
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const ListingDeletedInfoMaybe = props => {
  return props.listingDeleted ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.messageDeletedListing" />
    </p>
  ) : null;
};

const HeadingCustomer = props => {
  const { className, id, values, listingDeleted } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const tooltip = (
  <Tooltip title={<FormattedMessage id="TransactionPanel.addPayoutDetailsInfo" />} >
    <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
  </Tooltip>
)

const HeadingCustomerWithSubtitle = props => {
  const { className, id, values, subtitleId, subtitleValues, children, listingDeleted, displayTooltip } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      <p className={css.subtitle}>
        <FormattedMessage id={subtitleId} values={subtitleValues} />
        {displayTooltip ? tooltip : null}
      </p>
      {children}
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const CustomerBannedInfoMaybe = props => {
  return props.isCustomerBanned ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.customerBannedStatus" />
    </p>
  ) : null;
};

const HeadingProvider = props => {
  const { className, id, values, isCustomerBanned, children } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      {children}
      <CustomerBannedInfoMaybe isCustomerBanned={isCustomerBanned} />
    </React.Fragment>
  );
};

// Functional component as a helper to choose and show Order or Sale heading info:
// title, subtitle, and message
const PanelHeading = props => {
  const {
    className,
    rootClassName,
    panelHeadingState,
    currentCustomerIsAdmin,
    customerName,
    providerName,
    listingId,
    listingTitle,
    listingCategory,
    listingDeleted,
    isCustomerBanned,
    isSupportPlan,
    projectJob
  } = props;

  const [npsLinkCopied, setNPSLinkCopied] = useState(false);

  const copyNPSLink = npsLink => {
    navigator.clipboard.writeText(npsLink);
    setNPSLinkCopied(true);
  }

  const isCustomer = props.transactionRole === 'customer';

  const defaultRootClassName = isCustomer ? css.headingOrder : css.headingSale;
  const titleClasses = classNames(rootClassName || defaultRootClassName, className);
  const listingLink = createListingLink(listingId, listingTitle, listingCategory, listingDeleted);

  const npsLink = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/${listingId}/nps`;
  const npsWrapper = <div className={css.npsLinkWrapper}>
    <div className={css.npsLink}>
      <div><FormattedMessage id="TransactionPanel.yourNPSLink" /></div>
      <NamedLink name="NPSSurveyPage" params={{ id: listingId }}>
        {npsLink}
      </NamedLink>
    </div>
    {npsLinkCopied
      ? <div className={css.copiedText}>copied!</div>
      : <div className={css.copyButton} onClick={() => copyNPSLink(npsLink)}>
        <MdContentCopy size={24} />
      </div>}
  </div>

  const projectJobLink = projectJob?.id && projectJob?.title && projectJob?.category ? createListingLink(projectJob.id, projectJob.title, projectJob.category, false) : 'project';

  const softwaresuppMessageLink =
    <NamedLink name="ConversationPage" params={{ id: process.env.REACT_APP_ADMIN_USER_ID }}>
      <FormattedMessage id="TransactionPanel.softwaresuppLink" />
    </NamedLink>

  switch (panelHeadingState) {
    case HEADING_OFFER_SENT:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.offerSentTitle"
          values={{ customerName, listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
          <HeadingProvider
            className={titleClasses}
            id={currentCustomerIsAdmin ? "TransactionPanel.offerFromAdminTitle" : "TransactionPanel.offerWaitingTitle"}
            values={{ providerName, listingLink }}
            isCustomerBanned={isCustomerBanned}
          >
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id={currentCustomerIsAdmin ? "TransactionPanel.offerFromAdminSubtitle" : "TransactionPanel.offerWaitingSubTitle"}
                values={{ softwaresuppMessageLink }}
              />
            </p>
          </HeadingProvider>
        );
    case HEADING_CANCELED_OFFER:
      return <HeadingCustomer
        className={titleClasses}
        id="TransactionPanel.offerCanceledTitle"
        values={{ listingLink }}
        listingDeleted={listingDeleted}
      />
    case HEADING_BOOKING_REQUESTED:
      return isCustomer ? (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.bookingRequestedClient"
          values={{ listingLink }}
        >
        </HeadingProvider>
      ) : (
          <HeadingProvider
            className={titleClasses}
            id="TransactionPanel.bookingRequestedExpert"
            values={{ customerName }}
            isCustomerBanned={isCustomerBanned}
          >
          </HeadingProvider>
        );
    case HEADING_ACCEPTED:
      return isCustomer ? (
        <>
          <HeadingCustomerWithSubtitle
            className={titleClasses}
            id="TransactionPanel.orderPreauthorizedTitle"
            values={{ customerName }}
            subtitleId={isSupportPlan ? "TransactionPanel.preOrderAcceptedSubtitleSupport" : "TransactionPanel.preOrderAcceptedSubtitle"}
            subtitleValues={{ listingLink, projectJobLink }}
          />
        </>
      ) : (
          <>
            <HeadingProvider
              className={titleClasses}
              id="TransactionPanel.saleAcceptedTitle"
              values={{ customerName, listingLink }}
            />
            {npsWrapper}
          </>
        );
    case HEADING_PRE_ACCEPTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderPreauthorizedTitle"
          values={{ customerName }}
          subtitleId="TransactionPanel.orderAcceptedSubtitle"
          subtitleValues={{ listingLink }}
        />
      ) : (
          <HeadingProvider
            className={titleClasses}
            id="TransactionPanel.preSaleAcceptedTitle"
            values={{ customerName, listingLink }}
          />
        );
    case HEADING_CANCELED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderCancelledTitle"
          values={{ customerName, listingLink }}
        />
      ) : (
          <HeadingProvider
            className={titleClasses}
            id="TransactionPanel.saleCancelledTitle"
            values={{ customerName, listingLink }}
          />
        );
    case HEADING_ACCEPTED_NOT_PAID:
      return isCustomer ?
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.waitToPayTitle"
          subtitleId="TransactionPanel.waitToPay"
          subtitleValues={{ providerName: "Customer" }}
        />
        : <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.waitToPayTitleCustomer"
          values={{ customerName }}
          subtitleId="TransactionPanel.waitToPayCustomer"
          subtitleValues={{ expert: customerName }}
        />
    case HEADING_PAID:
      return isCustomer ?
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.customerHasPaidTitle"
          subtitleId="TransactionPanel.customerHasPaidSubtitle"
          subtitleValues={{ providerName }}
        />
        : <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.youHavePaidTitle"
          values={{ customerName }}
          subtitleId="TransactionPanel.youHavePaidSubtitle"
          subtitleValues={{ expert: customerName }}
        />
    default:
      console.warn('Unknown state given to panel heading.');
      return null;
  }
};

export default PanelHeading;
