import React, { useState, Component } from 'react';
import PropTypes, { object } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { IoMdArrowDropright, IoMdArrowDropdown } from 'react-icons/io';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_CATEGORY_JOB,
  LISTING_CATEGORY_EXPERT,
  LISTING_CATEGORY_SERVICE,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import {
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import config from '../../config';
import {
  InlineTextButton,
  NamedLink,
  IconSpinner,
  ResponsiveImage,
  AvatarLarge,
} from '../../components';
import SectionTagsMaybe from '../../containers/ListingPage/SectionTagsMaybe';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';

import Overlay from './Overlay';
import css from './ManageListingCard.css';

// Menu content needs the same padding
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price).slice(0, -3);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const createListingURL = (routes, listing, page) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);
  const category = listing.attributes.publicData?.category;
  const isPendingApproval = listing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
  const isInProgress =
    category === LISTING_CATEGORY_JOB &&
    (listing.attributes.publicData.state === 'inProgress' ||
      listing.attributes.publicData.state === 'serviceInProgress');
  const variant = isDraft
    ? LISTING_PAGE_DRAFT_VARIANT
    : isPendingApproval
    ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
    : null;

  const linkProps = isInProgress
    ? {
        name: 'ProjectBoardPage',
        params: {
          id,
        },
      }
    : isPendingApproval || isDraft
    ? {
        name: 'ListingPageVariant',
        params: {
          id,
          slug,
          variant,
          category: `${category}s`,
        },
      }
    : {
        name: `${category.charAt(0).toUpperCase() + category.slice(1)}Page`,
        params: { id, slug, category: `${category}s` },
      };

  return createResourceLocatorString(
    linkProps.name,
    routes,
    linkProps.params,
    linkProps.search ? linkProps.search : {}
  );
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

export const ManageListingCardComponent = props => {
  const {
    className,
    rootClassName,
    hasClosingError,
    hasOpeningError,
    hasChangingStateError,
    history,
    intl,
    isMenuOpen,
    actionsInProgressListingId,
    listing,
    onOpenListing,
    onChangeState,
    renderSizes,
    availabilityEnabled,
    currentUser,

    page,
    onApproveListing,
    onRedirectToBillingPage,
    isAdmin,
    isExpert,
  } = props;

  const [scopeVisible, setScopeVisible] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, state, publicData } = currentListing.attributes;
  const scope = publicData.scope;
  const category = publicData?.category;
  const slug = createSlug(title);
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isJobListing = category === LISTING_CATEGORY_JOB;
  const isExpertListing = category === LISTING_CATEGORY_EXPERT;
  const isServiceListing = category === LISTING_CATEGORY_SERVICE;
  const isSolved = isJobListing && publicData.state === 'closed';
  const isOpen = isJobListing && !isPendingApproval && publicData.state === 'open';
  const isAwaiting = isJobListing && !isPendingApproval && publicData.state === 'awaiting';
  const isOngoing = isJobListing && publicData.state === 'inProgress';
  const isCompleted = isJobListing && publicData.state === 'closed';
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  // const offersCount = publicData.offersCount ? publicData.offersCount : 0;

  const user = currentListing.author;

  const currentUserIsAuthor = currentUser?.id.uuid === user?.id.uuid;

  const { formattedPrice, priceTitle } = priceData(
    price ? new Money(isExpert ? price?.amount : price?.amount, config.currency) : null,
    intl
  );

  const createdAt = currentListing.attributes.createdAt;
  const formattedCreatedAt = Date(Date.parse(createdAt))
    .toString()
    .slice(4, 15);

  const hasError = hasOpeningError || hasClosingError || hasChangingStateError;
  const thisListingInProgress =
    actionsInProgressListingId && actionsInProgressListingId.uuid === id;

  const titleClasses = classNames(css.title, {
    [css.titlePending]: isPendingApproval,
    [css.titleDraft]: isDraft,
  });

  //converting project expiration date to Dat
  const formattedExpirationDate =
    isJobListing &&
    publicData.expires &&
    new Date(Date.parse(`${publicData.expires.slice(0, 6)}, ${publicData.expires.slice(-4)}`));
  const isAfterExpirationDate = formattedExpirationDate
    ? formattedExpirationDate < new Date()
    : false;

  const editListingLinkType = LISTING_PAGE_PARAM_TYPE_EDIT;

  const unitTranslationKey = isJobListing
    ? 'ManageListingCard.offerInfo'
    : isServiceListing
    ? 'ManageListingCard.servicePrice'
    : 'ManageListingCard.offerInfoExpert';

  return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        tabIndex={0}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          // ManageListingCard contains links, buttons and elements that are working with routing.
          // This card doesn't work if <a> or <button> is used to wrap events that are card 'clicks'.
          //
          // NOTE: It might be better to absolute-position those buttons over a card-links.
          // (So, that they have no parent-child relationship - like '<a>bla<a>blaa</a></a>')
          history.push(createListingURL(routeConfiguration(), listing, page));
        }}
      >
        {isExpertListing ? (
          <div className={css.avatarWrapper}>
            <AvatarLarge
              user={user}
              className={css.avatar}
              initialsClassName={css.initialsDesktop}
              disableProfileLink
            />
          </div>
        ) : (
          <div className={css.aspectWrapper}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              software={
                listing.attributes.publicData?.primarySoftware ||
                (listing.attributes.publicData?.softwares &&
                  listing.attributes.publicData?.softwares[0])
              }
              variants={['square-small']}
              sizes={renderSizes}
            />
          </div>
        )}

        <div className={classNames(css.menuOverlayWrapper, { [css.menuOverlayOpen]: isMenuOpen })}>
          <div className={classNames(css.menuOverlay)} />
          <div className={css.menuOverlayContent}>
            <FormattedMessage id="ManageListingCard.viewListing" />
          </div>
        </div>
        {isDraft ? (
          <React.Fragment>
            <div className={classNames({ [css.draftNoImage]: !firstImage })} />
            <Overlay
              message={intl.formatMessage(
                { id: `ManageListingCard.draftOverlayText` },
                { listingTitle: title, listingCategory: category }
              )}
            >
              {currentUserIsAuthor ? (
                <NamedLink
                  className={css.finishListingDraftLink}
                  name={'EditListingPage'}
                  params={{
                    id,
                    slug,
                    type: LISTING_PAGE_PARAM_TYPE_EDIT,
                    tab: 'description',
                    category,
                  }}
                  category={category}
                >
                  <FormattedMessage id="ManageListingCard.finishListingDraft" />
                </NamedLink>
              ) : null}
            </Overlay>
          </React.Fragment>
        ) : null}
        {isClosed ? (
          <Overlay
            message={intl.formatMessage(
              { id: 'ManageListingCard.closedListing' },
              { listingTitle: title, listingCategory: category }
            )}
          >
            {currentUserIsAuthor ? (
              <button
                className={css.openListingButton}
                disabled={!!actionsInProgressListingId}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (!actionsInProgressListingId) {
                    onOpenListing(currentListing.id);
                  }
                }}
              >
                <FormattedMessage id="ManageListingCard.openListing" />
              </button>
            ) : null}
          </Overlay>
        ) : null}
        {isSolved && !isDraft && !isClosed && !publicData.transactionId ? (
          <Overlay
            message={intl.formatMessage(
              { id: 'ManageListingCard.jobClosed' },
              { listingTitle: title, listingCategory: category }
            )}
          >
            {!isAfterExpirationDate ? (
              <button
                className={css.openListingButton}
                disabled={!!actionsInProgressListingId}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (!actionsInProgressListingId) {
                    onChangeState(currentListing.id.uuid);
                  }
                }}
              >
                <FormattedMessage id={'ManageListingCard.openJob'} />
              </button>
            ) : null}
          </Overlay>
        ) : null}
        {isPendingApproval ? (
          <React.Fragment>
            <Overlay
              message={
                <>
                  <div className={css.pendingApproval}>
                    {isJobListing
                      ? intl.formatMessage(
                          { id: 'ManageListingCard.projectPendingApproval' },
                          {
                            listingTitle: title,
                            listingCategory: isJobListing
                              ? 'project'
                              : isExpertListing
                              ? 'expert profile'
                              : category,
                          }
                        )
                      : intl.formatMessage(
                          { id: 'ManageListingCard.pendingApproval' },
                          {
                            listingTitle: title,
                            listingCategory: isJobListing
                              ? 'project'
                              : isExpertListing
                              ? 'expert profile'
                              : category,
                          }
                        )}
                  </div>
                  {isAdmin ? (
                    <div
                      className={css.approveListingButton}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onApproveListing(id);
                      }}
                    >
                      <FormattedMessage id="ManageListingCard.approveListing" />
                    </div>
                  ) : isJobListing ? (
                    <div
                      className={css.approveListingButton}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        currentListing?.attributes.privateData.depositInvoice?.url
                          ? window.open(currentListing?.attributes.privateData.depositInvoice.url)
                          : onRedirectToBillingPage();
                      }}
                    >
                      <FormattedMessage id="ManageListingCard.payDeposit" />
                    </div>
                  ) : null}
                </>
              }
            />
          </React.Fragment>
        ) : null}
        {thisListingInProgress ? (
          <Overlay>
            <IconSpinner />
          </Overlay>
        ) : hasError ? (
          <Overlay errorMessage={intl.formatMessage({ id: 'ManageListingCard.actionFailed' })} />
        ) : null}
      </div>

      <div className={css.info}>
        <div className={css.infoRow}>
          {isOpen ? (
            <div className={css.noPrice}>
              <FormattedMessage id="ManageListingCard.isOpen" />
            </div>
          ) : null}
          {isAwaiting ? (
            <div className={css.noPrice}>
              <FormattedMessage id="ManageListingCard.isOpenWithOffer" />
            </div>
          ) : null}
          {isOngoing ? (
            <div className={css.noPrice}>
              <FormattedMessage id="ManageListingCard.isOngoing" />
            </div>
          ) : null}

          {isCompleted ? (
            <div className={css.noPrice}>
              <FormattedMessage id="ManageListingCard.isCompleted" />
            </div>
          ) : null}
        </div>
        <div className={css.firstRow}>
          <InlineTextButton
            rootClassName={titleClasses}
            disabled={(isDraft || isPendingApproval) && !currentUserIsAuthor}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              history.push(createListingURL(routeConfiguration(), listing, page));
            }}
          >
            {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
          </InlineTextButton>

          {/* <div className={css.price}> */}
          {/*   {formattedPrice ? ( */}
          {/*     <React.Fragment> */}
          {/*       <div className={css.perUnit}> */}
          {/*         <FormattedMessage id={unitTranslationKey} /> */}
          {/*       </div> */}
          {/*       <div className={css.priceValue} title={priceTitle}> */}
          {/*         {formattedPrice} */}
          {/*       </div> */}
          {/*     </React.Fragment> */}
          {/*   ) : isJobListing ? ( */}
          {/*     <div className={css.noPrice}> */}
          {/*       <FormattedMessage id="ManageListingCard.priceNotSet" /> */}
          {/*     </div> */}
          {/*   ) : null} */}
          {/* </div> */}
        </div>

        <div>
          <div className={css.date}>
            <FormattedMessage id="ManageListingCard.date" values={{ date: formattedCreatedAt }} />
          </div>
        </div>
        <SectionTagsMaybe
          className={css.softwareSection}
          softwares={publicData.softwares}
          skills={publicData.skills}
        />

        <div className={css.manageLinks}>
          {availabilityEnabled && isExpertListing ? (
            <React.Fragment>
              <span className={css.manageLinksSeparator}>{' • '}</span>

              <NamedLink
                className={css.manageLink}
                name="EditListingPage"
                params={{
                  id,
                  slug,
                  type: editListingLinkType,
                  tab: 'availability',
                  category: category,
                }}
                category={category}
              >
                <FormattedMessage id="ManageListingCard.manageAvailability" />
              </NamedLink>
            </React.Fragment>
          ) : null}
        </div>

        {isJobListing && scope?.length > 0 ? (
          <>
            <div className={css.scopeSection}>
              <span className={css.scopeTitle} onClick={() => setScopeVisible(!scopeVisible)}>
                <FormattedMessage id="ManageListingCard.scope" />{' '}
                {scopeVisible ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
              </span>
              {scopeVisible
                ? scope.map(milestone => {
                    return (
                      <div className={css.projects} key={`${milestone.task}`}>
                        <div className={css.projectSideWrapper}>
                          <div className={css.projectPointer} />
                          <div
                            className={
                              scope.indexOf(milestone) + 1 === scope.length &&
                              milestone.subtasks.length === 0
                                ? css.projectsNoSideLine
                                : scope.indexOf(milestone) + 1 === scope.length
                                ? css.projectsLastSideLine
                                : css.projectsSideLine
                            }
                          />
                        </div>
                        <div className={css.projectWrapper}>
                          <div className={css.projectFirstRow}>
                            <span className={css.projectTitle}>{milestone.task}</span>
                          </div>
                          <div className={css.projectDescription}>
                            {milestone.subtasks.map(subtask => {
                              return (
                                <span className={css.subtask} key={`${subtask.name}`}>
                                  {subtask.name}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </>
        ) : null}

        {/* {isJobListing && projectPosted ? (
          <div className={css.jobAndTicketDetailsSection}>
            <div className={css.answersWrapper}>
              <p className={css.answersCount}>
                {isPendingApproval ? softwareExperts?.count : offersCount}
              </p>
              <p className={css.answersCountSubtitle}>
                {isPendingApproval ? (
                  <FormattedMessage
                    id="ManageListingCard.expertsAvailable"
                    values={{ count: softwareExperts?.count }}
                  />
                ) : offersCount === 1 ? (
                  'offer'
                ) : (
                  'offers'
                )}
              </p>
            </div>
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

ManageListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,
  availabilityEnabled: config.enableAvailability,
};

const { bool, func, shape, string } = PropTypes;

ManageListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  hasClosingError: bool.isRequired,
  hasOpeningError: bool.isRequired,
  intl: intlShape.isRequired,
  listing: object.isRequired,
  isMenuOpen: bool.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  onToggleMenu: func.isRequired,
  availabilityEnabled: bool,

  // Responsive image sizes hint
  renderSizes: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(withRouter, injectIntl)(ManageListingCardComponent);
