import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IconContext } from "react-icons";
import { FaRegHandshake } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  PrimaryButton,
  SecondaryButton,
  Logo,
} from '../../components';
import { TopbarContainer } from '../../containers';

import css from './ReferralSystemPage.css';

export class ReferralSystemPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      linkVisible: false, 
      buttonDisabled: false, 
      copied: false,
      helpVisible: false, 
      option: 'customers',
    };
    this.onShowLink = this.onShowLink.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onShowHelp = this.onShowHelp.bind(this);
    this.onSwitchOption = this.onSwitchOption.bind(this);
  }

  onShowLink() {
    this.setState({ linkVisible: true, buttonDisabled: true});
  }

  onCopy (texttoCopy) {
    navigator.clipboard.writeText(texttoCopy);
    this.setState({copied: true});
  }

  onShowHelp() {
    this.setState({ helpVisible: true});
  }

  onSwitchOption(option) {
    this.setState({ option: option});
    this.setState({ linkVisible: false});
    this.setState({ buttonDisabled: false});
  }

  render() {
    const {
      currentUser,
      scrollingDisabled
    } = this.props;

    const title = 'Referral Voucher'
    const description = 'Invite your friends to SoftwareSupp and earn 25% of the transaction value.'
    const user = ensureCurrentUser(currentUser);
    const code = user.attributes.profile.privateData?.referral?.code;
    const referralLink = !code
      ? 'Something went wrong! Please contact us at support@softwaresupp.com'
      : this.state.option === 'customers'
        ? `${process.env.REACT_APP_CANONICAL_ROOT_URL}/l/new-project/1/referral/${code}`
        : `${process.env.REACT_APP_CANONICAL_ROOT_URL}/l/draft/00000000-0000-0000-0000-000000000000/new/expert/quick/${code}`;

    const referralVoucher = (
      <div className={css.voucherContainer}>
        <p className={css.choiceText}>Recommend SoftwareSupp to a new:</p>
        <div className={css.buttonsWrapper}>
          <div
            className={classNames(
              css.choiceButton,
              this.state.option === 'customers' ? css.chosen : null
            )}
            onClick={() => this.onSwitchOption('customers')}
          >
            <FormattedMessage id="ReferralSystemPage.customersButton" />
          </div>
          <div
            className={classNames(
              css.choiceButton,
              this.state.option === 'experts' ? css.chosen : null
            )}
            onClick={() => this.onSwitchOption('experts')}
          >
            <FormattedMessage id="ReferralSystemPage.expertsButton" />
          </div>
        </div>
        <div className={css.voucher}>
          <Logo className={css.logo} />
          <h1 className={css.voucherTitle}>Referral Voucher</h1>
          <h2 className={css.voucherInvite}>Invite your friends to SoftwareSupp</h2>
          <IconContext.Provider value={{ size: '2em', color: '#3c88f8' }}>
            {' '}
            <FaRegHandshake />{' '}
          </IconContext.Provider>
          <p className={css.voucherText}>
            {this.state.option === 'customers' ? (
              <span>
                If you want to recommend our services to your friend or another company, send them
                this link [or invite them to SoftwareSupp] and if they use SoftwareSupp services,
                you will receive
              </span>
            ) : (
              <span>
                If you want to recommend SoftwareSupp to your friend, who's a freelancer, send
                them this link and if they complete a project on our platform, you will receive
              </span>
            )}
            <br />
            <span className={css.tenPercent}>25%</span>
            <br />
            lifetime commission from every payment and a free
            <br />
            <span className={css.tenPercent}>$100</span>
            <br />
            bonus.
          </p>

          {this.state.linkVisible ? (
            <div className={css.linkContainer}>
              <p className={css.referralLink}>{referralLink}</p>
              <SecondaryButton onClick={() => this.onCopy(referralLink)} className={css.copyButton}>
                {this.state.copied ? <span>Copied!</span> : null}
                <MdContentCopy size={24} />
              </SecondaryButton>
            </div>
          ) : null}
          
          <PrimaryButton
            className={css.linkButton}
            disabled={this.state.buttonDisabled}
            onClick={this.onShowLink}
          >
            Generate link
          </PrimaryButton>
          <SecondaryButton className={css.linkButtonSecondary} onClick={this.onShowHelp}>
            How it works?
          </SecondaryButton>
          {this.state.helpVisible ? (
            <div className={css.helpText}>
              <ol>
                <li>Generate a referral link and send it to your friend.</li>
                {this.state.option === 'customers' ? (
                  <>
                    <li>
                      When they post a project on SoftwareSupp using this link, it will be assigned
                      to you and you’ll receive an email confirmation.{' '}
                    </li>
                    <li>
                      SoftwareSupp will estimate the project and price for the service for them.
                    </li>
                    <li>
                      Once the referred customer accepts the offer, we’ll notify you of the project
                      launch.
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      When they create an expert profile on SoftwareSupp using this link, you’ll
                      receive an email confirmation.{' '}
                    </li>
                    <li>
                      Using SoftwareSupp platform they can post their services or submit an offer
                      for one of the current projects.
                    </li>
                    <li>
                      Once the referred expert starts the project, we’ll notify you of its launch.
                    </li>
                  </>
                )}
                <li>
                  You will get <b>25%</b> lifetime commission from every customer's payment to
                  be paid out to your account (wire transfer or PayPal) and a free $100 bonus to your account.
                </li>
              </ol>
            </div>
          ) : null}
        </div>
      </div>
    );

    return (
      <Page title={title} description={description} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ReferralSystemPage" />
            <UserNav currentUser={currentUser} selectedPageName="ReferralSystemPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>{referralVoucher}</LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ReferralSystemPageComponent.defaultProps = {
  currentUser: null,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const ReferralSystemPage = compose(
  connect(
    mapStateToProps,
  ),
  injectIntl
)(ReferralSystemPageComponent);

export default ReferralSystemPage;
