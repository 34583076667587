import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { Tooltip } from '@material-ui/core';
import { AiOutlineCheckCircle, AiFillCheckCircle } from 'react-icons/ai';
import { dateToReadableString } from '../../util/inbox/dateUtil';
import { flags } from '../../util/inbox/flags';
import AttachmentsList from './AttachmentsList/AttachmentsList';
import css from './Message.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const renderStatusIcon = status => {
  let statusIcon;
  let statusMessage;
  const iconSize = '1.1em';
  const iconDefaultColor = 'darkgray';
  const iconActiveColor = '#2ecc71';

  switch (status) {
    case flags.MSGSTATUS_SENT:
      statusIcon = <AiOutlineCheckCircle color={iconDefaultColor} size={iconSize} />;
      statusMessage = <FormattedMessage id="OwnMessage.MSGSTATUS_SENT" />;
      break;
    case flags.MSGSTATUS_READ:
      statusIcon = <AiFillCheckCircle color={iconActiveColor} size={iconSize} />;
      statusMessage = <FormattedMessage id="OwnMessage.MSGSTATUS_READ" />;
      break;
    default:
      statusIcon = <AiOutlineCheckCircle color={iconDefaultColor} size={iconSize} />;
      statusMessage = <FormattedMessage id="OwnMessage.MSGSTATUS_SENT" />;
  }

  return (
    <Tooltip title={statusMessage} placement={'right'}>
      <div className={css.statusIconWrapper}>{statusIcon}</div>
    </Tooltip>
  );
};

const OwnMessage = props => {
  const { message } = props;
  const { date, content, status, attachments } = message;

  // const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <div className={css.ownMessageContent}>
          {richText(content, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
            linkify: true,
            linkClass: css.ownMessageLink,
          })?.map(line => {
            return <div className={css.messageLine}>{line}</div>;
          })}
          <AttachmentsList data={attachments} color={'white'} />
        </div>
      </div>
      <div className={css.ownMessageDateWrapper}>
        <p className={css.ownMessageDate}>
          {dateToReadableString(date)}
          {/* {formatDate(intl, todayString, message.attributes.createdAt)} */}
        </p>
        {renderStatusIcon(status)}
      </div>
    </div>
  );
};

OwnMessage.propTypes = {
  intl: intlShape.isRequired,
};

export default OwnMessage;
