import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { SecondaryChip } from '../../components/Chip/Chip';

import css from './ListingCard.css';

const SectionExperiencedMaybe = props => {
  const { experience } = props;
  const isExperienced = experience === 'above 10 years';
  
  return isExperienced ? (
    <SecondaryChip className={css.experiencedChip}>
      <FormattedMessage id="ListingCard.experienced" />
    </SecondaryChip>
  ) : null;
};

export default SectionExperiencedMaybe;