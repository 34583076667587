import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import config from '../../config';
import { Modal } from '../../components';
import BookingTimeForm from '../../forms/BookingTimeForm/BookingTimeForm';

import css from './BookingTimeModal.css';

const TODAY = new Date();

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingTimeModal = props => {
  const {
    listing,
    isOwnListing,
    onRequestBooking,
    onManageDisableScrolling,
    intl,
    unitType,
    onFetchTimeSlots,
    monthlyTimeSlots,
    openBookModal,
  } = props;

  const [isMeetingModalOpen, setMeetingModal] = useState(openBookModal);

  const timeZone = typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

  let isOpen = isMeetingModalOpen;
  if (isMeetingModalOpen === undefined) {
    isOpen = openBookModal;
  }

  return (
    <Modal
      containerClassName={css.modalContainer}
      id="BookingTimeFormInModal"
      onClose={() => setMeetingModal(false)}
      scrollLayerClassName={css.modalScrollLayer}
      onManageDisableScrolling={onManageDisableScrolling}
      isOpen={isOpen}
      customClassName="customBookingModal"
    >
      <BookingTimeForm
        formId="BookingPanel"
        unitType={unitType}
        onSubmit={values => onRequestBooking(values, true)}
        isOwnListing={isOwnListing}
        listingId={listing.id}
        monthlyTimeSlots={monthlyTimeSlots}
        onFetchTimeSlots={onFetchTimeSlots}
        startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
        endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
        timeZone={timeZone}
        isPaidBooking={false}
      />
    </Modal>
  );
};

BookingTimeModal.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  currentCustomer: null,
  isTransaction: false,
};

BookingTimeModal.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing, object]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func,
  monthlyTimeSlots: object,

  // from withRouter
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingTimeModal);
