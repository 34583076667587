import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { TRANSITION_ACCEPT_OFFER, TRANSITION_FINISH_MEETING } from '../../util/transaction';
import * as log from '../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  createStripeCustomer,
  generateInvoice,
  updateStripeCustomer,
  getInvoice,
} from '../../util/api';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const INVITE_USER_REQUEST = 'app/TransactionPage/INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'app/TransactionPage/INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'app/TransactionPage/INVITE_USER_ERROR';

export const COMPLETE_SALE_REQUEST = 'app/TransactionPage/COMPLETE_SALE_REQUEST';
export const COMPLETE_SALE_SUCCESS = 'app/TransactionPage/COMPLETE_SALE_SUCCESS';
export const COMPLETE_SALE_ERROR = 'app/TransactionPage/COMPLETE_SALE_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const CREATE_STRIPE_CUSTOMER_REQUEST = 'app/TransactionPage/CREATE_STRIPE_CUSTOMER_REQUEST';
export const CREATE_STRIPE_CUSTOMER_SUCCESS = 'app/TransactionPage/CREATE_STRIPE_CUSTOMER_SUCCESS';
export const CREATE_STRIPE_CUSTOMER_ERROR = 'app/TransactionPage/CREATE_STRIPE_CUSTOMER_ERROR';

export const CREATE_STRIPE_PAYMENT_REQUEST = 'app/TransactionPage/CREATE_STRIPE_PAYMENT_REQUEST';
export const CREATE_STRIPE_PAYMENT_SUCCESS = 'app/TransactionPage/CREATE_STRIPE_PAYMENT_SUCCESS';
export const CREATE_STRIPE_PAYMENT_ERROR = 'app/TransactionPage/CREATE_STRIPE_PAYMENT_ERROR';

export const FETCHING_INVOICE_REQUEST = 'app/TransactionPage/FETCHING_INVOICE_REQUEST';
export const FETCHING_INVOICE_SUCCESS = 'app/TransactionPage/FETCHING_INVOICE_SUCCESS';
export const FETCHING_INVOICE_ERROR = 'app/TransactionPage/FETCHING_INVOICE_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  completeInProgress: false,
  requestPaymentInProgress: false,
  requestPaymentError: null,
  initiateOrderError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case QUERY_LISTINGS_SUCCESS:
      return { ...state, expertListing: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case CREATE_STRIPE_CUSTOMER_REQUEST:
      return { ...state, createStripeCustomerInProgress: true, createStripeCustomerError: null };
    case CREATE_STRIPE_CUSTOMER_SUCCESS:
      return { ...state, createStripeCustomerInProgress: false };
    case CREATE_STRIPE_CUSTOMER_ERROR:
      return {
        ...state,
        createStripeCustomerInProgress: false,
        createStripeCustomerError: payload,
      };

    case CREATE_STRIPE_PAYMENT_REQUEST:
      return { ...state, createStripePaymentInProgress: true, createStripePaymentError: null };
    case CREATE_STRIPE_PAYMENT_SUCCESS:
      return { ...state, createStripePaymentInProgress: false };
    case CREATE_STRIPE_PAYMENT_ERROR:
      return { ...state, createStripePaymentInProgress: false, createStripePaymentError: payload };

    case FETCHING_INVOICE_REQUEST:
      return { ...state, fetchingInvoiceInProgress: true };
    case FETCHING_INVOICE_SUCCESS:
      return { ...state, listingInvoiceUrl: payload, fetchingInvoiceInProgress: false };
    case FETCHING_INVOICE_ERROR:
      return { ...state, fetchingInvoiceInProgress: false, fetchingInvoiceError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress;
};

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingId => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingId },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const createStripeCustomerRequest = () => ({ type: CREATE_STRIPE_CUSTOMER_REQUEST });
const createStripeCustomerSuccess = () => ({ type: CREATE_STRIPE_CUSTOMER_SUCCESS });
const createStripeCustomerError = e => ({
  type: CREATE_STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

const paymentRequest = () => ({ type: CREATE_STRIPE_PAYMENT_REQUEST });
const paymentSuccess = () => ({ type: CREATE_STRIPE_PAYMENT_SUCCESS });
const paymentError = e => ({ type: CREATE_STRIPE_PAYMENT_ERROR, error: true, payload: e });

const fetchingInvoiceRequest = () => ({ type: FETCHING_INVOICE_REQUEST });
const fetchingInvoiceSuccess = payload => ({ type: FETCHING_INVOICE_SUCCESS, payload: payload });
const fetchingInvoiceError = e => ({ type: FETCHING_INVOICE_ERROR, error: true, payload: e });

// ================ Thunks ================ //

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id: id.uuid,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...IMAGE_VARIANTS,
        });
      } else {
        return response;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(txResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const acceptOffer = (params, currentTransactionId) => (dispatch, getState, sdk) => {
  const { offerTransactionId } = params;

  const currentBodyParams = {
    id: offerTransactionId,
    transition: TRANSITION_ACCEPT_OFFER,
    params: {},
  };

  return sdk.transactions
    .transition(currentBodyParams)
    .then(response => {
      dispatch(fetchTransaction(response.data.data.id));
    })
    .catch(e => {
      console.warn('error' + e);
    });
};

export const finishBooking = transactionId => (dispatch, getState, sdk) => {
  const bodyParams = {
    id: transactionId,
    transition: TRANSITION_FINISH_MEETING,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      return response;
    })
    .catch(e => {
      log.error(e, 'finish-meeting-failed', {
        transition: TRANSITION_FINISH_MEETING,
      });
      throw e;
    });
};

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  // dispatch(queryListingsRequest(userId));
  return sdk.listings
    .query({
      author_id: userId,
      pub_category: 'expert',
      include: ['author', 'author_profileImage', 'images'],
      // 'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      // const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(queryListingsSuccess(response.data));
      return response;
    })
    .catch
    // e => dispatch(queryListingsError(storableError(e)))
    ();
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

export const pay = body => (dispatch, getState, sdk) => {
  dispatch(paymentRequest());

  const { data } = body;
  console.log(body)
  return generateInvoice(data)
    .then(response => {
      const updateBody =
        data.isDeposit && data.depositListingId
          ? {
              id: new UUID(data.depositListingId),
              privateData: {
                depositInvoice: { id: response.id, url: response.hosted_invoice_url },
              },
            }
          : {
              id: new UUID(data.listingId),
              privateData: { invoice: response.id },
            };

      if (!updateBody) {
        dispatch(paymentSuccess());
        return response.hosted_invoice_url;
      } else {
        return sdk.ownListings.update(updateBody).then(() => {
          dispatch(paymentSuccess());
          console.log(response);
          return response.hosted_invoice_url;
        }).catch((error)=>{
          console.log(error)

        })
      }
    })
    .catch(error => {
      console.log(error);
      dispatch(paymentError());
    });
};

export const createNewStripeCustomer = (body, stripeAccount) => (dispatch, getState, sdk) => {
  dispatch(createStripeCustomerRequest());
  const { currency, ...rest } = body;
  return createStripeCustomer(rest)
    .then(response => {
      response.currency = currency;
      const updateBody = {
        privateData: {
          stripeAccount: stripeAccount
            ? [response.id, ...stripeAccount.map(account => account.id)]
            : [response.id],
        },
      };
      return sdk.currentUser.updateProfile(updateBody, { expand: true }).then(() => {
        dispatch(createStripeCustomerSuccess());
        return response;
      });
    })
    .catch(error => {
      dispatch(createStripeCustomerError());
    });
};

export const updateExistingStripeCustomer = body => (dispatch, getState, sdk) => {
  dispatch(createStripeCustomerRequest());
  return updateStripeCustomer(body)
    .then(response => {
      dispatch(createStripeCustomerSuccess());
      return response;
    })
    .catch(error => {
      dispatch(createStripeCustomerError());
    });
};

export const fetchListingHasInvoice = (listingId, isDeposit) => (dispatch, getState, sdk) => {
  dispatch(fetchingInvoiceRequest());
  sdk.ownListings
    .show({ id: new UUID(listingId) })
    .then(response => {
      const { invoice, depositInvoice } = response?.data.data.attributes.privateData ?? {};
      if ((!isDeposit && invoice) || (isDeposit && depositInvoice)) {
        getInvoice({ invoiceId: invoice ?? depositInvoice }).then(invoiceResponse => {
          dispatch(fetchingInvoiceSuccess(invoiceResponse.hosted_invoice_url));
        });
      } else {
        dispatch(fetchingInvoiceError());
      }
    })
    .catch(e => {
      console.log(e);
      dispatch(fetchingInvoiceError());
    });
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const createServiceProjectSequence = version => (dispatch, getState, sdk) => {
  const hours = version === 'basic' ? '10h' : version === 'support' ? '20h' : null;
  const draftData = {
    title: `Plan ${hours}`,
    publicData: {
      category: 'job',
      state: 'serviceInProgress',
      private: true,
      expert: [],
    },
  };

  return sdk.ownListings.create(draftData).then(response => {
    return response.data.data.id.uuid;
  });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, txRole)),
    dispatch(fetchMessages(txId, 1)),
    dispatch(fetchNextTransitions(txId)),
  ]);
};

export const addExpertToProject = (listingId, expertId, transactionId) => (
  dispatch,
  getState,
  sdk
) => {
  return sdk.ownListings
    .update({
      id: listingId,
      publicData: { expert: [expertId], transactionId: transactionId },
    })
    .then(data => {})
    .catch(e => {
      console.log(e);
    });
};
