import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { FormattedMessage } from '../../../util/reactIntl';
import { PrimaryButton } from '../../../components';
import { hashFromString } from '../../../util/hash';
import css from './ExamQuestion.css';

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const ExamQuestionComponent = (props) => {

    const {questionNumber, onNextQuestion, isLast} = props;
    const {title, answers} = props?.data;
    const displayedQuestion = (questionNumber+1) + '. ' + title;

    const [userAnswers, setUserAnswers] = useState([]);

    const handleCheckboxChange = (checkboxIndex, event) => {
        setUserAnswers(userAnswers => {
            userAnswers[checkboxIndex] = event.target.checked;
            return userAnswers;
        });
    }

    const renderAnswer = (item, index) => {
        return (
            <div key={hashFromString(item)} className={css.answerWrapper}>
                <GreenCheckbox
                    checked={userAnswers[index]}
                    onChange={(event) => handleCheckboxChange(index, event)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <div className={css.answerTitle}>
                    {item}
                </div>
            </div>
        );
    }

    const onSubmit = () => {
        const formattedAnswers = userAnswers.map((item, index) => {
            return item === true ? index : null
        }).filter((item) => item !== null);
        setUserAnswers([]);
        if(!(formattedAnswers?.length > 0) && typeof window !== undefined) {
            if(!window.confirm('Are you sure you want to skip this question without any answer?')) {
                // cancel submit
                return;
            }
        }
        onNextQuestion(formattedAnswers);
    }

    return (
        <div className={css.questionRoot}>
            <div className={css.questionTitle}>
                {displayedQuestion}
            </div>
            <div className={css.questionAnswers}>
                {answers.map((item, index) => {
                    return renderAnswer(item, index);
                })}
            </div>
            <div className={css.submitButtonWrapper}>
                <PrimaryButton 
                    className={css.defaultButton}
                    onClick={onSubmit}
                >
                    {!isLast ?
                        <FormattedMessage id="ExamPage.nextQuestionButton" />
                    :
                        <FormattedMessage id="ExamPage.finishExamButton" />
                    }
                </PrimaryButton>
          </div>
        </div>
    );
}

const ExamQuestion = React.memo(ExamQuestionComponent);

export default ExamQuestion;