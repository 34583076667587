export const getUser = (userId) => (dispatch, getState, sdk) => {
    const bodyParams = {
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small'],
    };
    return sdk.users
      .show(bodyParams)
      .then(response => {
        const user = response.data;
        return user;
      })
      .catch(e => {
        throw e;
      });
};