import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { NegotiateOfferForm } from '../../forms';
import { Modal } from '../../components';

import css from './NegotiationModal.css';

const { Money } = sdkTypes;

const NegotiationModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    isProvider,
    currentCustomerIsAdmin,
    onSubmitNegotiationAnswer,
    transaction,
    negotiationType,
    adminEditMode,
    adminEditOfferNumber
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const transactionId = transaction?.id.uuid;
  // protectedData is maintained for older offers
  const originalOfferSrc = (transaction?.attributes?.protectedData?.scope !== undefined) ? 'protectedData' : 'metadata';
  const originalOfferData = originalOfferSrc === 'protectedData' ? transaction?.attributes?.protectedData 
                            : transaction?.attributes?.metadata?.originalOffer;
  const offerIsFromAdmin = !!originalOfferData?.isAdmin;
  const defaultCommission = (negotiationType === 'service') ? (1/3) : 0.5;
  const commission = transaction?.listing.attributes?.publicData?.commission ? transaction.listing.attributes.publicData.commission : defaultCommission;
  const readerRole = negotiationType === 'service' ? (isProvider ? 'expert' : 'client') : (isProvider ? 'client' : 'expert');
  const negotiationHistory = transaction?.attributes?.metadata?.negotiationHistory;
  const offerWasNegotiated = negotiationHistory?.length > 0;
  const lastNegotiationIndex = negotiationHistory?.length - 1;
  const latestOffer = {
    latestOfferScope: originalOfferData?.scope,
    latestOfferData: {
      estimatedPrice: (negotiationType === 'service') ? 
                        new Money(transaction?.attributes?.metadata?.originalOffer?.price*100, config.currency)
                        : currentCustomerIsAdmin ? new Money(transaction.attributes.metadata.originalOffer.price*100, config.currency)
                        : transaction.attributes.payinTotal,
      estimatedTime: offerIsFromAdmin ? originalOfferData?.estimatedTimeMin : originalOfferData?.estimatedTime
    }
  };
  if(offerWasNegotiated) {
    latestOffer.latestOfferScope = negotiationHistory[lastNegotiationIndex].scope;
    latestOffer.latestOfferData.estimatedPrice = new Money(negotiationHistory[lastNegotiationIndex].priceSummary*100, config.currency);
    latestOffer.latestOfferData.estimatedTime = negotiationHistory[lastNegotiationIndex].timeSummary;
  }
  if(adminEditMode) {
    if(adminEditOfferNumber === 0) {
      latestOffer.latestOfferScope = originalOfferData?.scope;
      latestOffer.latestOfferData.estimatedPrice = new Money(originalOfferData?.estimatedPriceMin*100, config.currency);
      latestOffer.latestOfferData.estimatedTime = originalOfferData?.estimatedTimeMin;
    } else {
      latestOffer.latestOfferScope = negotiationHistory[adminEditOfferNumber-1].scope;
      latestOffer.latestOfferData.estimatedPrice = new Money(negotiationHistory[adminEditOfferNumber-1].priceSummary*100, config.currency);
      latestOffer.latestOfferData.estimatedTime = negotiationHistory[adminEditOfferNumber-1].timeSummary;
    }
  }

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
    <NegotiateOfferForm 
      onSubmit={onSubmitNegotiationAnswer} 
      commission={commission} 
      readerRole={readerRole}
      currentCustomerIsAdmin={currentCustomerIsAdmin}
      transactionId={transactionId} 
      isModalOpen={isOpen}
      negotiationType={negotiationType}
      adminEditMode={adminEditMode}
      {...latestOffer} 
    />
    </Modal>
  );
};

const { string } = PropTypes;

NegotiationModal.defaultProps = {
  className: null,
  rootClassName: null,
};

NegotiationModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(NegotiationModal);
