import React from 'react';
import classNames from 'classnames';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { LISTING_PAGE_PENDING_APPROVAL_VARIANT } from '../../util/urlHelpers';
import { Modal, NamedLink, PrimaryButton, SecondaryButton } from '..';

import exams from '../../components/ExpertModal/media/jobs_pack.png';
import css from './ExpertModal.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../../util/urlHelpers';
import { AiOutlineCalendar } from 'react-icons/ai';
const Step = props => {
  const { title, completed, current } = props;

  return (
    <div className={css.step}>
      <div className={css.pointedLine}>
        <div className={classNames(css.point, completed && css.pointCompleted)} />
        <div className={classNames(css.line, completed && css.lineCompleted)} />
      </div>
      <div className={classNames(css.stepTitle, current && css.stepTitleCurrent)}>{title}</div>
    </div>
  );
};

// This modal is used on 2 stages of client's path:
// 1. When user's project pending approval
// 2. When a deposit has been paid, but user hasn't receive any offers yet
const ExpertModal = props => {
  const {
    id,
    step,
    listingId,
    isPendingApproval,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
  } = props;

  let projectBoardParams = step === 1 ? { id: listingId } : {};

  if (isPendingApproval) {
    projectBoardParams.variant = LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  }
  const pmCallTrigger = () => {
    const { history } = props;
    history.push(
      createResourceLocatorString(
        'ExpertPage',
        routeConfiguration(),
        {
          id: process.env.REACT_APP_ADMIN_BOOKING_LISTING_ID,
          slug: createSlug('Michael'),
          category: 'experts',
        },
        {}
      ),
      { openBookModal: true }
    );
  };
  return (
    <Modal
      id={id}
      containerClassName={css.root}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <h1 className={css.modalTitle}>
        <FormattedMessage id="ExpertModal.title" />
      </h1>
      <FormattedMessage id="ExpertModal.description" values={{ br: <br /> }} /><br/><br/>
      <FormattedMessage id="ExpertModal.description2" values={{ br: <br /> }} /><br/><br/>
      <FormattedMessage id="ExpertModal.description3" values={{ br: <br /> }} />
      <div className={css.stepsContainer}>
        <Step
          title={<FormattedMessage id="ExpertModal.step1" />}
          current={false}
          completed={true}
        />
        <Step
          title={<FormattedMessage id="ExpertModal.step2" />}
          current={false}
          completed={true}
        />
        <Step
          title={<FormattedMessage id="ExpertModal.step3" />}
          current={false}
          completed={true}
        />
        <Step
          title={<FormattedMessage id="ExpertModal.step4" />}
          current={false}
          completed={true}
        />
        <Step
          title={<FormattedMessage id="ExpertModal.step5" />}
          current={true}
          completed={false}
        />
      </div>
      <h2>
        <FormattedMessage id="ExpertModal.benefits" values={{ b: <b />, br: <b /> }} />
      </h2>
      <div className={css.benefits}>
        <div className={css.benefit}>
          <FormattedMessage id="ExpertModal.benefit1" values={{ b: <b />, br: <b /> }} />
        </div>
        <div className={css.benefit}>
          <FormattedMessage id="ExpertModal.benefit2" values={{ b: <b />, br: <b /> }} />
        </div>
        <div className={css.benefit}>
          <FormattedMessage id="ExpertModal.benefit3" values={{ b: <b />, br: <b /> }} />
        </div>
      </div>

      <div className={css.contact}>
        {/* General part of the modal */}
        <div className={css.buttonsContainer}>
          <SecondaryButton className={css.contactButton}>
            <NamedLink name="SearchPage_jobs" className={css.buttonLink}>
              <FormattedMessage id="ExpertModal.goToJobs" />
            </NamedLink>
          </SecondaryButton>
          <SecondaryButton className={css.contactButton}>
            <NamedLink name="BillingPage" className={css.buttonLink}>
              <FormattedMessage id="ExpertModal.invoice" />
            </NamedLink>
          </SecondaryButton>
          <PrimaryButton className={css.contactButton}>
            <NamedLink name="SubscriptionPage" className={css.buttonLinkPrime}>
              <FormattedMessage id="ExpertModal.buyHourly" />
            </NamedLink>
          </PrimaryButton>
        </div>
        <div className={css.mainInfo}>
          <img src={exams} width="100%" alt="Exams" />
        </div>
        <div className={css.summarize}>
          <FormattedMessage id="ExpertModal.summarize" />
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(ExpertModal);
