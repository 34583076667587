import React, { useState } from 'react';
import { IoMdArrowDropright, IoMdArrowDropdown } from 'react-icons/io';
import { MdContentCopy } from 'react-icons/md';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { richText } from '../../util/richText';
import css from './ProjectBoardPage.css';

const SectionProjectInfo = props => {
  const { projectTitle, projectDescription, projectScope, listingId, isPendingApproval } = props;

  const id = listingId.uuid;

  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const [scopeVisible, setScopeVisible] = useState(false);
  const [npsLinkCopied, setNPSLinkCopied] = useState(false);

  const copyNPSLink = npsLink => {
    navigator.clipboard.writeText(npsLink);
    setNPSLinkCopied(true);
  };

  const npsLink = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/${id}/nps`;
  const npsWrapper = (
    <div className={css.npsLinkWrapper}>
      <div className={css.npsLink}>
        <div className={css.npsTitle}>
          <FormattedMessage id="TransactionPanel.yourNPSLink" />
        </div>
        <NamedLink name="NPSSurveyPage" params={{ id }}>
          {npsLink}
        </NamedLink>
      </div>
      {npsLinkCopied ? (
        <div className={css.copiedText}>copied!</div>
      ) : (
        <div className={css.copyButton} onClick={() => copyNPSLink(npsLink)}>
          <MdContentCopy size={17} />
        </div>
      )}
    </div>
  );

  const heading = (
    <p className={css.projectBoardHeading}>
      <FormattedMessage id="ProjectBoardPage.heading" />
    </p>
  );

  const title = <h1 className={css.projectTitle}>{projectTitle}</h1>;

  const approvalInfo = isPendingApproval ? (
    <p className={css.approvalInfo}>
      <AiOutlineExclamationCircle className={css.buttonIcon} />
      <FormattedMessage id="ProjectBoardPage.approvalInfo" />
    </p>
  ) : null;

  const descriptionTitle = (
    <p
      className={css.projectInfoSubtitle}
      onClick={() => setDescriptionVisible(!descriptionVisible)}
    >
      <FormattedMessage id="ProjectBoardPage.description" />{' '}
      {descriptionVisible ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
    </p>
  );
  const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;
  
  const descriptionText = descriptionVisible
    ? richText(projectDescription, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
        longWordClass: css.longWord,
        linkify: true,
        mailify: true,
      })?.map(line => <p className={css.projectDescription}>{line} </p>)
    : null;

  const scopeTitle = (
    <p className={css.projectInfoSubtitle} onClick={() => setScopeVisible(!scopeVisible)}>
      <FormattedMessage id="ProjectBoardPage.scope" />{' '}
      {scopeVisible ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
    </p>
  );

  const scope = scopeVisible
    ? projectScope.map(milestone => {
        return (
          <>
            <div className={css.scope}>
              <div className={css.scopeSideWrapper}>
                <div className={css.scopePointer} />
                <div
                  className={
                    projectScope.indexOf(milestone) + 1 === projectScope.length &&
                    milestone.subtasks.length === 0
                      ? css.scopeNoSideLine
                      : projectScope.indexOf(milestone) + 1 === projectScope.length
                      ? css.scopeLastSideLine
                      : css.scopeSideLine
                  }
                />
              </div>
              <div className={css.milestoneWrapper}>
                <div className={css.milestoneFirstRow}>
                  <span className={css.milestoneTitle}>{milestone.task}</span>
                </div>
                <div className={css.subtasksWrapper}>
                  {milestone.subtasks.map(subtask => {
                    return <span className={css.subtask}>{subtask.name}</span>;
                  })}
                </div>
              </div>
            </div>
          </>
        );
      })
    : null;

  return (
    <div className={css.projectInfoWrapper}>
      {heading}
      {title}
      {npsWrapper}
      {approvalInfo}
      {descriptionTitle}
      {descriptionText}
      {projectScope?.length > 0 ? (
        <>
          {scopeTitle}
          {scope}
        </>
      ) : null}
    </div>
  );
};

export default SectionProjectInfo;
