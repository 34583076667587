import React from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './TicketModal.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDeliveryDateMaybe = props => {
  const { deliveryDate } = props;
  return deliveryDate ? (
    <div className={css.sectionDeliveryDate}>
      <div>
        <AiOutlineCalendar />
        <span className={css.deliveryDateTitle}>
          <FormattedMessage id="TicketModal.deliveryDate" />
        </span>
      </div>
      <span className={css.deliveryDate}>
        {richText(deliveryDate, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </span>
    </div>
  ) : null;
};

export default SectionDeliveryDateMaybe;
