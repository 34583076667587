/* eslint-disable */
import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  AiOutlineUserAdd,
  AiOutlineCalendar,
  AiOutlineFolderOpen,
  AiOutlineLock,
} from 'react-icons/ai';
import {
  HiDocumentReport,
} from 'react-icons/hi';
import { createSlug } from '../../util/urlHelpers';
import { AiOutlineDollar } from 'react-icons/ai';
import { FormattedMessage } from '../../util/reactIntl';
import {
  SecondaryButton,
  PrimaryButton,
  ExternalLink,
  NamedLink,
  Modal,
  ListingLink,
} from '../../components';
import { InviteUserForm } from '../../forms';
import { addUserToTicket } from '../../util/api';
import { sendInviteMail } from '../../util/triggerMail';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ProjectBoardPage.css';
const { Money } = sdkTypes;
// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SectionButtons = props => {
  const {
    onOpenRepoModal,
    onDownloadReport,
    showSubmitCredentialsButton,
    onManageDisableScrolling,
    currentUser,
    currentProject,
    isAdminProfile,
    isProjectSolved,
    isProjectOwner,
    isPendingApproval,
    listingInvoiceUrl,
    closeListing,
    transactionId,
    estimatedHours,
    completedHours,

    // currentUserStripeAccounts,
    connectedExperts,
    expertConnectedToProject,
  } = props;
  const [sendInviteModal, setSendInviteModal] = useState(false);
  const [userAlreadyInvited, setUserAlreadyInvited] = useState(false);
  const [userSuccessfullyInvited, setUserSuccessfullyInvited] = useState(false);
  const [userAlreadyAllowed, setUserAlreadyAllowed] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [completeProjectModalFlag, setCompleteProjectModalFlag] = useState(false);
  const expertPrice = connectedExperts
    ? connectedExperts.length > 0
      ? connectedExperts[0].data.attributes.price
      : 0
    : 0;

  let plannedBudget = new Money(0, 'USD');
  let usedBudget = new Money(0, 'USD');
  let estimatedBudget = new Money(0, 'USD');
  if (expertPrice && currentProject.attributes.price) {
    plannedBudget = expertConnectedToProject
      ? new Money(
          currentProject.attributes.price.amount / process.env.REACT_APP_COMMISSION_FACTOR,
          'USD'
        )
      : currentProject.attributes.price;

    usedBudget = expertConnectedToProject
      ? new Money((expertPrice.amount * completedHours) / 60, 'USD')
      : new Money(
          (expertPrice.amount * process.env.REACT_APP_COMMISSION_FACTOR * completedHours) / 60,
          'USD'
        );

    estimatedBudget = expertConnectedToProject
      ? new Money((expertPrice.amount * estimatedHours) / 60, 'USD')
      : new Money(
          (expertPrice.amount * process.env.REACT_APP_COMMISSION_FACTOR * estimatedHours) / 60,
          'USD'
        );
  }


  const onSubmitInvite = (values, form) => {
    setUserAlreadyInvited(false);
    setUserSuccessfullyInvited(false);
    setUserAlreadyAllowed(false);

    const invitedUsers = currentProject.attributes.publicData?.invitedUsers
      ? currentProject.attributes.publicData.invitedUsers
      : null;

    const { allowedUserEmail } = values;
    const foundInInvited = invitedUsers
      ? invitedUsers.find(user => user === allowedUserEmail)
      : false;

    if (!foundInInvited) {
      const body = {
        userId: currentUser.id.uuid,
        listingId: currentProject.id.uuid,
        emails: [allowedUserEmail],
        isProject: true,
      };
      addUserToTicket(body).then(() => {
        form.reset();
        const inviteListUpdated =
          inviteList?.length > 0
            ? inviteList
            : currentProject.attributes.publicData.invitedUsers?.length
            ? currentProject.attributes.publicData.invitedUsers
            : [];
        if (inviteListUpdated) {
          inviteListUpdated.push(values.allowedUserEmail);
        }
        setInviteList(inviteListUpdated);
        setUserAlreadyInvited(false);
        setUserSuccessfullyInvited(true);

        sendInviteMail(
          currentProject,
          currentUser.attributes.profile.firstName,
          values.allowedUserEmail,
        );
      });
    } else if (foundInInvited) {
      setUserAlreadyInvited(true);
    } else {
      setUserAlreadyAllowed(true);
    }
  };

  const addTeammatesButton = (
    <SecondaryButton
      key="invite"
      onClick={() => {
        setSendInviteModal(true);
        setUserAlreadyInvited(false);
        setUserSuccessfullyInvited(false);
        setUserAlreadyAllowed(false);
      }}
      className={css.button}
    >
      <AiOutlineUserAdd className={css.buttonIcon} />
      <FormattedMessage id="ProjectBoardPage.addTeammates" />
    </SecondaryButton>
  );

  const repoButton = (
    <SecondaryButton key="repo" onClick={onOpenRepoModal} className={css.button}>
      <AiOutlineFolderOpen className={css.buttonIcon} />
      <FormattedMessage id="TransactionPanel.projectRepo" />
    </SecondaryButton>
  );

  const downloadReportButton = (
    <SecondaryButton key="downloadReport" onClick={onDownloadReport} className={css.button}>
      <HiDocumentReport className={css.buttonIcon} />
      <FormattedMessage id="TransactionPanel.downloadReport" />
    </SecondaryButton>
  );


  const submitCredentialsButton = (
    <ExternalLink
      key="submitCredentials"
      href="https://www.cognitoforms.com/SoftwareSupp/SecuredAccessUpload"
      className={css.submitCredentialsButton}
    >
      <SecondaryButton className={css.button}>
        <AiOutlineLock className={css.buttonIcon} />
        <FormattedMessage id="TransactionPanel.submitCredentials" />
      </SecondaryButton>
    </ExternalLink>
  );

  const pmCallButton = (
    // <SecondaryButton
    //   key="pmCallButton"
    //   onClick={() => {
    //     window.location.href = 'https://calendly.com/softwaresupp/software-consultation';
    //   }}
    //   className={css.button}
    // >
    <NamedLink
      name="ExpertPage"
      params={{
        id: process.env.REACT_APP_ADMIN_BOOKING_LISTING_ID,
        slug: createSlug('Michael'),
        category: 'experts',
      }}
      state={{ openBookModal: true, freeBooking: true }}
      className={css.pmCallButton}
      key="pmCallButton"
    >
      <SecondaryButton key="call1234">
        {/* <SecondaryButton
        onClick={() => {
          window.location.href = 'https://calendly.com/softwaresupp/softwaresupp-recruitment';
        }}
        className={css.button}
      >
        <AiOutlineCalendar className={css.buttonIcon} />
        <FormattedMessage id="ProjectBoardPage.pmCall" />
        <Tooltip interactive title={<FormattedMessage id="ProjectBoardPage.pmCallTooltip" />}>
          <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
        </Tooltip>
      </SecondaryButton> */}

        <AiOutlineCalendar className={css.buttonIcon} />
        <FormattedMessage id="ProjectBoardPage.pmCall" />
        <Tooltip interactive title={<FormattedMessage id="ProjectBoardPage.pmCallTooltip" />}>
          <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
        </Tooltip>
      </SecondaryButton>
    </NamedLink>
  );

  const paymentButton = listingInvoiceUrl ? (
    <SecondaryButton
      key="paymentButton"
      className={css.button}
      onClick={() => window.open(listingInvoiceUrl)}
    >
      <AiOutlineDollar className={css.buttonIcon} />
      <FormattedMessage id="ProjectBoardPage.openPayment" />
    </SecondaryButton>
  ) : transactionId ? (
    <NamedLink
      key="paymentButtonLink"
      name="SaleDetailsPage"
      params={{
        id: transactionId,
      }}
    >
      <SecondaryButton key="paymentButton" className={css.button}>
        <AiOutlineDollar className={css.buttonIcon} />{' '}
        <FormattedMessage id="ProjectBoardPage.openPayment" />
      </SecondaryButton>
    </NamedLink>
  ) : null;

  const inviteUserModal = (
    <Modal
      id="InviteUserModal"
      contentClassName={css.enquiryModalContent}
      isOpen={sendInviteModal}
      onClose={() => setSendInviteModal(false)}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <InviteUserForm
        id="InviteUserForm"
        onSubmit={onSubmitInvite}
        userAlreadyInvited={userAlreadyInvited}
        userAlreadyAllowed={userAlreadyAllowed}
        userSuccessfullyInvited={userSuccessfullyInvited}
        clearInvitation={() => setUserSuccessfullyInvited(false)}
        isPendingApproval={isPendingApproval}
      />
    </Modal>
  );

  const projectPageButton = (
    <ListingLink key="projectPageButton" listing={currentProject}>
      <SecondaryButton className={css.button}>
        <FormattedMessage id="ProjectBoardPage.goToProject" />
      </SecondaryButton>
    </ListingLink>
  );
  const completeProjectButton = (
    <PrimaryButton key="completeProjectButton" onClick={() => setCompleteProjectModalFlag(true)}>
      <FormattedMessage id="ProjectBoardPage.completeButton" />
    </PrimaryButton>
  );
  const completeProjectButtonFinal = (
    <div key="completeProjectButtonFinal">
      <FormattedMessage className={css.sectionButtonsWrapper} id="ProjectBoardPage.completeInfo" />
      <PrimaryButton
        onClick={() => {
          closeListing(currentProject.id.uuid);
          // adjustBalance(currentUser.attributes.profile.privateData.inboxToken, {
          //   amount: -leftBudget.amount,
          //   currency: 'usd',
          //   customer: currentUserUsdAccount.id,
          // });
        }}
      >
        <FormattedMessage id="ProjectBoardPage.completeButtonFinal" />
      </PrimaryButton>
    </div>
  );

  return (
    <>
      <div className={css.sectionButtonsWrapper}>
        {[
          projectPageButton,
          isProjectOwner ? paymentButton : null,
          !isProjectSolved ? addTeammatesButton : null,
          !isProjectSolved ? repoButton : null,
          !isProjectSolved ? downloadReportButton : null,
          isProjectSolved || isAdminProfile ? null : pmCallButton,
          !isProjectSolved && showSubmitCredentialsButton ? submitCredentialsButton : null,
          !isProjectSolved && isProjectOwner && !completeProjectModalFlag
            ? completeProjectButton
            : null,
          !isProjectSolved && isProjectOwner && completeProjectModalFlag
            ? completeProjectButtonFinal
            : null,
        ]}
      </div>
      {inviteUserModal}
    </>
  );
};

export default SectionButtons;
