import React from 'react';
import { string, arrayOf, bool, func, object, oneOfType } from 'prop-types';
import { richText } from '../../util/richText';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import { Avatar, InlineTextButton, UserDisplayName, NamedLink } from '../../components';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
  TRANSITION_BOOKING_PRIVILIGED,
  TRANSITION_CANCEL_MEETING,
  TRANSITION_FINISH_MEETING,
  TRANSITION_FINISH_MEETING_SOFTWARESUPP,
  PRE_TRANSITION_SEND_OFFER,
  txRoleIsProvider,
  getUserTxRole,
  isRelevantPastTransition,
  TRANSITION_CANCEL_OFFER,
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_PAY_FOR_OFFER,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import css from './ActivityFeed.css';


const MIN_LENGTH_FOR_LONG_WORDS = 10;

const Message = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.message}>
      <Avatar className={css.avatar} user={message.sender} disableProfileLink />
      <div>
        <div className={css.messageContent}>
          {richText(message.attributes.content, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
            linkify: true,
          })?.map(line => {
            return <p className={css.messageLine}>{line}</p>;
          })}
        </div>
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: oneOfType([propTypes.message, object]),
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <div className={css.ownMessageContent}>
          {richText(message.attributes.content, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
            linkify: true,
            linkClass: css.ownMessageLink,
          })?.map(line => {
            return <p className={css.messageLine}>{line}</p>;
          })}
        </div>
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, message.attributes.createdAt)}
      </p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl
) => {
  const currentTransition = transition.transition;
  const displayName = otherUsersName;

  const projectLink = !!transaction.listing && (
    <NamedLink name="ProjectBoardPage" params={{ id: transaction.listing?.id.uuid }}>
      <FormattedMessage id="ActivityFeed.projectsLink" />
    </NamedLink>
  );

  if (currentTransition === TRANSITION_ACCEPT_OFFER && transaction.attributes.metadata.isPaid) {
    return txRoleIsProvider(ownRole) ? (
      <FormattedMessage id="ActivityFeed.youHavePaidForOffer" values={{ projectLink }} />
    ) : (
      <FormattedMessage
        id="ActivityFeed.theCustomerHasPaid"
        values={{ customerName: displayName }}
      />
    );
  }

  switch (currentTransition) {
    case TRANSITION_ACCEPT_OFFER:
      return <FormattedMessage id="ActivityFeed.accepted" />;
    case TRANSITION_BOOKING_PRIVILIGED:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequestBooking" values={{ displayName }} />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionRequestBooking" values={{ listingTitle }} />
      );
    case TRANSITION_CANCEL_MEETING:
      return <FormattedMessage id="ActivityFeed.transitionCancelBooking" />;
    case TRANSITION_FINISH_MEETING:
    case TRANSITION_FINISH_MEETING_SOFTWARESUPP:
      return <FormattedMessage id="ActivityFeed.transitionCompleteBooking" />;
    case PRE_TRANSITION_SEND_OFFER:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.priceSentUser" />
      ) : (
        <FormattedMessage id="ActivityFeed.priceSentExpert" />
      );
    case TRANSITION_CANCEL_OFFER:
      return <FormattedMessage id="ActivityFeed.offerCanceled" />;
    case TRANSITION_PAY_FOR_OFFER:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.youHavePaidForOffer" values={{ projectLink }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.theCustomerHasPaid"
          values={{ customerName: displayName }}
        />
      );
    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

const Transition = props => {
  const { transition, transaction, currentUser, intl } = props;

  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
    <UserDisplayName user={provider} intl={intl} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl
  );

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>{formatDate(intl, todayString, transition.createdAt)}</p>
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: oneOfType([propTypes.transaction, object]),
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  // combine messages and transaction transitions
  const items = organizedItems(messages, transitions, hasOlderMessages || fetchMessagesInProgress);

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };

  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    if (isOwnMessage) {
      return <OwnMessage message={message} intl={intl} />;
    }
    return <Message message={message} intl={intl} />;
  };

  const messageListItem = message => {
    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent(message)}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (isRelevantPastTransition(transition.transition)) {
      return (
        <li key={transition.createdAt} className={css.transitionItem}>
          {transitionComponent(transition)}
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {items.map(item => {
        if (isMessage(item)) {
          return messageListItem(item);
        } else {
          return transitionListItem(item);
        }
      })}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: oneOfType([propTypes.transaction, object]),
  messages: arrayOf(oneOfType([propTypes.message, object])),
  hasOlderMessages: bool.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
