import React from 'react';
import { IoMdAttach } from 'react-icons/io';
import css from './AttachmentsList.css';

const AttachmentsList = props => {

  const linkClass = props.color === 'white' ? css.linkWhite : css.linkBlack;

  const generateAttachments = (attachments) => {
    return attachments.map((el) => {
      return (
        <li key={el.url}>
          <a href={el.url} target="_blank" rel="noopener noreferrer" className={linkClass}>
            <IoMdAttach />
            {el.originalname}
          </a>
        </li>
      )
    })
  }

  return (
    <ul className={css.attachmentsWrapper}>
      {props.data?.length > 0 && 
        generateAttachments(props.data)
      }
    </ul>
  )
}

export default AttachmentsList;