import intersection from 'lodash/intersection';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_CATEGORY_EXPERT,
  LISTING_CATEGORY_JOB,
  LISTING_CATEGORY_SERVICE,
} from '../../util/types';

/**
 * Validates a filter search param agains a filters configuration.
 *
 * All invalid param names and values are dropped
 *
 * @param {String} paramName Search parameter name
 * @param {Object} paramValue Search parameter value
 * @param {Object} filters Filters configuration
 */
export const validURLParamForExtendedData = (paramName, paramValueRaw, filters) => {
  const filtersArray = Object.values(filters);
  // resolve configuration for this filter
  const filterConfig = filtersArray.find(f => f.paramName === paramName);

  const paramValue = paramValueRaw.toString();
  const valueArray = paramValue ? paramValue.split(',') : [];

  if (filterConfig && valueArray.length > 0) {
    const { min, max, active } = filterConfig.config || {};

    if (filterConfig.options) {
      // Single and multiselect filters
      const allowedValues = filterConfig.options.map(o => o.key);

      const validValues = intersection(valueArray, allowedValues).join(',');
      return validValues.length > 0 ? { [paramName]: validValues } : {};
    } else if (filterConfig.config && min != null && max != null) {
      // Price filter
      const validValues = valueArray.map(v => {
        return v < min ? min : v > max ? max : v;
      });
      return validValues.length === 2 ? { [paramName]: validValues.join(',') } : {};
    } else if (filterConfig.config && active) {
      // Generic filter
      return paramValue.length > 0 ? { [paramName]: paramValue } : {};
    }
  }
  return {};
};

/**
 * Checks filter param value validity.
 *
 * Non-filter params are dropped.
 *
 * @param {Object} params Search params
 * @param {Object} filters Filters configuration
 */
export const validFilterParams = (params, filters) => {
  const filterParamNames = Object.values(filters).map(f => f.paramName);
  const paramEntries = Object.entries(params);

  return paramEntries.reduce((validParams, entry) => {
    const paramName = entry[0];
    const paramValue = entry[1];

    return filterParamNames.includes(paramName)
      ? {
          ...validParams,
          ...validURLParamForExtendedData(paramName, paramValue, filters),
        }
      : { ...validParams };
  }, {});
};

/**
 * Checks filter param value validity.
 *
 * Non-filter params are returned as they are.
 *
 * @param {Object} params Search params
 * @param {Object} filters Filters configuration
 */
export const validURLParamsForExtendedData = (params, filters) => {
  const filterParamNames = Object.values(filters).map(f => f.paramName);
  const paramEntries = Object.entries(params);

  return paramEntries.reduce((validParams, entry) => {
    const paramName = entry[0];
    const paramValue = entry[1];

    return filterParamNames.includes(paramName)
      ? {
          ...validParams,
          ...validURLParamForExtendedData(paramName, paramValue, filters),
        }
      : { ...validParams, [paramName]: paramValue };
  }, {});
};

// extract search parameters, including a custom URL params
// which are validated by mapping the values to marketplace custom config.
export const pickSearchParamsOnly = (params, filters) => {
  const { address, origin, bounds, ...rest } = params || {};
  const boundsMaybe = bounds ? { bounds } : {};
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const filterParams = validFilterParams(rest, filters);

  return {
    ...boundsMaybe,
    ...originMaybe,
    ...filterParams,
  };
};

export const createSearchResultSchema = (
         listings,
         searchParams,
         intl,
         productSoftware,
         productSkills,
         productIndustries,
       ) => {
         // Schema for search engines (helps them to understand what this page is about)
         // http://schema.org
         // We are using JSON-LD format
         const capitalize = function(word) {
           return word.charAt(0).toUpperCase() + word.slice(1);
         };

         const siteTitle = config.siteTitle;
         const categoryParameter =
           searchParams && searchParams.category ? searchParams.category : null;
         let searchParameter;
         if ( searchParams?.pub_software) {
           searchParameter = searchParams.pub_software;
         } else if (searchParams?.pub_softwares && typeof searchParams?.pub_softwares === 'string') {
           searchParameter = searchParams.pub_softwares;
         } else if ( searchParams?.pub_skills ) {
           searchParameter = searchParams.pub_skills;
         } else if ( searchParams?.pub_industries ) {
           searchParameter = searchParams.pub_industries;
         } else {
           searchParameter = null;
         }
         let softwareKey = productSoftware.filter(k => k.key === searchParameter);
         let skillKey = productSkills.filter(k => k.key === searchParameter);
         let industryKey = productIndustries.filter(k => k.key === searchParameter);
         if(softwareKey.length > 0) {
           searchParameter = softwareKey[0].label;
         } else if(skillKey.length > 0) {
           searchParameter = skillKey[0].label;
         } else if(industryKey.length > 0) {
           searchParameter = industryKey[0].label;
         } else {
           searchParameter = null;
         }
         const schemaTitleExperts = searchParameter
           ? intl.formatMessage(
               { id: 'SearchPage.schemaTitleExperts' },
               { number: listings.length, searchKeyword: capitalize(searchParameter), siteTitle }
             )
           : intl.formatMessage({ id: 'SearchPage.defaultSchemaTitleExperts' }, { siteTitle });

         const schemaTitleJobs = searchParameter
           ? intl.formatMessage(
               { id: 'SearchPage.schemaTitleJobs' },
               { number: listings.length, searchKeyword: capitalize(searchParameter), siteTitle }
             )
           : intl.formatMessage({ id: 'SearchPage.defaultSchemaTitleJobs' }, { siteTitle });

         const schemaTitleServices = searchParameter
           ? intl.formatMessage(
               { id: 'SearchPage.schemaTitleServices' },
               { searchKeyword: capitalize(searchParameter), siteTitle }
             )
           : intl.formatMessage({ id: 'SearchPage.defaultSchemaTitleServices' }, { siteTitle });

         const schemaTitle =
           categoryParameter && categoryParameter === LISTING_CATEGORY_EXPERT
             ? schemaTitleExperts
             : categoryParameter && categoryParameter === LISTING_CATEGORY_JOB
             ? schemaTitleJobs
             : categoryParameter && categoryParameter === LISTING_CATEGORY_SERVICE
             ? schemaTitleServices
             : intl.formatMessage({ id: 'SearchPage.defaultSchemaTitle' }, { siteTitle });

         const schemaDescriptionExperts = searchParameter
           ? intl.formatMessage(
               { id: 'SearchPage.schemaDescriptionExperts' },
               { searchKeyword: capitalize(searchParameter), siteTitle }
             )
           : intl.formatMessage(
               { id: 'SearchPage.defaultSchemaDescriptionExperts' },
               { siteTitle }
             );

         const schemaDescriptionJobs = searchParameter
           ? intl.formatMessage(
               { id: 'SearchPage.schemaDescriptionJobs' },
               { searchKeyword: capitalize(searchParameter), siteTitle }
             )
           : intl.formatMessage({ id: 'SearchPage.defaultSchemaDescriptionJobs' }, { siteTitle });

         const schemaDescriptionServices = searchParameter
           ? intl.formatMessage(
               { id: 'SearchPage.schemaDescriptionServices' },
               { searchKeyword: capitalize(searchParameter), siteTitle }
             )
           : intl.formatMessage(
               { id: 'SearchPage.defaultSchemaDescriptionServices' },
               { siteTitle }
             );

         const schemaDescription =
           categoryParameter && categoryParameter === LISTING_CATEGORY_EXPERT
             ? schemaDescriptionExperts
             : categoryParameter && categoryParameter === LISTING_CATEGORY_JOB
             ? schemaDescriptionJobs
             : categoryParameter && categoryParameter === LISTING_CATEGORY_SERVICE
             ? schemaDescriptionServices
             : intl.formatMessage({ id: 'SearchPage.defaultSchemaDescription' }, { siteTitle });

         const schemaListings = listings.map((l, i) => {
           const title = l.attributes.title;
           const pathToItem = createResourceLocatorString(
             `${capitalize(l.attributes.publicData.category)}Page`,
             routeConfiguration(),
             {
               id: l.id.uuid,
               slug: createSlug(title),
               category: `${l.attributes.publicData.category}s`,
             }
           );
           return {
             '@type': 'ListItem',
             position: i,
             url: `${config.canonicalRootURL}${pathToItem}`,
             name: title,
           };
         });

         const schemaMainEntity = JSON.stringify({
           '@type': 'ItemList',
           name: searchParameter,
           itemListOrder: 'http://schema.org/ItemListOrderAscending',
           itemListElement: schemaListings,
         });
         return {
           title: schemaTitle,
           description: schemaDescription,
           schema: {
             '@context': 'http://schema.org',
             '@type': 'SearchResultsPage',
             description: schemaDescription,
             name: schemaTitle,
             mainEntity: [schemaMainEntity],
           },
         };
       };
