import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Chip.css';

const Chip = props => {
  const {
    children,
    className,
    rootClassName,
    ...rest
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);

  return (
    <div className={classes} {...rest} >
      {children}
    </div>
  );
}

const { node, string } = PropTypes;

Chip.defaultProps = {
  rootClassName: null,
  className: null,
  children: null,
};

Chip.propTypes = {
  rootClassName: string,
  className: string,
  children: node,
};

export default Chip;

export const PrimaryChip = props => {
  const classes = classNames(props.rootClassName || css.primaryChipRoot, css.primaryChip);
  return <Chip {...props} rootClassName={classes} />;
};
PrimaryChip.displayName = 'PrimaryChip';

export const SecondaryChip = props => {
  const classes = classNames(props.rootClassName || css.secondaryChipRoot, css.secondaryChip);
  return <Chip {...props} rootClassName={classes} />;
};
SecondaryChip.displayName = 'SecondaryChip';

export const HighlightedChip = props => {
  const classes = classNames(props.rootClassName || css.highlightedChipRoot, css.highlightedChip, props.addClassName);
  return <Chip {...props} rootClassName={classes} />;
};
HighlightedChip.displayName = 'HighlightedChip';

export const DefaultChip = props => {
  const classes = classNames(props.rootClassName || css.defaultChipRoot, css.defaultChip);
  return <Chip {...props} rootClassName={classes} />;
};
DefaultChip.displayName = 'DefaultChip';
