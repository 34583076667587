/* eslint-disable */
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Edit } from 'react-feather';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import {
  showTicketMessageOwner,
  showUser,
  showListing,
  requestImageUpload,
  requestUpdateListing,
  requestCreateListingDraft,
  requestPublishListingDraft,
} from './TicketModal.duck';
import { ensureListing, ensureOwnListing, ensureUser } from '../../util/data';
import { getMarketplaceEntities, addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { richText } from '../../util/richText';
import { IconSpinner, MessagesPanel, Modal } from '../../components';
import { SolutionForm, EditTicketForm, EditReportTime } from '../../forms';
import { parseTime } from '../../util/helpers';
import { approveListing, changeTicketData, sendTicketNotifications } from '../../util/api';
import { adjustBalance, getStripeCustomer, getUser } from '../../util/api';
import {
  newAnswer,
  newAnswerNotOwn,
  newMention,
  sendTicketNotification,
  sendExpertAssignedToTicket,
} from '../../util/triggerMail';

import config from '../../config';

import { LISTING_STATE_CLOSED, LISTING_STATE_PENDING_APPROVAL } from '../../util/types';

import SectionTaskAuthor from './SectionTaskAuthor';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionDeliveryDateMaybe from './SectionDeliveryDateMaybe';
import SectionProgressMaybe from './SectionProgressMaybe';
import SectionPriority from './SectionPriority';
import SectionImages from './SectionImages/SectionImages';

import css from './TicketModal.css';

const { UUID } = sdkTypes;

const MODAL_BREAKPOINT = 760;

const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};

export class TicketModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentListing: {},
      pageClassNames: [],
      imageCarouselOpen: false,
      listingState: null,
      deliveryDate: null,
      imageOrder: [],
      removedImageIds: [],
      images: [],
      listing: null,
      initialValues: null,
      estimationHours: null,
      completionHours: null,
      ticketAnswers: [],
      ticketUpdates: [],
      sendingEmailSequenceInProgress: false,
      listingIsClosed: false,
      ticketUpdateInProgress: false,
    };

    this.handleTicketSolution = this.handleTicketSolution.bind(this);
    this.handleNewMention = this.handleNewMention.bind(this);
    this.handleChangeTicketAnswer = this.handleChangeTicketAnswer.bind(this);
    this.newListingSequence = this.newListingSequence.bind(this);
    this.handleAddActivity = this.handleAddActivity.bind(this);
    this.adjustBalanceBasedOnTicketData = this.adjustBalanceBasedOnTicketData.bind(this);
  }

  componentDidMount() {
    const { listingId, onShowListing, isDraft, isProjectSolved } = this.props;
    const isNew = listingId === '00000000-0000-0000-0000-000000000000';
    if (isDraft) {
      if (!isNew) {
        onShowListing(listingId, true);
      }
    } else {
      if (!isNew) {
        onShowListing(listingId, false);
      }
    }

    if (isProjectSolved) {
      this.setState({ listingIsClosed: true });
    }
    if (typeof window !== 'undefined') {
      if(!window.location.search&&!isNew){
        window.history.replaceState(null, "Project Board", window.location+`?${listingId}`)
      }
    }
  }

  componentWillUnmount() {
    this.setState({ ticketAnswers: [] });
    if(String(window.location).includes('?')){
      window.history.replaceState(null, "Project Board", String(window.location).slice(0,String(window.location).indexOf('?')))
    }
  }

  async componentDidUpdate(prevProps) {
    const { listingId, isDraft, isEdit, getListing, getOwnListing } = this.props;
    const listing = isDraft ? getOwnListing(new UUID(listingId)) : getListing(new UUID(listingId));

    if (listing?.images?.length > 0 && this.state.images?.length === 0)
      this.setState({ images: listing.images });

    if (!this.state.initialValues && listing?.id) {
      const { description, title, publicData } = listing.attributes;
      const initialValues = {
        description,
        title,
        deliveryDate: publicData.deliveryDate,
        priority: publicData.priority,
        completionHours: publicData.completionHours,
        estimationHours: this.state.estimationHours || publicData.estimationHours,
        images: this.state.images,
      };
      this.setState({ initialValues: initialValues });
    }
    const { fetchedTicketAnswers, onShowTicketAuthor, onShowUser } = this.props;
    //FETCHING USER IMAGES AND EXPERT LISTINGS FOR ANSWERS
    if (
      listing?.attributes.publicData.answers?.length > 0 &&
      this.state.ticketAnswers.length === 0 &&
      !isEdit &&
      fetchedTicketAnswers?.length === listing?.attributes.publicData.answers?.length
    ) {
      const answers = await fetchedTicketAnswers;
      let ticketAnswers = [];
      if (answers) {
        for (let i = 0; i < answers.length; i++) {
          if (!answers[i].type) {
            const foundSameExpert = !!answers[i].expertId
              ? ticketAnswers.find(answer => answer.listing?.data.id.uuid === answers[i].expertId)
              : null;
            const listingData =
              answers[i].expertId && foundSameExpert
                ? { data: foundSameExpert.listing }
                : answers[i].expertId
                ? await onShowTicketAuthor(answers[i].expertId, true)
                : null;
            const userId = new UUID(`${answers[i].userId}`);
            const foundSameUser = ticketAnswers.find(answer => answer.userId === answers[i].userId);
            const userData = answers[i].userId && !foundSameUser ? await onShowUser(userId) : null;
            ticketAnswers[i] = {
              userId: answers[i].userId,
              listing: listingData ? listingData.data : null,
              user:
                answers[i].userId && foundSameUser
                  ? foundSameUser.user
                  : answers[i].userId
                  ? userData.data
                  : null,
              solution: answers[i].solution,
              date: answers[i].date,
              txId: answers[i].txId,
            };
          } else ticketAnswers[i] = answers[i];
        }
        this.setState({ ticketAnswers: ticketAnswers });
      }
    }
  }

  handleAddActivity(type, additionalInfo) {
    const { listingId, currentUser } = this.props;

    const activity = {
      listingId: listingId,
      userId: currentUser.id.uuid,
      type: 'add-answer',
      body: {
        date: new Date().toString(),
        type,
        additionalInfo,
      },
    };

    changeTicketData(activity).then(() => {
      const currentAnswers = this.state.ticketAnswers;
      currentAnswers.push(activity.body);
      this.setState({ ticketAnswers: currentAnswers });
      return null;
    });
  }

  // ADDING ANSWER TO TICKET
  handleTicketSolution(values, form, isOwnListing, userConnectedToTicket, expertConnectedToTicket) {
    const {
      listingId,
      currentUser,
      currentUserExpertListing,
      getListing,
      currentProject,
      expertsOnProject,
    } = this.props;
    const id = new UUID(listingId);
    const currentListing = getListing(id);
    const { solution } = values;

    if (!solution || solution?.length === 0) return null;

    const formattedOffer = solution.replace(/(\r\n|\n|\r)/gm, '\n');

    const expertsAnswered =
      currentListing.attributes.publicData.answers
        ?.filter(answer => !!answer.expertUserId && answer.expertUserId !== currentUser.id.uuid)
        ?.map(expertAnswer => expertAnswer.expertUserId)
        ?.filter(distinct) ?? [];

    const formattedExperts = [...expertsOnProject, ...expertsAnswered];

    const ticketSolution = {
      listingId: listingId,
      userId: currentUser.id.uuid,
      type: 'add-answer',
      body: expertConnectedToTicket
        ? {
            solution: formattedOffer,
            expertId: currentUserExpertListing.id.uuid,
            date: new Date().toString(),
            expertUserId: currentUser.id.uuid,
          }
        : {
            userId: currentUser.id.uuid,
            solution: formattedOffer,
            date: new Date().toString(),
          },
    };
    changeTicketData(ticketSolution).then(() => {
      this.handleNewMention(formattedOffer, currentListing);
      const currentAnswers = this.state.ticketAnswers;
      ticketSolution.body.listing = currentUserExpertListing;
      ticketSolution.body.user = currentUserExpertListing ? null : currentUser;
      currentAnswers.push(ticketSolution.body);
      this.setState({ ticketAnswers: currentAnswers });

      if (form) setTimeout(form.reset);

      newAnswer(currentListing, solution, currentUser, currentProject);
      if (
        formattedExperts?.length > 0 ||
        currentListing.attributes.publicData.invitedTicketUsers?.length > 0
      )
        newAnswerNotOwn(
          currentListing,
          formattedOffer,
          !expertConnectedToTicket
            ? currentUser.attributes.profile.displayName.slice(0, -2)
            : currentUserExpertListing.attributes.title,
          formattedExperts,
          currentProject
        );
    });
  }
  async adjustBalanceBasedOnTicketData(time, old_time) {
    const { connectedExperts, currentUser, currentProject } = this.props;

    const user = await getUser(currentUser.attributes.profile.privateData.inboxToken, {
      id: currentProject.author.id.uuid,
    });
    let expert_user = null;
    if (connectedExperts) {
      expert_user =
        connectedExperts.length > 0
          ? await getUser(currentUser.attributes.profile.privateData.inboxToken, {
              id: connectedExperts[0].data.relationships.author.data.id.uuid,
            })
          : null;
    }

    const stripeAccounts = await Promise.all(
      user.attributes.profile.privateData.stripeAccount.map(async data => {
        return await getStripeCustomer({ customer: data });
      })
    );

    const expertStripeAccounts = expert_user
      ? await Promise.all(
          expert_user.attributes.profile.privateData.stripeAccount.map(async data => {
            return await getStripeCustomer({ customer: data });
          })
        )
      : null;

    const customerStripe = stripeAccounts.find(
      data => data.currency === 'usd' || data.currency === null
    );

    const expertStripe = expertStripeAccounts
      ? expertStripeAccounts.find(data => data.currency === 'usd' || data.currency === null)
      : null;
    const expertPrice = connectedExperts
      ? connectedExperts.length > 0
        ? connectedExperts[0].data.attributes.price
        : 0
      : 0;
    let data = time.split(' ');

    let hours = parseInt(data[0].replace('h', ''));
    let minutes = data[1] ? parseInt(data[1].replace('m', '')) : 0;
    let time_report = (hours * 60 + minutes).toFixed(2);
    let old_time_report = 0;
    if (old_time) {
      data = old_time.split(' ');
      hours = parseInt(data[0].replace('h', ''));
      minutes = data[1] ? parseInt(data[1].replace('m', '')) : 0;
      old_time_report = (hours * 60 + minutes).toFixed(2);
    }

    const modPrice=1/(1-(currentProject?.attributes?.publicData?.commission ?? 0.5))*(expertPrice.amount ?? 0)

    
    if((modPrice *
        (time_report - old_time_report) /
        60)<0){
          var balance = Math.ceil(
            (modPrice *
              (time_report - old_time_report)) /
              60
          );
          var balanceFreelancer = Math.ceil(
            (expertPrice.amount *
              (time_report - old_time_report)) /
              60
          );
        }
    else{
      var balance = Math.floor(
        (modPrice *
          (time_report - old_time_report)) /
          60
      );
      var balanceFreelancer = Math.floor(
        (expertPrice.amount *
          (time_report - old_time_report)) /
          60
      );
    }

    //console.log('balance',balance,expertStripe,connectedExperts.length);
    if(balance !== 0){
      adjustBalance(currentUser.attributes.profile.privateData.inboxToken, {
        amount: balance,
        currency: 'usd',
        customer: customerStripe.id,
      });
      if (expertStripe) {
        if (connectedExperts.length == 1) {
          adjustBalance(currentUser.attributes.profile.privateData.inboxToken, {
            amount: -parseInt(balanceFreelancer),
            currency: 'usd',
            customer: expertStripe.id,
          });
        }
      }
    }
  }
  handleNewMention(formattedOffer, currentListing, isNew) {
    const { currentUser, currentProject } = this.props;
    const nonWhiteSpaceSequence = /([^\s]+)/gi;
    const mailRegex = /([A-Za-z0-9_\-+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})/gi; //eslint-disable-line

    const mentionedEmails = formattedOffer
      .split(nonWhiteSpaceSequence)
      .map(word => {
        if (word.match(mailRegex)) {
          return word.match(mailRegex);
        }
        return null;
      })
      ?.filter(mail => !!mail)
      ?.flat(1);

    const formattedMails = currentListing?.attributes.publicData.invitedTicketUsers
      ? mentionedEmails.filter(
          mail =>
            !currentListing.attributes.publicData.invitedTicketUsers.find(
              currentUser => mail === currentUser
            )
        )
      : mentionedEmails;

    if (formattedMails?.length > 0) {
      newMention(
        formattedMails,
        currentListing,
        currentUser,
        formattedOffer,
        currentProject.id.uuid
      );

      // addUserToTicket(body);
    }
  }

  handleChangeTicketAnswer(index, type, body, currentListing, currentUser) {
    const changeTicketBody = {
      index,
      type,
      listingId: currentListing.id.uuid,
      userId: currentUser.id.uuid,
      body,
    };
    changeTicketData(changeTicketBody).then(() => {
      if (type === 'delete') {
        const onlyAnswersWithType = this.state.ticketAnswers.filter(
          answer => answer.type
        );
        this.setState({
          ticketAnswers: onlyAnswersWithType.concat(this.state.ticketAnswers.filter((answer, i) => !answer.type).filter((answer, i) => i !== index)),
        });
      } else {
        const answers = this.state.ticketAnswers;
        answers[index].completionHours = body.completionHours;
        this.setState({ answers: answers });
      }
    });
  }

  // SEND ALL EMAILS ON LISTING CREATION
  newListingSequence(listingResponse, project) {
    if (!this.state.sendingEmailSequenceInProgress) {
      this.setState({ sendingEmailSequenceInProgress: true });
      const { currentUser, currentProject } = this.props;
      if (!project?.isTask) {
        sendExpertAssignedToTicket(
          listingResponse,
          currentProject.attributes.publicData.expert?.[0],
          currentUser.attributes.profile.firstName,
          currentProject.id.uuid
        );
      }
      sendTicketNotification(currentProject, listingResponse.attributes.description, listingResponse.attributes.title, 'newTask', listingResponse.id.uuid);

      sendTicketNotifications({
        projectId: currentProject.id.uuid,
        ticketId: listingResponse.id.uuid,
        currentUserId: currentUser.id.uuid,
        invitedUsers: currentProject.attributes.publicData.invitedUsers,
        expert: currentProject.attributes.publicData.expert?.[0],
        projectOwnerId: currentProject.author?.id.uuid,
        projectTitle: currentProject.attributes.title,
      });
      const formattedOffer = listingResponse.attributes.description?.replace(
        /(\r\n|\n|\r)/gm,
        '\n'
      );
      if (formattedOffer) {
        this.handleNewMention(formattedOffer, listingResponse, true);
      }
      this.setState({ sendingEmailSequenceInProgress: false });
    }
  }

  render() {
    const {
      id,
      isOpen,
      onCloseModal,
      onManageDisableScrolling,
      getListing,
      getOwnListing,
      listingId,
      fetchedExpertProfileForService,
      currentUserHasExpertListing,
      currentUser,
      currentUserExpertListing,
      intl,
      onEditButton,
      isEdit,
      onUpdateListing,
      fetchingExpertListingInProgress,
      onSaveAsDraft,
      onPublishListingDraft,
      isDraft,
      viewport,
      showListingError,
      transactionId,
      currentProject,
      connectedTransaction: project,
      onShowListing,
      isProjectSolved,
      onShowConnectedTickets,

      expertConnectedToProject,
      onClearTicket,
    } = this.props;

    const classes = classNames(css.root);

    const isNew = listingId === '00000000-0000-0000-0000-000000000000';

    const listing = isDraft ? getOwnListing(new UUID(listingId)) : getListing(new UUID(listingId));

    const currentListing = this.state.listing
      ? this.state.listing
      : isDraft
      ? ensureOwnListing(listing)
      : ensureListing(listing);
    const currentAuthor = currentListing?.author ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    const isListingStatusNew = currentListing.attributes.state ? false : true;

    const answersOnly = this.state.ticketAnswers
      ?.map((answer, id) => {
        return { ...answer, basicId: id };
      })
      .filter(element => element.solution);
    const updatesOnly = this.state.ticketAnswers?.filter(element => !element.solution);

    // WHEN LISTING NOT LOADED SHOW LOADING ICON
    if (!currentListing.id && !isNew && !showListingError)
      return (
        <Modal
          id={id}
          containerClassName={classes}
          contentClassName={css.modalContent}
          isOpen={isOpen}
          onClose={onCloseModal}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <div className={css.root}>
            <div className={css.loadingWrapper}>
              <IconSpinner />
            </div>
          </div>
        </Modal>
      );

    //WHEN USER HAS NO RIGHTS TO WATCH TICKET
    if (showListingError && !isNew)
      return (
        <Modal
          id={id}
          containerClassName={classes}
          contentClassName={css.modalContent}
          isOpen={isOpen}
          onClose={onCloseModal}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <div className={css.root}>
            <div className={css.noRightsToBrowse}>
              <FormattedMessage id="TicketModal.noRightsToBrowse" />
            </div>
          </div>
        </Modal>
      );

    const userAndListingAuthorAvailable = !!(currentUser && !!ensuredAuthor);
    const isOwnListing =
      userAndListingAuthorAvailable &&
      (currentListing.author?.id.uuid === currentUser.id.uuid || isNew);

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const handleUpdateListing = async (values, draftMaybe) => {
      this.setState({ ticketUpdateInProgress: true });
      const {
        hours,
        title,
        images,
        description,
        bookingStartDate,
        priority,
        estimationHours,
      } = values;
      const currentCompletionHours =
        this.state.completionHours || currentListing.attributes.publicData.completionHours;
      const currentDeliveryDate =
        this.state.deliveryDate || currentListing.attributes.publicData.deliveryDate;
      const deliveryDate = bookingStartDate
        ? intl.formatDate(bookingStartDate.date, {
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            year: 'numeric',
          })
        : null;
      let { completionHours } = values;
      if(completionHours===undefined){
        completionHours='0h';
      }
      // expertPrice.amount * process.env.REACT_APP_COMMISSION_FACTOR * completedHours;
      let minutes = 0;
      let hoursData = 0;
      let data = [];
      if (hours) {
        if (currentCompletionHours) {
          data = currentCompletionHours.split(' ');

          hoursData = parseInt(data[0].replace('h', ''));
          minutes = data[1] ? parseInt(data[1].replace('m', '')) : 0;
        }
        data = hours.split(' ');
        hoursData += parseInt(data[0].replace('h', ''));
        minutes += data[1] ? parseInt(data[1].replace('m', '')) : 0;

        if (minutes >= 60) {
          hoursData += (minutes - (minutes % 60)) / 60;
          minutes = minutes % 60;
        }
        completionHours = `${hoursData}h ${minutes}m`;
      }
      let estimation = estimationHours;
      if (
        estimationHours === undefined ||
        parseTime(estimationHours) < parseTime(completionHours) ||
        currentListing.attributes.publicData.estimationHours === undefined ||
        parseTime(currentListing.attributes.publicData.estimationHours) < parseTime(completionHours)
      ) {
        if (completionHours && estimationHours === undefined) {
          estimation = completionHours;
        }
      }

      if (!isOwnListing) {
        sendTicketNotification(currentProject,description,title,'taskUpdate', listingId);
        onEditNotOwnTicket(
          title,
          images,
          description,
          deliveryDate,
          estimation,
          priority,
          completionHours,
          currentDeliveryDate,
          currentCompletionHours,
        );
      } else {
        const saveAsDraft = draftMaybe === 'draft' || isDraft;
        const body = {
          images: images,
          title: title,
          description: description,
          publicData: {
            deliveryDate: !!bookingStartDate ? deliveryDate : null,
            category: 'ticket',
            priority,
            private: true,
            completionHours,
            estimationHours: estimation,
          },
        };
        if (isListingStatusNew) {
          body.publicData = {
            ...body.publicData,
            invitedTicketUsers: currentProject?.attributes.publicData?.invitedUser,
            isTask: currentUser?.id.uuid === project?.provider?.id.uuid,
            assignedExpertUserId:
              currentUser?.id.uuid !== project?.provider?.id.uuid
                ? project?.provider?.id.uuid
                : null,
            projectId: currentProject.id.uuid,
            expert: project?.listing?.id.uuid,
            state: this.props.location?.state?.defaultState
              ? this.props.location.state.defaultState
              : null,
            answers: [
              {
                date: new Date().toString(),
                type: 'ticket-created',
              },
            ],
          };
        }else{
          sendTicketNotification(currentProject,description,title,'taskUpdate', listingId);
        }
        isListingStatusNew && saveAsDraft
          ? onSaveAsDraft(body).then(response => {
              // handleRedirect(response, 'draft');

              setTimeout(
                () =>
                  onShowConnectedTickets(new UUID(currentProject.id.uuid)).then(() => {
                    this.setState({ ticketUpdateInProgress: false });

                    onClearTicket();
                    this.adjustBalanceBasedOnTicketData(completionHours, currentCompletionHours);
                  }),
                500
              );
            })
          : isListingStatusNew
          ? onSaveAsDraft(body).then(response =>
              onPublishListingDraft(response).then(response => {
                approveListing({
                  userId: currentUser?.id.uuid,
                  listingId: response.id.uuid,
                }).then(() => {
                  onShowListing(response.id.uuid, false).then(data => {
                    this.setState({ listing: getListing(new UUID(response.id.uuid)) });
                    this.newListingSequence(response, project);

                    setTimeout(
                      () =>
                        onShowConnectedTickets(new UUID(currentProject.id.uuid)).then(() => {
                          this.setState({ ticketUpdateInProgress: false });

                          onClearTicket();
                          this.adjustBalanceBasedOnTicketData(
                            completionHours,
                            currentCompletionHours
                          );
                        }),
                      500
                    );
                  });
                });
              })
            )
          : onUpdateListing({ ...body, id: listingId }).then(async () => {
              if (completionHours !== currentCompletionHours) {
                this.handleAddActivity('completion-hours-and-update', completionHours);
                await this.adjustBalanceBasedOnTicketData(completionHours, currentCompletionHours);
              } else if (estimation !== currentListing.attributes.publicData.estimationHours) {
                this.handleAddActivity('estimation-hours', estimation);
              } else this.handleAddActivity('ticket-updated');
              this.setState({ listing: getListing(new UUID(listingId)) });
              // handleRedirect(currentListing.id.uuid);
              this.setState({ initialValues: null });

              setTimeout(
                () =>
                  onShowConnectedTickets(new UUID(currentProject.id.uuid)).then(() => {
                    this.setState({ ticketUpdateInProgress: false });

                    onClearTicket();
                  }),
                500
              );
            });
      }
    };

    const imageUploadHandler = image => {
      const { onImageUpload } = this.props;
      if (image) {
        this.setState({ imageUploadRequested: true });
        onImageUpload(image)
          .then(response => {
            image.imageId = response.uuid;
            const images = this.state.imageOrder;
            images.push(image);
            this.setState({ imageOrder: images, imageUploadRequested: false });
          })
          .catch(() => {
            this.setState({ imageUploadRequested: false });
          });
      }
    };

    const onRemoveImage = image => {
      const images = this.state.removedImageIds;
      images.push(image);
      this.setState({ removedImageIds: images });
    };

    const onChangeState = (newState, stateKey) => {
      if (currentListing && currentUser)
        changeTicketData({
          listingId: currentListing.id.uuid,
          userId: currentUser.id.uuid,
          type: 'change-state',
          body: { state: stateKey },
        }).then(response => {
          sendTicketNotification(currentProject,currentListing.attributes.description,currentListing.attributes.title,'taskUpdate', currentListing.id.uuid);
          addMarketplaceEntities(response);
          this.handleAddActivity('status-changed', newState);
        });
      this.setState({ listingState: stateKey });
    };

    const onEditNotOwnTicket = async (
      title,
      images,
      description,
      deliveryDate,
      estimation,
      priority,
      completionHours,
      currentDeliveryDate,
      currentCompletionHours,
    ) => {
      this.setState({ ticketUpdateInProgress: true });
      let estimationHours = estimation;

      // if (
      //   estimation === undefined ||
      //   parseTime(estimation) < parseTime(completionHours) ||
      //   currentListing.attributes.publicData.estimationHours === undefined ||
      //   parseTime(currentListing.attributes.publicData.estimationHours) < parseTime(completionHours)
      // ) {
      //   if (completionHours) {
      //     estimationHours = completionHours;
      //   }
      // }
      if (!isExpertOnProject){
        changeTicketData({
          userId: currentUser.id.uuid,
          listingId: listingId,
          type: 'edit-not-own',
          body: {
            deliveryDate,
            estimationHours:estimation,
            completionHours,
          },
        },isExpertOnProject).then(response => {
          if (currentDeliveryDate !== deliveryDate) this.handleAddActivity('ticket-updated');
          if (completionHours !== currentCompletionHours) {
            this.handleAddActivity('completion-hours', completionHours);
            this.adjustBalanceBasedOnTicketData(completionHours, currentCompletionHours);
          } else if (estimationHours !== currentListing.attributes.publicData.estimationHours) {
            this.handleAddActivity('estimation-hours', estimationHours);
          }

        addMarketplaceEntities(response);

        onShowConnectedTickets(new UUID(currentProject.id.uuid)).then(() => {
          this.setState({ ticketUpdateInProgress: false });
        });
        this.setState({ initialValues: null });
          deliveryDate && this.setState({ deliveryDate: deliveryDate });
          estimationHours && this.setState({ estimationHours: estimationHours });
          completionHours && this.setState({ completionHours: completionHours });
          onClearTicket();
        // handleRedirect(listingId);
      })
      }
      else if(isExpertOnProject){
          changeTicketData({
            userId: currentUser.id.uuid,
            listingId: listingId,
            type: 'edit-not-own-expert',
            body: {
              title:title,
              images:images,
              description:description,
              priority:priority,
              deliveryDate,
              estimationHours:estimation,
              completionHours,
            },
        }, isExpertOnProject).then(response => {
          if (currentDeliveryDate !== deliveryDate) this.handleAddActivity('ticket-updated');
          if (completionHours !== currentCompletionHours) {
            this.handleAddActivity('completion-hours', completionHours);
            this.adjustBalanceBasedOnTicketData(completionHours, currentCompletionHours);
          } else if (estimationHours !== currentListing.attributes.publicData.estimationHours) {
            this.handleAddActivity('estimation-hours', estimationHours);
          }
          addMarketplaceEntities(response);

          onShowConnectedTickets(new UUID(currentProject.id.uuid)).then(() => {
            this.setState({ ticketUpdateInProgress: false });
          });
          this.setState({ initialValues: null });
          deliveryDate && this.setState({ deliveryDate: deliveryDate });
          estimationHours && this.setState({ estimationHours: estimationHours });
          completionHours && this.setState({ completionHours: completionHours });
          onClearTicket();
        }
      )}
      };
    
    const serviceAuthorExpertProfileId = fetchedExpertProfileForService?.id?.uuid;
    const serviceAuthorExpertProfileTitle = fetchedExpertProfileForService?.attributes?.title;
    const serviceAuthorName = fetchedExpertProfileForService?.attributes?.publicData?.authorName;
    const serviceAuthorExpertise =
      fetchedExpertProfileForService?.attributes?.publicData?.expertise;

    const { publicData, title, description } = currentListing.attributes;
    const { priority, isTask, state, deliveryDate, estimationHours, completionHours } = publicData;
    const currentState = this.state.listingState ? this.state.listingState : state;
    const isSolved = currentState === 'closed';
    const isAwaiting = publicData?.state === 'awaiting';
    const hasListingState = !!currentListing.attributes.state;

    const isClosed = hasListingState && currentListing.attributes.state === LISTING_STATE_CLOSED;

    const isNotOwnPrivateListing =
      currentListing.attributes.publicData?.expert &&
      !!currentListing.attributes.publicData?.expert.find(
        expertId => expertId === currentUserExpertListing?.id.uuid
      );

    const isExpertOnProject =
      project?.provider?.id.uuid === currentUser?.id.uuid || expertConnectedToProject;

    const userConnectedToTicket =
      !!(
        currentListing.attributes.publicData.invitedTicketUsers?.find(
          mail => mail === currentUser?.attributes.email
        ) || currentListing.attributes.publicData.projectCustomerId === currentUser?.id.uuid
      ) ||
      currentProject.attributes.publicData.invitedUsers?.find(
        mail => mail === currentUser?.attributes.email
      );

    const currentListingImages =
      currentListing && currentListing.images ? currentListing.images : [];

    // Images not yet connected to the listing
    const imageOrder = this.state.imageOrder || [];

    const priorityOptions = config.custom.priority.options;

    const allImages = currentListingImages.concat(imageOrder);
    const removedImageIds = this.state.removedImageIds || [];
    const images = allImages.filter(img => {
      return !removedImageIds.includes(img.id);
    });

    const stateOptions = [
      { key: 'open', label: 'To do' },
      { key: 'inProgress', label: 'In Progress' },
      { key: 'closed', label: 'Completed' },
    ];

    const containerClasses = classNames(css.ticketLayout, this.state.ticketUpdateInProgress ? css.ticketCentered : '');
    const modalContent = (
      <div className={containerClasses}>
        {this.state.ticketUpdateInProgress ?
          <div>
            <FormattedMessage
              id="TicketModal.ticketUpdateInProgress"
            />
            <IconSpinner />
          </div>
        : isEdit ? (
          <EditTicketForm
            className={css.form}
            name="TicketForm"
            initialValues={this.state.initialValues}
            onSubmit={values =>
              handleUpdateListing({
                ...values,
                images: images?.map(image => image.imageId || image.id),
              })
            }
            onSaveAsDraft={values =>
              handleUpdateListing(
                { ...values, images: images?.map(image => image.imageId || image.id) },
                'draft'
              )
            }
            onEditButton={onEditButton}
            isListingStatusNew={isListingStatusNew}
            intl={intl}
            listing={currentListing}
            images={images}
            onImageUpload={image => imageUploadHandler(image)}
            onRemoveImage={image => onRemoveImage(image)}
            isOwnListing={isOwnListing}
            fetchingExpertListingInProgress={fetchingExpertListingInProgress}
            listingId={listingId}
            currentUser={currentUser}
            isDraft={isDraft}
            priorityOptions={priorityOptions}
            currentUserExpertListing={currentUserExpertListing}
            mobileMargins={viewport.width <= MODAL_BREAKPOINT}
            userConnectedToTicket={userConnectedToTicket}
            isNotOwnPrivateListing={isNotOwnPrivateListing}
            showListingError={showListingError}
            estimationHoursPresent={!!(this.state.estimationHours || estimationHours)}
            transactionId={transactionId}
            isExpertOnProject={isExpertOnProject}
            updateInProgress={this.state.ticketUpdateInProgress}
          />
        ) : (
          <>
            <div className={css.leftColumn}>
              <div className={css.ticketEdit}>
                {!this.state.listingIsClosed ? (
                  <Edit onClick={onEditButton} className={css.editIconFeather} size={18} />
                ) : null}
              </div>
              <p className={css.ticketTitle}>
                {richText(title, {
                  longWordClass: css.longWord,
                })}
              </p>
              <div className={css.ticketContent}>
                {!isListingStatusNew ? (
                  <div className={css.ticketDetails}>
                    <SectionDescriptionMaybe description={description} withoutTitle />

                    <div className={css.minorTicketDetails}>
                      <SectionPriority priority={priority} />
                      <SectionDeliveryDateMaybe
                        deliveryDate={this.state.deliveryDate || deliveryDate}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              <div className={css.messages} id="messages">
                <MessagesPanel
                  answers={answersOnly || null}
                  currentListing={currentListing}
                  isOwnListing={isOwnListing}
                  currentUserExpertListing={
                    currentUserHasExpertListing ? currentUserExpertListing : null
                  }
                  currentUser={currentUser}
                  currentUserHasExpertListing={currentUserHasExpertListing}
                  isSolved={isSolved}
                  onChangeTicketAnswer={(index, type, body) =>
                    this.handleChangeTicketAnswer(index, type, body, currentListing, currentUser)
                  }
                  type="answers"
                />

                {/* && && !isClosed  && !isAwaiting && !isProjectSolved  !isSolved - Ticket jest w complitach  */}
                {currentUser ? (
                  
                <SolutionForm
                    className={css.applicationForm}
                    formId="BookingPanel"
                    submitButtonWrapperClassName={css.submitButtonWrapper}
                    onSubmit={(values, form) =>
                      this.handleTicketSolution(
                        values,
                        form,
                        isOwnListing,
                        userConnectedToTicket,
                        isNotOwnPrivateListing
                      )
                    }
                    isOwnListing={isOwnListing}
                    hasExpertProfile={currentUserHasExpertListing}
                    expertListingIsPendingApproval={
                      currentUserExpertListing?.attributes.state === LISTING_STATE_PENDING_APPROVAL
                    }
                    userConnectedToTicket={userConnectedToTicket}
                  />
                ) : null}
              </div>
            </div>
            <div className={css.rightColumn}>
              {!this.state.listingIsClosed ? (
                <select
                  id={'state'}
                  value={stateOptions.find(option => option.key === currentState)?.label}
                  className={css.stateDropdown}
                  onChange={e =>
                    onChangeState(e.target.value, stateOptions[e.target.options.selectedIndex].key)
                  }
                >
                  {stateOptions.map(state => (
                    <option key={state.key} value={state.label}>
                      {state.label}
                    </option>
                  ))}
                </select>
              ) : (
                <p>{currentState}</p>
              )}
              {isOwnListing ||
              isNotOwnPrivateListing ||
              userConnectedToTicket ||
              isExpertOnProject ? (
                <div>
                  <SectionProgressMaybe
                    estimationHours={this.state.estimationHours || estimationHours}
                    completionHours={this.state.completionHours || completionHours}
                    intl={intl}
                  />
                  {!this.state.listingIsClosed ? (
                    <EditReportTime
                      className={css.form}
                      name="TicketForm"
                      initialValues={this.state.initialValues}
                      onSubmit={(values, form) =>
                        handleUpdateListing({
                          ...values,
                          images: images?.map(image => image.imageId || image.id),
                        })
                      }
                      onSaveAsDraft={values =>
                        handleUpdateListing(
                          {
                            ...values,
                            images: images?.map(image => image.imageId || image.id),
                          },
                          'draft'
                        )
                      }
                      onEditButton={onEditButton}
                      isListingStatusNew={isListingStatusNew}
                      intl={intl}
                      listing={currentListing}
                      images={images}
                      ticketUpdateInProgress={this.state.ticketUpdateInProgress}
                      onImageUpload={image => imageUploadHandler(image)}
                      onRemoveImage={image => onRemoveImage(image)}
                      isOwnListing={isOwnListing}
                      fetchingExpertListingInProgress={fetchingExpertListingInProgress}
                      listingId={listingId}
                      currentUser={currentUser}
                      isDraft={isDraft}
                      priorityOptions={priorityOptions}
                      currentUserExpertListing={currentUserExpertListing}
                      mobileMargins={viewport.width <= MODAL_BREAKPOINT}
                      userConnectedToTicket={userConnectedToTicket}
                      isNotOwnPrivateListing={isNotOwnPrivateListing}
                      showListingError={showListingError}
                      estimationHoursPresent={!!(this.state.estimationHours || estimationHours)}
                      transactionId={transactionId}
                      isExpertOnProject={isExpertOnProject}
                    />
                  ) : null}
                </div>
              ) : null}
              {isTask ? (
                <SectionTaskAuthor
                  profileTitle={serviceAuthorExpertProfileTitle}
                  profileId={serviceAuthorExpertProfileId}
                  author={ensuredAuthor}
                  authorName={serviceAuthorName}
                  authorExpertise={serviceAuthorExpertise}
                />
              ) : null}
              <div className={css.attachmentsSection}>
                <SectionImages
                  title={title}
                  ticket={currentListing}
                  imageCarouselOpen={this.state.imageCarouselOpen}
                  onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                  handleViewPhotosClick={handleViewPhotosClick}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
              </div>
              <div className={css.activitySection}>
                <MessagesPanel
                  answers={updatesOnly || null}
                  isUpdates
                  currentListing={currentListing}
                  isOwnListing={isOwnListing}
                  currentUserExpertListing={
                    currentUserHasExpertListing ? currentUserExpertListing : null
                  }
                  currentUser={currentUser}
                  currentUserHasExpertListing={currentUserHasExpertListing}
                  isSolved={isSolved}
                  onChangeTicketAnswer={(index, type, body) =>
                    this.handleChangeTicketAnswer(index, type, body, currentListing, currentUser)
                  }
                  type="updates"
                />
              </div>
            </div>
          </>
        )}
      </div>
    );

    return (
      <div>
        <Modal
          id={id}
          containerClassName={classes}
          contentClassName={css.modalContent}
          isOpen={isOpen}
          onClose={onCloseModal}
          onManageDisableScrolling={onManageDisableScrolling}
          isTicketInMobile={viewport.width <= MODAL_BREAKPOINT}
        >
          {modalContent}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    enquiryModalOpenForListingId,
    openBookingPanelModal,
    fetchedTicketAnswers,
    fetchedExpertProfileForService,
    fetchedExpertServices,
    // createListingDraftInProgress,
    // createListingDraftError,
    // submittedListingId,
    // listingDraft
  } = state.ListingPage;
  const {
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasExpertListing,
    currentUserExpertListing,
    currentUserHasProjects,
    fetchingExpertListingInProgress,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);

    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    openBookingPanelModal,
    currentUserHasExpertListing,
    currentUserExpertListing,
    fetchedTicketAnswers,
    fetchedExpertProfileForService,
    fetchedExpertServices,
    currentUserHasProjects,
    fetchingExpertListingInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onShowListing: (listingId, isOwn) => dispatch(showListing(listingId, isOwn)),
  onShowTicketAuthor: listingId => dispatch(showTicketMessageOwner(listingId)),
  onShowUser: userId => dispatch(showUser(userId)),
  onImageUpload: data => dispatch(requestImageUpload(data)),
  onUpdateListing: values => dispatch(requestUpdateListing(values)),
  onSaveAsDraft: values => dispatch(requestCreateListingDraft(values)),
  onPublishListingDraft: id => dispatch(requestPublishListingDraft(id)),
});

const TicketModal = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TicketModalComponent);

export default TicketModal;
