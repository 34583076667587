import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { FieldTextInput } from '../../components';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import { Field } from 'react-final-form';
import { required } from '../../util/validators';
import { Tooltip } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './FieldExperience.css';

const mapInitialValues = initialValues => {
  return initialValues.map((experience, index) => {
    return {
      id: index,
      experienceTitle: experience.title,
      experienceDescription: experience.description,
      experienceUrl: experience.url,
      experienceCompany: experience.company,
      experienceSoftware: experience.software,
      experienceYears: experience.years
    }
  });
}

const FieldExperience = props => {
  const { intl, initialValues, values, setExperienceDeleted, experienceItems, setExperienceItems, productSoftware } = props;

  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);
  const initialValuesLoaded = useRef(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      setShouldRenderReactSelect(true);
    }
  }, []);

  useLayoutEffect(() => {
    if (!initialValuesLoaded.current && initialValues?.length > 0) {
      setExperienceItems(mapInitialValues(initialValues));
      initialValuesLoaded.current = true;
    }
  }, [initialValues, setExperienceItems]);

  const allSofts = productSoftware.map(soft => {
    return {
      value: soft.key,
      label: soft.label
    }
  });

  const experienceTitleLabel = intl.formatMessage({ id: "FieldExperience.titleLabel" });
  const experienceTitlePlaceholder = intl.formatMessage({ id: "FieldExperience.titlePlaceholder" });
  const experienceTitleRequired = required(intl.formatMessage({ id: "FieldExperience.titleRequired" }));

  const companyNameLabel = intl.formatMessage({ id: "FieldExperience.companyNameLabel" });
  const companyNamePlaceholder = intl.formatMessage({ id: "FieldExperience.companyNamePlaceholder" });

  const descriptionLabel = intl.formatMessage({ id: "FieldExperience.descriptionLabel" });
  const descriptionPlaceholder = intl.formatMessage({ id: "FieldExperience.descriptionPlaceholder" });

  const experienceYearsLabel = intl.formatMessage({ id: "FieldExperience.yearsLabel" });
  const experienceYearsPlaceholder = intl.formatMessage({ id: "FieldExperience.yearsPlaceholder" });

  const urlLabel = intl.formatMessage({ id: "FieldExperience.urlLabel" });
  const urlPlaceholder = intl.formatMessage({ id: "FieldExperience.urlPlaceholder" });

  const softwarePlaceholder = intl.formatMessage({ id: "FieldExperience.softwarePlaceholder" });

  // const notValidURLMessage = intl.formatMessage({ id: "EditExpertForm.notValidURLMessage" });

  if (shouldRenderReactSelect) {
    var isMobile = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      isMobile = true;
    }
    const { default: CreatableSelect } = require('react-select/creatable');
    const ReactCreatableSelectAdapter = ({ input, ...rest }) => (
      <CreatableSelect {...input} {...rest} searchable />
    );
    var softwareField = (id) => {
      return <Field
        name={"experienceSoftware" + id}
        isMulti
        component={ReactCreatableSelectAdapter}
        closeMenuOnSelect={false}
        options={allSofts}
        blurInputOnSelect={false}
        isSearchable={!isMobile}
        placeholder={softwarePlaceholder}
      />
    }
  }

  const generateExperienceInput = ({ id }, experienceHandler, removeExperienceHandler, shouldDisplayPlus, shouldDisplayMinus) => {
    const titleRequired = !!(values['experienceTitle'+id] || values['experienceCompany'+id] ||  values['experienceYears'+id] || values['experienceDescription'+id] || values['experienceUrl'+id]);
    return (
      <div key={"experienceTitle" + id} className={css.projectWrapper}>
        <div className={css.projectsButtonWrapper}>
          {shouldDisplayMinus ?
            <Tooltip title={intl.formatMessage({ id: "FieldExperience.remove" })}>
              <div className={css.removeExperienceButton} onClick={() => removeExperienceHandler(id)}>
                <span >
                  <BiMinusCircle size={"1.5em"} />
                </span>
              </div>
            </Tooltip>
            : null}
          {shouldDisplayPlus ?
            <Tooltip title={intl.formatMessage({ id: "FieldExperience.add" })}>
              <div className={css.addExperienceButton} onClick={experienceHandler}>
                <span>
                  <BiPlusCircle size={"1.5em"} />
                </span>
              </div>
            </Tooltip>
            : null}
        </div>
        <div className={css.fieldsWrapper}>
          <div className={css.projectFirstRowWrapper}>
            <FieldTextInput
              id={"experienceTitle" + id}
              name={"experienceTitle" + id}
              className={css.experienceTitle}
              label={experienceTitleLabel}
              type="textarea"
              placeholder={experienceTitlePlaceholder}
              validate={titleRequired ? experienceTitleRequired : null}
            />

            <FieldTextInput
              id={"experienceCompany" + id}
              name={"experienceCompany" + id}
              className={css.experienceCompany}
              type="textarea"
              label={companyNameLabel}
              placeholder={companyNamePlaceholder}
            />
            <FieldTextInput
              id={"experienceYears" + id}
              name={"experienceYears" + id}
              className={css.yearsTitle}
              label={experienceYearsLabel}
              type="number"
              step="0.5"
              min={0}
              placeholder={experienceYearsPlaceholder}
            /> 
           
          </div>

            <FieldTextInput
              id={"experienceDescription" + id}
              name={"experienceDescription" + id}
              className={css.projectDescription}
              type="textarea"
              label={descriptionLabel}
              placeholder={descriptionPlaceholder}
              isDescription
            />

            <FieldTextInput
              id={"experienceUrl" + id}
              name={"experienceUrl" + id}
              className={css.projectUrl}
              type="text"
              label={urlLabel}
              placeholder={urlPlaceholder}
              // validate={validBusinessURL(notValidURLMessage)}
              />

            {typeof softwareField === 'function' ? softwareField(id) : null}

        </div>
      </div>
    );
  }

  const renderExperience = (experienceArray, experienceHandler, removeExperienceHandler) => {
    return experienceArray.map((experience, index) => {
      const shouldDisplayPlus = experienceArray.length >= 1 && (index === experienceArray.length - 1);
      const shouldDisplayMinus = experienceArray.length > 1 && (index < experienceArray.length - 1);
      return (
        generateExperienceInput(experience, experienceHandler, removeExperienceHandler, shouldDisplayPlus, shouldDisplayMinus)
      );
    })
  }

  const addExperienceHandler = () => {
    let lastExperienceId = experienceItems[experienceItems.length - 1].id;
    setExperienceItems([
      ...experienceItems,
      {
        id: lastExperienceId + 1,
      }
    ]);
  };

  const removeExperienceHandler = (id) => {
    values[`experienceTitle${id}`] = null;
    values[`experienceCompany${id}`] = null;
    values[`experienceDescription${id}`] = null;
    values[`experienceUrl${id}`] = null;
    values[`experienceSoftware${id}`] = null;
    const experienceAfterRemoval = experienceItems.filter((experience, index) => index > id || index < id);
    setExperienceDeleted(true);
    setExperienceItems([...experienceAfterRemoval]);
  }

  return (
    <div>
      {renderExperience(experienceItems, addExperienceHandler, removeExperienceHandler)}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    productSoftware
  } = state.marketplaceData;
  return {
    productSoftware
  }
}

export default compose(
  connect(mapStateToProps)
)(FieldExperience);
