import React, { useState } from 'react';
import { Edit } from 'react-feather';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { RiLock2Line, RiUserAddLine } from 'react-icons/ri';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { bool, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
} from '../../util/types';
import { LISTING_PAGE_PENDING_APPROVAL_VARIANT } from '../../util/urlHelpers';
import { NamedLink, PrimaryButton, SecondaryButton, DepositModal } from '../../components';

import SectionLinksJobMaybe from './SectionLinksJobMaybe';
import css from './ListingPage.css';

export const SideActionBarMaybe = props => {
  const {
    isOwnListing,
    listing,
    editParams,
    publicData,
    currentUser,
    isSolved,
    onChangeState,
    onManageDisableScrolling,
    onOpenClientPathModal,
    softwareExperts,
    allExperts,
  } = props;

  const [depositModalOpen, setDepositModalOpen] = useState(false);

  const listingId = listing.id.uuid;
  const state = listing.attributes.state;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const category = publicData.category;
  const messageFormatting = '';
  const message = isDraft ? 'ListingPage.finishListing' : 'ListingPage.editListing';
  const primarySoftware = listing?.attributes.publicData.primarySoftware;

  const expertsLink = allExperts + ' experts';
  const softwareLink =
    // <NamedLink name="SearchPage_experts_software" params={{ software: primarySoftware }}>
    softwareExperts + (softwareExperts > 1 ? ' experts' : ' expert');
  // </NamedLink>;

  //converting project expiration date to Dat
  const formattedExpirationDate =
    publicData.expires &&
    new Date(Date.parse(`${publicData.expires.slice(0, 6)}, ${publicData.expires.slice(-4)}`));
  const isAfterExpirationDate = formattedExpirationDate < new Date();

  const termsLink = (
    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
      <FormattedMessage id="JobApplicationForm.termsOfUse" />
    </NamedLink>
  );

  const unregisteredUserTooltip = (
    <>
      <FormattedMessage id="BookingTimeForm.signupExpert" />
      <Tooltip
        interactive
        title={
          <FormattedMessage
            id={`BookingPanel.benefitsListing${messageFormatting}`}
            values={{ br: <br />, terms: termsLink }}
          />
        }
      >
        <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
      </Tooltip>
    </>
  );

  return (
    <>
      {currentUser && isOwnListing ? (
        <div className={css.ProjectTitleHeader}>
          <FormattedMessage id="ListingPage.projectStatus" />
        </div>
      ) : null}

      <div className={css.sectionSideActionBar}>
        {isPendingApproval && currentUser ? (
          <div className={classNames(css.actionBar, css.closedListingText)}>
            <FormattedMessage id="ListingPage.pendingApprovalListing" />
          </div>
        ) : null}
        {isClosed && currentUser ? (
          <div className={classNames(css.actionBar, css.closedListingText)}>
            <FormattedMessage id={`ListingPage.closedListing${messageFormatting}`} />
          </div>
        ) : null}
        {!isClosed && isSolved && currentUser ? (
          <div className={classNames(css.actionBar, css.closedListingText)}>
            <FormattedMessage id="SideActionBarMaybe.jobClosed" />
          </div>
        ) : null}
        {/* {!isClosed && isAwaiting && currentUser ? (
          <div className={css.actionBar}>
            <p className={css.closedListingText}>
              <FormattedMessage id={'SideActionBarMaybe.jobAwaiting'} />
            </p>
          </div>
        ) : null} */}
        {isPendingApproval && currentUser && allExperts && (
          <div className={css.availableExperts}>
            <FormattedMessage
              id="BookingPanel.currentlyAvailableExperts"
              values={{
                softwareExpertsLink: primarySoftware === 'other' ? expertsLink : softwareLink,
                quantity: primarySoftware === 'other' ? allExperts : softwareExperts,
              }}
            />
          </div>
        )}
        {isPendingApproval ? (
          <>
            <div className={css.sectionCTA}>
              <PrimaryButton className={css.editButton} onClick={onOpenClientPathModal}>
                <RiUserAddLine className={css.closeIcon} size="24" />
                <FormattedMessage id="ListingPage.getOffers" />
              </PrimaryButton>
            </div>
            <div className={css.sectionCTA}>
              <NamedLink
                name="ProjectBoardPageVariant"
                params={{ id: listingId, variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT }}
                className={css.jobLink}
              >
                <SecondaryButton className={css.editButton}>
                  <AiOutlineFundProjectionScreen className={css.closeIcon} size="24" />
                  <FormattedMessage id="ListingPage.goToProjectBoard" />
                </SecondaryButton>
              </NamedLink>
            </div>
          </>
        ) : null}
        {!isPendingApproval && isOwnListing ? (
          <div className={css.sectionCTA}>
            <NamedLink name="ProjectBoardPage" params={{ id: listingId }} className={css.jobLink}>
              <SecondaryButton className={css.editButton}>
                <AiOutlineFundProjectionScreen className={css.closeIcon} size="24" />
                <FormattedMessage id="ListingPage.goToProjectBoard" />
              </SecondaryButton>
            </NamedLink>
          </div>
        ) : null}
        {isOwnListing ? (
          <div className={css.sectionCTA}>
            <NamedLink
              className={css.editListingLink}
              name="EditListingPage"
              params={editParams}
              category={category}
            >
              <SecondaryButton className={css.editButton}>
                <Edit className={css.editIconFeather} />
                <FormattedMessage id={message} />
              </SecondaryButton>
            </NamedLink>
          </div>
        ) : null}
        {isOwnListing &&
        !isClosed &&
        currentUser &&
        !isAfterExpirationDate &&
        !publicData.transactionId ? (
          <div className={css.sectionCTA}>
            <SecondaryButton
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                onChangeState();
              }}
            >
              <RiLock2Line className={css.closeIcon} size="24" />
              <FormattedMessage
                id={isSolved ? 'SideActionBarMaybe.reopenJob' : 'SideActionBarMaybe.closeJob'}
              />
            </SecondaryButton>
          </div>
        ) : null}
        {currentUser && !isClosed ? (
          <div className={css.sectionCTA}>
            <SectionLinksJobMaybe jobDetailsLink={publicData ? publicData.linkJobDetails : null} />
          </div>
        ) : null}
        {!currentUser ? (
          <div className={css.sectionCTA}>
            <NamedLink name={'NewListingExpertPage'} className={css.newListingLink}>
              <PrimaryButton className={css.postJobButton}>{unregisteredUserTooltip}</PrimaryButton>
            </NamedLink>
            <div className={css.smallPrint}>
              <FormattedMessage id={`BookingTimeForm.createProfile${messageFormatting}`} />
            </div>
          </div>
        ) : null}
      </div>
      <DepositModal
        id="ListingPage.clientPath"
        contentClassName={css.enquiryModalContent}
        isOpen={depositModalOpen}
        onCloseModal={() => setDepositModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        alreadyBought={listing.attributes.privateData?.depositInvoice}
        usePortal
        listingId={listingId}
      />
    </>
  );
};

SideActionBarMaybe.propTypes = {
  isOwnListing: bool.isRequired,
  // listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  editParams: object.isRequired,
};

SideActionBarMaybe.displayName = 'SideActionBarMaybe';

export default SideActionBarMaybe;
