import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingCard, InlineTextButton } from '../../components';

import css from '../../components/ExpertOverview/ExpertPage.css';

const SectionExpertServicesMaybe = props => {
  const { services, serviceAuthor, currentUser } = props;

  const [showMoreServices, setShowMoreServices] = useState(false);

  if(!services) {
    return null;
  }

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth}vw`,
    `${panelLargeWidth}vw`,
  ].join(', ');

  const renderServices = services => {
    const servicesLength = services?.length;
    if (services?.length > 1 && !showMoreServices) {
      services = services.slice(0, 2);
    }
    return services.map((item, index) => {
      return (
        <div
          key={item.attributes.title + index}
          className={
            index === 1 && !showMoreServices && servicesLength > 2
              ? css.invisibleServiceWrapper
              : null
          }
        >
          <ListingCard
            isListingReducedToService={true}
            serviceAuthor={serviceAuthor}
            className={css.serviceListingCard}
            renderSizes={cardRenderSizes}
            shouldDisplayBookmark={false}
            isCardIFrameLink={false}
            currentUserExpertListing={null}
            searchedSoftware={null}
            urlQueryParams={null}
            setActiveListing={() => null}
            currentUser={currentUser}
            listing={item}
            isExpertPage
          />
          {index === 1 && !showMoreServices && servicesLength > 2 ? (
            <div className={css.invisibleBlock} />
          ) : null}
        </div>
      );
    })
  }

  return <>
    {services?.length > 0 ?
    <>
      <h2 className={css.servicesTitle}>
        <FormattedMessage id={`ExpertPage.expertServicesTitle`} />
      </h2>
      <div className={css.sectionExpertServices}>
        <div className={css.services}>
            <div>
              {renderServices(services)}
              {services?.length > 2 && !showMoreServices
                ?
                <div className={css.showMoreWrapper}>
                  <InlineTextButton className={css.showMoreButton} onClick={() => setShowMoreServices(true)}>
                    <span className={css.showMoreText}>
                      <FormattedMessage id="ExpertPage.showMore" />
                    </span>
                  </InlineTextButton>
                </div>
                : null
              }
            </div>
        </div>
      </div>
    </>
    : null}
  </>
};

export default SectionExpertServicesMaybe;
