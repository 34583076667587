import React, { useState, useEffect, useRef } from 'react';
import { func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { required, composeValidators, nonNegativeNumber } from '../../util/validators';
import { Button, Form, FieldTextInput, FieldCurrencyInput, MultilevelScopeInput } from '../../components';
import {BsCloudDownload} from 'react-icons/bs';
import SideValidatedFieldTextInput from './SideValidatedFieldTextInput';
import css from './DiscussServiceForm.css';

export const DiscussServiceFormComponent = props => {

  const [isServiceScopeLoaded, setIsServiceScopeLoaded] = useState(false);
  const [serviceScopeLoadingError, setServiceScopeLoadingError] = useState('');
  const [initialValues, setInitialValues] = useState({});

  const { Money } = sdkTypes;
  const { disabled, formMinimumPriceNotReached, serviceScope } = props;

  const isItFirstRender = useRef(true);

  useEffect(() => {
    if(isItFirstRender.current && serviceScope?.length > 0) {
      loadServiceScope();
      isItFirstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItFirstRender.current, serviceScope]);

  const sendEnquiryButton = (
    <FormattedMessage id={`DiscussServiceForm.sendEnquiry`} />
  );

  const loadServiceScope = (values) => {
    if(!serviceScope || serviceScope.length === 0) {
      setServiceScopeLoadingError('Service scope is empty. Please try to define scope on your own.');
      return;
    }
    let initialValues = {};
    for(let i = 0; i < serviceScope.length; i++) {
      if(!!serviceScope[i].name) {
        initialValues[`scopeTaskNameInput${i}`] = serviceScope[i].name;
      }
      if(!!serviceScope[i].time) {
        initialValues[`scopeTaskTimeInput${i}`] = serviceScope[i].time.toString();
      }
      if(!!serviceScope[i].price) {
        initialValues[`scopeTaskPriceInput${i}`] = new Money(serviceScope[i].price*100, config.currency);
      }
      if(serviceScope[i]?.subtasks?.length > 0) {
        for(let j = 0; j < serviceScope[i].subtasks.length; j++) {
          if(!!serviceScope[i].subtasks[j].name) {
            initialValues[`scopeTask${i}SubtaskNameInput${j}`] = serviceScope[i].subtasks[j].name;
          }
          if(!!serviceScope[i].subtasks[j].time) {
            initialValues[`scopeTask${i}SubtaskTimeInput${j}`] = serviceScope[i].subtasks[j].time.toString();
          }
          if(!!serviceScope[i].subtasks[j].price) {
            initialValues[`scopeTask${i}SubtaskPriceInput${j}`] = new Money(serviceScope[i].subtasks[j].price*100, config.currency);
          }
        }
      }
    }
    initialValues[`offer`] = values?.offer ?? '';
    setInitialValues(initialValues);
    setIsServiceScopeLoaded(true);
  }

  return (
    <FinalForm
      {...props}
      initialValues={initialValues}
      render={formRenderProps => {
        const {
          className,
          handleSubmit,
          intl,
          values,
          invalid,
          submitFailed,
          submitSucceeded,
        } = formRenderProps;

        const classes = classNames(css.root, className);

        const calculateSumTimeValue = (values) => {
          const timeEntries = Object.entries(values).filter((entryArray) => {
            return entryArray[0].includes('scopeTaskTimeInput');
          });
          const sumTime = timeEntries && timeEntries.length > 0 ? timeEntries.reduce((acc, item) => {
            return item[1] ? acc+(parseInt(item[1])) : acc;
          }, 0) : null;
          return sumTime;
        }

        const calculateSumPriceValue = (values) => {
          const priceEntries = Object.entries(values).filter((entryArray) => {
            return entryArray[0].includes('scopeTaskPriceInput');
          });
          const sumPrice = priceEntries && priceEntries.length > 0 ? priceEntries.reduce((acc, item) => {
            return item[1]?.amount ? acc+(parseInt(item[1]?.amount)) : acc;
          }, 0) : null;
          return sumPrice;
        }

        // const calculateFinalPrice = (price) => {
        //   let priceWithCommission = price?.amount / (1 - commission);
        //   let totalPrice = !isNaN(price?.amount) ? Math.trunc(priceWithCommission/100)*100 : 0;
        //   return totalPrice;
        // }

        let serviceMessage = intl.formatMessage({ id: `DiscussServiceForm.serviceMessage` });
        let serviceMessagePlaceholder = intl.formatMessage({
          id: `DiscussServiceForm.serviceMessagePlaceholder`,
        });

        const valueRequired = required(
          intl.formatMessage({
            id: 'CustomOfferScopeInput.valueRequired',
          })
        );

        const valueNotNegative = nonNegativeNumber(
          intl.formatMessage({
            id: 'CustomOfferScopeInput.valueNotNegative',
          })
        );

        const sumMinTimeValue = calculateSumTimeValue(values);
        const suggestedEstimatedTime = sumMinTimeValue > 0 ? sumMinTimeValue?.toString() : null; 

        const sumMinPriceValue = calculateSumPriceValue(values);
        const suggestedPriceValue = sumMinPriceValue > 0 ? sumMinPriceValue : undefined;

        // DISCUSS FORM FOR CLIENTS
        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {disabled ? (
              <p className={css.error}>
                <FormattedMessage id="NewOfferForm.jobIsClosed" />
              </p>
            ) : null}
            <FieldTextInput
              id="offer"
              name="offer"
              className={css.title}
              type="textarea"
              label={serviceMessage}
              placeholder={serviceMessagePlaceholder}
              disabled={disabled}
              //tooltip={offerTooltip}
              validate={valueRequired}
            />
            <>
              <h2 className={css.scopeTitle}>
                Suggest scope modifications • optional
              </h2>
              <h5 className={css.scopeSubtitle}>
                Loaded values represents original service scope.
              </h5>
              <div className={css.loadServiceScopeLink} onClick={() => loadServiceScope(values)}>
                <BsCloudDownload />{'\u00A0'}
                Reload scope from service description (will clear your current form).
              </div>
              {serviceScopeLoadingError !== '' && 
                <span className={css.serviceScopeLoadingError}>{serviceScopeLoadingError}</span>
              }
              <MultilevelScopeInput 
                intl={intl} 
                values={values}
                disabled={disabled}
                scopeType={'offer'}
                isAdminOffer={true} // no price validation
                jobScope={serviceScope}
                isJobScopeLoaded={isServiceScopeLoaded}
              />
              <h2 className={css.servicePriceTitle}>
                Suggest final price • optional
              </h2>
              <FieldCurrencyInput
                id="estimatedPriceSummary"
                name="estimatedPriceSummary"
                className={css.title}
                currencyConfig={config.currencyConfig}
                disabled={disabled}
                suggestPriceValue={suggestedPriceValue}
              />
            </>
            <SideValidatedFieldTextInput
              id="estimatedTime"
              name="estimatedTime"
              type="number"
              className={css.estimatedTimeInput}
              label={intl.formatMessage({ id: 'DiscussServiceForm.estimatedTimeLabel' })}
              placeholder={intl.formatMessage({ id: 'DiscussServiceForm.estimatedTimePlaceholder' })}
              validate={composeValidators(valueNotNegative)}
              triggerTrackValue={sumMinTimeValue}
              triggerFieldValue={suggestedEstimatedTime}
              disabled={disabled}
              // defaultValue={suggestedEstimatedTime}
            />
            {((submitFailed || submitSucceeded) && (invalid || formMinimumPriceNotReached)) && 
              <span className={css.serviceError}>
                <FormattedMessage id="NewOfferForm.formInvalid" />
              </span>
            }
            <Button
              className={css.submitButton}
              type="submit"
              disabled={disabled}
            >
              {sendEnquiryButton}
            </Button>
          </Form>
        );
      }}
    />

  );
}




DiscussServiceFormComponent.defaultProps = { 
    className: null,
};

DiscussServiceFormComponent.propTypes = {
    className: string,
    onSubmit: func.isRequired,
    intl: intlShape.isRequired,
};

export default compose(injectIntl)(DiscussServiceFormComponent);
// based on EditListingPricingForm