import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { createExamTemplate, readExistingExamListings } from './ExamsListPage.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import {
  Sidebar,
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { TopbarContainer } from '../../containers';
import ExamListingCard from './ExamListingCard/ExamListingCard';
import { ExpertModal } from '../../components';
import css from './ExamsListPage.css';

export const ExamsListPageComponent = props => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled,
    // onCreateExamTemplate,
    onManageDisableScrolling,
    currentUserStripeAccounts,
    history,
    onReadExistingExams,
  } = props;
  let usdAccount = currentUserStripeAccounts?.find(user => user.currency === 'usd');
  let plnAccount = currentUserStripeAccounts?.find(user => user.currency === 'pln');
  const userHasBalance = usdAccount?.balance || plnAccount?.balance;
  //const [expertModalOpen, setExpertModalOpen] = useState(!userHasBalance); - to było
  const [expertModalOpen, setExpertModalOpen] = useState(true);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  // const [creatingExamInProgress, setCreatingExamInProgress] = useState(false);
  // const [creatingExamMessage, setCreatingExamMessage] = useState();
  const [existingExams, setExistingExams] = useState([]);

  useEffect(() => {
    readExistingExams(onReadExistingExams);
  }, [onReadExistingExams]);

  const readExistingExams = async onReadExistingExams => {
    const { data, meta } = await onReadExistingExams();
    if (meta?.totalItems > 0) {
      setExistingExams(data);
    }
  };

  const title = `Exams`;
  const isAdmin =
    currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

  // Sprawdzanie czy osoba jest isVerified, jak jest True to wtedy ustawia 
  // zmienną na false i blokuje renderowanie komponentu Expert Modal. 

  let expertModalForVerificated = true
  console.log(!currentUser.attributes.profile.publicData?.isVerified)

  if(!currentUser.attributes.profile.publicData?.isVerified){
    expertModalForVerificated = true;
  } else {
    expertModalForVerificated = false;
  }

  console.log(expertModalForVerificated)


  // const onCreateExam = async () => {
  //   setCreatingExamInProgress(true);
  //   const description = "Change this field to describe exam.";
  //   const config = {
  //     passThreshold: 80,
  //     examTimeEnabled: true,
  //     examTime: 10, // in minutes
  //     questionTimeEnabled: false,
  //     maxAttempts: 1,
  //     goingBackEnabled: false
  //   };
  //   const questions = [...Array(10).keys()].map((questionIndex) => {
  //     return {
  //       title: `Question ${questionIndex}`,
  //       answers: [
  //         "Answer 0",
  //         "Answer 1",
  //         "Answer 2"
  //       ],
  //       correctAnswers: [0,1],
  //       answerTime: null
  //     }
  //   })
  //   const result = await onCreateExamTemplate(description, config, questions);
  //   if(!!result?.data?.id.uuid) {
  //     setCreatingExamMessage(`Exam template created with id: ${result.data.id.uuid}`);
  //   }
  //   setCreatingExamInProgress(false);
  // }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="ExamsListPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav
          className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}
        >
          <Sidebar
            tab={'examsList'}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
          {/* {error} */}

          <div className={css.mainWrapper}>
            {currentUserHasUnpublishedExpertListing ? (
              <FormattedMessage id="ExamPage.youAreNotVerified" />
            ) : !userHasBalance ? (
              <FormattedMessage id="ExamPage.noBalance" />
            ) : (
              <>
                <div className={css.examsListWrapper}>
                  <ul className={css.examsList}>
                    {existingExams.map(examItem => {
                      return (
                        <li key={examItem.id.uuid}>
                          <ExamListingCard data={examItem} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <NamedLink name="ExamSuggestQuestionPage">
                  {'Suggest new exam question here.'}
                </NamedLink>
              </>
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      
      { expertModalForVerificated && <ExpertModal
        id="ListingPage.clientPath"
        step={1}
       
        listingId=""
        isOpen={expertModalOpen}
        onCloseModal={() => setExpertModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        history={history}
      /> }
    </Page>
  );
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserStripeAccounts,
  } = state.user;
  return {
    currentUser,
    currentUserStripeAccounts,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateExamTemplate: (description, config, questions) =>
    dispatch(createExamTemplate(description, config, questions)),
  onReadExistingExams: () => dispatch(readExistingExamListings()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ExamsListPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ExamsListPageComponent);

export default ExamsListPage;
