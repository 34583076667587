import React from 'react'
import css from './TransactionPanel.css';



const AttachmentPanel = props => {
const { data } = props

let files = [] 

if(!!data?.offerSelectedFiles){
  files = files.concat(data.offerSelectedFiles)
}

if(!!data?.offerAttachment){
  files.push(data.offerAttachment)
}

const listOfFiles = ( 
      <>
      <h3>Attachments: </h3>
      <ul className={css.attachmentList}>
        {files.map((item, index) => {
          return(
            <li key="{index}"><a href={item}>Attachment {index + 1}</a></li>
          )
      }) }
      </ul>
      </>
    )

return (
  <>
  {files.length > 0 ? listOfFiles : null}
  </>
)
}




export default AttachmentPanel;
