/* eslint-disable */
import React from 'react';
import { compose } from 'redux';
import { object, string, bool, number, func, shape } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';

import config from '../../config';
import {
  PriceFilter,
  SelectMultipleFilter,
  SortBy,
  InlineTextButton,
  NamedLink,
  LanguageFilter,
  RangeFilter,
} from '../../components';
import SearchIcon from '../../components/Topbar/SearchIcon';
import { TopbarSearchForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import {
  propTypes,
  LISTING_CATEGORY_EXPERT,
  LISTING_CATEGORY_JOB,
  LISTING_CATEGORY_SERVICE,
} from '../../util/types';
import css from './SearchFilters.css';

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;
const RADIX = 10;

//helper for comparing arrays
function arraysEqual(a1, a2) {
  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  return JSON.stringify(a1) === JSON.stringify(a2);
}

// resolve initial value for a single value filter
const initialValue = (queryParams, paramName) => {
  return queryParams[paramName];
};

const initialPriceRangeValue = (queryParams, paramName) => {
  const price = queryParams[paramName];
  const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

  return !!price && valuesFromParams.length === 2
    ? {
        minPrice: valuesFromParams[0],
        maxPrice: valuesFromParams[1],
      }
    : null;
};

const SearchFiltersComponent = props => {
  const {
    rootClassName,
    className,
    urlQueryParams,
    sort,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    priceFilter,
    keywordFilter,
    experienceFilter,
    yogaStylesFilter,
    projectsCountFilter,
    estimatedTimeFilter,
    serviceCategoriesFilter,
    languageFilter,
    isSearchFiltersPanelOpen,
    toggleSearchFiltersPanel,
    searchFiltersPanelSelectedCount,
    history,
    intl,
    category,
    software,
    skill,
    industry,
    onMapDesktopIconClick,
    saveUserKeywordSearch,
    isPrivateSearch,
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const tooFewResults = listingsAreLoaded && resultsCount < 10;
  const classes = classNames(rootClassName || css.root, className);

  if (tooFewResults && software) {
    if (
      history.location.pathname.includes('frame') &&
      history.location.pathname.includes('experts')
    ) {
      history.push(
        createResourceLocatorString(
          `SearchPage_frame_${category}s`,
          routeConfiguration(),
          {},
          { pub_softwares: software }
        )
      );
    } else {
      history.push(
        createResourceLocatorString(
          `SearchPage_${category}s`,
          routeConfiguration(),
          {},
          { pub_softwares: software }
        )
      );
    }
  }

  const initialPriceRange = priceFilter
    ? initialPriceRangeValue(urlQueryParams, priceFilter.paramName)
    : null;

  const initialEstimatedTimeRange = priceFilter
    ? initialPriceRangeValue(urlQueryParams, estimatedTimeFilter.paramName)
    : null;

  const initialKeyword = keywordFilter
    ? initialValue(urlQueryParams, keywordFilter.paramName)
    : null;
  
  const initialSearchBar = { keywords: initialKeyword };

  const handlePrice = (urlParam, range) => {
    const { minPrice, maxPrice } = range || {};
    const queryParams =
      minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [urlParam]: `${minPrice},${maxPrice}` }
        : omit(urlQueryParams, urlParam);
    software
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s_software`,
            routeConfiguration(),
            { software: software },
            queryParams
          )
        )
      : category
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s`,
            routeConfiguration(),
            {},
            queryParams
          )
        )
      : history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
        );
  };

  const handleNumber = (urlParam, range) => {
    const { minNumber, maxNumber } = range || {};
    const queryParams =
      minNumber != null && maxNumber != null
        ? { ...urlQueryParams, [urlParam]: `${minNumber},${maxNumber}` }
        : omit(urlQueryParams, urlParam);
    software
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s_software`,
            routeConfiguration(),
            { software: software },
            queryParams
          )
        )
      : category
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s`,
            routeConfiguration(),
            {},
            queryParams
          )
        )
      : history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
        );
  };

  // Reset all filter query parameters except pub_category


  const handleKeyword = (urlParam, values) => {
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);
    software
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s_software`,
            routeConfiguration(),
            { software: software },
            queryParams
          )
        )
      : category
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s`,
            routeConfiguration(),
            {},
            queryParams
          )
        )
      : history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
        );
  };

  const changeState = action => {
    const targetState = action === 'closed' ? ['awaiting', 'closed'] : 'open';
    const currentState = urlQueryParams.state;
    const queryParams = {
      ...urlQueryParams,
      state: arraysEqual(targetState, currentState) ? null : targetState,
    };
    software
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s_software`,
            routeConfiguration(),
            { software: software },
            queryParams
          )
        )
      : category
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s`,
            routeConfiguration(),
            {},
            queryParams
          )
        )
      : history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
        );
  };

  const searchBarSubmit = values => {
    const keywords = values.keywords;
    const searchParams = {
      ...urlQueryParams,
      keywords,
    };
    saveUserKeywordSearch(values.keywords);
    category
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s`,
            routeConfiguration(),
            {},
            searchParams
          )
        )
      : history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
        );
  };

  const isExpert = category === LISTING_CATEGORY_EXPERT;
  const isJob = category === LISTING_CATEGORY_JOB;

  const isService = category === LISTING_CATEGORY_SERVICE;

 
  const experienceLabel = intl.formatMessage({
    id: 'SearchFilters.experienceTitle',
  });

  const initialNumbersFunc = (queryParams, paramName) => {
    const projectsCount = queryParams[paramName];
    const valuesFromParams = !!projectsCount
      ? projectsCount.split(',').map(v => Number.parseInt(v, RADIX))
      : [];

    return !!projectsCount && valuesFromParams.length === 2
      ? {
          minNumber: valuesFromParams[0],
          maxNumber: valuesFromParams[1],
        }
      : null;
  };

  const initialExperience = experienceFilter
    ? initialNumbersFunc(urlQueryParams, experienceFilter.paramName)
    : null;

  const languagesLabel = intl.formatMessage({
    id: 'SearchFilters.languagesLabel',
  });

  const initialValuesFunc = (queryParams, paramName) => {
    const value = queryParams[paramName];
    const valuesFromParams = !!value ? value.split(',') : [];
    return valuesFromParams;
  };

  const initialLanguage = languageFilter
    ? initialValuesFunc(urlQueryParams, languageFilter.paramName)
    : null;

  const projectsCountLabel = intl.formatMessage({
    id: 'SearchFilters.projectsCountLabel',
  });

  const projectsCountSubtitle = intl.formatMessage({
    id: 'SearchFilters.projectsCountTitle',
  });

  const initialProjectsCount = projectsCountFilter
    ? initialNumbersFunc(urlQueryParams, projectsCountFilter.paramName)
    : null;



  const serviceCategoriesLabel = intl.formatMessage({
    id: 'SearchFilters.serviceCategoriesLabel',
  });

  const initialServiceCategories = serviceCategoriesFilter
    ? initialValuesFunc(urlQueryParams, serviceCategoriesFilter.paramName)
    : null;

  const serviceCategoriesFilterElement =
    serviceCategoriesFilter && isService ? (
      <SelectMultipleFilter
        id={'SearchFilters.serviceCategoriesFilter'}
        name="serviceCategories"
        urlParam={serviceCategoriesFilter.paramName}
        label={serviceCategoriesLabel}
        onSubmit={handleKeyword}
        showAsPopup
        options={serviceCategoriesFilter.config.options}
        initialValues={initialServiceCategories}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

  const softwareLabel = intl.formatMessage({ id: 'SearchFiltersPanel.softwareLabel' });

  const initialYogaStylesFunc = (queryParams, paramName) => {
    const yogaStyles = queryParams[paramName];
    const valuesFromParams = !!yogaStyles ? yogaStyles.split(',') : [];
    return valuesFromParams;
  };

  const initialYogaStyles = yogaStylesFilter
    ? initialYogaStylesFunc(urlQueryParams, yogaStylesFilter.paramName)
    : null;

  const initialSoftware = software ? [software] : initialYogaStyles;

  const handleSoftware = (urlParam, values) => {
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);
    const otherQueryParams = values
      ? { ...urlQueryParams, [urlParam]: null }
      : omit(urlQueryParams, urlParam);
    const softwareValue = values ? values[0] : null;

    category && softwareValue && values.length === 1
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s_software`,
            routeConfiguration(),
            { software: softwareValue },
            otherQueryParams
          )
        )
      : category
      ? history.push(
          createResourceLocatorString(
            `SearchPage_${category}s`,
            routeConfiguration(),
            {},
            queryParams
          )
        )
      : history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
        );
  };

  const yogaStylesFilterElement = yogaStylesFilter ? (
    <SelectMultipleFilter
      id="SearchFiltersPanel.yogaStylesFilter"
      name="yogaStyles"
      urlParam={yogaStylesFilter.paramName}
      label={softwareLabel}
      onSubmit={handleSoftware}
      showAsPopup
      options={yogaStylesFilter.options}
      initialValues={initialSoftware}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const toggleSearchFiltersPanelButtonClasses =
    isSearchFiltersPanelOpen || searchFiltersPanelSelectedCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;

  const toggleSearchFiltersPanelButton =
    !isService && toggleSearchFiltersPanel ? (
      <button
        className={toggleSearchFiltersPanelButtonClasses}
        onClick={() => {
          toggleSearchFiltersPanel(!isSearchFiltersPanelOpen);
        }}
      >
        <FormattedMessage
          id="SearchFilters.moreFiltersButton"
          values={{ count: searchFiltersPanelSelectedCount }}
        />
      </button>
    ) : null;

  const searchBar = (
    <div className={css.searchBar}>
      <SearchIcon className={css.searchIcon} />
      <TopbarSearchForm
        className={css.searchLink}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={searchBarSubmit}
        initialValues={initialSearchBar}
      />
    </div>
  );

  const postJobLink = (
    <NamedLink name="NewListingPage">
      <FormattedMessage id="SearchFilters.postJob" />
    </NamedLink>
  );
  const postHourlyLink = (
    <NamedLink name="SubscriptionPage">
      <FormattedMessage id="SearchFilters.hourly" />
    </NamedLink>
  );

  const create_project_link = (
    <NamedLink name="NewListingPage">
      <FormattedMessage id="SearchFilters.createProject" />
    </NamedLink>
  );

  const postExpertLink = (
    <NamedLink name="NewListingExpertPage">
      <FormattedMessage id="SearchResultsPanel.postExpertLink" />
    </NamedLink>
  );



  return (
    <div className={classes}>
      {isPrivateSearch ? null : searchBar}
      {/* <div className={css.searchOptions}>
        {listingsAreLoaded ? (
          <div className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage id="SearchFilters.foundResults" values={{ count: resultsCount }} />
            </span>
          </div>
        ) : null}
        {isPrivateSearch ? null : sortBy}
        {!isPrivateSearch && (
          <div className={css.mapIcon} onClick={onMapDesktopIconClick}>
            <FormattedMessage id="SearchFilters.openMapView" className={css.mapIconText} />
          </div>
        )}
      </div> */}

      <div className={css.filters}>
        <>
          {/* {dateRangeLengthFilterElement} */}
          {yogaStylesFilterElement}
          {/* {priceFilterElement} */}
          {/* {paidFilterElement} */}
          {/* {experienceFilterElement}
          {languageFilterElement}
          {projectsCountFilterElement} */}
          {serviceCategoriesFilterElement}
          {/* {estimatedTimeFilterElement} */}
          {toggleSearchFiltersPanelButton}
          {/* {activeStateButton}
          {closedStateButton} */}
          {/* <InlineTextButton rootClassName={css.resetAllButton} onClick={resetAll}>
            <FormattedMessage id={'SearchFiltersPanel.resetAll'} />
          </InlineTextButton> */}
        </>
      </div>

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          {!category ? (
            <FormattedMessage id="SearchFilters.noResults" />
          ) : software ? null : category === 'expert' ? (
            <FormattedMessage id="SearchFilters.noExpertsResults" values={{ postJobLink }} />
          ) : category === 'service' ? (
            <FormattedMessage id="SearchFilters.noServicesResults" values={{ postJobLink }} />
          ) : (
            <FormattedMessage id="SearchFilters.noJobResults" />
          )}
        </div>
      ) : null}


      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFilters.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchingInProgress: false,
  priceFilter: null,
  dateRangeLengthFilter: null,
  isSearchFiltersPanelOpen: false,
  toggleSearchFiltersPanel: null,
  searchFiltersPanelSelectedCount: 0,
};

SearchFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  onManageDisableScrolling: func.isRequired,
  priceFilter: propTypes.filterConfig,
  dateRangeLengthFilter: propTypes.filterConfig,
  isSearchFiltersPanelOpen: bool,
  toggleSearchFiltersPanel: func,
  searchFiltersPanelSelectedCount: number,
  onMapDesktopIconClick: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchFilters = compose(withRouter, injectIntl)(SearchFiltersComponent);

export default SearchFilters;
