import pick from 'lodash/pick';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT } from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/ServicePage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ServicePage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ServicePage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ServicePage/SHOW_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  id: null,
  fetchedExpertProfileForService: null,
  fetchedExpertServices: null,
  showListingError: null,
  expertLoaded: false,
  expert: null,
  expertLoadingInProgress: false,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return { 
        ...state, 
        fetchedExpertProfileForService: payload.data.expertProfile
      };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = (data) => ({
  type: SHOW_LISTING_SUCCESS,
  payload: {data},
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const listingAuthorId = data.data.data.relationships.author.data.id.uuid;
      sdk.listings.query({
        authorId: listingAuthorId,
        pub_category: "expert",
      }).then(res => {
        const expertProfile = res.data.data[0];
        data = {
          ...data,
          expertProfile: expertProfile,
        };
        dispatch(showListingSuccess({
          expertProfile: expertProfile
        }));
        dispatch(addMarketplaceEntities(data));
        return data;
      });

    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const showUser = (userId) => (dispatch, getState, sdk) => {
  return sdk.users.show({
    id: userId,
    include: ['profileImage'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
    ],
  })
    .then(data => {
      return data;
    })
    .catch(e => {
    });
}

export const loadData = (params, search, pathname) => dispatch => {
  const listingId = new UUID(params.id)
  
  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return dispatch(showListing(listingId))
};