import React, { useState } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { loadData, setInitialValues, approveItem } from './ProductItemsApprovalPage.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  NamedRedirect,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Sidebar,
} from '../../components';
import { TopbarContainer } from '..';
import ApprovalItem from './ApprovalItem';
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';

import css from './ProductItemsApprovalPage.css';

export const ProductItemsApprovalPageComponent = props => {
  const {
    currentUser,
    intl,
    params,
    scrollingDisabled,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    pendingApprovalSoftware,
    pendingApprovalSkills,
    onApproveItem
  } = props;
  const { tab } = params;

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const pageTitle = intl.formatMessage({
    id: "ProductItemsApprovalPage.title",
  });

  const isAdmin = currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

  if (currentUser?.id && !isAdmin) {
    return <NamedRedirect name="NotificationsPage" params={{ tab: 'notifications' }} />;
  }

  const handleApproveItem = (id, type) => {
    const itemApprovedMessage = intl.formatMessage({ id: "ProductItemsApprovalPage.approved" });
    onApproveItem(id, type).then(() =>
      toast(itemApprovedMessage, { style: { marginTop: '100px', marginLeft: '300px' } })
    );
  }

  return (
    <Page title={pageTitle} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="ProductItemsApprovalPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}>
          <Sidebar
            tab={tab}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
          <div className={css.itemsWrapper}>
            <Toaster />
            <div className={css.itemRow}>
              <FormattedMessage id="ProductItemsApprovalPage.software" />
              <div className={css.itemContainer}>
                {pendingApprovalSoftware.map(software => {
                  return (
                    <ApprovalItem
                      key={software.id}
                      item={software}
                      onApproveItem={() => handleApproveItem(software.id, 'software')}
                    />
                  )
                })}
                {pendingApprovalSoftware.length === 0 && <FormattedMessage id="ProductItemsApprovalPage.noSoftware" />}
              </div>
            </div>
            <div className={css.itemRow}>
              <FormattedMessage id="ProductItemsApprovalPage.skills" />
              <div className={css.itemContainer}>
                {pendingApprovalSkills.map(skill => {
                  return (
                    <ApprovalItem
                      key={skill.id}
                      item={skill}
                      onApproveItem={() => handleApproveItem(skill.id, 'skill')}
                    />
                  )
                })}
                {pendingApprovalSkills.length === 0 && <FormattedMessage id="ProductItemsApprovalPage.noSkills" />}
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ProductItemsApprovalPageComponent.defaultProps = {

};

ProductItemsApprovalPageComponent.propTypes = {
};

const mapStateToProps = state => {
  const {
    pendingApprovalSoftware,
    pendingApprovalSkills
  } = state.ProductItemsApprovalPage;
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing
  } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    pendingApprovalSoftware,
    pendingApprovalSkills,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUser
  }
};

const mapDispatchToProps = dispatch => ({
  onApproveItem: (itemId, type) => dispatch(approveItem(itemId, type))
})

const ProductItemsApprovalPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ProductItemsApprovalPageComponent);

ProductItemsApprovalPage.setInitialValues = initialValues => setInitialValues(initialValues);
ProductItemsApprovalPage.loadData = loadData;

export default ProductItemsApprovalPage;