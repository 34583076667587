/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionScopeMaybe.css';

const SectionScopeMaybe = props => {
  const { scope, formattedPrice, offerAuthor, isAdmin, isPremiumUser, isOwnListing } = props;

  const [oldOfferWithNoSubtasks, setOldOfferWithNoSubtasks] = useState(true);
  const commissionFactor=2
  useEffect(() => {
    // Old scope didn't contained subtasks
    if (scope?.length > 0) {
      let isOldOfferWithoutSubtasks = true;
      for (let i = 0; i < scope.length; i++) {
        if (!!scope[i]?.subtasks === true) {
          isOldOfferWithoutSubtasks = false;
        }
      }
      setOldOfferWithNoSubtasks(isOldOfferWithoutSubtasks);
    }
  }, [scope, formattedPrice]);
  
  const renderScopeRegularOffer = (scope) => {
    let scopeSummaryPrice = 0;
    for (let i = 0; i < scope.length; i++) {
      if (!!scope[i]?.price) {
        scopeSummaryPrice += isOwnListing ? scope[i]?.price / commissionFactor : scope[i]?.price;
      }
    }
    scopeSummaryPrice = scopeSummaryPrice > 0 ? '$' + scopeSummaryPrice : '';
    let formattedScope = scope.map((item, id) => {
      const scopeTaskName = item.name;
      const scopeTaskTime = item.time && item.time > 0
        ? item.time === 1
          ? item.time + ' hour' : item.time + ' hours'
        : '';
      const scopeTaskPrice = item.price
        ? ('$' + (isOwnListing ? item.price/commissionFactor: item.price))
        : '';

      const scopeTaskSubtasks = item.subtasks.map((subitem, id) => {
        const scopeSubtaskName = subitem.name;
        const scopeSubtaskPrice = subitem.price
          ? ('$' + (isOwnListing ? subitem.price/commissionFactor: subitem.price))
          : '';

        // SUBTASK
        return (
          <div key={'scopeSubtask' + id} className={css.subtask}>
            <div>{scopeSubtaskName}</div>
            {isAdmin || isPremiumUser || isOwnListing ? (
              <div>{scopeSubtaskPrice}</div>
            ) : null}
          </div>
        );
      });
      // TASK
      return (
        <div key={'scopeTask' + id} className={css.projects}>
          <div className={css.projectSideWrapper}>

            <div className={css.projectPointer} />

            <div className={
              (scope.indexOf(item) + 1 === scope.length) && (item.subtasks.length === 0) ? css.projectsNoSideLine
                : (scope.indexOf(item) + 1 === scope.length) ? css.projectsLastSideLine
                  : css.projectsSideLine
            }
            />

          </div>

          <div className={css.projectWrapper}>

            <div className={css.projectFirstRow}>
              <div className={css.projectTitle} >{scopeTaskName}</div>
              <div className={css.taskDetails}>
                <div className={css.projectTaskTime}>{scopeTaskTime}</div>
               {(isAdmin || isPremiumUser || isOwnListing) ?  <div className={css.projectTaskPrice}>{scopeTaskPrice}</div>: null}
              </div>
            </div>

            <div className={css.projectDescription}>
              {scopeTaskSubtasks}
            </div>

          </div>

        </div>
      );
    });
    // SUMMARY
   
    if (scopeSummaryPrice !== '' && (isAdmin || isPremiumUser || isOwnListing)) {
      formattedScope.push(
        <div key={'summary'} className={css.scopeItemSummaryWrapper}>
          <div className={css.scopeItemSummaryTitle}>
            Summary
              </div>
          <div className={css.scopeItemSummaryPrice}>
            {scopeSummaryPrice}
          </div>
        </div>
      );
    }
    return formattedScope;
  }
 
  
  const renderScope = (scope) => {

    let formattedScope = scope.map((item, id) => {
      const scopeItemTask = item.task;
      const scopeItemTime = item.time && item.time > 0
        ? item.time === 1
          ? item.time + ' hour' : item.time + ' hours'
        : '';
      const scopeItemPrice = item.price ? ('$' + item.price) : '';
      
      return (
        <div key={'scopeTask' + id} className={css.projects}>
          <div className={css.projectSideWrapper}>

            <div className={css.projectPointer} />

            {id !== scope.length - 1
              ? <div className={css.projectsSideLine} />
              : null}
          </div>

          <div className={css.projectWrapper}>
            <div className={css.projectFirstRow}>
              <div className={css.projectTitle} >{scopeItemTask}</div>
              <div className={css.taskDetails}>
                <div className={css.projectTaskTime}>{scopeItemTime}</div>
                <div className={css.projectTaskPrice}>{scopeItemPrice}</div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    formattedScope.push(
      <div key={'summary'} className={css.scopeItemSummaryWrapper}>
        <div className={css.scopeItemSummaryTitle}>
          Total price
            </div>
        <div className={css.scopeItemSummaryPrice}>
          {formattedPrice}
        </div>
      </div>
    );
    return formattedScope;
  }

  return (scope && scope.length > 0) ? (
    <div className={css.sectionServiceScopeWrapper}>
      <h2 className={css.serviceScopeTitle}>
        Service Scope
      </h2>
      {!!offerAuthor &&
        <h4 className={css.noMarginHeading}>
          Authored by: {offerAuthor}
        </h4>
      }
      <div className={css.serviceScopeContainer}>
        {
          oldOfferWithNoSubtasks ?
            renderScope(scope)
            : renderScopeRegularOffer(scope)
        }
      </div>
    </div>
  ) : null;
};

export default SectionScopeMaybe;
