import React, { Component } from 'react';
import PropTypes, { number } from 'prop-types';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { newServiceFrameMail } from '../../util/triggerMail';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { IoMdCopy, IoMdSwitch } from 'react-icons/io';
import { TextField } from '@material-ui/core';
import ServiceFrameScreen from './images/service-iframe-screen.png';
import Button from '../../components/Button/Button';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import css from './IFrameServicePage.css';

export class IFrameServicePageComponent extends Component {
  constructor(props) {
    super(props);
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    this.props.staticContext.notfound = true;

    const MAX_MOBILE_SCREEN_WIDTH = 768;
    const isMobileLayout = this.props.viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    this.state = {
      userId: '',
      websiteInput: '',
      shoudlDisplayFrameCode: false,
      isButtonDisabled: true,
      shouldDisplayTextFieldAnnotation: true,
      styledCodeText: '',
      plainCodeText: '',
      codeToDisplay: '',
      copyButtonText: isMobileLayout ? 'Copy' : 'Copy to clipboard',
      switchButtonText: isMobileLayout ? 'Switch' : 'Switch versions'
    };

    this.handleButtonCopy = this.handleButtonCopy.bind(this);
    this.handleButtonSwitch = this.handleButtonSwitch.bind(this);
    this.handleButtonGenerate = this.handleButtonGenerate.bind(this);
    this.websiteInputHandler = this.websiteInputHandler.bind(this);
  }

  componentDidMount() {
    let userId = new URLSearchParams(this.props.location.search).get("userid");
    const styledCodeText = `<style>.softwaresuppIFrameContainer{position:relative;height:500px;width:90%;}@media (min-width: 560px){.softwaresuppIFrameContainer{width:80%}}@media (min-width: 960px){.softwaresuppIFrameContainer{width:70%}}@media (min-width: 1280px){.softwaresuppIFrameContainer{width:60%}}.softwaresuppIFrameWrapper{position:absolute;top:0;left:0;width:98%;height:98%;border-style:solid;border-width:1px;border-radius:5px;border-color:#e5e5e5;box-shadow:0 2px 4px 0 rgba(0,0,0,.09);}</style><div class="softwaresuppIFrameContainer"><iframe class="softwaresuppIFrameWrapper" id="softwaresuppIFrame" title="Services" width="auto" height="auto" src="https://softwaresupp.com/frame/services/${userId}" >This browser does not support iframe. To check my services you can visit softwaresupp.com</iframe></div>`;
    const plainCodeText = `<iframe id="softwaresuppiFrame" title="Services" width="400" height="230" src="https://softwaresupp.com/frame/services/${userId}"></iframe>`;
    this.setState({
      userId: userId,
      styledCodeText: styledCodeText,
      plainCodeText: plainCodeText,
      currentCodeVersion: 'Styled',
      codeToDisplay: styledCodeText
    });
  }

  handleButtonCopy() {
    var textArea = document.createElement("textarea");
    // ---- styling
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    // copy text
    textArea.value = this.state.codeToDisplay;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
  }

  handleButtonSwitch() {
    if(this.state.currentCodeVersion === 'Styled') {
      this.setState({
        codeToDisplay: this.state.plainCodeText,
        currentCodeVersion: 'Plain'
      });
    } else {
      this.setState({
        codeToDisplay: this.state.styledCodeText,
        currentCodeVersion: 'Styled'
      });
    }
  }

  handleButtonGenerate() {
    this.setState({
      shoudlDisplayFrameCode: true,
      isButtonDisabled: true,
    });
    // send email with data
    newServiceFrameMail(this.state.websiteInput, this.props.currentUser.attributes.email, this.state.userId);
  }

  websiteInputHandler(e) {
    if(e?.target?.value?.includes('http')) {
      this.setState({
        isButtonDisabled: false,
        shouldDisplayTextFieldAnnotation: false,
        websiteInput: e.target.value
      });
    } else {
      this.setState({
        isButtonDisabled: true,
        shouldDisplayTextFieldAnnotation: true,
        websiteInput: e.target.value
      });
    }
  }

  render() {
    const { intl, scrollingDisabled } = this.props;


    const title = intl.formatMessage({
      id: 'IFrameServicePage.title',
    });

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.root}>
              <div className={css.content}>
                <h1 className={css.heading}>
                  Your SoftwareSupp <strong style={{textDecoration: 'underline'}}>Services</strong> iFrame 
                </h1>
                <div className={css.imageWrapper}>
                  <img src={ServiceFrameScreen} alt="Service Frame"/>
                </div>
                <h4>
                  This is out-of-the-box iFrame generator with SoftwareSupp custom css styles and responsive design, you can also generate plain version without css styles. You can copy this code and include it in any suitable place on your website. If you are not sure what to do with it, please contact us or your web developer.
                </h4>
                <div className={css.generateFormWrapper}>
                  <TextField label="Your website" id="websiteInput" type="email" placeholder="http://" value={this.state.websiteInput} onChange={this.websiteInputHandler} />
                  {this.state.shouldDisplayTextFieldAnnotation && 
                    <p className={css.textFieldAnnotation}>Wesbite address should include 'http://' or 'https://'</p>
                  }
                  <h5>
                    We need it to add you to our whitelist which should take no more than 24 hours.<br />
                    After that you will receive e-mail confirmation and iFrame will be ready to use.
                  </h5>
                  <Button disabled={this.state.isButtonDisabled} className={css.buttonGenerate} onClick={this.handleButtonGenerate}>Generate</Button>
                </div>
                {this.state.shoudlDisplayFrameCode && 
                  <div className={css.codeFieldWrapper}>
                    <div className={css.codeFieldHeadlineWrapper}>
                      <div className={css.codeCurrentVersion}>
                        <strong>{this.state.currentCodeVersion+" version"}</strong>
                      </div>
                      <div className={css.codeActionButtonsWrapper}>
                        <div className={css.buttonCopy} onClick={this.handleButtonCopy}><IoMdCopy />{this.state.copyButtonText}</div>
                        <div className={css.buttonSwitch} onClick={this.handleButtonSwitch}><IoMdSwitch />{this.state.switchButtonText}</div>
                      </div>
                    </div>
                    <div className={css.codeContainer}>
                      {this.state.codeToDisplay}
                    </div>
                  </div>
                }
              </div>
              </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

IFrameServicePageComponent.defaultProps = {
          staticContext: {},
};

const { bool, func, object, shape} = PropTypes;

IFrameServicePageComponent.propTypes = {
          scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
          push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
  } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser: currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const IFrameServicePage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(IFrameServicePageComponent);

export default IFrameServicePage;
