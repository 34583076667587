import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IoMdClose } from 'react-icons/io'; 

import css from './LightboxWrapper.css';

const LightboxWrapper = props => {

  const [isModalActive, setIsModalActive] = useState(false);
  const { className, rootClassName, children } = props;
  const classes = classNames(rootClassName || css.root, className, isModalActive ? css.rootPositionBlocked : null);

  const setModalToActive = () => {
    setIsModalActive(true);
  }

  const closeButtonHandler = () => {
    setIsModalActive(false);
  }

  return (
    <div className={classes}>
      { isModalActive && 
        <div className={css.lightboxItem}>
          <div className={css.contentWrapper}>
          <div className={css.lightboxCloseWrapper} onClick={closeButtonHandler}>
            <IoMdClose color={'lightgray'} size={"1.5em"} />
          </div>
            <div className={css.videoWrapper}>
              <iframe id="player" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="640" height="360" src="https://www.youtube.com/embed/2ccabSvu1Fw?wmode=opaque&amp;widget_referrer=https%3A%2F%2Fsoftwaresupp.com%2F&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fcdn.embedly.com&amp;widgetid=1"></iframe>
            </div>
          </div>
        </div>
      }
      {React.cloneElement(children, { showLightbox: setModalToActive })}
    </div>
  );
};

LightboxWrapper.defaultProps = {
  className: null,
  rootClassName: null,
};

const { node, string } = PropTypes;

LightboxWrapper.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
};

export default LightboxWrapper;
