import pick from 'lodash/pick';
import { storableError } from '../../util/errors';
import { getAllSelectValues, modifyApprovalForSelectValue } from '../../util/api';
import { fetchProductSoftware, fetchProductSkills } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/ProductItemsApprovalPage/SET_INITIAL_VALUES';

export const FETCH_SOFTWARE_APPROVALS_REQUEST = 'app/ProductItemsApprovalPage/FETCH_SOFTWARE_APPROVALS_REQUEST';
export const FETCH_SOFTWARE_APPROVALS_SUCCESS = 'app/ProductItemsApprovalPage/FETCH_SOFTWARE_APPROVALS_SUCCESS';
export const FETCH_SOFTWARE_APPROVALS_ERROR = 'app/ProductItemsApprovalPage/FETCH_SOFTWARE_APPROVALS_ERROR';

export const FETCH_SKILLS_APPROVALS_REQUEST = 'app/ProductItemsApprovalPage/FETCH_SKILLS_APPROVALS_REQUEST';
export const FETCH_SKILLS_APPROVALS_SUCCESS = 'app/ProductItemsApprovalPage/FETCH_SKILLS_APPROVALS_SUCCESS';
export const FETCH_SKILLS_APPROVALS_ERROR = 'app/ProductItemsApprovalPage/FETCH_SKILLS_APPROVALS_ERROR';

export const MODIFY_APPROVAL_ERROR = 'app/ProductItemsApprovalPage/MODIFY_APPROVAL_ERROR';

// ================ Reducer ================ //

const initialState = {
  pendingApprovalSkills: [],
  pendingApprovalSoftware: [],
  loadSkillsError: false,
  loadSoftwareError: false,
  loadingSoftwareInProgress: false,
  loadingSkillsInProgress: false,
  modifyApprovalError: false
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_SOFTWARE_APPROVALS_REQUEST:
      return { ...state, loadingSoftwareInProgress: true, loadSoftwareError: false };
    case FETCH_SOFTWARE_APPROVALS_SUCCESS:
      return { ...state, pendingApprovalSoftware: payload, loadingSoftwareInProgress: false };
    case FETCH_SOFTWARE_APPROVALS_ERROR:
      return { ...state, loadingSoftwareInProgress: false, loadSoftwareError: true };

    case FETCH_SKILLS_APPROVALS_REQUEST:
      return { ...state, loadingSkillsInProgress: true, loadSkillsError: false };
    case FETCH_SKILLS_APPROVALS_SUCCESS:
      return { ...state, pendingApprovalSkills: payload, loadingSkillsInProgress: false };
    case FETCH_SKILLS_APPROVALS_ERROR:
      return { ...state, loadingSkillsInProgress: false, loadSkillsError: true };

    case MODIFY_APPROVAL_ERROR:
      return { ...state, modifyApprovalError: true }

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const fetchSoftwareApprovalsRequest = () => ({
  type: FETCH_SOFTWARE_APPROVALS_REQUEST,
});

export const fetchSoftwareApprovalsSuccess = pendingApprovalItems => ({
  type: FETCH_SOFTWARE_APPROVALS_SUCCESS,
  payload: pendingApprovalItems
});

export const fetchSoftwareApprovalsError = () => ({
  type: FETCH_SOFTWARE_APPROVALS_ERROR,
});

export const fetchSkillsApprovalsRequest = () => ({
  type: FETCH_SKILLS_APPROVALS_REQUEST,
});

export const fetchSkillsApprovalsSuccess = pendingApprovalItems => ({
  type: FETCH_SKILLS_APPROVALS_SUCCESS,
  payload: pendingApprovalItems
});

export const fetchSkillsApprovalsError = () => ({
  type: FETCH_SKILLS_APPROVALS_ERROR,
});

export const modifyApprovalForSelectValueError = () => ({
  type: MODIFY_APPROVAL_ERROR,
});

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});


// ================ Thunks ================ //

export const fetchSoftware = () => (dispatch, getState, sdk) => {
  dispatch(fetchSoftwareApprovalsRequest())

  return getAllSelectValues({ type: 'software', isApproved: false }).then(response => {
    if (response.success) {
      return dispatch(fetchSoftwareApprovalsSuccess(response.data))
    }
  })
    .catch(e => {
      dispatch(fetchSoftwareApprovalsError(storableError(e)));
    })
}

export const fetchSkills = () => (dispatch, getState, sdk) => {
  dispatch(fetchSkillsApprovalsRequest())

  return getAllSelectValues({ type: 'skill', isApproved: false }).then(response => {
    if (response.success) {
      return dispatch(fetchSkillsApprovalsSuccess(response.data))
    }
  })
    .catch(e => {
      dispatch(fetchSkillsApprovalsError(storableError(e)));
    })
}

export const approveItem = (id, type) => (dispatch, getState, sdk) => {

  return modifyApprovalForSelectValue({ id, value: true }).then(response => {
    if (response.success) {
      if (type === 'skill') {
        dispatch(fetchProductSkills())
        return dispatch(fetchSkills())
      } else {
        dispatch(fetchProductSoftware())
        return dispatch(fetchSoftware())
      }
    }
  })
    .catch(e => {
      dispatch(modifyApprovalForSelectValueError(storableError(e)));
    })
}

export const loadData = () => dispatch => {
  return Promise.all([dispatch(fetchSkills()), dispatch(fetchSoftware())])
};