import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionJobScopeMaybe.css';

const SectionJobScopeMaybe = props => {
  const { scope } = props;

  const renderScope = (scope) => {
    let formattedScope = scope.map((milestone, index) => {
      return <div className={css.projects} key={milestone.task + index}>
        <div className={css.projectSideWrapper}>
          <div className={css.projectPointer} />
          <div className={
            (scope.indexOf(milestone) + 1 === scope.length) && (milestone.subtasks.length === 0) ? css.projectsNoSideLine
              : (scope.indexOf(milestone) + 1 === scope.length) ? css.projectsLastSideLine
                : css.projectsSideLine
          }
          />
        </div>
        <div className={css.projectWrapper}>
          <div className={css.projectFirstRow}>
            <span className={css.projectTitle} >{milestone.task}</span>
          </div>
          <div className={css.projectDescription}>
            {milestone.subtasks?.map(subtask => {
              return (
                <span className={css.subtask}>
                  {subtask.name}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    })
    return formattedScope;
  }

  return (scope && scope.length > 0) ? (
    <div className={css.scopeWrapper}>
      <h2 className={css.scopeTitle}>
        <FormattedMessage id={`ListingPage.jobScopeTitle`} />
      </h2>
      <div className={css.scopeContainer}>
        {renderScope(scope)}
      </div>
    </div>
  ) : null;
};

export default SectionJobScopeMaybe;