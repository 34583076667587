import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

//when client or admin accepts an offer for a project
export const TRANSITION_ACCEPT_OFFER = 'transition/accept-offer';
export const TRANSITION_ACCEPT_OFFER_ADMIN = 'transition/accept-offer-admin';

// This is 
export const TRANSITION_ENQUIRE = 'transition/enquire';

//when the experts sends an offer
export const PRE_TRANSITION_SEND_OFFER = 'transition/send-offer';

//when SoftwareSupp wants to cancel an offer
export const TRANSITION_CANCEL_OFFER = 'transition/cancel-offer';

//when the client can talk with the expert
export const EXPERT_TRANSITION_ENQUIRE = 'transition/enquire-expert';

// create meetings
export const TRANSITION_FINISH_MEETING = 'transition/complete-meeting';
export const TRANSITION_FINISH_MEETING_SOFTWARESUPP = 'transition/complete-meeting-operator';
export const TRANSITION_CANCEL_MEETING = 'transition/refund-meeting';

//when the user has already made a transaction with a particular expert
export const TRANSITION_BOOKING_PRIVILIGED = 'transition/request-meeting-priviliged';

//recommending expert
export const TRANSITION_RECOMMEND = 'transition/recommend';

// paying for the offer
export const TRANSITION_PAY_FOR_OFFER = 'transition/pay-for-offer';

//canceling accepted ofer via flex console, for example when the user clicked it by mistake
export const TRANSITION_CANCEL_ACCEPTED_OFFER = 'transition/cancel-accepted-offer';

//restore a previous canceled offer 
export const TRANSITION_RESTORE_OFFER = 'transition/restore-offer';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */

//common transition states
const STATE_INITIAL = 'initial';

//client path states
const STATE_ENQUIRY = 'enquiry';
const STATE_BOOKING_CONFIRMED = 'meeting-confirmed-payment';
const STATE_MEETING_COMPLETED = 'meeting-completed';
const STATE_MEETING_CANCELED = 'meeting-canceled';

//expert path states
const STATE_PENDING_ACCEPT = 'pending-accept';
const STATE_CANCELED = 'canceled';
const STATE_ACCEPTED = 'accept';
const STATE_ACCEPTED_AND_REDIRECTED = 'accepted-and-redirected';
const STATE_RECOMMENDED = 'recommended';
const STATE_PAID = 'paid';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'preauth-unit-time-booking/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [EXPERT_TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [PRE_TRANSITION_SEND_OFFER]: STATE_PENDING_ACCEPT,
        [TRANSITION_BOOKING_PRIVILIGED]: STATE_BOOKING_CONFIRMED,
        [TRANSITION_RECOMMEND]: STATE_RECOMMENDED
      },
    },
    [STATE_BOOKING_CONFIRMED]: {
      on: {
        [TRANSITION_FINISH_MEETING] : STATE_MEETING_COMPLETED,
        [TRANSITION_FINISH_MEETING_SOFTWARESUPP] : STATE_MEETING_COMPLETED,
        [TRANSITION_CANCEL_MEETING] : STATE_MEETING_CANCELED,
      },
    },
    [STATE_PENDING_ACCEPT]: {
      on: {
        [TRANSITION_CANCEL_OFFER]: STATE_CANCELED,
        [TRANSITION_ACCEPT_OFFER]: STATE_ACCEPTED,
        [TRANSITION_ACCEPT_OFFER_ADMIN]: STATE_CANCELED,
      },
    },
    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_PAY_FOR_OFFER]: STATE_PAID,
        [TRANSITION_CANCEL_ACCEPTED_OFFER]: STATE_PENDING_ACCEPT
      }
    },
    [STATE_CANCELED]: {
      on: {
        [TRANSITION_RESTORE_OFFER]: STATE_PENDING_ACCEPT
      }
    },
    [STATE_MEETING_COMPLETED]: { type: 'final' },
    [STATE_ACCEPTED_AND_REDIRECTED]: { type: 'final' },
    [STATE_RECOMMENDED]: {type: 'final'},
    [STATE_PAID]: {type: 'final'}
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};
// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsBookingPaymentConfirmed = tx =>
  getTransitionsToState(STATE_BOOKING_CONFIRMED).includes(txLastTransition(tx));

export const txIsBookingCompleted = tx =>
  getTransitionsToState(STATE_MEETING_COMPLETED).includes(txLastTransition(tx));

export const txIsBookingCanceled = tx =>
  getTransitionsToState(STATE_MEETING_CANCELED).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));

export const txIsExpertOfferSent = tx =>
  getTransitionsToState(STATE_PENDING_ACCEPT).includes(txLastTransition(tx));

export const txIsExpertOfferCanceled = tx =>
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));

export const txIsPaid = tx => 
  getTransitionsToState(STATE_PAID).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ACCEPT_OFFER,
    TRANSITION_CANCEL_MEETING,
    TRANSITION_FINISH_MEETING,
    TRANSITION_FINISH_MEETING_SOFTWARESUPP,
    TRANSITION_BOOKING_PRIVILIGED,
    PRE_TRANSITION_SEND_OFFER,
    TRANSITION_CANCEL_OFFER,
    TRANSITION_PAY_FOR_OFFER
  ].includes(transition);
};

export const MEETING_TRANSITIONS = {
  TRANSITION_CANCEL_MEETING,
  TRANSITION_FINISH_MEETING,
  TRANSITION_FINISH_MEETING_SOFTWARESUPP,
  TRANSITION_BOOKING_PRIVILIGED,
}

export const MESSAGES_TRANSITIONS = {
  TRANSITION_ENQUIRE,
  EXPERT_TRANSITION_ENQUIRE,
}

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;
