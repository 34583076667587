import React from 'react';
import { AvatarLarge } from '../../components';

import css from './TransactionPanel.css';

// Functional component as a helper to build AddressLinkMaybe
const DetailCardImage = props => {
  const {
    avatarWrapperClassName,
    provider,
  } = props;

  const listingAvatar = <AvatarLarge user={provider} disableProfileLink />;

  return (
    <React.Fragment>
      <div className={avatarWrapperClassName || css.avatarWrapper}>{listingAvatar}</div>
    </React.Fragment>
  );
};

export default DetailCardImage;
