export const TESTIMONIALS = [
  // {
  //     id: 0,
  //     category: 'testimonial',
  //     author: "Jacek Zawadzki",
  //     company: "Jit Team",
  //     starsNumber: 5,
  //     quote: "The team is already running on the new setup and so far everything looks fine. Thanks a lot for the smooth running of this project, I am very satisfied with the cooperation with SoftwareSupp.",
  //     date: "Sep 1, 2020",
  //     imgLocation: "testimonial-Jacek-Zawadzki-profile-image.png",
  //     storyLink: "",
  // },
  {
    id: 1,
    category: 'testimonial',
    author: 'Patryk Ciesluk',
    company: 'TGL Poland',
    starsNumber: 5,
    quote:
      'We ordered implementation of CRM in our company. Guys from SoftwareSupp did it well. They are very helpfull and solution objective. They provide a very professional service with great final training. So my team understood well application same as they understood our needs. Well done, great job.',
    date: 'Mar 23, 2020',
    imgLocation: 'testimonial-Patryk-Ciesluk-profile-image.png',
    storyLink: 'https://softwaresupp.com/blog/tgl-poland-case-study',
  },
  {
    id: 2,
    category: 'caseStudy',
    author: 'SunRoof',
    company: '',
    starsNumber: 0,
    quote:
      'When we first met, SunRoof had already implemented Pipedrive at their company. What they needed help with was the optimization of processes and their setup.',
    date: 'Oct 4, 2020',
    imgLocation: 'sunroof-logo.png',
    storyLink: 'https://softwaresupp.com/blog/sunroof-case-study',
  },
  {
    id: 3,
    category: 'testimonial',
    author: 'Marcin Zielinski',
    company: 'Grupa Terytorium',
    starsNumber: 5,
    quote:
      'Great company, great people. They were implementing new CRM in our company. They provided us a very professional service with great final training. I recommend SoftwareSupp as reliable and trustworthy business partner.',
    date: 'Mar 24, 2020',
    imgLocation: 'testimonial-Marcin-Zielinski-profile-image.png',
    storyLink: 'https://softwaresupp.com/blog/grupa-terytorium-case-study',
  },

  {
    id: 4,
    category: 'testimonial',
    author: 'Mert Aktaş',
    company: 'UserGuiding',
    starsNumber: 5,
    quote:
      "We hired an automation expert freelancer from SoftwareSupp. She helped us finish the long process in only a few days! While our company grows, we'll probably hire experts from here as our tech stack grows.",
    date: 'Sep 10, 2020',
    imgLocation: 'testimonial-Mert-Aktas-profile-image.png',
    storyLink: '',
  },
  {
    id: 5,
    category: 'testimonial',
    author: 'Stefan Dreyer',
    company: 'TonePedia',
    starsNumber: 5,
    quote:
      'Mateusz is very eager to lead his customers to success. He helped us to a successful webshop relaunch on Shopify Plus as the agency project manager, coordinating between our business goals and the development roadmap. I am looking forward to meet in person.',
    date: 'Apr 8, 2020',
    imgLocation: 'testimonial-Stefan-Dreyer-profile-image.png',
    storyLink: '',
  },
  {
    id: 6,
    category: 'caseStudy',
    author: 'Lech Poznan',
    company: '',
    starsNumber: 0,
    quote:
      'CRM systems can be useful for many types of organizations. It’s not reserved only for companies that manufacture products, sell software, or offer services. Sports clubs can also benefit from optimizing their funnels in Pipedrive.',
    date: 'Oct 21, 2020',
    imgLocation: 'lech-poznan-logo.jpg',
    storyLink: 'https://softwaresupp.com/blog/lech-poznan-case-study',
  },
  {
    id: 7,
    category: 'caseStudy',
    author: 'JIT Team',
    company: '',
    starsNumber: 0,
    quote:
      'JIT Team had clearly defined goals and expectations when it comes to their Pipedrive CRM account. They turned to us with an issue solving a problem with generating proper reports in their CRM system.',
    date: 'Oct 22, 2020',
    imgLocation: 'jit-logo.png',
    storyLink: 'https://softwaresupp.com/blog/jit-team-case-study',
  },
  {
    id: 8,
    category: 'caseStudy',
    author: 'Dirty Hands',
    company: '',
    starsNumber: 0,
    quote:
      'To make the best decisions, many businesses need specific reports and summaries. Sometimes, the options available in the CRM system by default are not detailed enough. That was the problem the Dirty Hands team was facing.',
    date: 'Oct 21, 2020',
    imgLocation: 'dirty-hands-logo.png',
    storyLink: 'https://softwaresupp.com/blog/dirty-hands-case-study',
  },

  {
    id: 9,
    category: 'caseStudy',
    author: 'Cinch',
    company: '',
    starsNumber: 0,
    quote:
      'Cinch team reached out to us to support them in the database structuring, CRM integration, sales process design and team training to help them manage the company in one place.',
    date: 'Oct 21, 2020',
    imgLocation: 'cinch-logo.png',
    storyLink: 'https://softwaresupp.com/blog/cinch-case-study',
  },
  {
    id: 10,
    category: 'caseStudy',
    author: 'Trade Nations',
    company: '',
    starsNumber: 0,
    quote:
      'How is Trade Nations using the integrated technology to handle all business partners, leads, communication channels and sales in one place integrated with the website system?',
    date: 'Oct 20, 2020',
    imgLocation: 'trade-nations-logo.png',
    storyLink: 'https://softwaresupp.com/blog/bluemedia-case-study',
  },

  // {
  //     id: 4,
  //     category: 'testimonial',
  //     author: "Lauren Bernhardt-Rhone",
  //     company: "Freelance Marketing",
  //     starsNumber: 5,
  //     quote: "We were very happy with Zapier and Pipedrive integrations SoftwareSupp implemented for us, it was a rather complex project that required parsing email data and they did a great job making it work.",
  //     date: "May 19, 2020",
  //     imgLocation: "testimonial-Lauren-Bernhardt-Rhone-profile-image.png",
  //     storyLink: "https://softwaresupp.com/blog/pipedrive-crm-support-success-story",
  // },
  // {
  //     id: 5,
  //     category: 'testimonial',
  //     author: "Chris",
  //     company: "Pipedrive expert",
  //     starsNumber: 5,
  //     quote: "I would like to emphasize that in terms of project and customer service, I believe that the processes are arranged at a very high level, and I value direct cooperation with Mike from SoftwareSupp very much.",
  //     date: "Sep 4, 2020",
  //     imgLocation: "testimonial-Chris-profile-image.png",
  //     storyLink: "",
  // },
  // {
  //     id: 6,
  //     category: 'testimonial',
  //     author: "Jakub Moczulski",
  //     company: "Grupa Wirtualna Polska",
  //     starsNumber: 5,
  //     quote: "We operate the holding of different companies and used SoftwareSupp to find and connect with Baselinker Expert, had a great experience with them, they found the right person instantly, addressed questions up front, processed the payment securely. Looking forward to using their service again in the future.",
  //     date: "Aug 11, 2020",
  //     imgLocation: "testimonial-Jakub-Moczulski-profile-image.png",
  //     storyLink: "",
  // },
];
