import React from 'react';
import { bool, func, object, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  createSlug,
  getListingType,
} from '../../util/urlHelpers';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  EditServiceWizard,
  Page,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  Footer,
  EditPageSidebar,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
  requestCreateListingDraft,
  requestPublishListingDraft,
  requestUpdateListing,
  loadData,
  requestImageUpload,
  removeListingImage,
} from './EditServicePage.duck';
import css from './EditServicePage.css';

const { UUID } = sdkTypes;

const TABS = ['description', 'scope', 'category', 'software', 'photos', 'save'];

// N.B. All the presentational content needs to be extracted to their own components
export const EditServicePageComponent = props => {
  const {
    currentUser,
    currentUserHasExpertListing,
    getOwnListing,
    history,
    intl,
    onCreateListingDraft,
    onPublishListingDraft,
    onUpdateListing,
    onManageDisableScrolling,
    page,
    params,
    onImageUpload,
    scrollingDisabled,
    onRemoveListingImage,
  } = props;

  const isAdmin =
    currentUser && currentUser.id
      ? currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID
      : null;

  const scrollElements =
    typeof document !== 'undefined' ? TABS.map(tab => document.getElementById(tab)) : [];

  const handleClick = id => {
    // Get the size and position of our element in the viewport
    const rect = scrollElements[id].getBoundingClientRect();
    // The top offset of our element is the top position of the
    // element in the viewport plus the amount the body is scrolled
    const offsetTop = rect.top + window.scrollY - 100;
    // Now we can scroll the window to this position
    window.scrollTo(0, offsetTop);
  };

  const { id, type, referralNumber } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const listingId =
    (page.submittedListingId?.uuid === id ? page.submittedListingId : null) ||
    (id ? new UUID(id) : null);
  const listing = getOwnListing(listingId);
  const currentListing = ensureOwnListing(listing);
  const { title } = currentListing.attributes;
  const { state: currentListingState } = currentListing.attributes;
  const listingSlug = currentListingState ? createSlug(title) : 'draft';

  const {
    createListingDraftError = null,
    publishListingError = null,
    updateListingError = null,
    showListingsError = null,
  } = page;
  const errors = {
    createListingDraftError,
    publishListingError,
    updateListingError,
    showListingsError,
  };
  // TODO: is this dead code? (shouldRedirect is checked before)
  const newListingPublished =
    isDraftURI && currentListing && currentListingState !== LISTING_STATE_DRAFT;

  // Show form if user is posting a new listing or editing existing one
  const disableForm = page.redirectToListing && !showListingsError;

  const currentListingImages =
      currentListing && currentListing.images ? currentListing.images : [];

  // Images not yet connected to the listing
  const imageOrder = page.imageOrder || [];
  const unattachedImages = imageOrder.map(i => page.images[i]);
  console.log('PAGE', page);
  const allImages = currentListingImages.concat(unattachedImages);
  const removedImageIds = page.removedImageIds || [];
  const images = allImages.filter(img => {
    return !removedImageIds.includes(img.id);
  });
  

  const pageTitle = isNewListingFlow
    ? intl.formatMessage({ id: 'EditServicePage.titleCreateListing' })
    : intl.formatMessage({ id: 'EditServicePage.titleEditListing' });

  const showForm = isNewURI || currentListing.id;
  if (showForm) {
    return (
      <Page title={pageTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={css.topbar}
              mobileRootClassName={css.mobileTopbar}
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav className={css.navigation}>
            <EditPageSidebar
              slug={listingSlug}
              id={listingId.uuid}
              type={getListingType(type)}
              tabArray={TABS}
              handleScroll={handleClick}
            />
          </LayoutWrapperSideNav>
          <LayoutWrapperMain className={css.main}>
            <div className={css.container}>
              <EditServiceWizard
                id="EditListingWizard"
                className={css.wizard}
                params={params}
                disabled={disableForm}
                errors={errors}
                images={images}
                newListingPublished={newListingPublished}
                history={history}
                onImageUpload={onImageUpload}
                listing={currentListing}
                onRemoveImage={onRemoveListingImage}
                onUpdateListing={onUpdateListing}
                onCreateListingDraft={values => onCreateListingDraft(values)}
                handlePublishListing={onPublishListingDraft}
                currentUser={currentUser}
                currentUserHasExpertListing={currentUserHasExpertListing}
                onManageDisableScrolling={onManageDisableScrolling}
                updateInProgress={page.updateInProgress || page.createListingDraftInProgress}
                referralNumber={referralNumber}
                type={getListingType(type)}
                isAdmin={isAdmin}
              />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  } else {
    // If user has come to this page through a direct linkto edit existing listing,
    // we need to load it first.
    const loadingPageMsg = {
      id: 'EditExpertPage.loadingListingData',
    };
    return (
      <Page title={intl.formatMessage(loadingPageMsg)} scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
        <div className={css.placeholderWhileLoading} />
      </Page>
    );
  }
};

EditServicePageComponent.defaultProps = {
  currentUser: null,
  currentUserHasOrders: null,
  listing: null,
  listingDraft: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

EditServicePageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  getOwnListing: func.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  onCreateListingDraft: func.isRequired,
  onPublishListingDraft: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onUpdateListing: func.isRequired,
  page: object.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
  }).isRequired,
  scrollingDisabled: bool.isRequired,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const page = state.EditServicePage;

  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasExpertListing,
    currentUserExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;

  const getOwnListing = id => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);

    return listings.length === 1 ? listings[0] : null;
  };

  return {
    currentUser,
    currentUserListing,
    currentUserHasExpertListing,
    currentUserListingFetched,
    getOwnListing,
    page,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserExpertListing,
    currentUserHasUnpublishedExpertListing,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(requestImageUpload(data)),
  onRemoveListingImage: imageId => dispatch(removeListingImage(imageId)),
  onUpdateListing: values => dispatch(requestUpdateListing(values)),
  onCreateListingDraft: values => dispatch(requestCreateListingDraft(values)),
  onPublishListingDraft: listingId => dispatch(requestPublishListingDraft(listingId)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EditServicePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(EditServicePageComponent));

EditServicePage.loadData = loadData;

export default EditServicePage;
