import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { PrimaryButton, NamedLink } from '../../../components';
import { injectIntl } from '../../../util/reactIntl';
import css from './ExamStartInfo.css';

const getCurrentExamData = (currentUserExamAttempts, examId) => {
  if(!(currentUserExamAttempts?.length > 0)) {
    return null;
  }
  const filteredExams = currentUserExamAttempts?.filter((ex) => ex.examId === examId);
  return filteredExams?.length > 0 ? filteredExams[0] : null;
}

const ExamStartInfo = (props) => {

  const { intl, loadedExam, currentUser, onStartExam } = props;
  const examId = loadedExam?.id.uuid;
  const examTitle = loadedExam?.attributes.title;
  const examConfig = loadedExam?.attributes.publicData.config;
  const currentUserExamAttempts = currentUser?.attributes.profile.publicData?.examAttempts ?? [];
  const userExamData = getCurrentExamData(currentUserExamAttempts, examId);
  const isTooManyAttempts = !!userExamData && examConfig?.maxAttempts <= userExamData?.howManyAttempts;

  // EXAM RULES
  const timeRules = (!!examConfig?.examTimeEnabled && examConfig?.examTime > 0) ? 
                      intl.formatMessage({id: "ExamStartInfo.timeRules"}, {maxTime: examConfig.examTime }) : '';
  const goingBackRules = (!!examConfig.goingBackEnabled) ? <FormattedMessage id="ExamStartInfo.goingBackRules" /> : '';
  const maxAttemptsRules = (examConfig?.maxAttempts > 0) ? 
                            intl.formatMessage({id:"ExamStartInfo.maxAttemptsRules"}, 
                              {maxAttempts: examConfig.maxAttempts, userAttempts: userExamData?.howManyAttempts ?? 0 }) : '';
  const scoreInfoRules = intl.formatMessage({id:"ExamStartInfo.scoreInfoRules"});
  const passThresholdRules = (examConfig?.passThreshold > 0) ? 
                              intl.formatMessage({id:"ExamStartInfo.passThresholdRules"},
                                {passThreshold: (examConfig.passThreshold).toString()+'%' }) : '';
  const cheatingInfoRules = intl.formatMessage({id:"ExamStartInfo.cheatingInfoRules"});
  const examRules1 = (intl.formatMessage({id: "ExamStartInfo.basicRules"}));
  const examRules2 = timeRules + goingBackRules + maxAttemptsRules;
  const examRules3 = scoreInfoRules + passThresholdRules + cheatingInfoRules;

  return (
    <div>
      {isTooManyAttempts ?
        <div>
          <div className={css.examTitle}>
            {loadedExam?.attributes.title}
          </div>
          <FormattedMessage id="ExamStartInfo.tooManyAttempts" values={{ 
            userAttempts: userExamData?.howManyAttempts,
            maxAttempts: examConfig?.maxAttempts
           }}/>
          <div className={css.buttonWrapper}>
            <NamedLink name={"ExamsListPage"}>
              <PrimaryButton className={css.defaultButton}>
                <FormattedMessage id="ExamStartInfo.goBackButton" />
              </PrimaryButton>
            </NamedLink>
          </div>
        </div>
        :
        <div>
          <div className={css.titleWrapper}>
            {examTitle}
          </div>
          <div className={css.rulesWrapper}>
            {examRules1}
            <br /><br />
            {examRules2}
            <br /><br />
            {examRules3}
          </div>
          <div className={css.buttonWrapper}>
            <PrimaryButton className={css.defaultButton} onClick={onStartExam}>
              <FormattedMessage id="ExamStartInfo.startExamButton" />
            </PrimaryButton>
          </div>
        </div>
      }
    </div>
  );
}

export default injectIntl(ExamStartInfo);