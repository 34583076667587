import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { storableError } from '../util/errors';
import { 
  LISTING_STATE_DRAFT, 
  LISTING_CATEGORY_EXPERT, 
  LISTING_CATEGORY_JOB, 
  LISTING_STATE_PUBLISHED 
} from '../util/types';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { getStripeCustomer } from '../util/api';
import InboxSDK from '../util/inbox/InboxSDK';
import { checkIfNewUserInvitedAndUpdateContacts } from '../util/api';
import { addInboxTokenToUser } from '../util/api';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_HAS_EXPERT_LISTING_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_EXPERT_LISTING_REQUEST';
export const FETCH_CURRENT_USER_HAS_EXPERT_LISTING_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_EXPERT_LISTING_SUCCESS';
export const FETCH_CURRENT_USER_HAS_EXPERT_LISTING_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_EXPERT_LISTING_ERROR';

export const FETCH_CURRENT_USER_HAS_BEEN_INVITED_REQUEST = 'app/user/FETCH_CURRENT_USER_HAS_BEEN_INVITED_REQUEST';
export const FETCH_CURRENT_USER_HAS_BEEN_INVITED_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_BEEN_INVITED_SUCCESS';
export const FETCH_CURRENT_USER_HAS_BEEN_INVITED_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_BEEN_INVITED_ERROR';

export const FETCH_CURRENT_USER_STRIPE_ACCOUNTS_REQUEST = 'app/user/FETCH_CURRENT_USER_STRIPE_ACCOUNTS_REQUEST'
export const FETCH_CURRENT_USER_STRIPE_ACCOUNTS_SUCCESS = 'app/user/FETCH_CURRENT_USER_STRIPE_ACCOUNTS_SUCCESS'
export const FETCH_CURRENT_USER_STRIPE_ACCOUNTS_ERROR = 'app/user/FETCH_CURRENT_USER_STRIPE_ACCOUNTS_ERROR'

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const FETCH_CURRENT_USER_HAS_PROJECTS_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_PROJECTS_SUCCESS';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const FETCH_CURRENT_USER_HAS_UNREAD_ENTITIES_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_UNREAD_ENTITIES_SUCCESS';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListingsError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,
  currentUserExpertListing: null,
  currentUserHasExpertListing: null,
  currentUserHasUnpublishedExpertListing: false,
  fetchingListingInProgress: true,
  fetchingExpertListingInProgress: null,
  currentUserFetched: false,
  currentUserStripeAccounts: null,
  hasUnreadNotifications: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return { ...state, currentUserFetched: true, currentUser: mergeCurrentUser(state.currentUser, payload) };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserFetched: true, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserListing: null,
        currentUserListingFetched: false,
        fetchingListingInProgress: true
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserHasListings: payload.hasListings,
        currentUserListing: payload.listing,
        currentUserUnpublishedListing: payload.unpublishedListing,
        currentUserHasUnpublishedListing: !!payload.unpublishedListing,
        currentUserListingFetched: true,
        fetchingListingInProgress: false,
      };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_HAS_EXPERT_LISTING_REQUEST:
      return { ...state, fetchingExpertListingInProgress: true, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_EXPERT_LISTING_SUCCESS:
      return {
        ...state,
        currentUserExpertListing: payload.listing,
        currentUserListingFetched: true,
        currentUserHasExpertListing: payload.hasExpertListing,
        currentUserHasUnpublishedExpertListing: payload.hasUnpublishedExpertListing,
        fetchingExpertListingInProgress: false,
      };
    case FETCH_CURRENT_USER_HAS_EXPERT_LISTING_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, 
        currentUserHasListingsError: payload,
        fetchingExpertListingInProgress: false,
      };

    case FETCH_CURRENT_USER_HAS_BEEN_INVITED_REQUEST:
      return { ...state, fetchingUserInvitationInProgress: true, fetchingUserInvitationError: null };
    case FETCH_CURRENT_USER_HAS_BEEN_INVITED_SUCCESS:
      return {
        ...state,
        fetchingUserInvitationInProgress: false,
        fetchingUserInvitationError: null,
        userHasProjectInvitations: payload
      };
    case FETCH_CURRENT_USER_HAS_BEEN_INVITED_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        currentUserHasListingsError: payload,
        fetchingExpertListingInProgress: false,
      };

    case FETCH_CURRENT_USER_STRIPE_ACCOUNTS_REQUEST:
      return { ...state, currentUserStripeAccountsError: null };
    case FETCH_CURRENT_USER_STRIPE_ACCOUNTS_SUCCESS:
      return { ...state, currentUserStripeAccounts: payload };
    case FETCH_CURRENT_USER_STRIPE_ACCOUNTS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserStripeAccountsError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { 
        ...state,
        currentUserHasOrders: payload.hasOrders,
        currentUserHasLegacyProjects: payload.hasProjects        
      };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case FETCH_CURRENT_USER_HAS_PROJECTS_SUCCESS: 
    return {
      ...state,
      currentUserHasProjects: payload.hasProjects
    }

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case FETCH_CURRENT_USER_HAS_UNREAD_ENTITIES_SUCCESS: 
    return {
      ...state,
      hasUnreadNotifications: payload.notificationResponse
    }

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = (hasListings, listing, unpublishedListing) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings, listing, unpublishedListing },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasExpertListingRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_EXPERT_LISTING_REQUEST,
});
export const fetchCurrentUserHasExpertListingSuccess = (listing, hasExpertListing, hasUnpublishedExpertListing) => ({
  type: FETCH_CURRENT_USER_HAS_EXPERT_LISTING_SUCCESS,
  payload: { listing, hasExpertListing, hasUnpublishedExpertListing },
});
const fetchCurrentUserHasExpertListingError = e => ({
  type: FETCH_CURRENT_USER_HAS_EXPERT_LISTING_ERROR,
  error: true,
  payload: e,
});


const fetchCurrentUserHasBeenInvitedRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_BEEN_INVITED_REQUEST,
});
export const fetchCurrentUserHasBeenInvitedSuccess = (data) => ({
  type: FETCH_CURRENT_USER_HAS_BEEN_INVITED_SUCCESS,
  payload: data,
});

export const fetchCurrentUserStripeAccountsSuccess = accounts => ({
  type: FETCH_CURRENT_USER_STRIPE_ACCOUNTS_SUCCESS,
  payload: accounts,
});

const fetchCurrentUserStripeAccountsError = e => ({
  type: FETCH_CURRENT_USER_STRIPE_ACCOUNTS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = ( hasOrders, hasProjects) => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders, hasProjects },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const fetchCurrentUserHasProjectsSuccess = hasProjects => ({
  type: FETCH_CURRENT_USER_HAS_PROJECTS_SUCCESS,
  payload: { hasProjects },
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const fetchCurrentUserHasUnreadEntitiesSuccess = payload => ({
  type: FETCH_CURRENT_USER_HAS_UNREAD_ENTITIES_SUCCESS,
  payload: payload
})

// ================ Thunks ================ //

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  // const params = {
  //   // CHANGE (commented out) - check all, if at least one is not draft we return true
  //   // Since we are only interested in if the user has
  //   // listings, we only need at most one result.
  //   page: 1,
  //   per_page: 20,
  // };

  return sdk.ownListings
    .query()
    .then(response => {
      const listing = response.data.data?.find(listing =>
          listing.attributes.publicData.category === LISTING_CATEGORY_JOB
          && listing.attributes.state === 'published');
      const unpublishedListing = response.data.data?.find(listing =>
        listing.attributes.publicData.category === LISTING_CATEGORY_JOB
        && listing.attributes.state === 'pendingApproval');
      const hasListings = !!listing;
      let hasAtLeastOnePublishedListing = false;
      for(let i = 0; i < response.data.data.length; i++) {
        if(ensureOwnListing(response.data.data[i]).attributes.state !== LISTING_STATE_DRAFT) {
          hasAtLeastOnePublishedListing = true;
        }
      }
      const hasPublishedListings =
        !!hasListings &&
        hasAtLeastOnePublishedListing;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings, listing, unpublishedListing));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasExpertListing = () => async (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasExpertListingRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasExpertListingSuccess(false));
    return Promise.resolve(null);
  }
  let listings = [];
  try {
    let page = 1;
    while (true) {
      const response = await sdk.ownListings.query({ page, perPage:100 });
      page++;
      listings.push(...response.data.data);
      if (response.data.meta.totalPages===0 || response.data.meta.page === response.data.meta.totalPages) {
        break;
      }
      
    }
    const expertListingArray =
      listings.find(listing =>
        listing.attributes.publicData.category === LISTING_CATEGORY_EXPERT);
    const expertListing = expertListingArray;
    const hasExpertListing = expertListingArray && expertListingArray.attributes.state === LISTING_STATE_PUBLISHED;
    const hasUnpublishedExpertListing = expertListingArray && expertListingArray.attributes.state !== LISTING_STATE_PUBLISHED;
    dispatch(fetchCurrentUserHasExpertListingSuccess(expertListing, hasExpertListing, !!hasUnpublishedExpertListing));
  } catch (e) {
    dispatch(fetchCurrentUserHasExpertListingError(storableError(e)));
  }

};

export const fetchCurrentUserHasBeenInvitedToProject = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasBeenInvitedRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasBeenInvitedSuccess(false));
    return Promise.resolve(null);
  }

  return sdk.listings
    .query({ pub_invitedUsers: currentUser.attributes.email })
    .then(response => {
      const hasBeenInvited = response?.data.data.length > 0;
      const isListingPaidFor = !!response?.data.data.find(listing => listing.attributes.publicData.transactionId)
      dispatch(fetchCurrentUserHasBeenInvitedSuccess(hasBeenInvited && isListingPaidFor));
    })
    .catch(e => dispatch(fetchCurrentUserHasExpertListingError(storableError(e))));
}

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  const params = {
    only: 'order',
    page: 1,
    per_page: 100,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      const ordersArray = hasOrders ? response.data.data : [];
      const hasLegacyProjects = ordersArray.find(order => 
        !!order.attributes.transitions.find(transition => 
          transition.transition === 'transition/skip-payment'
          || transition.transition === 'transition/confirm-payment'
          || transition.transition === 'transition/invite-users'
          )
      )
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders, !!hasLegacyProjects));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

export const fetchCurrentUserHasProjects = () => (dispatch, getState, sdk) => {
  return new Promise((resolve, reject) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  sdk.ownListings
    .query({})
    .then(response => {
      const projectsArray = response.data.data ?? [];
      const hasProjects = projectsArray.find(
        project =>
          (project.attributes.publicData.state === 'inProgress' ||
            project.attributes.publicData.state === 'serviceInProgress' ||
            project.attributes.publicData.state === 'closed') 
      );
      dispatch(fetchCurrentUserHasProjectsSuccess(!!hasProjects));
    })
  })
};

export const fetchCurrentUserStripeAccounts = currentUser => (dispatch, getState, sdk) => {
  
  let accounts = [];
  const stripeAccounts = currentUser.attributes.profile.privateData?.stripeAccount
    ?? [currentUser.stripeCustomer?.attributes.stripeCustomerId]
    ?? [];
  if (stripeAccounts.length > 0 && stripeAccounts[0]) {
    getStripeCustomer({customer: stripeAccounts[0]})
    .then(customer => {
        accounts.push(customer)
        if (stripeAccounts.length > 1) {
          getStripeCustomer({customer: stripeAccounts[1]})
          .then(customer => {
              accounts.push(customer);
              dispatch(fetchCurrentUserStripeAccountsSuccess(accounts));
            })
            .catch(e => dispatch(fetchCurrentUserStripeAccountsError(storableError(e))))
          }
          else {
            dispatch(fetchCurrentUserStripeAccountsSuccess(accounts));
          }
      })
      .catch(e => dispatch(fetchCurrentUserStripeAccountsError(storableError(e))))
  }
  else if (currentUser?.id) {
    dispatch(fetchCurrentUserStripeAccountsSuccess([]));
  }
}

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {

  
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount', 'stripeCustomer.defaultPaymentMethod'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];


      
      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);

      if (!currentUser?.attributes?.profile?.privateData?.inboxToken) {
        const createdUserId = currentUser.id.uuid;
        addInboxTokenToUser({
          userId: createdUserId,
          firstName: currentUser.attributes.profile.firstName,
          lastName: currentUser.attributes.profile.lastName,
        }).then(({ userToken }) => {
          checkIfNewUserInvitedAndUpdateContacts(userToken, {
            userMail: currentUser.attributes.email,
          });

        }).catch((e)=>{console.log(e)});
      }
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasUnreadEntities(currentUser.attributes.profile.privateData.inboxToken, currentUser.id.uuid))
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserHasExpertListing());
      dispatch(fetchCurrentUserHasOrders());
      dispatch(fetchCurrentUserHasBeenInvitedToProject());
      dispatch(fetchCurrentUserStripeAccounts(currentUser));
      dispatch(fetchCurrentUserHasProjects());

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

export const fetchCurrentUserHasUnreadEntities = (userToken, userId) => (dispatch, _getState, _sdk) => {
  const inboxSdk = new InboxSDK(userId, userToken);
    return inboxSdk.userHasUnreadNotifications(userToken).then(notificationResponse => {
      return dispatch(fetchCurrentUserHasUnreadEntitiesSuccess({notificationResponse}));
    })

    .catch(error =>
      console.log(error)
    );
}