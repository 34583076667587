/* eslint-disable */
import React, { Component } from 'react';
import { object, string, bool, number, func, shape, array } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';

import config from '../../config';

import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import {
  ModalInMobile,
  Button,
  KeywordFilter,
  PriceFilter,
  SelectMultipleFilter,
  SortBy,
  LanguageFilter,
  RangeFilter
} from '../../components';
import {
  propTypes,
  LISTING_CATEGORY_EXPERT,
  LISTING_CATEGORY_JOB
} from '../../util/types';
import css from './SearchFiltersMobile.css';

const RADIX = 10;

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isFiltersOpenOnMobile: false, initialQueryParams: null };

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.handleSelectSingle = this.handleSelectSingle.bind(this);
    this.handleSelectMultiple = this.handleSelectMultiple.bind(this);
    this.handleSoftware = this.handleSoftware.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.handleNumber = this.handleNumber.bind(this);
    this.handleKeyword = this.handleKeyword.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);
    this.handleDateRangeLength = this.handleDateRangeLength.bind(this);
    this.initialValue = this.initialValue.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.initialPriceRangeValue = this.initialPriceRangeValue.bind(this);
    this.initialDateRangeValue = this.initialDateRangeValue.bind(this);
    // this.initialSoftware = this.initialSoftware.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal, software, category } = this.props;

    software ?
    history.push(createResourceLocatorString(`SearchPage_${category}s_software`, routeConfiguration(), {software: software}, this.state.initialQueryParams))
    :
    category ?
    history.push(createResourceLocatorString(`SearchPage_${category}s`, routeConfiguration(), {}, this.state.initialQueryParams))
    :
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, this.state.initialQueryParams));
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  handleSelectSingle(urlParam, option) {
    const { urlQueryParams, history } = this.props;

    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleSelectMultiple(urlParam, options) {
    const { urlQueryParams, history, category, software } = this.props;

    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: options.join(',') }
        : omit(urlQueryParams, urlParam);

    software ?
    history.push(createResourceLocatorString(`SearchPage_${category}s_software`, routeConfiguration(), {software: software}, queryParams))
    :
    category ?
    history.push(createResourceLocatorString(`SearchPage_${category}s`, routeConfiguration(), {}, queryParams))
    :
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleSoftware(urlParam, options) {
    const { urlQueryParams, history, category } = this.props;

    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: options.join(',') }
        : omit(urlQueryParams, urlParam);
    const otherQueryParams = 
      options  
      ? { ...urlQueryParams, [urlParam]: null }
      : omit(urlQueryParams, urlParam);
    const softwareValue = options ? options[0] : null;

    category && softwareValue && options.length === 1 ?
    history.push(createResourceLocatorString(`SearchPage_${category}s_software`, routeConfiguration(), {software: softwareValue}, otherQueryParams))
    :
    category ?
    history.push(createResourceLocatorString(`SearchPage_${category}s`, routeConfiguration(), {}, queryParams))
    :
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handlePrice(urlParam, range) {
    const { urlQueryParams, history, category, software } = this.props;
    const { minPrice, maxPrice } = range || {};
    const queryParams =
      minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [urlParam]: `${minPrice},${maxPrice}` }
        : omit(urlQueryParams, urlParam);

    software ?
    history.push(createResourceLocatorString(`SearchPage_${category}s_software`, routeConfiguration(), {software: software}, queryParams))
    :
    category ?
    history.push(createResourceLocatorString(`SearchPage_${category}s`, routeConfiguration(), {}, queryParams))
    :
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }


  handleNumber(urlParam, range) {
    const { urlQueryParams, history, category, software } = this.props;
    const { minNumber, maxNumber } = range || {};
    const queryParams =
      minNumber != null && maxNumber != null
        ? { ...urlQueryParams, [urlParam]: `${minNumber},${maxNumber}` }
        : omit(urlQueryParams, urlParam);

    software ?
      history.push(createResourceLocatorString(`SearchPage_${category}s_software`, routeConfiguration(), { software: software }, queryParams))
      :
      category ?
        history.push(createResourceLocatorString(`SearchPage_${category}s`, routeConfiguration(), {}, queryParams))
        :
        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleKeyword(urlParam, keywords) {
    const { urlQueryParams, history, category, software, saveUserKeywordSearch } = this.props;
    const queryParams = urlParam
      ? { ...urlQueryParams, [urlParam]: keywords }
      : omit(urlQueryParams, urlParam);
    saveUserKeywordSearch(keywords);

    software ?
    history.push(createResourceLocatorString(`SearchPage_${category}s_software`, routeConfiguration(), {software: software}, queryParams))
    :
    category ?
    history.push(createResourceLocatorString(`SearchPage_${category}s`, routeConfiguration(), {}, queryParams))
    :
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleSortBy(urlParam, sort) {
    const { urlQueryParams, history, category, software } = this.props;
    const queryParams = urlParam
      ? { ...urlQueryParams, [urlParam]: sort }
      : omit(urlQueryParams, urlParam);

    software ?
    history.push(createResourceLocatorString(`SearchPage_${category}_software`, routeConfiguration(), {software: software}, queryParams))
    :
    category ?
    history.push(createResourceLocatorString(`SearchPage_${category}`, routeConfiguration(), {}, queryParams))
    :
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleDateRangeLength(values) {
    const { urlQueryParams, history, dateRangeLengthFilter } = this.props;

    const hasDates = values && values[dateRangeLengthFilter.paramName];
    const { startDate, endDate } = hasDates ? values[dateRangeLengthFilter.paramName] : {};
    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;
    const minDuration =
      hasDates && values && values[dateRangeLengthFilter.minDurationParamName]
        ? values[dateRangeLengthFilter.minDurationParamName]
        : null;

    const restParams = omit(
      urlQueryParams,
      dateRangeLengthFilter.paramName,
      dateRangeLengthFilter.minDurationParamName
    );

    const datesMaybe =
      start != null && end != null ? { [dateRangeLengthFilter.paramName]: `${start},${end}` } : {};
    const minDurationMaybe = minDuration
      ? { [dateRangeLengthFilter.minDurationParamName]: minDuration }
      : {};

    const queryParams = {
      ...datesMaybe,
      ...minDurationMaybe,
      ...restParams,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  // Reset all filter query parameters except pub_category
  resetAll(e) {
    const { history, category } = this.props;
    category ?
    history.push(createResourceLocatorString(`SearchPage_${category}s`, routeConfiguration(), {}))
    :
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}));
    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  // resolve initial value for a single value filter
  initialValue(paramName) {
    return this.props.urlQueryParams[paramName];
  }

  // resolve initial values for a multi value filter
  initialValues(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    return !!urlQueryParams[paramName] ? urlQueryParams[paramName].split(',') : [];
  }

  initialPriceRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const price = urlQueryParams[paramName];
    const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

    return !!price && valuesFromParams.length === 2
      ? {
          minPrice: valuesFromParams[0],
          maxPrice: valuesFromParams[1],
        }
      : null;
  }

  initialDateRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const dates = urlQueryParams[paramName];
    const rawValuesFromParams = !!dates ? dates.split(',') : [];
    const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
    const initialValues =
      !!dates && valuesFromParams.length === 2
        ? {
            dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
          }
        : { dates: null };

    return initialValues;
  }

  render() {
    const {
      rootClassName,
      className,
      urlQueryParams,
      sort,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      showAsModalMaxWidth,
      onMapIconClick,
      onManageDisableScrolling,
      selectedFiltersCount,
      yogaStylesFilter,
      skillsFilter,
      experienceFilter,
      projectsCountFilter,
      category,
      software,
      priceFilter,
      keywordFilter,
      languageFilter,
      intl,
      history
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const resultsFound = (
      <FormattedMessage id="SearchFilters.foundResults" values={{ count: resultsCount }} />
    );
    const noResults = <FormattedMessage id="SearchFilters.noResultsMobile" />;
    const loadingResults = <FormattedMessage id="SearchFilters.loadingResultsMobile" />;
    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });

    const showListingsLabel = intl.formatMessage(
      { id: 'SearchFiltersMobile.showListings' },
      { count: resultsCount }
    );

    const isExpert = category === LISTING_CATEGORY_EXPERT;
    const isJobListing = category === LISTING_CATEGORY_JOB;

    const filtersButtonClasses =
      selectedFiltersCount > 0 ? css.filtersButtonSelected : css.filtersButton;

    const softwareStylesLabel = intl.formatMessage({ id: 'SearchFiltersMobile.softwareLabel' });

    const initialyogaStyles =  software ? [software] : this.initialValues(yogaStylesFilter.paramName);

    const softwareStylesFilterElement = yogaStylesFilter ? (
      <SelectMultipleFilter
        id="SearchFiltersMobile.yogaStylesFilter"
        name="yogaStyles"
        urlParam={yogaStylesFilter.paramName}
        label={softwareStylesLabel}
        onSubmit={this.handleSoftware}
        liveEdit
        options={yogaStylesFilter.options}
        initialValues={initialyogaStyles}
      />
    ) : null;

    const skillsLabel = intl.formatMessage({ id: 'SearchFiltersMobile.skillsLabel' });

    const initialskills = this.initialValues(skillsFilter.paramName);

    const skillsFilterElement = skillsFilter ? (
      <SelectMultipleFilter
        id="SearchFiltersMobile.skillsFilter"
        name="skills"
        urlParam={skillsFilter.paramName}
        label={skillsLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={skillsFilter.options}
        initialValues={initialskills}
      />
    ) : null;

    const languagesLabel = intl.formatMessage({
      id: "SearchFilters.languagesLabel",
    });

    const initialLanguageFunc = (queryParams, paramName) => {
      const language = queryParams[paramName];
      const valuesFromParams = !!language ? language.split(',') : [];
      return valuesFromParams
    };

    const initialLanguage = languageFilter
    ? initialLanguageFunc(urlQueryParams, languageFilter.paramName)
    : null;

    const languageFilterElement = isExpert ? (
      <LanguageFilter
          id={'SearchFilters.experienceFilter'}
          name="language"
          urlParam={languageFilter.paramName}
          label={languagesLabel}
          onSubmit={this.handleKeyword}
          options={languageFilter.config.options}
          initialValues={initialLanguage}
       />
      ) : null;

      const initialNumbersFunc = (queryParams, paramName) => {
        const projectsCount = queryParams[paramName];
        const valuesFromParams = !!projectsCount ? projectsCount.split(',').map(v => Number.parseInt(v, RADIX)) : [];
    
        return !!projectsCount && valuesFromParams.length === 2
          ? {
            minNumber: valuesFromParams[0],
            maxNumber: valuesFromParams[1],
          }
          : null;
      };

    const changeState = () => {
      const queryParams = {
        ...urlQueryParams,
        state: !urlQueryParams.state ? ['awaiting', 'closed'] : null,
      }
      software ?
      history.push(createResourceLocatorString(`SearchPage_${category}s_software`, routeConfiguration(), {software: software}, queryParams))
      :
      category ?
      history.push(createResourceLocatorString(`SearchPage_${category}s`, routeConfiguration(), {}, queryParams))
      :
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams))
    };

    const initialExperience = experienceFilter
    ? initialNumbersFunc(urlQueryParams, experienceFilter.paramName)
    : null;

    const experienceFilterElement = (experienceFilter && isExpert) ? (
      <RangeFilter
        id={'SearchFilters.experienceFilter'}
        name="experience"
        urlParam={experienceFilter.paramName}
        label={"Experience"}
        onSubmit={this.handleNumber}
        {...experienceFilter.config}
        options={experienceFilter.config.options}
        initialValues={initialExperience}
      />
    ) : null;

    const initialProjectsCount = projectsCountFilter
    ? initialNumbersFunc(urlQueryParams, projectsCountFilter.paramName)
    : null;

    const projectsCountFilterElement = (projectsCountFilter && isExpert) ? (
      <RangeFilter
      id={'SearchFilters.projectsCountFilter'}
      name="projectsCount"
      urlParam={projectsCountFilter.paramName}
      label="Projects count"
      onSubmit={this.handleNumber}
      {...projectsCountFilter.config}
      options={projectsCountFilter.config.options}
      initialValues={initialProjectsCount}
    />
    ) : null;

    const initialPriceRange = this.initialPriceRangeValue(priceFilter.paramName);

    const priceFilterElement =
      priceFilter && !isExpert ? (
        <PriceFilter
          id="SearchFiltersMobile.priceFilter"
          urlParam={priceFilter.paramName}
          onSubmit={this.handlePrice}
          liveEdit
          {...priceFilter.config}
          initialValues={initialPriceRange}
          category={category}
        />
      ) : null;

    const changeStateButton = isJobListing ?
      <Button
        className={urlQueryParams.state?.includes('closed') ? css.filtersButtonSelected : css.filtersButton}
        onClick={changeState}
      >
        <FormattedMessage id="SearchFilters.closedJobs" />
      </Button> : null;

    const initialKeyword = this.initialValue(keywordFilter.paramName);
    const keywordLabel = intl.formatMessage({
      id: 'SearchFiltersMobile.keywordLabel',
    });
    const keywordFilterElement =
      keywordFilter && keywordFilter.config.active ? (
        <KeywordFilter
          id={'SearchFiltersMobile.keywordFilter'}
          name="keyword"
          urlParam={keywordFilter.paramName}
          label={keywordLabel}
          onSubmit={this.handleKeyword}
          liveEdit
          showAsPopup={false}
          initialValues={initialKeyword}
        />
      ) : null;

    const isKeywordFilterActive = !!initialKeyword;

    const sortBy = config.custom.sortConfig.active ? (
      <SortBy
        rootClassName={css.sortBy}
        menuLabelRootClassName={css.sortByMenuLabel}
        sort={sort}
        showAsPopup
        isKeywordFilterActive={isKeywordFilterActive}
        onSelect={this.handleSortBy}
      />
    ) : null;

   
    return (

         <div className={classes}>
        <div className={css.searchResultSummary}>
          {listingsAreLoaded && resultsCount > 0 ? resultsFound : null}
          {listingsAreLoaded && resultsCount === 0 ? noResults : null}
          {searchInProgress ? loadingResults : null}
        </div>
        <>
          {/* <div className={css.buttons}>
            <Button rootClassName={filtersButtonClasses} onClick={this.openFilters}>
              <FormattedMessage id="SearchFilters.filtersButtonLabel" className={css.mapIconText} />
            </Button>
            {sortBy}
            <Button rootClassName={filtersButtonClasses} onClick={e => this.resetAll(null)}>
              <FormattedMessage id={'SearchFiltersPanel.resetAllMobile'} />
            </Button>
          </div> */}
          <div className={css.buttons}>

            {changeStateButton}

            {/* {isExpert || isJobListing ? <div className={css.mapIcon} onClick={onMapIconClick}>
              <FormattedMessage id="SearchFilters.openMapView" className={css.mapIconText} />
            </div> : null} */}
          </div>
          </>
        
        <ModalInMobile
          id="SearchFiltersMobile.filters"
          isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
          onClose={this.cancelFilters}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          closeButtonMessage={modalCloseButtonMessage}
        >
          <div className={css.modalHeadingWrapper}>
            <span className={css.modalHeading}>{filtersHeading}</span>
            <button className={css.resetAllButton} onClick={e => this.resetAll(e)}>
              <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
            </button>
          </div>
          {this.state.isFiltersOpenOnMobile ? (
            <div className={css.filtersWrapper}>
              {priceFilterElement}
              {/* {paidFilterElement} */}
              {languageFilterElement}
              {keywordFilterElement}
              {experienceFilterElement}
              {projectsCountFilterElement}
              {softwareStylesFilterElement}
              {skillsFilterElement}
            </div>
          ) : null}

          <div className={css.showListingsContainer}>
            <Button className={css.showListingsButton} onClick={this.closeFilters}>
              {showListingsLabel}
            </Button>
          </div>
        </ModalInMobile>
      </div>
    );
          }}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sort: null,
  resultsCount: null,
  searchingInProgress: false,
  selectedFiltersCount: 0,
  filterParamNames: [],
  certificateFilter: null,
  yogaStylesFilter: null,
  skillsFilter: null,
  priceFilter: null,
  dateRangeLengthFilter: null,
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  sort: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onMapIconClick: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  selectedFiltersCount: number,
  filterParamNames: array,
  certificateFilter: propTypes.filterConfig,
  yogaStylesFilter: propTypes.filterConfig,
  skillsFilter: propTypes.filterConfig,
  priceFilter: propTypes.filterConfig,
  dateRangeLengthFilter: propTypes.filterConfig,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersMobile = injectIntl(withRouter(SearchFiltersMobileComponent));

export default SearchFiltersMobile;
