import React from 'react';
import { bool, func, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { PaymentForm } from '../../forms';
import {
  updateExistingStripeCustomer,
  createNewStripeCustomer,
} from './PaymentMethodsPage.duck.js';
import { compareData, createUpdateBody } from '../../util/stripe';
import { Alert, AlertTitle } from '@material-ui/lab';

import css from './PaymentMethodsPage.css';

const PaymentMethodsPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    currentUserStripeAccounts,
    createStripeCustomerInProgress,
    onCreateStripeCustomer,
    onUpdateStripeCustomer,
  } = props;

  const title = intl.formatMessage({ id: 'PaymentMethodsPage.title' });

  if (!currentUser)
    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="PaymentMethodsPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
            <UserNav currentUser={currentUser} selectedPageName="PaymentMethodsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSettingsSideNav currentTab="PaymentMethodsPage" />
          <LayoutWrapperMain>
            <FormattedMessage id="PaymentMethodsPage.loadingData" />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );

  const { address, currency, tax_info, name } = currentUserStripeAccounts?.[0] ?? {};

  const { line1, line2, postal_code, ...restOfAddressInfo } = address ?? {};

  const initialValues = {
    addressLine1: line1,
    addressLine2: line2,
    postal: postal_code,
    currency: currency ?? 'usd',
    vatNumber: tax_info?.tax_id,
    name: name,
    ...restOfAddressInfo,
  };

  const defaultMessageId =
    currentUser?.stripeAccount || currentUserStripeAccounts
      ? 'PaymentMethodsPage.saveAccount'
      : 'PaymentMethodsPage.createAccount';
  const defaultMessage = intl.formatMessage({ id: defaultMessageId });

  // let viban=currentUser.attributes.profile.privateData.stripeViban;

  // if(Object.keys(viban).length === 0){

  // }

  let viban = false;

  const handleChangeStripeCustomerData = async values => {
    if (!createStripeCustomerInProgress) {
      let stripeAccount = currentUserStripeAccounts ?? null;
      if (stripeAccount && !Array.isArray(stripeAccount)) {
        stripeAccount = [stripeAccount.id];
      }

      let currentUserMatchingAccount = stripeAccount?.find(
        user => user.currency === (values.currency ?? 'usd')
      );

      if (currentUserMatchingAccount) {
        if (compareData(currentUserMatchingAccount, currentUser, values)) {
          currentUserMatchingAccount = await onUpdateStripeCustomer({
            customer: currentUserMatchingAccount.id,
            ...createUpdateBody(values, currentUser),
          });
          if (stripeAccount.length > 1) {
            const secondAccount = stripeAccount?.find(
              user => user.currency !== (values.currency ?? 'usd')
            );
            await onUpdateStripeCustomer({
              customer: secondAccount.id,
              ...createUpdateBody(values, currentUser),
            });
          }
        }
      } else {
        const body = createUpdateBody(values, currentUser);
        onCreateStripeCustomer(body, stripeAccount);
      }
    }
  };

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="PaymentMethodsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav currentUser={currentUser} selectedPageName="PaymentMethodsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="PaymentMethodsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="PaymentMethodsPage.heading" />
            </h1>

            {/* wyświetlanie inforamcja o VIBAN */}
            {viban && (
              <>
                <Alert severity="info">
                  <AlertTitle>{viban.bank_name}</AlertTitle>
                  <div>
                    <strong>IBAN </strong> {viban.account_number}
                  </div>
                  <div>
                    <strong>SWIFT </strong> {viban.swift_code}
                  </div>
                </Alert>
                <p></p>
              </>
            )}

            <PaymentForm
              createStripeCustomerInProgress={createStripeCustomerInProgress}
              initialValues={initialValues}
              onSubmit={handleChangeStripeCustomerData}
              defaultMessage={defaultMessage}
              isPaymentsMethodPage
            />
          </div>
        </LayoutWrapperMain>
      </LayoutSideNavigation>
    </Page>
  );
};

PaymentMethodsPageComponent.defaultProps = {
  currentUser: null,
  addPaymentMethodError: null,
  deletePaymentMethodError: null,
  createStripeCustomerError: null,
  handleCardSetupError: null,
};

PaymentMethodsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  addPaymentMethodError: object,
  deletePaymentMethodError: object,
  createStripeCustomerError: object,
  handleCardSetupError: object,
  onCreateSetupIntent: func.isRequired,
  onHandleCardSetup: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onDeletePaymentMethod: func.isRequired,
  fetchStripeCustomer: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserStripeAccounts } = state.user;

  const { createStripeCustomerInProgress } = state.PaymentMethodsPage;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserStripeAccounts,
    createStripeCustomerInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onCreateStripeCustomer: (body, stripeAccount) =>
    dispatch(createNewStripeCustomer(body, stripeAccount)),
  onUpdateStripeCustomer: body => dispatch(updateExistingStripeCustomer(body)),
});

const PaymentMethodsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PaymentMethodsPageComponent);

export default PaymentMethodsPage;
