import React from 'react';
import { richText } from '../../util/richText';

import css from './ListingCard.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionExpertiseMaybe = props => {
  const { expertise } = props;
  
  return expertise ? (
    <div className={css.sectionExpertise}>
      <p className={css.expertise}>
        {richText(expertise, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
};

export default SectionExpertiseMaybe;