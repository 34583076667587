import React from 'react';
import css from './PanelExpertPage.css';
import classNames from 'classnames';

import { EXPERT_DATA } from './PanelExpertData';
import { compose } from 'redux';
import { connect } from 'react-redux';

const PanelExpertConent = (props) => {

    const { pageNumber, fadeIn, fadeOut, fadeDirection} = props;

    const data = EXPERT_DATA[pageNumber];
    const logo = './ongoing-images/'+data.logoUrl;

    const contentWrapper = classNames(css.contentWrapper, 
        fadeDirection === 'left' ? 
            fadeIn ? css.contentLeftOpen : fadeOut ? css.contentLeftClosed : null
        : fadeDirection === 'right' ? 
            fadeIn ? css.contentRightOpen : fadeOut ? css.contentRightClosed : null
        : null);
    return (
      <div className={contentWrapper}>
        <div className={css.firstRow}>
          <div className={css.jobLogoWrapper}>
            <img src={require('' + logo).default} className={css.jobLogoImage} alt="Project" />
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = state => {
    const {
      productSoftware
    } = state.marketplaceData;
    return {
      productSoftware
    }
  }
  
  export default compose(
    connect(mapStateToProps)
  )(PanelExpertConent);