import React from 'react';
import { TiTick } from 'react-icons/ti';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { SecondaryButton } from '../../components';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import css from './SubscriptionPanel.css';

export const PricingCard = props => {
  const {
    title,
    description,
    features,
    buttonMessage,
    onSubmit,
    isSupport,
    isTask,
    isBasic,
    isStarter,
    isServices,
    isNewProjectPage,
  } = props;

  const ticketsOrder = isServices ? 1 : 5;

  const featuresFirstPart = features?.slice(0, ticketsOrder).map((feature, index) => {
    return (
      <span key={`basicFeature${index}`} className={css.features}>
        <TiTick className={css.tickIcon} /> {feature}
      </span>
    );
  });

  const featuresSecondPart = features?.slice(ticketsOrder).map((feature, index) => {
    return (
      <span key={`basicFeature${index}`} className={css.features}>
        <TiTick className={css.tickIcon} /> {feature}
      </span>
    );
  });

  const ticketsFeature = (
    <span className={css.features}>
      <TiTick className={css.tickIcon} />
      <div>
        <FormattedMessage id="PricingCard.ticketsFeatures" values={{ br: <br /> }} />
      </div>
    </span>
  );

  const expertFeature = (
    <span className={css.features}>
      <TiTick className={css.tickIcon} />
      <div>
        <FormattedMessage id="PricingCard.expertFeatures" values={{ br: <br /> }} />
      </div>
    </span>
  );

  const platformFeature = (
    <span className={css.features}>
      <TiTick className={css.tickIcon} />
      <div>
        <FormattedMessage id="PricingCard.platformFeatures" values={{ br: <br /> }} />
      </div>
    </span>
  );

  const buttonContent =
    isSupport || isBasic || isStarter || isTask ? (
      <Tooltip
        title={
          <span className={css.tooltipMessage}>
            <FormattedMessage id="ServicePage.netPriceInfo" />
          </span>
        }
      >
        <div>
          {buttonMessage}
          <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
        </div>
      </Tooltip>
    ) : (
      buttonMessage
    );
  const containerClassNames=classNames(css.fieldContainer, isBasic && css.fieldContainerPopular, isTask && css.fieldContainerBig)
  return (
      <div className={containerClassNames}>
        {isBasic && <span className={css.mostPopular}>Most popular</span>}
        <div className={css.showMore}>
          <p
            className={classNames(isNewProjectPage ? css.versionTitleSmallFont : css.versionTitle)}
          >
            {title}
          </p>
          <p className={css.priceText}>{description}</p>
          <SecondaryButton
            onClick={onSubmit}
            className={css.submitButton}
            name="version"
            value="basic"
            type="submit"
          >
            {buttonContent}
          </SecondaryButton>
          <div className={css.featuresContainer}>
            <div className={css.featuresColumn}>
              {expertFeature}
              {featuresFirstPart}
            </div>
            <div className={css.featuresColumn}>
              {platformFeature}
              {ticketsFeature}
              {featuresSecondPart}
            </div>
          </div>
        </div>
      </div>
  );
};
