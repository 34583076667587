/* eslint-disable */
import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { setHttp } from '../../util/urlHelpers';
import { richText } from '../../util/richText';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, jobDetailsLink, isJobListing } = props;
  // const text = richText(description, {
  //       longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
  //       longWordClass: css.longWord,
  //       linkify: true,
  //       mailify: true})

  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        {isJobListing ? (
          <FormattedMessage id="ListingPage.descriptionTitle" />
        ) : (
          <FormattedMessage id="ExpertPage.descriptionTitle" />
        )}
      </h2>
      {/* {text.map(line => (
        <p className={css.description}>{line} </p>
      ))} */}

      <p className={css.description}>{description} </p>
      <p className={css.description}>
        {jobDetailsLink ? (
          <>
            {jobDetailsLink.split('\n').map((link, index) => {
              return (
                <React.Fragment key={`${index}${link}`}>
                  <br />
                  <a href={setHttp(link)} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage
                      id="ListingPage.jobLinkDetails"
                      values={{ index: index + 1 }}
                    />
                  </a>
                </React.Fragment>
              );
            })}
          </>
        ) : null}
      </p>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
