import pick from 'lodash/pick';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_CATEGORY_EXPERT } from '../../util/types';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const LOAD_EXPERT_SUCCESS = 'app/ListingPage/LOAD_EXPERT_SUCCESS';
export const LOAD_EXPERT_ERROR = 'app/ListingPage/LOAD_EXPERT_ERROR';
export const LOADING_EXPERT = 'app/ListingPage/LOADING_EXPERT';

export const FETCH_OFFERS_REQUEST = 'app/ExpertPage/FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'app/ExpertPage/FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_ERROR = 'app/ExpertPage/FETCH_OFFERS_ERROR';

export const FETCH_EXPERTS_COUNT = 'app/ExpertPage/FETCH_EXPERTS_COUNT';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  id: null,
  fetchedExpertProfileForService: null,
  fetchedExpertServices: null,
  showListingError: null,
  expertLoaded: false,
  expert: null,
  expertLoadingInProgress: false,
  offers: [],
  offersLoading: false,
  fetchOffersError: null,
  enquiryModalOpenForListingId: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return { 
        ...state, 
        fetchedTicketAnswers: payload.data.answers,
        fetchedExpertProfileForService: payload.data.expertProfile,
        fetchedExpertServices: payload.data.expertServices
      };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case LOAD_EXPERT_SUCCESS: 
      return {...state, expertLoaded: true, expertLoadingInProgress: false, expert: payload}
    case LOAD_EXPERT_ERROR:   
      return {...state, expertLoaded: true, expertLoadingInProgress: false}
    case LOADING_EXPERT:   
      return {...state, expertLoadingInProgress: true}

    case FETCH_OFFERS_REQUEST:
      return { ...state, offersLoading: true, fetchOffersError: null };
    case FETCH_OFFERS_SUCCESS:
      return { ...state, offersLoading: false, offers: payload };
    case FETCH_OFFERS_ERROR:
      return { ...state, offersLoading: false, fetchOffersError: payload };

    case FETCH_EXPERTS_COUNT: 
    return { ...state, allExperts: payload.allExperts, softwareExperts: payload.softwareExperts}

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = (data) => ({
  type: SHOW_LISTING_SUCCESS,
  payload: {data},
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const expertLoadingError = () => ({ type: LOAD_EXPERT_ERROR });
export const expertLoadingSuccess = payload => ({ type: LOAD_EXPERT_SUCCESS, payload: payload });
export const expertLoadingInProgress = () => ({ type: LOADING_EXPERT });

export const fetchOffersRequest = () => ({ type: FETCH_OFFERS_REQUEST });
export const fetchOffersSuccess = payload => ({ type: FETCH_OFFERS_SUCCESS, payload: payload });
export const fetchOffersError = error => ({
  type: FETCH_OFFERS_ERROR,
  error: true,
  payload: error,
});

export const fetchExpertsCount = (allExperts, softwareExperts) => ({
  type: FETCH_EXPERTS_COUNT,
  payload: { allExperts, softwareExperts }
})

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const listing = data.data.data;
      const answers = listing.attributes.publicData.answers;
      const primarySoftware = listing.attributes.publicData.primarySoftware;

      if (listing.attributes.state === LISTING_STATE_PENDING_APPROVAL) {
        sdk.listings.query({ pub_category: LISTING_CATEGORY_EXPERT, states: ['published'] })
          .then(allExperts => { 
            sdk.listings.query({pub_softwares: primarySoftware, pub_category: LISTING_CATEGORY_EXPERT, states: ['published'] })
            .then(specializedExperts => { 
              dispatch(fetchExpertsCount(
                allExperts.data.meta.totalItems,
                specializedExperts.data.meta.totalItems
              ))
            })
          })
      }

      dispatch(showListingSuccess({
        answers: answers, 
        expertProfile: null, 
        expertServices: null,
      }));
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const showUser = (userId) => (dispatch, getState, sdk) => {
  return sdk.users.show({
    id: userId,
    include: ['profileImage'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
    ],
  })
    .then(data => {
      return data;
    })
    .catch(e => {
    });
}

export const fetchConnectedTransaction = listingId => (dispatch, getState, sdk) => {
  dispatch(expertLoadingInProgress())
  return sdk.transactions
    .show({ id: listingId, include: ['listing'] })
    .then(response => {
      dispatch(expertLoadingSuccess(response.data.included[0].id.uuid))
    }
    )
    .catch(e =>
      dispatch(expertLoadingError())
    )
}

export const fetchOffers = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchOffersRequest());
  return sdk.transactions
    .query({
      lastTransitions: ["transition/send-offer", "transition/accept-offer", "transition/accept-offer-admin"],
      include: ['author', 'author.profileImage', 'listing', 'customer', 'customer.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const offers = denormalisedResponseEntities(response);
      const filteredOffers = offers.filter((element, index, array) => {
        return element.listing.id.uuid === listingId.uuid}
      )
      dispatch(fetchOffersSuccess(filteredOffers));
    })
    .catch(e => {
      dispatch(fetchOffersError(storableError(e)));
    });
};

export const showMessageOwner = (listingId) => (dispatch, getState, sdk) => {
  const params = {
    id: listingId,
    include: ['author.profileImage'],
    'fields.image': [
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = sdk.listings.show(params);

  return show
    .then(data => {
      return data;
    })
    .catch(e => {
    });
};

export const loadData = (params, search, pathname) => dispatch => {
  const listingId = new UUID(params.id)

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return Promise.all([dispatch(showListing(listingId)), dispatch(fetchOffers(listingId))])
};
