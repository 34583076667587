import React, { Component } from 'react';
import { func, shape, array } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';

import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { AiOutlineSave } from 'react-icons/ai';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { reportTimeFormat } from '../../util/validators'
import {

  Form,
  FieldTextInput,
  SecondaryButton,
} from '../../components';

import css from './EditTicketForm.css';



export class EditReportTimeComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      imageUploadRequested: false,
    };

    this.submittedImages = [];
  }

  render() {
    return (
      <FinalForm
        {...this.props}

        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
 
            ready,
            rootClassName,
            className,
            handleSubmit,
            pristine,
            updated,

            fetchErrors,
            intl,
            ticketUpdateInProgress

          } = formRenderProps;

          // if (!isOwnListing && !userConnectedToTicket && !isNotOwnPrivateListing) {
          //   return <div className={css.noRightsToEdit}>
          //     <FormattedMessage id="EditTicketForm.noRightsToEdit" />
          //   </div>
          // }
          const classes = classNames(rootClassName || css.root, className);
          
          const submitReady = (updated && pristine) || ready;
       
          


          const reportTimeFormatValidator = reportTimeFormat(
            intl.formatMessage({
              id: 'EditTicketForm.completionHoursErrorFormat',
            })
          );


          const { updateListingError, showListingsError } = fetchErrors || {};
          const errorMessage = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          return (
            <Form className={classes} onSubmit={handleSubmit}>

              {errorMessage}
              {errorMessageShowListing}
              <FieldTextInput
                className={css.hours}
                name={`hours`}
                id={`hours`}
                key={`hours`}
                type="textarea"
                validate={reportTimeFormatValidator}

                placeholder={intl.formatMessage({ id: `EditTicketForm.completionHoursPlaceholder` })}
              />
              { <SecondaryButton
                className={css.submitWorkTimeButton}
                type="submit"
                inProgress={ticketUpdateInProgress}
                // disabled={submitDisabled}
                ready={submitReady}
              >
                <AiOutlineSave />
              </SecondaryButton>}

            </Form>
          );
        }}
      />);
  }
}

EditReportTimeComponent.defaultProps = { fetchErrors: null, images: [] };

EditReportTimeComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditReportTimeComponent);