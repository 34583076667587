import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { 
  IoIosArrowDropright, 
  IoIosArrowDropleft, 
  IoIosArrowDropup,
  IoIosArrowDropdown
} from 'react-icons/io';
import {
  AiOutlineHome,
  AiOutlineBell,
  AiOutlineMessage,
  AiOutlineCalendar
} from 'react-icons/ai';
import {
  GrWorkshop,
  GrUserExpert,
  GrUserAdmin
} from 'react-icons/gr';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_DRAFT, LISTING_STATE_CLOSED } from '../../util/types';
import { LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT } from '../../util/urlHelpers';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from '../../util/reactIntl';
import { TabNav, NamedLink } from '../../components';

import css from './Sidebar.css';

const MAX_MOBILE_SCREEN_WIDTH = 1023;

const SidebarComponent = props => {
  const { tab, isAdmin, isExpert, isPendingApprovalExpert, isVisible, setVisibility, currentUserExpertListing, hasUnreadNotifications } = props;

  const [icon1Hovered, setIcon1Hovered] = useState(false);
  const toggleIcon1Hover = () => setIcon1Hovered(!icon1Hovered);

  const [icon2Hovered, setIcon2Hovered] = useState(false);
  const toggleIcon2Hover = () => setIcon2Hovered(!icon2Hovered);

  // checking if mobile
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  let isMobile = (width <= MAX_MOBILE_SCREEN_WIDTH);

  const isGetStarted = tab === 'getStarted';
  //const isNotifications = tab === 'sales' || tab === 'salesExpert' || tab === 'salesAdmin';

  // PROJECTS
  const isProjectsPosted = tab === 'projects-posted';
  const isProjectsOngoing = tab === 'projects-ongoing';
  const isProjectsCompleted = tab  === 'projects-completed';
  const isProjects = isProjectsPosted || isProjectsOngoing || isProjectsCompleted;

  const isNotifications = tab === 'notifications';
  const isMessage = tab === 'messages';
  const isMeeting = tab === 'meetings';

  // EXPERT PANEL
  const isExpertListing = tab === 'expert';
  const isServices = tab === 'services';
  const isOrders = tab === 'orders' || tab === 'ordersAdmin';
  const isExamsList = tab === 'examsList';
  const isExamPage = tab === 'examPage';
  const isExpertPanel = isExpertListing || isServices || isOrders || isExamsList || isExamPage;
  const isExamsAdmin = tab === 'examsAdmin';
  const isAdminBulkMessage = tab === 'adminBulkMessage';

  const isApprovals = tab === 'approvals';
  const isProductItemsApprovals = tab === 'itemApprovals';

  const salesTabVariant = isAdmin ? 'salesAdmin' : isExpert ? 'salesExpert' : 'sales';

  let expertListingVariant = '';
  if (currentUserExpertListing?.attributes.state === LISTING_STATE_PENDING_APPROVAL) {
    expertListingVariant = LISTING_PAGE_PENDING_APPROVAL_VARIANT
  } else if (currentUserExpertListing?.attributes.state === LISTING_STATE_DRAFT) {
    expertListingVariant = LISTING_PAGE_DRAFT_VARIANT
  } else if (currentUserExpertListing?.attributes.state === LISTING_STATE_CLOSED ) {
    expertListingVariant = LISTING_PAGE_PENDING_APPROVAL_VARIANT
  }

  const tabsGetStartedNotifications = [
    {
      text: (
        <span>
          <FormattedMessage 
            id="CenterPage.getStarted" 
            values={{icon: <AiOutlineHome className={css.icon}/>}} 
          />
        </span>
      ),
      selected: isGetStarted,
      linkProps: {
        name: 'CenterPage',
        params: { tab: 'getStarted' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage 
            id={"CenterPage.offers"}
            values={{
              icon: <AiOutlineBell className={css.icon} />,
              notificationDot: hasUnreadNotifications ? <span className={css.notificationDot}>•</span> : null
            }} 
          />
        </span>
      ),
      selected: isNotifications,
      linkProps: {
        name: 'NotificationsPage',
        params: { tab: 'notifications' },
      },
    },
  ];

  const tabsProjects = [
    {
      text: (
        <span>
          <FormattedMessage
            id="CenterPage.projects"
            values={{ icon: <GrWorkshop className={css.icon} /> }}
          />
        </span>
      ),
      selected: isProjectsPosted,
      linkProps: {
        name: 'ManageListingsPage',
        params: { tab: 'projects-posted', page: 1 },
      },
    },
  ];

  const tabsMessagesMeetings = [

    {
      text: (
        <span>
          <FormattedMessage
            id={'CenterPage.messages'}
            values={{ icon: <AiOutlineMessage className={css.icon} /> }}
          />
        </span>
      ),
      selected: isMessage,
      linkProps: {
        name: 'MessagesPage',
        // name: 'CenterPage', //archive
        // params: { tab: 'messages' }, //archive
      },
    },
    {
      text: (
        <span>
          <FormattedMessage
            id={'CenterPage.meetings'}
            values={{ icon: <AiOutlineCalendar className={css.icon} /> }}
          />
        </span>
      ),
      selected: isMeeting,
      linkProps: {
        name: 'CenterPage',
        params: { tab: 'meetings' },
      },
    },
  ];

  const tabsExpertPanel = [
    {
      text: (
        <span>
          <FormattedMessage id="ManageListingsPage.expertTabTitle" />
        </span>
      ),
      selected: isExpertListing,
      linkProps: {
        name: isPendingApprovalExpert ? 'ListingPageVariant' : 'ExpertPage',
        params: {
          id: currentUserExpertListing?.id.uuid,
          slug: currentUserExpertListing?.attributes.title,
          category: 'experts',
          variant: expertListingVariant
        }
      },
    },
    // {
    //   text: (
    //     <span>
    //       <FormattedMessage id="ManageListingsPage.servicesTabTitle" />
    //     </span>
    //   ),
    //   selected: isServices,
    //   linkProps: {
    //     name: 'ManageListingsPage',
    //     params: { tab: 'services', page: 1 }
    //   },
    // },
    {
      text: (
        <span>
          <FormattedMessage id={"CenterPage.ordersTabTitle"} />
        </span>
      ),
      selected: isOrders,
      linkProps: {
        name: 'CenterPage',
        params: { tab: 'orders' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id={"CenterPage.examsListTabTitle"} />
        </span>
      ),
      selected: (isExamsList || isExamPage),
      linkProps: {
        name: 'ExamsListPage',
        params: { tab: 'examsList' },
      },
    },
  ];

  const tabsAdminPanel = [
    {
      text: (
        <span>
          <FormattedMessage 
            id={"ManageListingsPage.adminNewInbox"}
          />
        </span>
      ),
      selected: isMessage,
      linkProps: {
        name: 'MessagesPage'
      },
    },
    {
      text: (
        <span>
          <FormattedMessage 
            id={"ManageListingsPage.approvalsTabTitle"} 
            values={{icon: <GrUserAdmin className={css.icon} />}}
          />
        </span>
      ),
      selected: isApprovals,
      linkProps: {
        name: 'ManageListingsPage',
        params: { tab: 'approvals', page: 1 },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage 
            id={"ManageListingsPage.productItemsApprovalsTabTitle"} 
            values={{icon: <GrUserAdmin className={css.icon} />}}
          />
        </span>
      ),
      selected: isProductItemsApprovals,
      linkProps: {
        name: 'ProductItemsApprovalPage',
        params: { tab: 'approvals' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage
            id={"CenterPage.salesTabTitleAdmin"}
          />
        </span>
      ),
      selected: isNotifications,
      linkProps: {
        name: 'CenterPage',
        params: { tab: salesTabVariant },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id={"CenterPage.ordersTabTitleAdmin"} />
        </span>
      ),
      selected: isOrders,
      linkProps: {
        name: 'CenterPage',
        params: { tab: 'ordersAdmin' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id={"CenterPage.examsTabTitleAdmin"} />
        </span>
      ),
      selected: isExamsAdmin,
      linkProps: {
        name: 'ExamsAdminPage',
        params: { tab: 'examsAdmin' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id={"CenterPage.adminBulkMessageTabTitle"} />
        </span>
      ),
      selected: isAdminBulkMessage,
      linkProps: {
        name: 'AdminBulkMessagePage',
        params: { tab: 'adminBulkMessage' },
      },
    },
  ]


  // const navGetStarted = (
  //   <div className={css.tabs}>
  //     <NamedLink name='CenterPage' params={{ tab: 'getStarted'}}><FormattedMessage id="CenterPage.getStarted"/></NamedLink>
  //   </div>
  // );

  // const navNotifications = (
  //   <div className={css.tabs}>
  //     <NamedLink name='CenterPage' params={{ tab: 'sales'}}><FormattedMessage id="CenterPage.offers"/></NamedLink>
  //   </div>
  // );


  const navGetStartedNotifications = (
    <TabNav
      rootClassName={css.tabs}
      tabRootClassName={css.tab}
      tabs={tabsGetStartedNotifications}
      // centerTab
    />
  );

  const navProjects = (
    <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabsProjects} />
  );

  const navMessagesMeetings = (
    <TabNav
      rootClassName={css.tabs}
      tabRootClassName={css.tab}
      tabs={tabsMessagesMeetings}
      // centerTab
    />
  );

  const navExpertPanel = (
    <TabNav
      rootClassName={css.projectsTabs}
      tabRootClassName={css.tab}
      tabs={tabsExpertPanel}
      label={
        <FormattedMessage 
          id="CenterPage.expertPanel" 
          values={{icon: <GrUserExpert className={css.icon} />}}
        />
      }
      selectedLabel={isExpertListing || isServices || isOrders}
      centerTab
    />
  );

  const navAdminPanel = (
    <TabNav
      rootClassName={css.tabs}
      tabRootClassName={css.tab}
      tabs={tabsAdminPanel}
      label={
        <FormattedMessage 
          id="CenterPage.adminPanel" 
          values={{icon: <GrUserAdmin className={css.icon} />}}
        />
      }
      centerTab
    />
  );

  const sidebarTitle = (
    <p className={css.title}>
      <FormattedMessage id="Sidebar.title" />
    </p>
  );
    
  const HideIcon = React.forwardRef((props, ref) => 
    <div ref={ref}>
      {props.hide ?  
        <IoIosArrowDropleft  onClick={() => setVisibility(false)} /> 
        : 
        <IoIosArrowDropright onClick={() => setVisibility(true)} /> 
      }
    </div>
    )
  
  const hideIconDesktop = isVisible ? (
    <div className={css.hideIcon}>
      <Tooltip title="Hide" placement="top">
        <HideIcon hide={true} onClick={() => setVisibility(false)}/>
      </Tooltip>
    </div> 
  ) : (
    <div className={css.hideIcon}>
      <Tooltip title="Show" placement="top">
        <HideIcon hide={false} onClick={() => setVisibility(true)}/>
      </Tooltip>
    </div>
  );
  
  const hideIconMobile = isVisible ? (
    <div className={css.hideIcon}>
      <IoIosArrowDropup  onClick={() => setVisibility(false)} />
    </div>
    ) : (
    <div className={css.hideIcon}>
      <IoIosArrowDropdown  onClick={() => setVisibility(true)} /> 
    </div>
  );

  const iconSidebar = !isVisible && !isAdmin ? (
    <div className={css.iconSidebar}>
      <NamedLink 
        name='CenterPage' 
        params={{ tab: 'getStarted' }} 
        className={classNames(css.iconLink, isGetStarted && css.iconLinkActive)}
      >
        <AiOutlineHome className={css.iconVertical} />
      </NamedLink>

      <NamedLink 
        name='NotificationsPage' 
        params={{ tab: 'notifications' }} 
        className={classNames(css.iconLink, isNotifications && css.iconLinkActive)}
      >
        <AiOutlineBell className={css.iconVertical} />
      </NamedLink>
      
      {isExpert || isPendingApprovalExpert ?
        <GrUserExpert 
          onClick={() => {
            setVisibility(true);
            toggleIcon1Hover();
            }
          } 
          onMouseEnter={toggleIcon1Hover}
          onMouseLeave={toggleIcon1Hover}
          className={classNames(css.iconVertical, icon1Hovered && css.iconHovered, isExpertPanel && css.iconLinkActive)} />
      : null}
      
      <GrWorkshop 
        onClick={() => {
          setVisibility(true);
          toggleIcon2Hover();
          }
        } 
        onMouseEnter={toggleIcon2Hover}
        onMouseLeave={toggleIcon2Hover}
        className={classNames(css.iconVertical, icon2Hovered && css.iconHovered, isProjects && css.iconLinkActive)} 
      />

      <NamedLink 
        name={'MessagesPage'}
        // name={'CenterPage'} // archive
        // params={{ tab: 'messages' }} // archive
        className={classNames(css.iconLink, isMessage && css.iconLinkActive)}
      >
        <AiOutlineMessage className={css.iconVertical} />
      </NamedLink>
      
      <NamedLink 
        name='CenterPage' 
        params={{ tab: 'meetings' }} 
        className={classNames(css.iconLink, isMeeting && css.iconLinkActive)}
      >
        <AiOutlineCalendar className={css.iconVertical} />
      </NamedLink>
    </div>
  ) : null;

  return (
    <div className={css.layout}>
      
      {isMobile ? 
        <>
          {hideIconMobile}
          {sidebarTitle}
        </> 
      : 
        <>
          {hideIconDesktop}
          {iconSidebar}
        </>
      }

      {isVisible && !isAdmin ?
        <>
          {navGetStartedNotifications}
          {isExpert || isPendingApprovalExpert ? navExpertPanel : null}
          {navProjects}
          {navMessagesMeetings}
        </>
      : isVisible ?
        <>
          {navProjects}
          {navAdminPanel}
        </>
      : null
      }
    </div>
  )
}

const mapStateToProps = state => {
  const {
    currentUserExpertListing,
    hasUnreadNotifications
  } = state.user;
  return {
    currentUserExpertListing,
    hasUnreadNotifications
  };
};

const Sidebar = compose(
  connect(mapStateToProps),
)(SidebarComponent);

export default Sidebar;
