import React, { useState, useEffect } from 'react';
// import { ImHammer2 } from 'react-icons/im';
import { PrimaryButton, NamedLink } from '../../components';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { formatDate } from '../../util/dates';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { AiOutlineInfoCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { acceptNegotiatedOffer } from '../../util/api';
import {
  sendDiscussionAcceptedByExpertMail,
  sendProjectNegotiationAcceptedByExpertMail,
} from '../../util/triggerMail';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import SectionScopeMaybe from '../../containers/ListingPage/SectionScopeMaybe';
import SectionPriceTimeMaybe from './SectionPriceTimeMaybe/SectionPriceTimeMaybe';

import css from './TransactionPanel.css';

const { Money } = sdkTypes;

const NegotiatedOfferDetailsComponent = props => {
  const {
    intl,
    currentTransaction,
    transactionRole,
    stateData,
    commission,
    scopeType,
    price,
  } = props;
  const { onEditAdminOfferScope, onSubmitRedirect } = props;
  const { isAdminOffer, estimatedTimeRange, estimatedPriceRange, currentUserIsAdmin } = props;
 

  const modPrice=1/(1-(commission ?? 0.5))*(price?.amount ?? 0)

  let hourlyRateExpert = 'Not specified';
  if (!!price) {
    const data  = new Money(modPrice, config.currency);
    hourlyRateExpert = formatMoney(intl, data);
  }


  const negotiationType =
    stateData.headingState === 'offer-sent'
      ? 'Offer'
      : stateData.headingState === 'service-discussed'
      ? 'Service'
      : undefined;
  // const negotiationButtonId = `TransactionPanel.negotiateButton${negotiationType}`;

  const [chosenDate, setChosenDate] = useState(null);
  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setShouldRenderReactSelect(true);
    }
  }, []);

  let negotiationReaderRole;
  if (negotiationType === 'Offer') {
    negotiationReaderRole =
      transactionRole === 'provider' ? 'client' : transactionRole === 'customer' ? 'expert' : null;
  } else if (negotiationType === 'Service') {
    negotiationReaderRole =
      transactionRole === 'provider' ? 'expert' : transactionRole === 'customer' ? 'client' : null;
  }

  // const withCommission = (price) => {
  //     return Math.trunc(price / (1-commission));
  // }

  const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const acceptOffer = (displayedOfferAuthor, negotiationReaderRole) => {
    let attributeName =
      negotiationReaderRole === 'client' && displayedOfferAuthor === 'client'
        ? 'isAcceptedByClient'
        : 'isAccepted';
    if (negotiationType === 'Service') {
      attributeName = 'isAcceptedByExpert';
    }
    const transactionId = currentTransaction.id.uuid;
    let negotiatedOfferId = !!chosenDate ? chosenDate.value - 1 : -1; // because on server it is stored without original (first) offer
    const body = {
      transactionId: transactionId,
      attributeName: attributeName,
      negotiatedOfferId: negotiatedOfferId,
    };
    const orderLink = createResourceLocatorString(
      'OrderDetailsPage',
      routeConfiguration(),
      { id: transactionId },
      {}
    );
    const saleLink = createResourceLocatorString(
      'SaleDetailsPage',
      routeConfiguration(),
      { id: transactionId },
      {}
    );
    if (negotiationType === 'Service' && negotiationReaderRole === 'expert') {
      sendDiscussionAcceptedByExpertMail(currentTransaction, orderLink);
    } else if (negotiationType === 'Offer' && negotiationReaderRole === 'expert') {
      sendProjectNegotiationAcceptedByExpertMail(currentTransaction, saleLink);
    }
    acceptNegotiatedOffer(body).then(res => {
      if (!isAdminOffer && negotiationReaderRole === 'client') {
        onSubmitRedirect(negotiatedOfferId);
      } else if (typeof window !== 'undefined') {
        window.location.reload();
        window.scrollTo(0, 0);
      }
    });
  };
  const termsLink = (
    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
      <FormattedMessage id="JobApplicationForm.termsOfUse" />
    </NamedLink>
  );
  const mapDatesToSelectOptions = dates => {
    return dates.map((date, index) => {
      return {
        value: index,
        label: date,
      };
    });
  };

  const handleSelectChange = value => {
    setChosenDate(value);
  };

  const isFirstOffer = !chosenDate || chosenDate.value === 0;
  // protectedData is maintained for older offers
  const originalOfferSrc =
    currentTransaction?.attributes?.protectedData?.scope !== undefined
      ? 'protectedData'
      : 'metadata';
  const originalOfferData =
    originalOfferSrc === 'protectedData'
      ? currentTransaction?.attributes?.protectedData
      : currentTransaction?.attributes?.metadata?.originalOffer;
  const serviceScope = originalOfferData?.scope;
  let formattedFinalScopePrice;
  if (!!currentTransaction?.attributes?.payinTotal) {
    formattedFinalScopePrice =
      currentTransaction?.attributes?.payinTotal instanceof Money
        ? formatMoney(intl, currentTransaction?.attributes?.payinTotal)
        : formatMoney(
            intl,
            new Money(currentTransaction?.attributes?.payinTotal.amount, config.currency)
          );
    formattedFinalScopePrice = formattedFinalScopePrice?.slice(
      0,
      formattedFinalScopePrice.length - 3
    ); // cut decimal part from price
  }
  const offerEstimatedTime = originalOfferData?.estimatedTime;
  const offerEstimatedPrice = originalOfferData?.price;
  const firstOfferAcceptedByExpert = originalOfferData?.isAcceptedByExpert;
  const negotiationHistory = currentTransaction?.attributes?.metadata?.negotiationHistory;
  // const lastNegotiationAnswerAuthor = !!negotiationHistory ? negotiationHistory[negotiationHistory.length-1].answerAuthor : null;
  const isOfferAcceptable =
    (stateData.headingState === 'offer-sent' &&
      isAdminOffer &&
      !!negotiationReaderRole &&
      !!negotiationHistory) ||
    (stateData.headingState === 'offer-sent' &&
      !isAdminOffer &&
      !!negotiationReaderRole &&
      (!!negotiationHistory || !!originalOfferData));

  const offerWasNegotiated = negotiationHistory?.length > 0;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  const firstOfferDate = new Date(currentTransaction.attributes.createdAt);
  const firstAnswerAuthor =
    negotiationType === 'Service' ? 'Client' : isAdminOffer ? 'SoftwareSupp' : 'Expert';
  const firstOfferDateFormatted =
    formatDate(intl, todayString, firstOfferDate) + ' - ' + firstAnswerAuthor + ' (First Offer)';
  let negotiationDates = offerWasNegotiated
    ? negotiationHistory.map(answer => {
        const answerAuthor =
          isAdminOffer && answer.answerAuthor === 'expert'
            ? 'SoftwareSupp'
            : capitalize(answer.answerAuthor);
        return formatDate(intl, todayString, new Date(answer.answerDate)) + ' - ' + answerAuthor;
      })
    : null;
  if (offerWasNegotiated) {
    negotiationDates = [firstOfferDateFormatted, ...negotiationDates];
  } else {
    negotiationDates = [firstOfferDateFormatted];
  }
  if (negotiationDates?.length > 1) {
    negotiationDates[negotiationDates.length - 1] =
      negotiationDates[negotiationDates.length - 1] + ' (Last Offer)';
  }
  if (offerWasNegotiated && chosenDate === null) {
    const lastDateValue = negotiationDates[negotiationDates.length - 1];
    setChosenDate({
      value: negotiationDates.length - 1,
      label: lastDateValue,
    });
  }
  if (shouldRenderReactSelect) {
    var { default: Select } = require('react-select');
  }

  let displayedServiceScope;
  let displayedFormattedFinalScopePrice;
  let displayedOfferEstimatedTime;
  let displayedOfferEstimatedPrice;
  let displayedOfferAuthor;
  let displayedAcceptanceState = true; // for first offer we assume it's accepted
  let displayedAcceptanceByClientState = true;
  let displayedAcceptanceByExpertState = false;
  if (!chosenDate || chosenDate?.value === 0) {
    displayedServiceScope = serviceScope;
    displayedFormattedFinalScopePrice = formattedFinalScopePrice;
    displayedOfferEstimatedTime = offerEstimatedTime;
    displayedOfferEstimatedPrice = offerEstimatedPrice;
    displayedOfferAuthor = 'expert';
    if (negotiationType === 'Service') {
      displayedOfferAuthor = 'client';
    }
    displayedAcceptanceByExpertState = firstOfferAcceptedByExpert;
  } else if (offerWasNegotiated) {
    displayedServiceScope = negotiationHistory[chosenDate.value - 1].scope;
    if (negotiationHistory[chosenDate.value - 1].answerAuthor === 'expert') {
      displayedFormattedFinalScopePrice = formatMoney(
        intl,
        new Money(negotiationHistory[chosenDate.value - 1].priceSummary * 100, config.currency)
      );
    } else {
      displayedFormattedFinalScopePrice = formatMoney(
        intl,
        new Money(negotiationHistory[chosenDate.value - 1].priceSummary * 100, config.currency)
      );
    }
    displayedFormattedFinalScopePrice = displayedFormattedFinalScopePrice?.slice(
      0,
      displayedFormattedFinalScopePrice.length - 3
    ); // cut decimal part from price
    displayedOfferEstimatedTime = negotiationHistory[chosenDate.value - 1].timeSummary;
    displayedOfferEstimatedPrice = negotiationHistory[chosenDate.value - 1].priceSummary;
    displayedOfferAuthor = negotiationHistory[chosenDate.value - 1].answerAuthor;
    displayedAcceptanceState = negotiationHistory[chosenDate.value - 1].isAccepted;
    displayedAcceptanceByClientState = negotiationHistory[chosenDate.value - 1].isAcceptedByClient;
    displayedAcceptanceByExpertState = negotiationHistory[chosenDate.value - 1].isAcceptedByExpert;
  }

  // let canExpertNegotiate, canClientNegotiate;
  // if(negotiationType === 'Service') {
  //     canExpertNegotiate = !negotiationHistory || lastNegotiationAnswerAuthor === 'client';
  //     canClientNegotiate = !!negotiationHistory && lastNegotiationAnswerAuthor === 'expert';
  //     if((isFirstOffer && firstOfferAcceptedByExpert) || (!isFirstOffer && negotiationHistory[chosenDate.value-1].isAcceptedByExpert)) {
  //         canExpertNegotiate = false;
  //     }
  // } else if(negotiationType === 'Offer') {
  //     canExpertNegotiate = (!!negotiationHistory && lastNegotiationAnswerAuthor === 'client' && !displayedAcceptanceState) && !currentTransaction.attributes.metadata.projectTransactionId ;
  //     canClientNegotiate = (!negotiationHistory || lastNegotiationAnswerAuthor === 'expert') && !currentTransaction.attributes.metadata.projectTransactionId ;
  // }
  // let isOfferNegotiable = ((negotiationType === 'Offer' || negotiationType === 'Service') && !!negotiationReaderRole)
  //                             && (negotiationReaderRole === 'expert' ? canExpertNegotiate : canClientNegotiate);

  let shouldDisplayAcceptForExpert =
    displayedOfferAuthor === 'client' && isOfferAcceptable && displayedAcceptanceState === false;
  let shouldDisplayAcceptForClient =
    isOfferAcceptable &&
    ((!isAdminOffer && displayedOfferAuthor === 'expert') ||
      (isAdminOffer && displayedOfferAuthor === 'expert') ||
      (displayedOfferAuthor === 'client' && displayedAcceptanceState === true));
  let shouldDisplayAcceptButton =
    (negotiationReaderRole === 'expert' && shouldDisplayAcceptForExpert) ||
    (negotiationReaderRole === 'client' && shouldDisplayAcceptForClient);
  let offerAcceptanceMessageId =
    displayedOfferAuthor === 'expert' && displayedAcceptanceState === false
      ? 'NegotiatedOfferDetails.offerWaitingForClientAcceptance'
      : displayedOfferAuthor === 'expert' && displayedAcceptanceState === true
      ? 'NegotiatedOfferDetails.offerAcceptedByClient'
      : displayedOfferAuthor === 'client' && displayedAcceptanceState === false && isAdminOffer
      ? 'NegotiatedOfferDetails.offerWaitingForAdminAcceptance'
      : displayedOfferAuthor === 'client' && displayedAcceptanceState === false && !isAdminOffer
      ? 'NegotiatedOfferDetails.offerWaitingForExpertAcceptance'
      : displayedOfferAuthor === 'client' &&
        displayedAcceptanceState === true &&
        displayedAcceptanceByClientState === false &&
        isAdminOffer
      ? 'NegotiatedOfferDetails.offerWaitingForClientAcceptance2'
      : displayedOfferAuthor === 'client' &&
        displayedAcceptanceState === true &&
        displayedAcceptanceByClientState === false &&
        !isAdminOffer
      ? 'NegotiatedOfferDetails.offerWaitingForClientAcceptance3'
      : displayedOfferAuthor === 'client' &&
        displayedAcceptanceState === true &&
        displayedAcceptanceByClientState === true
      ? 'NegotiatedOfferDetails.offerAcceptedByBothParties'
      : undefined;
  if (!chosenDate || chosenDate?.value === 0) {
    offerAcceptanceMessageId = undefined;
  }
  if (negotiationType === 'Service') {
    offerAcceptanceMessageId =
      displayedOfferAuthor === 'client' && displayedAcceptanceByExpertState === false
        ? 'NegotiatedOfferDetails.offerWaitingForExpertAcceptance'
        : displayedOfferAuthor === 'expert' && displayedAcceptanceState === false
        ? 'NegotiatedOfferDetails.offerWaitingForClientAcceptance'
        : displayedAcceptanceByExpertState === true
        ? 'NegotiatedOfferDetails.offerAcceptedByExpert'
        : undefined;
    shouldDisplayAcceptForExpert =
      displayedOfferAuthor === 'client' && displayedAcceptanceByExpertState === false;
    shouldDisplayAcceptForClient = false;
    shouldDisplayAcceptButton =
      (negotiationReaderRole === 'expert' && shouldDisplayAcceptForExpert) ||
      (negotiationReaderRole === 'client' && shouldDisplayAcceptForClient);
  }
  // let shouldDisplayBuyServiceButton = (negotiationType === 'Service') && !isAdminOffer && ((negotiationReaderRole === 'client') && ((displayedAcceptanceByExpertState === true) || (firstOfferAcceptedByExpert) || displayedOfferAuthor === 'expert'));

  if (isAdminOffer && displayedOfferAuthor === 'expert') {
    displayedOfferAuthor = 'SoftwareSupp';
  }
  const shouldDisplayEditForAdmin =
    isAdminOffer && displayedOfferAuthor === 'SoftwareSupp' && currentUserIsAdmin;

  return (
    <>
      {offerWasNegotiated ? (
        <>
          {/* DATE SELECT */}
          <div className={css.offerWasNegotiatedInfoWrapper}>
            <AiOutlineInfoCircle />
            <span className={css.offerWasNegotiatedInfoText}>
              <FormattedMessage id="NegotiatedOfferDetails.offerWasNegotiatedInfo" />
            </span>
          </div>
          <div className={css.selectLabel}>
            <FormattedMessage id="NegotiatedOfferDetails.selectLabel" />
          </div>
          {shouldRenderReactSelect && (
            <div className={css.negotiationSelectWrapper}>
              <Select
                options={mapDatesToSelectOptions(negotiationDates)}
                value={chosenDate}
                onChange={v => {
                  handleSelectChange(v);
                }}
              />
            </div>
          )}
          {/* DETAILS */}
          <SectionPriceTimeMaybe
            estimatedTime={displayedOfferEstimatedTime}
            estimatedPrice={displayedOfferEstimatedPrice}
            isAdminOffer={isAdminOffer}
            isFirstPosition={chosenDate?.value === 0 ? true : false}
            estimatedTimeRange={estimatedTimeRange}
            estimatedPriceRange={estimatedPriceRange}
            scope={serviceScope}
            formattedPrice={formattedFinalScopePrice}
            commission={commission}
            negotiationReaderRole={negotiationReaderRole}
          />
          {isFirstOffer ? (
            <>
              <SectionScopeMaybe
                scope={serviceScope}
                formattedPrice={formattedFinalScopePrice}
                scopeType={scopeType}
                isAdminOffer={isAdminOffer}
                transactionRole={transactionRole}
                commission={commission}
                offerAuthor={displayedOfferAuthor}
                negotiationReaderRole={negotiationReaderRole}
              />
              {/* {negotiationType === 'Service' && 
                        <>
                            <SectionEstimatedTimeMaybe estimatedTime={displayedOfferEstimatedTime} negotiationType={negotiationType} displayedOfferAuthor={displayedOfferAuthor} />
                            <SectionEstimatedPriceMaybe estimatedPrice={displayedOfferEstimatedPrice} negotiationType={negotiationType} displayedOfferAuthor={displayedOfferAuthor} />
                        </>
                    } */}
              {!!offerAcceptanceMessageId && (
                <p className={css.smallPrint}>
                  <FormattedMessage id={offerAcceptanceMessageId} />
                </p>
              )}
            </>
          ) : (
            <>
              <SectionScopeMaybe
                scope={displayedServiceScope}
                formattedPrice={displayedFormattedFinalScopePrice}
                displayedAcceptanceState={displayedAcceptanceState}
                scopeType={scopeType}
                isAdminOffer={isAdminOffer}
                isNegotiated={true}
                transactionRole={transactionRole}
                commission={commission}
                offerAuthor={displayedOfferAuthor}
                negotiationReaderRole={negotiationReaderRole}
              />
              {/* <SectionEstimatedTimeMaybe estimatedTime={displayedOfferEstimatedTime} negotiationType={negotiationType} displayedOfferAuthor={displayedOfferAuthor} />
                    {negotiationType === 'Service' && 
                        <SectionEstimatedPriceMaybe estimatedPrice={displayedOfferEstimatedPrice} negotiationType={negotiationType} displayedOfferAuthor={displayedOfferAuthor} />
                    } */}
              {!!offerAcceptanceMessageId && (
                <p className={css.smallPrint}>
                  <FormattedMessage id={offerAcceptanceMessageId} />
                </p>
              )}
            </>
          )}
        </>
      ) : !offerWasNegotiated ? (
        <>
          {/* REGULAR OFFER DETAILS */}
          <SectionPriceTimeMaybe
            estimatedTime={offerEstimatedTime}
            estimatedPrice={offerEstimatedPrice}
            isAdminOffer={isAdminOffer}
            estimatedTimeRange={estimatedTimeRange}
            estimatedPriceRange={estimatedPriceRange}
            isFirstPosition={true}
            hourlyRateExpert={hourlyRateExpert}
            commission={commission}
            negotiationReaderRole={negotiationReaderRole}
          />
          <SectionScopeMaybe
            scope={serviceScope}
            formattedPrice={formattedFinalScopePrice}
            scopeType={scopeType}
            isAdminOffer={isAdminOffer}
            transactionRole={transactionRole}
            commission={commission}
            negotiationReaderRole={negotiationReaderRole}
          />
          {!!offerAcceptanceMessageId && (
            <p className={css.smallPrint}>
              <FormattedMessage id={offerAcceptanceMessageId} />
            </p>
          )}
        </>
      ) : null}
      {/* BUTTONS */}
      <>
        {/* EDIT OFFER (FOR ADMIN ONLY) */}
        {shouldDisplayEditForAdmin && (
          <div className={css.acceptClientOfferWrapper}>
            <PrimaryButton
              className={css.acceptClientOfferButton}
              onClick={() => {
                onEditAdminOfferScope(chosenDate?.value);
              }}
            >
              <AiOutlineCheckCircle size={'1.2em'} />
              {'\u00A0'}
              {'\u00A0'}
              <FormattedMessage id={`NegotiatedOfferDetails.editAdminOfferScope`} />
            </PrimaryButton>
          </div>
        )}      
        
        {/* ACCEPT OFFER */}
        {shouldDisplayAcceptButton && (
          <div>
            <div className={css.acceptClientOfferWrapper}>
              <PrimaryButton
                className={css.acceptClientOfferButton}
                onClick={() => acceptOffer(displayedOfferAuthor, negotiationReaderRole)}
              >
                <AiOutlineCheckCircle size={'1.2em'} />
                {'\u00A0'}
                {'\u00A0'}
                <FormattedMessage
                  id={
                    currentTransaction.attributes.metadata.projectTransactionId
                      ? 'NegotiatedOfferDetails.buyClientOfferButton'
                      : 'NegotiatedOfferDetails.acceptClientOfferButton'
                  }
                />
              </PrimaryButton>
            </div>

            <div className={css.acceptClientOfferWrapper}>
              <FormattedMessage
                id="TransactionPanel.rememberInfo"
                values={{ br: <br />, link: termsLink }}
              />
            </div>
          </div>
        )}
        {/* NEGOTIATIE OFFER */}
        {/* {isOfferNegotiable && 
                <div className={css.negotiateButtonWrapper}>
                    <PrimaryButton className={css.negotiateButton} onClick={() => changeIsNegotiationModalOpen(true)}>
                    <ImHammer2 />{'\u00A0'}{'\u00A0'}
                    <FormattedMessage id={negotiationButtonId} />
                    </PrimaryButton>
                </div>
                } */}
      </>
    </>
  );
};

const NegotiatedOfferDetails = injectIntl(NegotiatedOfferDetailsComponent);

export default NegotiatedOfferDetails;
