import React from 'react';
import { DefaultChip } from '../Chip/Chip';
import { FormattedMessage } from '../../util/reactIntl';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { GoVerified } from 'react-icons/go';

import css from './ExpertPage.css';

const SectionExamResultsMaybe  = props => {
  const { examResults } = props;
  const passedExams = examResults?.filter((result) => result.isPassed === true);

  const renderPassedExams = () => {
    return passedExams.map(exam => {
      return (
        <DefaultChip className={css.chip} key={exam.examId}>
          {exam.certificationName}{'\u00A0'}{'\u00A0'}
          <GoVerified color={'#2ECC71'} />
        </DefaultChip>
      );
    })
  }
  
  return passedExams?.length > 0 ? (
    <div className={css.sectionVerifiedSoftwaresWrapper}>
      <div className={css.smallPrintHeading}>
        <h2 className={css.smallPrintHeading}>
          <FormattedMessage id="ExpertPage.examsResultsTitle" />
        </h2>
        <Tooltip
          interactive
          title={<FormattedMessage id={`ExpertPage.examsResultsTooltip`} values={{ br: <br /> }} />}
        >
          <InfoOutlinedIcon className={css.infoIcon} style={{ height: 16 }} />
        </Tooltip>
      </div>
      <div className={css.sectionTagsWithMargin}>{renderPassedExams()}</div>
    </div>
  ) : null;
};

export default SectionExamResultsMaybe;
