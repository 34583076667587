import React from 'react';
import { Edit, Briefcase, MessageCircle } from 'react-feather';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FiUserPlus } from 'react-icons/fi';
import { bool, oneOfType, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
  propTypes,
} from '../../util/types';
import { NamedLink, PrimaryButton, SecondaryButton } from '../../components';

import css from './SideActionBarMaybe.css';

const MODAL_BREAKPOINT = 400;

export const SideActionBarMaybe = props => {
  const {
    isOwnListing,
    listing,
    editParams,
    publicData,
    currentUser,
    isAdmin,
    isSolved,
    showContactUser,
    onContactUser,
    windowWidth,
    isMobile
  } = props;
  const state = listing.attributes.state;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const category = publicData.category;
  const message = isDraft ? "ExpertPage.finishListing" : "ExpertPage.editListing";

  const termsLink = 
    <NamedLink name="TermsOfServicePage">
      <FormattedMessage id="JobApplicationForm.termsOfUse" />
    </NamedLink>

  const unregisteredUserTooltip = (
    <>
      <FormattedMessage id="BookingTimeForm.postJob" /> 
      <Tooltip interactive 
        title={<FormattedMessage id="BookingPanel.benefitsListingExpert" 
        values = {{ br: <br/>, terms: termsLink }}/>} >
        <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }}/>
      </Tooltip>
    </>
  )

  const unregisteredUserIcon = <Briefcase className={css.briefcaseIcon} />;


  console.log(isMobile, windowWidth)

  if (isMobile && windowWidth > MODAL_BREAKPOINT) {
    return null;
  }

  return (
    <div className={windowWidth > MODAL_BREAKPOINT ? css.root : css.mobileBottomPanel}>
    <div className={css.sectionSideActionBar}>
      {isPendingApproval && currentUser ? (
        <div className={css.actionBar}>
          <p className={css.closedListingText}>
            <FormattedMessage id="ExpertPage.pendingApprovalListing" />
          </p>
        </div>
      ) : null}
      {(isClosed || isSolved) && currentUser ? (
        <div className={css.actionBar}>
          <p className={css.closedListingText}>
            <FormattedMessage id="ExpertPage.closedListing"/>
          </p>
        </div>
      ) : null}
       
      {isOwnListing ? (
        <>
          <div className={css.sectionCTA}>
            <NamedLink className={css.editListingLink} name="EditListingPage" params={editParams} category={category} >
              <SecondaryButton className={css.editButton}>
                <Edit className={css.editIconFeather} />
                <FormattedMessage id={message} />
              </SecondaryButton>
            </NamedLink>
          </div>
          {/* {isOwnListing && !isClosed &&  */}
          {/*   <div className={css.sectionCTA}> */}
          {/*     <a className={css.editListingLink} href={'/l/newService'} > */}
          {/*       <PrimaryButton className={css.editButton}> */}
          {/*         <FiUserPlus className={css.editIconFeather} /> */}
          {/*         <FormattedMessage id={'BookingPanel.addServiceCTA'} /> */}
          {/*         <Tooltip interactive title={<FormattedMessage id={`BookingPanel.servicesTooltipDescription`} values = {{ br: <br/>}}/>} > */}
          {/*           <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }}/> */}
          {/*         </Tooltip> */}
          {/*       </PrimaryButton> */}
          {/*     </a> */}
          {/*   </div> */}
          {/* } */}
        </>
      ) : null}
      {!isOwnListing && currentUser && !isAdmin && !isClosed ? (
        <div className={css.sectionCTA}>
          <NamedLink name="NewListingPage" className={css.postJobLink}>
              <PrimaryButton className={css.postJobButton}>
                <Briefcase className={css.briefcaseIcon} />
                {unregisteredUserTooltip}              
                </PrimaryButton>
            </NamedLink>
            {/* <p className={css.smallPrint}>
              <FormattedMessage id="BookingTimeForm.contactExpertInfo" />
            </p> */}
        </div>
      ) : null}
      {!currentUser ? (
        <div className={css.sectionCTA}>
          <NamedLink name="NewListingPage" className={css.newListingLink}>
            <PrimaryButton className={css.postJobButton}>
              {unregisteredUserIcon}
              {unregisteredUserTooltip}
            </PrimaryButton>
          </NamedLink>
          {/* <div className={css.smallPrint}>
            <FormattedMessage id="BookingTimeForm.createProfileExpert" />
          </div> */}
      </div>
      ) : null}
        {showContactUser ?
          <PrimaryButton className={css.contactAdminButton} onClick={onContactUser}>
            <MessageCircle className={css.messageIcon} />
            <FormattedMessage id="BookingTimeForm.contact" />
          </PrimaryButton>
          : null}
    </div>
    </div>
  )
}

SideActionBarMaybe.propTypes = {
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  editParams: object.isRequired,
};

SideActionBarMaybe.displayName = 'SideActionBarMaybe';

export default SideActionBarMaybe;
