import React from 'react';
import { propTypes } from '../../util/types';
import { object, oneOfType } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { Avatar } from '../../components';
import AttachmentsList from './AttachmentsList/AttachmentsList';
import { dateToReadableString } from '../../util/inbox/dateUtil';
import css from './Message.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const Message = props => {
  const { message } = props;
  const { peerName, peerImgUrl, date, content, attachments } = message;
  // const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.message}>
      <Avatar
        className={css.avatar}
        onlyImgName={peerName}
        onlyImgUrl={peerImgUrl}
        disableProfileLink
      />
      <div>
        <div className={css.messageContent}>
          {richText(content, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
            linkify: true,
          })?.map(line => {
            return <div className={css.messageLine}>{line}</div>;
          })}
          <AttachmentsList data={attachments} color={'black'} />
        </div>
        <p className={css.messageDate}>
          {dateToReadableString(date)}
          {/* {formatDate(intl, todayString, message.attributes.createdAt)} */}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: oneOfType([propTypes.message, object]),
  intl: intlShape.isRequired,
};

export default Message;
