import React from 'react';
import { AvatarSmall } from '../../components';

const OptionAvatar = props => {

    const { imgUrl, userName, abbreviatedName } = props;
  
    return !imgUrl ? (
      <AvatarSmall user={{attributes: {profile: {abbreviatedName: abbreviatedName}}}} />
    ) : (
      <AvatarSmall onlyImgName={userName} onlyImgUrl={imgUrl} disableProfileLink/>
    ); 
}

export default OptionAvatar;