import React from 'react';
import { DefaultChip, PrimaryChip } from '../../components/Chip/Chip';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './ServicePage.css';

const SectionTagsMaybe = props => {
  const { softwares, skills, serviceCategories, productSoftware, productSkills } = props;

  const slicedSoftwares = softwares ? softwares.slice(0,9) : [];
  const softwaresLabels = productSoftware.filter(s => slicedSoftwares.includes(s.key))

  const slicedSkills = skills ? skills.slice(0,9) : [];
  const skillsLabels = productSkills.filter(s => slicedSkills.includes(s.key))

  const renderSoftwares = () => {
    return softwaresLabels?.map(software => {
      return <DefaultChip className={css.chip} key={software.label}>{software.label}</DefaultChip>
    })
  }

  const renderSkills = () => {
    return skillsLabels?.map(software => {
      return <PrimaryChip className={css.chip} key={software.label}>{software.label}</PrimaryChip>
    })
  }

  const renderServiceCategories = () => {
    return serviceCategories?.map((item, index) => {
      return <PrimaryChip key={"serviceCategory" + index}>{item}</PrimaryChip>
    });
  }
  
  return softwares || skills ? (
    <div className={css.sectionTags}>
      {softwares ? renderSoftwares() : null}
      {skills ? renderSkills() : null}
      {serviceCategories ? renderServiceCategories() : null}
    </div>
  ) : null;
};

const mapStateToProps = state => {
  const {
    productSoftware,
    productSkills
  } = state.marketplaceData;
  return {
    productSoftware,
    productSkills
  }
}

export default compose(
  connect(mapStateToProps)
)(SectionTagsMaybe);