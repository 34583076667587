import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, SecondaryButton } from '../../components';
import { propTypes } from '../../util/types';

import css from './AddCompletionHoursForm.css';

const BLUR_TIMEOUT_MS = 100;

class AddCompletionHoursFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    const { intl } = this.props;
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            invalid,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;

          const titleMessage = intl.formatMessage({ id: `AddCompletionHoursForm.completionHours` });

          return (
            <Form className={classes} onSubmit={values => handleSubmit(values)}>
              <FieldTextInput
                inputRootClass={css.textarea}
                type="number"
                id='completionHours'
                name="completionHours"
                label={titleMessage}
                placeholder={messagePlaceholder}
                onFocus={this.handleFocus}
                min={1}
                onBlur={this.handleBlur}
              />
              <div className={css.submitContainer}>
                <SecondaryButton
                  rootClassName={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                >
                  <FormattedMessage id="AddCompletionHoursForm.sendCompletionHours" />
                </SecondaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

AddCompletionHoursFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

AddCompletionHoursFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const AddCompletionHoursForm = compose(injectIntl)(AddCompletionHoursFormComponent);

AddCompletionHoursForm.displayName = 'AddCompletionHoursForm';

export default AddCompletionHoursForm;
