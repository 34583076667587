/* eslint-disable */
import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { updateInboxUserProfile, anonymization } from '../../util/api';
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink
} from '../../components';
import { ProfileSettingsForm } from '../../forms';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withRouter } from 'react-router-dom';
import { TopbarContainer } from '../../containers';

import { updateProfile, uploadImage } from './ProfileSettingsPage.duck';
import css from './ProfileSettingsPage.css';
import {Form, Field } from 'react-final-form'

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
export class ProfileSettingsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { showRemovePasswordForm: false };
    this.state = { showCantBeRemoved: false};
    this.state = { showIncorrectPassword:false }
  }

  

 
  
  render() {
    const {
      currentUser,
      currentUserListing,
      image,
      onImageUpload,
      onUpdateProfile,
      scrollingDisabled,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      uploadInProgress,
      intl,
      location,
      history,
      form,

    } = this.props;



    const showRemovePassword = () => {
      this.setState({ showRemovePasswordForm: true})
    }

    const isNewExpertFlow = location?.state?.isExpertRedirect;

    const handleSubmit = values => {
      const { firstName, lastName, bio: rawBio } = values;

      // Ensure that the optional bio is a string
      const bio = rawBio || '';

      const profile = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        bio,
      };
      const uploadedImage = this.props.image;
      const uploadedImageUrl = uploadedImage?.uploadedImage.attributes.variants['square-small2x'].url;
      const currentUserId = currentUser?.id.uuid;
      const currentUserInboxToken = currentUser?.attributes?.profile?.privateData?.inboxToken;

      // Update profileImage only if file system has been accessed
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { ...profile, profileImageId: uploadedImage.imageId }
          : profile;

      onUpdateProfile(updatedValues).then(() => {
        let updateInboxProfileData = {
          firstName,
          lastName
        };
        if(!!uploadedImageUrl) {
          updateInboxProfileData['imgUrl'] = uploadedImageUrl;
        }
        updateInboxUserProfile(currentUserInboxToken, {userId: currentUserId, data: updateInboxProfileData});
        if (isNewExpertFlow) {
          history.push(createResourceLocatorString('NewListingExpertPage', routeConfiguration()))
        }
      });
    };

    const user = ensureCurrentUser(currentUser);
    const { firstName, lastName, bio } = user.attributes.profile;
    const profileImageId = user.profileImage ? user.profileImage.id : null;
    const profileImage = image || { imageId: profileImageId };

    const profileSettingsForm = user.id ? (
      <ProfileSettingsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{ firstName, lastName, bio, profileImage: user.profileImage }}
        profileImage={profileImage}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        isNewExpertFlow={isNewExpertFlow}
        onSubmit={handleSubmit}
      />
    ) : null;

    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });
 
    const onSubmitRemove = async values => {

      var cookies = document.cookie.split(";");

      const path = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});

      for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }

      let token = currentUser?.attributes?.profile?.privateData?.inboxToken
      const payload = {
        email:currentUser?.attributes?.email,
        password:values.password,
        userId:currentUser?.id.uuid,
      }


      anonymization(token, payload).then((res)=> {
        if(res.status === false){
            if(res.msg === 'wrong auth'){
              this.setState({ showIncorrectPassword:true})
              this.setState({ showCantBeRemoved:false})
            } else {
              this.setState({ showIncorrectPassword:false})
              this.setState({ showCantBeRemoved:true})
            }
            

        } else if (res.status === true) {
          // usuwanie ciasteczek aby wylogowac
          var cookies = document.cookie.split(";");

          for (var i = 0; i < cookies.length; i++) {
               // usuwanie ciasteczek aby wylogowac
               var cookies = document.cookie.split(";");

               for (var i = 0; i < cookies.length; i++) {
                   var cookie = cookies[i];
                   var eqPos = cookie.indexOf("=");
                   var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
               }
               sleep(300)
               try{
               window.location.reload()
             } catch(e){}
          }


        }
        console.log(res)
      })


      console.log(token,payload)
      
      await sleep(300)

    }
  
    return (
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfileSettingsPage" />
            <UserNav
              currentUser={currentUser}
              selectedPageName="ProfileSettingsPage"
              listing={currentUserListing}
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  <FormattedMessage id="ProfileSettingsPage.heading" />
                </h1>
                {user.id ? (
                  <NamedLink
                    className={css.profileLink}
                    name="ProfilePage"
                    params={{ id: user.id.uuid }}
                  >
                    <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                  </NamedLink>
                ) : null}
              </div>
              {profileSettingsForm}
            </div>
            
            <div className={css.content}>
            <div className={css.removeAccount}>
              <p><b>Want to remove your Account?</b><a onClick={showRemovePassword}> Click here</a></p>
              
              { this.state.showRemovePasswordForm && (
                  <div className={css.removeConfiramtion}>
                  Enter your password to confirm:*<br/>
                  <Form onSubmit={onSubmitRemove}
                  render={({ handleSubmit, form, }) => (
                    <form onSubmit={handleSubmit}>
                      <Field
              name="password"
              component="input"
              type="password"
              placeholder="password"
            />
                      <button type="submit">Remove account</button>
                    </form>
                  )
              } />

              </div> )}
             
              { this.state.showCantBeRemoved && (
                <p className={ css.alert }>Account can't be removed. Please contact with support:  <a href="mailto:support@softwaresupp.com">support@softwaresupp.com</a></p>
              )}
               { this.state.showIncorrectPassword && (
                <p className={ css.alert }>Password incorrect</p>
              )}
          
              </div>
              </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  currentUserListing: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserListing } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    currentUserListing,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const ProfileSettingsPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ProfileSettingsPageComponent);

export default ProfileSettingsPage;
