import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '../../components';

import css from './InvitedUsersModal.css';

const InvitedUsersModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    transaction,
    inviteList
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const transactionHasInvitedUsers = transaction && transaction.attributes.metadata?.allowedUser;
  const invitedUsers = inviteList?.length > 0 ? inviteList : transactionHasInvitedUsers ? transaction.attributes.metadata?.allowedUser : null;

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      {invitedUsers?.length > 0 ? invitedUsers.map((user,index) => {
        return (
          <div className={css.user} key={index}>
              <div className={css.userContainer}>
                {user}
              </div>
          </div>
        )
      }) :
        <FormattedMessage id="InvitedUsersModal.noInvitedUsers" />
      }
    </Modal>
  );
};

const { string } = PropTypes;

InvitedUsersModal.defaultProps = {
  className: null,
  rootClassName: null,
};

InvitedUsersModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(InvitedUsersModal);
