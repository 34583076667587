import React from 'react';
import classNames from 'classnames';
import { Avatar, NamedLink } from '../../components';
import { dateToReadableString } from '../../util/inbox/dateUtil';
import css from './MessagesPage.css';

const shortText = (text, maxLength) => {
  if(text?.length > 0) {
    let shortedText = text.substring(0, maxLength);
    shortedText = shortedText.substring(0, shortedText.lastIndexOf(" ")) + "...";
    return text.length > maxLength ? shortedText : text;
  } else {
    return text;
  }
}

export const InboxItem = props => {

  const { conversationId, displayedName, userName, abbreviatedName, userImgUrl, lastMessage, date, containsAttachments, isGroupMessage, isDeleted } = props.data;

  const displayedDate = dateToReadableString(date);
  const displayedItemName = displayedName ?? '';
  const displayedMessage = (containsAttachments && !lastMessage) ? 'Attachment.' : shortText(lastMessage, 70);

  const rowNotificationDot = false ? <div className={css.notificationDot} /> : null;
  //const isAdminListing = listing.id.uuid === process.env.REACT_APP_ADMIN_PM_LISTING_ID;

  const linkClasses = classNames(css.itemLink);

  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        {isDeleted ? 
          <Avatar user={{attributes: {deleted: true}}} disableProfileLink />
        : !userImgUrl ?
          <Avatar user={{attributes: {profile: {abbreviatedName: abbreviatedName}}}} />
        :
          <Avatar onlyImgName={userName} onlyImgUrl={userImgUrl} disableProfileLink/>
        }
      </div>
      {!!conversationId && <NamedLink
        className={linkClasses}
        name={isGroupMessage ? 'GroupConversationPage' : 'ConversationPage'}
        params={{id: conversationId}}
        state={null}
      >
        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
        <div className={css.itemInfo}>
          <div className={classNames(css.itemUsername, css.nameNotEmphasized)}>
            {displayedItemName}
          </div>
          <div className={css.listingTitle}>
            {displayedMessage}
          </div>
        </div>
        <div className={css.price}>
        </div>
        <div className={css.itemState}>
          <div className={classNames(css.stateName, css.stateActionNeeded)}>
            {'Message'}
          </div>
          <div className={classNames(css.lastTransitionedAt, css.lastTransitionedAtNotEmphasized)}>
            {displayedDate}
          </div>
        </div>
      </NamedLink>}
    </div>
  );
};
  
InboxItem.propTypes = {
    //unitType: propTypes.bookingUnitType.isRequired,
    //type: oneOf(['order', 'sale', 'all']).isRequired,
    //tx: oneOfType([propTypes.transaction, object]).isRequired,
    //intl: intlShape.isRequired,
};