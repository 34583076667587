import React, { useState, useEffect } from 'react';
import { bool, func, shape, string, object } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field, useFormState } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { connect } from 'react-redux';
import classNames from 'classnames';
import config from '../../config';
import { connectCalendarGoogle } from '../../util/api';

import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import { propTypes, DATE_TYPE_DATETIME } from '../../util/types';
import {
  // maxLength,
  // required,
  // composeValidators,
  // validBusinessURL,
  // numberValueAtLeast,
  moneySubUnitAmountAtLeastOrNull,
} from '../../util/validators';
import { timestampToDate } from '../../util/dates';

import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCurrencyInput,
  LocationAutocompleteInputField,
  FieldProjects,
  FieldExperience,
  SecondaryButton,
  Modal,
  InlineTextButton,
  IconEdit,
  IconSpinner,
  IconClose,
  TimeRange,
} from '../../components';
import { EditListingAvailabilityPlanForm, EditListingAvailabilityExceptionForm } from '../../forms';
import css from './EditExpertForm.css';

const TITLE_MAX_LENGTH = 120;
const identity = v => v;

const MAX_EXCEPTIONS_COUNT = 100;

/////////////
// Weekday //
/////////////
const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);

const Weekday = props => {
  const { availabilityPlan, dayOfWeek, openEditModal } = props;
  const hasEntry = findEntry(availabilityPlan, dayOfWeek);

  return (
    <div
      className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
      onClick={() => openEditModal(true)}
      role="button"
    >
      <div className={css.dayOfWeek}>
        <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
      </div>
      <div className={css.entries}>
        {availabilityPlan && hasEntry
          ? getEntries(availabilityPlan, dayOfWeek).map(e => {
              return (
                <span className={css.entry} key={`${e.dayOfWeek}${e.startTime}`}>{`${
                  e.startTime
                } - ${e.endTime === '00:00' ? '24:00' : e.endTime}`}</span>
              );
            })
          : null}
      </div>
    </div>
  );
};

const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

const createEmptyResumeeObject = objectType => {
  if (objectType === 'experience') {
    return [0].map(value => {
      return {
        id: 0,
        experienceTitle: '',
        experienceDescription: '',
        experienceUrl: '',
        experienceCompany: '',
        experienceSoftware: [],
        experienceYears: '',
      };
    });
  } else {
    return [0].map(value => {
      return {
        id: 0,
        projectTitle: '',
        projectDescription: '',
        projectUrl: '',
        projectCompany: '',
        projectSoftware: [],
      };
    });
  }
};

const EditExpertFormComponent = props => {
  const {
    onAddAvailabilityException,
    currentListing,
    createAvailabilityPlan,
    submittedAvailabilityPlan,
  } = props;

  const [imageUploadRequested, setImageUploadRequested] = useState(false);
  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);
  const [projectDeleted, setProjectDeleted] = useState(false);
  const [projectItems, setProjectItems] = useState(createEmptyResumeeObject('projects'));
  const [experienceItems, setExperienceItems] = useState(createEmptyResumeeObject('experience'));
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false);
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [uploading, setFileUploating] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileValidation, setFileValidation] = useState(null);
  const [FormValidation, setFormValidation] = useState(null);
  const [fileList, setFileList] = useState([])


  useEffect(() => {
    if (typeof window !== undefined) {
      setShouldRenderReactSelect(true);
    }
      // console.log('useeffect', props.initialValues.uploadedFileLink )
    // if(props.initialValues.uploadedFileLink !== undefined){
    //   let objFileList = JSON.parse(props.initialValues.uploadedFileLink)
    //   setFileList(objFileList)

    // }
  }, []);

  useEffect(() => {
      if(props.initialValues.uploadedFileLink !== undefined){
      let tempJson = JSON.parse(props.initialValues.uploadedFileLink)
      setFileList(tempJson)
    }
  },[props?.initialValues.uploadedFileLink])

    // Save exception click handler
  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime } = values;

    // TODO: add proper seat handling
    const seats = availability === 'available' ? 1 : 0;

    return onAddAvailabilityException({
      listingId: currentListing.id,
      seats,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(() => {
        setIsEditExceptionsModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const setValue = (args, state, { setIn, changeValue }) => {
    const fieldName = args[0];
    const fieldValue = args[1];
    const field = state.fields[fieldName];
    if (!!field) {
      field.change(fieldValue);
    }
  };

  const handleSaveAvailability = (values, close) => {
    //log(values);
    setIsEditPlanModalOpen(false);
    createAvailabilityPlan(values);
  };

  const removeFile = (index, form) => {
      let list = fileList
      list.splice(index, 1)
      setFileList(list)
      form.change('uploadedFileLink', JSON.stringify(list))
  }

  const handleFileUpload = async (e, form) => {
    let validationError = null;
    setFileValidation(false);
    setFileUploaded(false);
  

    if (e.target.files.length > 0) {
      setFileUploating(true);

      // if (e.target.files[0].type != 'application/pdf') {
      //   validationError = 'Wrong file format';
      // }

      if (e.target.files[0].size > 200000000) {
        validationError = 'File is to large';
      }

      if (!validationError) {
        const resdata = await uploadToS3(e);
        
        console.log('file url',resdata)
        let uploadElement = {
          "name":resdata.name,
          "url":resdata.url
        }
        let formValue = form.getFieldState('uploadedFileLink').value
        
        console.log('not',formValue)
        
        if(formValue === undefined){
          let arrFile = [uploadElement]
          form.change('uploadedFileLink', JSON.stringify(arrFile))
        } else {
          let temp = JSON.parse(formValue)
          console.log(temp.push(uploadElement))

          form.change('uploadedFileLink', JSON.stringify(temp))
        }
        // dodaje plik do tablic
        setFileList(prev => [...prev, resdata])
        setFileUploating(false);
        setFileUploaded(true);
      }

      if (validationError) {
        form.change('uploadedFileLink','' )
        setFileValidation(validationError);
        setFileUploating(false);
      }
    }
  };
const uploadToS3 = async (data) => {

    let fileName = data.target?.files[0].name
    const url = `${apiBaseUrl()}/api/upload`;
    let formData = new FormData();
    formData.append("cvfile", data.target.files[0])
    formData.append("name", "cvfile")
    formData.append('prefix', 'cv')

    const axiosRes = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })

    console.log('s3filename', fileName)
    return {
      'name':fileName,
      'url':axiosRes.data.url,
    }
}


  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators, setValue }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          form,
          handleSubmit,
          intl,
          submitFailed,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          currentUserExists,
          values,
          initialValues,
          initialPrice,
          isNew,
          onManageDisableScrolling,
          availabilityExceptions,
          fetchExceptionsInProgress,
          onDeleteAvailabilityException,
          defaultAvailabilityPlan,
          WEEKDAYS,
          panelTitle,
          languages,
          currentTab,
          onGoToNextTab,
          productSoftware,
          productSkills,
          productIndustries,
          calendarLists,
          user,
        } = formRenderProps;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const buttonDisabled = currentTab === 'description' && !values.title;

        const allSofts = productSoftware.map(soft => {
          return {
            value: soft.key,
            label: soft.label,
          };
        });

        const allSkills = productSkills.map(soft => {
          return {
            value: soft.key,
            label: soft.label,
          };
        });

        const allIndustries = productIndustries.map(industry => {
          return {
            value: industry.key,
            label: industry.label,
          };
        });

        const formattedLanguages = Object.entries(languages).map(language => {
          return {
            value: language[0],
            label: `${language[1].name} (${language[1].nativeName})`,
          };
        });

        // error messages
        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        //title messages
        const titleMessage = intl.formatMessage({ id: 'EditExpertForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditExpertForm.titlePlaceholder',
        });
        // const titleRequiredMessage = intl.formatMessage({ id: "EditExpertForm.titleRequired" });
        // const maxLengthMessage = intl.formatMessage(
        //   { id: 'EditListingDescriptionForm.maxLength' },
        //   {
        //     maxLength: TITLE_MAX_LENGTH,
        //   }
        // );
        // const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

        const expertiseMessage = intl.formatMessage({ id: 'EditExpertForm.expertise' });
        const expertisePlaceholderMessage = intl.formatMessage({
          id: 'EditExpertForm.expertisePlaceholder',
        });

        // description messages
        const descriptionMessage = intl.formatMessage({ id: 'EditExpertForm.description' });
        const descriptionPlaceholder = intl.formatMessage({
          id: 'EditExpertForm.descriptionPlaceholder',
        });
        // const descriptionRequired = intl.formatMessage({ id: 'EditExpertForm.descriptionRequired' });

        // phone number messages
        const phoneLabel = intl.formatMessage({ id: 'SignupForm.phoneLabel' });
        const phonePlaceholder = intl.formatMessage({ id: 'SignupForm.phonePlaceholder' });
        // const phoneRequiredMessage = intl.formatMessage({ id: 'SignupForm.phoneRequired' });
        // const phoneRequired = required(phoneRequiredMessage);

        //software messages
        const softwareLabel = intl.formatMessage({ id: 'EditExpertForm.softwareTitle' });
        const softwarePlaceholder = intl.formatMessage({
          id: 'EditExpertForm.softwarePlaceholder',
        });
        const primarySoftwareLabel = intl.formatMessage({
          id: 'EditExpertForm.primarySoftwareTitle',
        });

        // skills messages
        const skillsPlaceholder = intl.formatMessage({ id: 'EditExpertForm.skillsPlaceholder' });
        const skillsLabel = intl.formatMessage({ id: 'EditExpertForm.skillsInfo' });

        //industries messages
        const industriesLabel = intl.formatMessage({ id: 'EditExpertForm.industriesInfo' });
        const industriesPlaceholder = intl.formatMessage({
          id: 'EditExpertForm.industriesPlaceholder',
        });

        // budget messages
        const pricePerUnitMessage = intl.formatMessage({ id: 'EditExpertForm.pricing' });
        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditExpertForm.priceInputPlaceholder',
        });
        const hourlyRateErrorMessage = intl.formatMessage({
          id: 'EditExpertForm.hourlyRateTooLowMessage',
        });

        //links messages
        const linkedinMessage = intl.formatMessage({ id: 'EditExpertForm.linkedin' });
        const linkedinPlaceholderMessage = intl.formatMessage({
          id: 'EditExpertForm.linkedinPlaceholder',
        });
        const calendlyPlaceholderMessage = intl.formatMessage({
          id: 'EditExpertForm.calendlyPlaceholder',
        });
        const calendlyMessage = intl.formatMessage({ id: 'EditExpertForm.calendly' });
        const webPlaceholderMessage = intl.formatMessage({ id: 'EditExpertForm.webPlaceholder' });
        const webMessage = intl.formatMessage({ id: 'EditExpertForm.web' });
        // const notValidURLMessage = intl.formatMessage({ id: "EditExpertForm.notValidURLMessage" });

        //address messages
        const addressPlaceholderMessage = intl.formatMessage({
          id: 'EditExpertForm.addressPlaceholder',
        });
        const addressTitleMessage = intl.formatMessage({ id: 'EditExpertForm.address' });

        //languages messages
        const languagesLabel = intl.formatMessage({ id: 'EditExpertForm.languagesTitle' });
        const languagesPlaceholder = intl.formatMessage({
          id: 'EditExpertForm.languagesPlaceholder',
        });

        //projects count messages
        const projectsCountMessage = intl.formatMessage({ id: 'EditExpertForm.projectsCount' });
        const projectsCountPlaceholder = intl.formatMessage({
          id: 'EditExpertForm.projectsCountPlaceholder',
        });
        // const projectsCountRequiredMessage = intl.formatMessage({ id: 'EditExpertForm.projectsCountRequired' });
        // const projectsNumberErrorMessage = intl.formatMessage({ id: 'EditExpertForm.projectsCountNumberTooLowMessage'});

        //experience messages
        const experienceMessage = intl.formatMessage({ id: 'EditExpertForm.experience' });
        const experiencePlaceholder = intl.formatMessage({
          id: 'EditExpertForm.experiencePlaceholder',
        });
        // const experienceNumberErrorMessage = intl.formatMessage({ id: 'EditExpertForm.experienceNumberTooLowMessage'});
        // const experienceRequired = required(intl.formatMessage({ id: 'EditExpertForm.experienceRequired' }));

        if (shouldRenderReactSelect) {
          var isMobile = false;
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            isMobile = true;
          }
          const { default: CreatableSelect } = require('react-select/creatable');
          const { default: Select } = require('react-select');
          var ReactCreatableSelectAdapter = ({ input, ...rest }) => (
            <CreatableSelect {...input} {...rest} searchable />
          );
          var ReactSelectAdapter = ({ input, ...rest }) => (
            <Select {...input} {...rest} searchable />
          );
          var primarySoftwareField = (
            <Field
              name="primarySoftware"
              component={ReactCreatableSelectAdapter}
              options={allSofts}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
              placeholder={softwarePlaceholder}
              className={css.software}
            />
          );
          var softwareField = (
            <Field
              name="software"
              isMulti
              component={ReactCreatableSelectAdapter}
              closeMenuOnSelect={false}
              options={allSofts}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
              placeholder={softwarePlaceholder}
              className={css.software}
            />
          );
          var skillsField = (
            <Field
              name="skills"
              isMulti
              component={ReactCreatableSelectAdapter}
              closeMenuOnSelect={false}
              options={allSkills}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
              placeholder={skillsPlaceholder}
            />
          );
          var industriesField = (
            <Field
              name="industries"
              isMulti
              component={ReactCreatableSelectAdapter}
              closeMenuOnSelect={false}
              options={allIndustries}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
              placeholder={industriesPlaceholder}
            />
          );
          var languagesField = (
            <Field
              name="languages"
              isMulti
              component={ReactSelectAdapter}
              closeMenuOnSelect={false}
              options={formattedLanguages}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
              placeholder={languagesPlaceholder}
            />
          );
        }

        const initialAvailabilityPlan = currentListing.attributes.availabilityPlan
          ? currentListing.attributes.availabilityPlan
          : defaultAvailabilityPlan;

        const availabilityPlan = {
          ...initialAvailabilityPlan,
          timezone: submittedAvailabilityPlan?.timezone ?? initialAvailabilityPlan.timezone,
          entries: submittedAvailabilityPlan.entries
            ? [
                ...submittedAvailabilityPlan.entries,
                ...initialAvailabilityPlan?.entries.filter(
                  entry =>
                    !submittedAvailabilityPlan.entries.find(e => e.dayOfWeek === entry.dayOfWeek)
                ),
              ]
            : initialAvailabilityPlan?.entries,
        };

        const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;
        const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);

        return (
          <Form
            className={classes}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            <h1 className={css.panelTitle}>{panelTitle}</h1>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}

            <table className={css.sections}>
              <tbody>
                <tr
                  id="description"
                  className={currentTab === 'description' ? null : css.hiddenTab}
                >
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.descriptionNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <FieldTextInput
                      id="EditProjectForm.title"
                      name="title"
                      className={css.title}
                      type="text"
                      label={titleMessage}
                      placeholder={titlePlaceholderMessage}
                      maxLength={TITLE_MAX_LENGTH}
                      // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                      autoFocus
                      onClick={() => {
                        if (!currentUserExists) {
                          window.ga('send', 'event', 'Title', 'fulfill');
                        }
                      }}
                    />
                    <FieldTextInput
                      id="EditProjectForm.expertise"
                      name="expertise"
                      className={css.title}
                      type="text"
                      label={expertiseMessage}
                      placeholder={expertisePlaceholderMessage}
                      maxLength={TITLE_MAX_LENGTH}
                      onClick={() => {
                        if (!currentUserExists) {
                          window.ga('send', 'event', 'Expertise', 'fulfill');
                        }
                      }}
                    />
                    <FieldTextInput
                      id="EditProjectForm.description"
                      name="description"
                      className={css.description}
                      type="textarea"
                      label={descriptionMessage}
                      placeholder={descriptionPlaceholder}
                      // validate={composeValidators(required(descriptionRequired))}
                      isTicket
                      isDescription
                      onClick={() => {
                        if (!currentUserExists) {
                          window.ga('send', 'event', 'Description', 'fulfill');
                        }
                      }}
                    />
                    <div className={css.numbersRow}>
                      <FieldTextInput
                        className={css.experience}
                        name="experience"
                        id="experience"
                        type="number"
                        label={experienceMessage}
                        placeholder={experiencePlaceholder}
                        // validate={composeValidators(experienceRequired, numberValueAtLeast(experienceNumberErrorMessage, 0))}
                      />

                      <FieldTextInput
                        className={css.projectsCount}
                        name="projectsCount"
                        id="projectsCount"
                        type="number"
                        label={projectsCountMessage}
                        placeholder={projectsCountPlaceholder}
                        // validate={composeValidators(required(projectsCountRequiredMessage), numberValueAtLeast(projectsNumberErrorMessage, 0))}
                      />
                    </div>
                    <div className={css.languages}>
                      <div className={css.software}>{languagesLabel}</div>
                      {languagesField}
                    </div>
                  </td>
                </tr>

                <tr id="projects" className={currentTab === 'projects' ? null : css.hiddenTab}>
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.projectsNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <FieldProjects
                      values={values}
                      intl={intl}
                      initialValues={initialValues.projects}
                      setProjectDeleted={() => setProjectDeleted(true)}
                      projectItems={projectItems}
                      setProjectItems={setProjectItems}
                    />
                  </td>
                </tr>
                
                <tr id="attachments" className={currentTab === 'attachments' ? null : css.hiddenTab}>
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.attachmentsNav" />
                  </td>
                  <td className={css.sectionContent}>

              <span className={css.reqirements} >* Allowed formats *.PDF, max. 20 MB</span> 
              <div> 
              <Field className={css.hideField} name="uploadedFileLink" component="input" />

              <label>
                  <input
                    className={css.customfileinput}
                    name="cvfile"
                    type="file"
                    onChange={e => handleFileUpload(e,form)}
                  ></input>

                  {uploading ? (
                  <div className={css.inprogress}>
                    {' '}
                    <Bars
                      height="30"
                      width="30"
                      color="#000000"
                      ariaLabel="bars-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                    <div>Uploading file</div>
                  </div>
                ) : null}
                {fileUploaded ? <div className={css.success}>File uploaded</div> : null}
                {fileValidation ? <div className={css.error}>{fileValidation}</div> : null}
                </label>   

                <div>
                  <table className={css.fileTable}>
                    <tbody>
                  {fileList.map((item, index)=>{
                          return (
                              <tr>
                                <td>
                                  <span className={css.uploadedFile}>{item.name}</span>
                                </td>
                                <td>
                                  <span className={css.removeBtn} onClick={(e)=>{removeFile(index, form)}}>Remove</span>
                                </td>
                              </tr>
                              )
                        })}
                  </tbody>
                </table>
                </div>
              </div>
                  </td>
                </tr>
              
                <tr id="software" className={currentTab === 'software' ? null : css.hiddenTab}>
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.softwareNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <div>
                      <div className={css.software} id="EditProjectForm.software">
                        {primarySoftwareLabel}
                      </div>
                      {primarySoftwareField}
                      <div className={css.software}>{softwareLabel}</div>
                      {softwareField}
                    </div>
                  </td>
                </tr>

                <tr id="experience" className={currentTab === 'experience' ? null : css.hiddenTab}>
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.experienceNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <FieldExperience
                      values={values}
                      intl={intl}
                      initialValues={initialValues.professionalExperience}
                      setExperienceDeleted={() => setProjectDeleted(true)}
                      experienceItems={experienceItems}
                      setExperienceItems={setExperienceItems}
                    />
                  </td>
                </tr>

                <tr id="pricing" className={currentTab === 'pricing' ? null : css.hiddenTab}>
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.pricingNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <FieldCurrencyInput
                      id="price"
                      name="price"
                      className={css.priceInput}
                      label={pricePerUnitMessage}
                      placeholder={pricePlaceholderMessage}
                      currencyConfig={config.currencyConfig}
                      defaultValue={initialPrice}
                      validate={moneySubUnitAmountAtLeastOrNull(hourlyRateErrorMessage, 500)}
                    />
                    <FormattedMessage id="EditPageSidebar.pricingInfo" />

                    {/* {values.price ? (
                      <>
                        <span className={css.hourlyRateInfo}>
                          Final price offered to customers: ${values.price?.amount / 50}
                        </span>
                        <Tooltip
                          interactive
                          title={
                            <FormattedMessage
                              id={`NewOfferForm.offerPriceTooltip`}
                              values={{ br: <br /> }}
                            />
                          }
                        >
                          <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
                        </Tooltip>
                      </>
                    ) : null} */}
                  </td>
                </tr>

                <tr id="skills" className={currentTab === 'skills' ? null : css.hiddenTab}>
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.skillsNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <div>
                      <div className={css.software} id="EditProjectForm.skills">
                        {skillsLabel}
                      </div>
                      {skillsField}
                    </div>
                  </td>
                </tr>
                <tr id="links" className={currentTab === 'links' ? null : css.hiddenTab}>
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.linksNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <FieldTextInput
                      id="linkWeb"
                      name="linkWeb"
                      className={css.web}
                      type="text"
                      label={webMessage}
                      placeholder={webPlaceholderMessage}
                      // validate={validBusinessURL(notValidURLMessage)}
                    />
                    <FieldTextInput
                      id="linkLinkedin"
                      name="linkLinkedin"
                      className={css.linkedin}
                      type="text"
                      label={linkedinMessage}
                      placeholder={linkedinPlaceholderMessage}
                      // validate={validBusinessURL(notValidURLMessage)}
                    />
                    <FieldTextInput
                      id="linkCalendly"
                      name="linkCalendly"
                      className={css.calendly}
                      type="textarea"
                      label={calendlyMessage}
                      placeholder={calendlyPlaceholderMessage}
                      // validate={composeValidators(validBusinessURL(notValidURLMessage))}
                    />
                  </td>
                </tr>

                <tr
                  id="availability"
                  className={currentTab === 'availability' ? null : css.hiddenTab}
                >
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.availabilityNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <section className={css.section}>
                      <header className={css.sectionHeader}>
                        <h2 className={css.sectionTitle}>
                          <FormattedMessage id="EditListingAvailabilityPanel.defaultScheduleTitle" />
                        </h2>
                        <InlineTextButton
                          className={css.editPlanButton}
                          onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            setIsEditPlanModalOpen(true);
                          }}
                        >
                          <IconEdit className={css.editPlanIcon} />{' '}
                          <FormattedMessage id="EditListingAvailabilityPanel.edit" />
                        </InlineTextButton>
                      </header>
                      {calendarLists.length === 0 ? (
                        <PrimaryButton
                          type="button"
                          onClick={() =>
                            connectCalendarGoogle({
                              resource: 'calendar',
                              method: 'auth',
                              params: { url: window.location.origin + '/google/redirct_uri' },
                            }).then(obj => {
                              if(obj.url){
                              window.location.href = obj.url;
                              }
                            })
                          }
                        >
                          <FormattedMessage id="EditExpertForm.googleCalendarConnet" />
                        </PrimaryButton>
                      ) : (
                        <PrimaryButton
                          type="button"
                          onClick={() =>
                            connectCalendarGoogle({
                              resource: 'calendar',
                              method: 'disconnect',
                              params: { userId: user.id.uuid },
                            }).then(obj => {
                              window.location.reload(true);
                            })
                          }
                        >
                          <FormattedMessage id="EditExpertForm.googleCalendarDisconnet" />
                        </PrimaryButton>
                      )}
                      <p>TIMEZONE: {availabilityPlan.timezone}</p>
                      <div className={css.week}>
                        {WEEKDAYS.map(w => (
                          <Weekday
                            dayOfWeek={w}
                            key={w}
                            availabilityPlan={availabilityPlan}
                            openEditModal={setIsEditPlanModalOpen}
                          />
                        ))}
                      </div>
                    </section>
                    {currentUserExists ? (
                      <section className={css.section}>
                        <header className={css.sectionHeader}>
                          <h2 className={css.sectionTitle}>
                            {fetchExceptionsInProgress ? (
                              <FormattedMessage id="EditListingAvailabilityPanel.availabilityExceptionsTitleNoCount" />
                            ) : (
                              <FormattedMessage
                                id="EditListingAvailabilityPanel.availabilityExceptionsTitle"
                                values={{ count: exceptionCount }}
                              />
                            )}
                          </h2>
                        </header>
                        {fetchExceptionsInProgress ? (
                          <div className={css.exceptionsLoading}>
                            <IconSpinner />
                          </div>
                        ) : exceptionCount === 0 ? (
                          <div className={css.noExceptions}>
                            <FormattedMessage id="EditListingAvailabilityPanel.noExceptions" />
                          </div>
                        ) : (
                          <div className={css.exceptions}>
                            {sortedAvailabilityExceptions.map(availabilityException => {
                              const { start, end, seats } = availabilityException.attributes;
                              return (
                                <div key={availabilityException.id.uuid} className={css.exception}>
                                  <div className={css.exceptionHeader}>
                                    <div className={css.exceptionAvailability}>
                                      <div
                                        className={classNames(css.exceptionAvailabilityDot, {
                                          [css.isAvailable]: seats > 0,
                                        })}
                                      />
                                      <div className={css.exceptionAvailabilityStatus}>
                                        {seats > 0 ? (
                                          <FormattedMessage id="EditListingAvailabilityPanel.exceptionAvailable" />
                                        ) : (
                                          <FormattedMessage id="EditListingAvailabilityPanel.exceptionNotAvailable" />
                                        )}
                                      </div>
                                    </div>
                                    <button
                                      className={css.removeExceptionButton}
                                      onClick={() =>
                                        onDeleteAvailabilityException({
                                          id: availabilityException.id,
                                        })
                                      }
                                    >
                                      <IconClose size="normal" className={css.removeIcon} />
                                    </button>
                                  </div>
                                  <TimeRange
                                    className={css.timeRange}
                                    startDate={start}
                                    endDate={end}
                                    dateType={DATE_TYPE_DATETIME}
                                    timeZone={availabilityPlan.timezone}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {exceptionCount <= MAX_EXCEPTIONS_COUNT ? (
                          <InlineTextButton
                            className={css.addExceptionButton}
                            onClick={event => {
                              event.stopPropagation();
                              event.preventDefault();
                              setIsEditExceptionsModalOpen(true);
                            }}
                            disabled={disabled}
                            ready={ready}
                          >
                            <FormattedMessage id="EditListingAvailabilityPanel.addException" />
                          </InlineTextButton>
                        ) : null}
                      </section>
                    ) : null}
                  </td>
                </tr>

                <tr id="location" className={currentTab === 'location' ? null : css.hiddenTab}>
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.locationNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <LocationAutocompleteInputField
                      className={css.locationAddress}
                      inputClassName={css.locationAutocompleteInput}
                      iconClassName={css.locationAutocompleteInputIcon}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      name="location"
                      id="location"
                      label={addressTitleMessage}
                      placeholder={addressPlaceholderMessage}
                      useDefaultPredictions={false}
                      format={identity}
                      valueFromForm={values.location}
                    />
                  </td>
                </tr>

                <tr id="contact" className={currentTab === 'contact' ? null : css.hiddenTab}>
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.contactNav" />
                    {/* <Tooltip title={<FormattedMessage id="EditExpertForm.sectionRequired" />}>
                      <span className={css.required}>*</span>
                    </Tooltip> */}
                  </td>
                  <td className={css.sectionContent}>
                    <FieldTextInput
                      className={css.secondRowElement}
                      type="tel"
                      id="phone"
                      name="phone"
                      label={phoneLabel}
                      placeholder={phonePlaceholder}
                      // validate={phoneRequired}
                    />
                  </td>
                </tr>

                <tr id="industries" className={currentTab === 'industries' ? null : css.hiddenTab}>
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.industriesNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <div>
                      <div className={css.software} id="EditProjectForm.industries">
                        {industriesLabel}
                      </div>
                      {industriesField}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            {submitFailed && (
              <span className={css.formError}>
                <FormattedMessage id="NewOfferForm.formInvalid" />
              </span>
            )}

            <div className={css.buttonGroup} id="save">
              {!currentUserExists && currentTab === 'description' && isNew ? null : (
                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  ready={submitReady || projectDeleted}
                >
                  {currentTab === 'description' && isNew && !currentUserExists ? (
                    <FormattedMessage id="EditProjectForm.goToNextTab" />
                  ) : (
                    saveActionMsg
                  )}
                </PrimaryButton>
              )}
              {/* {isNew
                ? <SecondaryButton
                  className={css.submitButton}
                  ready={submitReady}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSaveAsDraft(values)
                  }}>
                  {createDraftMessage}
                </SecondaryButton>
                : null} */}
              {currentTab !== 'contact' ? (
                <SecondaryButton
                  className={css.submitButton}
                  disabled={buttonDisabled && isNew}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onGoToNextTab();
                  }}
                >
                  <FormattedMessage id="EditProjectForm.goToNextTab" />
                </SecondaryButton>
              ) : null}

              {onManageDisableScrolling ? (
                <Modal
                  id="EditAvailabilityPlan"
                  isOpen={isEditPlanModalOpen}
                  onClose={() => setIsEditPlanModalOpen(false)}
                  onManageDisableScrolling={onManageDisableScrolling}
                  containerClassName={css.modalContainer}
                  usePortal
                >
                  <EditListingAvailabilityPlanForm
                    formId="EditListingAvailabilityPlanForm"
                    listingTitle={currentListing.attributes.title}
                    availabilityPlan={availabilityPlan}
                    weekdays={WEEKDAYS}
                    initialValues={initialValues}
                    inProgress={updateInProgress}
                    fetchErrors={fetchErrors}
                    values={values}
                    onSavePlan={() => handleSaveAvailability(values)}
                  />
                </Modal>
              ) : null}
              {onManageDisableScrolling ? (
                <Modal
                  id="EditAvailabilityExceptions"
                  isOpen={isEditExceptionsModalOpen}
                  onClose={() => setIsEditExceptionsModalOpen(false)}
                  onManageDisableScrolling={onManageDisableScrolling}
                  containerClassName={css.modalContainer}
                  usePortal
                >
                  <EditListingAvailabilityExceptionForm
                    formId="EditListingAvailabilityExceptionForm"
                    onSubmit={values => {
                      saveException(values);
                    }}
                    timeZone={availabilityPlan.timezone}
                    availabilityExceptions={sortedAvailabilityExceptions}
                    updateInProgress={updateInProgress}
                    fetchErrors={fetchErrors}
                  />
                </Modal>
              ) : null}
            </div>
          </Form>
        );
      }}
    />
  );
};

const mapStateToProps = state => {
  const { currentUser, currentUserExpertListing, fetchingExpertListingInProgress } = state.user;
  return {
    currentUser,
    currentUserExpertListing,
    fetchingExpertListingInProgress,
  };
};

EditExpertFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  isAdmin: false,
};

EditExpertFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: object.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  currentUserExists: bool.isRequired,
};

export default compose(connect(mapStateToProps), injectIntl)(EditExpertFormComponent);
