import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.css';

const Tab = props => {
  const { className, id, disabled, text, selected, linkProps, listingCategory, centerTab, tooltip } = props;
  const linkClasses = classNames(centerTab ? css.centerLink : css.link, {
    [css.selectedLink]: selected && !centerTab,
    [css.selectedLinkCenter]: selected && centerTab,
    [css.disabled]: disabled,
  });

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps} category={listingCategory}>
          {text}
          {tooltip}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const { className, rootClassName, tabRootClassName, tabs, listingCategory, label, centerTab, selectedLabel } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = !!centerTab ? css.tabCenter : tabRootClassName || css.tab;
  return (
    <div className={classes}>
        {label ? (<span className={selectedLabel ? css.selectedLabel : css.label}>{label}</span>) : null}
    <nav className={classes}>
      {tabs.length ? tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return <Tab key={id} id={id} className={tabClasses} {...tab} listingCategory={listingCategory} centerTab={centerTab}/>;
      }) : <Tab className={tabClasses} id={tabs.id ? tabs.id : `${1}`} {...tabs} listingCategory={listingCategory} centerTab={centerTab}/>}
    </nav>
    </div>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
