import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './InviteUserForm.css';

const InviteUserFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        form,
        intl,
        userAlreadyInvited,
        userAlreadyAllowed,
        userSuccessfullyInvited,
        isAdmin,
        clearInvitation,
        isPendingApproval
      } = fieldRenderProps;

      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = isPendingApproval;

      return (
        <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
          <div>
            <h1>
              <FormattedMessage id={isAdmin
                ? "InviteUserForm.inviteUserToSoftwareSuppHeader"
                : "InviteUserForm.inviteCoworkersHeader"} />
            </h1>
            <p>
              <FormattedMessage id={isAdmin
                ? "InviteUserForm.inviteUserToSoftwareSuppSubheader"
                : "InviteUserForm.inviteCoworkersSubheader"} />
            </p>
            <FieldTextInput
              className={css.field}
              type="email"
              name="allowedUserEmail"
              id="allowedUserEmail"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              onClick={() => clearInvitation()}
            />

            <div className={css.alreadyInvited}>
              {userAlreadyInvited ?
                <FormattedMessage id="InviteUserForm.userAlreadyInvited" />
                : userAlreadyAllowed ? 
                <FormattedMessage id="InviteUserForm.userAlreadyAllowed" />
                  : null
              }
            </div>
            {userSuccessfullyInvited ?
              <div className={css.successfullyInvited}>
                <FormattedMessage id="InviteUserForm.userSuccessfullyInvited" />
              </div> : null}

            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id={isAdmin ? "InviteUserForm.inviteUser" : "InviteUserForm.inviteCoworker"} />
              {
                isPendingApproval
                && 
                <Tooltip interactive title={<FormattedMessage id="ProjectBoardPage.noInvite" />}>
                  <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
                </Tooltip>
              }
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

InviteUserFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

InviteUserFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const InviteUserForm = compose(injectIntl)(InviteUserFormComponent);

InviteUserForm.displayName = 'InviteUserForm';

export default InviteUserForm;
