import React from 'react';
import { Linkedin, Calendar, Link } from 'react-feather';
import { FormattedMessage } from '../../util/reactIntl';
import { Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import css from './ListingCard.css';

const tooltipStyles = {
  tooltip: {
      width: "200px",
  }
};
const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

const SectionProfileLinksMaybe = props => {
  const { linkedin, calendly, web, className } = props;

  const tooltipsNumber = !!linkedin + !!calendly + !!web;
  let tooltipId = 0;
  if(tooltipsNumber === 1) {
    if(linkedin) {
      tooltipId = 1;
    } else if(calendly) {
      tooltipId = 2;
    } else if(web) {
      tooltipId = 3;
    }
  } else if(tooltipsNumber === 2) {
    if(linkedin && calendly) {
      tooltipId = 4;
    } else if(linkedin && web) {
      tooltipId = 5;
    } else if(calendly && web) {
      tooltipId = 6;
    }
  } else if(tooltipsNumber === 3) {
    tooltipId = 7;
  }
  tooltipId = tooltipId > 0 && tooltipId < 8 ? "SectionProfileLinksMaybe.profileIconsDetails"+tooltipId : "SectionProfileLinksMaybe.profileIconsDetailsNone";

  return (linkedin || calendly || web) ? (
    <CustomTooltip title={<FormattedMessage id={tooltipId}  />} >
      <div className={className}>
        {linkedin ? <Linkedin className={css.icon} /> : null}
        {calendly ? <Calendar className={css.icon} /> : null}
        {web ? <Link className={css.icon} /> : null}
      </div>
    </CustomTooltip>
  ) : null;
};

export default SectionProfileLinksMaybe;