import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

const withCommission = (price, commission) => {
  return Math.ceil(price / (1-commission));
}

const withoutCommission = (price, commission) => {
  return Math.trunc(price - (price * commission));
}

// authorRole is parameter added due to negotiation specification
// so it shouldn't be used in different circumstances
export const parseScope = (values, commission, authorRole, negotiationType, currentCustomerIsAdmin) => {
    // New Scope Parsing, output: {scope, price}
    let scopeEntries = Object.entries(values).filter((entryArray) => {
      return entryArray[0].includes('scope');
    });
    let scopeTaskIds = scopeEntries.filter((entry) => {
      return entry[0].includes('scopeTaskNameInput') && entry[1] !== '';
    }).map((entry) => {
      return parseInt(entry[0].replace("scopeTaskNameInput", ""));
    }).sort((a,b) => {
      return a - b; // integers sort
    });
    let scope = [];
    let totalPrice = 0;
    let howManyTasks = scopeTaskIds.length;
    for(let i = 0; i < howManyTasks; i++) {
      let scopeTaskName = scopeEntries.find((entry) => {
        return entry[0].includes('scopeTaskNameInput'+scopeTaskIds[i])
      })[1];
      let scopeTaskTime = scopeEntries.find((entry) => {
        return entry[0].includes('scopeTaskTimeInput'+scopeTaskIds[i]);
      });
      scopeTaskTime = !!scopeTaskTime ? parseInt(scopeTaskTime[1]) : null;
      let scopeTaskPrice = scopeEntries.find((entry) => {
        return entry[0].includes('scopeTaskPriceInput'+scopeTaskIds[i]);
      });
      if(authorRole === 'client') {
        scopeTaskPrice = !!scopeTaskPrice ? (scopeTaskPrice[1]?.amount)/100 : null;
      } else {
        scopeTaskPrice = !!scopeTaskPrice ? scopeTaskPrice[1]?.amount/100 : null;
      }
      if(negotiationType === 'service') {
        scopeTaskPrice = withCommission(scopeTaskPrice, commission);
      } else if(!currentCustomerIsAdmin && authorRole === 'client') {
        scopeTaskPrice = withoutCommission(scopeTaskPrice, commission);
      }
      if(scopeTaskPrice) {
        totalPrice += scopeTaskPrice*100;
      }
      let scopeSubtaskIds = scopeEntries.filter((entry) => {
        return entry[0].includes('scopeTask'+scopeTaskIds[i]+'SubtaskNameInput') && entry[1] !== '';
      }).map((entry) => {
        return parseInt(entry[0].replace('scopeTask'+scopeTaskIds[i]+'SubtaskNameInput', ""));
      }).sort((a,b) => {
        return a - b; // integers sort
      });
      let howManySubtasks = scopeSubtaskIds.length;
      let scopeSubtasks = [];
      for(let j = 0; j < howManySubtasks; j++) {
        let scopeSubtaskName = scopeEntries.find((entry) => {
          return entry[0].includes('scopeTask'+scopeTaskIds[i]+'SubtaskNameInput'+scopeSubtaskIds[j]);
        })[1];
        let scopeSubtaskTime = scopeEntries.find((entry) => {
          return entry[0].includes('scopeTask'+scopeTaskIds[i]+'SubtaskTimeInput'+scopeSubtaskIds[j]);
        });
        scopeSubtaskTime = !!scopeSubtaskTime && scopeSubtaskTime.length > 1 ? parseInt(scopeSubtaskTime[1]) : null;
        let scopeSubtaskPrice = scopeEntries.find((entry) => {
          return entry[0].includes('scopeTask'+scopeTaskIds[i]+'SubtaskPriceInput'+scopeSubtaskIds[j]);
        });
        if(authorRole === 'client') {
          scopeSubtaskPrice = !!scopeSubtaskPrice && scopeSubtaskPrice.length > 1 ? (scopeSubtaskPrice[1]?.amount)/100 : null;
        } else {
          scopeSubtaskPrice = !!scopeSubtaskPrice && scopeSubtaskPrice.length > 1 ? scopeSubtaskPrice[1]?.amount/100 : null;
        }
        if(negotiationType === 'service') {
          scopeSubtaskPrice = withCommission(scopeSubtaskPrice, commission);
        } else if(!currentCustomerIsAdmin && authorRole === 'client') {
          scopeSubtaskPrice = withoutCommission(scopeSubtaskPrice, commission);
        }
        let scopeSubtaskItem = {};
        if(!!scopeSubtaskName) {
          scopeSubtaskItem['name'] = scopeSubtaskName;
        }
        if(!!scopeSubtaskTime) {
          scopeSubtaskItem['time'] = scopeSubtaskTime;
        }
        if(!!scopeSubtaskPrice) {
          scopeSubtaskItem['price'] = scopeSubtaskPrice;
        }
        scopeSubtasks.push(scopeSubtaskItem);
      }
      let scopeTask = {
        name: scopeTaskName,
        time: scopeTaskTime,
        price: scopeTaskPrice,
        subtasks: scopeSubtasks
      };
      scope.push(scopeTask);
    }
    totalPrice = Math.trunc((totalPrice/(1-commission))/100)*100;
    let price = new Money(totalPrice, config.currency);
    return {scope, price};
}