import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import OrderFormPdf from './assets/SoftwareSupport_OrderForm.pdf';
import FreelancerAgreement from './assets/SoftwareSupport_Freelancer_Agreement.pdf';
import { default as HT } from './HighlightedTooltip';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <h2>I. GENERAL PROVISIONS </h2>
      <h3>§1</h3>
      <h3>[General provisions]</h3>
      <ol>
        <li>
          The following General Terms and Conditions shall apply exclusively to all goods and
          services provided in relation to SoftwareSupp platform (
          <a href="https://SoftwareSupp.com">https://SoftwareSupp.com</a>,{' '}
          <strong>“SoftwareSupp platform”</strong>) arising out of the contractual relationship
          between SoftwareSupp sp. z o.o. with its registered office in Wroclaw, Poland (
          <strong>"SoftwareSupp"</strong> or <strong>“Service provider”</strong>) with the address
          for service: ul. Wróblewskiego 4 1A 50–068 Wrocław, entered into the registry of
          entrepreneurs of the National Court Registry under the KRS number 0000732460, with the
          registry files maintained by the District Court for Wroclaw – Fabryczna in Wroclaw, VI
          Commercial Division of the National Court Registry, with the NIP number: (tax identification number) 8992845287 
          and REGON number: (National Business Registry Number) 380268047, email address: support@softwaresupp.com, and the
          users of SoftwareSupp platform (<strong>“Users”</strong>), which may be collectively
          referred to as <strong>"Parties"</strong> or solely as <strong>"Party"</strong>.
        </li>
        <li>
          You must read, agree to, and accept all of the Terms and Conditions contained in this
          document in order to become User of the platform.{' '}
        </li>
        <li>
          This document indicates the frame Terms and Conditions for legal relationship between
          Parties.
        </li>
        <li>
          The main purpose of SoftwareSupp platform is to connect business entities (
          <strong>“Clients”</strong>) with professional software services (
          <strong>"Services"</strong>) and expert freelancers (<strong>“Experts”</strong>) in a
          specific field of expertise in order to implement specific projects with due diligence (
          <strong>“Order”</strong>).
        </li>
        <li>
          The present Terms and Conditions and all attachments hereto, together with the Order Form
          executed by Parties as well as other documents refer to the contractual rights and obligations 
          of a party to any contract as the contract between SoftwareSupp and Users.
        </li>
        <li>
          Softwaresupp and Users may conclude an Expert Sourcing Agreement on the basis of which Softwaresupp 
          provides Services in any form, including in writing or electronically form (<strong>"Agreement"</strong>).
        </li>
        <li>Each Order should be interpreted individually.</li>
        <li>
          The SoftwareSupp platform offers additional services which are specified in detail on the
          website (<a href="https://SoftwareSupp.com">https://SoftwareSupp.com</a>).
        </li>
        <li>
          SoftwareSupp reserves the right to amend this Terms and Conditions at any time with prior
          notification by posting the updated Terms and Conditions on the Website, providing notice
          on the Website or by sending the notice by email.
        </li>
        <li>
          SoftwareSupp will provide reasonable advance notice of any amendment that includes a
          substantial change by posting the updated Terms and Conditions on the Website, providing
          notice on the Website and by sending the notice by email.
        </li>
        <li>
          Once accepted terms and conditions cannot be changed by any side of this Agreement, unless
          all Parties expressly agree to specific changes.
        </li>
      </ol>
      <h3>§2</h3>
      <h3>[Essential principles of operation]</h3>
      <ol>
        <li>
          SoftwareSupp platform is designed to provide professional software services,{' '}
          <HT textId={'TermsOfService.Tooltip.2.1'}>
            connect and enable Experts to perform services to Clients
          </HT>{' '}
          as specified in Order where SoftwareSupp acts as the coordinating party for projects
          settled between Users, and further enables Users to successfully complete the service,
          settle transactions as well as provides tools, resources and support leading to successful
          project’s completion.
        </li>
        <li>
          SoftwareSupp verifies and certifies experts in terms of their know-how, skills and
          experience based on the{' '}
          <HT textId={'TermsOfService.Tooltip.2.2'}>designed and planned process</HT> presented on
          the SoftwareSupp website. The purpose of the verification and recruitment process is to
          ensure the highest quality of services delivered to the Client.
        </li>
        <li>
          Internal tools and resourcers become available once payment for a specific Order is
          transferred to the escrow bank account indicated by SoftwareSupp.
        </li>
        <li>
          <HT textId={'TermsOfService.Tooltip.2.4'}>
            All communications between Users related to the implementation of Order shall be carried
            out through internal system of communications provided by SoftwareSupp platform.
          </HT>{' '}
          Users can communicate outside of the communication system under no liability or penalty
          but in this case SoftwareSupp cannot guarantee the full deliverability of the services
          offered and cannot take responsibility for any damages resulting from the communication
          outside of the platform (e.g. password sharing). For projects where communication outside
          of the SoftwareSupp platform is required or convenient, key milestones and information
          should be communicated and recorded through the SoftwareSupp communication system.
        </li>
        <li>
          In larger projects or at Client's request, SoftwareSupp may participate in the actual
          implementation of specific Order or communication between Users. In this case, a project
          manager is delegated.{' '}
          <HT textId={'TermsOfService.Tooltip.2.5'}>
            The role of the project manager is to facilitate the contact between Users and ensure
            the smooth implementation of Order in the specified time frame.
          </HT>
        </li>
        <li>
          For the time period of the performed service, SoftwareSupp and the Expert agree to support
          customer's tasks related to the performed Order, by communicating and providing access to the
          dedicated task system with proper response/handling times:
          <ul className={css.dash}>
            <li>1h response time</li>
            <li>
              <HT textId={'TermsOfService.Tooltip.2.6'}>24h action time</HT>
            </li>
            <li>
              in case the action cannot be done within 24h time, SoftwareSupp will inform the Client
              about the estimated time to take action and will attempt to take action as
              quickly as possible
            </li>
          </ul>
        </li>
        <li>
          In the event of concluding Agreement, Client undertakes to pay the remuneration for the
          execution of Order and Expert undertakes to act with due diligence in order to implement
          Order.
        </li>
        <li>
          SoftwareSupp is the Party coordinating the implementation of Order, delivering know how
          necessary for successful project execution, including:
          <ul className={css.dash}>
            <li>Responses to tickets/queries;</li>
            <li>Project planning/scheduling;</li>
            <li>Expert materials and communication tools.</li>
          </ul>
        </li>
        <li>The liability of SoftwareSupp for tax liabilities of Users is fully excluded.</li>
        <li>The scope of operation of SoftwareSupp platform is territorially unlimited.</li>
        <li>The subject matter of Order is unlimited in terms of its subject matter.</li>
        <li>
          Users declare that they bear full tax liability due to the cooperation between Parties.
          Users are committed to act in full compliance with the appropriate tax law system. In
          particular, SoftwareSupp is not responsible for the payment of income tax or other tax
          liabilities by the Client or Expert.
        </li>
        <li>
          SoftwareSupp operates in accordance with the provisions of Polish law and in its operation
          meets all tax obligations related to services and the commission fee charged.
        </li>
      </ol>
      <h3>§3</h3>
      <h3>[Registration]</h3>
      <ol>
        <li>
          Once registered to the SoftwareSupp platform User acquires full access to its essential
          functionalities.
        </li>
        <li>
          In the case of a legal entity or other entity of similar type, the registration may be
          conducted by an authorized person.
        </li>
        <li>Data provided during the registration process shall be constantly updated.</li>
        <li>
          As a consequence of successful registration, User profile is created in accordance with
          voluntarily provided data and information. User profile is available for the public as
          well as other Users.
        </li>
        <li>
          Users are fully responsible for the truthfulness and compliance with the factual and legal
          status of the data and information provided during registration. SoftwareSupp attempts to
          verify data provided by the Users and runs a verification process for all Experts joining
          the SoftwareSupp platform.
        </li>
        <li>
          SoftwareSupp verifies knowledge, expertise and/or skills of Experts through its
          verification and certification process and has a right to instantly terminate the
          co-operation with the user providing fake or incomplete information.
        </li>
        <li>SoftwareSupp bears no liability for the payment capacity of Client.</li>
        <li>
          In order to implement Agreement, Expert agrees to make his or her personal data and image, 
          and content of his articles publicly available on SoftwareSupp platform free of charge.
        </li>
        <li>
          User who is a natural person possess the following rights related to the processing of
          personal data:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>the right to withdraw consent to the processing of personal data;</li>
            <li>the right to access personal data;</li>
            <li>the right to request the rectification of personal data,</li>
            <li>the right to request the deletion of personal data,</li>
            <li>the right to request the restriction of the processing of personal data.</li>
          </ol>
        </li>
        <li>It is forbidden to possess more than one User profile by the same person.</li>
      </ol>
      <h3>§4</h3>
      <h3>[Fees and payment]</h3>
      <ol>
        <li>
          Price for SoftwareSupp service is automatically included in the price of the Order
          presented to each Client for a particular Order.
        </li>
        <li>
          <HT textId={'TermsOfService.Tooltip.4.2'} noIcon>
            Users who have been connected with each other via SoftwareSupp platform cannot settle
            transactions outside of SoftwareSupp platform.
          </HT>{' '}
          Users who breached the above obligation shall be fully liable towards SoftwareSupp for
          damages and may be obliged to pay additional penalty fees including compensation to
          SoftwareSupp for the lost benefits and can be excluded from further usage of SoftwareSupp
          platform. In this case, SoftwareSupp is also allowed to withhold an initial deposit placed
          by the customer on project’s submission.
        </li>
        <li>
          <HT textId={'TermsOfService.Tooltip.4.3'}>
            Payment for Order by Client shall be made to the escrow account indicated by
            SoftwareSupp
          </HT>{' '}
          and is always settled upfront i.e. takes place before the execution of Order commences.
        </li>
        <li>SoftwareSupp’s escrow account is managed by Stripe, Inc.</li>
        <li>Payment for Order by Client is a condition for conclusion of Agreement.</li>
        <li>Payment is accepted in various currencies including USD, EUR, CHF.</li>
        <li>
          Payment for the completed Order to Expert takes place after the acceptance of its correct
          completion by Client.
        </li>
        <li>
          In the event of refusal to accept the correct completion of Order by Client, Client must
          provide the exact reasons for the refusal. In this case, as a rule, payment to Expert is
          withheld until the dispute is resolved. The dispute resolution procedure takes place in
          the manner described in §5.
        </li>
        <li>
          The payment may also be refunded to the customer within 14 days on his request if the
          service has not yet been commenced (meaning the Client has not posted the project or in 
          selected cases didn’t decide to launch the work
          or project with a SoftwareSupp Expert, either by launching a communication/work
          with a SoftwareSupp Expert or by accepting the project at SoftwareSupp platform,
          which are treated as an order commencement and act as an evidence of the Order delivery).
          A Client can request a refund by initiating a dispute. The dispute process is described
          in §5.
        </li>
        <li>
          Once the order has been commenced, project has been posted or 14 days have passed since the payment date, 
          a refund cannot be issued and the balance needs to be used for SoftwareSupp Experts’
          services.
        </li>
        <li>
          Billing rules
          <p>
            <HT textId={'TermsOfService.Tooltip.4.11'}>Clients’ are billed every month, by the hourly work used (hourly work * hourly rate), 
            the amount consumed by the Client for work or every 2 weeks for selected customers</HT>. 
            The billing period is set by SoftwareSupp at the beginning of co-operation.{' '}
          </p>
        </li>

        <li>
          Upgrade rules
          <p>
            If the amount of work reported or estimated by a certified freelancer exceeds the currently used plan, a plan can be upgraded to cover the ordered work or estimate.{' '}
          </p>
        </li>
        <li>
          Termination rules
          <p>
            Clients are entitled to terminate using the SoftwareSupp freelancers services by finishing their project and cancelling their account in the: Account settings -> User profile. 
            Clients can also send email to support@softwaresupp.com requesting account termination. 
            Clients with available balance and completed work can use their balance in the future within one year time by re-launching their service (posting a job or starting a plan).
          </p>
        </li>
        <li>
          Refund policy
          <p>
            SoftwareSupp pricing plans are refundable for new Clients within 14 days after the payment if the job has not been posted or if the customer has not decided to start work with a Certified Freelancer. 
            A Client can submit a complaint regarding a freelancer’s service with all disputes addressed in 14-day time.
          </p>
        </li>
      </ol>

      <h3>§5</h3>
      <h3>[Complaints &amp; Disputes]</h3>
      <ol>
        <li>
          <HT textId={'TermsOfService.Tooltip.5.1'}>
            Any User may submit a complaint regarding non-performance or improper performance of
            SoftwareSupp or improper performance of the Agreement.
          </HT>
        </li>
        <li>
          The complaint may be submitted to the e-mail address:{' '}
          <a href="mailto:support@softwaresupp.com">support@softwaresupp.com</a> or to the dedicated
          project manager.
        </li>
        <li>SoftwareSupp will make every effort to consider the complaint without delay.</li>
        <li>
          In the event of objections to the activities of the other Party to Agreement reported by
          either party, a dispute is initiated between Client and Expert via SoftwareSupp platform.
        </li>
        <li>
          In order to submit objections and initiate a dispute, Party must at least indicate the
          scope of the objections and indicate what he or she claims from the other Party.
        </li>
        <li>
          Within 7 days from the date of reporting the dispute, Party against whom the objections
          were raised has the right to take a written position regarding the dispute, in particular
          its scope and claims.
        </li>
        <li>
          SoftwareSupp, acting as a conciliator, will investigate the documentation provided and
          take a decision seeking an amicable settlement of the dispute.
        </li>
        <li>
          <HT textId={'TermsOfService.Tooltip.5.8'}>
            SoftwareSupp will make every effort to resolve the dispute between the parties within 14
            days from submitting a dispute.
          </HT>{' '}
          In exceptional cases, where the dispute cannot be settled and/or requires additional
          explanation/documentation, this time limit may be extended to 30 days. from the date of
          submitting the dispute.
        </li>
        <li>
          In the exceptional situation, when Order has not yet been performed,{' '}
          <HT textId={'TermsOfService.Tooltip.5.9'}>
            Client may request SoftwareSupp to replace Expert or Experts.
          </HT>{' '}
          SoftwareSupp shall make the necessary efforts to present one replacement candidate that
          would match the needs of Client. In the situation where it turns out to be impossible to
          present the replacement candidate SoftwareSupp may refund Client taking into account the
          degree of advancement of the performed Order.
        </li>
      </ol>
      <h3>§6</h3>
      <h3>[Liability]</h3>
      <ol>
        <li>
          <HT textId={'TermsOfService.Tooltip.6.1'}>
            Expert shall be liable towards SoftwareSupp and the Client
          </HT>{' '}
          for non-performance or improper performance of the obligations resulting from the services
          provided to implement Order as well as for infringement of copyright or other rights of
          third parties as well as intentional damage caused to Client.
        </li>
        <li>
          <HT textId={'TermsOfService.Tooltip.6.2'}>
            SoftwareSupp provides all the resources, quality monitoring standards, escrow mechanisms
            and verification/recruitment process to ensure successful service’s delivery until the
            Order completion date
          </HT>{' '}
          (the date of indicating the order as complete by the Client).
        </li>
        <li>
          The total liability towards Client and his or her clients for any claims, damages, costs
          or expenses related to the services provided to implement particular Order is limited for
          each Order up to the amount equal to the total price of SoftwareSupp service for
          performance of a specific Order.
        </li>
        <li>
          SoftwareSupp and the Expert bear no liability for issues or outages outside of the Order
          scope, such as Client’s technology or third party cloud solutions.
        </li>
        <li>
          Expert shall not be liable for damages or mistakes resulting from:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>incompliance of Client with the law;</li>
            <li>
              mistakes in internal procedures of Client or his/her clients transferred to
              SoftwareSupp or Expert in order to be applied as part of performance of Order;
            </li>
            <li>mistakes in instructions and indications specified in Order;</li>
            <li>
              mistakes in functioning of IT systems or other devices provided to Client
              indispensable to provide the services;
            </li>
            <li>
              providing Expert or SoftwareSupp by Client with incorrect information or inaccurate
              information or other incompleteness or inconsistency of data necessary to perform the
              given Order.
            </li>
            <li>software costs associated with the Order’s implementation</li>
          </ol>
        </li>
      </ol>
      <h2>II. DETAILED TERMS AND CONDITIONS FOR EXPERT</h2>
      <h3>§7</h3>
      <ol>
        <li>
          <HT textId={'TermsOfService.Tooltip.7.1'} noIcon>
            Expert declares to possess all the required authorizations, the necessary knowledge to
            perform the subject of Agreement as well as skills and experience necessary for the
            proper implementation of Order.
          </HT>
        </li>
        <li>
          Expert is fully responsible for the content and statements that are made while creating a
          User profile and then with conclusion of Agreement.
        </li>
        <li>
          Expert undertakes to perform Agreement with due diligence, taking into account the
          professional nature of the services provided in order to implement Order.
        </li>
        <li>
          Expert is obliged to perform the services for the benefit of Client in accordance with the
          standards, methodology and specifications determined by Client, provided that these rights
          do not constitute management within the meaning of Article 22 § 1, § 1<sup>1</sup>, § 1
          <sup>2</sup> of the Labor Code.
        </li>
        <li>
          Client undertakes to provide Expert with the specifications, instructions and standards
          required to perform Order.
        </li>
        <li>
          Experts are free to choose the methods and means by which they shall perform tasks
          assigned unless it is otherwise stipulated in Order.
        </li>
        <li>
          In the event that it is necessary to perform Order in additional time periods different
          from those specified in the Order, Expert shall immediately notify Client and Expert shall
          exercise due diligence to implement Order within the time period expected by Client.
        </li>
        <li>
          Expert hereby accepts and represents that:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              He/she commits to comply fully with the provisions of this Terms and Conditions;
            </li>
            <li>
              He/she agrees and accepts the fact that this Terms and Conditions define not only the
              rights and obligations of Users towards Service provider, but also create directly the
              rights and obligations between Expert and SoftwareSupp/Client including the liability
              of Expert towards Client for the quality and manner of performing the services under
              the particular Order;
            </li>
            <li>
              He/she accepts to perform services in accordance with Orders and to fulfil the
              obligations resulting from Agreement in full compliance with the relevant provisions
              of national and international law in particular tax law.
            </li>
          </ol>
        </li>
      </ol>
      <h3>§8</h3>
      <ol>
        <li>
          Client is responsible for compliance with factual circumstances and bears full liability
          for the content of the posted offer or project.
        </li>
        <li>
          Client undertakes to indicate in the posted offer/project all general and necessary
          specifications, instructions and standards required to perform the Order.
        </li>
        <li>
          Client is obliged amongst others to:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              provide Expert with source documents and other documents necessary for the proper and
              timely performance of the obligations specified in Agreement;
            </li>
            <li>pay the remuneration for implementation of Order;</li>
            <li>comply with the commitments resulting from this Agreement with due diligence;</li>
            <li>
              ongoing communication with Expert (such as providing information required to implement
              Order or granting access to IT systems) and to immediately inform the Expert about any
              risks and other problems occurring on the side of Client or the client of the Client,
              which may affect completing the Order in accordance with this Agreement;
            </li>
            <li>ongoing cooperation with SoftwareSupp in order to implement the Agreement.</li>
          </ol>
        </li>
        <li>
          The Client is obliged to constantly provide to Experts all of the data necessary to
          perform the tasks assigned to them and, if necessary, to inform SoftwareSupp or project
          manager dedicated by SoftwareSupp about the results of the work of Experts, i.e. about any
          threats resulting from deficiencies in skills of Experts, low performance of Experts, lack
          of due diligence on the side of Experts, deviations from the methodology, standards or
          specifications as well as about the lack of appropriate discipline among Experts.
        </li>
        <li>
          In the event of dissatisfaction (e.g. delay, errors, lack of due diligence) with the
          execution of Order by Expert during its implementation, Client should report this fact to
          SoftwareSupp or the appropriate project manager, if appointed. SoftwareSupp will make
          every effort to fix any issues or present the replacement candidate in accordance with §5
          of these Terms and Conditions. Also, on the Client's request a complaint can be
          launched.
        </li>
        <li>
          Client hereby accepts and represents that:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              He/she commits to comply fully with the provisions of these Terms and Conditions;
            </li>
            <li>
              He/she agrees and accepts the fact that these Terms and Conditions define the rights
              and obligations towards the Service provider.
            </li>
            <li>
              He/she accepts to fulfil the obligations resulting from Agreement in full compliance
              with the relevant provisions of national and international law.
            </li>
          </ol>
        </li>
      </ol>
      <h2>IV. SUPPLEMENTARY PROVISIONS</h2>
      <h3>§9</h3>
      <h3>[Protection of personnel]</h3>
      <ol>
        <li>In external relations Experts are treated as personnel of SoftwareSupp.</li>
        <li>
          If a Client and Expert intend to enter into a direct legal relationship such as an
          employment contract, contract of mandate, cooperation agreement or other similar, outside
          of the Agreement and with omission of SoftwareSupp, Users are obliged to inform
          SoftwareSupp as appropriate fees may apply.
        </li>
        <li>
          <HT textId={'TermsOfService.Tooltip.9.3'}>
            Client undertakes that during the term of Order and within 12 months after its
            expiration for any reason, without the consent of SoftwareSupp, shall not employ or use
            services on the basis of any legal relationship, directly or indirectly of Experts who
            performed Order or Orders, or other members of personnel of SoftwareSupp participating
            in the provision of services to Client, without obtaining the written consent of SoftwareSupp.
          </HT>{' '}
          The same applies to SoftwareSupp experts attempting to contract SoftwareSupp customers
          outside of SoftwareSupp acting as coordinating party.
        </li>
        <li>
          In the event of violation by Client of the restriction referred to in section 2 and 3
          above, the Client shall pay to SoftwareSupp the additional fee in the amount of the value
          of the project related to settlement between the Client and Expert for each separate case
          of violation. SoftwareSupp is not obliged to prove a damage in order to claim additional
          fee.
        </li>
      </ol>
      <h3>§10</h3>
      <h3>[License to SoftwareSupp platform]</h3>
      <ol>
        <li>Softwaresupp in connection with the conclusion of this Agreement, upon its signing, grants the Client a non-exclusive, non-transferable and worldwide license for on-line use of the Softwaresupp platform functionalities ("Platform").</li>
        <li>Softwaresupp grants the Client the right to temporary multiplication, i.e. to run the Platform on the end device via the Internet (on-line access) in order to use all its functionalities and to process and store their data via the Platform on Softwaresupp servers, provided that compliance with all provisions of this Agreement.</li>
        <li>The right to use all functionalities of the Platform applies only to the resulting version and includes in particular: connecting to the Softwaresupp server using user accounts made available to the Client, entering, displaying, using, transferring, storing and modifying the data entered into the Platform.</li>
        <li>The license does not include the right to pay or free of charge disseminating, publishing or making available the Platform in whole or in part or its copies to third parties who are not registered users of the Platform - including through lending, rental, lease, further license, making available on the network for download or in the form of a further subscription as a service of access to the Platform.</li>
        <li>The license is non-transferable and it is not possible to make the Platform available and transfer any rights under the Agreement to third parties without the written consent of Softwaresupp.</li>
        <li>The license granted is valid only for the duration of the Agreement for the use of the Platform and ceases to apply upon termination of the Agreement without the need for any additional statements in this regard by either party.</li>
        <li>In accordance to hereby Agreement, the Parties indicate that the Client is not entitled to any rights to the Platform other than those expressly set out in the Agreement.</li>
      </ol>
      <h3>§11</h3>
      <h3>[Copyright]</h3>
      <ol>
        <li>The Parties decided that in the event that as a result of the provision of Services to the Client 
        any works will be created within the meaning of art. 1 point 1 of the Act of February 4, 1994 on copyright 
        and related rights (,,Works "), then, <HT textId={'TermsOfService.Tooltip.11.2'}>upon payment of the Remuneration, 
        established in the Order the Expert will transfer to the Client all proprietary copyrights to the Works created</HT> 
        as part of the performance of the Services by the Expert for the Client, 
        for which the Remuneration has been paid, as well as the right to introduce any changes to the Works and making their studies, 
        including the right to allow third parties to make these changes or studies (dependent rights).
        The above-mentioned transfer of proprietary copyrights to the Works (including the right to exercise derivative rights to the Works) 
        does require conclude hereby Agreement and takes place in all fields of use described in art. 50 and 74 of the Copyright and Related Rights Act, in the fields including:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              in relation to computer programs – in the following fields of exploitation:
              <ul className={css.dash}>
                <li>
                  permanent or temporary multiplication of a computer program, in whole or in part,
                  by any means and in any form, including to the extent that it is necessary to
                  enter, display, use, transmit and store the computer program in order to multiply
                  the program;
                </li>
                <li>
                  translation, adaptation, layout change and any other changes in the computer
                  program;
                </li>
                <li>
                  dissemination, including lending for use or tenancy of a computer program or a
                  copy thereof.
                </li>
              </ul>
            </li>
            <li>
              in relation to other works – in the following fields of exploitation:
              <ul className={css.dash}>
                <li>recording in any form;</li>
                <li>
                  multiplication (also through the Internet), including on any audiovisual carrier
                  and in particular on a video carrier, a photosensitive tape, a magnetic tape and a
                  computer disk, and all types of media intended for digital recording;
                </li>
                <li>
                  borrowing, tenancy, lease of a work or exchanging carriers on which the work has
                  been recorded, use on websites and in multimedia works;
                </li>
                <li>
                  production of a specific copies of the work, including printing, reprographic,
                  magnetic recording and digital technique;
                </li>
                <li>
                  placing on the market (including the Internet), including repeated dissemination;
                </li>
                <li>
                  (in whole and in fragments) through its television broadcast in national programs;
                </li>
                <li>and foreign TV and satellite stations;</li>
                <li>public distribution (also on the Internet);</li>
                <li>
                  public performance, presentation, display, reproduction and broadcasting and
                  re-issuing, as well as public sharing in such a way that everyone can have access
                  to it in the place and time chosen;
                </li>
                <li>
                  translating, adapting layout changes or any other changes, modifying, creating
                  other works based on the work;
                </li>
                <li>
                  broadcasting via wired or wireless video or audio via terrestrial station or via
                  satellite;
                </li>
                <li>inserting into the computer's memory.</li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          Acquisition of author’s economic rights to works referred by these Terms and Conditions
          shall be each time on the date of payment of the remuneration due for implementation of
          Order on the basis of which such works were created. Until payment, Expert provides Client
          with the nonexclusive, unrestricted territorial license to use works created in the process
          of Order implementation. The license shall be granted for a definite period, until the
          date of payment to the Expert of the remuneration due for implementation of Order.
        </li>
        <li>
          Expert authorizes Client to perform for an unspecified period of time, on his or her
          behalf author’s moral rights to the works created in connection with implementation of
          Order.
        </li>
        <li>The obligations listed above shall be binding after Agreement is terminated.</li>
        <li>
          The remuneration determined under Agreement also includes remuneration for the transfer of
          author's economic right to work to the entirety of the works, derivative rights as well as
          their exploitation, including the transfer of rights in all separate fields of
          exploitation.
        </li>
      </ol>
      <h3>§12</h3>
      <h3>[Confidentiality, personal data]</h3>
      <ol>
        <li>
          <HT textId={'TermsOfService.Tooltip.12.1'}>
            Parties mutually undertake not to disclose to third parties during the term of Agreement
            and within 12 months after it expires any information being of confidential nature,
            received from the other Party at the course of performance of Agreement (
            <strong>"Confidential Information"</strong>). The obligation indicated above applies to
            all Confidential Information, irrespective of the carrier, manner and form of the
            transfer of Confidential Information.
          </HT>
        </li>
        <li>
          By the "Confidential Information" Parties understand information, documents, data,
          materials, etc. obtained in any form (oral, written or electronic) in conjunction with the
          implementation of this Agreement, regarding each of the Parties, its partners, contracting
          parties, co-workers, clients, with the exception of the information referred to in section
          below. In particular, the Confidential Information shall include all information and
          documents of a technical, technological and commercial nature or related to the
          organization of the undertaking and all other information and documents having economic
          value that are not widely known. In addition, as the Confidential Information shall be
          perceived all information regarding:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>commercial transactions,</li>
            <li>
              undertaking of each Party, its finances or information regarding branches and related
              entities,
            </li>
            <li>
              scientific and research works, project works, technological works, investments,
              inventions (patents) and utility models related to the business activity carried out
              by each of Parties,
            </li>
            <li>
              legal and commercial issues related to each of the Party, data related to contracting
              parties of each Party and their number, preparation and negotiations aimed at
              concluding agreements, legal, technical and economic conditions specified in the
              agreements and commercial correspondence, procedural and administrative letters, court
              rulings, administrative and administrative court rulings,
            </li>
            <li>the wording (content) of any agreements concluded by each Party.</li>
          </ol>
        </li>
        <li>
          As the Confidential Information shall not be perceived:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              information which, at the time of disclosure, was publicly known, without prejudice to
              the provisions of this Agreement by any Party,
            </li>
            <li>
              information in the possession of which Party has entered through a third party that is
              not bound by confidentiality obligation towards the other Party, provided that the
              information has been obtained without violating the provisions of Agreement by Party,
            </li>
            <li>
              information which was disclosed by any Party on the basis of a court decision or a
              decision of a competent public authority, in connection with any other ruling arising
              from legal provisions or pending proceedings, conducted by competent public
              authorities,
            </li>
            <li>
              information that has been disclosed by any Party, if such disclosure occurred with the
              prior written consent of the other Party,
            </li>
            <li>information relating to the fact of cooperation between Parties.</li>
          </ol>
        </li>
        <li>
          The Confidential Information that has been disclosed remains the exclusive property of the
          disclosing Party and their disclosure to the other Party is not equal to granting any
          right to possess, distribute or exploit the Confidential Information beyond the limits set
          out in this Agreement.
        </li>
        <li>
          In the situation of doubts as to the loss of confidential nature of the information
          transferred, Party shall inquire of other Party about the confidentiality status of such
          information, however, lack of a reply within 7 days shall mean that the information has
          lost its confidential nature.
        </li>
        <li>
          SoftwareSupp, as the data controller, entrusts Clients with the processing of the personal
          data of Experts (hereinafter referred to as <strong>“Personal Data”</strong>) solely for
          the purpose of proper performance of Agreement.
        </li>
        <li>
          SoftwareSupp is obliged to process Personal Data in such manner to comply with the
          provisions of the Regulation (EU) 2016/679 of the European Parliament and of the Council
          of 27 April 2016 on the protection of natural persons with regard to the processing of
          personal data and on the free movement of such data, and repealing Directive 95/46/EC
          (General Data Protection Regulation) (<strong>"GDPR"</strong>) and the Polish law
          concerning the protection of personal data, including all other applicable laws.
          SoftwareSupp declares that before commencement of processing Personal Data, it shall take
          technical and organizational measures to secure the personal data entrusted to it in
          accordance with the relevant provisions of law.
        </li>
        <li>
          Client shall perform only such operations on Personal Data which are aimed at the proper
          performance of Agreement. By processing Personal Data, Client undertakes to comply with
          the provisions on the protection of personal data and to apply legal measures required to
          protect this data.
        </li>
      </ol>
      <h3>§13</h3>
      <h3>[Duration of Agreement]</h3>
      <ol>
        <li>
          Agreement is concluded for a definite period of time stipulated in particular Order.
        </li>
        <li>
          The time period specified in the Order can be amended in case of any delays and/or changes
          in the expected delivery date of the Order.
        </li>
        <li>
          Users do not have the right to terminate Agreement unless exceptional, documented
          circumstances occurred such as force majeure, illness, sudden death of the close family
          member etc.
        </li>
        Client does have the right to terminate Agreement unless exceptional, documented
        circumstances occurred such as force majeure.
      </ol>
      <h3>§14</h3>
      <h3>[Force majeure]</h3>
      <ol>
        <li>
          Parties shall not be liable for partial or complete non-performance or improper
          performance of obligations resulting from Agreement caused by force majeure.
        </li>
        <li>
          Force majeure it shall be understood as events unknown to Parties at the moment of
          concluding Agreement, occurring independently from the will of Parties, and on which
          Parties have no influence nor could have anticipated such events. The cases of force
          majeure include in particular: war, terrorist attacks, natural disasters, disasters,
          epidemics, pandemics, strikes.
        </li>
      </ol>
      <h3>§15</h3>
      <h3>[Final provisions]</h3>
      <ol>
        <li>The law applicable to Agreement including implementation of Order is the Polish law.</li>
        <li>Parties undertake to endeavor to settle any disputes amicably through negotiations.</li>
        <li>
          In particular, Users are firsty obliged to exhaust the procedure from § 5 (Complaints
          &amp; Disputes).
        </li>
        <li>
          Any disputes related to the services provided through the SoftwareSupp platform including
          relation between Client and Expert, which cannot be resolved amicably, will be settled by
          the competent court for the registered office of SoftwareSupp.
        </li>
        <li>
          If any of the provisions of this Terms and Conditions is considered invalid by the final
          court decision, the remaining provisions shall remain in force. In place of the invalid
          provision, the provision corresponding to the intention of Parties will be applied.
        </li>
        <li>Attachments constitute the integral part of these Terms and Conditions.</li>
      </ol>
      <strong>Attachments:</strong>
      <ul className={css.dash}>
        <li>
          <NamedLink name="PrivacyPolicyPage">Privacy policy</NamedLink>
        </li>
        <li>
          <a href={OrderFormPdf} target="_blank" rel="noopener noreferrer">
            Order form
          </a>
        </li>
        <li>
          <a href={FreelancerAgreement} target="_blank" rel="noopener noreferrer">
            Freelancer Sourcing Agreement
          </a>
        </li>
      </ul>
      <strong>Last updated on: 1st January 2023</strong>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
