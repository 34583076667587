/* eslint-disable */
import React, { Component } from 'react';
import PropTypes, { number } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dayjs from 'dayjs'
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { MdKeyboardBackspace } from 'react-icons/md';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { setOpenTicket, clearTicket } from './ProjectBoardPage.duck';
import { json2csv } from 'json-2-csv';
import { downloadFile } from '../../util/downloadFile'
import {
  Page,
  LayoutSingleColumn,
  LayoutSideNavigation,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  TicketModal,
  IconSpinner,
  MessagesPanel,
  RepoModal,
  Sidebar,
  ListingLink,
  NamedLink,
} from '../../components';
import { SolutionForm } from '../../forms';
import {
  loadData,
  setInitialValues,
  showMessageOwner,
  showUser,
  fetchListingHasInvoice,
  closeStateListing,
  fetchProjectTransaction,
  showConnectedTickets,
} from './ProjectBoardPage.duck';
import { ensureListing, ensureOwnListing } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { TopbarContainer } from '../../containers';
import {
  parse,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { LISTING_STATE_PENDING_APPROVAL } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { changeTicketData, addUserToTicket, addRepo, fetchProject } from '../../util/api';
import { newAnswerNotOwn, newAnswer, newMention } from '../../util/triggerMail';
import { calculateToFormat, parseTime } from '../../util/helpers';
import SectionProjectInfo from './SectionProjectInfo';
import SectionProjectProgress from './SectionProjectProgress';
import SectionTickets from './SectionTickets';
import SectionExpert from './SectionExpert';
import SectionButtons from './SectionButtons';

import css from './ProjectBoardPage.css';
import SectionNotifications from './SectionNotifications';

const { bool, func, object, shape } = PropTypes;

const { UUID } = sdkTypes;

const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};

const calculateTicketHours = tickets => {
  if (tickets) {
    let estimatedHours = 0;
    let completedHours = 0;
    tickets.forEach(ticket => {
      if (ticket.attributes.publicData.estimationHours) {
        const formattedEstimationHours =
          typeof ticket.attributes.publicData.estimationHours === number
            ? ticket.attributes.publicData.estimationHours
            : parseTime(ticket.attributes.publicData.estimationHours);
        estimatedHours += formattedEstimationHours;
      }
      if (ticket.attributes.publicData.completionHours) {
        const formattedCompletionHours =
          typeof ticket.attributes.publicData.completionHours === number
            ? ticket.attributes.publicData.completionHours
            : parseTime(ticket.attributes.publicData.completionHours);
        completedHours += formattedCompletionHours;
      }
    });

    return {
      estimatedHours,
      completedHours,
    };
  }
  return {};
};

export class ProjectBoardPageComponent extends Component {
  constructor(props) {
    super(props);
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    this.props.staticContext.notfound = true;
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChangeTicketAnswer = this.handleChangeTicketAnswer.bind(this);
    this.handleSolution = this.handleSolution.bind(this);
    this.onSubmitRepo = this.onSubmitRepo.bind(this);
    this.handleSidebarVisibility = this.handleSidebarVisibility.bind(this);

    this.state = {
      ticketModalOpen: false,
      connectedTransaction: null,
      connectedExperts: null,
      adminTransaction: null,
      conversationItems: [],
      isRepoModalOpen: false,
      repoList: [],
      projectState: null,
      sidebarVisible: false,
      currentUserExpertListing: null,
      listingPaid: false,
      ticketIdOpen: null,
      isEdit: false,
    };
  }

  async componentDidUpdate(prevState) {
    const {
      currentUser,
      params,
      location,
      getListing,
      getOwnListing,

      fetchingExpertListingInProgress,
      currentUserExpertListing,
      fetchingProjectTransactionInProgress,
      projectTransaction,
      onFetchProjectTransaction,
      ticketToOpen,
 
    } = this.props;

    if (fetchingExpertListingInProgress === false && this.state.hasExpertProfile === undefined) {
      this.setState({ hasExpertProfile: !!currentUserExpertListing });
    }

    const listingId = new UUID(params.id);
    const isPendingApproval = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    let listingProject = null;
    if (isPendingApproval) {
      listingProject = getOwnListing(listingId);
    } else {
      listingProject = getListing(listingId);
    }

    if (listingProject) {
      let currentProject = isPendingApproval
        ? ensureOwnListing(listingProject)
        : ensureListing(listingProject);

      if (!isPendingApproval && currentProject?.author?.id.uuid === currentUser?.id.uuid) {
        currentProject = ensureOwnListing(getOwnListing(listingId));
      }

      if ((parse(location.search)?.id || ticketToOpen) && !this.state.ticketModalOpen)
        this.setState({ ticketModalOpen: true });
      if (!parse(location.search)?.id && !ticketToOpen && this.state.ticketModalOpen)
        this.setState({ ticketModalOpen: false });

      if (
        !fetchingProjectTransactionInProgress &&
        !this.state.connectedTransaction &&
        currentProject?.attributes.publicData.transactionId &&
        currentUser
      ) {
        if (projectTransaction) {
          this.setState({ connectedTransaction: projectTransaction });
        } else {
          onFetchProjectTransaction({
            userId: currentUser.id.uuid,
            includeCustomers: true,
            projectId: currentProject.attributes.publicData.transactionId,
          });
        }
      }
      if (
        !this.state.adminTransaction &&
        currentProject?.attributes.publicData.adminTransactionId &&
        currentUser
      ) {
        const project = await fetchProject({
          userId: currentUser.id.uuid,
          includeCustomers: true,
          projectId: currentProject.attributes.publicData.adminTransactionId,
        });
        this.setState({ adminTransaction: project });
      }

      if (
        currentProject?.attributes.publicData.answers?.length > 0 &&
        this.state.conversationItems.length === 0
      ) {
        const { fetchedAnswers, onShowAnswerAuthor, onShowUser } = this.props;
        const answers = fetchedAnswers;
        let conversationItems = [];
        if (fetchedAnswers) {
          for (let i = 0; i < answers.length; i++) {
            if (!answers[i].type) {
              const foundSameExpert = !!answers[i].expertId
                ? conversationItems.find(
                    answer => answer.listing?.data.id.uuid === answers[i].expertId
                  )
                : null;
              const listingData =
                answers[i].expertId && foundSameExpert
                  ? { data: foundSameExpert.listing }
                  : answers[i].expertId
                  ? await onShowAnswerAuthor(answers[i].expertId, true)
                  : null;
              const userId = new UUID(`${answers[i].userId}`);
              const foundSameUser = conversationItems.find(
                answer => answer.userId === answers[i].userId
              );
              const userData =
                answers[i].userId && !foundSameUser ? await onShowUser(userId) : null;
              conversationItems[i] = {
                userId: answers[i].userId,
                listing: listingData ? listingData.data : null,
                user:
                  answers[i].userId && foundSameUser
                    ? foundSameUser.user
                    : answers[i].userId
                    ? userData.data
                    : null,
                solution: answers[i].solution,
                date: answers[i].date,
                txId: answers[i].txId,
              };
            } else conversationItems[i] = answers[i];
          }
        }
        if (this.state.conversationItems.length !== conversationItems.length) {
          this.setState({
            conversationItems: conversationItems,
          });
        }
      }
    }
  }

  switchNotifications = async (currentListing, type) => {
    const {
      currentUser,
    } = this.props;
    const changeTicketBody = {
      type: 'change-notifications',
      listingId: currentListing.id.uuid,
      userId: currentUser.id.uuid,
      notificationsType: type,
    };
    let status;
    await changeTicketData(changeTicketBody).then(res => {
      status = 'success';
    }).catch(err => {
      status = 'error';
    });
    return status;
  };

  handleChangeTicketAnswer(reverse_index, type, body, currentListing, currentUser) {
    const index = reverse_index;
    const changeTicketBody = {
      index,
      type,
      listingId: currentListing.id.uuid,
      userId: currentUser.id.uuid,
      body,
    };
    changeTicketData(changeTicketBody).then(() => {
      if (type === 'delete') {
        this.setState({
          conversationItems: this.state.conversationItems.filter(
            (answer, i) => i > index || i < index
          ),
        });
      } else {
        const answers = this.state.conversationItems;
        answers[index].completionHours = body.completionHours;
        this.setState({ answers: answers });
      }
    });
    
  }

  // ADDING ANSWER TO TICKET
  handleSolution(values, form, isOwnListing, userConnectedToTicket, expertConnectedToTicket) {
    const {
      params: rawParams,
      currentUser,
      currentUserExpertListing,
      getListing,
      getOwnListing,
      connectedExperts,
    } = this.props;

    const id = new UUID(rawParams.id);
    const isPendingApproval = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const currentListing = isPendingApproval
      ? ensureOwnListing(getOwnListing(id))
      : ensureListing(getListing(id));

    const { solution } = values;

    if (!solution || solution?.length === 0) return null;

    const formattedOffer = solution.replace(/(\r\n|\n|\r)/gm, '\n');

    const expertsAnswered =
      currentListing.attributes.publicData.answers
        ?.filter(answer => !!answer.expertUserId && answer.expertUserId !== currentUser.id.uuid)
        ?.map(expertAnswer => expertAnswer.expertUserId)
        ?.filter(distinct) ?? [];

    const expertsOnProject = connectedExperts?.map(
      expert => expert.included?.find(element => element.type === 'user')?.id.uuid
    );

    const formattedExperts = [...expertsAnswered, expertsOnProject]
      .filter(distinct)
      .filter(expert => expert !== currentUser.id.uuid);

    const ticketSolution = {
      listingId: rawParams.id,
      userId: currentUser.id.uuid,
      type: 'add-answer',
      body: expertConnectedToTicket
        ? {
            solution: formattedOffer,
            expertId: currentUserExpertListing.id.uuid,
            date: new Date().toString(),
            expertUserId: currentUser.id.uuid,
            userId: currentUser.id.uuid,
          }
        : {
            userId: currentUser.id.uuid,
            solution: formattedOffer,
            date: new Date().toString(),
          },
    };
    changeTicketData(ticketSolution).then(() => {
      this.handleNewMention(formattedOffer, currentListing);
      const currentAnswers = this.state.conversationItems;
      ticketSolution.body.listing = currentUserExpertListing;
      ticketSolution.body.user = currentUserExpertListing ? null : currentUser;
      currentAnswers.push(ticketSolution.body);
      this.setState({ conversationItems: currentAnswers });

    
      newAnswer(currentListing, solution, currentUser);
      if (
        formattedExperts?.length > 0 ||
        currentListing.attributes.publicData.invitedUsers?.length > 0
      )
        newAnswerNotOwn(
          currentListing,
          formattedOffer,
          !expertConnectedToTicket
            ? currentUser.attributes.profile.displayName.slice(0, -2)
            : currentUserExpertListing.attributes.title,
          formattedExperts
        );
    });
    if (form) setTimeout(form.reset);
  }

  handleNewMention(formattedOffer, currentListing) {
    const { currentUser } = this.props;
    const nonWhiteSpaceSequence = /([^\s]+)/gi;
    const mailRegex = /([A-Za-z0-9_\-+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})/gi; //eslint-disable-line

    const mentionedEmails = formattedOffer
      .split(nonWhiteSpaceSequence)
      .map(word => {
        if (word.match(mailRegex)) {
          return word.match(mailRegex);
        }
        return null;
      })
      ?.filter(mail => !!mail)
      ?.flat(1);

    const formattedMails = currentListing?.attributes.publicData.invitedUsers
      ? mentionedEmails.filter(
          mail =>
            !currentListing.attributes.publicData.invitedUsers.find(
              currentUser => mail === currentUser
            )
        )
      : mentionedEmails;

    if (formattedMails?.length === 0) {
      const body = {
        userId: currentUser.id.uuid,
        listingId: currentListing.id.uuid,
        emails: formattedMails,
        isProject: true,
      };

      let mentionMailed = newMention(mentionedEmails, currentListing, currentUser, formattedOffer, true);
      if(mentionMailed===undefined){
        addUserToTicket(body);
      }
    }
  }

  handleCloseModal() {
    const { history, params, onClearTicket } = this.props;
    const isPendingApproval = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const projectBoardPathName = isPendingApproval ? 'ProjectBoardPageVariant' : 'ProjectBoardPage';
    let projectBoardParams = { id: params.id };
    if (isPendingApproval) {
      projectBoardParams.variant = LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    }
    this.setState({ isEdit: false });
    onClearTicket();
    this.setState({ alreadyLoaded: false, listings: [] });
  }

  onSubmitRepo(values, form) {
    const { currentUser, params, getListing, getOwnListing } = this.props;

    const listingId = new UUID(params.id);
    const isPendingApproval = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const currentProject = isPendingApproval
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

    const body = {
      userId: currentUser.id.uuid, // user who provided offer for job listing
      listingIdPaidFor: currentProject.id.uuid,
      transactionId: currentProject.attributes.publicData.transactionId,
      newRepo: {
        repoTitle: values.repoLinkTitle,
        repoLink: values.repoLink,
      },
      isProjectWithoutOffer: !currentProject.attributes.publicData.transactionId,
    };
    addRepo(body).then(() => {
      form.reset();
      let repoList;
      if (this.state.repoList?.length > 0) {
        repoList = this.state.repoList;
      } else {
        const listingRepo = currentProject?.attributes.publicData.repository ?? [];
        repoList = [...listingRepo];
      }
      if (repoList)
        repoList.push({
          repoTitle: values.repoLinkTitle,
          repoLink: values.repoLink,
        });
      this.setState({ repoList: repoList });
    });
  }

  handleSidebarVisibility(state) {
    this.setState({ sidebarVisible: state });
  }

  render() {
    const {
      intl,
      scrollingDisabled,
      currentUser,
      connectedTickets,
      history,
      onManageDisableScrolling,
      currentUserExpertListing,
      currentUserHasExpertListing,
      currentUserHasUnpublishedExpertListing,
      params,
      getListing,
      getOwnListing,
      listingInvoiceUrl,
      connectedExperts,
      onCloseListing,
      projectClosed,
      onSetOpenTicket,
      onClearTicket,
      ticketToOpen,
      onShowConnectedTickets,
      currentUserStripeAccounts,
    } = this.props;

    const expertsOnProject = connectedExperts?.map(
      expert => expert.included?.find(element => element.type === 'user')?.id.uuid
    );

    const listingId = new UUID(params.id);
    const isPendingApproval = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const currentProject = isPendingApproval
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

    const projectBoardPathName = isPendingApproval ? 'ProjectBoardPageVariant' : 'ProjectBoardPage';
    let projectBoardParams = { id: params.id };
    if (isPendingApproval) {
      projectBoardParams.variant = LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    }
    const projectTitle = currentProject.attributes.title;
    const projectDescription = currentProject.attributes.description;
    const projectScope = currentProject.attributes.publicData.scope;

    const title = intl.formatMessage({
      id: 'ProjectBoardPage.title',
    });
    const projectBoardLoading = intl.formatMessage({
      id: 'ProjectBoardPage.loading',
    });
    if (!currentProject.id || !currentUser?.id || this.state.hasExpertProfile === undefined)
      return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn>
            <LayoutWrapperTopbar>
              <TopbarContainer />
            </LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <div className={css.loadingWrapper}>
                <h1>{projectBoardLoading}</h1>
                <IconSpinner className={css.infoIcon} />
              </div>
            </LayoutWrapperMain>
          </LayoutSingleColumn>
        </Page>
      );

    const ticketId = parse(this.props.location.search)?.id;

    const status = parse(this.props.location.search)?.status;
    const transactionId = currentProject.attributes.publicData.transactionId;

    const { publicData } = currentProject?.attributes;

    const isDraft = status === LISTING_PAGE_DRAFT_VARIANT;
    const isSolved = this.state.projectState === 'closed' || publicData.state === 'closed';
    const isInProgress =
      publicData.state === 'inProgress' || publicData.state === 'serviceInProgress';
    const isOwnListing = currentProject?.author?.id.uuid === currentUser?.id.uuid;
    // excluding services and support plans for displaying NamedLink to offer in SectionExpert:
    const isJobInProgress = publicData.state === 'inProgress';

    let currentTab;
    if (isInProgress) {
      currentTab = 'projects-ongoing';
    } else if (isSolved) {
      currentTab = 'projects-completed';
    }

    const userConnectedToProject = !!currentProject.attributes.publicData.invitedUsers?.find(
      mail => mail === currentUser?.attributes.email
    );

    // connected by adding to PUBLIC DATA:
    const expertAssignedByData = currentProject.attributes.publicData.expert?.includes(
      currentUserExpertListing?.id.uuid
    );

    

    const expertConnectedToProject = currentUserExpertListing && expertAssignedByData;

    //console.log('currentUserExpertListing',currentUserExpertListing,currentProject.attributes);

   
    const offerIdProtected = this.state.connectedTransaction?.attributes.protectedData
      .offerTransactionId;
    const offerIdMeta = this.state.connectedTransaction?.attributes.metadata.offerTransactionId;
    const usdAccount=currentUserStripeAccounts?.find((el)=>(el.currency === 'usd'))
    const hasUnpaidBalance = usdAccount?.balance > 0
    const offerId =
      offerIdProtected ?? offerIdMeta ?? currentProject.attributes.publicData.transactionId;
    const transactionLink = (
      <NamedLink
        name="BillingPage"
        params={{
          
        }}
      >
        link
      </NamedLink>
    );

    const downloadReport = () => {
      const getStatus = (state) => {
        switch(state) {
          case 'open':
            return 'To Do'
          case 'inProgress':
            return 'In Progress'
          case 'closed':
            return 'Completed'
          default:
            return state
        }
      }

      const subtractReportTimes = (newTime, oldTime) => {
        const newTimeMinutes = parseTime(newTime)
        const oldTimeMinutes = parseTime(oldTime)

        return calculateToFormat(newTimeMinutes - oldTimeMinutes)
      }

      const expertPrice = connectedExperts
        ? connectedExperts.length > 0
          ? connectedExperts[0].data.attributes.price.amount / 100
          : 0
        : 0;


      const entries = connectedTickets.data.reduceRight((entries, ticket) => {
        const ticketEntries = []
        ticket.attributes.publicData.answers.reduce((acc, answer) => {
          if (answer.type === 'status-changed') {
            acc.lastStatus = answer.additionalInfo
          } else if (answer.type === "completion-hours-and-update") {
            const timeReported = acc.previousHoursReport ? subtractReportTimes(answer.additionalInfo, acc.previousHoursReport['Overall Time Reported']) : answer.additionalInfo
            const hoursReport = {
              'Task Title': ticket.attributes.title,
              'Task Description': ticket.attributes.description,
              'Date': dayjs(answer.date).toISOString(),
              'Task Status': getStatus(acc.lastStatus),
              'Time Reported': timeReported,
              'Overall Time Reported': answer.additionalInfo,
              'Cost [USD]': (parseTime(timeReported) * expertPrice * 2) / 60,
              'Overall Cost [USD]': (parseTime(answer.additionalInfo) * expertPrice * 2) / 60
            }
            acc.previousHoursReport = hoursReport
            ticketEntries.push(hoursReport)
          }
          return acc
        }, {
          lastStatus: 'open',
          previousHoursReport: null
        })

        if (!ticketEntries.length) {
          const completionHours = ticket.attributes.publicData.completionHours ? ticket.attributes.publicData.completionHours : undefined
          ticketEntries.push({
            'Task Title': ticket.attributes.title,
            'Task Description': ticket.attributes.description,
            'Date': dayjs(ticket.attributes.publicData.answers[0].date).toISOString(),
            'Task Status': getStatus(ticket.attributes.publicData.state),
            'Time Reported': completionHours ? completionHours : '',
            'Overall Time Reported': completionHours ? completionHours : '',
            'Cost [USD]': completionHours ? (parseTime(completionHours) * expertPrice * 2) / 60 : 0,
            'Overall Cost [USD]': completionHours ? (parseTime(completionHours) * expertPrice * 2) / 60 : 0,
          })
        }
        entries = [...entries, ...ticketEntries]
        return entries
      }, [])
      json2csv(entries, (err, csv) => {
        downloadFile(csv, `${projectTitle}-report.csv`, 'text/csv')
      })
    }

    const setEdit = edit => {
      this.setState({ isEdit: edit });
    };
    const showProject =
      isOwnListing ||
      userConnectedToProject ||
      expertConnectedToProject ||
      currentUser?.attributes.profile?.publicData.adminPrivileges?.includes(
        'browse-project-boards'
      ) ||
      connectedTickets?.data.filter(ticket =>
        ticket.attributes.publicData?.invitedTicketUsers?.includes(currentUser.attributes.email)
      )?.length > 0;
    // && (isInProgress || isSolved);

    const { estimatedHours, completedHours } = calculateTicketHours(connectedTickets?.data);

    if (!showProject) {
      history.push(
        createResourceLocatorString('ManageListingsPage', routeConfiguration(), {
          tab: 'projects-ongoing',
          page: 1,
        })
      );
      return null;
    } else {
      return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
          <LayoutSideNavigation>
            <LayoutWrapperTopbar>
              <TopbarContainer />
            </LayoutWrapperTopbar>
            <LayoutWrapperSideNav
              className={classNames(
                currentUser ? css.navigation : css.navigationNone,
                !this.state.sidebarVisible && css.navigationHidden
              )}
            >
              <Sidebar
                tab={currentTab}
                isAdmin={false}
                isExpert={currentUserHasExpertListing}
                isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
                isVisible={this.state.sidebarVisible}
                setVisibility={this.handleSidebarVisibility}
              />
            </LayoutWrapperSideNav>
            <LayoutWrapperMain>
              <div className={css.mainWrapper}>
                <div className={css.mainSection}>
                  <ListingLink className={css.goToProjectButton} listing={currentProject}>
                    <MdKeyboardBackspace />
                    <FormattedMessage id="ProjectBoardPage.goToProject" />
                  </ListingLink>

                  <SectionProjectInfo
                    projectTitle={projectTitle}
                    projectDescription={projectDescription}
                    projectScope={projectScope}
                    listingId={listingId}
                    isPendingApproval={isPendingApproval}
                  />

                  {isSolved ? (
                    <div className={css.projectSolvedWrapper}>
                      <FormattedMessage id="ProjectBoardPage.projectSolvedInfo" />
                    </div>
                  ) : null}

                  {/* {isOwnListing && hasUnpaidInvoice ? (
                    <div className={css.projectSolvedWrapper}>
                      <FormattedMessage id="ProjectBoardPage.unpaidInvoiceInfo" />
                    </div>
                  ) : null} */}

                  {isOwnListing && hasUnpaidBalance ? (
                    <div className={css.projectSolvedWrapper}>
                      <FormattedMessage
                        id="ProjectBoardPage.invoiceNotGenerated"
                        values={{ br: <br />, link: transactionLink }}
                      />
                    </div>
                  ) : null}

                  <SectionTickets
                    tickets={connectedTickets}
                    ticketPathName={projectBoardPathName}
                    ticketPathParams={projectBoardParams}
                    isSolved={projectClosed || isSolved}
                    onSetOpenTicket={onSetOpenTicket}
                    onClearTicket={onClearTicket}
                    connectedExperts={connectedExperts}
                    expertConnectedToProject={expertConnectedToProject}
                    setEdit={setEdit}
                  />
                  <div className={css.messages} id="messages">
                    <MessagesPanel
                      answers={this.state.conversationItems || null}
                      currentListing={currentProject}
                      isOwnListing={isOwnListing}
                      currentUserExpertListing={
                        currentUserHasExpertListing ? currentUserExpertListing : null
                      }
                      currentUser={currentUser}
                      isSolved={projectClosed || isSolved}
                      onChangeTicketAnswer={(index, type, body) =>
                        this.handleChangeTicketAnswer(
                          index,
                          type,
                          body,
                          currentProject,
                          currentUser
                        )
                      }
                      isProject
                    />
                    {currentUser && !(projectClosed || isSolved) ? (
                      <SolutionForm
                        className={css.solutionForm}
                        formId="solutionForm"
                        submitButtonWrapperClassName={css.submitButtonWrapper}
                        onSubmit={(values, form) =>
                          this.handleSolution(
                            values,
                            form,
                            isOwnListing,
                            userConnectedToProject,
                            expertConnectedToProject
                          )
                        }
                        isOwnListing={isOwnListing}
                        hasExpertProfile={currentUserHasExpertListing}
                        expertListingIsPendingApproval={
                          currentUserExpertListing?.attributes.state ===
                          LISTING_STATE_PENDING_APPROVAL
                        }
                        userConnectedToTicket={userConnectedToProject}
                      />
                    ) : null}
                  </div>
                </div>
                <div className={css.asideDesktop}>
                  <SectionProjectProgress
                    estimatedHours={estimatedHours}
                    completedHours={completedHours}
                    currentProject={currentProject}
                    connectedTickets={connectedTickets?.data}
                    connectedExperts={connectedExperts}
                    expertConnectedToProject={expertConnectedToProject}
                    currentUser={currentUser}
                    currentUserStripeAccounts={currentUserStripeAccounts}
                    intl={intl}
                    currentUserExpertListing={currentUserExpertListing}
                  />
                  <div className={css.expertAndButtons}>
                    <SectionExpert
                      isExpertOnProject={expertConnectedToProject}
                      expertsListingsData={connectedExperts}
                      offerId={offerId}
                      isProjectSolved={projectClosed || isSolved}
                      isJobInProgress={isJobInProgress}
                      isProjectOwner={isOwnListing}
                    />
                    <SectionNotifications
                      currentProject={currentProject}
                      switchNotifications={this.switchNotifications}
                      currentUserId={currentUser.id.uuid}
                    />
                    <SectionButtons
                      closeListing={onCloseListing}
                      estimatedHours={estimatedHours}
                      currentUserStripeAccounts={currentUserStripeAccounts}
                      completedHours={completedHours}
                      connectedTickets={connectedTickets?.data}
                      connectedExperts={connectedExperts}
                      expertConnectedToProject={expertConnectedToProject}
                      showSubmitCredentialsButton={!expertConnectedToProject}
                      isInProgress={isInProgress && !isSolved}
                      onOpenRepoModal={() => this.setState({ isRepoModalOpen: true })}
                      onDownloadReport={downloadReport}
                      currentUser={currentUser}
                      adminTransaction={this.state.adminTransaction}
                      currentProject={currentProject}
                      transactionId={transactionId}
                      onManageDisableScrolling={onManageDisableScrolling}
                      isProjectOwner={isOwnListing}
                      isProjectSolved={projectClosed || isSolved}
                      hasAdminTransaction={
                        !!currentProject?.attributes.publicData.adminTransactionId
                      }
                      isPendingApproval={isPendingApproval}
                      listingInvoiceUrl={listingInvoiceUrl}
                      transactionLink={transactionLink}
                    />
                  </div>
                </div>
              </div>

              {ticketId || ticketToOpen ? (
                <TicketModal
                  id="TicketModal"
                  isOpen={this.state.ticketModalOpen}
                  onCloseModal={listingId => this.handleCloseModal(listingId)}
                  onEditButton={() => setEdit(true)}
                  onClearTicket={onClearTicket}
                  connectedExperts={connectedExperts}
                  expertConnectedToProject={expertConnectedToProject}
                  currentProject={currentProject}
                  onShowConnectedTickets={onShowConnectedTickets}
                  handleRedirect={(id, status) => {
                    setEdit(false);
                    if (history.location.search !== '') {
                      history.push(
                        createResourceLocatorString(
                          `${projectBoardPathName}`,
                          routeConfiguration(),
                          projectBoardParams,
                          { id: id, status: status }
                        )
                      );
                    }
                  }}
                  onManageDisableScrolling={onManageDisableScrolling}
                  listingId={ticketId ? ticketId : ticketToOpen}
                  isEdit={this.state.isEdit}
                  isDraft={isDraft}
                  transactionId={transactionId}
                  isProjectSolved={projectClosed || isSolved}
                  expertsOnProject={expertsOnProject}
                />
              ) : null}

              <RepoModal
                id="RepoModal"
                isOpen={this.state.isRepoModalOpen}
                onCloseModal={() => this.setState({ isRepoModalOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                isProvider={!!expertConnectedToProject}
                onSubmitRepo={this.onSubmitRepo}
                project={currentProject}
                repoList={this.state.repoList}
                isProjectSolved={projectClosed || isSolved}
                isPendingApproval={isPendingApproval}
              />
             
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSideNavigation>
        </Page>
      );
    }
  }
}

ProjectBoardPageComponent.defaultProps = {
  staticContext: {},
};

ProjectBoardPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    connectedTickets,
    fetchedAnswers,
    listingInvoiceUrl,
    fetchingInvoiceInProgress,
    connectedExperts,
    projectClosed,
    listingData,
    fetchingProjectTransactionInProgress,
    projectTransaction,
    ticketToOpen,
  } = state.ProjectBoardPage;
  const {
    currentUser,
    currentUserFetched,
    currentUserExpertListing,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    fetchingExpertListingInProgress,
    currentUserStripeAccounts,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserFetched,
    connectedTickets,
    currentUserExpertListing,
    fetchedAnswers,
    listingData,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    listingInvoiceUrl,
    fetchingInvoiceInProgress,
    fetchingExpertListingInProgress,
    connectedExperts,
    ticketToOpen,
    getListing,
    getOwnListing,
    projectClosed,
    fetchingProjectTransactionInProgress,
    projectTransaction,
    currentUserStripeAccounts,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onShowAnswerAuthor: listingId => dispatch(showMessageOwner(listingId)),
  onShowUser: userId => dispatch(showUser(userId)),
  onFetchListingHasInvoice: listingId => dispatch(fetchListingHasInvoice(listingId)),
  onCloseListing: listingId => dispatch(closeStateListing(listingId)),
  onFetchProjectTransaction: data => dispatch(fetchProjectTransaction(data)),
  onSetOpenTicket: ticket => dispatch(setOpenTicket(ticket)),
  onClearTicket: () => dispatch(clearTicket()),
  onShowConnectedTickets: listingId => dispatch(showConnectedTickets(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ProjectBoardPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProjectBoardPageComponent);

ProjectBoardPage.setInitialValues = initialValues => setInitialValues(initialValues);
ProjectBoardPage.loadData = loadData;

export default ProjectBoardPage;
