import React, { useEffect } from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import Accordion from 'react-bootstrap/Accordion';
import ProjectItem from './ProjectItem';

import css from './BillingPage.css';
// userId, userEmail, userExpertListingId, currentUserPrivileges, category, isAdmin;
const UserOngoingProjectsPanel = props => {
  const {
    currentUser,
    userStripeAccounts,
    onManageDisableScrolling,
    history,
    ongoingProjects,
   
    projects,
    increaseBalance,
    onFetchCurrentUserStripeAccounts,
    reload,
    setReload,
    filterContion
  } = props;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, reload, ongoingProjects]);


  function checkStatus(data){
    let status;
   if(data.attributes.state === 'pendingApproval' && data.attributes.publicData.state === 'open'){
      status = "awaiting"
    } else
    if(data.attributes.state === 'published' && (data.attributes.publicData.state === 'open' || data.attributes.publicData.state === 'awaiting')){
      status = "open"
    } else if(data.attributes.state === 'published' && data.attributes.publicData.state === 'closed') {
      status = "closed"
    }

    return status;
  }


  const awaitingProjects = projects.filter(
    data =>
      data.attributes.state === 'pendingApproval' && data.attributes.publicData.state === 'open'
  );
  const openProjects = projects.filter(
    data => data.attributes.state === 'published' && (data.attributes.publicData.state === 'open' || data.attributes.publicData.state === 'awaiting')
  );
  const closedProjects = projects.filter(
    data => data.attributes.state === 'published' && data.attributes.publicData.state === 'closed'
  );

  



  let projectList
  
  if(filterContion === "closed"){
    projectList = closedProjects
  } else if(filterContion === "inProgress"){
    projectList = ongoingProjects

  }
  else if(filterContion === "open"){
    projectList = openProjects
  } else if(filterContion === "waiting") {
    projectList = awaitingProjects
  } else {
    projectList = [...ongoingProjects,...openProjects, ...awaitingProjects, ...closedProjects]
  }


  const ognoingProjectsPanel = (
    <div>
      <div>
        {projectList.length === 0 ? (
          <p className={css.loadingInfo}>
            <FormattedMessage id="BillingPage.noOngoinProjects" />
          </p>
        ) : (
        <Accordion defaultActiveKey="0" className={css.accClassCorrect}>
          {projectList.map((project, id) => {
              return (
                <Accordion.Item eventKey={id} className={css.AccItemListCorrection}>
                <Accordion.Header>{project.title ? project.title : project?.attributes?.title}</Accordion.Header>
                  <Accordion.Body>
                  

                  <ProjectItem
                    onFetchCurrentUserStripeAccounts={onFetchCurrentUserStripeAccounts}
                    setReload={setReload}
                    pub_state={project?.title ? 'ongoing' : checkStatus(project) }
                    history={history}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                    userStripeAccounts={userStripeAccounts}
                    project={project}
                  />
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </div>
           </div>
  );

  return <div>{ognoingProjectsPanel}</div>;
};

export default injectIntl(UserOngoingProjectsPanel);
