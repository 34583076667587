import React from 'react';
import {
  Modal,
  PrimaryButton,
  NamedLink
} from '../../components';
import { SendFileForm } from '../../forms';


import css from './StripePayoutPage.css';

export const VerificationModal = (props) => {
  const {
    alternativeModalOpen,
    setAlternativeModalOpen,
    onManageDisableScrolling,
    sendFile,
    fileSent
  } = props;

  const content = !fileSent ? (
    <>
      <p className={css.alternativeInfoMain}>Please verify your identity by sending a photo/photos with your face and document visible.</p>
      <span className={css.alternativeInfoNote}>Identity verification is performed to deliver top quality service to our customers</span>
      <SendFileForm
        formId={'TransactionPanel.SendMessageForm'}
        rootClassName={css.sendMessageForm}
        onFocus={() => {}}
        onBlur={() => {}}
        onSubmit={sendFile}
      />
    </>   
  ) : (
    <>
    <p className={css.alternativeInfoMain}>Now your profile and identity will be verified by us. You will receive information about positive verification within 24h to your email address. Until then, we recommend that you check the current open projects for which you will be able to apply after positive verification.</p>
    
    <NamedLink name="SearchPage_jobs" className={css.linkButton}>
      <PrimaryButton className={css.projectButton}>
        Check current projects
      </PrimaryButton>
    </NamedLink>
    </>

  );

  return (
    <Modal
    id="StripePayoutPage.verificationModal"
    containerClassName={css.rootModalContent}
    contentClassName={css.modalContent}
    isOpen={alternativeModalOpen}
    onClose={() => setAlternativeModalOpen(false)}
    onManageDisableScrolling={onManageDisableScrolling}
    usePortal
  >
    <h1>Identity verification</h1>
    {content}            
  </Modal>
  )
}       