import { useState, useEffect } from 'react';

const Delayed = (props) => {
  const {
    children,
    waitBeforeShow
  } = props;
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};

export default Delayed;