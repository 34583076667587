import React, { useState, useEffect } from 'react';
import { bool, func, object, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
// import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  createSlug,
  getListingType,
} from '../../util/urlHelpers';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  EditProjectWizard,
  Page,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  Footer,
  EditPageSidebar,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { sendJobUpdatedMail } from '../../util/triggerMail';

import {
  requestCreateListingDraft,
  requestPublishListingDraft,
  requestUpdateListing,
  requestImageUpload,
  updateImageOrder,
  removeListingImage,
  loadData,
  clearUpdatedTab,
  createTicketsFromListing,
} from './EditProjectPage.duck';
import css from './EditProjectPage.css';
// import  hiredByImage  from '../../assets/hiredby.png'
// import hiredByMobileImage from '../../assets/hiredbymobile.png'

const { UUID } = sdkTypes;

const listingUpdatedNotifications = (onUpdateListing, listing, currentUser) => {
  const lastUpdatedAt = Date.parse(listing.attributes.publicData.lastUpdatedAt);
  const hourAgo = new Date();
  hourAgo.setMinutes(hourAgo.getMinutes() - 30);
  if (!lastUpdatedAt || lastUpdatedAt < hourAgo) {
    onUpdateListing({
      id: listing.id,
      publicData: { lastUpdatedAt: new Date().toString() },
    }).then(() => sendJobUpdatedMail(listing, currentUser));
  }
};

const MAX_MOBILE_SCREEN_WIDTH = 768;

// const StepNavigation  = props => {
//   const { title, completed, current } = props;

//   return (
//     <div className={css.step}>
//       <div className={css.pointedLine}>
//         <div className={classNames(css.point, completed && css.pointCompleted)}/>
//         <div className={classNames(css.line, completed && css.lineCompleted)}/>
//       </div>
//       <div className={classNames(css.stepTitle, current && css.stepTitleCurrent)}>
//         {title}
//       </div>
//     </div>
//   )
// }

// N.B. All the presentational content needs to be extracted to their own components
export const EditProjectPageComponent = props => {
  const {
    currentUser,
    currentUserHasExpertListing,
    getOwnListing,
    history,
    location,
    intl,
    onCreateListingDraft,
    onPublishListingDraft,
    onUpdateListing,
    onImageUpload,
    onRemoveListingImage,
    onManageDisableScrolling,
    onUpdateImageOrder,
    onChange,
    page,
    params,
    scrollingDisabled,
    onCreateTicketsFromListing,
  } = props;

  const initialValues = location?.state?.initialValues;

  const { id, type, referralNumber } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const listingId =
    (page.submittedListingId?.uuid === id ? page.submittedListingId : null) ||
    (id ? new UUID(id) : null);
  const listing = getOwnListing(listingId);
  const currentListing = ensureOwnListing(listing);
  const { title } = currentListing.attributes;
  const { state: currentListingState } = currentListing.attributes;
  const listingSlug = currentListingState ? createSlug(title) : 'draft';

  // Sprawdzanie czy formularz dodoaje czy edytuje
  //const inEditMode = listingId.uuid === '00000000-0000-0000-0000-000000000000' ? false : true;
  const inEditMode = false;

  // Ustawianie kolejnosci Tabów w EditMode / NewMode
  let tabs = ['software', 'skills', 'budget'];

  if (inEditMode) {
    tabs = ['description', 'links', 'photos', 'location', 'software', 'skills', 'budget', 'scope'];
  } else {
    tabs = ['software', 'skills', 'budget'];

    if (currentUser) {
      tabs.push('scope');
      tabs.push('links');
      tabs.push('location');
      tabs.push('photos');
      tabs.unshift('description');
    } else {
      tabs.push('user');
      tabs.unshift('description');
    }
  }

  const [currentTab, setCurrentTab] = useState(tabs[0]);

  useEffect(() => {
    if (location.hash === '#contact') {
      setCurrentTab('user');
    }
  }, [location.hash]);

  const handleNext = id => {
    let foundId = null;
    if (!id) {
      foundId = tabs.indexOf(currentTab) + 1;
    }
    window.scrollTo(0, 0);
    setCurrentTab(tabs[id ?? foundId]);
  };
  const handlePrev = id => {
    let foundId = null;
    if (!id) {
      foundId = tabs.indexOf(currentTab) - 1;
    }
    window.scrollTo(0, 0);
    setCurrentTab(tabs[id ?? foundId]);
  };
  const handleClick = (id, tabId = '', fluidScroll = false) => {
    let foundId = null;
    if (!id) {
      foundId = tabs.indexOf(currentTab) + 1;
    }
    if (fluidScroll) {
      let yposition = document.getElementById(tabId).offsetTop;
      window.scrollTo({ top: yposition - 100, behavior: 'smooth' });
      window.scrollTo({ top: yposition - 100, behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }
    setTimeout(() => {
      setCurrentTab(tabs[id ?? foundId]);
    }, 500);
  };

  const {
    createListingDraftError = null,
    publishListingError = null,
    updateListingError = null,
    showListingsError = null,
    uploadImageError = null,
  } = page;
  const errors = {
    createListingDraftError,
    publishListingError,
    updateListingError,
    showListingsError,
    uploadImageError,
  };
  // TODO: is this dead code? (shouldRedirect is checked before)
  const newListingPublished =
    isDraftURI && currentListing && currentListingState !== LISTING_STATE_DRAFT;

  // Show form if user is posting a new listing or editing existing one
  const disableForm = page.redirectToListing && !showListingsError;

  // Images are passed to EditListingForm so that it can generate thumbnails out of them
  const currentListingImages = currentListing && currentListing.images ? currentListing.images : [];

  // Images not yet connected to the listing
  const imageOrder = page.imageOrder || [];

  const unattachedImages = imageOrder.map(i => page.images[i]);

  const allImages = currentListingImages.concat(unattachedImages);
  const removedImageIds = page.removedImageIds || [];
  const images = allImages.filter(img => {
    return !removedImageIds.includes(img.id);
  });

  const pageTitle = isNewListingFlow
    ? intl.formatMessage({ id: 'EditProjectPage.titleCreateListing' })
    : intl.formatMessage({ id: 'EditProjectPage.titleEditListing' });
  const tabStyles = {
    '--index': tabs.indexOf(currentTab) + 1,
    '--indexLength': tabs.length,
  };

  const goBack =
    typeof window !== 'undefined' && !inEditMode ? (
      <div className={css.mobileProgress}>
        <div className={css.mobileProgressSection}>
          <div className={css.goBack}>
            <span onClick={() => history.goBack()} className={css.goBackButton}>
              <ArrowLeft className={css.goBackIcon} />
              Back
            </span>
          </div>
          <div className={css.mobileProgress}>
            {tabs.indexOf(currentTab) + 1}/{tabs.length}
          </div>
        </div>
        <div className={css.mobileProgressBar} style={tabStyles}></div>
      </div>
    ) : null;

  const showForm = isNewURI || currentListing.id;
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'quotation',
    });
  }
  if (showForm) {
    return (
      <Page title={pageTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={css.topbar}
              mobileRootClassName={css.mobileTopbar}
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
          </LayoutWrapperTopbar>

          <LayoutWrapperSideNav className={css.navigation}>
            <EditPageSidebar
              slug={listingSlug}
              id={listingId.uuid}
              type={getListingType(type)}
              tabArray={tabs}
              handleScroll={handleClick}
              currentTabIndex={tabs.indexOf(currentTab)}
              disabledPagesIndex={null}
              EditFormMode={inEditMode}
            />
          </LayoutWrapperSideNav>

          <LayoutWrapperMain className={css.main}>
            {typeof window !== 'undefined' && window.innerWidth < MAX_MOBILE_SCREEN_WIDTH ? (
              <div className={css.mobileBar}>{goBack}</div>
            ) : null}

            <div className={css.container}>
              <EditProjectWizard
                id="EditListingWizard"
                className={css.wizard}
                params={params}
                disabled={disableForm}
                errors={errors}
                newListingPublished={newListingPublished}
                history={history}
                images={images}
                listing={currentListing}
                listingCategory="job"
                onUpdateListing={values => {
                  return onUpdateListing(values).then(response => {
                    if (!isNewListingFlow)
                      listingUpdatedNotifications(onUpdateListing, currentListing, currentUser);
                    return response;
                  });
                }}
                onCreateListingDraft={values =>
                  onCreateListingDraft(values, currentUser?.attributes.email, currentUser?.id.uuid)
                }
                handlePublishListing={onPublishListingDraft}
                onImageUpload={onImageUpload}
                onUpdateImageOrder={onUpdateImageOrder}
                onRemoveImage={onRemoveListingImage}
                onChange={onChange}
                currentUser={currentUser}
                currentUserHasExpertListing={currentUserHasExpertListing}
                onManageDisableScrolling={onManageDisableScrolling}
                updateInProgress={page.updateInProgress || page.createListingDraftInProgress}
                referralNumber={referralNumber}
                type={getListingType(type)}
                initialValuesFromClientPath={initialValues}
                currentTab={currentTab}
                currentTabIndex={tabs.indexOf(currentTab)}
                tabArray={tabs}
                onGoToNextTab={handleNext}
                onGoToPrevTab={handlePrev}
                onCreateTicketsFromListing={onCreateTicketsFromListing}
              />
            </div>
          </LayoutWrapperMain>

          {/* <LayoutWrapperSideNav className={css.navigation}> */}

          {/* </LayoutWrapperSideNav> */}

          {/* <LayoutWrapperMain className={css.main}> */}

          {/* </LayoutWrapperMain> */}
          {/* <div class={css.imageBlock}> */}
          {/* <img src={hiredByImage} className={css.hiredBy}/>
            <img src={hiredByMobileImage} className={css.hiredByMobile} /> */}
          {/* </div> */}

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  } else {
    // If user has come to this page through a direct linkto edit existing listing,
    // we need to load it first.
    const loadingPageMsg = {
      id: 'EditProjectPage.loadingListingData',
    };
    return (
      <Page title={intl.formatMessage(loadingPageMsg)} scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
        <div className={css.placeholderWhileLoading} />
      </Page>
    );
  }
};

EditProjectPageComponent.defaultProps = {
  currentUser: null,
  currentUserHasOrders: null,
  listing: null,
  listingDraft: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

EditProjectPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  getOwnListing: func.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  onCreateListingDraft: func.isRequired,
  onPublishListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveListingImage: func.isRequired,
  onUpdateListing: func.isRequired,
  onChange: func.isRequired,
  page: object.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
  }).isRequired,
  scrollingDisabled: bool.isRequired,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const page = state.EditProjectPage;

  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasExpertListing,
    currentUserExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;

  const getOwnListing = id => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);

    return listings.length === 1 ? listings[0] : null;
  };
  return {
    currentUser,
    currentUserListing,
    currentUserHasExpertListing,
    currentUserListingFetched,
    getOwnListing,
    page,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserExpertListing,
    currentUserHasUnpublishedExpertListing,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateListing: values => dispatch(requestUpdateListing(values)),
  onCreateListingDraft: (values, email, id) =>
    dispatch(requestCreateListingDraft(values, email, id)),
  onPublishListingDraft: listingId => dispatch(requestPublishListingDraft(listingId)),
  onImageUpload: data => dispatch(requestImageUpload(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateImageOrder: imageOrder => dispatch(updateImageOrder(imageOrder)),
  onRemoveListingImage: imageId => dispatch(removeListingImage(imageId)),
  onChange: () => dispatch(clearUpdatedTab()),
  onCreateTicketsFromListing: (userId, listingId) =>
    dispatch(createTicketsFromListing(userId, listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EditProjectPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(EditProjectPageComponent));

EditProjectPage.loadData = loadData;

export default EditProjectPage;
