import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import classNames from 'classnames';
import getCountryCodes from '../../translations/countryCodes';
import config from '../../config';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, NamedLink } from '../../components';
import {
  validPolandTaxNumber,
  positiveNumber,
  required,
  composeValidators,
} from '../../util/validators';

import css from './PaymentForm.css';

const PaymentFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        intl,
        values,
        currentUser,
        createStripePaymentInProgress,
        createStripeCustomerInProgress,
        defaultMessage,
        version,
      } = fieldRenderProps;

      const countryCodes = getCountryCodes(config.locale);
      const termsLink = (
        <NamedLink name="TermsOfServicePage" className={css.legalLink}>
          <FormattedMessage id="JobApplicationForm.termsOfUse" />
        </NamedLink>
      );
      const countryLabel = intl.formatMessage({ id: 'PaymentForm.countryLabel' });
      const countryPlaceholder = intl.formatMessage({ id: 'PaymentForm.countryPlaceholder' });
      const restOfCountriesPlaceholder = intl.formatMessage({
        id: 'PaymentForm.restOfCountriesPlaceholder',
      });

      const currencyLabel = intl.formatMessage({ id: 'PaymentForm.currencyLabel' });
      const currencyPlaceholder = intl.formatMessage({ id: 'PaymentForm.currencyPlaceholder' });

      const postalCodeLabel = intl.formatMessage({ id: 'PaymentForm.postalCodeLabel' });
      const postalCodePlaceholder = intl.formatMessage({ id: 'PaymentForm.postalCodePlaceholder' });

      const cityLabel = intl.formatMessage({ id: 'PaymentForm.cityLabel' });
      const cityPlaceholder = intl.formatMessage({ id: 'PaymentForm.cityPlaceholder' });

      const vatNumber = intl.formatMessage({ id: 'PaymentForm.vatNumber' });
      const vatNumberPlaceholder = intl.formatMessage({ id: 'PaymentForm.vatNumberPlaceholder' });
      const notValidTaxNumberMessage = intl.formatMessage({
        id: 'PaymentForm.notValidTaxNumberMessage',
      });

      const addressLine1Label = intl.formatMessage({ id: 'PaymentForm.addressLine1Label' });
      const addressLine1Placeholder = intl.formatMessage({
        id: 'PaymentForm.addressLine1Placeholder',
      });

      const addressLine2Label = intl.formatMessage({ id: 'PaymentForm.addressLine2Label' });
      const addressLine2Placeholder = intl.formatMessage({
        id: 'PaymentForm.addressLine2Placeholder',
      });

      const nameLabel = intl.formatMessage({ id: 'PaymentForm.nameLabel' });
      const namePlaceholder = intl.formatMessage({
        id: 'PaymentForm.namePlaceholder',
      });

      const emailLabel = intl.formatMessage({ id: 'PaymentForm.emailLabel' });
      const additionalEmailLabel = intl.formatMessage({ id: 'PaymentForm.additionalEmailLabel' });
      const emailPlaceholder = intl.formatMessage({
        id: 'PaymentForm.emailPlaceholder',
      });

      const errorPrice = intl.formatMessage({ id: 'PaymentForm.errorPrice' });
      const priceLabel = intl.formatMessage({ id: 'PaymentForm.priceLabel' });
      const pricePlaceholder = intl.formatMessage({
        id: 'PaymentForm.pricePlaceholder',
      });
      const classes = classNames(rootClassName || css.root, className);

      console.log('cu', values);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FaFileInvoiceDollar size={40} color="#3c88f8" />

          <FormattedMessage id="PaymentForm.title" />
          {version === 'depositBalance' ? (
            <FieldTextInput
              id="depositBalance"
              name="depositBalance"
              className={css.expandedField}
              type="number"
              label={priceLabel}
              validate={composeValidators(positiveNumber(errorPrice), required(errorPrice))}
              placeholder={pricePlaceholder}
              required
            />
          ) : null}

          {!currentUser ? (
            <FieldTextInput
              id="email"
              name="email"
              className={css.expandedField}
              type="text"
              label={emailLabel}
              placeholder={emailPlaceholder}
            />
          ) : null}
          <FieldTextInput
            id="name"
            name="name"
            className={css.expandedField}
            type="text"
            label={nameLabel}
            placeholder={namePlaceholder}
          />

          <div className={css.formRow}>
            <FieldTextInput
              id="addressLine1"
              name="addressLine1"
              className={css.field}
              type="text"
              // autoComplete="billing address-line1"
              label={addressLine1Label}
              placeholder={addressLine1Placeholder}
            />

            <FieldTextInput
              id="addressLine2"
              name="addressLine2"
              className={css.field}
              type="text"
              // autoComplete="billing address-line2"
              label={addressLine2Label}
              placeholder={addressLine2Placeholder}
            />
          </div>

          <div className={css.formRow}>
            <FieldTextInput
              id="postal"
              name="postal"
              className={css.field}
              type="text"
              autoComplete="billing postal-code"
              label={postalCodeLabel}
              placeholder={postalCodePlaceholder}
            />

            <FieldTextInput
              id="city"
              name="city"
              className={css.field}
              type="text"
              autoComplete="billing address-level2"
              label={cityLabel}
              placeholder={cityPlaceholder}
            />
          </div>

          <div className={css.formRow}>
            <FieldSelect id="country" name="country" className={css.field} label={countryLabel}>
              <option disabled value="">
                {countryPlaceholder}
              </option>
              <option key="PL" value="PL">
                Poland
              </option>
              <option disabled value="">
                {restOfCountriesPlaceholder}
              </option>
              {countryCodes
                .filter(country => country.code !== 'PL')
                .map(country => {
                  return (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  );
                })}
            </FieldSelect>
            {currentUser?.attributes?.profile?.publicData?.type !== 'freelancer' ? (
              <FieldSelect
                id="currency"
                name="currency"
                className={css.field}
                label={currencyLabel}
              >
                <option disabled value="">
                  {currencyPlaceholder}
                </option>
                <option key="PLN" value="pln">
                  PLN
                </option>
                <option key="USD" value="usd">
                  USD
                </option>
              </FieldSelect>
            ) : null}
            <FieldTextInput
              id="addEmail"
              name="addEmail"
              className={css.expandedField}
              type="text"
              label={additionalEmailLabel}
              placeholder={emailPlaceholder}
            />
          </div>

          {values.country === 'PL' ? (
            <FieldTextInput
              id="vatNumber"
              name="vatNumber"
              className={css.expandedField}
              type="text"
              label={vatNumber}
              placeholder={vatNumberPlaceholder}
              validate={validPolandTaxNumber(notValidTaxNumberMessage)}
            />
          ) : null}

          <div className={css.submitInvoiceDetails}>
            <PrimaryButton
              type="submit"
              inProgress={createStripePaymentInProgress || createStripeCustomerInProgress}
            >
              {defaultMessage ?? <FormattedMessage id="PaymentForm.pay" />}
            </PrimaryButton>
            <FormattedMessage id="PaymentForm.acceptTermsAndConditions" values={{ termsLink }} />
          </div>
        </Form>
      );
    }}
  />
);

PaymentFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

PaymentFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PaymentForm = compose(injectIntl)(PaymentFormComponent);

PaymentForm.displayName = 'PaymentForm';

export default PaymentForm;
