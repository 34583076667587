import React from 'react';
import css from './HighlightedTooltip.css';
import { Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FormattedMessage } from '../../util/reactIntl';

const tooltipStyles = {
    tooltip: {
        fontSize: "14px",
        width: 500,
        maxWidth: 500
    }
};
const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

const InfoIcon = (props) => {
    return (
        <sup className={css.infoIcon}>
          <AiOutlineInfoCircle/>
        </sup>
    );
}

const HighlightedTooltip = (props) => {
    return (
        <>
            <CustomTooltip title={<FormattedMessage id={props.textId} />}>
                <span className={css.yellowBackground}>
                    {props.children}
                </span>
            </CustomTooltip>
            {!props.noIcon &&
                <InfoIcon />
            }
        </>
    );
}

export default HighlightedTooltip;