/* eslint-disable */
import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ServicePage.css';

const SectionBenefits = props => {
  const { expertBenefit } = props;

  const softwaresuppServiceBenefits = [
    'Certified Expert realizing work, starting in 24h',
    'Safe balance payment to secure your transaction',
    'Quality monitoring to ensure quality service',
  ];

  const allBenefits = expertBenefit ? [expertBenefit].concat(softwaresuppServiceBenefits) : softwaresuppServiceBenefits;

  return (
    <div className={css.sectionDescription}>
      <h2 className={css.benefitsTitle}>
        Benefits
      </h2>
      <ul>
        {allBenefits.map((benefit, index) =>
          <div className={css.benefit} key={index}>
            <div className={css.benefitSideWrapper}>
              <div className={css.benefitPointer}/>
              {index !== allBenefits.length - 1 ?
                <div className={css.benefitSideLine} />
                : null}
            </div>
            <div className={css.benefitWrapper}>
              {benefit}
            </div>
          </div>
        )}
      </ul>
    </div>
  )
};

export default SectionBenefits;
