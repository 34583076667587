/* eslint-disable */
import { Box, Grid, MenuItem, TextField, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, func, object, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { isUploadImageOverLimitError } from '../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';

import { Autocomplete } from '@material-ui/lab';
import { BsQuestionOctagon } from 'react-icons/bs';
import {
  AddImages,
  FieldCurrencyInput,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
  MultilevelScopeInput,
  PrimaryButton,
  SecondaryButton,
  ValidationError,
} from '../../components';
import getCountryCodesWithPhoneCodes from '../../translations/countryCodesWithPhoneCodes';
import { sendQuickPublishNotCompleted } from '../../util/triggerMail';
import * as validators from '../../util/validators';
import {
  autocompletePlaceSelected,
  composeValidators,
  emailFormatValid,
  maxLength,
  // validBusinessURL,
  moneySubUnitAmountAtLeastOrNull,
  required,
} from '../../util/validators';
import css from './EditProjectForm.css';
import { ifUserExists, SendFormData } from '../../util/api';

const TITLE_MAX_LENGTH = 120;
const identity = v => v;
const ACCEPT_IMAGES = 'image/*';

const CustomTooltip = props => {
  return (
    <Tooltip
      title={
        <FormattedMessage id="EditProjectForm.sectionRequired" values={{ name: `${props.name}` }} />
      }
    >
      <span className={css.required}>*</span>
    </Tooltip>
  );
};

const SendFormToStorage = value => {
  if (value.formId != '') {
    SendFormData(value);
  }
};
const SendSubmitConfirmation = value => {
  alert('polecialo');
  // if (value.formId != '') {
  //   RemoveFormData(value);
  // }
};

const EditProjectFormComponent = props => {
  const [imageUploadRequested, setImageUploadRequested] = useState(false);
  const [currentValues, setCurrentValues] = useState({});
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);
  const [countryPhone, setCountryPhone] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [workloadTitle, setWorkloadTitle] = useState('Small');
  const [workloadDesc, setWorkloadDesc] = useState('Few simple tasks.');
  const [workloadValue, setWorkloadValue] = useState('Small work: ');

  useEffect(() => {
    if (typeof window !== undefined) {
      setShouldRenderReactSelect(true);
    }
  }, []);

  const history = useHistory();

  useEffect(() => {
    window.addEventListener('beforeunload', quickPublishNotFinished);
    return () => {
      window.removeEventListener('beforeunload', quickPublishNotFinished);
    };
  });

  // Sprawdzanie czy mail istnie w bazie

  const verifyEmail = value => {
    const errors = [];

    ifUserExists(value).then(res => {
      if (res) {
        errors.push('Email adress already exists.');
      }
    });
    return errors;
  };

  const quickPublishNotFinished = event => {
    const { parseJobScope } = props;
    if (currentValues?.email && !submitInProgress) {
      const scope = parseJobScope(currentValues);
      const body = {
        ...currentValues,
        softwares: currentValues.software,
        scope,
      };
      // sendQuickPublishNotCompleted(body);
      if (event) {
        event.preventDefault();
      }
    }
  };

  const pmCallTrigger = () => {
    history.push(
      createResourceLocatorString(
        'ExpertPage',
        routeConfiguration(),
        {
          id: process.env.REACT_APP_ADMIN_BOOKING_LISTING_ID,
          slug: createSlug('Michael'),
          category: 'experts',
        },
        {}
      ),
      { openBookModal: true }
    );
  };

  const onImageUploadHandler = file => {
    const { onImageUpload } = props;
    if (file) {
      setImageUploadRequested(true);
      onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          setImageUploadRequested(false);
        })
        .catch(e => {
          setImageUploadRequested(false);
        });
    }
  };

  const setValue = (args, state, { setIn, changeValue }) => {
    const fieldName = args[0];
    const fieldValue = args[1];
    const field = state.fields[fieldName];
    if (!!field) {
      field.change(fieldValue);
    }
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators, setValue }}
      ///initialValues = {{ formId: formIdValue}}
      imageUploadRequested={imageUploadRequested}
      render={formRenderProps => {
        const {
          className,
          ready,
          handleSubmit,
          form,
          intl,
          invalid,
          submitFailed,
          submitSucceeded,
          pristine,
          saveActionMsg,
          // createDraftMessage,
          updated,
          updateInProgress,
          fetchErrors,
          currentUserExists,
          values,
          currentUser,
          initialValues,
          initialPrice,
          formMinimumPriceNotReached,
          // onSaveAsDraft,
          onGoToNextTab,
          onGoToPrevTab,
          images,
          onRemoveImage,
          isNew,
          panelTitle,
          // editProject, zmienna aby były rozwiniete
          currentTab,
          productSkills,
          productSoftware,
        } = formRenderProps;

        const editProject = false;

        const countries = getCountryCodesWithPhoneCodes();
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;

        const allSofts = productSoftware.map(soft => {
          return {
            value: soft.key,
            label: soft.label,
          };
        });

        const allSkills = productSkills.map(soft => {
          return {
            value: soft.key,
            label: soft.label,
          };
        });

        // error messages
        const { updateListingError, createListingDraftError, showListingsError, uploadImageError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        //title messages
        const titleMessage = intl.formatMessage(
          { id: 'EditProjectForm.title' },
          { tooltip: <CustomTooltip name="Title" /> }
        );
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditProjectForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({ id: 'EditProjectForm.titleRequired' });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

        // description messages
        const descriptionMessage = intl.formatMessage(
          { id: 'EditProjectForm.description' },
          { tooltip: <CustomTooltip name="Description" /> }
        );

        const descriptionMessageWorkload = intl.formatMessage(
          { id: 'EditProjectForm.descriptionWorkload' },
          { tooltip: <CustomTooltip name="Description" /> }
        );

        const descriptionPlaceholder = intl.formatMessage({
          id: 'EditProjectForm.descriptionPlaceholder',
        });
        const descriptionRequired = intl.formatMessage({
          id: 'EditProjectForm.descriptionRequired',
        });

        //software messages
        const softwareLabel = intl.formatMessage({ id: 'EditProjectForm.softwareTitle' });
        const softwarePlaceholder = intl.formatMessage({
          id: 'EditProjectForm.softwarePlaceholder',
        });
        const primarySoftwareLabel = intl.formatMessage({
          id: 'EditProjectForm.primarySoftwareTitle',
        });

        // skills messages
        const skillsPlaceholder = intl.formatMessage({ id: 'EditProjectForm.skillsPlaceholder' });
        const skillsLabel = intl.formatMessage({ id: 'EditProjectForm.skillsInfo' });

        // budget messages
        const pricePerUnitMessage = intl.formatMessage(
          { id: 'EditProjectForm.pricing' },
          { tooltip: <CustomTooltip name="Budget" /> }
        );
        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditProjectForm.priceInputPlaceholder',
        });
        const priceErrorMessage = intl.formatMessage({ id: 'EditProjectForm.priceTooLowMessage' });
        const budgetRequiredMessage = intl.formatMessage({ id: 'EditProjectForm.budgetRequired' });

        // project links messages
        const jobLinkPlaceholderMessage = intl.formatMessage({
          id: `EditProjectForm.jobLinkPlaceholder`,
        });
        const jobLinkMessage = intl.formatMessage({ id: 'EditProjectForm.jobLink' });
        // const notValidURLMessage = intl.formatMessage({ id: "EditProjectForm.notValidURLMessage" });

        //address messages
        const addressPlaceholderMessage = intl.formatMessage({
          id: 'EditProjectForm.addressPlaceholder',
        });
        const addressTitleMessage = intl.formatMessage({ id: 'EditProjectForm.address' });
        const LocationLabel = intl.formatMessage({ id: 'EditPageSidebar.locationNav' });
        const validLocationRequired = intl.formatMessage({
          id: 'EditProjectForm.notValidLocation',
        });

        // image messages
        const chooseImageText = (
          <span className={css.chooseImageText}>
            <span className={css.chooseImage}>
              <FormattedMessage id="EditProjectForm.chooseImage" />
            </span>
            <span className={css.imageTypes}>
              <FormattedMessage id="EditProjectForm.imageTypes" />
            </span>
          </span>
        );
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);
        let uploadImageFailed = null;
        if (uploadOverLimit) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditProjectForm.imageUploadFailed.uploadOverLimit" />
            </p>
          );
        } else if (uploadImageError) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditProjectForm.imageUploadFailed.uploadFailed" />
            </p>
          );
        }

        // email messages
        const emailLabel = intl.formatMessage(
          { id: 'EditProjectForm.emailLabel' },
          { tooltip: <CustomTooltip name="Email" /> }
        );
        const emailPlaceholder = intl.formatMessage({ id: 'SignupForm.emailPlaceholder' });
        const emailRequiredMessage = intl.formatMessage({ id: 'SignupForm.emailRequired' });
        const emailRequired = required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({ id: 'SignupForm.emailInvalid' });
        const emailValid = emailFormatValid(emailInvalidMessage);
        const passwordRequired = required(
          intl.formatMessage({ id: 'SignupForm.passwordRequired' })
        );

        // Budgate Messages

        const budgetMessage = intl.formatMessage({ id: 'EditProjectForm.budgetTitle' });

        // phone number messages
        const phoneLabel = intl.formatMessage({ id: 'SignupForm.phoneLabel' });
        const phonePlaceholder = intl.formatMessage({ id: 'SignupForm.phonePlaceholder' });

        const countryCodes = require('country-codes-list');
        const countryCodeList = countryCodes.customList(
          'countryCode',
          '[{countryCode}] {countryNameEn}: +{countryCallingCode}'
        );

        const handleCountryCode = cc => {
          cc.preventDefault();
          values.countryCode = cc.target.value;
        };

        // firstName messages
        const firstNameLabel = intl.formatMessage(
          { id: 'EditProjectForm.firstNameLabel' },
          { tooltip: <CustomTooltip name="First name" /> }
        );
        const firstNamePlaceholder = intl.formatMessage({ id: 'SignupForm.firstNamePlaceholder' });
        const firstNameRequiredMessage = intl.formatMessage({ id: 'SignupForm.firstNameRequired' });
        const firstNameRequired = required(firstNameRequiredMessage);

        // lastName messages
        const lastNameLabel = intl.formatMessage(
          { id: 'EditProjectForm.lastNameLabel' },
          { tooltip: <CustomTooltip name="Last name" /> }
        );
        const lastNamePlaceholder = intl.formatMessage({ id: 'SignupForm.lastNamePlaceholder' });
        const lastNameRequiredMessage = intl.formatMessage({ id: 'SignupForm.lastNameRequired' });
        const lastNameRequired = required(lastNameRequiredMessage);

        const addstyle = () => {
          try {
            let a = document.createElement('style');
            a.innerText = '.MuiAutocomplete-popper{min-width:300px; width:100% }';
            document.querySelector('body').append(a);
          } catch (e) {}
        };

        const invalidPhoneMessage =
          phoneNumber === ''
            ? intl.formatMessage({
                id: 'SignupForm.invalidPhone',
              })
            : intl.formatMessage({
                id: 'SignupForm.noCC',
              });
        const buttonDisabled =
          (currentTab === 'description' && (!values.description || !values.title)) ||
          (currentTab === 'user' && (!values.fname || !values.lname || !values.email));

        if (shouldRenderReactSelect) {
          var isMobile = false;
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            isMobile = true;
          }

          const { default: CreatableSelect } = require('react-select/creatable');
          var ReactCreatableSelectAdapter = ({ input, ...rest }) => (
            <CreatableSelect {...input} {...rest} searchable />
          );
          var primarySoftwareField = (
            <Field
              name="primarySoftware"
              component={ReactCreatableSelectAdapter}
              options={allSofts}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
              placeholder={softwarePlaceholder}
              className={css.software}
            />
          );
          var softwareField = (
            <Field
              name="software"
              isMulti
              component={ReactCreatableSelectAdapter}
              closeMenuOnSelect={false}
              options={allSofts}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
              placeholder={softwarePlaceholder}
              className={css.software}
            />
          );
          var skillsField = (
            <Field
              name="skills"
              isMulti
              component={ReactCreatableSelectAdapter}
              closeMenuOnSelect={false}
              options={allSkills}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
              placeholder={skillsPlaceholder}
              className={css.software}
            />
          );
        }
        const smallWorkload = function() {
          const checked = workloadValue === 'Small work: ' ? true : null;
          return (
            <input
              type="radio"
              id="wSmall"
              name="workload"
              value="Small work: "
              onChange={() => {
                setWorkloadValue('Small work: ');
                setWorkloadTitle('Small');
                setWorkloadDesc('Few simple tasks.');
              }}
            />
          );
        };
        return (
          <div className={editProject ? css.editingProject : ''}>
            <Form
              className={classes}
              onSubmit={values => {
                console.log('Submit');
                setSubmitInProgress(true);
                console.log('values', values);
                // SendSubmitConfirmation(values);

                handleSubmit(values);
              }}
            >
              {/* Tytuł plus komunikaty */}
              <Grid container spacing={2}>
                {console.log(currentUser)}
                <div className={css.bookACall}>
                  Have any problems going through your needs?{' '}
                  { currentUser ? ( 
                  <a style={{textDecoration:"underline"}} onClick={pmCallTrigger}> Book a call with our professional</a>) : (
                  <a style={{textDecoration:"underline"}} href={'https://calendly.com/softwaresupp/software-consultation'}> Book a call with our professional</a>
                  )}                
                </div>
                <Grid item xs={12}>
                  <h2 className={css.panelMainTitle}>{panelTitle}</h2>
                </Grid>
                <Grid item xs={12}>
                  {errorMessageCreateListingDraft}
                  {errorMessageUpdateListing}
                  {errorMessageShowListing}
                </Grid>
              </Grid>

              <Field name="formId" type="hidden" component="input" />

              <div
                id="description"
                className={
                  currentTab === 'description' || editProject ? css.sectionSpacer : css.hiddenTab
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={10}>
                    {titleMessage}
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FieldTextInput
                      id="EditProjectForm.title"
                      name="title"
                      className={css.inputStyle}
                      type="text"
                      placeholder={titlePlaceholderMessage}
                      // validate={composeValidators(
                      //   required(titleRequiredMessage),
                      //   maxLength60Message
                      // )}
                      autoFocus
                      onClick={() => {
                        if (!currentUserExists) {
                          window.ga('send', 'event', 'Title', 'fulfill');
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <label for="EditProjectForm.descriptionRadio">
                      <div className={css.labelInputStyle}>{descriptionMessageWorkload}</div>
                    </label>
                    <ul
                      class={css.workload}
                      name="workload"
                      value={workloadValue}
                      onChange={event => {
                        if (
                          form.getFieldState('description').value &&
                          form.getFieldState('description').value !== ''
                        ) {
                          let desc = form.getFieldState('description').value.split('‎');
                          let description = '';
                          if (desc.length > 1 && desc) {
                            if (desc[1].length > 0) {
                              description = event.target.value + '‎' + desc[1];
                            } else {
                              description = event.target.value + '‎';
                            }
                          } else {
                            description = event.target.value + '‎' + desc;
                          }

                          form.mutators.setValue('description', description);
                        } else {
                          form.mutators.setValue('description', event.target.value + '‎');
                        }
                      }}
                      id="EditProjectForm.descriptionRadio"
                    >
                      <li>
                        {smallWorkload()}
                        <label className={css.labelmargin} for="wSmall">
                          <span className={css.workloadTitle}>Small work</span>
                          <br />
                          <span className={css.workloadDesc}>Few simple tasks.</span>
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="wMedium"
                          name="workload"
                          value="Medium: "
                          onChange={() => {
                            setWorkloadValue('Medium: ');
                            setWorkloadTitle('Medium');
                            setWorkloadDesc('A multistage, part-time work.');
                          }}
                        />
                        <label className={css.labelmargin} for="wMedium">
                          <span className={css.workloadTitle}>Medium</span>
                          <br />
                          <span className={css.workloadDesc}>A multistage, part-time work.</span>
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="wFull"
                          name="workload"
                          value="Full-time: "
                          onChange={() => {
                            setWorkloadValue('Full-time: ');
                            setWorkloadTitle('Full-time position or project');
                            setWorkloadDesc('Comprehensive project or hire.');
                          }}
                        />
                        <label className={css.labelmargin} for="wFull">
                          <span className={css.workloadTitle}>Full-time position or project</span>
                          <br />
                          <span className={css.workloadDesc}>Comprehensive project or hire.</span>
                        </label>
                      </li>
                    </ul>

                    {/* <Field
                    id="workloadDesc"
                    component="input"
                    className={css.hidden}
                    type="text"
                    name="workloadDesc"
                  /> */}
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <label>{descriptionMessage}</label>
                    <div className={css.inputStyle}>
                      <Field
                        id="EditProjectForm.description"
                        name="description"
                        type="text"
                        component="textarea"
                        placeholder={descriptionPlaceholder}
                        isTicket
                        isDescription
                        onClick={() => {
                          if (!currentUserExists) {
                            window.ga('send', 'event', 'Description', 'fulfill');
                          }
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div
                id="links"
                className={
                  currentTab === 'links' || editProject
                    ? classNames(css.sectionSpacer, css.editBottomPadding)
                    : css.hiddenTab
                }
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={10}>
                    {jobLinkMessage}
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FieldTextInput
                      id="links"
                      name="linkJobDetails"
                      className={css.inputStyle}
                      type="textarea"
                      // label={jobLinkMessage}
                      placeholder={jobLinkPlaceholderMessage}
                      // validate={composeValidators(validBusinessURL(notValidURLMessage))}
                    />
                  </Grid>
                </Grid>
              </div>

              <div
                id="photos"
                className={
                  currentTab === 'photos' || editProject ? css.sectionSpacer : css.hiddenTab
                }
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={10}>
                    <FormattedMessage id="EditPageSidebar.photosNav" />
                  </Grid>

                  <Grid item xs={12} md={10}>
                    <AddImages
                      className={css.imagesField}
                      images={images}
                      thumbnailClassName={css.thumbnail}
                      savedImageAltText={intl.formatMessage({
                        id: 'EditProjectForm.savedImageAltText',
                      })}
                      onRemoveImage={onRemoveImage}
                    />
                  </Grid>

                  <Grid item xs={12} md={10}>
                    <Field
                      id="photos"
                      name="addImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`addImage`, file);
                          form.blur(`addImage`);
                          onImageUploadHandler(file);
                        };
                        const inputProps = { accept, id: name, name, onChange, type };

                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                    <Field
                      component={props => {
                        const { input, meta } = props;
                        return (
                          <div className={css.imageRequiredWrapper}>
                            <input {...input} />
                            <ValidationError fieldMeta={meta} />
                          </div>
                        );
                      }}
                      name="images"
                      type="hidden"
                      //validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                    />
                    {uploadImageFailed}
                  </Grid>
                </Grid>
              </div>

              <div
                id="location"
                className={
                  currentTab === 'location' || editProject
                    ? classNames(css.sectionSpacer, css.editSeparator)
                    : css.hiddenTab
                }
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={10}>
                    <FormattedMessage id="EditPageSidebar.locationNav" />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <div className={css.locationInput}>
                      <LocationAutocompleteInputField
                        className={css.locationAddress}
                        inputClassName={css.locationAutocompleteInput}
                        iconClassName={css.locationAutocompleteInputIcon}
                        predictionsClassName={css.predictionsRoot}
                        validClassName={css.validLocation}
                        name="location"
                        id="location"
                        // label={LocationLabel}
                        placeholder={addressPlaceholderMessage}
                        useDefaultPredictions={false}
                        format={identity}
                        valueFromForm={values.location}
                        validate={
                          values.location?.search && !values.location?.selectedPlace
                            ? autocompletePlaceSelected(validLocationRequired)
                            : null
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div
                id="software"
                className={
                  currentTab === 'software' || editProject
                    ? classNames(css.sectionSpacer, css.editBottomPadding, css.editSeparator)
                    : css.hiddenTab
                }
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormattedMessage id="EditProjectForm.primarySoftwareTitle" />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    {primarySoftwareField}
                  </Grid>
                  <Grid item xs={12} md={10}>
                    {softwareField}
                  </Grid>
                </Grid>
              </div>

              <div
                id="skills"
                className={
                  currentTab === 'skills' || editProject
                    ? classNames(css.sectionSpacer, css.editSeparator)
                    : css.hiddenTab
                }
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={10}>
                    {skillsLabel}
                  </Grid>

                  <Grid item xs={12} md={10}>
                    {skillsField}
                  </Grid>
                </Grid>
              </div>

              <div
                id="budget"
                className={
                  currentTab === 'budget' || editProject
                    ? classNames(css.sectionSpacer, css.editSeparator)
                    : css.hiddenTab
                }
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={10}>
                    {budgetMessage}
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FieldCurrencyInput
                      id="EditProjectForm.budget"
                      name="budget"
                      className={css.inputStyle}
                      placeholder={pricePlaceholderMessage}
                      currencyConfig={config.currencyConfig}
                      validate={composeValidators(
                        moneySubUnitAmountAtLeastOrNull(
                          priceErrorMessage,
                          config.minimumProjectBudget
                        )
                      )}
                      defaultValue={initialPrice}
                    />
                  </Grid>
                </Grid>
              </div>

              <div
                id="scope"
                className={
                  currentTab === 'scope' || editProject
                    ? classNames(css.sectionSpacer, css.editSeparator)
                    : css.hiddenTab
                }
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={10}>
                    <div>
                      <FormattedMessage id="EditProjectForm.scopeSectionTitle" />
                    </div>
                    <div>
                      <BsQuestionOctagon />
                      {'\u00A0'}
                      <a
                        href="https://softwaresupp.com/blog/scoping-session"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={css.scopeSubtitleUnderlined}
                      >
                        <FormattedMessage id="EditProjectForm.scopeSectionSubtitle" />
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <MultilevelScopeInput
                      intl={intl}
                      form={form}
                      values={values}
                      disabled={false}
                      initialValues={initialValues.scope}
                      scopeType={'job'}
                      suggestValues={true}
                    />
                  </Grid>
                </Grid>
              </div>

              {!currentUser ? (
                <div
                  id="user"
                  className={currentTab === 'user' ? css.sectionSpacer : css.hiddenTab}
                >
                  <Grid container spaceing={3}>
                    <Grid item xs={12} md={7}>
                      <FormSpy
                        subscription={{ values: true, valid: true }}
                        onChange={state => {
                          const { values } = state;
                          setCurrentValues(values);
                        }}
                      />
                      <label for="EditProjectForm.user">
                        <FormattedMessage id="EditPageSidebar.userNav" />
                      </label>

                      <FieldTextInput
                        className={[css.firstNameRoot, css.inputStyle].join(' ')}
                        type="text"
                        id={'fname'}
                        name="fname"
                        label={firstNameLabel}
                        validate={firstNameRequired}
                        onClick={() => {
                          window.ga('send', 'event', 'Firstname', 'fulfill');
                        }}
                      />

                      <FieldTextInput
                        className={[css.lastNameRoot, css.inputStyle].join(' ')}
                        type="text"
                        id={'lname'}
                        name="lname"
                        label={lastNameLabel}
                        validate={lastNameRequired}
                        onClick={() => {
                          window.ga('send', 'event', 'Lastname', 'fulfill');
                        }}
                      />

                      <FieldTextInput
                        className={[css.thirdRowElement, css.inputStyle].join(' ')}
                        type="email"
                        id={'email'}
                        name="email"
                        label={emailLabel}
                        validate={composeValidators(emailRequired, emailValid, verifyEmail)}
                        onClick={() => {
                          window.ga('send', 'event', 'Email', 'fulfill');
                        }}
                        onBlur={() => {
                          SendFormToStorage(values);

                          !!values.email &&
                            !emailFormatValid(emailInvalidMessage)(values.email) &&
                            quickPublishNotFinished();
                        }}
                      />

                      <FieldTextInput
                        className={[css.thirdRowElement, css.inputStyle].join(' ')}
                        id="password"
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Enter you password..."
                        validate={composeValidators(passwordRequired)}
                      ></FieldTextInput>

                      {addstyle()}
                      <div class={css.inputStyle}>
                        <label>{phoneLabel}</label>
                        <Grid container spacing={2}>
                          <div className={css.countryCodeBlock}>
                            <Autocomplete
                              id={'countryCodeac'}
                              className={css.countryCodes}
                              name="countryCodeac"
                              options={countries}
                              autoHighlight
                              disableClearable
                              //popupIcon={''}
                              getOptionLabel={option => option.phone || countryPhone || ''}
                              filterOptions={(options, state) => {
                                if (state.inputValue !== '') {
                                  if (isNaN(state.inputValue)) {
                                    let filteredOptions = [];
                                    filteredOptions = options.filter(option =>
                                      option.label
                                        .toLowerCase()
                                        .includes(state.inputValue.toLowerCase())
                                    );
                                    return filteredOptions;
                                  } else {
                                    let filteredOptions = [];
                                    filteredOptions = options.filter(option =>
                                      option.phone.includes(state.inputValue)
                                    );
                                    return filteredOptions;
                                  }
                                } else {
                                  return options;
                                }
                              }}
                              getOptionSelected={(option, value) => `+${option.phone}` === value}
                              value={countryPhone}
                              onChange={(event, newValue) => {
                                if (newValue?.phone) {
                                  form.mutators.setValue('countryCode', `+${newValue?.phone}`);
                                  setCountryPhone(`+${newValue?.phone}`);
                                  if (newValue?.phone !== null && phoneNumber !== null) {
                                    let phoneInput = document.getElementById('phone');
                                    phoneInput.classList.remove('FieldTextInput_inputError__GA57-');
                                    let phoneError = document.getElementsByClassName(
                                      'ValidationError_root__1SXA9'
                                    );
                                    for (let i = 0; i < phoneError.length; i++) {
                                      if (phoneError[i].innerHTML === invalidPhoneMessage) {
                                        phoneError[i].style.visibility = 'hidden';
                                        break;
                                      }
                                    }
                                  }
                                }
                              }}
                              renderOption={(option, props) => (
                                <Box
                                  component="li"
                                  sx={{
                                    '& > img': { mr: 2, flexShrink: 0 },
                                    color: 'rgba(74, 74, 74, 0.9)',
                                  }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  {option.label} ({option.code}) +{option.phone}
                                </Box>
                              )}
                              renderInput={params => (
                                <TextField
                                  className={
                                    countryPhone === ''
                                      ? css.countryCodePlaceholder
                                      : css.countryCode
                                  }
                                  id={'countryCode'}
                                  name="countryCode"
                                  value={countryPhone}
                                  {...params}
                                  label=""
                                  placeholder="+00"
                                  validate={validators.phoneRequired(
                                    invalidPhoneMessage,
                                    phoneNumber
                                  )}
                                  SelectProps={{ renderValue: option => option }}
                                  onBlur={event => {
                                    SendFormToStorage(values);
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment:
                                      countryPhone === '' ? (
                                        <div className={css.countryArrow}>
                                          {params.InputProps.endAdornment.props.children}
                                        </div>
                                      ) : null,
                                    endAdornment: null,
                                  }}
                                >
                                  {Object.entries(countryCodeList).map((option, index) => (
                                    <MenuItem
                                      key={index}
                                      className={css.countryOption}
                                      value={option[1].substr(
                                        option[1].indexOf('+'),
                                        option[1].length
                                      )}
                                    >
                                      {option[1]}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                          </div>
                          <div className={css.phonenBlock}>
                            <FieldTextInput
                              className={css.phoneNumber}
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder={phonePlaceholder}
                              // validate={validators.phoneRequired(invalidPhoneMessage, countryPhone)}
                              onKeyUp={event => {
                                setPhoneNumber(event.target.value);
                              }}
                              onBlur={event => {
                                SendFormToStorage(values);
                              }}
                            />

                            <Field
                              id={'countryCode'}
                              component="input"
                              className={css.hidden}
                              //validate={validators.phoneRequired(invalidPhoneMessage, phoneNumber)}
                              type="text"
                              name="countryCode"
                              value={countryPhone}
                            />
                          </div>
                          <Grid xs={12}>
                            <div className={css.terms}>
                              By publishing you sign up and accept the{' '}
                              <a href="/terms-of-service">terms & conditions</a>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              {(submitFailed || submitSucceeded) && (invalid || formMinimumPriceNotReached) && (
                <span className={css.formError}>
                  <FormattedMessage id="EditProjectForm.formInvalid" />
                </span>
              )}
              {/* 
            <div className={css.submitSection}>
              <div className={css.buttonGroup} id="save">
                {!currentUser && currentTab === 'description' && isNew ? null : (
                  <PrimaryButton
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    ready={submitReady}
                  >
                    {currentTab === 'description' && isNew && !currentUser ? (
                      <FormattedMessage id="EditProjectForm.goToNextTab" />
                    ) : (
                      saveActionMsg
                    )}
                  </PrimaryButton>
                )}
                {currentTab !== 'photos' ? (
                  <SecondaryButton
                    className={css.submitButton}
                    disabled={buttonDisabled && isNew}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onGoToNextTab();
                    }}
                  >
                    <FormattedMessage id="EditProjectForm.goToNextTab" />
                  </SecondaryButton>
                ) : null}
              </div> */}
              {/* <div>
              <img src={social} width="100%" alt="Board" />
              </div>
            </div> */}

              <div className={css.submitSection} id="save">
                {!editProject && currentTab !== 'description' ? (
                  <SecondaryButton
                    className={css.submitButton}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onGoToPrevTab();
                    }}
                  >
                    <FormattedMessage id="EditProjectForm.goToPrevTab" />
                  </SecondaryButton>
                ) : null}

                {!editProject && currentTab !== 'photos' && currentTab !== 'user' ? (
                  <PrimaryButton
                    className={css.submitButton}
                    disabled={buttonDisabled && isNew}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onGoToNextTab();
                    }}
                  >
                    <FormattedMessage id="EditProjectForm.goToNextTab" />
                  </PrimaryButton>
                ) : null}

                {editProject || currentTab === 'user' || currentTab === 'photos' ? (
                  <PrimaryButton
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </PrimaryButton>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

const mapStateToProps = state => {
  const { currentUser, currentUserExpertListing, fetchingExpertListingInProgress } = state.user;
  return {
    currentUser,
    currentUserExpertListing,
    fetchingExpertListingInProgress,
  };
};

EditProjectFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  isAdmin: false,
};

EditProjectFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: object.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  currentUserExists: bool.isRequired,
};

export default compose(connect(mapStateToProps), injectIntl)(EditProjectFormComponent);
