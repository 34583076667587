import React from 'react';
import { string } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { formatDate } from '../../util/dates';
import { MdDelete } from 'react-icons/md';
import { NamedLink, Avatar } from '..';

import css from './TicketMessage.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const TicketMessageComponent = props => {
  const {
    intl,
    answer,
    currentTicket,
    isOwnListing,
    isSolved,
    currentUserExpertListing,
    onChangeTicketAnswer,
    currentUser,
    index,
  } = props;

  const { listing, solution, date, userId, type, additionalInfo } = answer;

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  if (type) {
    const values =
      type === 'status-changed'
        ? { status: additionalInfo }
        : type === 'completion-hours'
        ? { completionHours: additionalInfo }
        : type === 'estimation-hours' || type === 'completion-hours-and-update'
        ? { estimationHours: additionalInfo }
        : null;
    return (
      <div className={css.activity}>
        <div className={css.activityDot}>
          <p className={css.activityItemContent}>•</p>
        </div>
        <div>
          <p className={css.activityItemContent}>
            {intl.formatMessage({ id: `TicketMessage.${type}` }, values)}
          </p>
          <p className={css.activityItemDate}>{formatDate(intl, todayString, new Date(date))}</p>
        </div>
      </div>
    );
  }

  const expertListing = listing?.data;
  let id = expertListing?.id.uuid;
  if (id === undefined) {
    id = listing?.id.uuid;
  }
  const slug = expertListing ? createSlug(expertListing.attributes.title) : null;
  const answerProviderIsTicketProvider =
    userId && currentTicket?.author.id.uuid === userId ? true : false;
  const user = answer.user?.data
    ? answer.user.data
    : // : answer.user && !answerProviderIsTicketProvider
    //   ? answer.user
    // : listing?.included && !answerProviderIsTicketProvider && listing?.included[1]
    //   ? listing.included[1]
    //   : listing?.included && listing.included[0]
    //     ? listing?.included[0]
    currentUserExpertListing && listing?.id?.uuid === currentUserExpertListing?.id.uuid
    ? currentUser
    : currentTicket?.author;

  const title =
    expertListing && !answerProviderIsTicketProvider
      ? expertListing.attributes.title
      : user?.attributes.profile.displayName;

  const isOwnAnswer =
    (currentUserExpertListing && currentUserExpertListing?.id.uuid === id) ||
    (listing && listing?.id?.uuid === id);

  const imageAttributes =
    listing?.included && listing?.included[0].type === 'image'
      ? listing?.included[0]
      : answer?.user?.included && answer.user.included[0]
      ? answer.user.included[0]
      : null;

  const formattedUser =
    ((expertListing && !answerProviderIsTicketProvider) || answer.user?.data) && user
      ? {
          attributes: user.attributes,
          id: user.id,
          profileImage: {
            attributes: imageAttributes ? imageAttributes.attributes : null,
            id: imageAttributes ? imageAttributes.id : null,
            type: 'image',
          },
        }
      : user;

  return (
    <div className={css.ticketMessageContent}>
      <div className={css.main}>
        <div className={css.sectionAvatar}>
          <div className={css.avatarWrapper}>
            {expertListing && !answerProviderIsTicketProvider ? (
              <NamedLink name="ExpertPage" params={{ id, slug, category: 'experts' }}>
                <Avatar
                  user={formattedUser}
                  className={css.avatarDesktop}
                  initialsClassName={css.initialsDesktop}
                  disableProfileLink
                />
              </NamedLink>
            ) : (
              <Avatar
                user={formattedUser}
                className={css.avatarDesktop}
                initialsClassName={css.initialsDesktop}
                disableProfileLink
              />
            )}
          </div>
          <p className={css.messageDate}>{formatDate(intl, todayString, new Date(date))}</p>
        </div>
        <div className={css.contentWrapper}>
          <div className={css.answerInfo}>
            <div className={css.sectionMainContent}>
              <div className={css.jobTitle}>{title}</div>
              <div className={css.solution}>
                {richText(solution, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                  linkify: true,
                  mailify: true,
                })?.map(line => {
                  return <p className={css.solutionText}>{line}</p>;
                })}
              </div>
            </div>
          </div>
          {(isOwnAnswer || isOwnListing) && !isSolved ? (
            <div onClick={() => onChangeTicketAnswer(index, 'delete')} className={css.deleteIcon}>
              <MdDelete />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

TicketMessageComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

TicketMessageComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing,

  // Responsive image sizes hint
  renderSizes: string,
};

export default injectIntl(TicketMessageComponent);
