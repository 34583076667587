/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_CATEGORY_JOB,
  LISTING_CATEGORY_EXPERT,
  LISTING_STATE_PENDING_APPROVAL,
} from '../../util/types';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  ManageListingCard,
  Page,
  LayoutSideNavigation,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  PaginationLinks,
  NamedLink,
  Modal,
  SecondaryButton,
  PrimaryButton,
  Sidebar,
  ExportCSVModal,
  ExpertModal,
  history
} from '../../components';
import { InviteUserForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import {
  closeListing,
  openListing,
  fetchExpertsCount,
  fetchSoftwareExpertsCount,
} from './ManageListingsPage.duck';
import { queryOwnListings, changeTicketData, approveListing } from '../../util/api';
import {
  sendNewListingNotification,
  activateExpertCampaign,
  sendListingPublishedAfterApprovalMail,
  sendSoftwareSuppInviteMail,
} from '../../util/triggerMail';

import css from './ManageListingsPage.css';

const paginationSize = 8;

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages

export class ManageListingsPageComponent extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      listingMenuOpen: null,
      alreadyLoaded: false,
      nowLoading: false,
      listings: [],
      sidebarVisible: true,
      openModal: true,
      renderModal: false,
      expertModalForVerificated: false,
      isClient: false,
    };

    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.changeState = this.changeState.bind(this);
    this.handleCloseListing = this.handleCloseListing.bind(this);
    this.handleOpenListing = this.handleOpenListing.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.onApproveListing = this.onApproveListing.bind(this);
    this.onSubmitDeposit = this.onSubmitDeposit.bind(this);
    this.onSubmitInvite = this.onSubmitInvite.bind(this);
    this.handleSidebarVisibility = this.handleSidebarVisibility.bind(this);
  }

  componentDidMount() {
    const { onFetchExpertsCount, params } = this.props;
    const { tab } = params;
    this._isMounted = true;
    if (tab === 'projects-posted') {
      onFetchExpertsCount().then(response => this.setState({ expertsCount: response }));
    }
  
  }




  componentDidUpdate(prevProps) {
    const {
      currentUser,
      currentUserExpertListing,
      fetchingExpertListingInProgress,
      onCheckAvailableExperts,
    } = this.props;

  
  

    if (this.props.params.tab !== prevProps.params.tab)
      this.setState({ alreadyLoaded: false, nowLoading: false });
    if (
      this._isMounted &&
      (this.state.listings.length === 0 || !this.state.alreadyLoaded) &&
      currentUser &&
      (currentUserExpertListing || fetchingExpertListingInProgress === false)
    ) {
      //  NEW: INSTEAD OF 'JOBS' THERE COULD BE 3 TYPES OF PROJECTS:
      // "projects-posted" || "projects-ongoing" || "projects-completed"
      const category =
        this.props.params.tab === 'services'
          ? this.props.params.tab.slice(0, -1)
          : this.props.params.tab;

      let body = {
        userId: currentUser?.id.uuid,
        userEmail: currentUser?.attributes.email,
        userExpertListingId: currentUserExpertListing?.id.uuid,
        userExpertListingSoftwares: currentUserExpertListing?.attributes.publicData.softwares,
        currentUserPrivileges: currentUser?.attributes?.profile?.publicData?.adminPrivileges,
        category: category,
        isAdmin: currentUser?.id?.uuid === process.env.REACT_APP_ADMIN_USER_ID,
      };
      if (currentUser && !this.state.alreadyLoaded && !this.state.nowLoading) {
        this.setState({ nowLoading: true });
        queryOwnListings(body).then(posted => {
          if (this._isMounted) {
            if (category === 'projects-posted') {
              posted.forEach((listing, index) => {
                if (listing.attributes.state === LISTING_STATE_PENDING_APPROVAL) {
                  onCheckAvailableExperts(listing.attributes.publicData.primarySoftware, index);
                }
              });

              this.setState({
                alreadyLoaded: true,
                listings: posted,
                nowLoading: false,
              });
              let body = {
                userId: currentUser?.id.uuid,
                userEmail: currentUser?.attributes.email,
                userExpertListingId: currentUserExpertListing?.id.uuid,
                userExpertListingSoftwares:
                  currentUserExpertListing?.attributes.publicData.softwares,
                currentUserPrivileges:
                  currentUser?.attributes?.profile?.publicData?.adminPrivileges,
                category: 'projects-ongoing',
                isAdmin: currentUser?.id?.uuid === process.env.REACT_APP_ADMIN_USER_ID,
              };
              queryOwnListings(body).then(ongoing => {
                let body = {
                  userId: currentUser?.id.uuid,
                  userEmail: currentUser?.attributes.email,
                  userExpertListingId: currentUserExpertListing?.id.uuid,
                  userExpertListingSoftwares:
                    currentUserExpertListing?.attributes.publicData.softwares,
                  currentUserPrivileges:
                    currentUser?.attributes?.profile?.publicData?.adminPrivileges,
                  category: 'projects-completed',
                  isAdmin: currentUser?.id?.uuid === process.env.REACT_APP_ADMIN_USER_ID,
                };
                this.setState({
                  alreadyLoaded: true,
                  listings: posted.concat(ongoing),
                  nowLoading: false,
                });
                queryOwnListings(body).then(closed => {
                  this.setState({
                    alreadyLoaded: true,
                    listings: posted.concat(ongoing, closed),
                    nowLoading: false,
                  });
                });
                
              }).then(()=>{

                if(!currentUser.attributes?.profile?.publicData?.isVerified){
                  this.setState({expertModalForVerificated:true })
                }

                let hasJobs = !!this.state.listings.find(e => e.attributes.publicData.category === 'job');
                  if(hasJobs === false){
                   if(this.state.expertModalForVerificated) {
                     if(this.state.renderModal !== true){   
                     this.setState({ renderModal: false})
                     }
                   }} else {
                     if(this.state.renderModal !== false){  
                     this.setState({ renderModal: false})
                     } 
                   }
              });
            } else {
              this.setState({
                alreadyLoaded: true,
                listings: posted,
                nowLoading: false,
              });
            }
          }
        }).then(
          () => {
            // Logika od pokazywania się modala
          }
        );
      }
    }
    
    
    
  


  }



  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      alreadyLoaded: false,
      nowLoading: false,
      listings: [],
    });
  }

  async onSubmitInvite(values, form) {
    this.setState({ userSuccessfullyInvited: false });
    setTimeout(form.reset);
    sendSoftwareSuppInviteMail(values.allowedUserEmail);
    this.setState({ userSuccessfullyInvited: true });
  }

  onToggleMenu(listing) {
    this.setState({ listingMenuOpen: listing });
  }

  changeState(listingId) {

    const { currentUser } = this.props;
    const listings = this.state.listings;
    listings.find(listing => listing.id.uuid === listingId).attributes.publicData.state = 'open';
    this.setState({ listings: listings });
    
    changeTicketData({
      listingId,
      userId: currentUser.id.uuid,
      type: 'change-state',
      body: { state: 'open' },
    });
  }

  handleCloseListing(listingId) {
    const { onCloseListing } = this.props;
    const listings = this.state.listings;
    listings.find(listing => listing.id.uuid === listingId.uuid).attributes.state = 'closed';
    this.setState({ listings: listings });

    onCloseListing(listingId);
    
  }
  
  handleOpenListing(listingId) {
    const { onOpenListing } = this.props;
    const listings = this.state.listings;
    listings.find(listing => listing.id.uuid === listingId.uuid).attributes.state = 'published';
    this.setState({ listings: listings });
    onOpenListing(listingId);
  } 

  onApproveListing(listingId) {
    const { currentUser, intl, productSoftware } = this.props;
    const listings = this.state.listings;
    listings.find(listing => listing.id.uuid === listingId).attributes.state = 'published';
    const currentListing = listings.find(listing => listing.id.uuid === listingId);
    const category = currentListing.attributes.publicData.category;
    const author = currentListing.author;

    if (category === LISTING_CATEGORY_JOB) {
      const listingSoftwares = currentListing.attributes.publicData.softwares;
      const marketplaceSofts = listingSoftwares
        ? listingSoftwares
            .map(soft => {
              const foundSoft = productSoftware.find(foundSoft => foundSoft.key === soft);
              if (foundSoft) return soft;
              return null;
            })
            .filter(soft => !!soft)
        : null;
      sendNewListingNotification(
        listings.find(listing => listing.id.uuid === listingId),
        marketplaceSofts,
        intl,
        true
      );
    }
    sendListingPublishedAfterApprovalMail(currentListing, category, author.id.uuid);
    if (category === LISTING_CATEGORY_EXPERT) activateExpertCampaign(currentListing);
    this.setState({ listings: listings });
    approveListing({ userId: currentUser.id.uuid, listingId });
  }

  onSubmitDeposit(depositListingId) {
    const { history } = this.props;
    const path = createResourceLocatorString('SubscriptionPage', routeConfiguration(), {}, {});
    history.push(path, {});
  }
  

  handleCloseModal() {
    const { history, params } = this.props;
    this.setState({ alreadyLoaded: false, listings: [] });
    history.push(
      createResourceLocatorString('ManageListingsPage', routeConfiguration(), {
        tab: 'tickets',
        page: params.page,
      })
    );
  }

  handleSidebarVisibility(state) {
    this.setState({ sidebarVisible: state });
  }

  render() {
    const {
      closingListing,
      closingListingError,
      changingState,
      changingStateError,
      openingListing,
      openingListingError,
      queryInProgress,
      queryListingsError,
      scrollingDisabled,
      intl,
      authScopes,
      currentUserHasExpertListing,
      currentUserHasUnpublishedExpertListing,
      currentUser,
      params,
      onManageDisableScrolling,
      history,
      softwareExperts,
    } = this.props;

    const isAdmin =
      currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

    const listings = this.state.listings;
    const { tab, page } = params;

    const isJobs =
      tab === 'projects-posted' || tab === 'projects-ongoing' || tab === 'projects-completed';

    const isExpert = tab === 'expert';

    const isService = tab === 'services';

    let headingCategory = tab;
    if (tab === 'projects-posted') {
      headingCategory = 'projects';
    } else if (tab === 'projects-ongoing') {
      headingCategory = 'ongoing projects';
    } else if (tab === 'projects-completed') {
      headingCategory = 'completed projects';
    }

    const displayListings =
      listings?.length > page * (paginationSize + 1)
        ? listings.slice((page - 1) * paginationSize, page * paginationSize)
        : listings.slice((page - 1) * paginationSize);

    const createJobLink = (
      <NamedLink className={css.mainTab} name="NewListingPage">
        <FormattedMessage id="ManageListingsPage.createProject" />
      </NamedLink>
    );

    const loadingResults = (
      <h2>
        <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
      </h2>
    );

    const queryError = (
      <h2 className={css.error}>
        <FormattedMessage id="ManageListingsPage.queryError" />
      </h2>
    );

    const pagination = {
      totalItems: listings?.length,
      perPage: paginationSize,
      page: parseInt(page, 10),
      totalPages: Math.ceil(listings?.length / paginationSize),
    };

    const paginationLinks =
      listings.length > 0 && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="ManageListingsPage"
          pagePathParams={{ page: page, tab: tab }}
          pagination={pagination}
          isManageListingsPage
        />
      ) : null;

    const listingMenuOpen = this.state.listingMenuOpen;
    const closingErrorListingId = !!closingListingError && closingListingError.listingId;
    const openingErrorListingId = !!openingListingError && openingListingError.listingId;
    const changingStateListingId = !!changingStateError && changingStateError.listingId;

    let categoryForTitle = tab;
    if (isExpert) {
      categoryForTitle = 'expert profile';
    }
    if (isJobs) {
      categoryForTitle = 'projects';
    }

    const title = intl.formatMessage(
      { id: 'ManageListingsPage.title' },
      { category: categoryForTitle }
    );

    const panelWidth = 62.5;
    // Render hints for responsive image
    const renderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${panelWidth / 2}vw`,
      `${panelWidth / 3}vw`,
    ].join(', ');

    const sidebarVisible = this.state.sidebarVisible;
   
    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={css.topbar}
              mobileRootClassName={css.mobileTopbar}
              desktopClassName={css.desktopTopbar}
              currentPage="CenterPage"
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav
            className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}
          >
            <Sidebar
              tab={tab}
              isAdmin={isAdmin}
              isExpert={currentUserHasExpertListing}
              isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
              isVisible={this.state.sidebarVisible}
              setVisibility={this.handleSidebarVisibility}
            />

            <SecondaryButton
              className={css.inviteButton}
              onClick={() => this.setState({ sendInviteModal: true })}
            >
              <FormattedMessage id="ManageListingsPage.inviteUsersToSoftwareSupp" />
            </SecondaryButton>
            {isAdmin ? (
              <>
                <SecondaryButton
                  className={css.exportCSVButton}
                  onClick={() => this.setState({ showExportModal: true })}
                >
                  <FormattedMessage id="ManageListingsPage.exportCSV" />
                </SecondaryButton>
              </>
            ) : null}
          </LayoutWrapperSideNav>
          
          <LayoutWrapperMain>
            {queryInProgress ? loadingResults : null}
            {queryListingsError ? queryError : null}
            <div className={css.listingPanel}>
              {isService ? (
                <div className={css.addServiceButton}>
                  <PrimaryButton
                    onClick={() =>
                      history.push(
                        createResourceLocatorString('NewServicePage', routeConfiguration())
                      )
                    }
                  >
                    <FormattedMessage id="ManageListingsPage.addNewService" />
                  </PrimaryButton>
                </div>
              ) : null}
              {/* {heading} */}
              <div className={css.listingCards}>
                { console.log(displayListings)}
                {!queryInProgress && displayListings?.length > 0 ? (
                  displayListings?.map((l, index) => (
                    <ManageListingCard
                      className={css.listingCard}
                      key={l.id.uuid}
                      listing={l}
                      isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
                      actionsInProgressListingId={openingListing || closingListing || changingState}
                      onToggleMenu={this.onToggleMenu}
                      isExpert={currentUserHasExpertListing}
                      onCloseListing={this.handleCloseListing}
                      onOpenListing={this.handleOpenListing}
                      onChangeState={this.changeState}
                      hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                      hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                      hasChangingListingStateError={changingStateListingId.uuid === l.id.uuid}
                      renderSizes={renderSizes}
                      isLoggedInAsUser={authScopes && authScopes[0] === 'user:limited'}
                      currentUser={currentUser}
                      projectPosted={tab === 'projects-posted'}
                      index={index}
                      page={page}
                      onApproveListing={this.onApproveListing}
                      onRedirectToBillingPage={() => this.onSubmitDeposit(l.id.uuid)}
                      isAdmin={isAdmin}
                      softwareExperts={softwareExperts?.find(expert => expert.id === index)}
                    />
                  ))
                ) : this.state.alreadyLoaded ? (
                  <FormattedMessage
                    id={isJobs ? 'ManageListingsPage.noJobs' : 'ManageListingsPage.noListings'}
                    values={{ type: headingCategory, createJobLink: createJobLink }}
                  />
                ) : (
                  <div className={css.loadingListings}>
                    <p className={css.loadingMessage}>
                      <FormattedMessage
                        id="ManageListingsPage.loading"
                        values={{ category: categoryForTitle }}
                      />
                    </p>
                    <IconSpinner />
                  </div>
                )}
              </div>
              {paginationLinks}
            </div>

            <Modal
              id="InviteUserModal"
              contentClassName={css.enquiryModalContent}
              isOpen={this.state.sendInviteModal}
              onClose={() =>
                this.setState({ userSuccessfullyInvited: false, sendInviteModal: false })
              }
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <InviteUserForm
                id="InviteUserForm"
                onSubmit={this.onSubmitInvite}
                userSuccessfullyInvited={this.state.userSuccessfullyInvited}
                isAdmin={isAdmin}
                clearInvitation={() => this.setState({ userSuccessfullyInvited: false })}
              ></InviteUserForm>
            </Modal>
            <ExportCSVModal
              // Ten State musi być zawsze true
              isOpen={this.state.showExportModal}
              onClose={() => this.setState({ showExportModal: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              currentUser={currentUser}
            />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
       
        
        { this.state.renderModal &&
        <ExpertModal
          id="ListingPage.clientPath"
          step={1}
          listingId=""
          isOpen={this.state.openModal}
          onCloseModal={() => this.setState({ openModal: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          history={history}
        /> }
      </Page>
    );
  }
}

ManageListingsPageComponent.defaultProps = {
  listings: [],
  page: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { bool, func, object, shape, string } = PropTypes;

ManageListingsPageComponent.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    softwareExperts,
  } = state.ManageListingsPage;
  const { authScopes } = state.Auth;
  // const listings = getOwnListingsById(state, currentPageResultIds);
  const {
    currentUser,
    currentUserExpertListing,
    fetchingExpertListingInProgress,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;
  const { productSoftware } = state.marketplaceData;

  return {
    currentPageResultIds,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    authScopes,
    currentUser,
    currentUserExpertListing,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    fetchingExpertListingInProgress,
    softwareExperts,
    productSoftware,
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onFetchExpertsCount: () => dispatch(fetchExpertsCount()),
  onCheckAvailableExperts: (software, index) =>
    dispatch(fetchSoftwareExpertsCount(software, index)),
});

const ManageListingsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ManageListingsPageComponent);

export default ManageListingsPage;
