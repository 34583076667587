import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.css';

const SectionSkillsMaybe = props => {
  const { options, publicData } = props;
  if (!publicData.skills) {
    return null;
  }

  const selectedOptions = publicData && publicData.skills;
  
  const selectedConfigOptions = selectedOptions ? options.filter(o => selectedOptions.find(s => s === o.key)) : null;
  return ( selectedOptions ?
    <div className={css.sectionSkills}>
      <h2 className={css.skillsTitle}>
        <FormattedMessage id="ListingPage.skillsTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.software"
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions.length > 5}
      />
    </div> : null
  );
};

export default SectionSkillsMaybe;
