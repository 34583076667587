import React from 'react';
import { richText } from '../../util/richText';

import css from './ExpertPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionExpertiseAndNameMaybe = props => {
  const { expertName, expertise } = props;  
  return expertise ? (
    <div className={css.sectionExpertise}>
      <h1 className={css.expertise}>
        {richText(expertName + " | " + expertise, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </h1>
    </div>
  ) : null;
};

export default SectionExpertiseAndNameMaybe;