import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './AddRepoForm.css';

const AddRepoFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        isPendingApproval,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = isPendingApproval;

      return (
        <Form className={classes} onSubmit={values => handleSubmit(values)}>
          <div>
            <h3>Add a link to the document</h3>

            <FieldTextInput
              className={css.field}
              type="text"
              name="repoLinkTitle"
              id="repoLinkTitle"
              label="Link title"
              placeholder="Type in title of the document"
              required
            />

            <FieldTextInput
              className={css.field}
              type="url"
              name="repoLink"
              id="repoLink"
              label="Link to document"
              placeholder="Type in link to the document"
              required
            />

            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="AddRepoForm.addRepoButton" />
              {
                isPendingApproval
                && 
                <Tooltip interactive title={<FormattedMessage id="ProjectBoardPage.noInvite" />}>
                  <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
                </Tooltip>
              }
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

AddRepoFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

AddRepoFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const AddRepoForm = compose(injectIntl)(AddRepoFormComponent);

AddRepoForm.displayName = 'AddRepoForm';

export default AddRepoForm;
