import React, { useState } from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ExpertModal } from '../../components';
import {
  txIsEnquired,
  txIsExpertOfferSent,
  txIsBookingPaymentConfirmed,
  txIsBookingCanceled,
  txIsBookingCompleted,
  txIsExpertOfferCanceled,
  txIsAccepted,
} from '../../util/transaction';
import { createSlug} from '../../util/urlHelpers';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import { loadData } from './CenterPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  NamedLink,
  NamedRedirect,
  Page,
  PaginationLinks,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  PrimaryButton,
  GetStarted,
  Sidebar,
  Modal,
  SecondaryButton,
} from '../../components';
import { sendSoftwareSuppInviteMail } from '../../util/triggerMail';
import { InviteUserForm } from '../../forms';
import { CenterItem } from './CenterItem';
import { TopbarContainer } from '..';
import config from '../../config';

import css from './CenterPage.css';
import AuthenticationPage from '../AuthenticationPage/AuthenticationPage';

// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';

  if (txIsEnquired(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'CenterPage.stateEnquiry',
      }),
    };
  } else if (txIsExpertOfferCanceled(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'CenterPage.stateCanceled',
      }),
    };
  } else if (txIsBookingPaymentConfirmed(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'CenterPage.stateBookingRequested',
      }),
    };
  } else if (txIsBookingCompleted(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'CenterPage.stateMeetingCompleted',
      }),
    };
  } else if (txIsBookingCanceled(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'CenterPage.stateMeetingCanceled',
      }),
    };
  } else if (txIsExpertOfferSent(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: isOrder ? 'CenterPage.stateOfferSent' : 'CenterPage.stateOfferReceived',
      }),
    };
  } else if (txIsAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: !isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'CenterPage.statePreAccepted',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

export const CenterPageComponent = props => {
  const {
    unitType,
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    location,
    scrollingDisabled,
    transactions,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserHasListings,
    onManageDisableScrolling,
    currentUserStripeAccounts,
    history,
  } = props;
  const { tab } = params;

  let step = location?.search.replace('?step=', '');
  step = step === '2' ? 2 : step === '3' ? 3 : step === '4' ? 4 : 1;
  let usdAccount = currentUserStripeAccounts?.find(user => user.currency === 'usd');
  let plnAccount = currentUserStripeAccounts?.find(user => user.currency === 'pln');
  const userHasBalance = usdAccount?.balance || plnAccount?.balance;
  const [expertModalOpen, setExpertModalOpen] = useState(!userHasBalance);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [successfullyInvited, setSuccessfullyInvited] = useState(false);
  const validTab =
    tab === 'getStarted' ||
    tab === 'orders' ||
    tab === 'sales' ||
    tab === 'ordersAdmin' ||
    tab === 'salesAdmin' ||
    tab === 'salesExpert' ||
    tab === 'meetings' ||
    tab === 'messages';
  if (!validTab) {
    return <AuthenticationPage />;
  }

  const isGetStarted = tab === 'getStarted';
  const isSales = tab === 'sales' || tab === 'salesExpert' || tab === 'salesAdmin';
  const isSalesCustomer = tab === 'sales';
  const isOrders = tab === 'orders' || tab === 'ordersAdmin';
  const isMeetings = tab === 'meetings';
  const isMessages = tab === 'messages';

  if (currentUserHasExpertListing && isSalesCustomer) {
    return <NamedRedirect name="CenterPage" params={{ tab: 'salesExpert' }} />;
  }

  const pageTitle = intl.formatMessage({
    id: `CenterPage.${tab}Title`,
  });

  const toTxItem = tx => {
    let type;
    // for Notifications and Your offers:
    if (isOrders) {
      type = 'order';
    } else if (isSales) {
      type = 'sale';
    } else {
      // for Messages and Meetings:
      const currentUserId = currentUser?.id.uuid;
      const currentCustomerId = tx.customer.id.uuid;
      if (currentUserId === currentCustomerId) {
        type = 'order';
      } else {
        type = 'sale';
      }
    }

    const stateData = txState(intl, tx, type);
    // For Service scope discussion switch from Sale to Order
    const isServiceCustomer = currentUser?.id.uuid === tx.customer.id.uuid;
    if (isServiceCustomer) {
      type = 'order';
    }
    // Create object with related experts' offers for SoftwareSupp Summary Offer
    let adminOfferData = undefined;
    const currentTxIsOfferFromAdmin = tx.customer.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;
    if (currentTxIsOfferFromAdmin) {
      const currentTxOfferRelatedJobId = tx.listing.id.uuid;
      const currentTxOfferId = tx.id.uuid;
      const relatedOffers = transactions.filter(txOffer => {
        const isAdminOffer = txOffer.customer.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;
        const isProjectRelatedTransaction = txOffer.listing.id.uuid === currentTxOfferRelatedJobId;
        const isTheSameTransaction = txOffer.id.uuid === currentTxOfferId;
        const isCorrectLastTransition = txIsExpertOfferSent(txOffer);
        return (
          !isAdminOffer &&
          isProjectRelatedTransaction &&
          !isTheSameTransaction &&
          isCorrectLastTransition
        );
      });
      adminOfferData = {
        relatedOffers: relatedOffers,
      };
    }
    // For Notifications (Incoming offers) display only sales and service negotiations:
    const notDisplay =
      isSales && currentUser?.id.uuid === tx.customer.id.uuid && !isServiceCustomer;

    // Render CenterItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData && !notDisplay ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <CenterItem
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          isSales={isSales}
          isOrders={isOrders}
          isMeetings={isMeetings}
          isMessages={isMessages}
          isServiceCustomer={isServiceCustomer}
          stateData={stateData}
          adminOfferData={adminOfferData}
        />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="CenterPage.fetchFailed" />
    </p>
  ) : null;

  const jobsLink = (
    <NamedLink name="SearchPage_jobs">
      <FormattedMessage id={isOrders ? 'CenterPage.jobsLink' : 'CenterPage.jobsLinkProject'} />
    </NamedLink>
  );

  const expertsLink = (
    <NamedLink name="SearchPage_experts">
      <FormattedMessage id="CenterPage.expertsLink" />
    </NamedLink>
  );
  
  const pmMeetingButton = (
    <NamedLink
      name="ExpertPage"
      params={{
        id: process.env.REACT_APP_ADMIN_BOOKING_LISTING_ID,
        slug: createSlug('Michael'),
        category: "experts"
      }}
      state ={{ openBookModal: true, freeBooking: true }}
      className={css.pmMeetingButton}
    >
      <PrimaryButton
        className={css.newConversationButton}
      >
        <FormattedMessage id="CenterPage.pmMeeting" />
      </PrimaryButton>
    </NamedLink>

    // <PrimaryButton
    //   onClick={() => {
    //     window.location.href = 'https://calendly.com/softwaresupp/software-consultation';
    //   }}
    //   className={css.newConversationButton}
    // >
    //   <FormattedMessage id="CenterPage.pmMeeting" />
    // </PrimaryButton>
  );

  const noResultsMessage = `CenterPage.no${tab}Found`;

  const noResultsLink = () => {
    switch (true) {
      case isOrders:
        return { jobsLink };

      case isMeetings:
        return { expertsLink };

      default:
        return null;
    }
  };

  // render no orders/sales found message if all transactions are tied to deleted listings
  const noResults =
    !fetchInProgress &&
    transactions.length === 0 &&
    !fetchOrdersOrSalesError &&
    !isGetStarted &&
    !isMeetings ? (
      <>
        <li key="noResults" className={css.noResults}>
          <FormattedMessage id={noResultsMessage} values={noResultsLink()} />
        </li>
        <ExpertModal
          id="ListingPage.clientPath"
          step={1}
          listingId=""
          isOpen={expertModalOpen}
          onCloseModal={() => setExpertModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          history={history}
        />
      </>
    ) : null;

  const pagingLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="CenterPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const isAdmin =
    currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

  let results;
  if (isGetStarted) {
    results = (
      <GetStarted
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        initialStep={step}
      />
    );
  } else if (!fetchInProgress) {
    results = transactions.map(toTxItem);
  } else {
    results = (
      <li className={css.listItemsLoading}>
        <IconSpinner />
      </li>
    );
  }

  const archiveInboxTitle = (
    <div className={css.archiveInboxWrapper}>
      <p className={css.archiveInboxTitleText}>
        <FormattedMessage id="CenterPage.ArchiveInboxTitle" />
      </p>
      <div className={css.archiveSubtitleWrapper}>
        <InfoOutlinedIcon style={{ fontSize: 14 }} />
        {'\u00A0'}
        <div className={css.archiveSubtitleText}>
          <FormattedMessage id="CenterPage.ArchiveInboxSubtitle" />
          <NamedLink name="MessagesPage">here.</NamedLink>
        </div>
      </div>
    </div>
  );
  const onSubmitInvite = async (values, form) => {
    setSuccessfullyInvited(false);
    setTimeout(form.reset);
    sendSoftwareSuppInviteMail(values.allowedUserEmail);
    setSuccessfullyInvited(true);
  };
  return (
    <Page title={pageTitle} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="CenterPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav
          className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}
        >
          <Sidebar
            tab={tab}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
          <>
            <SecondaryButton className={css.inviteButton} onClick={() => setInviteOpen(true)}>
              <FormattedMessage id="ManageListingsPage.inviteUsersToSoftwareSupp" />
            </SecondaryButton>
          </>
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
          {error}
          <ul className={css.itemList}>
            {/* {isMessages ? pmConversationButton : null} */}
            {isMeetings ? pmMeetingButton : null}
            {isMessages ? archiveInboxTitle : null}
            {results}
            {noResults}
          </ul>
          {!isGetStarted ? pagingLinks : null}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      <Modal
        id="InviteUserModal"
        contentClassName={css.enquiryModalContent}
        isOpen={inviteOpen}
        onClose={() => {
          setSuccessfullyInvited(false);
          setInviteOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <InviteUserForm
          id="InviteUserForm"
          onSubmit={onSubmitInvite}
          setUserSuccessfullyInvited={data => setSuccessfullyInvited(data)}
          userSuccessfullyInvited={successfullyInvited}
          isAdmin={isAdmin}
          clearInvitation={() => setSuccessfullyInvited(false)}
        ></InviteUserForm>
      </Modal>
    </Page>
  );
};

CenterPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

CenterPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});
const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionRefs,
  } = state.CenterPage;
  const {
    currentUser,
    currentUserNotificationCount: providerNotificationCount,
    currentUserHasListings,
    currentUserExpertListing,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
    currentUserHasListings,
    currentUserExpertListing,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
  };
};

const CenterPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CenterPageComponent);

CenterPage.loadData = loadData;

export default CenterPage;
