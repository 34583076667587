import React from 'react';
import { oneOf, oneOfType, object } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { propTypes, LISTING_CATEGORY_TICKET, LISTING_CATEGORY_JOB } from '../../util/types';
import { createSlug, stringify } from '../../util/urlHelpers';
import {
  Avatar,
  NamedLink,
} from '../../components';
import { BookingInfoMaybe } from './BookingInfoMaybe';
import config from '../../config';

import css from './CenterPage.css';

const { Money } = sdkTypes;

const formatDate = (intl, date) => {
    return {
      short: intl.formatDate(date, {
        month: 'short',
        day: 'numeric',
      }),
      long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
    };
  };

const priceData = (price, commission = 0.5, intl) => {
    if(price && !(price instanceof Money))
        price = new Money(price.amount, config.currency);
    if (price && price.currency === config.currency) {
        const formattedPriceUser = formatMoney(intl, price);
        const formattedPriceExpert = formatMoney(intl, new Money(price.amount * (1 - commission), config.currency));
        return { formattedPriceUser, formattedPriceExpert, priceTitle: formattedPriceUser };
    } else if (price) {
        return {
        formattedPriceUser: `(${price.currency})`,
        formattedPriceExpert: `(${price.currency})`,
        priceTitle: `Unsupported currency (${price.currency})`,
        };
    }
    return {};
};

const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
    const listingId = listing.id && listing.id.uuid;
    const label = listing.attributes.title;
    const listingDeleted = listing.attributes.deleted;
    const listingCategory = listing.attributes.pubicData?.category
  
    if (!listingDeleted && listingCategory) {
      const params = { id: listingId, slug: createSlug(label), category: `${listingCategory}s`};
      const to = { search: stringify(searchParams) };
      return (
        <NamedLink
          className={className}
          name={`${listingCategory.charAt(0).toUpperCase() + listingCategory.slice(1)}Page`}
          params={params}
          to={to}
        >
          <Avatar user={otherUser} disableProfileLink />
        </NamedLink>
      );
    } else {
      return <Avatar user={otherUser} disableProfileLink />;
    }
};

export const CenterItem = props => {
    const { 
      type, 
      tx, 
      intl, 
      stateData, 
      isOrders, 
      isMeetings,
      unitType, 
      adminOfferData 
    } = props;
    const { customer, provider, listing } = tx;
    // protectedData is maintained for older offers
    const originalOfferSrc = (tx?.attributes?.protectedData?.scope !== undefined) ? 'protectedData' : 'metadata';
    const originalOfferData = originalOfferSrc === 'protectedData' ? tx?.attributes?.protectedData 
                              : tx?.attributes?.metadata?.originalOffer;
    const isAdminOffer = !!originalOfferData?.isAdmin;
    const adminPriceMin = isAdminOffer ? formatMoney(intl, new Money(originalOfferData.estimatedPriceMin*100, config.currency)) : null;
    const adminPriceMax = isAdminOffer ? !isNaN(originalOfferData.estimatedPriceMax) ?
      formatMoney(intl, new Money(originalOfferData.estimatedPriceMax*100, config.currency)) : '' : null;
    const formattedAdminPriceRange = isAdminOffer ? adminPriceMax !== '' ? (adminPriceMin + ' - ' + adminPriceMax) : adminPriceMin : '';
    const isAdminListing = listing.id.uuid === process.env.REACT_APP_ADMIN_PM_LISTING_ID;
    const isAll = type === 'all'; 
    const isOrder = type === 'order' || (isAdminListing && isAll);
    const otherUser = isOrder || (isAdminListing && isAll) ? provider : customer;
    const otherUserDisplayName = otherUser.attributes.profile.displayName?.slice(0, -2);
    const isOtherUserBanned = otherUser.attributes.banned;
  
    const lastTransitionedAt = formatDate(intl, tx.attributes.lastTransitionedAt);
  
    const linkClasses = classNames(css.itemLink, {
      [css.bannedUserLink]: isOtherUserBanned,
    });
  
    let listingType =  listing.attributes.publicData?.category === LISTING_CATEGORY_JOB && listing.attributes.publicData?.private
      ? 'private project'
      : listing.attributes.publicData?.category === LISTING_CATEGORY_TICKET && listing.attributes.publicData?.private
        ? 'private ticket'
        : listing.attributes.publicData?.category;
    
    if (listing.attributes.publicData?.category === 'job') {
      listingType = 'project';
    }
  
    const listingLink = listing ? createListingLink(listing, otherUser) : null;
    const price = tx.attributes.payinTotal;
  
    let commission =
      listing?.attributes.publicData?.commission
        ? listing?.attributes.publicData?.commission
        : tx.attributes.protectedData.commission;
  
    commission=0;    
    const { formattedPriceExpert, formattedPriceUser } =
      priceData(price, commission, intl);
  
    const offerAbout = tx.attributes.protectedData?.listingOfferTitle;
  
    const listingTitle =
      <FormattedMessage
        id="CenterPage.listingTitle"
        values={{
          listingType:
            offerAbout ? 'offer about' : listingType,
          listingTitle: 
            offerAbout ? offerAbout : listing.attributes.title
        }} />
  
    return (
      <div className={css.item}>
        <div className={css.itemAvatar}>
          {isOrder && listing ? listingLink : <Avatar user={otherUser} disableProfileLink/>}
        </div>
        <NamedLink
          className={linkClasses}
          name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
          params={{ id: tx.id.uuid }}
          state={!!adminOfferData ? {expertOffers: adminOfferData['relatedOffers']} : null}
        >
          <div className={css.itemInfo}>
            <div className={classNames(css.itemUsername, stateData.nameClassName)}>
              {
                isAdminOffer ? <FormattedMessage id="CenterPage.adminSummaryOfferTitle"/>
                : otherUserDisplayName
              }
            </div>
            <div className={css.listingTitle}>
              {listing.attributes.deleted
                ? <FormattedMessage id="CenterPage.deletedListing" />
                : 
                !isMeetings? listingTitle 
                : null}
            </div>
            {(isMeetings ) && tx.booking ? 
            <BookingInfoMaybe
              bookingClassName={stateData.bookingClassName}
              intl={intl}
              isOrder={isOrder}
              tx={tx}
              unitType={unitType}
            /> : null}
          </div>
          <div className={css.price}>
            {isAdminOffer ? formattedAdminPriceRange
              : (isOrders) ? formattedPriceExpert 
              : (!isMeetings) ? formattedPriceUser 
              : null}
          </div>
          <div className={css.itemState}>
            <div className={classNames(css.stateName, stateData.stateClassName)}>
              {stateData.state}
            </div>
            <div
              className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
              title={lastTransitionedAt.long}
            >
              {lastTransitionedAt.short}
            </div>
          </div>
        </NamedLink>
      </div>
    );
  };
  
CenterItem.propTypes = {
unitType: propTypes.bookingUnitType.isRequired,
type: oneOf(['order', 'sale', 'all']).isRequired,
tx: oneOfType([propTypes.transaction, object]).isRequired,
intl: intlShape.isRequired,
};