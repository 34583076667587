// Helper modul for user operation
import { useSelector } from 'react-redux'


export function useAdminCheck(){

  const adminId = process.env.REACT_APP_ADMIN_USER_ID
  const currentUser = useSelector(state => state.user.currentUser)

  if(adminId === currentUser?.id.uuid){
    return true
  } else {
    return false
  }
}
