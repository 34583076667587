/* eslint-disable */
import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { compose } from 'redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import {
  required,
  composeValidators,
  numberValueAtLeast,
  moneyValueAtLeast,
  nonNegativeNumber,
} from '../../util/validators';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Button,
  Form,
  FieldTextInput,
  FieldCurrencyInput,
  NamedLink,
        PrimaryButton,
  MultilevelScopeInput,
} from '../../components';

import { s3uploadFile } from '../../util/api';

import css from './NewOfferCVForm.css';

const termsLink = (
  <NamedLink name="TermsOfServicePage" className={css.legalLink}>
    <FormattedMessage id="JobApplicationForm.termsOfUse" />
  </NamedLink>
);

const sendOfferTooltip = (
  <>
    <FormattedMessage id={`NewOfferForm.sendOffer`} />
    <Tooltip
      interactive
      title={
        <FormattedMessage
          id={`JobApplicationForm.sendOfferExplanation`}
          values={{ br: <br />, terms: termsLink }}
        />
      }
    >
      <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
    </Tooltip>
  </>
);

const offerTooltip = (
  <Tooltip
    interactive
    title={
      <FormattedMessage
        id={'JobApplicationForm.offerTooltip'}
        values={{ br: <br />, terms: termsLink }}
      />
    }
  >
    <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
  </Tooltip>
);



const NewOfferCvForm = props => {
  const { onSubmit } = props;





  // const updateUrlFile = (args, state, { setIn, changeValue }) => {
  //   // alert("hi");
  //   // changeValue(state, "lastName", () => "xxx");
  //   const field = state.fields["filename"];
  //   field.change(file);
  //   // state.formState.submitFailed = true;
  //   // field.data = {...field.data, initialError: 'x0x0x'};
  // };

  return (
    <>
      <FinalForm
        onSubmit={onSubmit}
        // mutators={{ updateUrlFile }}
        render={({ form , handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col xs={12}>
                  <FieldTextInput
                    id="offer"
                    name="offer"
                    className={css.textfield}
                    type="textarea"
                    label="Offer Message"
                    placeholder="OfferMessage Placeholder"
                    tooltip={offerTooltip}
                  />
                </Col>
                <Col xs={12}>
                  {/* <Field
                  name="files"
                  component="input"
                  type="file"
                  placeholder="Upload your cv in PDF"
                  onChange={target => handleFileUpload(target)}
                /> */}
                </Col>
                <Col xs={12} className={css.buttonContainer}>
                  <PrimaryButton className={css.sendButton} type="submit">{sendOfferTooltip}</PrimaryButton>
                </Col>
              </Row>
            </Container>
          </form>
        )}
      />
    </>
  );
};

export default NewOfferCvForm;
