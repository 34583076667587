import omit from 'lodash/omit';
import config from '../../config';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import * as log from '../../util/log';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditProjectPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditProjectPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditProjectPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditProjectPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditProjectPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditProjectPage/UPDATE_LISTING_ERROR';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditProjectPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditProjectPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditProjectPage/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST = 'app/EditProjectPage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/EditProjectPage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/EditProjectPage/PUBLISH_LISTING_ERROR';

export const SHOW_LISTINGS_REQUEST = 'app/EditProjectPage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS = 'app/EditProjectPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR = 'app/EditProjectPage/SHOW_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  fetchedTicketAnswers: [],
  fetchedExpertProfileForService: null,
  fetchedExpertServices: null,
  showListingError: null,
  enquiryModalOpenForListingId: null,
  imageOrder: [],
  removedImageIds: [],
  images: [],
  createListingDraftInProgress: false,
  createListingDraftError: null,
  submittedListingId: null,
  listingDraft: null,
};

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// -----------

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return {
        ...state,
        fetchedTicketAnswers: payload.data.answers,
        fetchedExpertProfileForService: payload.data.expertProfile,
        fetchedExpertServices: payload.data.expertServices
      };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        submittedListingId: null,
        listingDraft: null,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        createListingDraftInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

      case PUBLISH_LISTING_REQUEST:
        return {
          ...state,
          publishingListing: payload.listingId,
          publishListingError: null,
        };
      case PUBLISH_LISTING_SUCCESS:
        return {
          ...state,
          redirectToListing: true,
          publishingListing: null,
          createListingDraftError: null,
          updateListingError: null,
          showListingsError: null,
          uploadImageError: null,
          createListingDraftInProgress: false,
          updateInProgress: false,
        };
      case PUBLISH_LISTING_ERROR: {
        // eslint-disable-next-line no-console
        console.error(payload);
        return {
          ...state,
          publishingListing: null,
          publishListingError: {
            listingId: state.publishingListing,
            error: payload,
          },
        };
      }

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, listing: payload.data.data, updateInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

      case SHOW_LISTINGS_REQUEST:
        return { ...state, showListingsError: null };
      case SHOW_LISTINGS_SUCCESS:
        return { ...initialState };
      case SHOW_LISTINGS_ERROR:
        // eslint-disable-next-line no-console
        return { ...state, showListingsError: payload, redirectToListing: false };

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId } = payload;
      const file = state.images[id].file;
      const images = { ...state.images, [id]: { id, imageId, file } };
      return { ...state, images };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const imageOrder = state.imageOrder.filter(i => i !== id);
      const images = omit(state.images, id);
      return { ...state, imageOrder, images, uploadImageError: error };
    }
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //


export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = (data) => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { data },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

export const publishListing = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

export const showListings = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false, throwErrors = true) => (dispatch, getState, sdk) => {

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };
  
  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const answers = data.data.data.attributes.publicData.answers;
      const listingAuthorId = data.data.data.relationships.author.data.id.uuid;
      
      const isTask = data.data.data.attributes.publicData.isTask;
      if (isTask) {
        sdk.listings.query({
          authorId: listingAuthorId,
          pub_category: "expert",
          include: ['author', 'author.image']
        }).then(res => {
          const expertProfile = res.data.data[0];
          data = {
            ...data,
            expertProfile: expertProfile,
          };
          dispatch(showListingSuccess({
            answers: answers,
            expertProfile: expertProfile,
            expertServices: null
          }));
          dispatch(addMarketplaceEntities(data));
          return data;
        });
      }
      else {
        dispatch(showListingSuccess({
          answers: answers,
        }));
        dispatch(addMarketplaceEntities(data));
        return data;
      }
    })
    .catch(e => {
      if (throwErrors)
      
        dispatch(showListingError(storableError(e)));
    });
};

export const showUser = (userId) => (dispatch, getState, sdk) => {
  return sdk.users.show({
    id: userId,
    include: ['profileImage'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
    ],
  })
    .then(data => {
      return data;
    })
    .catch(e => {
    });
}

export const showTicketMessageOwner = (listingId) => (dispatch, getState, sdk) => {
  const params = {
    id: listingId,
    include: ['author.profileImage'],
    'fields.image': [
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = sdk.listings.show(params);

  return show
    .then(data => {
     
      return data;
    })
    .catch(e => {

     
    });
};

// Images return imageId which we need to map with previously generated temporary id
export const requestImageUpload = (actionPayload) => (dispatch, getState, sdk) => {
  const id = actionPayload.id;
  dispatch(uploadImage(actionPayload));
  return sdk.images
    .upload({ image: actionPayload.file })
    .then(resp => {
      dispatch(uploadImageSuccess({ data: { id, imageId: resp.data.data.id } }))
      return resp.data.data.id;
    })
    .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
};

export function requestUpdateListing(data) {
  return (dispatch, getState, sdk) => {
    dispatch(updateListing(data));
    const { id } = data;
    let updateResponse;
    return sdk.ownListings
      .update(data, {expand: true} )
      .then(response => {
        updateResponse = response;
        response.data.data.type = 'listing';
        return dispatch(showListing(id));
      })
      .then(() => {
        dispatch(updateListingSuccess(updateResponse));
        dispatch(addMarketplaceEntities(updateResponse));
        return updateResponse;
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
        dispatch(updateListingError(storableError(e)));
        throw e;
      });
  };
}

export function requestCreateListingDraft(data) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingDraft(data));

    const commission = 1 / 2;

    data.publicData = {
      ...data.publicData,
      state: data.publicData.state ? data.publicData.state : 'open',
      expert: data.publicData.expert ? [data.publicData.expert, ...config.defaultPrivateTicketExpertList] : config.defaultPrivateTicketExpertList,
      commission,
    };

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    return sdk.ownListings
      .createDraft(data, queryParams)
      .then(response => {
        const id = response.data.data.id.uuid;
        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));
        (
          window.dataLayer.push({
            'event': 'pushEventGTM',
            'eventCategory': 'Interaction',
            'eventAction': 'Add Expert',
          })
        )
        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(response));
        return id;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
  dispatch(publishListing(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true, include: ["images"] })
    .then(response => {
      // Add the created listing to the marketplace data
      dispatch(addMarketplaceEntities(response));
      
   
      dispatch(publishListingSuccess(response));
      return response.data.data;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};