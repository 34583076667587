import React, { useEffect } from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';

import ProjectItem from './ProjectItem';

import css from './BillingPage.css';
// userId, userEmail, userExpertListingId, currentUserPrivileges, category, isAdmin;
const UserOngoingProjectsPanel = props => {
  const {
    currentUser,
    userStripeAccounts,
    onManageDisableScrolling,
    history,
    ongoingProjects,
   
    projects,
    increaseBalance,
    onFetchCurrentUserStripeAccounts,
    reload,
    setReload,
  } = props;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, reload, ongoingProjects]);
  const awaitingProjects = projects.filter(
    data =>
      data.attributes.state === 'pendingApproval' && data.attributes.publicData.state === 'open'
  );
  const openProjects = projects.filter(
    data => data.attributes.state === 'published' && (data.attributes.publicData.state === 'open' || data.attributes.publicData.state === 'awaiting')
  );
  const closedProjects = projects.filter(
    data => data.attributes.state === 'published' && data.attributes.publicData.state === 'closed'
  );

  const ognoingProjectsPanel = (
    <div>
      <div>
        {ongoingProjects.length === 0 ? (
          <p className={css.loadingInfo}>
            <FormattedMessage id="BillingPage.noOngoinProjects" />
          </p>
        ) : (
          <ul>
            <p className={css.balanceDetailsTitle}>
              <FormattedMessage id="BillingPage.ognoingProjects" />
            </p>
            {ongoingProjects.map((project, id) => {
              return (
                <li key={id} className={css.listItem}>
                  <ProjectItem
                    onFetchCurrentUserStripeAccounts={onFetchCurrentUserStripeAccounts}
                    setReload={setReload}
                    pub_state={'ongoing'}
                    history={history}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                    userStripeAccounts={userStripeAccounts}
                    project={project}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div>
        {awaitingProjects.length > 0 ? (
          <ul>
            <p className={css.balanceDetailsTitle}>
              <FormattedMessage id="BillingPage.awaitingProjects" />
            </p>
            {awaitingProjects.map(project => {
              return (
                <li key={project.id} className={css.listItem}>
                  <ProjectItem
                    onFetchCurrentUserStripeAccounts={onFetchCurrentUserStripeAccounts}
                    setReload={setReload}
                    history={history}
                    pub_state={'awaiting'}
                    increaseBalance={increaseBalance}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                    userStripeAccounts={userStripeAccounts}
                    project={project}
                  />
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
      <div>
        {openProjects.length > 0 ? (
          <ul>
            <p className={css.balanceDetailsTitle}>
              <FormattedMessage id="BillingPage.openProjects" />
            </p>
            {openProjects.map((project, id) => {
              return (
                <li key={id} className={css.listItem}>
                  <ProjectItem
                    onFetchCurrentUserStripeAccounts={onFetchCurrentUserStripeAccounts}
                    setReload={setReload}
                    history={history}
                    pub_state={'open'}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                    userStripeAccounts={userStripeAccounts}
                    project={project}
                  />
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
      <div>
        {closedProjects.length > 0 ? (
          <ul>
            <p className={css.balanceDetailsTitle}>
              <FormattedMessage id="BillingPage.completedProjects" />
            </p>
            {closedProjects.map((project, id) => {
              return (
                <li key={id} className={css.listItem}>
                  <ProjectItem
                    onFetchCurrentUserStripeAccounts={onFetchCurrentUserStripeAccounts}
                    setReload={setReload}
                    history={history}
                    pub_state={'closed'}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                    userStripeAccounts={userStripeAccounts}
                    project={project}
                  />
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );

  return <div>{ognoingProjectsPanel}</div>;
};

export default injectIntl(UserOngoingProjectsPanel);
