import React from 'react';
import { AvatarLarge } from '../../components';
import { MdVerifiedUser } from 'react-icons/md';
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage } from '../../util/reactIntl';
import { Tooltip } from '@material-ui/core';

import css from './ExpertPage.css';

const SectionAvatar = props => {
  const { user } = props;

  const tooltipStyles = {
    tooltip: {
        width: "200px",
    }
  };

  const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

  const isStripeVerified = user?.attributes?.profile?.publicData?.hasStripeConnected;

  const verifiedIcon = (
    <CustomTooltip title={<FormattedMessage id={"SectionExpertName.verifiedIconDetails"} />}>
      <div className={css.verifiedIcon}>
        <MdVerifiedUser size={'50px'} />
      </div>
    </CustomTooltip>
  );

  return <div className={css.sectionAvatar}>
      <AvatarLarge
        user={user}
        className={css.avatarDesktop}
        initialsClassName={css.initialsDesktop}
        disableProfileLink
      />
    {isStripeVerified ? verifiedIcon : null}
    </div>
};

export default SectionAvatar;
