import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, SecondaryButton, DateSelector } from '../../components';

import css from './EditDateForm.css'

const EditDateFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        form
      } = formRenderProps;

      const classes = classNames(css.root, className);
         
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || pristine;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.formWrapper}>
              <DateSelector
                  intl={intl}
                  form={form}
              />

              <SecondaryButton
              className={css.button}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <FormattedMessage id="EditDateForm.submit" />
              </SecondaryButton> 
            
        </div>
        </Form>
      );
    }}
  />
);

EditDateFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
};

EditDateFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditDateFormComponent);
