import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { MessageCircle } from 'react-feather';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import {
  ModalInMobile,
  PrimaryButton,
  SecondaryButton,
  NamedLink,
  JobApplicationPanel,
} from '../../components';
import SideActionBarMaybe from '../../containers/ListingPage/SideActionBarMaybe.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import { RiAdminLine, RiShieldCheckLine } from 'react-icons/ri';
import { GoCheck } from 'react-icons/go';

import css from './BookingPanel.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const { Money } = sdkTypes;
const TODAY = new Date();

const priceData = (price, commission = 0.5, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPriceUser = formatMoney(intl, price);
    const formattedPriceExpert = formatMoney(
      intl,
      new Money(price.amount * (1 - commission), config.currency)
    );
    return { formattedPriceUser, formattedPriceExpert, priceTitle: formattedPriceUser };
  } else if (price) {
    return {
      formattedPriceUser: `(${price.currency})`,
      formattedPriceExpert: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    isOwnListing,
    currentCustomerIsAdmin,
    estimatedPriceRange,

    isAdmin,
    onSubmit,
    handleNewJobOffer,
    onSubmitRedirect,
    onManageDisableScrolling,
    history,
    location,
    intl,
    hasExpertProfile,
    estimatedPriceMaybe,
    currentUser,
    isTransaction,
    currentCustomerStripe,
    currentProviderStripe,
    currentUserHasStripeConnected,
    expertListingIsPendingApproval,
    showPaymentMissing,
    showAcceptOffer,
    showContactUser,
    showContactExpert,
    onAskForDetails,
    onAdminOffer,
    messageFormatting,
    editParams,
    expertStack,
    isSolved,
    onChangeState,
    transactionRole,
    projectBoardAllowed,
    onOpenProjectBoard,
    onOpenClientPathModal,
    softwareExperts,
    allExperts,
  } = props;

  const price = estimatedPriceMaybe;
  const timeZone = listing.attributes.availabilityPlan?.timezone
    ? listing.attributes.availabilityPlan?.timezone
    : typeof window !== 'undefined'
    ? getDefaultTimeZoneOnBrowser()
    : 'Etc/UTC';
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const publicData = listing.attributes.publicData;

  const isAwaiting = publicData?.state === 'awaiting';
  const isOpen = publicData?.state === 'open';
  const listingAuthorId = listing?.author?.id.uuid;

  const expertStackMatching =
    (expertStack &&
    expertStack.find(software => software === listing.attributes.publicData.primarySoftware)
      ? true
      : false) ||
    isTransaction ||
    listing?.attributes.publicData?.primarySoftware === 'other';

  const payoutPageLink = (
    <NamedLink name="StripePayoutPage">
      <FormattedMessage id="TransactionPanel.addPayoutDetails" />
    </NamedLink>
  );

  const { formattedPriceUser, priceTitle } = priceData(
    price,
    listing?.attributes.publicData?.commission,
    intl
  );
  const isBook = !!parse(location.search).book;

  const classes = classNames(rootClassName || css.root, className);

  const bookingStripe =
    !currentProviderStripe && isOwnListing && showPaymentMissing && showPaymentMissing.booking;

  const softwaresuppEmail = (
    <a href={config.softwaresuppEmail}>
      <FormattedMessage id="BookingPanel.softwaresuppEmail" />
    </a>
  );
  const termsLink = (
    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
      <FormattedMessage id="JobApplicationForm.termsOfUse" />
    </NamedLink>
  );

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {/* {isAdmin && isTransaction ?
          <div className={css.pricingInfo}>
            <div className={css.pricing}>
              <FormattedMessage id='BookingPanel.yourOfferInfo' />
              <div className={css.desktopPriceValue} title={priceTitle}>
                {formattedPriceUser}
              </div>
            </div>
          </div>
        : null} */}
        {!!price && isTransaction && !isAdmin && !showPaymentMissing.booking ? (
          <div className={css.pricingInfo}>
            <div className={css.pricing}>
              {currentCustomerIsAdmin ? (
                <>
                  <FormattedMessage id="BookingPanel.offerInfo" />
                  <div className={css.desktopPriceValue} title={priceTitle}>
                    {estimatedPriceRange}
                  </div>
                </>
              ) : isOwnListing ? (
                <>
                  <FormattedMessage id="BookingPanel.offerInfo" />
                  <div className={css.desktopPriceValue} title={priceTitle}>
                    {formattedPriceUser}
                  </div>
                </>
              ) : (
                <>
                  {/* <FormattedMessage id='BookingPanel.yourOfferInfo' />
                  <div className={css.desktopPriceValue} title={priceTitle}>
                    {formattedPriceExpert}
                  </div> */}
                </>
              )}
            </div>
          </div>
        ) : null}

        {showPaymentMissing ? (
          <div>
            {(!isOwnListing && !currentCustomerStripe) || bookingStripe ? (
              <p className={css.smallPrintStripeNeeded}>
                <FormattedMessage
                  id="TransactionPanel.stripeConnectionNeeded"
                  values={{ payoutPageLink }}
                />
              </p>
            ) : null}
            <PrimaryButton
              className={css.stripeConnectButton}
              disabled={(!currentCustomerStripe && !isOwnListing) || bookingStripe}
              onClick={() => onSubmitRedirect()}
              type="submit"
            >
              {isOwnListing && !!showPaymentMissing.booking ? (
                <FormattedMessage id="TransactionPanel.requestPaymentForBooking" />
              ) : (
                <FormattedMessage id="TransactionPanel.requestPayment" />
              )}
            </PrimaryButton>
            <p className={css.priceExplanation}>
              {isOwnListing && !showPaymentMissing.booking ? (
                <FormattedMessage
                  id={'BookingTimeForm.priceExplanation'}
                  values={{
                    br: <br />,
                    terms: termsLink,
                    softwaresuppEmail: softwaresuppEmail,
                  }}
                />
              ) : !!showPaymentMissing.booking ? null : (
                <FormattedMessage
                  id={'BookingTimeForm.acceptProjectExplanation'}
                  values={{ br: <br /> }}
                />
              )}
            </p>
          </div>
        ) : null}

        {isOwnListing && !!hasExpertProfile && !!onSubmitRedirect && showAcceptOffer ? (
          <>
            {!currentCustomerIsAdmin && (
              <>
                {/* <div className={css.acceptOfferButton}>
                <PrimaryButton onClick={() => onSubmitRedirect()} type="submit">
                  {acceptOfferTooltip}
                </PrimaryButton>
              </div> */}
                <div className={css.adminOfferValues}>
                  <div className={css.adminOfferValuesTitleWrapper}>
                    <strong className={css.adminOfferValuesTitle}>
                      <RiShieldCheckLine />
                      {'\u00A0'}
                      <FormattedMessage
                        id={'BookingPanel.platformValuesTitle'}
                        values={{ br: <br /> }}
                      />
                    </strong>
                  </div>
                  <FormattedMessage
                    id={'BookingPanel.platformValues'}
                    values={{
                      br: <br />,
                      c: <GoCheck size={'1.2em'} />,
                    }}
                  />
                </div>
              </>
            )}

            {/* <div className={css.customersWrapper}>
              <SocialProofCard isCheckout type="caseStudy" />
            </div> */}
          </>
        ) : null}


        {currentUser &&
        !projectBoardAllowed &&
        !isAdmin &&
        !showPaymentMissing &&
        !isClosed &&
        isOpen ? (
          <JobApplicationPanel
            currentUser={currentUser}
            className={css.applicationForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            onSubmit={onSubmit}
            handleNewJobOffer={handleNewJobOffer}
            isOwnListing={isOwnListing}
            transactionRole={transactionRole}
            hasExpertProfile={hasExpertProfile}
            listingId={listing.id}
            listingAuthorId={listingAuthorId}
            isTransaction={isTransaction}
            currentUserHasStripeConnected={
              currentUserHasStripeConnected ? currentUserHasStripeConnected : currentCustomerStripe
            }
            expertListingIsPendingApproval={expertListingIsPendingApproval}
            messageFormatting={messageFormatting}
            expertStackMatching={expertStackMatching}
            timeZone={timeZone}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            onAskForDetails={onAskForDetails}
            listing={listing}
            history={history}
          />
        ) : null}

        {!isTransaction && !projectBoardAllowed ? (
          <SideActionBarMaybe
            isOwnListing={isOwnListing}
            listing={listing}
            editParams={editParams}
            publicData={publicData}
            currentUser={currentUser}
            isAdmin={isAdmin}
            isSolved={isSolved}
            isAwaiting={isAwaiting}
            onChangeState={onChangeState}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenClientPathModal={onOpenClientPathModal}
            allExperts={allExperts}
            softwareExperts={softwareExperts}
          />
        ) : null}

        {projectBoardAllowed ? (
          <>
            <PrimaryButton className={css.adminOfferButton} onClick={onOpenProjectBoard}>
              <AiOutlineFundProjectionScreen size={28} color={'white'} />
              {'\u00A0'}
              {'\u00A0'}
              <FormattedMessage id="ListingPage.goToProjectBoard" />
            </PrimaryButton>
          </>
        ) : null}

        {showContactExpert ? (
          <SecondaryButton
            className={
              isSolved || isAwaiting || isClosed
                ? css.contactAdminButtonClosed
                : css.contactAdminButton
            }
            onClick={onAskForDetails}
          >
            <MessageCircle className={css.messageIcon} />
            <FormattedMessage id="BookingTimeForm.contactExpert" />
          </SecondaryButton>
        ) : null}

        {showContactUser ? (
          <PrimaryButton
            className={
              isSolved || isAwaiting || isClosed
                ? css.contactAdminButtonClosed
                : css.contactAdminButton
            }
            onClick={onAskForDetails}
          >
            <MessageCircle className={css.messageIcon} />
            <FormattedMessage id="BookingTimeForm.contact" />
          </PrimaryButton>
        ) : null}

        {isAdmin && !isTransaction && !isAwaiting ? (
          <>
            <SecondaryButton className={css.adminOfferButton} onClick={onAdminOffer}>
              <RiAdminLine color={'black'} />
              {'\u00A0'}
              {'\u00A0'}
              <FormattedMessage id="BookingTimeForm.adminOffer" />
            </SecondaryButton>
          </>
        ) : null}

        {/* {isAdmin && !isTransaction && canCancelSummaryOffer ?
          <SecondaryButton className={css.adminOfferButton} onClick={() => {
            onCancelSummaryOffer();
            setAlreadyCanceled(true);
          }
          }>
            <RiAdminLine color={'black'} />{'\u00A0'}{'\u00A0'}
            <FormattedMessage id="BookingPanel.cancelSummaryOffer" />
          </SecondaryButton>
          : null} */}

        {/* {isAdmin && !isTransaction && !canCancelSummaryOffer && !summaryAlreadySent
          ? <SecondaryButton className={css.adminOfferButton} onClick={() => {
            onSendSummaryOffer();
            setSummaryAlreadySent(true);
          }}>
            <RiAdminLine color={'black'} />{'\u00A0'}{'\u00A0'}
            <FormattedMessage id="BookingPanel.sendSummaryOffer" />
          </SecondaryButton>
          : isAdmin && !isTransaction && !canCancelSummaryOffer
            ? <FormattedMessage id="BookingPanel.summaryAlreadySent" />
            : null} */}
      </ModalInMobile>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  isAdmin: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  currentCustomer: null,
  isTransaction: false,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  // listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  isAdmin: bool,
  unitType: propTypes.bookingUnitType,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func,
  monthlyTimeSlots: object,

  // from withRouter
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
