import { updatedEntities, denormalisedEntities } from '../util/data';
import { getAllSelectValues } from '../util/api';
import config from '../config';

// ================ Action types ================ //

export const ADD_MARKETPLACE_ENTITIES = 'app/marketplaceData/ADD_MARKETPLACE_ENTITIES';
export const GET_PRODUCT_SOFTWARE_SUCCESS = 'app/marketplaceData/GET_PRODUCT_SOFTWARE_SUCCESS';
export const GET_PRODUCT_SKILLS_SUCCESS = 'app/marketplaceData/GET_PRODUCT_SKILLS_SUCCESS';
export const GET_PRODUCT_INDUSTRIES_SUCCESS = 'app/marketplaceData/GET_PRODUCT_INDUSTRIES_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  // Database of all the fetched entities.
  entities: {},
  productSoftware: config.softwares,
  productSkills: config.skills,
  productIndustries: config.industries,
};

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    entities: updatedEntities({ ...state.entities }, apiResponse),
  };
};

export default function marketplaceDataReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_MARKETPLACE_ENTITIES:
      return merge(state, payload);
    case GET_PRODUCT_SOFTWARE_SUCCESS:
      return {
        ...state,
        productSoftware: payload,
      };
    case GET_PRODUCT_SKILLS_SUCCESS:
      return {
        ...state,
        productSkills: payload,
      };
    case GET_PRODUCT_INDUSTRIES_SUCCESS:
      return {
        ...state,
        productIndustries: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const addMarketplaceEntities = sdkResponse => ({
  type: ADD_MARKETPLACE_ENTITIES,
  payload: sdkResponse,
});

export const getProductSoftwareSuccess = response => ({
  type: GET_PRODUCT_SOFTWARE_SUCCESS,
  payload: response,
});

export const getProductSkillsSuccess = response => ({
  type: GET_PRODUCT_SKILLS_SUCCESS,
  payload: response,
});

export const getProductIndustriesSuccess = response => ({
  type: GET_PRODUCT_INDUSTRIES_SUCCESS,
  payload: response,
});

// ================ Selectors ================ //

/**
 * Get the denormalised listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsById = (state, listingIds) => {
  const { entities } = state.marketplaceData;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(entities, resources, throwIfNotFound);
};

/**
 * Get the denormalised entities from the given entity references.
 *
 * @param {Object} state the full Redux store
 *
 * @param {Array<{ id, type }>} entityRefs References to entities that
 * we want to query from the data. Currently we expect that all the
 * entities have the same type.
 *
 * @return {Array<Object>} denormalised entities
 */
export const getMarketplaceEntities = (state, entityRefs) => {
  const { entities } = state.marketplaceData;
  const throwIfNotFound = false;
  return denormalisedEntities(entities, entityRefs, throwIfNotFound);
};

export const fetchProductSoftware = () => (dispatch, getState, sdk) => {
  return getAllSelectValues({ type: 'software', isApproved: true }).then(response => {
    let productSoftware = response?.data.map(software => {
      return {
        key: software.key,
        label: software.label,
      };
    });
    const softwareKeys = productSoftware
      .map(software => {
        return software.key;
      })
      .sort();

    productSoftware = softwareKeys.map(software => {
      return {
        key: software,
        label: productSoftware.find(s => s.key === software).label,
      };
    });
    return dispatch(getProductSoftwareSuccess(productSoftware));
  });
};

export const fetchProductSkills = () => (dispatch, getState, sdk) => {
  return getAllSelectValues({ type: 'skill', isApproved: true }).then(response => {
    let produktSkills = response?.data.map(skill => {
      return {
        key: skill.key,
        label: skill.label,
      };
    });
    const skillsKeys = produktSkills
      .map(skill => {
        return skill.key;
      })
      .sort();

    produktSkills = skillsKeys.map(skill => {
      return {
        key: skill,
        label: produktSkills.find(s => s.key === skill).label,
      };
    });
    return dispatch(getProductSkillsSuccess(produktSkills));
  });
};

export const fetchProductIndustries = () => (dispatch, getState, sdk) => {
  console.log('fetchProductIndustries');
  return getAllSelectValues({ type: 'industry', isApproved: true }).then(response => {
    let productIndustries = response?.data.map(industry => {
      return {
        key: industry.key,
        label: industry.label,
      };
    });
    const industriesKeys = productIndustries
      .map(industry => {
        return industry.key;
      })
      .sort();

    productIndustries = industriesKeys.map(industry => {
      return {
        key: industry,
        label: productIndustries.find(s => s.key === industry).label,
      };
    });
    return dispatch(getProductIndustriesSuccess(productIndustries));
  });
};
