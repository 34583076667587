import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './TicketMessagesPanel.css';
import { FormattedMessage } from '../../util/reactIntl';
import { TicketMessage, InlineTextButton, IconSpinner } from '../../components';

const TicketMessagesPanel = props => {
  const {
    className,
    rootClassName,
    currentListing,
    isOwnListing,
    currentUserExpertListing,
    currentUser,
    isTicketSolved,
    isSolved,
    onChangeTicketAnswer,
    isProject,
    type,
    isUpdates,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  
  const [answers, setAnswers] = useState(props.answers);
  const [showAllAnswers, setShowAllAnswers] = useState(false);
  useEffect(() => {
    const loadAnswers = async () => {
      const loadedAnswers = await props.answers;
      setAnswers(loadedAnswers);
    }
    loadAnswers();
  }, [props.answers]);

  const answersToShow = showAllAnswers ? answers : answers.slice(-2);
  const title = type === 'answers' ?
    <FormattedMessage id="TicketMessagesPanel.titleAnswers" /> 
    : type === 'updates' ?
    <FormattedMessage id="TicketMessagesPanel.titleUpdates" /> 
    : <FormattedMessage id="TicketMessagesPanel.title" />;

  const hasAnswers = isUpdates ?
    currentListing.attributes.publicData.answers?.filter(el => !el.solution)?.length > 0
    : currentListing.attributes.publicData.answers?.find(el => el.solution);

  return (
    <div className={classes}>
      <h2 className={css.conversationTitle}>{title}</h2>
      {answers.length > 2 && !showAllAnswers ? (
        <InlineTextButton
          key="expand-answers"
          onClick={() => setShowAllAnswers(true)}
          className={css.showMoreButton}
        >
          <FormattedMessage
            id="TicketMessagesPanel.showRestOfAnswers"
            values={{ numberOfAnswers: answers.length - 2 }}
          />
        </InlineTextButton>
      ) : null}
      <div className={css.ticketMessages}>
        {!hasAnswers && answers?.length === 0 ? (
          <p className={css.noAnswers}>
            <FormattedMessage
              id={'TicketMessagesPanel.noAnswers'}
              values={{
                type: isProject ? 'project' : 'task',
                elements: isProject ? 'comments' : 'answers',
              }}
            />
          </p>
        ) : answers.length === 0 ? (
          <span>
            <FormattedMessage
              id="TicketMessagesPanel.loadingAnswers"
              values={{ type: isProject ? 'project' : 'task' }}
            />
            <IconSpinner />
          </span>
        ) : (
          answersToShow.map((a, index) => (
            <div
              key={index}
              id={answers.length > 1 && index === answers.length - 2 ? 'lastanswer' : ''}
            >
              <TicketMessage
                className={css.ticketMessage}
                answer={a}
                currentTicket={currentListing}
                isOwnListing={isOwnListing}
                currentUserExpertListing={currentUserExpertListing}
                onChangeTicketAnswer={onChangeTicketAnswer}
                isSolved={isSolved}
                index={showAllAnswers || answers.length <=2? index: answers.length - index}
                currentUser={currentUser}
              />
            </div>
          ))
        )}
        {isTicketSolved ? (
          <div className={css.ticketSolved}>
            <FormattedMessage id="TicketMessagesPanel.ticketSolved" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

TicketMessagesPanel.defaultProps = {
  children: null,
  className: null,
  rootClassName: null,
};

const { node, string } = PropTypes;

TicketMessagesPanel.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
};

export default TicketMessagesPanel;
