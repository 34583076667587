import React from 'react';
import css from './ProgressBar.css';

const ProgressBar = (props) => {
  const { currentValue, maxValue, label } = props;

  if (!maxValue) {
    return null;
  }

  const numbersRatio = currentValue / maxValue;

  // const barColor = numbersRatio >= 1 ? '#ff0000' : numbersRatio >= 0.6 && numbersRatio < 1 ? '#ffaa00' : 

  const fillerStyles = {
    height: '100%',
    width: `${numbersRatio > 1 ? 100 : currentValue/maxValue * 100}%`,
    backgroundColor: '#3c88f8',
    borderRadius: 'inherit',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  }

  return (
    <div className={css.progressBarContainer}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{label}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
