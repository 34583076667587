import React, { useState, useEffect, useRef } from 'react';
import { FieldTextInput } from '../../components';

const SideValidatedFieldTextInput = (props) => {

    const {id, name, type, className, label, placeholder, defaultValue, validate, disabled} = props;
    const triggerTrackValue = props.triggerTrackValue; // this is value that we observe, if changed we trigger re-validation
    const prevTriggerTrackValue = usePrevious(triggerTrackValue);
    const triggerFieldValue = props.triggerFieldValue; // this is current value of field, used for ghost update inside FieldTextInput

    const [validationTrigger, setValidationTrigger] = useState(0);

    useEffect(() => {
        if(prevTriggerTrackValue !== triggerTrackValue) {
            setValidationTrigger(validationTrigger => validationTrigger+1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerTrackValue]);

    return (
        <FieldTextInput
            id={id}
            name={name}
            type={type}
            className={className}
            label={label}
            placeholder={placeholder}
            validate={validate}
            validationTrigger={validationTrigger}
            validationTriggerFieldValue={triggerFieldValue}
            disabled={disabled}
            defaultValue={defaultValue}
        />
    );

}

// Custom Hook
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
}

export default SideValidatedFieldTextInput;