import React, { useState, useRef, useEffect } from 'react';
import css from './SocialProofModal.css';
import { RiCloseCircleLine, RiStarSFill, RiStarHalfSFill } from 'react-icons/ri';
import { GoArrowRight, GoArrowLeft } from 'react-icons/go';
import { TESTIMONIALS } from '../../translations/SocialProofTestimonials';

// Additional component
const StarsList = props => {
    const stars = [];
    const fullStars = props.starsNumber;
    const halfStar = ((props.starsNumber - fullStars) > 0) ? 1 : 0;
    const emptyStars = 5 - halfStar - fullStars;
    if(props.starsNumber >= 1 && props.starsNumber <= 5) {
        for(let i = 0; i < fullStars; i++) {
            stars.push(
                <RiStarSFill size={'1.2em'} color={'goldenrod'} />
            );
        }
        if(!!halfStar) {
            stars.push(
                <RiStarHalfSFill color={'goldenrod'} />
            );
        }
        for(let i = 0; i < emptyStars; i++) {
            stars.push(
                <RiStarSFill color={'goldenrod'} />
            );
        }
    }
    return (
        <div className={css.starsWrapper}>
            {stars}
        </div>
    );
}

const SocialProofModal = props => {

  const [currentProofPage, setCurrentProofPage] = useState(0);
  const proofPageRef = useRef(currentProofPage);
  proofPageRef.current = currentProofPage;
  const testimonials = TESTIMONIALS;

  useEffect(() => {
    const timer = setInterval(() => {
        const maxPageNumber = (testimonials.length-1);
        const nextPage = proofPageRef.current+1 <= maxPageNumber ? (proofPageRef.current+1) : 0;
        setCurrentProofPage(nextPage);
      }, 15000);
    return () => clearInterval(timer);  
  },[testimonials.length]);

  const authorText = testimonials[currentProofPage].author + (testimonials[currentProofPage].company ? ' ('+testimonials[currentProofPage].company+') ' : '');
  const starsNumber = testimonials[currentProofPage].starsNumber;
  const quote = (testimonials[currentProofPage].quote ? '"'+testimonials[currentProofPage].quote+'"' : '');
  const date = testimonials[currentProofPage].date;
  const imgLocation = (testimonials[currentProofPage].imgLocation.length > 0) ? ('./experts-images/' + testimonials[currentProofPage].imgLocation) : './experts-images/testimonial-no-profile-image.png';
  const storyLink = (testimonials[currentProofPage].storyLink.length > 0) ? testimonials[currentProofPage].storyLink : null;
  
  const arrowRightButtonHandler = () => {
    const maxPageNumber = (testimonials.length-1);
    const nextPage = currentProofPage+1 <= maxPageNumber ? (currentProofPage+1) : 0;
    setCurrentProofPage(nextPage);
  }

  const arrowLeftButtonHandler = () => {
    const maxPageNumber = (testimonials.length-1);
    const nextPage = currentProofPage-1 >= 0 ? (currentProofPage-1) : maxPageNumber;
    setCurrentProofPage(nextPage);
}

  return (
    <div className={css.mainWrapper}>
        <div className={css.mainContent}>
            <div className={css.profileImageWrapper}>
                <div className={css.profileImageShadow} />
                <div className={css.profileImage}>
                    <img className={css.img} 
                    src={require(""+imgLocation)}
                    alt="Profile"></img>
                </div>
                { storyLink ?
                    <div className={css.buttonWrapper}>
                        <a href={storyLink} className={css.link}>
                            <div className={css.readStoryButton}>
                                <span>Read Story</span>
                            </div>
                        </a>
                    </div>
                    :
                    null
                }
            </div>
            <div className={css.profileDescriptionWrapper}>
                <div className={css.profileDescriptionTitle}>{authorText}</div>
                <StarsList starsNumber={starsNumber} />
                <div className={css.profileDescriptionQuote}>{quote}</div>
                <div className={css.profileDescriptionDate}>{date}</div>
            </div>
        </div>
        <div className={css.closeButton} onClick={() => { props.closeButtonHandler() }}>
            <RiCloseCircleLine size={"1.5em"} />
        </div>
        <div className={css.arrowRightButton} onClick={() => { arrowRightButtonHandler() }}>
            <GoArrowRight size={"1.5em"} />
        </div>
        <div className={css.arrowLeftButton} onClick={() => { arrowLeftButtonHandler() }}>
            <GoArrowLeft size={"1.5em"} />
        </div>
    </div>
  );
};

// const { string } = PropTypes;
SocialProofModal.defaultProps = {
//   className: null,
//   format: 'desktop',
};
SocialProofModal.propTypes = {
//   className: string,
//   format: oneOf(['desktop', 'mobile']),
};

export default SocialProofModal;
