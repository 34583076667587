const isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

const isYesterday = (someDate) => {
  let yesterday = new Date(Date.now() - ( 1000 * 60 * 60 * 24));
  return someDate.getDate() === yesterday.getDate() &&
    someDate.getMonth() === yesterday.getMonth() &&
    someDate.getFullYear() === yesterday.getFullYear()
}

const formatTime = (date) => {
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? '0'+minutes : minutes;
  return date.getHours() + ':' + minutes;
}

export const dateToReadableString = (dateISOString) => {
  let date = new Date(dateISOString);
  let dateMonth = date.toLocaleString('default', { month: 'short' });
  dateMonth = dateMonth.charAt(0).toUpperCase() + dateMonth.slice(1);
  let datePart = isToday(date) ? 'Today' : isYesterday(date) ? 'Yesterday' : (dateMonth + ' ' + date.getDate());
  let dateTime = formatTime(date);
  return datePart + ', ' + dateTime; 
}