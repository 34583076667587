/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks, NamedLink } from '../../components';
import css from './SearchResultsPanel.css';
import { FormattedMessage } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    currentUserExpertListing,
    currentUser,
    currentUserStripeAccounts,
    urlQueryParams,
    productSoftware,
    productSkills,
    productIndustries,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const searchedSoftware = props.searchedSoftware;

  const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const pathNameArray = search.pathname.split('/');
  //const softwareSearchParams = parse(search.search)["pub_softwares"];
  let category = pathNameArray[1] ? pathNameArray[1] : null;
  let software = pathNameArray[2] ? pathNameArray[2] : null;

  let softwareSearch = software || urlQueryParams?.pub_softwares || urlQueryParams?.pub_skills || urlQueryParams?.pub_industries;

  let softwareKey = productSoftware.filter(k => k.key == softwareSearch);
  let skillKey = productSkills.filter(k => k.key == softwareSearch);
  let industryKey = productIndustries.filter(k => k.key == softwareSearch);
  if (softwareKey.length > 0) {
    softwareSearch = softwareKey[0];
  } else if (skillKey.length > 0) {
    softwareSearch = skillKey[0];
  } else {
    softwareSearch = industryKey[0];
  }
  let authorId = pathNameArray[3] ? pathNameArray[3] : null;
  let isIFrame = false;
  let isIFrameService = false;

  let postAJobLink = (
    <NamedLink name="NewListingPage">
      <FormattedMessage id="SearchResultsPanel.postAJobLink" />
    </NamedLink>
  );

  let allSoftwareSearchLink = (
    <NamedLink name={`SearchPage_${category}`} to={{ search: `pub_softwares=${software}` }}>
      <FormattedMessage id={`SearchResultsPanel.allSoftwareSearchLink${category}`} />
    </NamedLink>
  );

  const postExpertLink = (
    <NamedLink name="NewListingExpertPage">
      <FormattedMessage id="SearchResultsPanel.postExpertLink" />
    </NamedLink>
  );

  const allSoftwareSearch = (
    <div style={{ marginBottom: '15px' }}>
      <FormattedMessage
        id={`SearchResultsPanel.allSoftwareSearch${category}`}
        values={{
          categoryLink: allSoftwareSearchLink,
          postAJobLink: postAJobLink,
          postExpertLink: postExpertLink,
        }}
      />
    </div>
  );

  // IFRAME SUPPORT
  if (search.pathname.includes('frame')) {
    if (search.pathname.includes('frame/services')) {
      isIFrameService = true;
    }
    isIFrame = true;
    category = pathNameArray[2] ? pathNameArray[2] : null;
    software = pathNameArray[3] ? pathNameArray[3] : null; // : softwareSearchParams
    postAJobLink = (
      <a target="_blank" href="/l/newJob">
        <FormattedMessage id="SearchResultsPanel.postAJobLink" />
      </a>
    );
    const softwaresSearchLinkLiteral = `/experts/?pub_softwares=${software}`;
    allSoftwareSearchLink = (
      <a target="_blank" href={softwaresSearchLinkLiteral} rel="noopener noreferrer">
        <FormattedMessage id={`SearchResultsPanel.allSoftwareSearchLink${category}`} />
      </a>
    );
  }

  const pageName =
    software && category && category === 'experts'
      ? 'SearchPage_experts_software'
      : software && category && category === 'jobs'
      ? 'SearchPage_jobs_software'
      : software && category && category === 'tickets'
      ? 'SearchPage_tickets_software'
      : software && category && category === 'services'
      ? 'SearchPage_services_software'
      : category && category === 'experts'
      ? 'SearchPage_experts'
      : category && category === 'jobs'
      ? 'SearchPage_jobs'
      : category && category === 'tickets'
      ? 'SearchPage_tickets'
      : category && category === 'services'
      ? 'SearchPage_services'
      : 'SearchPage';

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={pageName}
        pagePathParams={isIFrameService ? { authorid: authorId } : { software: software }}
        pageSearchParams={parse(search.search)}
        pagination={pagination}
        isIFrame={isIFrame}
        isIFrameService={isIFrameService}
      />
    ) : null;

  const shouldDisplayBookmark =
    pageName.includes('jobs') ||
    pageName.includes('experts') ||
    pageName.includes('tickets') ||
    pageName.includes('services')
      ? false
      : true;
  const listingCardClass = classNames(
    css.listingCard,
    !shouldDisplayBookmark ? css.listingCardNoTopMargin : null
  );

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth}vw`,
    `${panelLargeWidth}vw`,
  ].join(', ');
  const listingsData = listings.filter(
    l =>
      l.id.uuid !== process.env.REACT_APP_BASIC_SUPPORT_PLAN_ID &&
      l.id.uuid !== process.env.REACT_APP_SUPPORT_SUPPORT_PLAN_ID &&
      l.id.uuid !== process.env.REACT_APP_STARTER_SUPPORT_PLAN_ID
  );
  return (
    <div className={classes}>
      <div className={classNames(css.listingCards, isIFrame ? css.listingCardsIFrame : null)}>
        {listingsData.length > 0 ? (
          pageName.includes('experts') ? (
            softwareSearch ? (
              <h1>{softwareSearch.label} Freelancers</h1>
            ) : (
              <h1>SoftwareSupp Freelancers</h1>
            )
          ) : null
        ) : null}

        {listingsData.length > 0 ? (
          pageName.includes('services') ? (
            softwareSearch ? (
              <h1>{capitalize(softwareSearch)} Services</h1>
            ) : (
              <h1>SoftwareSupp Services</h1>
            )
          ) : null
        ) : null}

        {listingsData.map(l => {
          return (
            <ListingCard
              className={listingCardClass}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              currentUserExpertListing={currentUserExpertListing}
              currentUser={currentUser}
              currentUserStripeAccounts={currentUserStripeAccounts}
              shouldDisplayBookmark={shouldDisplayBookmark}
              isCardIFrameLink={isIFrame} // iFrames opens in new browser cards
              urlQueryParams={urlQueryParams}
              searchedSoftware={searchedSoftware}
            />
          );
        })}
        {props.children}
        {pagination &&
        pagination.page === pagination.totalPages &&
        software &&
        category &&
        pagination.totalItems > 0
          ? null //allSoftwareSearch
          : null}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
