import React, { Component } from 'react';
import { array, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import { propTypes, LISTING_CATEGORY_SERVICE } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  createSlug
} from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  sendNewSoftOrSkillRequest,
  sendListingCreatedMail
} from '../../util/triggerMail';
import { addMultipleSelectValues } from '../../util/api';

import css from './EditServiceWizard.css';
import { EditServiceForm } from '../../forms';
import { ListingLink } from '../../components';

const { Money } = sdkTypes;

// Create a new or edit listing through EditListingWizard
class EditServiceWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      showDraftModal: false,
      portalRoot: null,
      initialValues: {},
      initialValuesLoaded: {},
      formMinimumPriceNotReached: false
    };
    this.handleProceedWithListing = this.handleProceedWithListing.bind(this);
    this.parseServiceScope = this.parseServiceScope.bind(this);
    this.parseCommission = this.parseCommission.bind(this);
    this.parseTotalPrice = this.parseTotalPrice.bind(this);

  }

  parseCommission(commission) {
    let parsedCommission = commission;
    if (typeof commission === 'string') {
      let fraction = commission.split("/");
      let numerator = fraction[0];
      let denominator = fraction[1];
      parsedCommission = numerator / denominator;
    }
    return parsedCommission;
  }

  parseTotalPrice(values) {
    let scopeEntries = Object.entries(values).filter((entryArray) => {
      return entryArray[0].includes('scope');
    });
    let scopePrices = scopeEntries.filter((entry) => {
      return entry[0].includes('scopeTaskPriceInput') && entry[1] !== null;
    });
    let scopeTotal = scopePrices.reduce((acc, price) => {
      return acc + price[1].amount;
    }, 0);
    return scopeTotal;
  }

  parseServiceScope = (values, commission) => {
    commission = this.parseCommission(commission);
    let scopeEntries = Object.entries(values).filter((entryArray) => {
      return entryArray[0].includes('scope');
    });
    let scopeTaskIds = scopeEntries.filter((entry) => {
      return entry[0].includes('scopeTaskNameInput') && entry[1] !== '';
    }).map((entry) => {
      return parseInt(entry[0].replace("scopeTaskNameInput", ""));
    }).sort((a, b) => {
      return a - b; // integers sort
    });
    let serviceScope = [];
    let totalPrice = 0;
    let howManyTasks = scopeTaskIds.length;
    for (let i = 0; i < howManyTasks; i++) {
      let scopeTaskName = scopeEntries.find((entry) => {
        return entry[0].includes('scopeTaskNameInput' + scopeTaskIds[i])
      })[1];
      let scopeTaskTime = scopeEntries.find((entry) => {
        return entry[0].includes('scopeTaskTimeInput' + scopeTaskIds[i]);
      });
      scopeTaskTime = !!scopeTaskTime ? parseInt(scopeTaskTime[1]) : null;
      let scopeTaskPrice = scopeEntries.find((entry) => {
        return entry[0].includes('scopeTaskPriceInput' + scopeTaskIds[i]);
      });
      let scopeTaskOriginalPrice = !!scopeTaskPrice && !!scopeTaskPrice[1]?.amount ? scopeTaskPrice[1].amount / 100 : null;
      scopeTaskPrice = !!scopeTaskPrice ? Math.ceil((scopeTaskPrice[1]?.amount / (1 - commission)) / 100) : null;
      if (scopeTaskPrice) {
        totalPrice += scopeTaskPrice * 100;
      }
      let scopeSubtaskIds = scopeEntries.filter((entry) => {
        return entry[0].includes('scopeTask' + scopeTaskIds[i] + 'SubtaskNameInput') && entry[1] !== '';
      }).map((entry) => {
        return parseInt(entry[0].replace('scopeTask' + scopeTaskIds[i] + 'SubtaskNameInput', ""));
      }).sort((a, b) => {
        return a - b; // integers sort
      });
      let howManySubtasks = scopeSubtaskIds.length;
      let scopeSubtasks = [];
      for (let j = 0; j < howManySubtasks; j++) {
        let scopeSubtaskName = scopeEntries.find((entry) => {
          return entry[0].includes('scopeTask' + scopeTaskIds[i] + 'SubtaskNameInput' + scopeSubtaskIds[j]);
        })[1];
        let scopeSubtaskTime = scopeEntries.find((entry) => {
          return entry[0].includes('scopeTask' + scopeTaskIds[i] + 'SubtaskTimeInput' + scopeSubtaskIds[j]);
        });
        scopeSubtaskTime = !!scopeSubtaskTime && scopeSubtaskTime.length > 1 ? parseInt(scopeSubtaskTime[1]) : null;
        let scopeSubtaskPrice = scopeEntries.find((entry) => {
          return entry[0].includes('scopeTask' + scopeTaskIds[i] + 'SubtaskPriceInput' + scopeSubtaskIds[j]);
        });
        let scopeSubtaskOriginalPrice = !!scopeSubtaskPrice && !!scopeSubtaskPrice[1]?.amount ? scopeSubtaskPrice[1]?.amount / 100 : null;
        scopeSubtaskPrice = !!scopeSubtaskPrice && scopeSubtaskPrice.length > 1 ? Math.ceil((scopeSubtaskPrice[1]?.amount / (1 - commission)) / 100) : null;
        let scopeSubtaskItem = {};
        if (!!scopeSubtaskName) {
          scopeSubtaskItem['name'] = scopeSubtaskName;
        }
        if (!!scopeSubtaskTime) {
          scopeSubtaskItem['time'] = scopeSubtaskTime;
        }
        if (!!scopeSubtaskPrice) {
          scopeSubtaskItem['price'] = scopeSubtaskPrice;
        }
        if (!!scopeSubtaskOriginalPrice) {
          scopeSubtaskItem['originalPrice'] = scopeSubtaskOriginalPrice;
        }
        scopeSubtasks.push(scopeSubtaskItem);
      }
      let scopeTask = {
        name: scopeTaskName,
        time: scopeTaskTime,
        price: scopeTaskPrice,
        originalPrice: scopeTaskOriginalPrice,
        subtasks: scopeSubtasks
      };
      serviceScope.push(scopeTask);
    }
    let servicePrice = new Money(totalPrice, config.currency);
    return { serviceScope, servicePrice };
  }

  handleProceedWithListing(values, saveAsDraft) {
    const { listing, type, currentUser, productSoftware } = this.props;

    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;

    const {
      title,
      description,
      referral,
      primarySoftware,
      shortDescription,
      price,
      estimatedTime
    } = values;

    const requestedPrimarySoftware =
      !primarySoftware || productSoftware.find(soft => soft.key === primarySoftware.value) ?
        null : primarySoftware.value;

    const requestedOtherSoftware = values.software ? values.software.filter(soft => {
      return soft?.__isNew__
    }
    ).map(soft => {
      return soft.value
    }) : [];

    const primarySoftwareKey = requestedPrimarySoftware || !primarySoftware ? 'other' : primarySoftware.value;

    let softwareArray = values.software ? values.software.filter(isUndefined => {
      if (!isUndefined) return false;
      return true;
    })
      .map(soft => {
        if (soft?.__isNew__) {
          return createSlug(soft.value)
        }
        return soft.value
      })
      : [primarySoftwareKey];
    if (softwareArray && !softwareArray.find(soft => soft === primarySoftwareKey)) {
      softwareArray.unshift(primarySoftwareKey);
    } else if (softwareArray && requestedPrimarySoftware && !softwareArray.find(soft => soft === 'other')) {
      softwareArray.unshift('other');
    }
    const serviceCategories = values.serviceCategories ? values.serviceCategories.map(soft => { return soft?.value }) : null;

    if (requestedPrimarySoftware || requestedOtherSoftware?.length > 0) {
      const requestSoftwareOrSkillBody = {
        userMail: currentUser.attributes.email,
        listingTitle: title,
        listingCategory: LISTING_CATEGORY_SERVICE
      }
      sendNewSoftOrSkillRequest(requestSoftwareOrSkillBody, [requestedPrimarySoftware, ...requestedOtherSoftware], true);
      const addMultipleSelectValuesBody = [requestedPrimarySoftware, ...requestedOtherSoftware].map(software => {
        return {
          type: 'software',
          label: software,
          key: createSlug(software),
          authorId: currentUser?.id.uuid,
          authorMail: currentUser?.attributes.email
        }
      })
      addMultipleSelectValues({selectValues: addMultipleSelectValuesBody})
    }

    const serviceCommission = "1/2";
    const { serviceScope } = this.parseServiceScope(values, serviceCommission);

    const listingValues = {
      title: title.trim(),
      description,
      price: new Money(price.amount * 2, config.currency),
      publicData: {
        category: LISTING_CATEGORY_SERVICE,
        state: isNewURI ? 'open' : listing.attributes.publicData.state,
        softwares: softwareArray,
        primarySoftware: primarySoftwareKey,
        primarySoftwareSearch: primarySoftwareKey,
        scope: serviceScope,
        private: false,
        serviceCategories,
        shortDescription,
        estimatedTime
      },
      privateData: {
        referral
      }
    };

    return listingValues
  }

  render() {
    const {
      params,
      listing,
      errors,
      currentUser,
      currentUserHasExpertListing,
      referralNumber,
      history,
      onCreateListingDraft,
      onUpdateListing,
      handlePublishListing,
      type,
      onRemoveImage,
      onImageUpload,
      images,
      isAdmin,
      updateInProgress,
      productSoftware
    } = this.props;

    const currentListing = ensureOwnListing(listing);
    const { description, title, price, publicData, privateData } = currentListing.attributes;
    const ensureReferralNumber = referralNumber ? referralNumber : privateData?.referral;

    const isListingStatusNew = currentListing.attributes.state ? false : true;

    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;

    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );
    const currentUserExists = !!currentUser;

    const { scope, softwares, primarySoftware, serviceCategories, shortDescription, estimatedTime } = publicData;

    const initialSoftwares = softwares ? softwares.map(soft => {
      const foundSoft = productSoftware.find(foundSoft => foundSoft.key === soft);
      if (foundSoft)
        return {
          value: foundSoft.key,
          label: foundSoft.label
        }
      else
        return {
          value: soft,
          label: soft
        }
    }) : null;

    const initialServiceCategories = serviceCategories ? serviceCategories.map(cat => {
      const foundCat = config.custom.serviceCategoriesConfig.options.find(foundCat => foundCat.key === cat);
      if (foundCat)
        return {
          value: foundCat.key,
          label: foundCat.label
        }
      return null
    }) : null;

    const foundPrimarySoft = productSoftware.find(foundSoft => foundSoft.key === primarySoftware);

      const initialValues = {
        title,
        description,
        price: price ? new Money(price.amount * 1/2, config.currency) : null,
        referral: ensureReferralNumber,
        primarySoftware: foundPrimarySoft ? { value: foundPrimarySoft.key, label: foundPrimarySoft.label } : null,
        software: initialSoftwares,
        serviceCategories: initialServiceCategories,
        scope,
        shortDescription,
        estimatedTime
      }
      if (scope) {
        for (let i = 0; i < publicData?.scope?.length; i++) {
          initialValues[`scopeTaskNameInput${i}`] = publicData.scope[i].name;
          initialValues[`scopeTaskTimeInput${i}`] = !!publicData.scope[i].time ? publicData.scope[i].time : '';
          initialValues[`scopeTaskPriceInput${i}`] = !!publicData.scope[i].price ? new Money(publicData.scope[i].price * 100 / 2, config.currency) : '';
          for (let j = 0; j < publicData?.scope[i]?.subtasks?.length; j++) {
            initialValues[`scopeTask${i}SubtaskNameInput${j}`] = publicData.scope[i].subtasks[j].name;
            initialValues[`scopeTask${i}SubtaskTimeInput${j}`] = !!publicData.scope[i].subtasks[j].time ? publicData.scope[i].subtasks[j].time : '';
            initialValues[`scopeTask${i}SubtaskPriceInput${j}`] = !!publicData.scope[i].subtasks[j].price ? new Money(publicData.scope[i].subtasks[j].price * 200 / 3, config.currency) : '';
          }
        }
      }

    const panelTitle = currentListing.id ? (
      <FormattedMessage
        id="EditServiceWizard.title"
        values={{
          listingTitle: (
            <ListingLink listing={currentListing}>
              <FormattedMessage id="EditServiceWizard.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
        <FormattedMessage id="EditServiceWizard.createListingTitle" />
      );
    const imageIds = images => {
       return images ? images.map(img => img.imageId || img.id) : null;
     };
    const onSubmit = (updateValues, saveAsDraft) => {
      // Normalize images for API call
      const { images: updatedImages, ...otherValues } = updateValues;

      const imageProperty = typeof images !== 'undefined' ? { images: imageIds(images) } : {};
      const updateValuesWithImages = { ...otherValues, ...imageProperty };
      if (isNewListingFlow) {
        const onUpsertListingDraft = isNewURI
          ? onCreateListingDraft
          : onUpdateListing;

        const upsertValues = isNewURI
          ? updateValuesWithImages
          : {
              ...updateValuesWithImages,
              id: currentListing.id,
            };

        onUpsertListingDraft(upsertValues)
          .then(response => {
            const listing = response.data.data;
            const listingParams = {
              id: listing.id.uuid,
              slug: createSlug(listing.attributes.title),
              category: 'services'
            }
            if (saveAsDraft) {
              listingParams.variant = LISTING_PAGE_DRAFT_VARIANT;
              history.push(createResourceLocatorString('ListingPageVariant', routeConfiguration(), listingParams, {}))
            }
            else {
              handlePublishListing(response.data.data.id).then(data => {
                listingParams.variant = LISTING_PAGE_PENDING_APPROVAL_VARIANT;
                sendListingCreatedMail(listing, LISTING_CATEGORY_SERVICE, currentUser);
                history.push(createResourceLocatorString('ListingPageVariant', routeConfiguration(), listingParams, {}))
              })
            }
          })
          .catch(e => {
            // No need for extra actions
          });
      } else {
        onUpdateListing({ ...updateValuesWithImages, id: currentListing.id }).then(data => {
          const listingParams = {
            id: currentListing.id.uuid,
            slug: createSlug(currentListing.attributes.title),
            variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
            category: 'services',
          };
          history.push(
            createResourceLocatorString(
              'ListingPageVariant',
              routeConfiguration(),
              listingParams,
              {}
            )
          );
        });
      }
    };

    const saveActionMessage = <FormattedMessage id={currentListing.id && !isNewListingFlow
      ? "EditServiceWizard.updateAndGoTo"
      : "EditServiceWizard.publishAndGoTo"} />

    const createDraftMessage = <FormattedMessage id="EditServiceWizard.createDraftAndGoTo" />;

    return (
      <EditServiceForm
        className={css.root}
        initialValues={initialValues}
        productSoftware={productSoftware}
        name="Project"
        saveActionMsg={saveActionMessage}
        createDraftMessage={createDraftMessage}
        onSubmit={values => {
          const submitValues = this.handleProceedWithListing(values)
          if (submitValues) {
            onSubmit(submitValues)
          }
        }}
        fetchErrors={errors}
        referral={ensureReferralNumber ? config.custom.referral : null}
        budget={config.custom.budget}
        category={config.custom.category}
        userId={config.custom.userId}
        currentUserExists={currentUserExists}
        currentUserHasExpertListing={currentUserHasExpertListing}
        isAdmin={isAdmin}
        isListingStatusNew={isListingStatusNew}
        messageFormatting=''
        onSaveAsDraft={values => onSubmit(this.handleProceedWithListing(values), true)}
        onImageUpload={onImageUpload}
        onRemoveImage={onRemoveImage}
        images={images}
        isNew={isNewURI}
        formMinimumPriceNotReached={this.state.formMinimumPriceNotReached}
        panelTitle={panelTitle}
        updateInProgress={updateInProgress}
      />
    );
  }
}

EditServiceWizard.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  listing: null,
  updateInProgress: false,
};

EditServiceWizard.propTypes = {
  id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired
  }).isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,

  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    productSoftware
  } = state.marketplaceData;
  return {
    productSoftware
  }
}

export default compose(
  withViewport,
  injectIntl,
  connect(mapStateToProps)
)(EditServiceWizard);
