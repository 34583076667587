import React from 'react';
import css from './ExpertPage.css';
import css2 from '../../components/ExpertOverview/ExpertPage.css';
import classNames from 'classnames';


const AttachmentPanel = props => {
    const {
        uploadedFileLink
    } = props

    return (
        <>
        {uploadedFileLink ? (
            <>
            {uploadedFileLink.length > 0 ? (
                <div>
                <h2 className={css2.smallPrintHeading}>Attachment</h2>
                
                { uploadedFileLink.map((item, index) => {
                    return(
                        <span key={index} className={classNames(css2.chip, css.fileAttachemtn)}>
                        <a href={item.url} >File {index + 1}</a>    
                    </span>)  
                    })
                }

                </div>
            ) : null}
            </>
        ) 
        : null }
        </>
        )
    }
export default AttachmentPanel;

