import { types as sdkTypes } from '../util/sdkLoader';
import {
  createStripeCustomer,
  generateInvoice,
  updateStripeCustomer,
  getInvoice,
  getUserInvoices, getUserPayouts,
} from '../util/api';
import config from '../config';

const { UUID, Money } = sdkTypes;

// ================ Action types ================ //

export const CREATE_STRIPE_CUSTOMER_REQUEST = 'app/stripe/CREATE_STRIPE_CUSTOMER_REQUEST';
export const CREATE_STRIPE_CUSTOMER_SUCCESS = 'app/stripe/CREATE_STRIPE_CUSTOMER_SUCCESS';
export const CREATE_STRIPE_CUSTOMER_ERROR = 'app/stripe/CREATE_STRIPE_CUSTOMER_ERROR';

export const CREATE_STRIPE_PAYMENT_REQUEST = 'app/stripe/CREATE_STRIPE_PAYMENT_REQUEST';
export const CREATE_STRIPE_PAYMENT_SUCCESS = 'app/stripe/CREATE_STRIPE_PAYMENT_SUCCESS';
export const CREATE_STRIPE_PAYMENT_ERROR = 'app/stripe/CREATE_STRIPE_PAYMENT_ERROR';

export const FETCHING_INVOICE_REQUEST = 'app/stripe/FETCHING_INVOICE_REQUEST';
export const FETCHING_INVOICE_SUCCESS = 'app/stripe/FETCHING_INVOICE_SUCCESS';
export const FETCHING_INVOICE_ERROR = 'app/stripe/FETCHING_INVOICE_ERROR';

export const FETCHING_USER_PAYOUTS_REQUEST = 'app/stripe/FETCHING_USER_PAYOUTS_REQUEST';
export const FETCHING_USER_PAYOUTS_SUCCESS = 'app/stripe/FETCHING_USER_PAYOUTS_SUCCESS';
export const FETCHING_USER_PAYOUTS_ERROR = 'app/stripe/FETCHING_USER_PAYOUTS_ERROR';

export const FETCHING_USER_INVOICES_REQUEST = 'app/stripe/FETCHING_USER_INVOICES_REQUEST';
export const FETCHING_USER_INVOICES_SUCCESS = 'app/stripe/FETCHING_USER_INVOICES_SUCCESS';
export const FETCHING_USER_INVOICES_ERROR = 'app/stripe/FETCHING_USER_INVOICES_ERROR';

// ================ Reducer ================ //

const initialState = {
  createStripeCustomerInProgress: false,
  createStripeCustomerError: null,
  fetchingInvoiceInProgress: false,
  listingInvoiceUrl: null,
  fetchingInvoiceError: null,
  fetchingUserInvoicesError: null,
  createStripePaymentError: null,
  createStripePaymentInProgress: false,
  userInvoices: null,
  userPayouts: null,
  fetchingUserPayoutsInProgress: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_STRIPE_CUSTOMER_REQUEST:
      return { ...state, createStripeCustomerInProgress: true, createStripeCustomerError: null };
    case CREATE_STRIPE_CUSTOMER_SUCCESS:
      return { ...state, createStripeCustomerInProgress: false };
    case CREATE_STRIPE_CUSTOMER_ERROR:
      return {
        ...state,
        createStripeCustomerInProgress: false,
        createStripeCustomerError: payload,
      };

    case CREATE_STRIPE_PAYMENT_REQUEST:
      return { ...state, createStripePaymentInProgress: true, createStripePaymentError: null };
    case CREATE_STRIPE_PAYMENT_SUCCESS:
      return { ...state, createStripePaymentInProgress: false };
    case CREATE_STRIPE_PAYMENT_ERROR:
      return { ...state, createStripePaymentInProgress: false, createStripePaymentError: payload };

    case FETCHING_INVOICE_REQUEST:
      return { ...state, fetchingInvoiceInProgress: true };
    case FETCHING_INVOICE_SUCCESS:
      return { ...state, listingInvoiceUrl: payload, fetchingInvoiceInProgress: false };
    case FETCHING_INVOICE_ERROR:
      return { ...state, fetchingInvoiceInProgress: false, fetchingInvoiceError: payload };

    case FETCHING_USER_INVOICES_REQUEST:
      return { ...state, fetchingUserInvoicesInProgress: true };
    case FETCHING_USER_INVOICES_SUCCESS:
      return { ...state, userInvoices: payload, fetchingUserInvoicesInProgress: false };
    case FETCHING_USER_INVOICES_ERROR:
      return {
        ...state,
        fetchingUserInvoicesInProgress: false,
        fetchingUserInvoicesError: payload,
      };
      
    case FETCHING_USER_PAYOUTS_REQUEST:
      return { ...state, fetchingUserPayouts: true };
    case FETCHING_USER_PAYOUTS_SUCCESS:
      return { ...state, userPayouts: payload, fetchingUserPayoutsInProgress: false };
    case FETCHING_USER_PAYOUTS_ERROR:
      return {
        ...state,
        fetchingUserPayoutsInProgress: false,
        fetchingUserPayoutsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const createStripeCustomerRequest = () => ({ type: CREATE_STRIPE_CUSTOMER_REQUEST });
const createStripeCustomerSuccess = () => ({ type: CREATE_STRIPE_CUSTOMER_SUCCESS });
const createStripeCustomerError = e => ({
  type: CREATE_STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const paymentRequest = () => ({ type: CREATE_STRIPE_PAYMENT_REQUEST });
export const paymentSuccess = () => ({ type: CREATE_STRIPE_PAYMENT_SUCCESS });
const paymentError = e => ({ type: CREATE_STRIPE_PAYMENT_ERROR, error: true, payload: e });

const fetchingInvoiceRequest = () => ({ type: FETCHING_INVOICE_REQUEST });
const fetchingInvoiceSuccess = payload => ({ type: FETCHING_INVOICE_SUCCESS, payload: payload });
const fetchingInvoiceError = e => ({ type: FETCHING_INVOICE_ERROR, error: true, payload: e });

const fetchingUserPayoutsRequest = () => ({ type: FETCHING_USER_PAYOUTS_REQUEST });
const fetchingUserPayoutsSuccess = payload => ({ type: FETCHING_USER_PAYOUTS_SUCCESS, payload: payload });
const fetchingUserPayoutsError = e => ({ type: FETCHING_USER_PAYOUTS_ERROR, error: true, payload: e });

const fetchingUserInvoicesRequest = () => ({ type: FETCHING_USER_INVOICES_REQUEST });
const fetchingUserInvoicesSuccess = payload => ({
  type: FETCHING_USER_INVOICES_SUCCESS,
  payload: payload,
});
const fetchingUserInvoicesError = e => ({
  type: FETCHING_USER_INVOICES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const pay = body => (dispatch, getState, sdk) => {
  dispatch(paymentRequest());

  const { data } = body;
  console.log(body)
  return generateInvoice(data)
    .then(response => {
      const updateBody =
        data.isDeposit && data.depositListingId
          ? {
              id: new UUID(data.depositListingId),
              privateData: {
                depositInvoice: { id: response.id, url: response.hosted_invoice_url },
              },
            }
          : !data.isDeposit
          ? {
              id: new UUID(data.listingId),
              privateData: { invoice: response.id },
            }
          : null;

      if (!updateBody) {
        dispatch(paymentSuccess());
        return response.hosted_invoice_url;
      } else {
        return sdk.ownListings.update(updateBody).then(() => {
          dispatch(paymentSuccess());
          return response.hosted_invoice_url;
        });
      }
    })
    .catch(error => {
      console.log(error);
      dispatch(paymentError());
    });
};

export const createNewStripeCustomer = (body, stripeAccount) => (dispatch, getState, sdk) => {
  dispatch(createStripeCustomerRequest());
  const { currency, ...rest } = body;
  return createStripeCustomer(rest)
    .then(response => {
      response.currency = currency;

      let accounts=[];
      for(const account of stripeAccount){
         accounts.push(account.id);
      }
      if(response.id){
        accounts.push(response.id);
      }

      const updateBody = {
        privateData: {
          stripeAccount: accounts
        },
      };
      return sdk.currentUser.updateProfile(updateBody, { expand: true }).then(() => {
        dispatch(createStripeCustomerSuccess());
        return response;
      });
    })
    .catch(error => {
      dispatch(createStripeCustomerError());
    });
};

export const updateExistingStripeCustomer = body => (dispatch, getState, sdk) => {
  dispatch(createStripeCustomerRequest());
  return updateStripeCustomer(body)
    .then(response => {
      dispatch(createStripeCustomerSuccess());
      return response;
    })
    .catch(error => {
      dispatch(createStripeCustomerError());
    });
};

export const fetchListingHasInvoice = (listingId, isDeposit) => (dispatch, getState, sdk) => {
  dispatch(fetchingInvoiceRequest());
  sdk.ownListings
    .show({ id: new UUID(listingId) })
    .then(response => {
      const { invoice, depositInvoice } = response?.data.data.attributes.privateData ?? {};
      if ((!isDeposit && invoice) || (isDeposit && depositInvoice)) {
        getInvoice({ invoiceId: invoice ?? depositInvoice }).then(invoiceResponse => {
          dispatch(fetchingInvoiceSuccess(invoiceResponse.hosted_invoice_url));
        });
      } else {
        dispatch(fetchingInvoiceError());
      }
    })
    .catch(e => {
      console.log(e);
      dispatch(fetchingInvoiceError());
    });
};

export const createServiceProjectSequence = version => (dispatch, getState, sdk) => {
  
  const hours = version === 'basic' ? '10h' : version === 'support' ? '20h' : '5h';
  const price = version === 'basic' ? 50000 : version === 'support' ? 100000: 30000
  const draftData = {
    title: `Plan - ${hours}`,
    price: new Money(price, config.currency),
    publicData: {
      category: 'job',
      state: 'open',
      private: false,
      primarySoftware: 'other',
      softwares: ['other'],
      expert: [],
      billing_type: 'hourly',
    },
  };

  return sdk.ownListings.create(draftData).then(response => {
    return response.data.data.id.uuid;
  });
};

export const currentUserHasNonClosedListings = version => (dispatch, getState, sdk) => {
  return sdk.ownListings.query().then(response => {
    for (let listing of response.data.data) {
      if (
        listing.attributes.publicData.state === 'open' ||
        listing.attributes.publicData.state === 'inProgress'
      ) {
        return true;
      }
    }
    return false;
  });
};

export const fetchUserInvoices = body => (dispatch, getState, sdk) => {
  dispatch(fetchingUserInvoicesRequest());

  getUserInvoices(body)
    .then(data => {
      dispatch(fetchingUserInvoicesSuccess(data));
    })
    .catch(e => {
      console.log(e);
      dispatch(fetchingUserInvoicesError());
    });
};

export const fetchUserPayouts = body => (dispatch, getState, sdk) => {
  dispatch(fetchingUserPayoutsRequest());
  getUserPayouts(body)
    .then(data => {
      dispatch(fetchingUserPayoutsSuccess(data));
    })
    .catch(e => {
      console.log(e);
      dispatch(fetchingUserPayoutsError());
    });
}
