import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '../../components';


import css from './AuthModal.css';
import { AuthForm } from '../../forms';

const AuthModal = props => {
  const {
    className,
    rootClassName,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    currentUser,
    inProgress,
    onSubmit,
    passwordChanged,
  } = props;


  const classes = classNames(rootClassName || css.root, className);
  return (
    <Modal
      id="AuthModal"
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
        <AuthForm
        className={css.form}
        currentUser={currentUser}
        onSubmit={onSubmit}
        inProgress={inProgress}
        ready={passwordChanged}
        />
    </Modal>
  );
};

const { string, bool } = PropTypes;

AuthModal.defaultProps = {
  className: null,
  rootClassName: null,
  form: null,
  inProgress: false,
};

AuthModal.propTypes = {
  className: string,
  rootClassName: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

export default injectIntl(AuthModal);
