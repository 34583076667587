import React from 'react';
import { richText } from '../../util/richText';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionExpertiseMaybe = props => {
  const { richTitle } = props;
  
  return richTitle ? (
    <div className={css.sectionExpertise}>
      <h1 className={css.expertise}>
        {richText(richTitle, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </h1>
    </div>
  ) : null;
};

export default SectionExpertiseMaybe;