import React from 'react';
import css from './TicketModal.css';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';

import { AvatarSmall, NamedLink } from '../../components';

const SectionTaskAuthor = props => {
  const { profileTitle, profileId, author, authorName } = props;

  if (!profileId) {
    return (
      <div className={css.sectionTicketAuthor}>
        <p className={css.smallPrintHeading}>Provided by</p>
        <FormattedMessage id="SectionServiceAuthor.loadingProfile" />
      </div>
    )
  }

  return (
    <div className={css.sectionTicketAuthor}>
      <p className={css.providedByTitle}>Provided by</p>
      <div className={css.ticketAvatarWrapper}>

        <>
          <NamedLink name="ExpertPage" params={{ id: profileId, slug: createSlug(profileTitle), category: "experts" }} >
            <div className={css.ticketAvatarAspectWrapper}>
              <AvatarSmall
                user={author}
                className={css.avatarDesktop}
                initialsClassName={css.initialsDesktop}
                disableProfileLink
              />
            </div>
          </NamedLink>
          <div className={css.ticketExpertName}>
            <NamedLink name="ExpertPage" className={css.linkViewExpert} params={{ id: profileId, slug: createSlug(profileTitle), category: "experts" }} >
              {authorName.split(" ")[0]}
            </NamedLink>
          </div>
        </>
      </div>
    </div>
  )
};

export default SectionTaskAuthor;