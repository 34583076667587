import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { AiOutlineSend } from 'react-icons/ai';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './TicketSolutionForm.css';

const AiOutlineSendReplacement = props => {
  const {
    props: {
      attr: { pId, ...rest },
      children: [defs, ...paths],
    },
  } = AiOutlineSend();

  const SAFE_PATHS = paths.map(({ type, props: { pId: pathId, ...pathProps } }) =>
    React.createElement(type, pathProps)
  );
  const SAFE_CHILDREN = React.Children.toArray([defs, ...SAFE_PATHS]);
  const SAFE_PROPS = { attr: rest, ...props };

  return React.cloneElement(AiOutlineSend(), SAFE_PROPS, SAFE_CHILDREN);
};


export class JobApplicationFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e, form) {
    this.props.onSubmit(e, form);
  }

  render() {
    const {
      rootClassName,
      className,
      isAdmin,
      isUser,
      hasExpertProfile,
      isExpertListing,
      isJobListing,
      transactionRole,
      expertListingIsPendingApproval,
      currentUserHasStripeConnected,
      isTransaction,
      isTicket,
      expertStackMatching,
      userConnectedToTicket,
      timeZone,
      startDatePlaceholder,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <FinalForm
        {...rest}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            handleSubmit,
            intl,
            isOwnListing,
            values,
            updated,
            pristine,
            updateInProgress,
            ready,
            invalid,
            disabled
          } = fieldRenderProps;

          const offerMessage = intl.formatMessage({ id: 'TicketSolutionForm.solutionTitle' });

          const offerPlaceholder = intl.formatMessage({
            id: !isOwnListing && !userConnectedToTicket ?
              'TicketSolutionForm.solutionPlaceholder' :
              'TicketSolutionForm.solutionPlaceholderOwnTicket',
          });

          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = (invalid || disabled || submitInProgress || pristine) && !values.solution;

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <div className={css.answerWrapper}>
                {!isTransaction && !isJobListing ? (
                  <FieldTextInput
                    id="solution"
                    name="solution"
                    className={css.solution}
                    type="textarea"
                    placeholder={offerPlaceholder}
                    label={offerMessage}
                  />
                ) : null}

                <div className={css.buttonWrapper}>
                  <PrimaryButton
                    type="submit"
                    inProgress={submitInProgress}
                    ready={submitReady}
                    disabled={submitDisabled}
                    className={css.submitButton}
                  >
                    <FormattedMessage id="TicketSolutionForm.submitSolution" />
                    <AiOutlineSendReplacement className={css.infoIcon} />
                  </PrimaryButton>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

JobApplicationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  isOwnListing: false,
  isAdmin: false,
  isUser: false,
};

JobApplicationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  isOwnListing: bool,
  // from injectIntl
  intl: intlShape.isRequired,
  isUser: bool.isRequired,
  isAdmin: bool.isRequired,
};

const JobApplicationForm = compose(injectIntl)(JobApplicationFormComponent);
JobApplicationForm.displayName = 'JobApplicationForm';

export default JobApplicationForm;
