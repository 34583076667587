import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { CgRadioChecked } from 'react-icons/cg';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import css from './SectionScopeMaybe.css';

const SectionScopeMaybe = props => {
  const {
    scope,
    formattedPrice,
    scopeType,
    transactionRole,
    commission,
    isAdminOffer,
    offerAuthor,
    isNegotiated,
    negotiationReaderRole,
  } = props;

  const [isSummaryPriceDifferent, setIsSummaryPriceDifferent] = useState(false);
  const [oldOfferWithNoSubtasks, setOldOfferWithNoSubtasks] = useState(true);

  useEffect(() => {
    // Old scope didn't contained subtasks
    if (scope?.length > 0) {
      let isOldOfferWithoutSubtasks = true;
      for (let i = 0; i < scope.length; i++) {
        if (!!scope[i]?.subtasks === true) {
          isOldOfferWithoutSubtasks = false;
        }
      }
      setOldOfferWithNoSubtasks(isOldOfferWithoutSubtasks);
      if (!isNegotiated) {
        if (isAdminOffer) {
          setIsSummaryPriceDifferent(false);
        } else if (isOldOfferWithoutSubtasks) {
          checkIfSummaryPriceDifferentOld(scope, formattedPrice, commission);
        } else {
          checkIfSummaryPriceDifferent(scope, formattedPrice, commission);
        }
      }
    }
  }, [scope, isNegotiated, isAdminOffer, formattedPrice, commission]);

  const checkIfSummaryPriceDifferent = (scope, formattedPrice, commission) => {
    let scopeSummaryPrice = scope.reduce((acc, item) => {
      return acc + item?.price;
    }, 0);
    let priceNumber = parseInt(formattedPrice?.replace(/\D/g, ''));
    let scopeSummaryPriceWithCommission = Math.trunc(scopeSummaryPrice / (1 - commission));
    setIsSummaryPriceDifferent(
      isNaN(scopeSummaryPriceWithCommission)
        ? false
        : scopeSummaryPriceWithCommission !== priceNumber
    );
  };

  const checkIfSummaryPriceDifferentOld = (scope, formattedPrice, commission) => {
    let scopeSummaryPrice = scope.reduce((acc, item) => {
      return acc + item?.price;
    }, 0);
    let priceNumber = parseInt(formattedPrice?.replace(/\D/g, ''));
    let scopeSummaryPriceWithCommission = Math.trunc(scopeSummaryPrice / (1 - commission));
    setIsSummaryPriceDifferent(scopeSummaryPriceWithCommission !== priceNumber);
  };

  const renderAdminScope = scope => {
    let scopeItemTaskStyle = css.scopeItemTaskFull;
    let formattedScope = scope.map((item, id) => {
      const scopeItemTask = item.task;
      return (
        <div key={'scopeItem' + id} className={css.scopeItemWrapper}>
          <div className={css.scopeItemIcon}>
            <CgRadioChecked />
          </div>
          <div className={scopeItemTaskStyle}>{scopeItemTask}</div>
        </div>
      );
    });
    return formattedScope;
  };

  const renderScopeRegularOffer = scope => {
    let scopeSummaryPrice = 0;
    for (let i = 0; i < scope.length; i++) {
      if (!!scope[i]?.price) {
        scopeSummaryPrice += scope[i]?.price;
      }
    }
    if (scopeType === 'service' && transactionRole === 'provider') {
      scopeSummaryPrice = Math.trunc(scopeSummaryPrice - scopeSummaryPrice * commission);
    }
    scopeSummaryPrice = scopeSummaryPrice > 0 ? '$' + scopeSummaryPrice : '';
    let formattedScope = scope.map((item, id) => {
      const scopeTaskName = item.name;
      const scopeTaskTime =
        item.time && item.time > 0
          ? item.time === 1
            ? item.time + ' hour'
            : item.time + ' hours'
          : '';
      const scopeTaskPriceWithCommission = item.price
        ? Math.trunc(item.price / (1 - commission))
        : 0;
      const scopeTaskPriceWithoutCommission = item.price
        ? Math.trunc(item.price - item.price * commission)
        : 0;
      const scopeTaskPrice = item.price
        ? scopeType === 'service'
          ? transactionRole === 'provider'
            ? '$' + scopeTaskPriceWithoutCommission
            : '$' + item.price
          : transactionRole === 'customer' || isAdminOffer
          ? '$' + item.price
          : '$' + scopeTaskPriceWithCommission
        : '';

      const scopeTaskSubtasks = item.subtasks.map((subitem, id) => {
        const scopeSubtaskName = subitem.name;
        const scopeSubtaskPriceWithCommission = Math.trunc(subitem.price / (1 - commission));
        const scopeSubtaskPriceWithoutCommission = Math.trunc(
          subitem.price - subitem.price * commission
        );
        const scopeSubtaskPrice = subitem.price
          ? scopeType === 'service'
            ? transactionRole === 'provider'
              ? '$' + scopeSubtaskPriceWithoutCommission
              : '$' + subitem.price
            : transactionRole === 'customer' || isAdminOffer
            ? '$' + subitem.price
            : '$' + scopeSubtaskPriceWithCommission
          : '';

        // SUBTASK
        return (
          <div key={'scopeSubtask' + id} className={css.subtask}>
            <div>{scopeSubtaskName}</div>
            <div>{scopeSubtaskPrice}</div>
          </div>
        );
      });
      // TASK
      return (
        <div key={'scopeTask' + id} className={css.projects}>
          <div className={css.projectSideWrapper}>
            <div className={css.projectPointer} />

            <div
              className={
                scope.indexOf(item) + 1 === scope.length && item.subtasks.length === 0
                  ? css.projectsNoSideLine
                  : scope.indexOf(item) + 1 === scope.length
                  ? css.projectsLastSideLine
                  : css.projectsSideLine
              }
            />
          </div>

          <div className={css.projectWrapper}>
            <div className={css.projectFirstRow}>
              <div className={css.projectTitle}>{scopeTaskName}</div>
              <div className={css.taskDetails}>
                <div className={css.projectTaskTime}>{scopeTaskTime}</div>
                <div className={css.projectTaskPrice}>{scopeTaskPrice}</div>
              </div>
            </div>

            <div className={css.projectDescription}>{scopeTaskSubtasks}</div>
          </div>
        </div>
      );
    });
    // SUMMARY
    if (isAdminOffer || scopeType === 'service') {
      if (scopeSummaryPrice !== '') {
        formattedScope.push(
          <div key={'summary'} className={css.scopeItemSummaryWrapper}>
            <div className={css.scopeItemSummaryTitle}>Summary</div>
            <div className={css.scopeItemSummaryPrice}>{scopeSummaryPrice}</div>
          </div>
        );
      }
    } else if (transactionRole === 'customer') {
      formattedScope.push(
        <div key={'summary'} className={css.scopeItemSummaryWrapper}>
          <div className={css.scopeItemSummaryTitle}>Summary</div>
          <div className={css.scopeItemSummaryPrice}>{scopeSummaryPrice}</div>
        </div>
      );
      if (negotiationReaderRole !== 'expert') {
      formattedScope.push(
        <div key={'summaryFinal'} className={css.scopeItemSummaryWrapper}>
          <div className={css.scopeItemSummaryTitle}>Final price</div>
          <div className={css.scopeItemSummaryPrice}>{formattedPrice}</div>
        </div>
      );}
    }
    // DO NOT DISPLAY ON OFFER PAGE FOR CUSTOMERS
    // else if(transactionRole === 'provider') {
    //   formattedScope.push(
    //     <div key={'summaryFinal'} className={css.scopeItemSummaryWrapper}>
    //         <div className={css.scopeItemSummaryTitle}>
    //           Total price
    //         </div>
    //         <div className={css.scopeItemSummaryPrice}>
    //           {formattedPrice}
    //         </div>
    //       </div>
    //   );
    // }
    return formattedScope;
  };

  const renderScope = scope => {
    let scopeItemTaskStyle =
      transactionRole === 'customer' || scopeType === 'service'
        ? css.scopeItemTask
        : css.scopeItemTaskWide;

    let scopeSummaryPrice = scope.reduce((acc, item) => {
      return acc + item.price;
    }, 0);
    scopeSummaryPrice = scopeSummaryPrice > 0 ? '$' + scopeSummaryPrice : '';
    let formattedScope = scope.map((item, id) => {
      const scopeItemTask = item.task;
      const scopeItemTime =
        item.time && item.time > 0
          ? item.time === 1
            ? item.time + ' hour'
            : item.time + ' hours'
          : '';
      let scopeItemPrice;
      if (scopeType === 'offer') {
        scopeItemPrice = item.price
          ? transactionRole === 'customer'
            ? '$' + item.price
            : '$' + item.price / (1 - commission)
          : '';
      } else {
        scopeItemPrice = item.price ? '$' + item.price : '';
      }
      return (
        <div key={'scopeItem' + id} className={css.scopeItemWrapper}>
          <div className={css.scopeItemIcon}>
            <CgRadioChecked />
          </div>
          <div className={scopeItemTaskStyle}>{scopeItemTask}</div>
          {transactionRole === 'customer' || scopeType === 'service' ? (
            <div className={css.scopeItemTime}>{scopeItemTime}</div>
          ) : null}
          <div className={css.scopeItemPrice}>{scopeItemPrice}</div>
        </div>
      );
    });
    // SUMMARY
    if (isAdminOffer) {
      if (scopeSummaryPrice !== '') {
        formattedScope.push(
          <div key={'summary'} className={css.scopeItemSummaryWrapper}>
            <div className={css.scopeItemSummaryTitle}>Summary</div>
            <div className={css.scopeItemSummaryPrice}>{scopeSummaryPrice}</div>
          </div>
        );
      }
    } else if (scopeType === 'offer') {
      if (transactionRole === 'customer') {
        formattedScope.push(
          <div key={'summary'} className={css.scopeItemSummaryWrapper}>
            <div className={css.scopeItemSummaryTitle}>Summary</div>
            <div className={css.scopeItemSummaryPrice}>{scopeSummaryPrice}</div>
          </div>
        );
        if (negotiationReaderRole !== 'expert') {
          formattedScope.push(
            <div key={'summaryFinal'} className={css.scopeItemSummaryWrapper}>
              <div className={css.scopeItemSummaryTitle}>Final price</div>
              <div className={css.scopeItemSummaryPrice}>{formattedPrice}</div>
            </div>
          );
        }
      } else if (transactionRole === 'provider') {
        formattedScope.push(
          <div key={'summaryFinal'} className={css.scopeItemSummaryWrapper}>
            <div className={css.scopeItemSummaryTitle}>Total price</div>
            <div className={css.scopeItemSummaryPrice}>{formattedPrice}</div>
          </div>
        );
      }
    } else {
      formattedScope.push(
        <div key={'summary'} className={css.scopeItemSummaryWrapper}>
          <div className={css.scopeItemSummaryTitle}>Total price</div>
          <div className={css.scopeItemSummaryPrice}>{formattedPrice}</div>
        </div>
      );
    }
    return formattedScope;
  };

  return scope && scope.length > 0 ? (
    <div className={css.sectionServiceScopeWrapper}>
      <h2 className={css.serviceScopeTitle}>
        <FormattedMessage id={`ListingPage.scopeOfferTitle`} />
      </h2>
      {!!offerAuthor && <h4 className={css.noMarginHeading}>Authored by: {offerAuthor}</h4>}
      <div className={css.serviceScopeContainer}>
        {scopeType === 'adminOffer'
          ? renderAdminScope(scope)
          : oldOfferWithNoSubtasks
          ? renderScope(scope)
          : scopeType === 'offer' || scopeType === 'service'
          ? renderScopeRegularOffer(scope)
          : renderScope(scope)}
      </div>
      {scopeType === 'offer' &&
        transactionRole === 'customer' &&
        negotiationReaderRole !== 'expert' && (
          <p className={classNames(css.smallPrint, css.smallPrintLeft)}>
            <FormattedMessage id="NewOfferForm.offerPriceDescription" />
            <Tooltip
              interactive
              title={
                <FormattedMessage id={`NewOfferForm.offerPriceTooltip`} values={{ br: <br /> }} />
              }
            >
              <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
            </Tooltip>
          </p>
        )}
      {scopeType === 'offer' && transactionRole === 'provider' && isSummaryPriceDifferent && (
        <p className={classNames(css.smallPrint, css.smallPrintLeft, css.scopeSmallPrintImportant)}>
          <FormattedMessage id="NewOfferForm.offerPriceMightDiffer" />
        </p>
      )}
    </div>
  ) : null;
};

export default SectionScopeMaybe;
