import { queryProjectBudgets } from '../../util/api';
import { queryOwnListings } from '../../util/api';
// ================ Action types ================ //

export const FETCH_ONGOING_PROJECTS_REQUEST = 'app/billing/FETCH_ONGOING_PROJECTS_REQUEST';
export const FETCH_ONGOING_PROJECTS_SUCCESS = 'app/billing/FETCH_ONGOING_PROJECTS_SUCCESS';
export const FETCH_ONGOING_PROJECTS_ERROR = 'app/billing/FETCH_ONGOING_PROJECTS_ERROR';

export const FETCH_PROJECTS_REQUEST = 'app/billing/FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = 'app/billing/FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_ERROR = 'app/billing/FETCH_PROJECTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  ongoingProjectsInProgress: false,
  ongoingProjects: [],
  ongoingProjectsError: null,
  projectsInProgress: false,
  projects: [],
  projectsError: null,
};

export default function billingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ONGOING_PROJECTS_REQUEST:
      return { ...state, ongoingProjectsInProgress: true };
    case FETCH_ONGOING_PROJECTS_SUCCESS:
      return {
        ...state,
        ongoingProjects: payload,
        ongoingProjectsInProgress: false,
      };
    case FETCH_ONGOING_PROJECTS_ERROR:
      return {
        ...state,
        ongoingProjectsInProgress: false,
        ongoingProjectsError: payload,
      };

    case FETCH_PROJECTS_REQUEST:
      return { ...state, projectsInProgress: true };
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: payload,
        projectsInProgress: false,
      };
    case FETCH_PROJECTS_ERROR:
      return {
        ...state,
        projectsInProgress: false,
        projectsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //
const fetchProjectsRequest = () => ({ type: FETCH_PROJECTS_REQUEST });
const fetchOngoingProjectsRequest = () => ({ type: FETCH_ONGOING_PROJECTS_REQUEST });
const fetchOngoingProjectsSuccess = data => {
  return {
    payload: data,
    type: FETCH_ONGOING_PROJECTS_SUCCESS,
  };
};

const fetchProjectsSuccess = data => {
  return {
    payload: data,
    type: FETCH_PROJECTS_SUCCESS,
  };
};

const fetchOngoingProjectsError = e => ({
  type: FETCH_ONGOING_PROJECTS_ERROR,
  error: true,
  payload: e,
});

const fetchProjectsError = e => ({
  type: FETCH_PROJECTS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchOngoingProjects = currentUser => (dispatch, getState, sdk) => {
  dispatch(fetchOngoingProjectsRequest());

  return queryProjectBudgets(currentUser.attributes.profile.privateData.inboxToken, {
    authorId: currentUser.id.uuid,
    email: currentUser.attributes.email,
  })
    .then(data => {
      dispatch(fetchOngoingProjectsSuccess(data));
    })
    .catch(e => {
      dispatch(fetchOngoingProjectsError(e));
    });
};

export const fetchProjects = currentUser => (dispatch, getState, sdk) => {
  dispatch(fetchProjectsRequest());

  return queryOwnListings({
    category: 'all',
    userId: currentUser?.id.uuid,
    userEmail: currentUser.attributes.email,
  })
    .then(data => {
      dispatch(fetchProjectsSuccess(data));
    })
    .catch(e => {
      dispatch(fetchProjectsError(e));
    });
};
