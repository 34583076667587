import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, FieldCheckbox} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './NewUserForm.css';

const NewUserForm = props => {
    return (
    <FinalForm
    {...props}
    render={formRenderProps => {
        const {
            handleSubmit,
            values,
        } = formRenderProps;

        const disabled = !values.question?.length > 0;
        const formName = "question"
        
        return (
            <Form onSubmit={handleSubmit}>
            <p className={css.modalMessage}>
                <FormattedMessage id="NewUserForm.title" />
            </p>
            <FieldCheckbox
                id="project"
                value="post project"
                name={formName}
                label={<FormattedMessage id="NewUserForm.fieldProject" />}
            />
            <FieldCheckbox
                id="support"
                value="support plan"
                name={formName}
                label={<FormattedMessage id="NewUserForm.fieldSupport" />}
            />
            <FieldCheckbox
                id="coworker"
                value="coworker"
                name={formName}
                label={<FormattedMessage id="NewUserForm.fieldCoworker" />}
            />
            <FieldCheckbox
                id="expert"
                value="expert"
                name={formName}
                label={<FormattedMessage id="NewUserForm.fieldExpert" />}
            />
            <FieldCheckbox
                id="unknown"
                value="unknown"
                name={formName}
                label={<FormattedMessage id="NewUserForm.fieldUnknown" />}
            />
            <PrimaryButton 
            className={css.submitButton}
            disabled={disabled} 
            type="submit">
                <FormattedMessage id="NewUserForm.submitButtonLabel" />
            </PrimaryButton>
            </Form>
        );
    }}
    />
    )
}

export default NewUserForm;
        