export const compareData = (currentAccount, currentUser, values) => {
    const { address, tax_info } = currentAccount;
    if (
      values?.city !== address?.city ||
      values?.country !== address?.country ||
      values?.addressLine1 !== address?.line1 ||
      values?.addressLine2 !== address?.line2 ||
      values?.postal !== address?.postal_code ||
      values?.vatNumber !== tax_info?.tax_id ||
      currentUser.attributes.email !== currentAccount.email ||
      values?.name !== currentAccount.name
    ) {
      return true;
    }
    return false;
} 

export const createUpdateBody = (values, currentUser) => {
   
    return {
        name: values.name ? values.name: currentUser.attributes.profile.firstName + " " + currentUser.attributes.profile.lastName,
        email: currentUser ? currentUser.attributes.email: values.email,
        address: values ? {
            city: values.city,
            country: values.country,
            line1: values.addressLine1,
            line2: values.addressLine2,
            postal_code: values.postal
        }
            : null,
        tax_id: values.vatNumber,
        currency: values.currency ?? 'usd'
    }
}