import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './EditPageSidebar.css';

const EditPageSidebarComponent = props => {
  const { tabArray, handleScroll, currentTabIndex, disabledPagesIndex, EditFormMode} = props;

  const tabsEditPage = tabArray.map((tab, index) => {
    const isDisabled = disabledPagesIndex !== null && currentTabIndex >= disabledPagesIndex && index > currentTabIndex;
    return <div
      className={isDisabled ? css.disabledTab : currentTabIndex === index ? css.currentTab : css.tab}
      key={tab + index}
      onClick={e => isDisabled ? null : handleScroll(index, tab, EditFormMode ? true :false )}
    >
      { EditFormMode ? <FormattedMessage id={`EditPageSidebar.${tab}Nav`} />: <FormattedMessage id={`EditPageSidebar.${tab}Nav`} /> }
      
    </div>
  });

  return (
    <div className={css.layout}>
      <div className={ EditFormMode ? classNames(css.tabs, css.fixedTabs) : css.tabs}>

      {tabsEditPage}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const {
    currentUserExpertListing,
  } = state.user;
  return {
    currentUserExpertListing,
  };
};

const EditPageSidebar = compose(
  connect(mapStateToProps),
)(EditPageSidebarComponent);

export default EditPageSidebar;
