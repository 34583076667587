import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from '../../components/ExpertOverview/ExpertPage.css';
import { InlineTextButton } from '../../components';
import css2 from './ExpertPage.css';

const SectionExperience = props => {
  console.log('props', props)
  const { experience } = props;

  console.log(experience)

  const [showMoreExperience, setShowMoreExperience] = useState(false);
  if (!experience) {
    return null;
  }

  const renderExperience = experience => {
    const experienceLength = experience?.length;
    if (experience?.length > 1 && !showMoreExperience) {
      experience = experience.slice(0, 2);
    }
    return experience.map((experience, index) => {
      let experienceUrl = experience.url;
      if (experienceUrl) {
        const protocolTokens = experienceUrl.split(':');
        const includesProtocol = protocolTokens.length > 1;
        const usesHttpProtocol = includesProtocol && !!protocolTokens[0].match(/^(https?)/);
        if (!usesHttpProtocol) {
          experienceUrl = 'https://' + experienceUrl;
        }
      }
      return (
        <div className={css.projects} key={experience.title + index}>
          <div className={css.projectSideWrapper}>
            <div className={css.projectPointer} />
            <div className={index === experience.length - 1 ? css.projectsLastSideLine : css.projectsSideLine} />
          </div>
          <div
            className={index === 1 && !showMoreExperience && experienceLength > 2 ? css.invisibleProjectWrapper : css.projectWrapper}
          >
            <div className={css.projectFirstRow}>
              <div className={css2.projectTitle}>
                {experience.title}
              </div>
              <div className={css2.companyTitle}>
                {experience.company ? experience.company : ''}
                {experience.url
                  ? <InlineTextButton className={css.viewUrl} onClick={() => window.open(experienceUrl)}>
                    <span className={css.viewUrlText}>
                      <FormattedMessage id="ExpertPage.viewUrl" />
                    </span>
                  </InlineTextButton>
                  : null}

              </div>
              <div className={css2.projectTime}>
                <FormattedMessage id="FieldExperience.yearsLabel" />
                {experience.years
                  ? (`: ${experience.years} ` + (experience.years === 1 || experience.years === 1.5 || experience.years === '1' || experience.years === '1,5'
                    ? 'year' : 'years'))
                  : ''}
              </div>
            </div>

            <div className={css.projectDescription}>
              {experience.description}
            </div>
            {index === 1 && !showMoreExperience && experienceLength > 2
              ? <div className={css.invisibleBlock} />
              : null}
          </div>
        </div>
      );
    })
  }

  return (
    <>
      {experience?.length > 0 ? (
        <div >
          <h2 className={css.projectsTitle}>
            <FormattedMessage id="ExpertPage.expertProfessionalExperience" />
          </h2>
          <div className={css.sectionProfessionalExperience}>
            <div>
              {renderExperience(experience)}
              {experience.length > 2 && !showMoreExperience
                ?
                <div className={css.showMoreWrapper}>
                  <InlineTextButton className={css.showMoreButton} onClick={() => setShowMoreExperience(true)}>
                    <span className={css.showMoreText}>
                      <FormattedMessage id="ExpertPage.showMore" />
                    </span>
                  </InlineTextButton>
                </div>
                : null
              }
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
};

export default SectionExperience;
