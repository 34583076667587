import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <h2 className={css.centered}>
        SOFTWARESUPP SP. Z O.O. WITH THE REGISTERED OFFICE
        <br />
        IN WROCLAW (POLAND)
      </h2>
      <p className={css.lastUpdated}>Last updated: October 28, 2020</p>

      <p className={css.centered}>PREFACE</p>

      <p>
        SoftwareSupp sp. z o.o. (ltd.) (owner of SoftwareSupp) with the registered office in
        Wroclaw, Poland highly appreciates protection of your personal data as well as your privacy.
        Thus, for the purposes of providing you with our website services, we decided to be bound by
        the principles included in this Privacy Policy. Please read this Policy carefully as it
        defines basic principles and mechanisms of how we process your personal information. This is
        a legal document, yet, we have tried to prepare it in a clear and transparent manner in
        order to enhance protection of your rights. This Data Protection Policy ("Policy") has been
        prepared by SoftwareSupp sp. z o.o. (ltd.) with its registered office in Wroclaw, Poland.
        ("SoftwareSupp", "us", "we", or "our"). In order to clarify we would like to indicate that
        you could use the principles of the GDPR if you are a citizen of one of the EEA states. In
        the following part of this Policy, all persons covered by the GDPR principles on processing
        of personal data are going to be jointly referred to as “EU persons”. Should you have any
        doubts with regard to your rights – please do not hesitate to contact us, we are here to
        help you! If the GDPR does not concern you, we strongly invite you to carefully read the
        entire General Data Protection and Privacy Policy document. Even if the GDPR does not apply
        in your case (as, for example, due to the fact that you are the citizen and resident of the
        US), we would like to protect your privacy and personal information
      </p>

      <p className={css.centered}>SIGNIFICANT CONCEPTS</p>

      <p>
        Please note that there are a number of concepts important to the protection of your rights.
        By processing of your personal data we understand the following key concepts as follows:
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              PERSONAL DATA – means any information relating to an identified or identifiable
              natural person. The identifiable natural person is the one who can be identified,
              directly or indirectly, in particular by reference to an identifier such as a name and
              surname. Typical examples of personal data are as follows: home and work addresses,
              telephone number, e-mail address, social security number, birthdate, gender, marital
              status, mother's maiden name, and health data.
            </td>
          </tr>
          <tr>
            <td>
              PROCESSING OF DATA – means any operation or set of operations which is performed on
              personal data or on sets of personal data, whether or not by automated means.
              Processing of data involves in particular: collection, recording, organization,
              structuring, storage, adaptation or alteration, retrieval, consultation, use,
              disclosure by transmission, dissemination or otherwise making available, alignment or
              combination, restriction, erasure or destruction of personal data.
            </td>
          </tr>
          <tr>
            <td>
              CONTROLLER OF PERSONAL DATA – within the framework of the EU law means the natural or
              legal person, public authority, agency or other body which, alone or jointly with
              others, determines the purposes and means of the processing of personal data;
              <br />
              <br />
              The data controller processing personal data for the purpose of providing our website
              is as follows:
              <br />
              <br />
              SoftwareSupp sp. z o.o. with its registered office in Wroclaw, Poland with the address
              for service: ul. Powstancow Slaskich Street 129/6 (53–317 Wroclaw), entered into the
              registry of entrepreneurs of the National Court Registry under the KRS number
              0000732460, with the registry files maintained by the District Court for Wroclaw –
              Fabryczna in Wroclaw, VI Commercial Division of the National Court Registry, with the
              invested capital in the amount of $250,000, with the NIP number: (tax identification
              number) 8992845287 and REGON number: (statistical number) 380268047.
              <br />
              <br />
              In all matters concerning the protection of your privacy and personal data you are
              welcome to contact us through the following electronic contact details:
              support@softwaresupp.com
              <br />
              <br />
              At the same time, we would like to kindly inform persons from the EU that we have not
              appointed a data protection officer (see: Articles 37–39 of GDPR).
            </td>
          </tr>
          <tr>
            <td>
              THE BASIS OF DATA PROCESSING – legally defined grounds for the processing of personal
              data by us. In principle, we process your data on the basis of your consent, or
              because we need it to provide you with the website. It may happen that we would be
              forced by law to transfer your personal data to public services - yet we always remain
              committed to act in accordance with the law. We are allowed to use your data to
              develop SoftwareSupp services through, for example, customer profile analysis,
              preparation of marketing strategies.
            </td>
          </tr>
          <tr>
            <td>
              PROFILING – means any form of automated processing of personal data consisting of the
              use of personal data to evaluate certain personal aspects relating to natural person,
              (in particular to analyze or predict aspects concerning that natural person's
              performance at work, economic situation, health, personal preferences, interests,
              reliability, behavior, location or movements). Examples of profiling are, e.g.
              automatic credit rating or displaying advertisements based on previous Internet
              activity.
              <br />
              <br />
              Currently we do not use your personal data to make automated decisions using the
              available technologies. If we change it in the future, we will update this Policy to
              let you know more.
            </td>
          </tr>
          <tr>
            <td>
              PROCESSOR – means a natural or legal person, public authority, agency or other body
              which processes personal data on behalf of the controller. It may happen that the
              controller of your data, acting legally, entrusts data to third party.
              <br />
              <br />
              Acting in compliance with the law, we may transfer your personal data to our
              contractors and service providers. We always provide at least the same level of
              security of your data and are constantly committed to choose our contractors who can
              guarantee a high level of protection of your privacy.
            </td>
          </tr>
          <tr>
            <td>
              CONSENT TO DATA PROCESSING – crucial concept, as most frequently we process your data
              based on your consent. Consent of the data subject means any freely given, specific,
              informed and unambiguous indication of the data subject's wishes by which he or she,
              by a statement or by a clear affirmative action, signifies agreement to the processing
              of personal data relating to him or her.
              <br />
              <br />
              Please remember that your consent to the processing of your personal data by us is and
              would always remain voluntary. You can also withdraw your consent at any time, however
              without your consent to data processing we might be unable to provide you with our
              website.
            </td>
          </tr>
        </tbody>
      </table>

      <p className={css.centered}>LAW</p>
      <p>
        {' '}
        In case of the EU citizens, the principles for the processing of personal data arise
        primarily from the so-called General Data Protection Regulation. It is an act of the EU law,
        which means that it is a regulation common to all of the EU Members. The same principles
        apply to all of the EU entrepreneurs.
        <br />
        The full name of this act is as follows: Regulation of the European Parliament and of The
        Council (EU) 2016/679 of 27 April 2016 on the protection of natural persons with regard to
        the processing of personal data and on the free movement of such data, and repealing
        Directive 95/46/EC (General Data Protection Regulation) (GDPR).
      </p>

      <p className={css.centered}>REASONS AND TYPES OF DATA </p>
      <p>
        In our organization, we process variety of data and different categories of personal data –
        all for specific purposes. If we ask you for consent to process information, we inform you
        about the processing principles. Please do not hesitate to familiarize yourself with them
        carefully.
        <br />
        <br />
        By using SoftwareSupp website, you consent to the processing of your personal data for the
        purpose of providing services by us.
        <br />
        <br />
        In the case of our website we collect and process the following data for the purposes
        indicated below:
      </p>

      <table>
        <tbody>
          <tr>
            <th>SOURCE OF DATA</th>
            <th>TYPE OF DATA</th>
            <th>PURPOSE</th>
          </tr>
          <tr>
            <td>website contact form, any other communications between us</td>
            <td>required: name, surname, e-mail address; optional: telephone number</td>
            <td>
              communications with you, negotiations of contracts, presentation of our offers and
              services, handling your requests
            </td>
          </tr>
          <tr>
            <td>any agreement between you and SoftwareSupp</td>
            <td>
              any agreement between you and SoftwareSupp
              <br />
              name and surname (if applicable), business name, contact address, email address, tax
              ID
            </td>
            <td>execution and performance of an agreement</td>
          </tr>
          <tr>
            <td>data collected during the use of website</td>
            <td>
              IP address, number of pages visited at the website, time spent on particular pages,
              any server requests, cursor position
            </td>
            <td>analytical purposes</td>
          </tr>
          <tr>
            <td>cookies files (please see the information below)</td>
            <td>identity of website</td>
            <td>user identification, authentication and authorization during the session</td>
          </tr>
        </tbody>
      </table>

      <p className={css.centered}>BASIC PRINCIPLES OF PERSONAL DATA PROTECTION</p>
      <p>
        Processing of your personal data may each time look different, depending on what data we
        process, for what purpose, by what means, on what legal basis, etc. In each case, however,
        we are guided by a few fundamental values and principles:
      </p>

      <ol type="I">
        <li>LAWFULNESS – we always process your data in accordance with the applicable law;</li>
        <li>
          RELIABILITY – we process your data reliably, in the organized and thoughtful manner;
        </li>
        <li>TRANSPARENCY – we are committed to make the data processing processes transparent;</li>
        <li>
          PURPOSEFULNESS – we always collect and process data for a specific legal purpose or
          purposes; we do not collect data unnecessarily;
        </li>
        <li>
          ADEQUACY – we process data adequate to the purposes for which we do it; we limit the
          processing of data to what is necessary to achieve a specific purpose beyond which we do
          not cross;
        </li>
        <li>
          CORECTNESS – we take reasonable care to process only personal data which are correct and
          up-to-date;
        </li>
        <li>
          LIMITATION OF STORAGE – in accordance with the GDPR, storage in a form which permits
          identification of data subjects for no longer than is necessary for the purposes for which
          the personal data are processed; we store personal data no longer than reasonably needed;
        </li>
        <li>
          INTEGRITY AND CONFIDENTIALITY – we process personal data in the manner that ensures
          appropriate security of the personal data, including protection against unauthorized or
          unlawful processing and against accidental loss, destruction or damage. We use appropriate
          technical or organizational measures;
        </li>
        <li>
          ACCOUNTABILITY – the controller of your data is responsible for compliance with the rules
          listed above. We keep records of how we process your personal data in order demonstrate
          that, if necessary.
        </li>
      </ol>
      <p className={css.centered}>INFORMATION ON YOUR RIGHTS (GDPR)</p>
      <p>
        The GDPR regulation confer persons from the European Union with a number of rights that they
        can use while we process their personal data. If you are the person from the EU or EEA you
        are vested in with the following rights:
      </p>

      <ol type="a">
        <li>
          the right to access and receive copies of your data. You have the right to receive from us
          one copy of your personal data, which we process, and another – for a fee;
        </li>
        <li>the right to rectify (to amend) your personal data;</li>
        <li>
          the right to erase data. If you think there are no grounds for us to process your data
          further and you are right, you can demand erasure;{' '}
        </li>
        <li>
          limitations on data processing. If you think that we have inaccurate data about you, and
          you do not request to erase these data, you can demand that we limit ourselves only to
          store this data, or to other undertake other activities that we would agree with you;
        </li>
        <li>the right to object to the processing of personal data;</li>
        <li>the right to data portability;</li>
        <li>
          if we process your data on the basis of your consent, you have the right to withdraw your
          consent for processing at any time without giving any reason. This does not affect the
          legality of the previous processing;
        </li>
        <li>the right to complain to the appropriate supervisory authority for our actions.</li>
      </ol>

      <p>
        In order to exercise your rights, you must first let us know. As a first step you are kindly
        requested to contact in a convenient way for you with the us as we are the controller of
        your personal data.{' '}
      </p>

      <p className={css.centered}>
        INFORMATION ON YOUR RIGHTS (PERSONS FROM OUTSIDE OF THE EU AND THE EEA)
      </p>
      <p>
        We would like to look after the security of all our Users and website visitors. If the
        provisions of GDPR do not apply to you, you can still request that your personal data to be
        rectified if they are incorrect; to abandon processing them - if there is no grounds for it;
        to amend personal data - if they are changed. Should you like to amend anything or you
        simply wish to find out more, please do not hesitate to contact us.
      </p>

      <p className={css.centered}>
        TRANSFER OF DATA TO THIRD COUNTRIES AND INTERNATIONAL ORGANIZATIONS
      </p>
      <p>
        SoftwareSupp is the company incorporated under the Poland law system. The data you provide
        to us shall be processed primarily in Poland. However, due to the fact that we usually act
        as intermediaries and the scope of our activity is not limited territorially, your data may
        be processed in various countries around the World.
        <br />
        <br />
        No matter where your data is processed, we are striving to provide equal level of data
        security everywhere. In particular, we select our contractors providers of infrastructure
        and IT services – choosing only those who can guarantee the high level of protection of your
        privacy.
      </p>

      <p className={css.centered}>SECURITY OF YOUR DATA AND DATA RETENTION</p>
      <p>
        We use appropriate to the level of risk (which may involve processing of your data)
        technological, organizational and physical safeguards. Depending on the circumstances, we
        may use different types of security: IT security, encryption, pseudoanonymization, physical
        security or well organized internal principles of processing of personal data only by
        concretely authorized persons. We protect your data in particular against accidental loss,
        modification or unauthorized disclosure to third parties. We protect your data best as we
        can.
        <br />
        <br />
        We store and process your data for as long as it is necessary for the purposes for which we
        do it. We might be obliged by law to keep data for a specific minimum period – we comply
        with such requirements. In principle, we process your data as long as it is necessary to
        provide and settle our services.
      </p>

      <p className={css.centered}>LINKS TO OTHER SITES</p>
      <p>
        Our Service may, from time to time, contain connections with or links to other sites that
        are not operated by us. If you click on a third-party link, you will be directed to that
        third party’s site or service. We advise you to review the privacy policy of every
        third-party service provider. We have no control over, and assume no responsibility for the
        content, privacy policies or practices of any third-party sites or services.
      </p>

      <p className={css.centered}>CHANGES TO PRIVACY POLICY</p>
      <p>
        This Policy is effective as of 1 November 2020 and will remain in effect in its current
        wording until we amend or change it in any manner. In case of any changes – we will let you
        know about, either by email message or by posting the new Policy on our website, as
        reasonably practicable. We reserve the right to update or change our Policy at any time. If
        you continue to use our services after any change of Policy, it means that you agree with
        such changes.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
