import React from 'react';
import css from './TicketModal.css';
import {parseTime} from '../../util/helpers'
import { ProgressBar } from '../../components';

const SectionProgressMaybe = props => {
  const { estimationHours, completionHours = 0, intl } = props;

  const progressTootlip = intl.formatMessage(
    { id: 'TicketModal.progressTooltip' },
    { estimationHours: estimationHours, completionHours: completionHours }
  );
  
  if (!completionHours) return null;

  return (
    <div>
      <p className={css.progressLabel}>
        Reported time: {completionHours}
        <br />
        Estimated time: {estimationHours === undefined ? 0: estimationHours}
      </p>

      <div className={css.sectionProgress} title={progressTootlip}>
        <div className={css.progressBar}>
          <ProgressBar
            currentValue={parseTime(completionHours)}
            maxValue={
              estimationHours === undefined ? parseTime(completionHours) : parseTime(estimationHours)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SectionProgressMaybe;
