import React from 'react';
import { PrimaryChip, DefaultChip, HighlightedChip } from '../../components/Chip/Chip';
import { NamedLink } from '../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './ExpertPage.css';

const SectionTagsMaybe = props => {
  const { softwares, skills, industries, productSoftware, productSkills, productIndustries } = props;

  const softwaresLabels = !!softwares && productSoftware.filter(s => softwares.includes(s.key));
  const skillsLabels = !!skills && productSkills.filter(s => skills.includes(s.key));
  const industriesLabels = !!industries && productIndustries.filter(s => industries.includes(s.key));


  const renderSoftwares = () => {
    return softwaresLabels?.map((software, index) => {
      return (
        <NamedLink
          key={index}
          name={`SearchPage_experts`}
          to={{
            search: `pub_softwares=${software.key}`,
          }}
        >
          <DefaultChip className={css.chip} key={software.label}>
            {software.label}
          </DefaultChip>
        </NamedLink>
      );
    });
  };

  const renderSkills = () => {
    return skillsLabels?.map((skill, index) => {
      return (
        <NamedLink
          key={index}
          name={`SearchPage_experts`}
          to={{
            search: `pub_skills=${skill.key}`,
          }}
        >
          <PrimaryChip className={css.chip} key={skill.label}>
            {skill.label}
          </PrimaryChip>
        </NamedLink>
      );
    });
  };
  
  const renderIndustries = () => {
    return industriesLabels?.map((industry, index) => {
      return (
        <NamedLink
          key={index}
          name={`SearchPage_experts`}
          to={{
            search: `pub_industries=${industry.key}`,
          }}
        >
          <HighlightedChip className={css.chip} key={industry.label}>
            {industry.label}
          </HighlightedChip>
        </NamedLink>
      );
    });
  };

  return softwares || skills || industries ? (
    <>
      <h2 className={css.smallPrintHeading}> Software</h2>

      <div className={css.sectionTags}>{softwares ? renderSoftwares() : null}</div>
      {skills ? (
        <>
          <h2 className={css.smallPrintHeading}> Skills </h2>
          <div className={css.sectionTags}>{skills ? renderSkills() : null}</div>
        </>
      ) : null}
      
      {industries ? (
        <>
          <h2 className={css.smallPrintHeading}> Industries </h2>
          <div className={css.sectionTags}>{renderIndustries()}</div>
        </>
      ) : null}
    </>
  ) : null;
};

const mapStateToProps = state => {
  const { productSoftware, productSkills, productIndustries } = state.marketplaceData;
  return {
    productSoftware,
    productSkills,
    productIndustries,
  };
};

export default compose(connect(mapStateToProps))(SectionTagsMaybe);
