import React, { useState, useEffect } from 'react';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import classNames from 'classnames';
import { FieldTextInput, FieldCurrencyInput } from '../../../components';
import { required, composeValidators, moneyValueAtLeast, moneyValuePrecise, nonNegativeNumber } from '../../../util/validators';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import css from './CustomOfferScopeInput.css';
import { BiPlus, BiMinus } from 'react-icons/bi';
import { HiArrowNarrowRight } from 'react-icons/hi';

const CustomOfferScopeInput = props => {

  const { intl, values, disabled, currentUserHasHourlyRate, currentUserHourlyRate, isAdminOffer, loadedScope, loadedValues } = props;
  const initialScope = [
    {
      id: 0,
      name: '',
      time: '',
      price: '',
      subtasksSumPrice: 0,
      containsEmptySubtaskPrice: false,
      subtasks: [
        {
          id: 0,
          name: '',
          time: '',
          price: ''
        }
      ]
    },
    {
      id: 1,
      name: '',
      time: '',
      price: '',
      subtasksSumPrice: 0,
      containsEmptySubtaskPrice: false,
      subtasks: [
        {
          id: 0,
          name: '',
          time: '',
          price: ''
        }
      ]
    },
    {
      id: 2,
      name: '',
      time: '',
      price: '',
      subtasksSumPrice: 0,
      containsEmptySubtaskPrice: false,
      subtasks: [
        {
          id: 0,
          name: '',
          time: '',
          price: ''
        }
      ]
    }
  ];
  const [scopeItems, setScopeItems] = useState(initialScope);
  const [refreshMarker, setRefreshMarker] = useState(0);
  const [currencyInputTaskWatcher, setCurrencyInputTaskWatcher] = useState(0);
  const [currencyInputSubtaskWatcher, setCurrencyInputSubtaskWatcher] = useState(0);

  // useEffect(() => {
  //   values['hasHourlyRate'] = props.currentUserHasHourlyRate;
  // }, [props.currentUserHasHourlyRate]);

  useEffect(() => {
    setScopeItems(currentScopeItems => {
      let newScopeItems = [...currentScopeItems];
      for(let i = 0; i < currentScopeItems.length; i++) {
        newScopeItems[i]['subtasksSumPrice'] = calculateSubtaskSumPrice(i, props.values);
        newScopeItems[i]['containsEmptySubtaskPrice'] = checkIfContainsEmptySubtaskPrice(i, props.values);
      }
      return newScopeItems;
    });
  }, [props.values, refreshMarker]);

  useEffect(() => {
    if(props.isScopeLoaded) {
      let newScopeItems = loadedScope.map((item, index) => {
        let newSubtasks = item.subtasks?.length > 0 ? item.subtasks.map((subitem, subindex) => {
          return {
            id: subindex,
            name: '',
            time: '',
            price: '',
          }
        }) : [{
          id: 0,
          name: '',
          time: '',
          price: '',
        }];
        return {
          id: index,
          name: '',
          time: '',
          price: '',
          subtasksSumPrice: 0,
          containsEmptySubtaskPrice: false,
          subtasks: newSubtasks
        };
      });
      setScopeItems(newScopeItems);
      setCurrencyInputTaskWatcher(value => value+1);
      setCurrencyInputSubtaskWatcher(value => value+1);
    }
  },[props.isScopeLoaded, loadedScope]);

  const refresh = () => {
    setRefreshMarker(refreshMarker+1);
  }

  const calculateSubtaskSumPrice = (taskId, values) => {
    let subtaskPrices = Object.entries(values).filter((entryArray) => {
      return entryArray[0].includes("scopeTask"+taskId+"SubtaskPriceInput");
    });
    let subtaskSumPrice = !!subtaskPrices?.length > 0 ? subtaskPrices.reduce((acc, entry) => {
      return entry[1]?.amount > 0 ? acc + entry[1]?.amount : acc;
    }, 0) : 0;
    return subtaskSumPrice;
  }

  const checkIfContainsEmptySubtaskPrice = (taskId, values) => {
    let scopeSubtaskEntries = Object.entries(values).filter((entry) => {
      return entry[0].includes('scopeTask'+taskId+'Subtask');
    });
    let scopeSubtaskIds = scopeSubtaskEntries.filter((entry) => {
      return entry[0].includes('scopeTask'+taskId+'SubtaskNameInput') && entry[1] !== '';
    }).map((entry) => {
      return parseInt(entry[0].replace('scopeTask'+taskId+'SubtaskNameInput', ""));
    }).sort((a,b) => {
      return a - b; // integers sort
    });
    let containsEmpty = false;
    for(let i = 0; i < scopeSubtaskIds.length; i++) {
      let priceEntry = scopeSubtaskEntries.find((entry) => {
        return entry[0].includes('scopeTask'+taskId+'SubtaskPriceInput'+scopeSubtaskIds[i]);
      });
      if(!!priceEntry === false || !!priceEntry[1] === false) {
        containsEmpty = true;
      }
    }
    return containsEmpty;
  }

  const taskLabel = "Milestone name";
  const taskPlaceholder = "What will be done?";
  const taskTimeLabel = "Estimated time";
  const taskTimePlaceholder = "Time (hours)";
  const taskPriceLabel = "Estimated price";
  const taskPricePlaceholder = "Price ($)";

  const subtaskNamePlaceholder = "Task name";
  const subtaskTimePlaceholder = "Time (hours)";
  const subtaskPricePlaceholder = "Price ($)";

  const valueRequired = required(
    intl.formatMessage({
      id: 'CustomOfferScopeInput.valueRequired',
    })
  );

  const valueNotNegative = nonNegativeNumber(
    intl.formatMessage({
      id: 'CustomOfferScopeInput.valueNotNegative',
    })
  );

  const { Money } = sdkTypes;

  const changeValue = (taskId, subtaskId) => {
    values["scopeTask"+taskId+"SubtaskPriceInput" + subtaskId] 
      = new Money((values["scopeTask"+taskId+"SubtaskTimeInput" + subtaskId] * currentUserHourlyRate), config.currency);
    return null;
  }

  const addScopeSubtaskHandler = (taskId) => {
    const subtasksLength = scopeItems[taskId].subtasks.length;
    const lastScopeSubtaskId = scopeItems[taskId].subtasks[subtasksLength-1].id;
    const newSubtasks = [
      ...scopeItems[taskId].subtasks,
      {
        id: lastScopeSubtaskId+1,
        name: '',
        time: null,
        price: null
      }
    ];
    let newScopeItems = scopeItems;
    newScopeItems[taskId].subtasks = newSubtasks;
    setScopeItems([
      ...newScopeItems
    ]);
    refresh();
  }

  const removeScopeSubtaskHandler = (taskId) => {
    const subtaskId = scopeItems[taskId].subtasks.length - 1;
    scopeItems[taskId].subtasks.pop();
    setScopeItems([...scopeItems]);
    values["scopeTask"+taskId+"SubtaskNameInput"+subtaskId] = "";
    values["scopeTask"+taskId+"SubtaskTimeInput"+subtaskId] = null;
    values["scopeTask"+taskId+"SubtaskPriceInput"+subtaskId] = null;
    refresh();
  }

  const renderSubtasks = (taskId, subtasks) => {
    return !!subtasks ? subtasks.map((item) => {
      const shouldDisplaySubtaskPlus = subtasks.length === 1 && (item.id === subtasks.length - 1);
      const shouldDisplaySubtaskMinus = subtasks.length > 1 && (item.id === subtasks.length - 1);
      return (
        generateSubtaskInput(taskId, item, addScopeSubtaskHandler, removeScopeSubtaskHandler, shouldDisplaySubtaskPlus, shouldDisplaySubtaskMinus)
      );
    }) : null;
  }

  const generateSubtaskInput = (taskId, {id, name, time, price}, addScopeSubtaskHandler, removeScopeSubtaskHandler, shouldDisplaySubtaskPlus, shouldDisplaySubtaskMinus) => {
    return (
      <div key={"scopeSubtaskInput"+id} className={css.scopeSubtaskWrapper}>
        {/* SCOPE SUBTASK POINTER */}
        <div className={css.scopeSubtaskPointer}>
          <HiArrowNarrowRight />
        </div>
        {/* SCOPE SUBTASK NAME */}
        <FieldTextInput
          id={"scopeTask"+taskId+"SubtaskNameInput"+id}
          name={"scopeTask"+taskId+"SubtaskNameInput"+id}
          className={css.scopeSubtaskName}
          type="text"
          placeholder={subtaskNamePlaceholder}
          validate={values["scopeTask"+taskId+"SubtaskTimeInput"+id]?.length > 0 
                    || values["scopeTask"+taskId+"SubtaskPriceInput"+id]?.amount >= 0 
                    ? composeValidators(valueRequired) : null}
          disabled={disabled}
        />
        {/* SCOPE SUBTASK TIME */}
        <FieldTextInput
          id={"scopeTask"+taskId+"SubtaskTimeInput"+id}
          name={"scopeTask"+taskId+"SubtaskTimeInput"+id}
          className={css.scopeSubtaskTime}
          type="number"
          placeholder={subtaskTimePlaceholder}
          disabled={disabled}
          validate={valueNotNegative}
        />
        {/* SCOPE SUBTASK PRICE */}
        {(currentUserHasHourlyRate && !!values["scopeTask"+taskId+"SubtaskTimeInput"+id] && values["scopeTask"+taskId+"SubtaskTimeInput"+id]>0) ?
          <div className={css.scopeSubtaskPrice}>
            <div className={css.scopeInputValue}>
              {formatMoney(intl, new Money((values["scopeTask"+taskId+"SubtaskTimeInput"+id]*currentUserHourlyRate),config.currency))}
              {changeValue(taskId, id)}
            </div>
          </div>
          :
          <FieldCurrencyInput
            id={"scopeTask"+taskId+"SubtaskPriceInput"+id}
            name={"scopeTask"+taskId+"SubtaskPriceInput"+id}
            className={css.scopeSubtaskPrice}
            currencyConfig={config.currencyConfig}
            placeholder={subtaskPricePlaceholder}
            disabled={disabled}
            loadable // custom flag to update price when loaded from outside
            loadedValue={loadedValues[`scopeTask${taskId}SubtaskPriceInput${id}`]}
            loadedWatcher={currencyInputSubtaskWatcher}
          />
        }
        {/* BUTTONS */}
        {shouldDisplaySubtaskPlus ?
          <div className={css.scopeSubtaskButtonsWrapper}>
            <div className={classNames(css.scopeSubtaskButton, css.scopeTaskButtonMin)} onClick={() => addScopeSubtaskHandler(taskId)}>
              <BiPlus size={"1.1em"} />
            </div>
            <div className={classNames(css.scopeSubtaskButton, css.scopeTaskButtonMin)}></div>
          </div>
          :
          shouldDisplaySubtaskMinus ?
            <div className={css.scopeSubtaskButtonsWrapper}>
              <div className={classNames(css.scopeSubtaskButton, css.scopeTaskButtonMin)} onClick={() => addScopeSubtaskHandler(taskId)}>
                <BiPlus size={"1.1em"} />
              </div>
              <div className={classNames(css.scopeSubtaskButton, css.scopeTaskButtonMin)} onClick={() => removeScopeSubtaskHandler(taskId)}>
                <BiMinus size={"1.1em"} />
              </div>
            </div>
            :
            <div className={css.scopeSubtaskButtonsWrapper}></div>
        }
      </div>
    );
  }

  const checkIfSubtaskNameExists = (taskId) => {
    let subtaskNames = Object.entries(values).filter((entryArray) => {
      return entryArray[0].includes("scopeTask"+taskId+"SubtaskNameInput");
    });
    return subtaskNames?.length > 0 ? true : false;
  }

  const generateScopeInput = ({id, name, time, subtasks}, addScopeTaskHandler, removeScopeTaskHandler, shouldDisplayTaskPlus, shouldDisplayTaskMinus) => {
    const sumPriceValue = scopeItems[id]['subtasksSumPrice'];
    const containsEmptySubtaskPrice = scopeItems[id]['containsEmptySubtaskPrice'];
    const estimatedTaskPriceMinValue = moneyValueAtLeast(
      intl.formatMessage(
        { id: 'NewOfferForm.minimumTaskPriceValue' },
        { min: sumPriceValue/100 }
      ),
      sumPriceValue
    );
    const estimatedTaskPricePreciseValue = moneyValuePrecise(
      intl.formatMessage(
        { id: 'NewOfferForm.preciseTaskPriceValue' },
        { min: sumPriceValue/100 }
      ),
      sumPriceValue
    );
    const estimatedPriceValidator = containsEmptySubtaskPrice ? composeValidators(estimatedTaskPriceMinValue) 
                                    : sumPriceValue > 0 ? composeValidators(valueRequired, estimatedTaskPricePreciseValue) : null;
    const estimatedPriceValidatorName = containsEmptySubtaskPrice ? "minVal" : sumPriceValue > 0 ? "preciseVal" : "requiredVal";

      return (
        <div key={"scopeTask"+id} className={css.scopeWrapper}>
          <div key={"scopeTaskInput"+id} className={css.scopeTaskWrapper}>
              {/* SCOPE TASK NAME */}
              <FieldTextInput
                  id={"scopeTaskNameInput"+id}
                  name={"scopeTaskNameInput"+id}
                  className={css.scopeTaskName}
                  type="text"
                  label={taskLabel}
                  placeholder={taskPlaceholder}
                  validate={values['scopeTaskTimeInput'+id]?.length > 0 || checkIfSubtaskNameExists(id) ? composeValidators(valueRequired) : null}
                  disabled={disabled}
              />
              {/* SCOPE TASK TIME */}
              <FieldTextInput
                id={"scopeTaskTimeInput"+id}
                name={"scopeTaskTimeInput"+id}
                className={css.scopeTaskTime}
                type="number"
                label={taskTimeLabel}
                placeholder={taskTimePlaceholder}
                validate={valueNotNegative}
                disabled={disabled}
              />
              {/* SCOPE TASK PRICE */}
              <FieldCurrencyInput
                id={"scopeTaskPriceInput"+id}
                name={"scopeTaskPriceInput"+id}
                className={css.scopeTaskPrice}
                currencyConfig={config.currencyConfig}
                label={taskPriceLabel}
                placeholder={taskPricePlaceholder}
                validate={isAdminOffer ? null : values['scopeTaskNameInput'+id]?.length > 0 ? estimatedPriceValidator : null}
                disabled={disabled}
                priceValidity={{sumPriceValue, containsEmptySubtaskPrice, estimatedPriceValidatorName}} // passed to trigger re-render
                loadable // custom flag to update price when loaded from outside
                loadedValue={loadedValues[`scopeTaskPriceInput${id}`]}
                loadedWatcher={currencyInputTaskWatcher}
              />
            {/* BUTTONS  */}
            {shouldDisplayTaskPlus ?
              <div className={css.scopeTaskButtonsWrapper}>
                <div className={classNames(css.scopeTaskButton, css.scopeTaskButtonMin)} onClick={addScopeTaskHandler}>
                  <BiPlusCircle size={"1.3em"} />
                </div>
                <div className={classNames(css.scopeTaskButton, css.scopeTaskButtonMin)}></div>
              </div>
              :
              shouldDisplayTaskMinus ?
                <div className={css.scopeTaskButtonsWrapper}>
                  <div className={classNames(css.scopeTaskButton, css.scopeTaskButtonMin)} onClick={addScopeTaskHandler}>
                    <BiPlusCircle size={"1.3em"} />
                  </div>
                  <div className={classNames(css.scopeTaskButton, css.scopeTaskButtonMin)} onClick={removeScopeTaskHandler}>
                    <BiMinusCircle size={"1.3em"} />
                  </div>
                </div>
                :
                <div className={css.scopeTaskButton}></div>
            }
          </div>
          {renderSubtasks(id, subtasks)}
        </div>
    );
  }

  const renderScopes = (scopes, addScopeTaskHandler, removeScopeTaskHandler) => {
    return scopes.map((item) => {
      const shouldDisplayTaskPlus = scopes.length === 1 && (item.id === scopes.length - 1);
      const shouldDisplayTaskMinus = scopes.length > 1 && (item.id === scopes.length - 1);
      return (
        generateScopeInput(item, addScopeTaskHandler, removeScopeTaskHandler, shouldDisplayTaskPlus, shouldDisplayTaskMinus)
      );
    });
  }

  const addScopeTaskHandler = () => {
    let lastScopeId = scopeItems[scopeItems.length - 1].id;
    setScopeItems([
      ...scopeItems,
      {
        id: lastScopeId + 1,
        name: '',
        time: null,
        subtasks: [
          {
            id: 0,
            name: '',
            time: null,
            price: null
          }
        ]
      }
    ]);
    refresh();
  };

  const removeScopeTaskHandler = () => {
    const itemId = scopeItems.length - 1;
    const numberOfSubtasks = scopeItems[itemId].subtasks.length;
    scopeItems.pop();
    setScopeItems([...scopeItems]);
    values['scopeTaskNameInput' + itemId] = '';
    values['scopeTaskTimeInput' + itemId] = null;
    values['scopeTaskPriceInput' + itemId] = null;
    for(let i = 0; i < numberOfSubtasks; i++) {
      values["scopeTask"+itemId+"SubtaskNameInput"+i] = "";
      values["scopeTask"+itemId+"SubtaskTimeInput"+i] = null;
      values["scopeTask"+itemId+"SubtaskPriceInput"+i] = null;
    }
    refresh();
  }

  return (
    <div>
      {renderScopes(scopeItems, addScopeTaskHandler, removeScopeTaskHandler)}
    </div>
  );
};

export default CustomOfferScopeInput;