import React from 'react';
import { DefaultChip } from '../../components/Chip/Chip';
import { FormattedMessage } from '../../util/reactIntl';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { GoVerified } from 'react-icons/go';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './ExpertPage.css';

const SectionVerifiedSoftwaresMaybe = props => {
  const { verifiedSoftwares, productSoftware } = props;

  const verifiedSoftwareLabels = verifiedSoftwares ? productSoftware.filter(s => verifiedSoftwares.includes(s.key)) : null;

  const renderVerifiedSoftwares = () => {
    return verifiedSoftwareLabels.map(software => {
      return (
        <DefaultChip className={css.chip} key={software.label}>
          {software.label}{'\u00A0'}{'\u00A0'}
          <GoVerified color={'#2ECC71'} />
        </DefaultChip>
      );
    })
  }
  
  return verifiedSoftwares?.length > 0 ? (
    <div className={css.sectionVerifiedSoftwaresWrapper}>
      <div className={css.smallPrintHeading}>
        <h2 className={css.smallPrintHeading}>
          <FormattedMessage id="ExpertPage.verifiedSoftwaresTitle" />
        </h2>
        <Tooltip
          interactive
          title={
            <FormattedMessage id={`ExpertPage.verifiedSoftwaresTooltip`} values={{ br: <br /> }} />
          }
        >
          <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
        </Tooltip>
      </div>
      <div className={css.sectionTagsWithMargin}>
        {verifiedSoftwares ? renderVerifiedSoftwares() : null}
      </div>
    </div>
  ) : null;
};

const mapStateToProps = state => {
  const {
    productSoftware
  } = state.marketplaceData;
  return {
    productSoftware
  }
}

export default compose(
  connect(mapStateToProps)
)(SectionVerifiedSoftwaresMaybe);