import React from 'react';
import { DefaultChip, PrimaryChip } from '../../components/Chip/Chip';
import { NamedLink } from '../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './ListingPage.css';

const SectionTagsMaybe = props => {
  const { softwares, skills, serviceCategories, productSkills, productSoftware } = props;

  const slicedSoftwares = softwares ? softwares.slice(0, 9) : [];
  const softwaresLabels = productSoftware.filter(s => slicedSoftwares.includes(s.key));

  const slicedSkills = skills ? skills.slice(0, 9) : [];
  const skillsLabels = productSkills.filter(s => slicedSkills.includes(s.key));

  const renderSoftwares = () => {
    return softwaresLabels.map(software => {
      return (
        <NamedLink
          name={`SearchPage_experts`}
          to={{
            search: `pub_softwares=${software.key}`,
          }}
        >
          <DefaultChip className={css.chip} key={software.label}>
            {software.label}
          </DefaultChip>
        </NamedLink>
      );
    });
  };

  const renderSkills = () => {
    return skillsLabels.map(software => {
      return (
        <NamedLink
          name={`SearchPage_experts`}
          to={{
            search: `pub_skills=${software.key}`,
          }}
        >
          <PrimaryChip className={css.chip} key={software.label}>
            {software.label}
          </PrimaryChip>
        </NamedLink>
      );
    });
  };

  const renderServiceCategories = () => {
    let serviceCategoriesList = [];
    serviceCategoriesList = serviceCategories.map((item, index) => {
      return <PrimaryChip key={'serviceCategory' + index}>{item}</PrimaryChip>;
    });
    return serviceCategoriesList;
  };

  return softwares || skills ? (
    <div className={css.sectionTagsWithMargin}>
      {serviceCategories ? renderServiceCategories() : null}
      {skills ? renderSkills() : null}
      {softwares ? renderSoftwares() : null}
    </div>
  ) : null;
};

const mapStateToProps = state => {
  const { productSoftware, productSkills } = state.marketplaceData;
  return {
    productSoftware,
    productSkills,
  };
};

export default compose(connect(mapStateToProps))(SectionTagsMaybe);
