import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal, PrimaryButton, IconSpinner } from '../../components';
import { PaymentForm } from '../../forms';

import css from './PaymentModal.css';

const PaymentModal = props => {
  const {
    className,
    rootClassName,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    currentUserStripeAccounts,
    createStripeCustomerInProgress,
    createStripePaymentInProgress,
    currentUser,
    paymentUrl,
    userInvoices,
    unpaidInvoice,
    fetchingInvoiceInProgress,
    version,
    amount,
  } = props;

  var isInvoiceUnpaid = unpaidInvoice;
  const classes = classNames(rootClassName || css.root, className);

  if (fetchingInvoiceInProgress) {
    return (
      <Modal
        id="PaymentModal"
        containerClassName={classes}
        contentClassName={css.modalContent}
        isOpen={isOpen}
        onClose={onCloseModal}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <IconSpinner />
      </Modal>
    );
  }

  const { address, currency, tax_info, name } = currentUserStripeAccounts?.[0] ?? {};

  const { line1, line2, postal_code, ...restOfAddressInfo } = address ?? {};

  let counterOpenInvoice=0;
for(const _invoice of userInvoices || []){
  if(_invoice.status==='open'){
   counterOpenInvoice++;
  }
}



  if (paymentUrl === undefined) {
    isInvoiceUnpaid = true;
  }
  if (userInvoices) {
    if (counterOpenInvoice>=2) {
      var unpaidUrl = userInvoices.find(invoice => invoice.status === 'open').hosted_invoice_url;
    }
  }
  const initialValues = {
    addressLine1: line1,
    addressLine2: line2,
    postal: postal_code,
    depositBalance: amount / 100,
    currency: currency ?? 'usd',
    vatNumber: tax_info?.tax_id,
    name: name,
    ...restOfAddressInfo,
  };

  return (
    <Modal
      id="PaymentModal"
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      {paymentUrl !== '' || paymentUrl === undefined || isInvoiceUnpaid === true ? (
        isInvoiceUnpaid === false || isInvoiceUnpaid === undefined ? (
          <div>
            <div className={css.invoiceFailInfo}>
              <FormattedMessage id="PaymentModal.alreadyCreatedInvoice" />
            </div>
            <PrimaryButton onClick={() => (window.location.href = paymentUrl)}>
              <FormattedMessage id="PaymentModal.goToPayment" />
            </PrimaryButton>
          </div>
        ) : unpaidUrl !== undefined ? (
          <div>
            <div className={css.invoiceFailInfo}>
              <FormattedMessage id="PaymentModal.alreadyOpenInvoice" />
            </div>
            <PrimaryButton onClick={() => (window.location.href = unpaidUrl)}>
              <FormattedMessage id="PaymentModal.goToPayment" />
            </PrimaryButton>
          </div>
        ) : (
          onCloseModal()
        )
      ) : (
        <>
          <PaymentForm
            version={version}
            amount={amount}
            currentUser={currentUser}
            createStripeCustomerInProgress={createStripeCustomerInProgress}
            createStripePaymentInProgress={createStripePaymentInProgress}
            initialValues={initialValues}
            onSubmit={onSubmit}
          />
        </>
      )}
    </Modal>
  );
};

const { string } = PropTypes;

PaymentModal.defaultProps = {
  className: null,
  rootClassName: null,
};

PaymentModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(PaymentModal);
