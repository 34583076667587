/* eslint-disable */
import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton, NamedLink } from '../../components';
import { withRouter } from 'react-router-dom';

import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import css from './JobApplicationPanel.css';

export class JobApplicationPanelComponent extends Component {
  render() {
    const {
      hasExpertProfile,
      transactionRole,
      isTransaction,
      messageFormatting,
      expertStackMatching,
      isOwnListing,
      submitButtonWrapperClassName,
      expertListingIsPendingApproval,
      onAskForDetails,
      currentUser,
      listing,
      history
    } = this.props;

    const transactionMessageFormatting = isTransaction ? '' : messageFormatting;

    const termsLink = (
      <NamedLink name="TermsOfServicePage" className={css.legalLink}>
        <FormattedMessage id="JobApplicationForm.termsOfUse" />
      </NamedLink>
    );

    const submitButtonClasses = classNames(submitButtonWrapperClassName || css.submitButtonWrapper);

    const newButtonClasses = classNames(
      submitButtonWrapperClassName || css.submitButtonWrapper,
      css.newSubmitButtonWrapper
    );

    const sendOfferTooltip = (
      <>
        {isTransaction && transactionRole === 'customer' ? (
          <FormattedMessage id={`BookingTimeForm.updateOffer`} />
        ) : (
          <FormattedMessage id={`BookingTimeForm.sendOffer${transactionMessageFormatting}`} />
        )}
        {messageFormatting !== 'Ticket' && !isOwnListing ? (
          <Tooltip
            interactive
            title={
              <FormattedMessage
                id={`JobApplicationForm.sendOfferExplanation${messageFormatting}`}
                values={{ br: <br />, terms: termsLink }}
              />
            }
          >
            <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
          </Tooltip>
        ) : null}
      </>
    );
    

    const pmCallTrigger = () => {
    history.push(
      createResourceLocatorString(
        'ExpertPage',
        routeConfiguration(),
        {
          id: process.env.REACT_APP_ADMIN_BOOKING_LISTING_ID,
          slug: createSlug('Michael'),
          category: 'experts',
        },
        {}
      ),
      { openBookModal: true }
    );
  };


    const sendNewOfferTooltip = (
      <>
        <FormattedMessage id={`BookingTimeForm.sendNewOffer`} />
        <Tooltip
          interactive
          title={
            <FormattedMessage
              id={`JobApplicationForm.sendNewOfferExplanation`}
              values={{ br: <br />, terms: termsLink }}
            />
          }
        >
          <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
        </Tooltip>
      </>
    );

    const askForDetails = (
      <>
        <FormattedMessage id={`BookingTimeForm.applyForProject`} />
      </>
    );


    const jobIsPremium = listing?.attributes?.publicData?.isPremium ? true : false
    const isVerified = currentUser.attributes.profile.publicData?.isVerified ? true : false

    let showApplication

    if(isVerified){
        showApplication = true
    } else if(!jobIsPremium && !isVerified && hasExpertProfile){
        showApplication = true
    } else {
      showApplication = false
    }

    return (
      <div>
        {/* SEND NEW OFFER FOR JOBS (TRANSACTION) */}
        {isTransaction && transactionRole === 'customer' ? (
          <div className={newButtonClasses}>
            <SecondaryButton
              onClick={() => {
                this.props.handleNewJobOffer();
              }}
            >
              {sendNewOfferTooltip}
            </SecondaryButton>
          </div>
        ) : null}
        {/* SEND OFFER FOR JOBS */}

        {!isOwnListing &&
        !isTransaction &&
        showApplication ? (
          <>
            <div className={submitButtonClasses}>
              <PrimaryButton
                onClick={() => {
                  this.props.handleNewJobOffer();
                }}
                // disabled={
                //   !hasExpertProfile || isOwnListing || !expertStackMatching
                // !currentUser.attributes.profile.publicData?.hasStripeConnected
                // }
              >
                {sendOfferTooltip}
              </PrimaryButton>
            </div>

            {/* {!currentUser.attributes.profile.publicData?.hasStripeConnected && (
              <div className={css.smallPrintWarning}>
                <FormattedMessage id="BookingPanel.noStripeConnected" />
              </div>
            )} */}
          </>
        ) : null}
        {/* ASK FOR DETAILS (EXPERT -> CLIENT) */}

        {!isOwnListing && !isTransaction && !currentUser.attributes.profile.publicData?.isVerified && (
          <div className={submitButtonClasses}>
            {!expertStackMatching ? (
              <>
              <NamedLink name="ExamsListPage" className={css.buttonLink}>
                <SecondaryButton>
                  <FormattedMessage id="Get certified" />
                </SecondaryButton>
              </NamedLink>
              <div className={css.bookACall}>
                  <div>Have any problems going through your needs?</div>
                  <a style={{textDecoration:"underline"}} onClick={pmCallTrigger}> Book a call with our professional</a>
                </div>
                </>
            ) : null}
          </div>
        )}

        {expertListingIsPendingApproval && !isOwnListing ? (
          <div className={css.smallPrintWarning}>
            <FormattedMessage id="BookingPanel.expertProfilePendingApproval" />
          </div>
        ) : !hasExpertProfile && !isOwnListing ? (
          <div className={css.smallPrintWarning}>
            <FormattedMessage
              id="JobApplicationForm.expertProfileReminder"
              values={{ listingType: 'projects' }}
            />
            <div>
              <NamedLink name="NewListingExpertPage">
                <FormattedMessage id="JobApplicationForm.createExpertProfile" />
              </NamedLink>
            </div>
          </div>
        ) : !expertStackMatching && !isOwnListing ? (
          <div className={css.smallPrintWarning}>
            {/* <FormattedMessage
              id="BookingPanel.expertProfileNotMatching"
              values={{ listingType: 'project' }}
            /> */}
          </div>
        ) : null}
      </div>
    );
  }
}

JobApplicationPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  isOwnListing: false,
};

JobApplicationPanelComponent.propTypes = {
  rootClassName: string,
  className: string,
  isOwnListing: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const JobApplicationPanel = compose(withRouter, injectIntl)(JobApplicationPanelComponent);
JobApplicationPanel.displayName = 'JobApplicationPanel';

export default JobApplicationPanel;
