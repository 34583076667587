/* eslint-disable */
import uuid from 'react-uuid';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { createUserWithIdp, checkIfNewUserInvitedAndUpdateContacts } from '../util/api';
import { storableError } from '../util/errors';
import { addInboxTokenToUser } from '../util/api';
import * as log from '../util/log';
import {
  adjustBalance,
  sendNotification,
  createStripeCustomer,
  generateInvoice,
  createStripeViban,
  getUserDataById,
} from '../util/api';

import { sendCvFromFreelancer } from '../util/triggerMail';

import { GrStatusCriticalSmall } from 'react-icons/gr';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password, quicklogin = false, jobData = null, ...rest) => (
  dispatch,
  getState,
  sdk
) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.

  return sdk
    .login({ username, password })
    .then(res => {
      dispatch(loginSuccess());
      sdk.currentUser.updateProfile({
        privateData: {
          lastLogin: new Date().toISOString(),
        },
      });

      window.dataLayer.push({
        event: 'pushEventGTM',
        eventCategory: 'Form',
        eventAction: 'Login',
      });
    })
    .then(() => dispatch(fetchCurrentUser()))
    .then(() => {
      if (quicklogin) {
        if (jobData.status == 'pendingApproval') {
          jobData.status = 'pending-approval';
        }
        window.location.href = '/jobs/' + jobData.slug + '/' + jobData.jobId + '/' + jobData.status;
      }
    })
    .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => async (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const { email, password, firstName, lastName, phoneNumber, title, cvData } = params;

  const startTrial = new Date();
  const endTrial = new Date(startTrial.setDate(startTrial.getDate() + 14));
  const customer = await createStripeCustomer({ name: firstName + ' ' + lastName, email: email });
  let viban = {};
  try {
    viban = await createStripeViban({
      customerId: customer.id,
      name: firstName + ' ' + lastName,
      email: email,
    });
  } catch (e) {}

  let accountType = {};

  if (params.type === 'freelancer') {
    accountType = {
      type: 'freelancer',
      isPremium: false,
      isVerified: false,
    };
  } else if (params.type === 'customer') {
    accountType = {
      type: 'customer',
      isPremium: false,
      isVerified: false,
    };
  }

  const publicData = {
    subscription: {
      start: Date().toString(),
      end: endTrial.toString(),
      isTrial: true,
      version: 'free',
      confirmed: true,
    },
    userMailCampaign: 0,
    userMailPendingApprovalCampaign: 0,
    userReactivationMail: 0,
  };

  Object.assign(publicData, accountType);

  const privateData = {
    referral: {
      active: true,
      code: uuid(),
      usedBy: null,
    },
    stripeAccount: [customer.id],
    stripeViban: viban,
  };

  const protectedData = {
    phoneNumber,
  };

  const createUserParams = {
    email,
    password,
    firstName,
    lastName,
    privateData,
    publicData,
    protectedData,
  };
  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  let userId;
  // const _state=getState();

  return sdk.currentUser
    .create(createUserParams) // ShareTribe newuser creation if false will throw error
    .then(result => {
      const createdUserId = result.data.data.id.uuid;
      userId = createdUserId;
      addInboxTokenToUser({
        userId: createdUserId,
        firstName: firstName,
        lastName: lastName,
      }).then(({ userToken }) => {
        checkIfNewUserInvitedAndUpdateContacts(userToken, { userMail: email });

        sendNotification(userToken, {
          toUserId: createdUserId,
          from: process.env.REACT_APP_PROJECT_MANAGER_USER_ID, // ok
          title: `Welcome ${firstName}`, // ok
          subtitle: `What's next? Create a project for free.`, // ok
          status: `What's next?`,
          redirectUrl: `\\center/getStarted`, // ok
          type: `PROCESS`, // ok
          shouldDisappear: false,
        })
          .then(data => {
            console.log(data);
          })
          .catch(e => {
            console.log(e);
          });
        // Obsługa wysyłania powiadomień po aplikacji po CV
        if (cvData) {
          getUserDataById({ id: cvData.authorid }).then(res => {
            const authorEmail = res.email;

            let dataToSend = {
              mail: authorEmail,
              message: cvData.values.offer,
              link: cvData.data,
            };
            sendCvFromFreelancer(dataToSend);
          });

          // Wysyłka powiadomienia do autora Posta
          sendNotification(userToken, {
            toUserId: cvData.authorid,
            from: process.env.REACT_APP_PROJECT_MANAGER_USER_ID, // ok
            title: `New application`, // ok
            subtitle: `We send you new application to your email adress`, // ok
            status: `New Application`,
            redirectUrl: cvData.data, // ok
            type: `CVAPPLICATION`, // ok
            shouldDisappear: false,
          });
          // Wysłanie powiadomienia do aplikującego
          sendNotification(userToken, {
            toUserId: createdUserId,
            from: process.env.REACT_APP_PROJECT_MANAGER_USER_ID, // ok
            title: `Application was sended`, // ok
            subtitle: `What's next? Becoma an expert and ...`, // ok
            status: `What's next?`,
            redirectUrl: '/l/draft/00000000-0000-0000-0000-000000000000/new/expert/quick', // ok
            type: `CVAPPLICATION`, // okr
            shouldDisappear: false,
          });

          // sdk.users.show({ id: cvData.authorid }).then(res => {
          //   debugger;
          //   console.log(res);

          //   //           // });

          // Tutaj wrzucić triggerowanie maila
        }

        adjustBalance(userToken, {
          amount: -100,
          currency: 'usd',
          customer: customer.id,
        });
        adjustBalance(userToken, {
          amount: 100,
          currency: 'usd',
          customer: customer.id,
        });

        sdk
          .login({
            username: email,
            password: password,
          })
          .then(loginRes => {
            console.log('Login successful.');
          });
      });

      dispatch(signupSuccess());
      window.dataLayer.push({
        event: 'pushEventGTM',
        eventCategory: 'Form',
        eventAction: 'Signup',
      });
    })
    .then(() => dispatch(login(email, password)))
    .then(creationResponse => {
      sdk.ownListings.query({}).then(response => {
        // const projectsArray = response.data.data ?? []

        const dataInvoice = {
          currency: 'usd',
          invoiceData: [
            {
              price: 30000,
              projectName: 'Plan - Deposit',
            },
          ],
          userId: userId,
          depositListingId: null,
          version: 'task',
          isDeposit: true,
          hourlyPlanId: null,
          customer: customer.id,
          isPoland: false,
          previousBalance: -30000,
        };

        if (title.length) {
          //generateInvoice(dataInvoice);
          //console.dir(['singup',projectsArray.length,customer,userId,createUserParams],{depth:10});
        }
      });
      return creationResponse;
    })
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
        privateData: params.privateData,
        publicData: params.publicData,
      });
    });
};

export const signupWithIdp = params => async (dispatch, getState, sdk) => {
  const startTrial = new Date();
  const endTrial = new Date(startTrial.setDate(startTrial.getDate() + 14));

  const publicData = {
    subscription: {
      start: Date().toString(),
      end: endTrial.toString(),
      isTrial: true,
      version: 'free',
      confirmed: true,
    },
    userMailCampaign: 0,
    userMailPendingApprovalCampaign: 0,
    userReactivationMail: 0,
  };
  const privateData = {
    referral: {
      active: true,
      code: uuid(),
      usedBy: null,
    },
  };

  dispatch(confirmRequest());
  return createUserWithIdp({ ...params, privateData, publicData })
    .then(res => {
      return dispatch(confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      return dispatch(confirmError(storableError(e)));
    });
};
