/* eslint-disable */
import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string, oneOfType } from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import {
  txIsExpertOfferSent,
  txIsBookingCanceled,
  txIsBookingPaymentConfirmed,
  txIsExpertOfferCanceled,
  txIsAccepted,
  txIsPaid,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import { LISTING_CATEGORY_EXPERT, LISTING_CATEGORY_JOB } from '../../util/types';
import {
  Avatar,
  BookingPanel,
  NamedLink,
  UserDisplayName,
  AcceptMeetingRequest,
  DetailCardHeadingsMaybe,
  AddressLinkMaybe,
  SecondaryButton,
  Modal,
  PrimaryButton,
} from '../../components';
import NegotiatedOfferDetails from './NegotiatedOfferDetails';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';

import { sendServiceDiscussionCounteroffer, sendProjectCounteroffer } from '../../util/triggerMail';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FaRegHandshake } from 'react-icons/fa';
import { negotiateOffer, editAdminOffer } from '../../util/api';
import { compareData, createUpdateBody } from '../../util/stripe';

// These are internal components that make this file more readable.
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import AttachmentsPanel from './AttachmentsPanel.js';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_OFFER_SENT,
  HEADING_PAID,
  HEADING_BOOKING_REQUESTED,
  HEADING_BOOKING_CANCELED,
  HEADING_CANCELED_OFFER,
  HEADING_ACCEPTED_NOT_PAID,
} from './PanelHeading';
import { parseScope } from './scopeParser';

import css from './TransactionPanel.css';

const MOBILE_BREAKPOINT = 768;
const { Money } = sdkTypes;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  let currentOfferAuthorNameString = userDisplayNameAsString(currentCustomer, '');

  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
    currentOfferAuthorNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNegotiationModalOpen: false,
      isFreeBooking: false,
      isPaidBooking: false,
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      adminEditMode: false,
      adminEditOfferNumber: 0,
      generatingPDFInProgress: false,
      paymentModalOpen: false,
    };
    this.isMobSaf = false;

    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.onSubmitNegotiationAnswer = this.onSubmitNegotiationAnswer.bind(this);
    this.changeIsNegotiationModalOpen = this.changeIsNegotiationModalOpen.bind(this);
    this.withCommission = this.withCommission.bind(this);
  }

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
    this.handleResize(); // initial state width and height
    window.addEventListener('resize', this.handleResize);
  }

  changeIsNegotiationModalOpen(isOpen) {
    this.setState({
      isNegotiationModalOpen: isOpen,
    });
  }

  withCommission(price, commission) {
    return Math.ceil(price / (1 - commission));
  }

  onSubmitNegotiationAnswer(values, form) {
    const {
      negotiationType,
      commission,
      authorRole,
      transactionId,
      estimatedTime,
      currentCustomerIsAdmin,
    } = values;
    const { transaction, transactionRole } = this.props;
    const { scope } = parseScope(
      values,
      commission,
      authorRole,
      negotiationType,
      currentCustomerIsAdmin
    );
    const priceSummary =
      authorRole === 'expert' && !currentCustomerIsAdmin
        ? this.withCommission(values['estimatedPriceSummary']?.amount / 100, commission)
        : values['estimatedPriceSummary']?.amount / 100;
    const body = {
      transactionId: transactionId,
      scope: scope,
      priceSummary: priceSummary,
      timeSummary: estimatedTime,
      answerDate: new Date().toString(),
      answerAuthor: authorRole,
      isAccepted: false,
      isAcceptedByClient: false,
      isAcceptedByExpert: false,
    };
    if (this.state.adminEditMode) {
      body['offerNumber'] = this.state.adminEditOfferNumber;
      editAdminOffer(body).then(res => {
        form.reset();
        this.setState({ isNegotiationModalOpen: false });
        if (typeof window !== 'undefined') {
          window.location.reload();
        }
      });
    } else {
      negotiateOffer(body).then(res => {
        const saleLink = createResourceLocatorString(
          'SaleDetailsPage',
          routeConfiguration(),
          { id: transactionId },
          {}
        );
        const orderLink = createResourceLocatorString(
          'OrderDetailsPage',
          routeConfiguration(),
          { id: transactionId },
          {}
        );
        if (negotiationType === 'service') {
          sendServiceDiscussionCounteroffer(transaction, saleLink, orderLink, transactionRole);
        } else if (negotiationType === 'offer') {
          sendProjectCounteroffer(transaction, saleLink, orderLink, transactionRole);
        }
        form.reset();
        this.setState({ isNegotiationModalOpen: false });
        if (typeof window !== 'undefined') {
          window.location.reload();
        }
      });
    }
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      expertListing,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onFinishBooking,
      onSubmitBookingRequest,
      onSubmitRedirect,
      isAdmin,
      isUser,
      currentCustomerStripe,
      currentProviderStripe,
      relatedExpertOffers,

      onFetchListingHasInvoice,
      listingInvoiceUrl,

      depositListingId,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const isViewer = transactionRole === 'viewer';
    const isSupportPlan = currentListing.attributes?.title === 'SoftwareSupp Project Manager';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;

    const hasExpertListing = !!(
      expertListing &&
      expertListing.listingId &&
      expertListing.listingId.included
    );

    const handleBookingSubmitJobOffer = () => {
      const jobId = currentListing?.id?.uuid;
      this.props.history.push(
        createResourceLocatorString('NewOfferPage', routeConfiguration(), { id: jobId }, {}),
        { jobListing: currentListing }
      );
    };

    const stateDataFn = tx => {
      if (txIsBookingPaymentConfirmed(tx)) {
        return {
          headingState: HEADING_BOOKING_REQUESTED,
          showWhereby: true,
          showBookingFinished: isCustomer,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsBookingCanceled(tx)) {
        return {
          headingState: HEADING_BOOKING_CANCELED,
        };
      } else if (txIsExpertOfferCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED_OFFER,
          showScope: true,
        };
      } else if (txIsExpertOfferSent(tx)) {
        return {
          headingState: HEADING_OFFER_SENT,
          showDetailCardHeadings: true,
          showBookingPanel: isProvider || isCustomer,
          showPaymentMissing: !currentCustomerStripe && isProvider,
          showAcceptOffer: isProvider && currentCustomerStripe,
          isPaidBooking: isProvider && currentCustomerStripe,
          showDownloadPDF: true,
          showScope: true,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: tx?.attributes.metadata?.isPaid ? HEADING_PAID : HEADING_ACCEPTED_NOT_PAID,
          showPayButtonMessage: tx?.attributes.metadata?.isPaid ? 'paid' : 'accepted',
        };
      } else if (txIsPaid(tx)) {
        return {
          headingState: HEADING_PAID,
          showScope: true,
          showPayButtonMessage: 'paid',
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const defaultCommission = stateData.headingState === 'service-discussed' ? 1 / 3 : 0.5;
    const commission = currentListing?.attributes?.publicData?.commission
      ? currentListing.attributes.publicData.commission
      : defaultCommission;

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      // otherUserDisplayName,
      // otherUserDisplayNameString,
      // currentOfferAuthorNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitTranslationKey = 'TransactionPanel.cost';

    const price =
      currentListing.attributes.price && currentListing.attributes.price instanceof Money
        ? currentListing.attributes.price
        : currentListing.attributes.price
        ? new Money(currentListing.attributes.price.amount, config.currency)
        : null;
    const bookingSubTitle = price
      ? ` ${intl.formatMessage({ id: unitTranslationKey })} ${formatMoney(intl, price)} `
      : '';

    // protectedData is maintained for older offers
    const originalOfferSrc =
      currentTransaction?.attributes?.protectedData?.scope !== undefined
        ? 'protectedData'
        : 'metadata';
    const originalOfferData =
      originalOfferSrc === 'protectedData'
        ? currentTransaction?.attributes?.protectedData
        : currentTransaction?.attributes?.metadata?.originalOffer;

    const offerIsFromAdmin = !!originalOfferData?.isAdmin;
    const estimatedPriceMin = originalOfferData?.estimatedPriceMin;
    const estimatedPriceMax = originalOfferData?.estimatedPriceMax;
    const estimatedPriceRange = !isNaN(estimatedPriceMax)
      ? '$' + estimatedPriceMin + ' - $' + estimatedPriceMax
      : '$' + estimatedPriceMin;
    const estimatedTimeMin = originalOfferData?.estimatedTimeMin;
    const estimatedTimeMax = originalOfferData?.estimatedTimeMax;
    const estimatedTimeRange =
      isNaN(estimatedTimeMin) && isNaN(estimatedTimeMax)
        ? undefined
        : !isNaN(estimatedTimeMin) && isNaN(estimatedTimeMax)
        ? estimatedTimeMin + ' hours'
        : isNaN(estimatedTimeMin) && !isNaN(estimatedTimeMax)
        ? estimatedTimeMax + ' hours'
        : estimatedTimeMin + ' - ' + estimatedTimeMax + ' hours';
    const offerPaymentDetails = originalOfferData?.offerPaymentDetails;
    const offerContact = originalOfferData?.offerContact;
    const offerContactMail = originalOfferData?.offerContactMail;
    const offerContactPhone = originalOfferData?.offerContactPhone;
    const offerValidity = originalOfferData?.offerValidity;
    const offerContractInfo = originalOfferData?.offerContractInfo;

    const listingCategory = currentListing?.attributes.publicData?.category;
    const isExpertListing = listingCategory === LISTING_CATEGORY_EXPERT;
    const isJobListing = listingCategory === LISTING_CATEGORY_JOB;

    const messageFormatting = isJobListing ? '' : isExpertListing ? 'Expert' : 'Ticket';

    const firstImage =
      hasExpertListing && expertListing && expertListing.listingId
        ? expertListing.listingId.included[1]
        : currentListing.images && currentListing.images.length > 0
        ? currentListing.images[0]
        : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={false}
        completeInProgress={this.state.paymentInProgress}
        setPaymentModalOpen={() => {
          if (!listingInvoiceUrl && (!isSupportPlan || depositListingId)) {
            onFetchListingHasInvoice(currentTransaction.listing.id.uuid, !!depositListingId);
          }
          this.setState({ paymentModalOpen: true });
        }}
      />
    );

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const termsLink = (
      <NamedLink name="TermsOfServicePage" className={css.legalLink}>
        <FormattedMessage id="JobApplicationForm.termsOfUse" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const softwares = isProvider
      ? hasExpertListing
        ? expertListing.listingId.data[0].attributes.publicData.softwares
        : null
      : currentListing.attributes.publicData
      ? currentListing.attributes.publicData.softwares
      : null;
    const currentCustomerIsAdmin = currentCustomer.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;
    const currentProviderIsAdmin = currentProvider.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

    const currentUserIsAdmin = currentUser?.id?.uuid === process.env.REACT_APP_ADMIN_USER_ID;

    const listingId =
      currentCustomerIsAdmin && isProvider
        ? process.env.REACT_APP_ADMIN_PM_LISTING_ID
        : isExpertListing
        ? currentListing.id.uuid
        : isProvider && hasExpertListing
        ? expertListing.listingId.data[0].id.uuid
        : currentListing.id.uuid;

    const isAdminListing = listingId === process.env.REACT_APP_ADMIN_PM_LISTING_ID;

    const showUser =
      currentCustomerIsAdmin && (isProvider || isViewer)
        ? currentCustomer
        : currentProviderIsAdmin
        ? currentProvider
        : isExpertListing
        ? currentProvider
        : isProvider || isViewer
        ? currentCustomer
        : currentProvider;

    const listingTitleHeading = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentCustomerIsAdmin && (isProvider || isViewer)
      ? 'SoftwareSupp Project Manager'
      : currentProviderIsAdmin
      ? 'SoftwareSupp Project Manager'
      : isExpertListing
      ? currentListing.attributes.title
      : (isProvider || isViewer) && expertListing && expertListing.listingId.data[0]
      ? expertListing.listingId.data[0].attributes.title
      : currentListing.attributes.title;

    const expertListingSubtitle = expertListing
      ? expertListing?.listingId?.data[0]?.attributes.publicData.expertise
      : currentListing?.attributes?.publicData?.expertise;
    const firstMessageInOffer = messages?.length > 0 ? messages[0].attributes.content : null;
    const offerDescription =
      currentTransaction?.attributes?.metadata?.originalOffer?.description ?? firstMessageInOffer; // firstMessageInOffer for legacy offers

    // experts' offers for PDF Admin Offer
    // let providersArray;
    // providersArray = relatedExpertOffers?.map((exOffer) => {
    //   const expertOfferName = exOffer.customer.attributes.profile.displayName.split(" ")[0];
    //   const expertOfferPrice = exOffer?.attributes?.payinTotal ? formatMoney(intl, new Money(exOffer?.attributes?.payinTotal?.amount, config.currency)) : "";
    //   const expertOfferId = exOffer.id.uuid; // for link
    //   return ({name: expertOfferName, id: expertOfferId, price: expertOfferPrice})
    // });
    const shouldDisplayOfferDetails = stateData.showScope;
    const negotiationHistory = currentTransaction?.attributes?.metadata?.negotiationHistory;

    // currentTransactionOffer is data about offer details that will be transfered to new transaction after acceptance
    // Expert can accept only 1 clients' offer. Current transaction price by default is equal to first offer.
    // However if clients' offer was accepted it becomes transaction price. If there is no accepted offer then
    // current transaction price is equal to last experts offer.
    let currentTransactionOffer = {
      scope: originalOfferData?.scope,
      price: currentTransaction.attributes.payinTotal,
      time: offerIsFromAdmin
        ? originalOfferData?.estimatedTimeMin
        : originalOfferData?.estimatedTime,
    };
    if (negotiationHistory?.length > 0) {
      const acceptedClientOffer = negotiationHistory.filter(answer => {
        return answer.isAccepted === true && answer.answerAuthor === 'client';
      });
      const expertOffers = negotiationHistory.filter(answer => {
        return answer.answerAuthor === 'expert';
      });
      if (acceptedClientOffer.length > 0) {
        currentTransactionOffer['price'] = new Money(
          acceptedClientOffer[0].priceSummary * 100,
          config.currency
        );
        currentTransactionOffer['time'] = acceptedClientOffer[0].timeSummary;
        currentTransactionOffer['scope'] = acceptedClientOffer[0].scope;
      } else if (expertOffers.length > 0) {
        const lastExpertOfferPrice = expertOffers[expertOffers.length - 1].priceSummary;
        currentTransactionOffer['price'] = new Money(lastExpertOfferPrice * 100, config.currency);
        currentTransactionOffer['time'] = expertOffers[expertOffers.length - 1].timeSummary;
        currentTransactionOffer['scope'] = expertOffers[expertOffers.length - 1].scope;
      }
    }

    const onEditAdminOfferScope = chosenDateValue => {
      const chosenOfferNumber = chosenDateValue ?? 0;
      this.changeIsNegotiationModalOpen(true);
      this.setState({
        adminEditMode: true,
        adminEditOfferNumber: chosenOfferNumber,
      });
    };

    let relatedOffers;
    if (currentCustomerIsAdmin) {
      relatedOffers = relatedExpertOffers?.map(exOffer => {
        if (exOffer?.attributes?.metadata?.blocked) {
          return null;
        }
        const expertOfferUser = exOffer.customer;
        const expertOfferName =
          exOffer.customer.attributes.profile.displayName?.split(' ')[0] ?? 'Expert';
        const expertOfferPrice = exOffer?.attributes?.payinTotal
          ? formatMoney(intl, new Money(exOffer?.attributes?.payinTotal?.amount, config.currency))
          : '';
        const expertOfferId = exOffer.id.uuid; // for link
        return (
          <div className={css.relatedOfferWrapper}>
            <div className={css.relatedOfferExpertWrapper}>
              <div className={css.relatedOfferAvatar}>
                <Avatar
                  user={expertOfferUser}
                  className={css.avatarDesktop}
                  initialsClassName={css.initialsDesktop}
                  disableProfileLink
                />
              </div>
              <div className={css.relatedOfferText}>
                <div className={css.relatedOfferName}>{expertOfferName}</div>
                <div className={css.relatedOfferPrice}>{expertOfferPrice}</div>
              </div>
            </div>
            <div className={css.relatedOfferButtonsWrapper}>
              <div className={css.relatedOfferButton}>
                <NamedLink
                  className={css.itemLink}
                  name={'SaleDetailsPage'}
                  params={{ id: expertOfferId }}
                >
                  {'View'}
                </NamedLink>
              </div>
              <div className={css.relatedOfferButton}>
                <NamedLink
                  className={css.itemLink}
                  name={'SaleDetailsPage'}
                  params={{ id: expertOfferId }}
                >
                  {'Accept'}
                </NamedLink>
              </div>
            </div>
          </div>
        );
      });
    }
    const pmCallTrigger = () => {
      const { history } = this.props;
      history.push(
        createResourceLocatorString(
          'ExpertPage',
          routeConfiguration(),
          {
            id: process.env.REACT_APP_ADMIN_BOOKING_LISTING_ID,
            slug: createSlug('Michael'),
            category: 'experts',
          },
          {}
        ),
        { openBookModal: true }
      );
    };

    const expertCallTrigger = () => {
      const { history } = this.props;
      history.push(
        createResourceLocatorString(
          'ExpertPage',
          routeConfiguration(),
          {
            id: expertListing ? expertListing.listingId.data[0]?.id?.uuid : listingId,
            slug: createSlug(
              expertListing ? expertListing.listingId.data[0]?.attributes?.title : listingTgitle
            ),
            category: 'experts',
          },
          {}
        ),
        { openBookModal: true }
      );
    };
    const shouldShowFeedSection =
      stateData?.headingState !== 'offer-sent' && stateData?.headingState !== 'service-discussed';
    const expertUserId = expertListing?.listingId?.data[0]?.relationships?.author?.data?.id?.uuid;

    return (
      <div className={classes}>
        <Row className={'fullHeight gx-0'}>
          <Col md={9}>
            <div className={classNames(css.txInfo, offerIsFromAdmin ? css.txInfoNoMargin : null)}>
              {/* <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperMobile}
                listingTitle={listingTitle}
                listingCategory={listingCategory}
                provider={showUser}
                listingId={listingId}
                listingDeleted={listingDeleted}
              /> */}

              {(expertListing?.listingId.data[0]?.id?.uuid ||
                currentListing?.attributes.publicData?.category === LISTING_CATEGORY_EXPERT) &&
              stateData.showDetailCardHeadings &&
              !isSupportPlan ? (
                <div className={css.viewExpertButtonWrapperMobile}>
                  <NamedLink
                    name="ExpertPage"
                    params={{
                      id: expertListing ? expertListing.listingId.data[0]?.id?.uuid : listingId,
                      slug: createSlug(
                        expertListing
                          ? expertListing.listingId.data[0]?.attributes?.title
                          : listingTitle
                      ),
                      category: 'experts',
                    }}
                  >
                    <SecondaryButton className={css.restButtons}>
                      <FormattedMessage
                        className={css.viewExpertLink}
                        id="TransactionPanel.viewExpert"
                      />
                    </SecondaryButton>
                    {!!expertUserId && (
                      <div className={css.viewExpertButtonWrapper}>
                        <NamedLink
                          name="ConversationPage"
                          className={css.linkViewExpert}
                          params={{ id: expertUserId }}
                        >
                          <SecondaryButton className={css.restButtons}>
                            <FormattedMessage id="TransactionPanel.sendMessage" />
                          </SecondaryButton>
                        </NamedLink>
                      </div>
                    )}
                  </NamedLink>
                </div>
              ) : null}
              <div className={css.viewExpertButtonWrapperMobile}>
                {!!expertUserId && (
                  <NamedLink
                    name="ConversationPage"
                    className={css.linkViewExpert}
                    params={{ id: expertUserId }}
                  >
                    <SecondaryButton className={css.restButtons}>
                      <FormattedMessage id="TransactionPanel.sendMessage" />
                    </SecondaryButton>
                  </NamedLink>
                )}

                {/* <SecondaryButton
                  className={css.restButtons}
                  // onClick={() => {
                  //   window.location.href = 'https://calendly.com/softwaresupp/software-consultation';
                  // }}
                  onClick={expertCallTrigger}
                >
                  <FormattedMessage className={css.viewExpertLink} id="TransactionPanel.bookCall" />
                </SecondaryButton> */}
              </div>
              {/* <div className={css.viewExpertButtonWrapperMobile}>
              <SecondaryButton
                className={css.restButtons}
                // onClick={() => {
                //   window.location.href = 'https://calendly.com/softwaresupp/software-consultation';
                // }}
                onClick={pmCallTrigger}
              >
                <FormattedMessage className={css.viewExpertLink} id="TransactionPanel.pmCall" />
              </SecondaryButton>
            </div> */}

              <PanelHeading
                panelHeadingState={stateData.headingState}
                currentCustomerIsAdmin={currentCustomerIsAdmin}
                transactionRole={transactionRole}
                providerName={authorDisplayName}
                customerName={customerDisplayName}
                customerMail={currentUser.attributes.email}
                isCustomerBanned={isCustomerBanned}
                listingId={currentListing.id.uuid}
                listingTitle={listingTitle}
                listingCategory={listingCategory}
                listingDeleted={listingDeleted}
                isSupportPlan={isSupportPlan}
                projectJob={{
                  title: currentTransaction?.attributes.protectedData?.listingTitle,
                  id: currentTransaction?.attributes.protectedData?.listingIdPaidFor,
                  category: currentTransaction?.attributes.protectedData?.listingCategory,
                }}
              />

              <div className={css.bookingDetailsMobile}>
                <AddressLinkMaybe
                  rootClassName={css.addressMobile}
                  location={location}
                  geolocation={geolocation}
                  showAddress={stateData.showAddress}
                />
                <BreakdownMaybe
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                  showWhereby={stateData.showWhereby}
                  showBookingFinished={stateData.showBookingFinished}
                  onFinishBooking={onFinishBooking}
                />
              </div>

              {savePaymentMethodFailed ? (
                <p className={css.genericError}>
                  <FormattedMessage
                    id="TransactionPanel.savePaymentMethodFailed"
                    values={{ paymentMethodsPageLink }}
                  />
                </p>
              ) : null}
              {/* OFFER DETAILS */}
              {shouldDisplayOfferDetails && (
                <div className={css.detailsWrapper}>
                  <div className={css.detailsSection}>
                    {stateData.headingState === 'service-discussed' ? (
                      <>
                        {/* SERVICE ENQUIRY DETAILS */}
                        {!!offerDescription && (
                          <div className={css.sectionOfferDescription}>
                            <h2 className={css.sectionDetailsTitle}>Enquiry description</h2>
                            <div className={css.sectionDetailsText}>{offerDescription}</div>
                            <AttachmentsPanel
                              data={currentTransaction?.attributes?.metadata?.originalOffer}
                            />
                          </div>
                        )}
                        <NegotiatedOfferDetails
                          currentTransaction={currentTransaction}
                          transactionRole={transactionRole}
                          currentListing={currentListing}
                          stateData={stateData}
                          price={
                            expertListing
                              ? expertListing?.listingId?.data[0]?.attributes.price
                              : null
                          }
                          commission={commission}
                          changeIsNegotiationModalOpen={this.changeIsNegotiationModalOpen}
                          scopeType={'service'}
                        />
                      </>
                    ) : !currentCustomerIsAdmin ? (
                      <>
                        {/* REGULAR DETAILS */}
                        {!!offerDescription && (
                          <div className={css.sectionOfferDescription}>
                            <h2 className={css.sectionDetailsTitle}>Offer description</h2>
                            <div className={css.sectionDetailsText}>{offerDescription}</div>
                            <AttachmentsPanel
                              data={currentTransaction?.attributes?.metadata?.originalOffer}
                            />
                          </div>
                        )}
                        <NegotiatedOfferDetails
                          currentTransaction={currentTransaction}
                          transactionRole={transactionRole}
                          currentListing={currentListing}
                          stateData={stateData}
                          commission={commission}
                          price={
                            expertListing
                              ? expertListing?.listingId?.data[0]?.attributes.price
                              : null
                          }
                          changeIsNegotiationModalOpen={this.changeIsNegotiationModalOpen}
                          scopeType={'offer'}
                          onSubmitRedirect={() => onSubmitRedirect(currentTransaction.id.uuid)}
                        />
                      </>
                    ) : (
                      <>
                        {/* ADMIN OFFER DETAILS */}
                        {!!offerDescription && (
                          <div className={css.sectionOfferDescription}>
                            <h2 className={css.sectionDetailsTitle}>Offer description</h2>
                            <div className={css.sectionDetailsText}>{offerDescription}</div>
                            <AttachmentsPanel
                              data={currentTransaction?.attributes?.metadata?.originalOffer}
                            />
                          </div>
                        )}
                        <NegotiatedOfferDetails
                          currentTransaction={currentTransaction}
                          transactionRole={transactionRole}
                          currentListing={currentListing}
                          stateData={stateData}
                          commission={commission}
                          price={
                            expertListing
                              ? expertListing?.listingId?.data[0]?.attributes.price
                              : null
                          }
                          changeIsNegotiationModalOpen={this.changeIsNegotiationModalOpen}
                          isAdminOffer={true}
                          estimatedTimeRange={estimatedTimeRange}
                          estimatedPriceRange={estimatedPriceRange}
                          scopeType={'offer'}
                          currentUserIsAdmin={currentUserIsAdmin}
                          onEditAdminOfferScope={onEditAdminOfferScope}
                        />
                        <div
                          className={classNames(css.detailsHeading, css.additionalDetailsMargin)}
                        >
                          <span>Additional information</span>
                        </div>
                        {!!offerValidity && (
                          <div>
                            <h2 className={css.sectionDetailsTitle}>Offer validity time</h2>
                            <div className={css.sectionDetailsText}>{offerValidity}</div>
                          </div>
                        )}
                        {!!offerPaymentDetails && (
                          <div>
                            <h2 className={css.sectionDetailsTitle}>Information about payment</h2>
                            <div className={css.sectionDetailsText}>{offerPaymentDetails}</div>
                          </div>
                        )}
                        {!!offerContractInfo ? (
                          <div>
                            <h2 className={css.sectionDetailsTitle}>Contract</h2>
                            <div className={css.sectionDetailsText}>{offerContractInfo}</div>
                          </div>
                        ) : (
                          <div>
                            <h2 className={css.sectionDetailsTitle}>Contract</h2>
                            <div className={css.sectionDetailsText}>
                              <FormattedMessage
                                id="TransactionPanel.defaultOfferContractInfo"
                                values={{ termsLink }}
                              />
                            </div>
                          </div>
                        )}
                        {(!!offerContact || !!offerContactMail || !!offerContactPhone) && (
                          <div>
                            <h2 className={css.sectionDetailsTitle}>Contact</h2>
                            <div className={css.sectionDetailsText}>
                              {!!offerContact ? (
                                <>
                                  {offerContact}
                                  <br />
                                </>
                              ) : null}
                              {!!offerContactMail ? (
                                <>
                                  {offerContactMail}
                                  <br />
                                </>
                              ) : null}
                              {!!offerContactPhone ? (
                                <>
                                  {offerContactPhone}
                                  <br />
                                </>
                              ) : null}
                            </div>
                          </div>
                        )}
                        {this.state.windowWidth < MOBILE_BREAKPOINT && (
                          <div className={css.sectionPickYourOffer}>
                            <strong className={css.adminOfferValuesTitle}>
                              <FaRegHandshake size={'1.2em'} />
                              {'\u00A0'}
                              Choose your contractor
                            </strong>
                            <div className={css.offersContainer}>{relatedOffers}</div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
              {shouldShowFeedSection && (
                <>
                  <FeedSection
                    rootClassName={css.feedContainer}
                    currentTransaction={currentTransaction}
                    currentUser={currentUser}
                    fetchMessagesError={fetchMessagesError}
                    fetchMessagesInProgress={fetchMessagesInProgress}
                    initialMessageFailed={initialMessageFailed}
                    messages={currentTransaction.messages ? currentTransaction.messages : messages}
                    oldestMessagePageFetched={oldestMessagePageFetched}
                    onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
                    totalMessagePages={totalMessagePages}
                  />
                </>
              )}

              {stateData.showPayButtonMessage ? (
                <div className={css.mobileActionButtons}>{saleButtons}</div>
              ) : null}
            </div>
          </Col>
          <Col md={3} className={css.rightColumnNew}>
            <div className={css.detailCard}>
              <div className={css.detailCardMainInfo}>
                {/* if booking page and we are expert (provider) -> dont show image and details */}
                {/* if offer page and we are expert (customer) -> dont show image and details */}
                {((txIsBookingPaymentConfirmed(currentTransaction) ||
                  stateData.headingState === 'booking-waiting' ||
                  stateData.headingState === 'booking-stripe-confirmed') &&
                  isProvider === true) ||
                (stateData.headingState === 'offer-sent' && isCustomer === true) ||
                stateData.headingState === 'service-discussed' ? null : (
                  <>
                    <DetailCardImage
                      avatarWrapperClassName={css.avatarWrapperDesktop}
                      listingTitle={listingTitle}
                      listingCategory={listingCategory}
                      image={firstImage}
                      provider={showUser}
                      isCustomer={isCustomer}
                      softwares={softwares}
                      listingId={listingId}
                      listingDeleted={listingDeleted}
                      showImage={isExpertListing || isProvider}
                      isAdminListing={isAdminListing}
                    />
                    <DetailCardHeadingsMaybe
                      // showDetailCardHeadings={stateData.showDetailCardHeadings}
                      listingId={listingId}
                      listingTitle={listingTitleHeading}
                      subTitle={expertListingSubtitle} // previously commented out {bookingSubTitle}
                      location={location}
                      geolocation={geolocation}
                      showAddress={stateData.showAddress}
                    />
                  </>
                )}
              </div>
              {!offerIsFromAdmin &&
              (expertListing?.listingId.data[0]?.id?.uuid ||
                currentListing?.attributes.publicData?.category === LISTING_CATEGORY_EXPERT) &&
              stateData.showDetailCardHeadings &&
              !isSupportPlan ? (
                <div className={css.viewExpertButtonWrapper}>
                  <NamedLink
                    name="ExpertPage"
                    className={css.linkViewExpert}
                    params={{
                      id: expertListing ? expertListing.listingId.data[0]?.id?.uuid : listingId,
                      slug: createSlug(
                        expertListing
                          ? expertListing.listingId.data[0]?.attributes?.title
                          : listingTitle
                      ),
                      category: 'experts',
                    }}
                  >
                    <SecondaryButton className={css.restButtons}>
                      <FormattedMessage id="TransactionPanel.viewExpert" />
                    </SecondaryButton>
                  </NamedLink>
                </div>
              ) : null}
              {/* new inbox sending messages  */}
              {!!expertUserId && (
                <div className={css.viewExpertButtonWrapper}>
                  <NamedLink
                    name="ConversationPage"
                    className={css.linkViewExpert}
                    params={{ id: expertUserId }}
                  >
                    <SecondaryButton className={css.restButtons}>
                      <FormattedMessage id="TransactionPanel.sendMessage" />
                    </SecondaryButton>
                  </NamedLink>
                </div>
              )}

              {/* <div className={css.viewExpertButtonWrapper}> */}
              {/*   <SecondaryButton */}
              {/*     className={css.restButtons} */}
              {/*     // onClick={() => { */}
              {/*     //   window.location.href = 'https://calendly.com/softwaresupp/software-consultation'; */}
              {/*     // }} */}
              {/*     onClick={expertCallTrigger} */}
              {/*   > */}
              {/*     <FormattedMessage className={css.viewExpertLink} id="TransactionPanel.bookCall" /> */}
              {/*   </SecondaryButton> */}
              {/* </div> */}
              {/* <div className={css.viewExpertButtonWrapper}>
                <SecondaryButton
                  className={css.restButtons}
                  // onClick={() => {
                  //   window.location.href = 'https://calendly.com/softwaresupp/software-consultation';
                  // }}
                  onClick={pmCallTrigger}
                >
                  <FormattedMessage className={css.viewExpertLink} id="TransactionPanel.pmCall" />
                </SecondaryButton>
              </div> */}
              {/* <div className={css.bookingTimePanelDesktop}>
                <SecondaryButton
                  className={css.restButtons}
                  onClick={() => {
                    window.location.href =
                      'https://calendly.com/softwaresupp/software-consultation';
                  }}
                >
                  <FormattedMessage className={css.viewExpertLink} id="TransactionPanel.bookCall" />
                </SecondaryButton>
              </div> */}

              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={isProvider}
                  currentCustomerIsAdmin={currentCustomerIsAdmin}
                  estimatedPriceRange={estimatedPriceRange}
                  relatedOffers={relatedOffers}
                  listing={currentListing}
                  expertListing={expertListing}
                  currentCustomer={currentCustomer}
                  title={listingTitle}
                  isAdmin={isAdmin}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onSubmitRedirect={() => this.setState({ confirmAcceptModalOpen: true })}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isExpertListing={isExpertListing}
                  isJobListing={isJobListing}
                  transactionRole={transactionRole}
                  handleNewJobOffer={handleBookingSubmitJobOffer}
                  isUser={isUser}
                  currentUser={currentUser}
                  hasExpertProfile={true}
                  estimatedPriceMaybe={currentTransactionOffer['price']}
                  isTransaction={true}
                  currentCustomerStripe={currentCustomerStripe}
                  currentProviderStripe={currentProviderStripe}
                  showPaymentMissing={stateData.showPaymentMissing}
                  showAcceptOffer={stateData.showAcceptOffer}
                  messageFormatting={messageFormatting}
                />
              ) : null}
              {stateData.showBookingPanel && !offerIsFromAdmin ? (
                <AcceptMeetingRequest
                  listing={currentListing}
                  className={css.modalBooking}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onSubmit={onSubmitBookingRequest}
                  isUser={isUser}
                  isAdmin={isAdmin}
                  isOwnListing={isProvider}
                  hasExpertProfile={true}
                  currentUser={currentUser}
                  isTransaction={true}
                  handleNewJobOffer={handleBookingSubmitJobOffer}
                  transactionRole={transactionRole}
                  currentCustomerStripe={currentCustomerStripe}
                  currentProviderStripe={currentProviderStripe}
                  onSubmitRedirect={() => console.log('TETS')}
                  currentCustomer={currentCustomer}
                  isExpertListing={isExpertListing}
                  isJobListing={isJobListing}
                  estimatedPriceMaybe={currentTransaction.attributes.payinTotal}
                  showPaymentMissing={stateData.showPaymentMissing}
                  showAcceptOffer={stateData.showAcceptOffer}
                  messageFormatting={messageFormatting}
                />
              ) : null}
              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                showWhereby={stateData.showWhereby}
                showBookingFinished={stateData.showBookingFinished}
                onFinishBooking={onFinishBooking}
              />
              {stateData.showPayButtonMessage ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
            </div>
          </Col>
        </Row>
        <div className={css.container}></div>
        <Modal
          id="confirm-accept"
          containerClassName={css.modalContentContainer}
          contentClassName={css.modalContent}
          isOpen={this.state.confirmAcceptModalOpen}
          onClose={() => this.setState({ confirmAcceptModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <p>
            <FormattedMessage id="TransactionPanel.confirmAcceptMessage" values={{ br: <br /> }} />
          </p>

          <p />
          <PrimaryButton
            onClick={() => {
              this.setState({ confirmAcceptModalOpen: false });
              onSubmitRedirect(currentTransaction.id.uuid);
            }}
          >
            <FormattedMessage id="TransactionPanel.confirmAccept" />
          </PrimaryButton>
        </Modal>
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  unitType: config.bookingUnitType,
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  nextTransitions: null,
  isAdmin: false,
  isUser: false,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: oneOfType([propTypes.transaction, object]).isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  // onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  // onSubmitBookingRequest: func.isRequired,
  onSubmitRedirect: func.isRequired,
  nextTransitions: array,

  // Sale related props
  completeInProgress: bool.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,

  // from injectIntl
  intl: intlShape,

  unitType: propTypes.bookingUnitType,

  isUser: bool.isRequired,
  isAdmin: bool.isRequired,
};

const mapStateToProps = state => {
  const { productSoftware, productSkills } = state.marketplaceData;
  return {
    productSoftware,
    productSkills,
  };
};

const TransactionPanel = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps)
)(TransactionPanelComponent);

export default TransactionPanel;
