import React from 'react';
import dayjs from 'dayjs';

import { Container, Row, Col, Tabs, Tab, Accordion } from 'react-bootstrap';
import css from './BillingPage.css';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import { injectIntl } from 'react-intl';

const { Money } = types;

const PayoutsPanel = ({ payouts, intl }) => {

  let totalAmount = 0;
  payouts.forEach(payout => {
    totalAmount += payout.amount;
  });
  return (
    <div >
      <h3>Payouts total: {formatMoney(intl, new Money(totalAmount * -1, 'USD'))}</h3>
      <ul>
        {payouts.map(payout => {
          const amount = formatMoney(intl, new Money(payout.amount * -1, payout.currency.toUpperCase()));
          return (
            <li className={css.invoiceItem}>
            <Container>
              <Row>
                <Col xs={6} md={8}>
                 Date: {dayjs.unix(payout.created).format('MMM D, YYYY')}
                </Col>
                <Col xs={6} md={4} className={css.payoutItem}>
                  Amount: {amount}
                </Col>
              </Row>
            </Container>
            </li>
          );
        })}
      </ul>
          </div>
  );
};
export default injectIntl(PayoutsPanel);
