import {
  sendNotification,
  getUserDataById,
} from '../util/api';

import { sendCvFromFreelancer } from '../util/triggerMail';

export const sendCvNotyfications = (currentUser, cvData) => {
  console.log('sendData', cvData)
  
  const userUide = currentUser.id.uuid;
  const userToken = currentUser.attributes.profile.privateData?.inboxToken;  // Token must be always
        
  
if( cvData?.type === 'freeofferting') {
  sendNotification(userToken, {
  toUserId: cvData.authorid,
  from: process.env.REACT_APP_PROJECT_MANAGER_USER_ID, // ok
  title: `New application`, // ok
  subtitle: `We send you new application to your email adress`, // ok
  status: `New Application`,
  redirectUrl: cvData.data, // ok
  type: `CVAPPLICATION`, // ok
  shouldDisappear: false,
  });
  // Wysłanie powiadomienia do aplikującego
  sendNotification(userToken, {
  toUserId: userUide,
  from: process.env.REACT_APP_PROJECT_MANAGER_USER_ID, // ok
  title: `Application was sended`, // ok
  subtitle: `What's next? Becoma an expert and ...`, // ok
  status: `What's next?`,
  redirectUrl: '/l/draft/00000000-0000-0000-0000-000000000000/new/expert/quick', // ok
  type: `CVAPPLICATION`, // okr
  shouldDisappear: false,
  });


// Wysylanie Maila
    
            // Send email with CV to JobPost author
          getUserDataById({'id': cvData.authorid }).then(res => {
              const authorEmail = res.email;

              let dataToSend = {
                   mail:authorEmail,
                   message: cvData.values.offer,
                   link: cvData.data,
                      };
                  sendCvFromFreelancer(dataToSend);
            })
}
  
}; // End sendCvNotyfiaction

