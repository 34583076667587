import React, {useState, useEffect} from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import {
  Form,
  PrimaryButton,
} from '../../../components';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useForm, Controller } from "react-hook-form";
import css from './QuestionSuggestForm.css';

const HookInput = ({ label, name, register, required }) => (
    <div className={css.hookInputWrapper}>
      <label>{label}</label>
      <input {...register(name, { required })} />
    </div>
);

const HookReactSelect = ({ label, name, control, options, required }) => {
  if(typeof window !== 'undefined') {
    var { default: CreatableSelect } = require('react-select/creatable');
    return (
      <>
        <label>{label}</label>
        <Controller
          name={name}
          control={control}
          rules={{ required: required }}
          render={({ field }) => <CreatableSelect 
            {...field}
            placeholder={'Type in or select from dropdown...'}
            isMulti
            options={options}
          />}
        />
      </>
    );
  }
  return null;
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const HookCheckbox = ({ name, control }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({
        field: { onChange, value, name }
      }) => (
        <GreenCheckbox
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      )}
    />
  );
}

const HookAnswerInput = ({ placeholder, name, register, control, required, shouldDisplayButtons, addHandler, removeHandler }) => (
  <div className={css.answerInputWrapper}>
    <div className={css.spaceHolder}>
      <HiArrowNarrowRight size={"1.3em"} />
    </div>
    <div className={css.answerInput}>
      <input {...register(name, { required })} />
    </div>
    <div className={css.checkbox}>
      <HookCheckbox name={`${name}checkbox`} control={control} />
    </div>
    <div className={css.sideButtonsWrapper}>
      {shouldDisplayButtons &&
        <>
          <div className={css.buttonWrapper} onClick={addHandler}>
            <BiPlusCircle size={"1.3em"} />
          </div>
          <div className={css.buttonWrapper} onClick={removeHandler}>
            <BiMinusCircle size={"1.3em"} />
          </div>
        </>
      }
    </div>
  </div>
);

export const QuestionSuggestForm = (props) => {

  const { productSoftware } = props;

  const allSofts = productSoftware.map(soft => {
    return {
      value: soft.key,
      label: soft.label
    }
  });

  const [answers, setAnswers] = useState([0,1,2,3]);

  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);
  useEffect(() => {
    if (typeof window !== undefined) {
      setShouldRenderReactSelect(true);
    }
  }, []);

  const { register, handleSubmit, formState: { errors }, control } = useForm();
  const onSubmit = (data) => {
    props.onSubmit(data);
  }

  const answerInputPlaceholder = 'Provide answer and mark it on the left panel if correct';

  const addHandler = () => {
    setAnswers((answers) => {
      if(answers.length >= 6) {
        return answers;
      }
      answers.push(answers[answers.length-1]+1);
      return [...answers];
    });
  }
  
  const removeHandler = () => {
    setAnswers((answers) => {
      if(answers.length <= 2) {
        return answers;
      }
      return answers.slice(0,-1);
    }
    );
  }

  const renderAnswerInputs = (answers, errors) => {
    return (
      <>
        <div className={css.answersLabelsBar}>
          <div className={css.spaceHolder}>{''}</div>
          <div className={css.answerInput}>{'Suggested answer'}</div>
          <div className={css.checkbox}>{'Validity'}</div>
          <div className={css.sideButtonsWrapper}>{''}</div>
        </div>
        { answers.map((a, i) => {
          return (
            <div key={`answer${i}`}>
              <HookAnswerInput 
                placeholder={answerInputPlaceholder} 
                name={`answer${i}`} 
                register={register} 
                control={control}
                shouldDisplayButtons={i === (answers.length-1)}
                addHandler={addHandler}
                removeHandler={removeHandler}
                required={i < 2}
              />
              {(errors[`answer${i}`] && i < 2) && renderError("This field is required", true)}
            </div>
          );
        }) }
      </>
    );
  }

  const renderError = (error, centered = false) => {
    const classes = centered ? classNames(css.errorText, css.centered) : classNames(css.errorText);
    return (
      <p className={classes}>
        {error}
      </p>
    );
  }

  return (
      <div className={css.mainWrapper}>
        <Form className={css.formWrapper} onSubmit={handleSubmit(onSubmit)}>
          {shouldRenderReactSelect && 
            <HookReactSelect label="Please provide exam type: " name={'examType'} control={control} options={allSofts} required />
          }
          {errors.examType && renderError("This field is required")}
          <HookInput label="Suggested question: " name={'question'} register={register} required />
          {errors.question && renderError("This field is required")}
          {renderAnswerInputs(answers, errors)}
          <PrimaryButton 
            className={css.createButton}
            disabled={false}
            inProgress={false}
            type={"submit"}
          >
            <FormattedMessage id="ExamSuggestQuestionPage.submitButton" />
          </PrimaryButton>
        </Form>
      </div>
  );
}