import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { getUser } from './MessagesPage.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { BiPlus, BiRefresh } from 'react-icons/bi';
import { inboxGetUserDisplayNames, getAllUserIds, getExpertTitlesByUserIds } from '../../util/api';
import { parse } from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import {
  AvatarSmall,
  NamedLink,
  SecondaryButton,
  Sidebar,
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  PaginationLinks,
  Modal
} from '../../components';
import InboxSDK from '../../util/inbox/InboxSDK';
import { TopbarContainer } from '../../containers';
import { sendSoftwareSuppInviteMail } from '../../util/triggerMail';
import { InviteUserForm } from '../../forms';
import { InboxItem } from './InboxItem';

import css from './MessagesPage.css';

const HOW_MANY_PER_PAGE = 8;

const OptionAvatar = props => {
  const { imgUrl, userName, abbreviatedName } = props;

  return !imgUrl ? (
    <AvatarSmall user={{ attributes: { profile: { abbreviatedName: abbreviatedName } } }} />
  ) : (
    <AvatarSmall onlyImgName={userName} onlyImgUrl={imgUrl} disableProfileLink />
  );
};

export const MessagesPageComponent = props => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    history,
    intl,
    params,
    location,
    onFetchCurrentUser,
    scrollingDisabled,
    onManageDisableScrolling,
  } = props;

  const { search } = location;

  const page = search ? parse(search).page : 0;

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const [inbox, setInbox] = useState();
  const [messages, setMessages] = useState();
  const [readingMessages, setReadingMessages] = useState(false);
  const [totalMessagesCount, setTotalMessagesCount] = useState();
  const [shouldDisplayCreateBar, setShouldDisplayCreateBar] = useState(false);
  const [chatGroupLoading, setChatGroupLoading] = useState(true);
  const [chatSelectOptions, setChatSelectOptions] = useState([]);
  const [chatSelectedUsers, setChatSelectedUsers] = useState([]);
  const [refreshingMessages, setRefreshingMessages] = useState({ status: false });
    const [inviteOpen, setInviteOpen] = useState(false);
    const [successfullyInvited, setSuccessfullyInvited] = useState(false);
  const chatGroupLoadingRef = useRef(chatGroupLoading);

  const pagination = {
    totalItems: totalMessagesCount,
    totalPages: !!totalMessagesCount && Math.ceil(totalMessagesCount / HOW_MANY_PER_PAGE),
    page: page === 0 ? 1 : page,
    perPage: HOW_MANY_PER_PAGE,
  };

  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setShouldRenderReactSelect(true);
    }
  }, []);

  useEffect(() => {
    if (currentUser && !currentUser.attributes.profile?.privateData?.inboxToken) {
      onFetchCurrentUser();
    }
    if (!!currentUser && !!currentUser.attributes.profile?.privateData?.inboxToken) {
      let userId = currentUser.id.uuid;
      let userToken = currentUser.attributes.profile.privateData.inboxToken;
      let inboxSdk = new InboxSDK(userId, userToken);
      setInbox(inboxSdk);
      readInbox(inboxSdk, page > 0 ? page - 1 : page);
      loadChatGroup(userToken, currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, page]);

  const readInbox = async (inboxSdk, pageNumber) => {
    setReadingMessages(true);
    const messages = await inboxSdk.readMessageSummary(pageNumber, HOW_MANY_PER_PAGE);
    setTotalMessagesCount(messages.data.totalItems);
    setMessages(messages?.data.messages);
    setReadingMessages(false);
  };

  const loadChatGroup = async (userToken, currentUser) => {
    // load chat group
    let userChatGroup = currentUser?.attributes?.profile?.publicData?.canChatWith;
    if (userChatGroup?.length > 0) {
      setShouldDisplayCreateBar(true);
    }
    if (userChatGroup?.includes('all')) {
      let { userIds } = await getAllUserIds();
      userChatGroup = userIds;
    }
    if (userChatGroup?.length > 0) {
      loadTitlesInParallel(userChatGroup);
      let userDisplayNames = await inboxGetUserDisplayNames(userToken, { userIds: userChatGroup });
      setChatGroupLoading(false);
      chatGroupLoadingRef.current = false;
      setChatSelectOptions(
        userDisplayNames.map(user => {
          return {
            value: user.userId,
            label: user.displayName,
            chipLabel: user.displayName,
            userName: user.firstName,
            nameForGroup: user.firstName,
            imgUrl: user.imgUrl,
            abbreviatedName: user.abbreviatedName,
          };
        })
      );
      if (!(userDisplayNames?.length > 0)) {
        setShouldDisplayCreateBar(false);
      }
    } else {
      setChatGroupLoading(false);
      chatGroupLoadingRef.current = false;
    }
  };

  const loadTitlesInParallel = async userChatGroup => {
    let userTitles = await getExpertTitlesByUserIds({ userIds: userChatGroup });
    // wait for chatSelectOptions
    while (chatGroupLoadingRef.current) {
      await wait(200);
    }
    if (!chatGroupLoadingRef.current && userTitles.success) {
      setChatSelectOptions(currentOptions => {
        for (let i = 0; i < currentOptions.length; i++) {
          let newTitle =
            userTitles.data.find(el => el.userId === currentOptions[i].value)?.title ?? '';
          currentOptions[i]['title'] = newTitle;
        }
        return currentOptions;
      });
    }
  };

  const wait = async timeGrain => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, timeGrain);
    });
  };

  const parseMessages = messages => {
    return messages.map(item => {
      let lastmsg = item?.lastMessage;
      let profile = item?.counterpartProfile;
      return {
        conversationId:
          lastmsg?.toGroupId?.length > 0
            ? lastmsg.toGroupId
            : profile?.userId ?? item?.counterpartId,
        date: lastmsg.createdAt,
        lastMessage: lastmsg.content,
        displayedName: item?.groupName ?? profile?.displayName,
        userName: profile?.displayName,
        abbreviatedName: profile?.abbreviatedName,
        userImgUrl: profile?.imgUrl,
        isGroupMessage: !!item?.groupName,
        containsAttachments: !!(lastmsg?.attachmentsQty > 0),
        isDeleted: false,
      };
    });
  };

  const messagesTitle = intl.formatMessage({ id: 'MessagesPage.messagesTitle' });
  const title = messagesTitle;

  const renderMessages = messages => {
    return messages.map(message => {
      return renderInboxItem(message);
    });
  };

  const renderInboxItem = item => {
    return (
      <li key={item.conversationId} className={css.listItem}>
        <InboxItem data={item} />
      </li>
    );
  };

  // const error = false ? (
  //   <p className={css.error}>
  //     <FormattedMessage id="InboxPage.fetchFailed" />
  //   </p>
  // ) : null;
  const isAdmin =
    currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

  const handleChangeSelectedUsers = v => {
    setChatSelectedUsers(v);
  };

  const onRefreshMessages = async () => {
    setRefreshingMessages({ status: true });
    setTimeout(() => {
      if (!refreshingMessages.status) {
        setRefreshingMessages({ status: false });
      }
    }, 1000);
    if (!!inbox) {
      const messages = await inbox.readMessageSummary(page, HOW_MANY_PER_PAGE);
      setRefreshingMessages({ status: false });
      setMessages(messages?.data);
    }
  };

  const onCreateConversation = async () => {
    let howManySelected = chatSelectedUsers?.length;
    let singularSelect = false;
    // for singular users only
    if (!!chatSelectedUsers?.value) {
      singularSelect = true;
      howManySelected = 1;
    }
    if (howManySelected < 1) {
      return;
    }
    const routes = routeConfiguration();
    if (howManySelected === 1) {
      if (singularSelect === true) {
        history.push(
          createResourceLocatorString('ConversationPage', routes, { id: chatSelectedUsers.value })
        );
      } else {
        history.push(
          createResourceLocatorString('ConversationPage', routes, {
            id: chatSelectedUsers[0].value,
          })
        );
      }
    } else if (howManySelected > 1) {
      const selectedUserIds = chatSelectedUsers.map(user => user.value);
      const { success, groupId } = await inbox.createGroupAddMembers(selectedUserIds);
      if (success && !!groupId) {
        history.push(createResourceLocatorString('GroupConversationPage', routes, { id: groupId }));
      }
    }
  };

  if (shouldRenderReactSelect) {
    var { default: Select, components } = require('react-select');
    var formatOptionLabel = ({ value, label, userName, imgUrl, abbreviatedName, title }) => (
      <div style={{ display: 'flex' }}>
        <div className={css.itemAvatar}>
          <OptionAvatar imgUrl={imgUrl} userName={userName} abbreviatedName={abbreviatedName} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {label}
        </div>
        <div
          style={{
            marginLeft: '10px',
            color: '#bbb',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {title}
        </div>
      </div>
    );
    var MultiValue = props => (
      <components.MultiValue {...props}>{props.data.chipLabel}</components.MultiValue>
    );
    var SingleValue = props => (
      <components.SingleValue {...props}>{props.data.chipLabel}</components.SingleValue>
    );
  }

  const customSelectStyles = {
    container: (provided, state) => ({
      ...provided,
      fontSize: '14px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '14px',
    }),
  };
  const onSubmitInvite = async (values, form) => {
    setSuccessfullyInvited(false);
    setTimeout(form.reset);
    sendSoftwareSuppInviteMail(values.allowedUserEmail);
    setSuccessfullyInvited(true);
  };
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav
          className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}
        >
          <Sidebar
            tab={'messages'}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
          <SecondaryButton className={css.inviteButton} onClick={() => setInviteOpen(true)}>
            <FormattedMessage id="ManageListingsPage.inviteUsersToSoftwareSupp" />
          </SecondaryButton>
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
          {/* {error} */}
          <div className={css.messagesPageWrapper}>
            {shouldDisplayCreateBar && (
              <div className={css.createBar}>
                <div className={css.selectWrapper}>
                  <Select
                    styles={customSelectStyles}
                    formatOptionLabel={formatOptionLabel}
                    components={{ SingleValue, MultiValue }}
                    isLoading={chatGroupLoading}
                    loadingMessage={() => 'Loading options...'}
                    options={chatSelectOptions}
                    isMulti
                    value={chatSelectedUsers}
                    onChange={v => {
                      handleChangeSelectedUsers(v);
                    }}
                    placeholder={'Select or type users for new conversation...'}
                  />
                </div>
                <div className={css.createButtonWrapper}>
                  <SecondaryButton
                    rootClassName={css.createButton}
                    onClick={onCreateConversation}
                    disabled={!(chatSelectedUsers?.length > 0)}
                    //inProgress={submitInProgress}
                    //disabled={submitDisabled}
                  >
                    <BiPlus />
                    <FormattedMessage id="MessagesPage.createNewConversation" />
                  </SecondaryButton>
                  <div className={css.refreshIcon}>
                    {!refreshingMessages.status ? (
                      <BiRefresh fontSize={'2em'} onClick={onRefreshMessages} />
                    ) : (
                      <IconSpinner className={css.refreshSpinner} />
                    )}
                  </div>
                </div>
              </div>
            )}
            {messages?.length > 0 && !readingMessages ? (
              <div>
                <ul>{renderMessages(parseMessages(messages))}</ul>
              </div>
            ) : messages?.length === 0 && !readingMessages ? (
              <div className={css.centeredLoadingMessage}>
                <div>Your inbox is empty.</div>
              </div>
            ) : (
              <div className={css.centeredLoadingMessage}>
                <div>Loading messages...</div>
              </div>
            )}
            <div className={css.archiveMessagesWrapper}>
              <div className={css.archiveMessagesText}>
                {'We have moved old messages to '}
                <NamedLink name="CenterPage" params={{ tab: 'messages' }}>
                  archive.
                </NamedLink>
              </div>
            </div>
            {totalMessagesCount > HOW_MANY_PER_PAGE && (
              <PaginationLinks
                className={css.pagination}
                pageName="MessagesPage"
                pagePathParams={params}
                pagination={pagination}
              />
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      <Modal
        id="InviteUserModal"
        contentClassName={css.enquiryModalContent}
        isOpen={inviteOpen}
        onClose={() => {
          setSuccessfullyInvited(false);
          setInviteOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <InviteUserForm
          id="InviteUserForm"
          onSubmit={onSubmitInvite}
          setUserSuccessfullyInvited={data => setSuccessfullyInvited(data)}
          userSuccessfullyInvited={successfullyInvited}
          isAdmin={isAdmin}
          clearInvitation={() => setSuccessfullyInvited(false)}
        ></InviteUserForm>
      </Modal>
    </Page>
  );
};

MessagesPageComponent.defaultProps = {
  currentUser: null,
};

MessagesPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;
  return {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onGetUser: userId => dispatch(getUser(userId)),
  onFetchCurrentUser: () => dispatch(fetchCurrentUser()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const MessagesPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MessagesPageComponent);

export default MessagesPage;
