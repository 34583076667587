import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SHOW_LISTING_REQUEST = 'app/NPSSurveyPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/NPSSurveyPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/NPSSurveyPage/SHOW_LISTING_ERROR';

export const FETCH_EXPERT_ERROR = 'app/NPSSurveyPage/FETCH_EXPERT_ERROR';

export const FETCH_REVIEWS_SUCCESS = 'app/NPSSurveyPage/FETCH_REVIEWS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  id: null,
  fetchedExpertProfileForService: null,
  fetchedExpertServices: null,
  enquiryModalOpenForListingId: null,
  showExpertError: false
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return { ...state, showListingError: null};
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_EXPERT_ERROR:
      return { ...state, fetchExpertError: true };

    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviewers: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = (data) => ({
  type: SHOW_LISTING_SUCCESS,
  payload: {data},
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchExpertError = e => ({
  type: FETCH_EXPERT_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsSuccess = reviews => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: reviews,
});

// ================ Thunks ================ //


export const showListing = listingId => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  return sdk.listings.show(params)
    .then(data => {
      dispatch(showListingSuccess());
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};
 
const fetchReviews = id => (dispatch, getState, sdk) => {

  sdk.reviews.query({
    listingId: id,
    include: ['author', 'author.profileImage'],
  }).then(res => {
    if (res) {
      const reviewUsers = res.data.data.map(review => review.relationships.author?.data.id?.uuid);
      dispatch(fetchReviewsSuccess(reviewUsers))
    }
  })
    .catch(e => {
      console.log(e)
    })

}

export const fetchExpert = id => (dispatch, getState, sdk) => {
  const expertId = new UUID(id);
  const params = {
    id: expertId,
    include: ['author', 'author.profileImage'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  return sdk.listings.show(params)
    .then(response => {
      if (response) {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchReviews(expertId));
        return response.data;
      }
    })
    .catch(e => {
      dispatch(fetchExpertError(storableError(e)))
    })
}

export const loadData = (params, search, pathname) => dispatch => {
  const listingId = new UUID(params.id);

  return dispatch(showListing(listingId))
};