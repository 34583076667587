/* eslint-disable */
import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import {
  propTypes,
  LISTING_CATEGORY_JOB,
  LISTING_CATEGORY_EXPERT,
  LISTING_CATEGORY_SERVICE,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, Avatar, VerifiedFlag, VerifiedIcon } from '../../components';
import { BiArchive } from 'react-icons/bi';
import { IoMdHourglass } from 'react-icons/io';
import { VscTools } from 'react-icons/vsc';
import { types as sdkTypes } from '../../util/sdkLoader';
import Overlay from '../ManageListingCard/Overlay';

import SectionProfileLinksMaybe from './SectionProfileLinksMaybe';
import SectionLocationMaybe from './SectionLocationMaybe';
import SectionExpertiseMaybe from './SectionExpertiseMaybe';
import SectionExperiencedMaybe from './SectionExperiencedMaybe';
import SectionNewMaybe from './SectionNewMaybe';
import SectionSoftwaresMaybe from './SectionSoftwaresMaybe';
import css from './ListingCard.css';

const { Money } = sdkTypes;

const priceData = (price, intl, budget) => {
  if (price !== null && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  } else if (budget) {
    const formattedPrice = formatMoney(intl, new Money(budget * 100, 'USD'));
    return { formattedPrice, priceTitle: formattedPrice };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    currentUser,
    urlQueryParams,
    searchedSoftware,
    isListingReducedToService,
    serviceAuthor,
    isExpertPage,
    currentUserStripeAccounts,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, createdAt } = currentListing.attributes;
  const slug = createSlug(title);
  const category = publicData.category;

  const isAuthor = currentUser?.id.uuid && currentUser?.id.uuid === serviceAuthor?.id.uuid;

  const isJobListing = category === LISTING_CATEGORY_JOB;
  const isExpertListing = category === LISTING_CATEGORY_EXPERT;
  const isServiceListing = category === LISTING_CATEGORY_SERVICE;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  let user = currentListing ? currentListing.author : null;
  const budget =
    currentListing.attributes.publicData && currentListing.attributes.publicData.budget
      ? currentListing.attributes.publicData.budget
      : null;

  let { formattedPrice, priceTitle } = priceData(price, intl, budget);
  formattedPrice = formattedPrice?.slice(0, formattedPrice.length - 3); // cut decimal part from price
  let formattedPriceForExpert = formatMoney(intl, new Money(0, 'USD'));

  if (formattedPrice) {
    formattedPriceForExpert = formatMoney(intl, new Money(price?.amount, 'USD'));

    formattedPriceForExpert = formattedPriceForExpert?.slice(0, formattedPriceForExpert.length - 3);
  }

  const estimatedTime =
    publicData.estimatedTime && publicData.estimatedTime > 1
      ? publicData.estimatedTime + ' days'
      : publicData.estimatedTime
      ? publicData.estimatedTime + ' day'
      : undefined;

  const isExpertStripeVerified = currentListing?.author?.attributes?.profile?.publicData
    ?.hasStripeConnected
    ? true
    : false;
  const isExpertSoftwareSuppVerified = publicData.isVerifiedBySoftwareVendor ? true : false;

  const firstSoftware = publicData.softwares ? publicData.softwares[0] : null;

  const isListingAuthor = currentListing?.author?.id.uuid === currentUser?.id.uuid;

  let serviceAuthorFullName = currentListing?.author?.attributes?.profile?.displayName;
  let serviceAuthorName = serviceAuthorFullName?.substr(0, serviceAuthorFullName.indexOf(' '));


  if (isListingReducedToService) {
    serviceAuthorName = currentListing.attributes.publicData.authorName;
    user = serviceAuthor;
  }

  // Job Card Rendering // Ticket Card Rendering
  if (isJobListing) {
    return (
      <div className={css.cardRootWrapper}>
        {props.shouldDisplayBookmark && (
          <div className={css.cardBookmark}>
            {isJobListing ? (
              <span>Project</span>
            ) : (
              <span>
                <FormattedMessage id="ListingCard.service" />
              </span>
            )}
          </div>
        )}
        <NamedLink
          className={css.linkClass}
          name="JobPage"
          params={{ id, slug, category: `${category}s` }}
        >
          <div className={classes}>
            <div className={css.jobMain}>
              <div className={css.jobLogoBudgetSection}>
                {/* LOGO */}
                <div className={css.jobLogoAspectWrapper}>
                  <LazyImage
                    rootClassName={css.jobRootForImage}
                    alt={title}
                    image={firstImage}
                    software={listing.attributes.publicData.primarySoftware || firstSoftware}
                    variants={['landscape-crop', 'landscape-crop2x']}
                    sizes={renderSizes}
                  />
                  {isJobListing &&
                  publicData.state &&
                  (publicData.state === 'closed' || publicData.state === 'awaiting') ? (
                    <Overlay
                      isSearchPage={true}
                      message={
                        <div
                          title={intl.formatMessage({
                            id: `ListingCard.${publicData.state}Explanation`,
                          })}
                          className={css.solvedWrapper}
                        >
                          {publicData.state === 'closed' ? (
                            <BiArchive className={css.solvedIcon}/>
                          ) : (
                            <IoMdHourglass className={css.solvedIcon}/>
                          )}
                          <FormattedMessage
                            id={
                              publicData.state === 'closed'
                                ? 'ListingCard.jobClosed'
                                : 'ListingCard.jobAwaiting'
                            }
                          />
                        </div>
                      }
                    ></Overlay>
                  ) : null}
                  {isJobListing && publicData.state && publicData.state === 'inProgress' ? (
                    <Overlay
                      isSearchPage={true}
                      message={
                        <div
                          title={intl.formatMessage({
                            id: `ListingCard.${publicData.state}Explanation`,
                          })}
                          className={css.solvedWrapper}
                        >
                          <VscTools className={css.solvedIcon}/>
                          <FormattedMessage id={'ListingCard.jobInProgress'}/>
                        </div>
                      }
                    ></Overlay>
                  ) : null}
                </div>
                {/* BUDGET */}
                {isJobListing ? (
                  <div className={css.jobBudgetWrapper}>
                    <div className={css.jobBudgetTitle}>
                      {isJobListing ? <FormattedMessage id={'ListingCard.price'}/> : null}
                    </div>
                    <div className={css.jobBudgetValue} title={priceTitle}>
                      {formattedPrice ? (
                        <div className={css.priceValueText}>
                          {isListingAuthor ? formattedPrice : formattedPriceForExpert}
                        </div>
                      ) : (
                        <div className={css.unknowBudgetSmallText}>
                          <FormattedMessage id={'ListingCard.unknownBudget'}/>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={css.jobMainContentSection}>
                <div className={css.titleInRow}>
                  <div className={css.jobTitle}>
                    <h2>{title}</h2>
                  </div>
                  {/* NEW STATUS CHIP */}
                  <div className={css.jobNewStatusChipWrapper}>
                    <SectionNewMaybe createdAt={createdAt}/>
                  </div>
                </div>
                {isJobListing ? (
                  <div className={css.dates}>
                    <FormattedMessage
                      id="ListingCard.dates"
                      values={{
                        published: currentListing.attributes.createdAt.toString().slice(4, 15),
                        expires: publicData.expires,
                      }}
                    />
                  </div>
                ) : null}
                {/* SOFTWARE TAGS */}
                <SectionSoftwaresMaybe
                  category={category}
                  softwares={publicData.softwares}
                  searchedSoftware={searchedSoftware}
                  urlQueryParams={urlQueryParams}
                />
              </div>
              <div className={css.jobDetailsSection}>
                {/* LOCATION */}
                <div className={css.jobLocationRootWrapper}>
                  <SectionLocationMaybe
                    wrapperClassName={css.jobLocationWrapper}
                    location={publicData.location}
                  />
                </div>
                {/* OFFERS TAGS */}
                {/* <div className={css.sectionOfferTags}>
                <div className={css.offersCountWrapper}>
                  <p className={css.offersValue}>{isJobListing ? offersCount : null}</p>
                  {isJobListing ? (
                    <p className={css.offersSubtitle}>offers</p>
                  ) : isListingAuthor ? (
                    <p className={css.offersSubtitle}>{'own ticket'} </p>
                  ) : null}
                </div>
  
              </div> */}
              </div>
            </div>
          </div>
        </NamedLink>
      </div>
    );
  } else if (isExpertListing) {
    // NEW Expert Card Rendering

    const userIsAdmin = currentUser?.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

    let name =
      userIsAdmin || currentUser?.attributes.profile.publicData?.isPremiumUser
        ? 'ExpertPage'
        : 'NewListingPage';

    return (
      <div className={css.cardRootWrapper}>
        {props.shouldDisplayBookmark && (
          <div className={css.cardBookmark}>
            <span>Expert</span>
          </div>
        )}
        <NamedLink
          className={css.linkClass}
          name={name}
          params={{ id, slug, category: `${category}s` }}
          openInNewCard={props.isCardIFrameLink}
        >
          <div className={classes}>
            <div className={css.expertMain}>
              <div className={css.expertAvatarBudgetSection}>
                {/* AVATAR */}
                <div className={css.expertAvatarAspectWrapper}>
                  <Avatar
                    user={user}
                    className={css.avatarDesktop}
                    initialsClassName={css.initialsDesktop}
                    disableProfileLink
                  />
                  <SectionProfileLinksMaybe
                    linkedin={publicData.linkLinkedin}
                    calendly={publicData.linkCalendly}
                    web={publicData.linkWeb}
                    className={css.mobileView}
                  />
                </div>
                {/* userHasBalance; isAuthor  */}
                { userIsAdmin ||
                currentUser?.attributes.profile.publicData?.isPremiumUser ? (
                  <div className={css.expertBudgetWrapper}>
                    <div className={css.expertBudgetTitle}>
                      <FormattedMessage id={'ListingCard.priceExpert'}/>
                    </div>
                    <div className={css.expertBudgetValue} title={priceTitle}>
                      {formattedPrice ? (
                        <div className={css.priceValueText}>{formattedPrice}</div>
                      ) : (
                        <div className={css.unknowBudgetSmallText}>
                          <FormattedMessage id={'ListingCard.unknownBudget'}/>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
            </div>
            <div className={css.expertMainContentSection}>
              {/* NAME & VERIFIED ICON, FLAG & CHIPS & EXPERTISE */}
              <div className={css.expertNameWrapper}>
                <div className={css.expertName}>
                  <h2 className={css.expertName}>{title}</h2>
                </div>
                <div className={css.expertNewStatusChipWrapper}>
                  {/* VERIFIED ICON */}
                  <VerifiedIcon isStripeVerified={isExpertStripeVerified} />
                  {/* VERIFIED FLAG */}
                  <VerifiedFlag isExpertVerified={isExpertSoftwareSuppVerified} />
                  {/* NEW/EXPERIENCED STATUS CHIPS */}
                  <SectionNewMaybe createdAt={createdAt} />
                  <SectionExperiencedMaybe experience={publicData.experience} />
                </div>
              </div>
              {/* EXPERTISE */}
              <SectionExpertiseMaybe expertise={publicData.expertise} />
              {/* SOFTWARE TAGS */}
              <SectionSoftwaresMaybe
                category={category}
                softwares={publicData.softwares}
                searchedSoftware={searchedSoftware}
                urlQueryParams={urlQueryParams}
                skills={publicData.skills}
                industries={publicData.industries}
              />
              {/* <SectionLanguagesMaybe initialLanguages={publicData.languages} />
               */}
              </div>
            </div>
          </div>
        </NamedLink>
      </div>
    );
  } else if (isServiceListing) {
    const userIsAdmin = currentUser?.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

    return (
      <div className={css.cardRootWrapper}>
        {props.shouldDisplayBookmark && (
          <div className={css.cardBookmark}>
            <span>
              <FormattedMessage id="ListingCard.service" />
            </span>
          </div>
        )}
        <NamedLink
          className={css.linkClass}
          name="ServicePage"
          params={{ id, slug, category: `${category}s` }}
          openInNewCard={props.isCardIFrameLink}
        >
          <div className={classes}>
            <div className={isExpertPage ? css.expertPageServiceMain : css.serviceMain}>
              <div className={css.serviceLogoSection}>
                {/* AVATAR */}
                <div className={css.serviceAvatarWrapper}>
                  <div className={css.serviceAvatarAspectWrapper}>
                    <Avatar
                      user={user}
                      className={css.avatarDesktop}
                      initialsClassName={css.initialsDesktop}
                      disableProfileLink
                    />
                  </div>
                  <div className={css.serviceProvidedByBarWrapper}>
                    <div className={css.serviceProvidedByBar}>
                      <span>Provided by {serviceAuthorName}</span>
                    </div>
                  </div>
                </div>
                {/* PRICE / TIME SECTION */}
                {!isExpertPage && userIsAdmin ? (
                  <div className={css.servicePriceTimeWrapper}>
                    <div className={css.servicePriceWrapper}>
                      <div className={css.servicePriceTimeTitle}>
                        <FormattedMessage id={'ListingCard.priceService'}/>
                      </div>
                      <div className={css.servicePriceTimeValue} title={priceTitle}>
                        {formattedPrice ? (
                          <div className={css.priceValueText}>{formattedPrice}</div>
                        ) : (
                          <div className={css.unknowBudgetSmallText}>
                            <FormattedMessage id={'ListingCard.unknownBudget'}/>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={css.servicePriceTimeSpace}/>
                    <div className={css.serviceTimeWrapper}>
                      <div className={css.servicePriceTimeTitle}>
                        <FormattedMessage id={'ListingCard.timeService'}/>
                      </div>
                      <div className={css.servicePriceTimeValue}>
                        {estimatedTime ? (
                          <div className={css.priceValueText}>{estimatedTime}</div>
                        ) : (
                          <div className={css.unknowBudgetSmallText}>
                            <FormattedMessage id={'ListingCard.unknownTime'}/>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={css.serviceMainContentSection}>
                {/* TITLE */}
                <div className={css.jobTitle}>
                  <h2 className={css.jobTitle}>{title}</h2>
                </div>
                <div className={css.serviceShortDescription}>
                  {publicData.shortDescription ? (
                    <>
                      <div className={css.whatWillBeDone}>{publicData.shortDescription}</div>
                    </>
                  ) : null}
                </div>
                {/* SOFTWARE TAGS */}
                <SectionSoftwaresMaybe
                  category={category}
                  softwares={publicData.softwares}
                  searchedSoftware={searchedSoftware}
                  urlQueryParams={urlQueryParams}
                  serviceCategories={publicData.serviceCategories}
                />
              </div>
              {/* PRICE / TIME SECTION */}
              {isExpertPage ? (
                <div className={css.serviceExpertPagePriceTimeWrapper}>
                  {userIsAdmin ||
                  currentUser?.attributes.profile.publicData?.isPremiumUser ||
                  isAuthor ? (
                    <div className={css.servicePriceWrapper}>
                      <div className={css.servicePriceTimeTitle}>
                        <FormattedMessage id={'ListingCard.priceService'}/>
                      </div>
                      <div className={css.servicePriceTimeValue} title={priceTitle}>
                        {formattedPrice ? (
                          <div className={css.priceValueText}>
                            {isAuthor ? formattedPriceForExpert : formattedPrice}
                          </div>
                        ) : (
                          <div className={css.unknowBudgetSmallText}>
                            <FormattedMessage id={'ListingCard.unknownBudget'}/>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className={css.servicePriceTimeSpace}/>
                  <div className={css.serviceTimeWrapper}>
                    <div className={css.servicePriceTimeTitle}>
                      <FormattedMessage id={'ListingCard.timeService'}/>
                    </div>
                    <div className={css.servicePriceTimeValue}>
                      {estimatedTime ? (
                        <div className={css.priceValueText}>{estimatedTime}</div>
                      ) : (
                        <div className={css.unknowBudgetSmallText}>
                          <FormattedMessage id={'ListingCard.unknownTime'}/>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </NamedLink>
      </div>
    );
  }
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
