import React from 'react';
import PropTypes from 'prop-types';
import css from './VerifiedFlag.css';
import { HighlightedChip } from '../../components/Chip/Chip';
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage } from '../../util/reactIntl';
import { Tooltip } from '@material-ui/core';

const VerifiedFlag = props => {
    const { isExpertVerified, addClassName } = props;
    const tooltipStyles = {
        tooltip: {
            width: "170px",
        }
      };
      const CustomTooltip = withStyles(tooltipStyles)(Tooltip);
      const verifiedFlag = (
        <CustomTooltip title={<FormattedMessage id={"VerifiedFlag.details"}  />}>
          <div className={css.verifiedFlag}>
            <HighlightedChip className={css.verifiedChip} addClassName={addClassName}>
                <FormattedMessage id="VerifiedFlag.chipTitle" />
            </HighlightedChip>
          </div>
        </CustomTooltip>
      );

    return (
        <>
            { isExpertVerified ? 
                verifiedFlag
                : 
                null
            }
        </>
    );
};

VerifiedFlag.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;
VerifiedFlag.propTypes = {
  rootClassName: string,
  className: string
};

export default VerifiedFlag;