import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { FieldTextInput } from '../../components';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import { Field } from 'react-final-form';
import { required } from '../../util/validators';
import { Tooltip } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './FieldProjects.css';

const mapInitialValues = initialValues => {
  return initialValues.map((project, index) => {
    return {
      id: index,
      projectTitle: project.title,
      projectDescription: project.description,
      projectUrl: project.url,
      projectCompany: project.company,
      projectSoftware: project.software
    }
  });
}

const FieldProjects = props => {
  const { intl, initialValues, values, setProjectDeleted, projectItems, setProjectItems, productSoftware } = props;

  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);
  const initialValuesLoaded = useRef(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      setShouldRenderReactSelect(true);
    }
  }, []);

  useLayoutEffect(() => {
    if (!initialValuesLoaded.current && initialValues?.length > 0) {
      setProjectItems(mapInitialValues(initialValues));
      initialValuesLoaded.current = true;
    }
  }, [initialValues, setProjectItems]);

  const allSofts = productSoftware.map(soft => {
    return {
      value: soft.key,
      label: soft.label
    }
  });

  const projectTitleLabel = intl.formatMessage({ id: "FieldProjects.titleLabel" });
  const projectTitlePlaceholder = intl.formatMessage({ id: "FieldProjects.titlePlaceholder" });
  const projectTitleRequired = required(intl.formatMessage({ id: "FieldProjects.titleRequired" }));

  const companyNameLabel = intl.formatMessage({ id: "FieldProjects.companyNameLabel" });
  const companyNamePlaceholder = intl.formatMessage({ id: "FieldProjects.companyNamePlaceholder" });

  const descriptionLabel = intl.formatMessage({ id: "FieldProjects.descriptionLabel" });
  const descriptionPlaceholder = intl.formatMessage({ id: "FieldProjects.descriptionPlaceholder" });

  const urlLabel = intl.formatMessage({ id: "FieldProjects.urlLabel" });
  const urlPlaceholder = intl.formatMessage({ id: "FieldProjects.urlPlaceholder" });

  const softwarePlaceholder = intl.formatMessage({ id: "FieldProjects.softwarePlaceholder" });

  // const notValidURLMessage = intl.formatMessage({ id: "EditExpertForm.notValidURLMessage" });

  if (shouldRenderReactSelect) {
    var isMobile = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      isMobile = true;
    }
    const { default: CreatableSelect } = require('react-select/creatable');
    const ReactCreatableSelectAdapter = ({ input, ...rest }) => (
      <CreatableSelect {...input} {...rest} searchable />
    );
    var softwareField = (id) => {
      return <Field
        name={"projectSoftware" + id}
        isMulti
        component={ReactCreatableSelectAdapter}
        closeMenuOnSelect={false}
        options={allSofts}
        blurInputOnSelect={false}
        isSearchable={!isMobile}
        placeholder={softwarePlaceholder}
      />
    }
  }

  const generateScopeInput = ({ id }, projectHandler, removeProjectHandler, shouldDisplayPlus, shouldDisplayMinus) => {
    const titleRequired = !!(values['projectTitle'+id] || values['projectCompany'+id] ||  values['projectUrl'+id] || values['projectDescription'+id]);
    return (
      <div key={"project" + id} className={css.projectWrapper}>
        <div className={css.projectsButtonWrapper}>
          {shouldDisplayMinus ?
            <Tooltip title={intl.formatMessage({ id: "FieldProjects.remove" })}>
              <div className={css.removeProjectButton} onClick={() => removeProjectHandler(id)}>
                <span >
                  <BiMinusCircle size={"1.5em"} />
                </span>
              </div>
            </Tooltip>
            : null}
          {shouldDisplayPlus ?
            <Tooltip title={intl.formatMessage({ id: "FieldProjects.add" })}>
              <div className={css.addProjectButton} onClick={projectHandler}>
                <span>
                  <BiPlusCircle size={"1.5em"} />
                </span>
              </div>
            </Tooltip>
            : null}
        </div>
        <div className={css.fieldsWrapper}>
          <div className={css.projectFirstRowWrapper}>
            <FieldTextInput
                id={"projectTitle" + id}
                name={"projectTitle" + id}
                className={css.projectTitle}
                label={projectTitleLabel}
                type="textarea"
                placeholder={projectTitlePlaceholder}
                validate={titleRequired ? projectTitleRequired : null}
              />

            <FieldTextInput
              id={"projectCompany" + id}
              name={"projectCompany" + id}
              className={css.projectCompany}
              type="textarea"
              label={companyNameLabel}
              placeholder={companyNamePlaceholder}
            />
          </div>

            <FieldTextInput
              id={"projectDescription" + id}
              name={"projectDescription" + id}
              className={css.projectDescription}
              type="textarea"
              label={descriptionLabel}
              placeholder={descriptionPlaceholder}
              isDescription
            />

            <FieldTextInput
              id={"projectUrl" + id}
              name={"projectUrl" + id}
              className={css.projectUrl}
              type="text"
              label={urlLabel}
              placeholder={urlPlaceholder}
              // validate={validBusinessURL(notValidURLMessage)}
            />

            {typeof softwareField === 'function' ? softwareField(id) : null}

        </div>
      </div>
    );
  }

  const renderProjects = (projects, projectHandler, removeProjectHandler) => {
    return projects.map((project, index) => {
      const shouldDisplayPlus = projects.length >= 1 && (index === projects.length - 1);
      const shouldDisplayMinus = projects.length > 1 && (index < projects.length - 1);
      return (
        generateScopeInput(project, projectHandler, removeProjectHandler, shouldDisplayPlus, shouldDisplayMinus)
      );
    })
  }

  const addProjectHandler = () => {
    let lastProjectId = projectItems[projectItems.length - 1].id;
    setProjectItems([
      ...projectItems,
      {
        id: lastProjectId + 1,
      }
    ]);
  };

  const removeProjectHandler = (id) => {
    values[`projectTitle${id}`] = null;
    values[`projectCompany${id}`] = null;
    values[`projectDescription${id}`] = null;
    values[`projectUrl${id}`] = null;
    values[`projectSoftware${id}`] = null;
    const projectsAfterRemoval = projectItems.filter((project, index) => index > id || index < id);
    setProjectDeleted(true);
    setProjectItems([...projectsAfterRemoval]);
  }

  return (
    <div>
      {renderProjects(projectItems, addProjectHandler, removeProjectHandler)}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    productSoftware
  } = state.marketplaceData;
  return {
    productSoftware
  }
}

export default compose(
  connect(mapStateToProps)
)(FieldProjects);
