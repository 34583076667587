import React from 'react';
import { richText } from '../../util/richText';

import css from './ExpertPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionExperienceAndProjectsMaybe = props => {
  const { experience, projectsCount } = props;

  return (
    <div className={css.sectionExperienceAndProjects}>
      {experience ? (
        <div className={css.experienceAndProjectsWrapper}>
          <h2 className={css.smallPrintHeading}>Years of experience</h2>
          <p className={css.experienceAndProjects}>
            {richText(experience + (experience === '1' || experience === 1 ? '\u00A0year' : '\u00A0years'), {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </div>
      ) : null}
      {projectsCount ? (
        <div className={css.experienceAndProjectsWrapper}>
          <h2 className={css.smallPrintHeading}>Number of realized projects</h2>
          <p className={css.experienceAndProjects}>
            {richText(projectsCount + (projectsCount === '1' || projectsCount === 1 ? '\u00A0project' : '\u00A0projects'), {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </div>
      ) : null  }
    </div>
  )
};

export default SectionExperienceAndProjectsMaybe;