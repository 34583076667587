import React from 'react';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { AiOutlineCalendar } from 'react-icons/ai';
import { FormattedMessage } from '../../util/reactIntl';
import { SecondaryButton, NamedLink, AvatarLarge } from '../../components';
import { createSlug } from '../../util/urlHelpers';

import css from './ProjectBoardPage.css';

const SectionExpert = props => {
  const {
    expertsListingsData,

    isExpertOnProject,
    isAdminProvider,
    isProjectSolved,
  } = props;

  if (!expertsListingsData || isExpertOnProject) {
    return null;
  }

  const link = (
    <NamedLink name="CenterPage" params={{ tab: 'meetings' }}>
      here
    </NamedLink>
  );

  const expertsCount = expertsListingsData?.length;
  let expertsTitle;
  if (expertsCount === 1) {
    expertsTitle = 'Your expert:';
  }
  if (expertsCount > 1) {
    expertsTitle = 'Your experts:';
  }

  return (
    <div className={css.sectionExpertWrapper}>
      <p className={css.expertsTitle}>{expertsTitle}</p>

      {expertsListingsData.map(element => {
        const expertListing = element.data;
        const avatarData = element.included?.find(element => element.type === 'image');
        const expertAvatarUrl = avatarData?.attributes?.variants?.['square-small2x']?.url;

        const expertListingId = expertListing.id.uuid;
        const isPM = expertListingId === process.env.REACT_APP_ADMIN_PM_LISTING_ID;
        let expertLabel = expertListing.attributes.title;
        if (isPM) {
          expertLabel += ' (PM)';
        }

        return (
          <div key="ExpertDiv" className={css.expertContainer}>
            <div className={css.expertLinkContainer}>
              <div className={css.expertLinks}>
                <NamedLink
                  key="ExpertLink"
                  name="ExpertPage"
                  disabled={isAdminProvider}
                  params={{
                    id: expertListingId,
                    slug: createSlug(expertListing.attributes.title),
                    category: 'experts',
                  }}
                  className={css.yourExpertTitleLink}
                >
                  {expertLabel}
                </NamedLink>
              </div>

              <div key="ExpertAvatar" className={css.expertAvatarAspectWrapper}>
                <AvatarLarge
                  onlyImgUrl={expertAvatarUrl}
                  className={css.expertAvatar}
                  initialsClassName={css.expertInitials}
                  disableProfileLink
                />
              </div>
            </div>
            {!isProjectSolved ? (
              <NamedLink
                key="ExpertSolvedProject"
                name="ExpertPage"
                params={{
                  id: isAdminProvider
                    ? process.env.REACT_APP_ADMIN_BOOKING_LISTING_ID
                    : expertListing?.id.uuid,
                  slug: createSlug(isAdminProvider ? 'Michael' : expertListing.attributes.title),
                  category: 'experts',
                }}
                state={{
                  openBookModal: true,
                  freeBooking: true,
                }}
                className={css.bookCallButtonLink}
              >
                <SecondaryButton key="ButtonPMCall" className={css.button}>
                  <AiOutlineCalendar className={css.buttonIcon} />
                  <FormattedMessage id="ProjectBoardPage.expertCall" />
                  <Tooltip
                    interactive
                    title={
                      <FormattedMessage
                        id="ProjectBoardPage.expertCallTooltip"
                        values={{ link, br: <br />, who: isPM ? 'Project Manager' : 'expert' }}
                      />
                    }
                  >
                    <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
                  </Tooltip>
                </SecondaryButton>
              </NamedLink>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default SectionExpert;
