/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { bool, func, shape, string, oneOf } from 'prop-types';
import { ArrowLeft } from 'react-feather';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import SectionImages from '../ListingPage/SectionImages';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  Modal,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperMain,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  Footer,
  InlineTextButton,
  PrimaryButton,
  Sidebar,
} from '../../components';
import { TopbarContainer, AuthenticationPage } from '../../containers';
import { loadData, setInitialValues, showUser } from './ServicePage.duck';
import SectionTagsMaybe from './SectionTagsMaybe';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionExpertiseMaybe from './SectionExpertiseMaybe';
import SectionPriceTime from './SectionPriceTime';
import SectionServiceAuthor from './SectionServiceAuthor';
import SectionScopeMaybe from './SectionScopeMaybe';
import SectionBenefits from './SectionBenefits';
import SideActionBarMaybe from './SideActionBarMaybe';
import SectionShareMaybe from '../../components/SectionShareMaybe/SectionShareMaybe';
import css from './ServicePage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const PM_EXPERT_ID = process.env.REACT_APP_ADMIN_PM_LISTING_ID;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ServicePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentListing: {},
      pageClassNames: [],
      voteButtonErrorMessage: '',
      listingState: null,
      imageCarouselOpen: false,
      sidebarVisible: false,
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
    };

    this.goBack = this.goBack.bind(this);
    this.handlePurchasingService = this.handlePurchasingService.bind(this);
    this.handleCreatingProjectFromService = this.handleCreatingProjectFromService.bind(this);
    this.handleSidebarVisibility = this.handleSidebarVisibility.bind(this);
  }

  componentDidMount() {
    const isPendingApprovalVariant =
      this.props.params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    if (isPendingApprovalVariant) {
      this.setState({ newExpertModalOpen: true });
    }
  }

  goBack(listing) {
    const { history } = this.props;
    const routes = routeConfiguration();

    const category = listing.attributes.publicData.category;

    if (history.length > 1) {
      history.goBack();
    } else
      history.push(
        createResourceLocatorString('SearchPage', routes, {}, { pub_category: category })
      );
  }

  handlePurchasingService(currentListing) {
    const { history, fetchedExpertProfileForService, onBuyService, currentUser } = this.props;
    const routes = routeConfiguration();

    if (!currentUser) {
      const id = currentListing.id.uuid;
      const title = currentListing.attributes.title;
      const currentRoute = createResourceLocatorString(
        'ServicePage',
        routes,
        { id, slug: createSlug(title) },
        {}
      );
      history.push(createResourceLocatorString('SignupPage', routes, {}, {}), {
        from: currentRoute,
      });
    } else {
      const price = currentListing.attributes.price;
      const listingTitle = currentListing.attributes.title;
      const isSupportPlan = listingTitle === 'Basic' || listingTitle === 'Support';

      const initialValues = {
        expertId: !isSupportPlan ? fetchedExpertProfileForService?.id.uuid : PM_EXPERT_ID,
        listingTitle: listingTitle,
        listingIdPaidFor: currentListing.id.uuid,
        negotiatedTotal: price,
        commission: 1 / 3,
      };

      onBuyService(initialValues).then(txId => {
        history.push(createResourceLocatorString('OrderDetailsPage', routes, { id: txId }, {}));
      });
    }
  }

  handleCreatingProjectFromService(currentListing) {
    const { history } = this.props;
    const { publicData, title, description } = currentListing.attributes;
    const params = {
      slug: 'draft',
      id: '00000000-0000-0000-0000-000000000000',
      type: 'new',
      category: 'job',
    };
    const to = createResourceLocatorString(
      'QuickEditProjectPage',
      routeConfiguration(),
      params,
      {}
    );
    const initialValues = {
      title: title,
      description: description,
      scope:
        publicData.scope?.map(scopeItem => {
          return { task: scopeItem.name, subtasks: scopeItem.subtasks };
        }) ?? [],
      primarySoftware: publicData.primarySoftware,
      softwares: publicData.softwares,
    };
    history.push(to, { initialValues });
  }

  handleSidebarVisibility(state) {
    this.setState({ sidebarVisible: state });
  }

  render() {
    const {
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      currentUserHasExpertListing,
      currentUserHasUnpublishedExpertListing,
      currentUserExpertListing,
      fetchedExpertProfileForService,
      currentUserStripeAccounts,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = 'description';

    const category = currentListing?.attributes.publicData?.category;
    const params = { slug: listingSlug, category: `${category}s`, ...rawParams };

    const { description = '', price = null, title = '', publicData } = currentListing.attributes;

    const messageFormatting = '';
    const isSolved = publicData.state === 'closed';
    const isAwaiting = publicData.state === 'awaiting';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ServicePage" params={params} search={location.search} />;
    }

    const richTitle = (
      <h1>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </h1>
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <AuthenticationPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ServicePage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ServicePage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing
      const loadingTitle = intl.formatMessage({
        id: 'ServicePage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ServicePage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
          </LayoutSingleColumn>
        </Page>
      );
    }
    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
  

   
    const authorAvailable = currentListing && currentListing.author;
    const isOwnListing = currentListing?.author?.id?.uuid === currentUser?.id?.uuid;
    const isAdmin = currentUser?.id?.uuid === process.env.REACT_APP_ADMIN_USER_ID;
    const isPremiumUser = currentUser?.attributes.profile.publicData?.isPremiumUser;
    const isSupportPlan =
      currentListing.attributes.title === 'Basic' || currentListing.attributes.title === 'Support';
    // const showDiscussService = !isSupportPlan && !isAdmin && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    let { formattedPrice } = priceData(price, intl);
    formattedPrice = formattedPrice?.slice(0, formattedPrice.length - 3); // cut decimal part from price

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.serviceSchemaTitle' },
      { title, siteTitle }
    );

    const serviceAuthorExpertProfileId = !isSupportPlan
      ? fetchedExpertProfileForService?.id?.uuid
      : PM_EXPERT_ID;
    const serviceAuthorExpertProfileTitle = fetchedExpertProfileForService?.attributes?.title;
    //const serviceAuthorName = fetchedExpertProfileForService?.attributes?.publicData?.authorName;
    const serviceAuthorName = fetchedExpertProfileForService?.attributes?.title;
    const serviceAuthorExpertise =
      fetchedExpertProfileForService?.attributes?.publicData?.expertise;
    const serviceScope = currentListing.attributes.publicData.scope;

    const sidebarVisible = this.state.sidebarVisible;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSideNavigation className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperSideNav
            className={classNames(
              currentUser ? css.navigation : css.navigationNone,
              !sidebarVisible && css.navigationHidden
            )}
          >
            <Sidebar
              tab={isOwnListing ? 'services' : null}
              isAdmin={isAdmin}
              isExpert={currentUserHasExpertListing}
              isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
              isVisible={this.state.sidebarVisible}
              setVisibility={this.handleSidebarVisibility}
            />
          </LayoutWrapperSideNav>
          <LayoutWrapperMain>
            <div className={css.mainWrapper}>
              <div className={css.pageWrapper}>
                <div className={css.rowsWrapper}>
                  <div className={css.goBack}>
                    <InlineTextButton
                      className={css.goBackButton}
                      onClick={() => this.goBack(currentListing)}
                    >
                      <ArrowLeft className={css.goBackIcon} />
                      Back
                    </InlineTextButton>
                  </div>
                  <div className={css.sideBorderWrapper}>
                    <div className={css.firstRow}>
                      <div className={css.mainContentWrapper}>
                        <SectionTagsMaybe
                          softwares={publicData.softwares}
                          serviceCategories={publicData.serviceCategories}
                        />
                        <SectionExpertiseMaybe richTitle={richTitle} />
                        <div className={css.mobileServiceAuthor}>
                          {!isPendingApprovalVariant && !isDraftVariant ? (
                            <SectionServiceAuthor
                              isAdmin={isAdmin}
                              isPremiumUser={isPremiumUser}
                              isOwnListing={isOwnListing}
                              profileTitle={serviceAuthorExpertProfileTitle}
                              profileId={serviceAuthorExpertProfileId}
                              author={currentAuthor}
                              authorName={serviceAuthorName}
                              authorExpertise={serviceAuthorExpertise}
                              isService
                              isSupportPlan={isSupportPlan}
                            />
                          ) : null}
                          <SectionPriceTime
                            isAdmin={isAdmin}
                            isPremiumUser={isPremiumUser}
                            isOwnListing={isOwnListing}
                            currentUserStripeAccounts={currentUserStripeAccounts}
                            currentUser={currentUser}
                            formattedPrice={formattedPrice}
                            time={publicData.estimatedTime}
                          />
                        </div>
                        <SectionDescriptionMaybe description={description} />
                      </div>
                    </div>
                    <div className={css.secondRow}>
                      <div className={css.contentContainer}>
                        <div className={css.mainContent}>
                          <SectionBenefits expertBenefit={publicData.shortDescription} />
                          {!isSupportPlan ? (
                            <SectionScopeMaybe
                              scope={serviceScope}
                              formattedPrice={formattedPrice}
                              isOwnListing={isOwnListing}
                              isPremiumUser={isPremiumUser}
                              isAdmin={isAdmin}
                            />
                          ) : null}
                          <SectionImages
                            title={title}
                            listing={currentListing}
                            imageCarouselOpen={this.state.imageCarouselOpen}
                            onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                            handleViewPhotosClick={handleViewPhotosClick}
                            onManageDisableScrolling={onManageDisableScrolling}
                            isAdmin={isAdmin}
                            hasExpertProfile={currentUserHasExpertListing}
                          />
                          <SectionShareMaybe
                            messageFormatting={messageFormatting}
                            isApproved={isApproved}
                            listingTitle={title}
                            listingCategory={category}
                            listingId={currentListing.id.uuid}
                            listingType={'service'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={css.sideBar}>
                  {!isPendingApprovalVariant ? (
                    <SectionServiceAuthor
                      profileTitle={serviceAuthorExpertProfileTitle}
                      profileId={serviceAuthorExpertProfileId}
                      author={currentAuthor}
                      authorName={serviceAuthorName}
                      authorExpertise={serviceAuthorExpertise}
                      isService
                      isSupportPlan={isSupportPlan}
                    />
                  ) : null}
                  <SectionPriceTime
                    currentUserStripeAccounts={currentUserStripeAccounts}
                    currentUser={currentUser}
                    formattedPrice={formattedPrice}
                    time={publicData.estimatedTime}
                  />
                  <SideActionBarMaybe
                    isOwnListing={isOwnListing}
                    listing={currentListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                      category: 'service',
                    }}
                    publicData={publicData}
                    currentUser={currentUser}
                    isAdmin={isAdmin}
                    isSolved={isSolved}
                    isAwaiting={isAwaiting}
                    onContactUser={this.onContactUser}
                    windowWidth={this.state.windowWidth}
                    isServiceProviderLoaded={!!fetchedExpertProfileForService || isSupportPlan}
                    onCreateProjectFromService={() =>
                      this.handleCreatingProjectFromService(currentListing)
                    }
                    // showDiscussService={showDiscussService}
                    isPendingApprovalVariant={isPendingApprovalVariant}
                  />
                </div>
              </div>
            </div>
            <SideActionBarMaybe
              isOwnListing={isOwnListing}
              listing={currentListing}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingType,
                tab: listingTab,
                category: 'service',
              }}
              publicData={publicData}
              currentUser={currentUser}
              isAdmin={isAdmin}
              isSolved={isSolved}
              onContactUser={this.onContactUser}
              windowWidth={this.state.windowWidth}
              isServiceProviderLoaded={!!fetchedExpertProfileForService || isSupportPlan}
              onCreateProjectFromService={() =>
                this.handleCreatingProjectFromService(currentListing)
              }
              // showDiscussService={showDiscussService}
              isMobile
            />
            <Modal
              id="ServicePage.forNewExperts"
              contentClassName={css.enquiryModalContent}
              isOpen={this.state.newExpertModalOpen && !currentUserHasExpertListing}
              onClose={() => this.setState({ newExpertModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              usePortal
            >
              <div className={css.addServiceModal}>
                <h1>Please wait</h1>
                <p className={css.addServiceModalText}>
                  Your{' '}
                  <a
                    href={`/experts/${createSlug(currentUserExpertListing?.attributes.title)}/${
                      currentUserExpertListing?.id.uuid
                    }/pending-approval`}
                  >
                    Expert profile
                  </a>{' '}
                  and your first{' '}
                  <span
                    className={css.linkStyled}
                    onClick={() => this.setState({ newExpertModalOpen: false })}
                  >
                    Service
                  </span>{' '}
                  are waiting for SoftwareSupp approval.
                  <br />
                  Now, we recommend that you check the offers tailored to your profile by clicking
                  the button below:
                </p>
                <a className={css.editListingLink} href={`/jobs`}>
                  <PrimaryButton>
                    <FormattedMessage id="ServicePage.newExpertCTA" />
                  </PrimaryButton>
                </a>
                <a href="/softwaresupp-expert" className={css.newExpertReadMoreLink}>
                  Read more about the benefits of being a Softwaresupp expert
                </a>
              </div>
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

ServicePageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  showListingError: null,
};

ServicePageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, authScopes } = state.Auth;
  const { showListingError, fetchedExpertProfileForService } = state.ServicePage;
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserExpertListing,
    currentUserStripeAccounts,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingError,
    currentUserHasListings,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserExpertListing,
    fetchedExpertProfileForService,
    authScopes,
    currentUserStripeAccounts,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onShowUser: userId => dispatch(showUser(userId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ServicePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ServicePageComponent);

ServicePage.setInitialValues = initialValues => setInitialValues(initialValues);
ServicePage.loadData = loadData;

export default ServicePage;
