import React, {useState} from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Modal, PrimaryButton} from '../../components';
import { exportToCSV } from '../../util/api';

import css from './ExportCSVModal.css';

const EXPORT_TYPES = [
  'projects',
  'projects-ongoing',
  'projects-closed',
  'projects-pending-approval',
  'projects-open',
  'experts',
  'experts-pending-approval',
  'users'
]

const ExportCheckbox = props => {
  const { name, id, setMainCategory, isChecked, intl, setGenerateCSVError, setNoFoundEntitiesError } = props;
  return (
    <div className={css.checkboxWrapper}>
      <input
        name={name}
        id={id}
        type="radio"
        checked={isChecked}
        onChange={() => {
          isChecked ? setMainCategory(null) : setMainCategory(id);
          setGenerateCSVError(false);
          setNoFoundEntitiesError(null);
        }}
        className={css.checkbox}
      />
      <label className={css.label} htmlFor={id}>{intl.formatMessage({ id: name })}</label>
    </div>
  )
}

const ExportCSVModal = props => {
  const {
    intl,
    isOpen,
    onManageDisableScrolling,
    currentUser,
    onClose
  } = props;

  const [mainCategoryChecked, setMainCategoryChecked] = useState();
  const [generateInProgress, setGenerateInProgress] = useState(false);
  const [generateCSVError, setGenerateCSVError] = useState(false);
  const [noFoundEntitiesError, setNoFoundEntitiesError] = useState(false);

  const checkboxes = EXPORT_TYPES.map(type =>
    <ExportCheckbox
      key={`${type}`}
      name={`ExportCSVModal.${type}`}
      id={`${type}`}
      isChecked={mainCategoryChecked === type}
      setMainCategory={setMainCategoryChecked}
      intl={intl}
      setGenerateCSVError={setGenerateCSVError}
      setNoFoundEntitiesError={setNoFoundEntitiesError}
    />
  )

  const generateExportRequestBody = () => {
    return {
      type: mainCategoryChecked,
      userId: currentUser.id.uuid
    }
  }

  const onExportToCSVClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setGenerateInProgress(true);
    exportToCSV(generateExportRequestBody())
      .then(response => {          
        setGenerateInProgress(false);
        if (response.error) {
          setNoFoundEntitiesError(response.error);
        }
        else if(response) {
          const hiddenElement = document.createElement('a');
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response.body);
          hiddenElement.target = '_blank';
          hiddenElement.download = `${mainCategoryChecked}Export.csv`;
          hiddenElement.click();
        }
      })
      .catch(error => {
        setGenerateInProgress(false);
        setGenerateCSVError(true);
        console.error(error);
      })
  }

  return (
    <Modal
    id="ExportCSVModal"
      containerClassName={css.root}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      {checkboxes}
      <p className={css.error}>
        {generateCSVError && <FormattedMessage id="ExportCSVModal.error" />}
        {noFoundEntitiesError ? noFoundEntitiesError : null}
      </p>

      <PrimaryButton 
        onClick={e => onExportToCSVClick(e)}
        disabled={!mainCategoryChecked}
        inProgress={generateInProgress}
      >
        <FormattedMessage id="ExportCSVModal.export"/>
      </PrimaryButton>
    </Modal>
  );
};

export default injectIntl(ExportCSVModal);