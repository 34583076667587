import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { createExamTemplate, readExistingExamListings } from './ExamsAdminPage.duck';
import {
  NamedRedirect,
  Sidebar,
  Page,
  PrimaryButton,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { FaRegDotCircle } from 'react-icons/fa';
import { TopbarContainer } from '../../containers';
import css from './ExamsAdminPage.css';

export const ExamsAdminPageComponent = props => {

  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled,
    onCreateExamTemplate,
    onReadExistingExams
  } = props;
 
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [creatingExamInProgress, setCreatingExamInProgress] = useState(false);
  const [creatingExamMessage, setCreatingExamMessage] = useState();
  const [existingExams, setExistingExams] = useState([]);

  useEffect(() => {
    readExistingExams(onReadExistingExams);
  }, [onReadExistingExams]);

  const readExistingExams = async (onReadExistingExams) => {
    const { data, meta } = await onReadExistingExams();
    if(meta?.totalItems > 0) {
      setExistingExams(data);
    }
  }

  const title = `Exams`;
  const isAdmin = currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

  if (currentUser?.id && !isAdmin) {
    return <NamedRedirect name="NotificationsPage" params={{ tab: 'notifications' }} />;
  }

  const onCreateExam = async () => {
    setCreatingExamInProgress(true);
    const description = "Change this field to describe exam.";
    const config = {
      certificationName: "",
      passThreshold: 80,
      examTimeEnabled: true, 
      examTime: 10, // in minutes
      questionTimeEnabled: false,
      maxAttempts: 1, 
      goingBackEnabled: false
    };
    const questions = [...Array(10).keys()].map((questionIndex) => {
      return {
        title: `Question ${questionIndex}`,
        answers: [
          "Answer 0",
          "Answer 1",
          "Answer 2"
        ],
        correctAnswers: [0,1],
        answerTime: null
      }
    })
    const result = await onCreateExamTemplate(description, config, questions);
    if(!!result?.data?.id.uuid) {
      setCreatingExamMessage(`Exam template created with id: ${result.data.id.uuid}`);
    }
    setCreatingExamInProgress(false);
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="ExamsAdminPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}>
          <Sidebar 
            tab={'examsAdmin'}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
        {/* {error} */}
        <div className={css.mainWrapper}>
          <div className={css.mainButtonsWrapper}>
            <PrimaryButton className={css.createButton}
              disabled={false}
              inProgress={creatingExamInProgress}
              onClick={onCreateExam}
            >
              <FormattedMessage id="ExamsAdminPage.createExamTemplate" />
            </PrimaryButton>
          </div>
          <div className={css.creatingExamMessageWrapper}>
            {creatingExamMessage}
          </div>
          <div className={css.examsListWrapper}>
            {'Approved exams list: '}
            <ul className={css.examsList}>
              {existingExams.map((examItem) => {
                return (
                  <li key={examItem.id.uuid + 'exam'}>
                    <FaRegDotCircle />{'\u00A0'}
                    {examItem.attributes.title + "(id: " + examItem.id.uuid + ")"}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;
  return {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateExamTemplate: (description, config, questions) => dispatch(createExamTemplate(description, config, questions)),
  onReadExistingExams: () => dispatch(readExistingExamListings()),
});

const ExamsAdminPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ExamsAdminPageComponent);

export default ExamsAdminPage;