import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '../../components';
import { AddRepoForm } from '../../forms';

import css from './RepoModal.css';

const RepoModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmitRepo,
    project,
    repoList,
    isProjectSolved,
    isPendingApproval
  } = props;

  const listingRepo = project?.attributes.publicData.repository ?? [];

  const classes = classNames(rootClassName || css.root, className);
  const links = repoList.length > 0 ? repoList : [...listingRepo];

  const titledLinks = links ? links.map( repo => {
    return <li><a href={`${repo.repoLink}`} target="_blank" rel="noopener noreferrer">{repo.repoTitle}</a></li>
    }) : null;

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
    <h1>Documents</h1>
    <ol className={css.repoLinks}>{titledLinks}</ol>
      {!isProjectSolved
        ? <AddRepoForm onSubmit={onSubmitRepo} isPendingApproval={isPendingApproval} />
        : null}
    </Modal>
  );
};

const { string } = PropTypes;

RepoModal.defaultProps = {
  className: null,
  rootClassName: null,
};

RepoModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(RepoModal);
