import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { createExamTemplate, readExistingExamListings } from './ExamSuggestQuestionPage.duck';
import {
  Sidebar,
  Page,
  PrimaryButton,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  NamedLink,
  Footer,
} from '../../components';
import { newQuestionForExam} from '../../util/triggerMail';
import { TopbarContainer } from '../../containers';
import { QuestionSuggestForm } from './QuestionSuggestForm/QuestionSuggestForm';
import css from './ExamSuggestQuestionPage.css';

const parseData = (data) => {
  const result = {
    examType: JSON.stringify(data.examType),
    question: data.question,
  };
  let answersEntries = Object.entries(data).filter((entry) => {
    return (entry[0].includes('answer') && (entry[1]?.length > 0));
  });
  let answersValidity = Object.entries(data).filter((entry) => {
    return (entry[0].includes('answer') && (typeof entry[1] === "boolean"));
  });
  let answers = [];
  for(let i = 0; i < answersEntries.length; i++) {
    let answer = answersEntries.find((el) => {
      return el[0].includes(i.toString());
    });
    let answerValidity = answersValidity.find((el) => {
      return el[0].includes(i.toString()+'checkbox');
    });
    answers.push(answer[1]+` (Validity: ${answerValidity[1]})`);
  }
  result['answers'] = answers;
  return result;
}

export const ExamSuggestQuestionPageComponent = props => {

  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled,
    productSoftware
  } = props;
 
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [questionSent, setQuestionSent] = useState(false);

  const onSubmitQuestion = (data) => {
    const emailData = parseData(data);
    emailData['authorId'] = currentUser.id.uuid;
    emailData['authorName'] = currentUser.attributes.profile.displayName;
    emailData['authorEmail'] = currentUser.attributes.email;
    newQuestionForExam(emailData);
    setQuestionSent(true);
  }

  const title = "Suggest Question";
  const isAdmin = currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="ExamSuggestQuestionPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}>
          <Sidebar 
            tab={'examPage'}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
        {/* {error} */}
        <div className={css.mainWrapper}>
          {questionSent ?
            <>
              <h2>
                Question sent!
              </h2>
              <h4>
                Your question will be verified by SoftwareSupp and you will be notified about verification result.
              </h4>
              <div className={css.buttonWrapper}>
                <NamedLink name={"ExamsListPage"}>
                  <PrimaryButton className={css.createButton}>
                    <FormattedMessage id="ExamPage.goBackButton" />
                  </PrimaryButton>
                </NamedLink>
              </div>
            </>
          :
            <>
              <h2>
                Suggest new exam question
              </h2>
              <h4>
                Your question will be verified by SoftwareSupp and you will be notified about verification result.
              </h4>
              <QuestionSuggestForm onSubmit={onSubmitQuestion} productSoftware={productSoftware}/>
            </>

          }
        </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;
  const {
    productSoftware
  } = state.marketplaceData;
  return {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing, 
    productSoftware,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateExamTemplate: (description, config, questions) => dispatch(createExamTemplate(description, config, questions)),
  onReadExistingExams: () => dispatch(readExistingExamListings()),
});

const ExamSuggestQuestionPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ExamSuggestQuestionPageComponent);

export default ExamSuggestQuestionPage;