/* eslint-disable */
import React, { Component } from 'react';

import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { newNPSMail, feedbackReward } from '../../util/triggerMail';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { parse } from '../../util/urlHelpers';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '..';
import { NPSSurveyForm } from '../../forms';
import { loadData, fetchExpert } from './NPSSurveyPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import SectionAvatar from '../../components/ExpertOverview/SectionAvatar';
import SectionExamResultsMaybe from '../../components/ExpertOverview/SectionExamResultsMaybe';
import SectionTagsMaybe from '../../components/ExpertOverview/SectionTagsMaybe';
import SectionVerifiedSoftwaresMaybe from '../../components/ExpertOverview/SectionVerifiedSoftwaresMaybe';
import SectionDescriptionMaybe from '../../components/ExpertOverview/SectionDescriptionMaybe';
import SectionExperienceAndProjectsMaybe from '../../components/ExpertOverview/SectionExperienceAndProjectsMaybe';
import SectionExpertiseAndNameMaybe from '../../components/ExpertOverview/SectionExpertiseAndNameMaybe';
import SectionLinksMaybe from '../../components/ExpertOverview/SectionLinksMaybe';
import VendorVerificationPanel from '../../components/ExpertOverview/VendorVerificationPanel';

import { AiOutlineStock, AiFillPlusCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';

import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../../util/urlHelpers';

import css from './NPSSurveyPage.css';
import expertCss from '../../components/ExpertOverview/ExpertPage.css'

import { bool, func, object, shape, number} from 'prop-types';
import arrowIcon from '../../assets/arrow.svg';

const npsCategories = ['Overall', 'Communication', 'Expert', 'PM', 'Project'];

const { UUID } = sdkTypes;

export class NPSSurveyPageComponent extends Component {
  constructor(props) {
    super(props);
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    this.props.staticContext.notfound = true;

    this.state = {
      relatedExpert: null,
      localPlusValue: 0,
      voteButtonErrorMessage: '',
      listingId: '',
      chosenNumber: new Array(5),
      submitted: false,
      reviewModalOpen: false,
      npsVersion: 'basic',
    };

    this.onSubmitFeedback = this.onSubmitFeedback.bind(this);
    this.onChangeChosenNumber = this.onChangeChosenNumber.bind(this);
    this.onChangeNPSVersion = this.onChangeNPSVersion.bind(this);

  }

  componentDidMount() {
    const { location } = this.props;
    const { ref } = parse(location.search);
    const initialNumberArray = this.state.chosenNumber;
    if (this.state.chosenNumber[0] === undefined){
      initialNumberArray[0] = ref
    }
    this.setState({ chosenNumber: initialNumberArray })
  }

  async componentDidUpdate() {
    const { params, getListing, fetchExpertError, onFetchExpert } = this.props;

    const currentListing = getListing(new UUID(params.id));
    //let expertProfileId = null;
    if (currentListing?.attributes.publicData.expert && !this.state.relatedExpert && !fetchExpertError) {
      //expertProfileId = await onFetchExpert(currentListing.attributes.publicData.expert[0]);
      //this.setState({ relatedExpert: getListing(new UUID(expertProfileId)) })
      this.setState({ relatedExpert: await onFetchExpert(currentListing.attributes.publicData.expert[0]) })
    }
  }

  onSubmitFeedback(values) {
    const { params, currentUser, getListing, reviewers, history } = this.props;
    const { chosenNumber } = this.state;

    const currentListing = getListing(new UUID(params.id));
    const name = currentUser?.attributes.profile.firstName ?? values.userName;

    newNPSMail(chosenNumber, values, params.id, name, npsCategories);

    const { state, expert } = currentListing?.attributes.publicData;
    const isInProgress = state === 'inProgress';
    if (isInProgress) {

      const averageNumber = chosenNumber.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue;
      }) / chosenNumber.filter(number => !!number)?.length;
      const mail = currentUser?.attributes.email ?? values.email;

      if (averageNumber >= 7 && mail && expert?.length > 0 ) {
        feedbackReward(mail, name)
        //recommendExpertMail(currentListing, mail, name)
      }
    }

    this.setState({ submitted: true });
    if(window!==undefined){
      window.scroll({ top: 0, left: 0, });
      history.push(
        createResourceLocatorString('ExpertPage', routeConfiguration(), {
          id: this.state.relatedExpert.data.id.uuid,
          slug: createSlug(this.state.relatedExpert.data.attributes.title),
          category: 'experts',
        }, {action: 'recommend'})
      );
    }
  }

  onChangeChosenNumber(number, index) {
    const currentArray = this.state.chosenNumber;
    currentArray[index] = number;
    this.setState({ chosenNumber: currentArray })
  }

  onChangeNPSVersion(version) {
    this.setState({ npsVersion: version })
  }

  render() {
    const { 
      intl,
      scrollingDisabled, 
      currentUser,
      currentUserHasListings, 
      currentUserFetched, 
      reviewers,
    } = this.props;
    if (this.state.relatedExpert !== null){

      const { publicData, description = '',} = this.state.relatedExpert.data.attributes;
      const authorAvailable = publicData && publicData.expert;
      const currentAuthor = authorAvailable ? publicData.expert : null;
      const expertExamResults = this.state.relatedExpert?.author?.attributes?.profile?.publicData?.examResults;
      const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
      const isOwnListing =
        userAndListingAuthorAvailable && publicData.expert === currentUser.id.uuid;
      const title = intl.formatMessage({ id: 'NPSSurveyPage.title' });
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      const handlePlusButton = async () => {
        if (!currentUser) {
          await sleep(1500);
        }
        // verify if adding value is feasible
        let isAuthorTheSame = currentUser?.id.uuid === this.state.relatedExpert.data.id.uuid;
        //currentUserHasListings
        let alreadyRecommended = this.state.relatedExpert?.upvoteSenderIds?.find(id => id === currentUser.id.uuid) || 
        reviewers.find(reviewer => reviewer === currentUser?.id.uuid);
        if (!currentUser) {
          // signup and return back to listingPage.
          this.props.history.push(
            createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
            this.state
          );
        } else if (isAuthorTheSame) {
          this.setState({ voteButtonErrorMessage: 'ExpertPage.recommendationErrorOwnProfile' });
        } else if (!currentUserHasListings) {
          this.setState({ voteButtonErrorMessage: 'ExpertPage.recommendationErrorNoActiveListings' });
        } else if (alreadyRecommended) {
          this.setState({
            voteButtonErrorMessage: 'ExpertPage.recommendationErrorAlreadyRecommended',
          });
        } else {
          if(window!==undefined){
            window.open(
              createResourceLocatorString('ExpertPage', routeConfiguration(), {
                id: this.state.relatedExpert.data.id.uuid,
                slug: createSlug(this.state.relatedExpert.data.attributes.title),
                category: 'experts',
              }, {action: 'recommend'}), '_blank'
            );
          }
        }
        this.setState({ isPlusButtonActive: false });
      };
  
      const voteButton =
        <div
          className={expertCss.plusButtonWrapper}
          onClick={() => {
            handlePlusButton();
          }}
        >
          <AiFillPlusCircle
            size={24}
            className={expertCss.plusButtonActive}
            style={{ marginBottom: '5px' }}
          />
        </div>

      return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn>
            <LayoutWrapperTopbar>
              <TopbarContainer />
            </LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <div className={css.mainWrapper}>
                <NPSSurveyForm
                  id="NPSSurveyForm"
                  onSubmit={this.onSubmitFeedback}
                  chosenNumber={this.state.chosenNumber}
                  changeChosenNumber={this.onChangeChosenNumber}
                  submitted={this.state.submitted}
                  npsCategories={npsCategories}
                  isLoggedIn={!!currentUser}
                  currentUserFetched={currentUserFetched}
                  version={this.state.npsVersion}
                  onChangeNPSVersion={this.onChangeNPSVersion}
                />
                {this.state.submitted ?
                  <div className={css.submitted}>
                    <p className={css.thankYou}>Thank you!</p>
                    <FormattedMessage id="NPSSurveyPage.stateSubmitted" />
                  </div> : null}
              
                <div className={expertCss.firstRow}>
                  <div className={expertCss.sectionAvatarAndLinks}>
                    <SectionAvatar user={{id: this.state.relatedExpert.included[1].id,
                    attributes: this.state.relatedExpert.included[1].attributes,
                    profileImage: this.state.relatedExpert.included[0],
                    type: "user"}} />
                    <SectionLinksMaybe
                      linkedin={publicData ? publicData.linkLinkedin : null}
                      calendly={publicData ? publicData.linkCalendly : null}
                      web={publicData ? publicData.linkWeb : null}
                    />
                  </div>
                  <div className={expertCss.mainContentWrapper}>
                    <span>
                      <AiOutlineStock size={20} />
                      <a className={expertCss.recommendationsLink}>
                        <span className={expertCss.viewRecommendations}>
                          <span className={expertCss.recommendationsText}>
                            <FormattedMessage id="ExpertPage.viewRecommendations" />
                          </span>
                          {/*upvotesCounter > 0 ? `(${reviews?.length + upvotesCounter})` : null*/}
                        </span>
                        <span className={expertCss.recommendationTooltip}>
                          <Tooltip
                            title={
                              <FormattedMessage id="ExpertPage.sendRecommendationExplanation" />
                            }
                          >
                            <div className={expertCss.tooltipIcon}>
                              <AiOutlineInfoCircle style={{ fontSize: 16 }} />
                            </div>
                          </Tooltip>
                        </span>
                      </a>
                      {voteButton}
                      <img src={arrowIcon} alt="arrow" width="50" className={css.arrow}></img>
                    </span>
                    {this.state.voteButtonErrorMessage !== '' && (
                          <div className={expertCss.plusButtonErrorMessage}>
                            <FormattedMessage id={this.state.voteButtonErrorMessage} />
                          </div>
                        )}
                    <SectionExpertiseAndNameMaybe
                      expertName={this.state.relatedExpert.data.attributes.title}
                      expertise={publicData?.expertise}
                    />
                    {/* <SectionLanguagesMaybe initialLanguages={publicData.languages} /> */}
                    <SectionExperienceAndProjectsMaybe
                      experience={publicData ? publicData.experience : null}
                      projectsCount={publicData ? publicData.projectsCount : null}
                    />
                    <SectionTagsMaybe
                      softwares={publicData.softwares}
                      skills={publicData.skills}
                      industries={publicData.industries}
                    />
                    <SectionVerifiedSoftwaresMaybe
                      verifiedSoftwares={this.state.verifiedSoftwares}
                    />
                    <SectionExamResultsMaybe examResults={expertExamResults} />
                    <SectionDescriptionMaybe description={description} />
                  </div>
                  <div className={expertCss.sideBar}>
                    <VendorVerificationPanel
                      currentUser={currentUser}
                      currentListing={this.state.relatedExpert.data}
                      expertVerifiedSoftwares={this.state.verifiedSoftwares}
                      verifiedSoftwaresCallback={this.verifiedSoftwaresCallback}
                      isOwnListing={isOwnListing}
                    />
                  </div>
                </div>
              </div>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
            <Footer/>
          </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }
    else{return null}
    }
}

NPSSurveyPageComponent.defaultProps = {
  staticContext: {},
};

NPSSurveyPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserHasListings: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasListings,
    currentUserFetched
  } = state.user;

  const {
    fetchExpertError,
    reviewers
  } = state.NPSSurveyPage;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserHasListings,
    currentUserFetched,
    fetchExpertError,
    reviewers,
    getListing
  };

};

const mapDispatchToProps = dispatch => ({
  onFetchExpert: expertId => dispatch(fetchExpert(expertId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NPSSurveyPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(NPSSurveyPageComponent);

NPSSurveyPage.loadData = loadData;

export default NPSSurveyPage;
