import React from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { IconSpinner } from '../../components';

import InvoiceItem from './InvoiceItem';
import css from './BillingPage.css';

const UserInvoicesPanel = props => {
  const { userInvoices } = props;

  const invoicePanel = (
    <div >
      <h3>Invoices</h3>
      {!userInvoices ? (
        <div>
          <div className={css.stripeAccountsContent}>
            <IconSpinner />
          </div>

          <p className={css.loadingInfo}>
            <FormattedMessage id="BillingPage.loadingInvoice" />
          </p>
        </div>
      ) : (
        <div>
          {userInvoices.length === 0 ? (
            <p className={css.loadingInfo}>
              <FormattedMessage id="BillingPage.noInvoices" />
            </p>
          ) : null}
          <ul>
            {userInvoices.map(invoice => {
              return (
                <li key={invoice.id} className={css.listItem}>
                  <InvoiceItem invoice={invoice} />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );

  return <div className={css.stripeAccountsContent}>{invoicePanel}</div>;
};

export default injectIntl(UserInvoicesPanel);
