import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LISTING_STATE_DRAFT } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, PrimaryButton, NamedLink, JobApplicationPanel } from '..';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './AcceptMeetingRequest.css';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const { Money } = sdkTypes;

const priceData = (price, commission = 0.5, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPriceUser = formatMoney(intl, price);
    const formattedPriceExpert = formatMoney(
      intl,
      new Money(price.amount * (1 - commission), config.currency)
    );
    return { formattedPriceUser, formattedPriceExpert, priceTitle: formattedPriceUser };
  } else if (price) {
    return {
      formattedPriceUser: `(${price.currency})`,
      formattedPriceExpert: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isTransaction, isClosed, history, location) => {
  if (isClosed) {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    history,
    location,
    intl,
    isUser,
    isAdmin,
    hasExpertProfile,
    currentUser,
    isTransaction,
    transactionRole,
    estimatedPriceMaybe,
    onSubmitRedirect,
    currentCustomerStripe,
    currentProviderStripe,
    currentUserHasStripeConnected,
    expertListingIsPendingApproval,
    showPaymentMissing,
    onContactUser,
    showContactUser,
    showAcceptOffer,
    messageFormatting,
    editParams,
    expertStack,
    handleNewJobOffer,
  } = props;

  const price = estimatedPriceMaybe;
  const budget = listing.attributes.price;

  const expertStackMatching =
    (expertStack &&
    expertStack.find(software => software === listing.attributes.publicData.primarySoftware)
      ? true
      : false) ||
    isTransaction ||
    listing?.attributes.publicData?.primarySoftware === 'other';

  const timeZone = listing.attributes.availabilityPlan?.timezone
    ? listing.attributes.availabilityPlan?.timezone
    : typeof window !== 'undefined'
    ? getDefaultTimeZoneOnBrowser()
    : 'Etc/UTC';
  const hasListingState = !!listing.attributes.state;
  const isClosed =
    hasListingState &&
    (listing.attributes.state === LISTING_STATE_CLOSED ||
      listing.attributes.publicData.state !== 'open');
  const isAwaiting = hasListingState && listing.attributes.publicData.state === 'awaiting';
  const showApplicationForm =
    !!currentUser &&
    !isOwnListing &&
    !isAdmin &&
    !isClosed &&
    ((isTransaction && transactionRole === 'customer') || (!isTransaction && !isOwnListing));
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPriceExpert, formattedPriceUser, priceTitle } = priceData(
    price,
    listing.attributes.publicData?.commission,
    intl
  );
  const isBook = !!parse(location.search).book;
  const formattedBudget =
    isTransaction && listing.attributes.price ? formatMoney(intl, listing.attributes.price) : null;

  const bookingStripe =
    !currentProviderStripe && isOwnListing && showPaymentMissing && showPaymentMissing.booking;

  const softwaresuppEmail = (
    <a href={config.softwaresuppEmail}>
      <FormattedMessage id="BookingPanel.softwaresuppEmail" />
    </a>
  );
  const termsLink = (
    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
      <FormattedMessage id="JobApplicationForm.termsOfUse" />
    </NamedLink>
  );

  const payoutPageLink = (
    <NamedLink name="StripePayoutPage">
      <FormattedMessage id="TransactionPanel.addPayoutDetails" />
    </NamedLink>
  );

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);
  const category = listing.attributes.publicData?.category;
  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
  const message = isDraft
    ? `ListingPage.finishListing${messageFormatting}`
    : `ListingPage.editListing${messageFormatting}`;

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h2 className={css.title}>{title}</h2>
        </div>
        <div className={css.bookingHeading}>
          <div className={css.bookingHeadingContainer}>
            <h2 className={titleClasses}>{title}</h2>
            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
          </div>
        </div>

        {showApplicationForm ? (
          <JobApplicationPanel
            currentUser={currentUser}
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            transactionRole={transactionRole}
            unitType={unitType}
            onSubmit={values => {
              onSubmit(values);
            }}
            isOwnListing={isOwnListing}
            listingId={listing.id}
            isUser={isUser}
            isAdmin={isAdmin}
            hasExpertProfile={hasExpertProfile}
            currentUserHasStripeConnected={
              currentUserHasStripeConnected ? currentUserHasStripeConnected : currentCustomerStripe
            }
            isTransaction={isTransaction}
            expertListingIsPendingApproval={expertListingIsPendingApproval}
            messageFormatting={messageFormatting}
            expertStackMatching={expertStackMatching}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
          />
        ) : null}

        {!!price && isTransaction ? (
          <div className={css.pricingInfo}>
            <>
              <div>
                {!isOwnListing ? (
                  <FormattedMessage id="BookingPanel.usersBudgetInfo" />
                ) : (
                  <FormattedMessage id="BookingPanel.yourBudgetInfo" />
                )}
              </div>
              <div className={css.desktopBudgetValue} title={priceTitle}>
                {budget ? formattedBudget : <FormattedMessage id="BookingPanel.budgetUnknown" />}
              </div>
            </>
            {!isOwnListing ? (
              <FormattedMessage id="BookingPanel.yourOfferInfo" />
            ) : (
              <FormattedMessage id="BookingPanel.offerInfo" />
            )}

            {isOwnListing ? (
              <div className={css.desktopPriceValue} title={priceTitle}>
                {formattedPriceUser}
              </div>
            ) : (
              <div className={css.desktopPriceValue} title={priceTitle}>
                {formattedPriceExpert}
              </div>
            )}
          </div>
        ) : null}

        {showPaymentMissing ? (
          <div>
            {(!isOwnListing && !currentCustomerStripe) || bookingStripe ? (
              <p className={css.smallPrintStripeNeeded}>
                <FormattedMessage
                  id="TransactionPanel.stripeConnectionNeeded"
                  values={{ payoutPageLink: payoutPageLink }}
                />
              </p>
            ) : null}
            <PrimaryButton
              disabled={(!currentCustomerStripe && !isOwnListing) || bookingStripe}
              onClick={() => onSubmitRedirect()}
              type="submit"
            >
              <FormattedMessage id="TransactionPanel.requestPayment" />
            </PrimaryButton>
            {isOwnListing && !showPaymentMissing.booking ? (
              <FormattedMessage
                id={'BookingTimeForm.priceExplanation'}
                values={{
                  br: <br />,
                  terms: termsLink,
                  softwaresuppEmail: softwaresuppEmail,
                }}
              />
            ) : !!showPaymentMissing.booking ? null : (
              <FormattedMessage
                id={'BookingTimeForm.acceptProjectExplanation'}
                values={{ br: <br /> }}
              />
            )}
          </div>
        ) : null}

        {isOwnListing && !!hasExpertProfile && !!onSubmitRedirect && showAcceptOffer ? (
          <div className={css.acceptOfferWrapper}>
            <PrimaryButton onClick={() => onSubmitRedirect()} type="submit">
              <FormattedMessage id="BookingTimeForm.redirectToExpertPage" />
            </PrimaryButton>
            <p className={css.priceExplanation}>
              <FormattedMessage
                id={'BookingTimeForm.priceExplanation'}
                values={{ br: <br />, terms: termsLink, softwaresuppEmail: softwaresuppEmail }}
              />
            </p>
          </div>
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        {showApplicationForm && currentUser.attributes.profile.publicData?.isVerified ? (
          <>
            <PrimaryButton
              className={css.answerButtonWithMarginRight}
              // disabled={!expertStackMatching && !isTransaction}
              onClick={
                transactionRole === 'customer' || !transactionRole
                  ? () => handleNewJobOffer()
                  : () => openBookModal(isOwnListing, isTransaction, isClosed, history, location)
              }
            >
              {isTransaction && transactionRole === 'customer' ? (
                <FormattedMessage id="BookingTimeForm.sendNewOffer" />
              ) : isTransaction ? (
                <FormattedMessage id="BookingPanel.showOffer" />
              ) : (
                <FormattedMessage id={`BookingPanel.ctaButtonMessage${messageFormatting}`} />
              )}
            </PrimaryButton>
            {/* {!expertStackMatching && !isTransaction ? (
              <div className={css.smallPrint}>
                <FormattedMessage
                  id="BookingPanel.expertProfileNotMatching"
                  values={{ listingType: 'job' }}
                />
              </div>
            ) : // : (!currentUserHasStripeConnected && !isTransaction && !isService)
            //   ? <div className={css.smallPrint}>
            //     <FormattedMessage id="JobApplicationForm.stripeAccountReminder" />
            //     {'b'}
            //     <div>
            //       <NamedLink name="StripePayoutPage" >
            //         <FormattedMessage id="JobApplicationForm.addPayoutDetails" />
            //       </NamedLink>
            //     </div>
            //   </div>
            null} */}
          </>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage
              id={
                isAwaiting
                  ? `BookingPanel.listingAwaiting`
                  : `BookingPanel.closedListingButtonText${messageFormatting}`
              }
            />
          </div>
        ) : null}

        {!currentUser ? (
          <div className={css.signupButtonWrapper}>
            <NamedLink name="NewListingExpertPage" className={css.signupLink}>
              <PrimaryButton>
                <FormattedMessage id="BookingTimeForm.signupExpert" />
              </PrimaryButton>
            </NamedLink>
            <p className={css.smallPrint}>
              <FormattedMessage id={`BookingTimeForm.createProfile${messageFormatting}`} />
            </p>
          </div>
        ) : null}

        {showContactUser ? (
          <PrimaryButton className={css.contactUserButton} onClick={onContactUser}>
            <FormattedMessage id="BookingTimeForm.contact" />
          </PrimaryButton>
        ) : isUser && !isTransaction ? (
          <PrimaryButton className={css.contactExpertButton} onClick={onContactUser}>
            <FormattedMessage id="BookingTimeForm.contactExpert" />
          </PrimaryButton>
        ) : null}

        {isOwnListing && !isTransaction && !isClosed ? (
          <div className={css.contactAdminWrapper}>
            <NamedLink
              className={css.signupLink}
              name="EditListingPage"
              params={editParams}
              category={category}
            >
              <PrimaryButton className={css.editButton}>
                {/* <Edit className={css.editIconFeather} /> */}
                <FormattedMessage id={message} />
              </PrimaryButton>
            </NamedLink>
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
