import React from 'react';
import { BiTask, BiTimeFive, BiMoney } from 'react-icons/bi';
import { FormattedMessage } from '../../util/reactIntl';
import { ProgressBarCircle } from '../../components';
import { calculateToFormat } from '../../util/helpers';
import css from './ProjectBoardPage.css';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
const SectionProjectProgress = props => {
  const {
    estimatedHours,
    completedHours,
    connectedTickets,
    currentProject,
    intl,
    connectedExperts,
    expertConnectedToProject,
    currentUserStripeAccounts,
    currentUser,
  } = props;

  let todoCount = 0;
  let inProgressCount = 0;
  let completedCount = 0;
  if (connectedTickets) {
    connectedTickets.forEach(ticket => {
      const ticketStatus = ticket.attributes.publicData.state;
      if (ticketStatus === 'open') {
        todoCount++;
      }
      if (ticketStatus === 'inProgress') {
        inProgressCount++;
      }
      if (ticketStatus === 'closed') {
        completedCount++;
      }
    });
  }
  let plannedBudget = new Money(0, 'USD');
  let usedBudget = new Money(0, 'USD');
  let estimatedBudget = new Money(0, 'USD');
  const usdAccount = currentUserStripeAccounts?.find(account => account.currency === 'usd') ?? {
    balance: 0,
    currency: 'usd',
  };

  const expertPrice = connectedExperts
    ? connectedExperts.length > 0
      ? connectedExperts[0].data.attributes.price
      : new Money(0, 'USD')
    : new Money(0, 'USD');

  //console.log('currentUserExpertListing',currentUserExpertListing,currentProject);

  //const expertPrice=new Money(currentUserExpertListing?.attributes?.price?.amount ?? 0, 'USD');
  const modPrice =
    (1 / (1 - (currentProject.attributes.publicData.commission ?? 0.5))) *
    (expertPrice?.amount ?? 0);

  //console.log('mod',modPrice,expertPrice,expertPrice.amount);
  //console.log('expertPrice',expertPrice);

  //console.log('budgetData',expertPrice.amount,completedHours);

  if (expertPrice && currentProject.attributes.price) {
    plannedBudget = expertConnectedToProject
      ? new Money(modPrice, 'USD')
      : currentProject.attributes.price;

    if (completedHours) {
      usedBudget = expertConnectedToProject
        ? new Money((expertPrice.amount * completedHours) / 60, 'USD')
        : new Money((modPrice * completedHours) / 60, 'USD');
    }
    if (estimatedHours) {
      estimatedBudget = expertConnectedToProject
        ? new Money((expertPrice.amount * estimatedHours) / 60, 'USD')
        : new Money((modPrice * estimatedHours) / 60, 'USD');
    }
  }

  //console.log('usedBudget',usedBudget);

  plannedBudget = formatMoney(intl, plannedBudget);
  usedBudget = formatMoney(intl, usedBudget);
  estimatedBudget = formatMoney(intl, estimatedBudget);

  let expertHourlyRate = expertPrice
    ? formatMoney(intl, new Money(modPrice, 'USD'))
    : formatMoney(intl, new Money(0, 'USD'));

  let estimated = calculateToFormat(estimatedHours);
  let consumed = calculateToFormat(completedHours);
  let hoursLeft = calculateToFormat(
    Math.floor(
      ((usdAccount.balance === 0 ? usdAccount.balance : -usdAccount.balance) / modPrice) * 60
    )
  );

  return (
    <div className={css.progressBarWrapper}>
      <div className={css.progressTitleContainer}>
        <p className={css.progressTitle}>
          <FormattedMessage id="ProjectBoardPage.projectProgress" />
        </p>
        <ProgressBarCircle maxValue={estimatedHours} currentValue={completedHours} />
      </div>

      <div className={css.projectProgressWrapper}>
        <div className={css.progressInfoWrapper}>
          {expertPrice &&
          currentProject.attributes.price &&
          plannedBudget &&
          usedBudget &&
          estimatedBudget &&
          expertHourlyRate ? (
            <div>
              <p className={css.progressHours}>
                <BiMoney className={css.buttonIcon} />
                <FormattedMessage id="ProjectBoardPage.budget" />
              </p>

              <div className={css.progressValues}>
                <FormattedMessage id="ProjectBoardPage.usedBudget" />
                <div>{usedBudget}</div>
              </div>
              {expertConnectedToProject ? null : (
                <div className={css.progressValues}>
                  <FormattedMessage id="ProjectBoardPage.expertHourlyRate" />
                  <div>{expertHourlyRate}</div>
                </div>
              )}
            </div>
          ) : null}
          <p className={css.progressHours}>
            <BiTimeFive className={css.buttonIcon} />
            <FormattedMessage id="ProjectBoardPage.progressHours" />
          </p>
          {consumed !== undefined ? (
            <div className={css.progressValues}>
              <FormattedMessage id="ProjectBoardPage.completedHours" />
              <div>{consumed}</div>
            </div>
          ) : null}
          {estimated !== undefined ? (
            <div className={css.progressValues}>
              <FormattedMessage id="ProjectBoardPage.estimatedHours" />
              <div>{estimated}</div>
            </div>
          ) : null}

          {currentUser.attributes.profile.publicData.type === 'customer' &&
          currentUserStripeAccounts?.find(account => account.currency === 'usd') !== undefined &&
          connectedExperts.length > 0 ? (
            <div className={css.progressValues}>
              <FormattedMessage id="ProjectBoardPage.hoursLeft" />
              {usdAccount.balance < 0 ? <div>{hoursLeft}</div> : <div>0h</div>}
            </div>
          ) : null}

          <p className={css.progressTickets}>
            <BiTask className={css.buttonIcon} />
            <FormattedMessage id="ProjectBoardPage.progressTickets" />
          </p>
          <div className={css.progressValues}>
            <FormattedMessage id="ProjectBoardPage.todoTickets" />
            <div>{todoCount}</div>
          </div>
          <div className={css.progressValues}>
            <FormattedMessage id="ProjectBoardPage.inprogressTickets" />
            <div>{inProgressCount}</div>
          </div>
          <div className={css.progressValues}>
            <FormattedMessage id="ProjectBoardPage.completedTickets" />
            <div>{completedCount}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionProjectProgress;
