import React, { Component } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import {  injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
    timeOfDayFromLocalToTimeZone,
    timeOfDayFromTimeZoneToLocal,
    nextMonthFn,
    prevMonthFn,
    dateIsAfter,
    getMonthStartInTimeZone,
    monthIdStringInTimeZone,
    resetToStartOfDay,
    getDefaultTimeZoneOnBrowser
} from '../../util/dates'
import { FieldDateInput } from '../../components';

import NextMonthIcon from '../../forms/BookingTimeForm/NextMonthIcon';
import PreviousMonthIcon from '../../forms/BookingTimeForm/PreviousMonthIcon';

const TODAY = new Date();
const MAX_TIME_SLOTS_RANGE = 180;

const timeZone =
    typeof window !== 'undefined'
        ? getDefaultTimeZoneOnBrowser()
        : 'Etc/UTC';

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };


const Next = props => {
    const { currentMonth } = props;
    const nextMonthDate = nextMonthFn(currentMonth, timeZone);

    return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
};

const Prev = props => {
    const { currentMonth } = props;
    const prevMonthDate = prevMonthFn(currentMonth, timeZone);
    const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

    return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

const endOfRange = (date) => {
    return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

export class DateSelectorComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentMonth: getMonthStartInTimeZone(TODAY, timeZone),
        };

        this.onBookingStartDateChange = this.onBookingStartDateChange.bind(this);
        this.onMonthClick = this.onMonthClick.bind(this);
        this.isOutsideRange = this.isOutsideRange.bind(this);
    }

    onBookingStartDateChange = (value, form) => {
        if (!value || !value.date) {
            // Reset the currentMonth too if bookingStartDate is cleared
            this.setState({ currentMonth: getMonthStartInTimeZone(TODAY, timeZone) });
            return;
        }

        // This callback function (onBookingStartDateChange) is called from react-dates component.
        // It gets raw value as a param - browser's local time instead of time in listing's timezone.
        const startDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);

        form.batch(() => {
            form.change('bookingStartDate', { date: startDate });
        });
    };

    isOutsideRange(day, bookingStartDate) {
        // 'day' is pointing to browser's local time-zone (react-dates gives these).
        // However, bookingStartDate and selectedTimeSlot refer to times in listing's timeZone.
        const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
        // Given day (endDate) should be after the start of the day of selected booking start date.
        const startDate = resetToStartOfDay(bookingStartDate, timeZone);
        // Given day (endDate) should be before the "next" day of selected timeSlots end.
        return !(dateIsAfter(localizedDay, startDate));
    }


    onMonthClick(monthFn) {
        const { onMonthChanged } = this.props;

        this.setState(
            prevState => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }),
            () => {

                // If previous fetch for month data failed, try again.
                const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);

                // Call onMonthChanged function if it has been passed in among props.
                if (onMonthChanged) {
                    onMonthChanged(monthId);
                }
            }
        );
    }

    render() {
        const {
            rootClassName,
            startDatePlaceholder,
            form,
            intl,
            mobileMargins
        } = this.props;

        const placeholder = startDatePlaceholder ? startDatePlaceholder : intl.formatDate(TODAY, dateFormattingOptions);

        const label = intl.formatMessage({
            id: `DateSelector.label`,
          });

        return (
            <div className={rootClassName}>
                <FieldDateInput
                    name="bookingStartDate"
                    id={'bookingStartDate'}
                    label={label}
                    placeholderText={placeholder}
                    format={v =>
                        v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
                    }
                    parse={v =>
                        v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
                    }
                    onChange={(values) => this.onBookingStartDateChange(values, form)}
                    onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
                    onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
                    navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                    navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                    showErrorMessage={false}
                    isOutsideRange={day =>
                        this.isOutsideRange(day, TODAY, timeZone)
                    }
                    useMobileMargins={mobileMargins}
                // validate={bookingDateRequired('Required')}
                />
            </div>
        );
    }
}

DateSelectorComponent.defaultProps = {
    rootClassName: null,
    className: null,
    listingId: null,
};

DateSelectorComponent.propTypes = {
    rootClassName: string,
    className: string,
    listingId: propTypes.uuid,
    // from injectIntl
};

const DateSelector = compose(injectIntl)(DateSelectorComponent);
DateSelector.displayName = 'JobApplicationForm';

export default DateSelector;
