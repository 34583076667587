import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Tooltip } from '@material-ui/core';
import { Reviews } from '../../components';

import { Row, Col } from 'react-bootstrap';
import css from '../../components/ExpertOverview/ExpertPage.css';
import css2 from '../../containers/ExpertPage/ExpertPage.css';

const SectionReviews = props => {
  const { reviews, voteButton, fetchReviewsError, upvotesCounter, voteButtonErrorMessage } = props;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ExpertPage.reviewsError" />
    </h2>
  );

  return (
    <>
    {upvotesCounter > 0 || reviews?.length > 0 ? (
    <Row>
      <Col xs={4} md={9} className={css2.reviewHeaderCorrection}>
        <FormattedMessage id="ExpertPage.reviewsHeading" values={{ count: reviews.length + upvotesCounter }} />
                      </Col>
                      <Col xs={8} md={3} className={css2.textRight}>
        <Tooltip title={<FormattedMessage id="ExpertPage.sendRecommendationExplanation" />} >
          {voteButton}
        </Tooltip>
        {voteButtonErrorMessage !== "" &&
          <div className={css.plusButtonErrorMessage}>
            <FormattedMessage id={voteButtonErrorMessage} />
          </div>
        }
        </Col>

        <Col xs={12}>
          {fetchReviewsError ? reviewsError : null}

          <div className={css.sectionReviews}>
            {reviews?.length > 0 && <Reviews reviews={reviews} />}
          </div>
        </Col>
        </Row>
    ) : null}
  </>
  );
};

export default SectionReviews;
