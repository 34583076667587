import React from 'react';
import { richText } from '../../util/richText';

import css from './ServicePage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionExpertiseMaybe = props => {
  const { richTitle } = props;

  return richTitle ? (
    <div className={css.sectionExpertise}>
      {richText(richTitle, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
        longWordClass: css.longWord,
      })}
    </div>
  ) : null;
};

export default SectionExpertiseMaybe;
