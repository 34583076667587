import React, { Component } from 'react';
import PropTypes, { number } from 'prop-types';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { IoMdCopy, IoMdSwitch } from 'react-icons/io';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import css from './IFramePage.css';

export class IFramePageComponent extends Component {
  constructor(props) {
    super(props);
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    this.props.staticContext.notfound = true;

    const MAX_MOBILE_SCREEN_WIDTH = 768;
    const isMobileLayout = this.props.viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    this.state = {
      chosenSoftwareUpperFirst: '',
      styledCodeText: '',
      plainCodeText: '',
      codeToDisplay: '',
      copyButtonText: isMobileLayout ? 'Copy' : 'Copy to clipboard',
      switchButtonText: isMobileLayout ? 'Switch' : 'Switch versions'
    };

    this.handleButtonCopy = this.handleButtonCopy.bind(this);
    this.handleButtonSwitch = this.handleButtonSwitch.bind(this);
  }

  componentDidMount() {
    let chosenSoftware = new URLSearchParams(this.props.location.search).get("software");
    let chosenSoftwareUpperFirst = chosenSoftware[0].toUpperCase() + chosenSoftware.slice(1);
    const styledCodeText = `<style>.softwaresuppIFrameContainer{position:relative;height:500px;width:90%;}@media (min-width: 560px){.softwaresuppIFrameContainer{width:80%}}@media (min-width: 960px){.softwaresuppIFrameContainer{width:70%}}@media (min-width: 1280px){.softwaresuppIFrameContainer{width:60%}}.softwaresuppIFrameWrapper{position:absolute;top:0;left:0;width:98%;height:98%;border-style:solid;border-width:1px;border-radius:5px;border-color:#e5e5e5;box-shadow:0 2px 4px 0 rgba(0,0,0,.09);}</style><div class="softwaresuppIFrameContainer"><iframe class="softwaresuppIFrameWrapper" id="softwaresuppIFrame" title="${chosenSoftwareUpperFirst} Experts" width="auto" height="auto" src="https://softwaresupp.com/frame/experts/${chosenSoftware}" >This browser does not support iframe. To check ${chosenSoftware} experts you can visit softwaresupp.com</iframe></div>`;
    const plainCodeText = `<iframe id="softwaresuppiFrame" title="${chosenSoftwareUpperFirst} Experts" width="600" height="400" src="https://softwaresupp.com/frame/experts/${chosenSoftware}"></iframe>`;
    this.setState({
      chosenSoftwareUpperFirst: chosenSoftwareUpperFirst,
      styledCodeText: styledCodeText,
      plainCodeText: plainCodeText,
      currentCodeVersion: 'Styled',
      codeToDisplay: styledCodeText
    });
  }

  handleButtonCopy() {
    var textArea = document.createElement("textarea");
    // ---- styling
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    // copy text
    textArea.value = this.state.codeToDisplay;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
  }

  handleButtonSwitch() {
    if(this.state.currentCodeVersion === 'Styled') {
      this.setState({
        codeToDisplay: this.state.plainCodeText,
        currentCodeVersion: 'Plain'
      });
    } else {
      this.setState({
        codeToDisplay: this.state.styledCodeText,
        currentCodeVersion: 'Styled'
      });
    }
  }

  render() {
    const { intl, scrollingDisabled } = this.props;


    const title = intl.formatMessage({
      id: 'IFramePage.title',
    });

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.root}>
              <div className={css.content}>
                <h1 className={css.heading}>
                  Your <strong style={{textDecoration: 'underline'}}>{this.state.chosenSoftwareUpperFirst}</strong> SoftwareSupp iFrame 
                </h1>
                <h4>
                  This is out-of-the-box iFrame with SoftwareSupp custom css styles and responsive design, you can also generate plain version without css styles. You can copy this code and include it in any suitable place on your website. If you are not sure what to do with it, please contact us or your web developer.
                </h4>
                <div className={css.codeFieldWrapper}>
                  <div className={css.codeFieldHeadlineWrapper}>
                    <div className={css.codeCurrentVersion}>
                      <strong>{this.state.currentCodeVersion+" version"}</strong>
                    </div>
                    <div className={css.codeActionButtonsWrapper}>
                      <div className={css.buttonCopy} onClick={this.handleButtonCopy}><IoMdCopy />{this.state.copyButtonText}</div>
                      <div className={css.buttonSwitch} onClick={this.handleButtonSwitch}><IoMdSwitch />{this.state.switchButtonText}</div>
                    </div>
                  </div>
                  <div className={css.codeContainer}>
                    {this.state.codeToDisplay}
                  </div>
                </div>
              </div>
              </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

IFramePageComponent.defaultProps = {
          staticContext: {},
};

const { bool, func, object, shape} = PropTypes;

IFramePageComponent.propTypes = {
          scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
          push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const IFramePage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(IFramePageComponent);

export default IFramePage;
