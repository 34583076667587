/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import {
  Page,
  Footer,
  LayoutWrapperFooter,
  PrimaryButton,
  IconClose,
  VerifiedIcon,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { filterQueryExpert, getAllSelectValues } from '../../util/api';
import { Bars } from 'react-loader-spinner';
import classNames from 'classnames';
import css from './ExpertListPage.css';
import ExpertProfileLinks from './ExpertProfileLinks';
import { Check } from 'react-feather';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import queryString from 'query-string';
import { Container, Row, Col } from 'react-bootstrap';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import { useSelector } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { useAdminCheck } from '../../util/userHelper';
import { createSlug } from '../../util/urlHelpers';

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

// Do przeniesienia do globalnych
function isNotEmptyObj(obj) {
  if (Object.keys(obj).length === 0) {
    return false;
  } else {
    return true;
  }
}

const ExpertListPage = props => {
  const { location, listings } = props;

  const [state, setState] = useState({
    key: false,
  });

  useEffect(() => {
    // you know what is this, don't you?
  }, []);

  useDidMountEffect(() => {
    // react please run me if 'key' changes, but not on initial render
  }, [state.key]);

  const history = useHistory();
  const { search } = useLocation();
  const urlQuery = queryString.parse(search);
  let urlParams = useParams();

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const [globalState, setglobalState] = useState({
    loadingData: true,
    userData: [],
  });

  const [searchQuery, setsearchQuery] = useState({});
  const [showSoftware, setshowSoftware] = useState(false);
  const [showSkills, setshowSkills] = useState(false);
  const [queryData, setqueryData] = useState({ software: '', skills: '', query: '' });
  const [paginationMaxPage, setpaginationMaxPage] = useState(0);
  const [expertsData, setexpertsData] = useState([]);
  const [loading, setloading] = useState(true);
  const [listReload, setlistReload] = useState(false);
  const [paginationPage, setpaginationPage] = useState({ offset: 0 });
  const [searchType, setsearchType] = useState({ type: 'all' });
  const [serchverified, setsearchverified] = useState({ isVerified: true });
  // const [filterData, setfilterData] = useState({});
  const [updateFilter, setupdateFilter] = useState({ run: true });
  const [disableScrolling, setDisableScrolling] = useState(false);

  const FilterData = useSelector(state => state.marketplaceData);
  const currentUser = useSelector(state => state.user.currentUser);

  const isAdmin = useAdminCheck()  // Hook sprawdzający czy uzytkownik jest adminem


  useEffect(() => {
    // you know what is this, don't you?
  }, []);

  // useEffect(() => {
  //   let qparams;
  //   if (Object.keys(urlParams).length > 0) {
  //     console.log('first')
  //     qparams = {
  //       software: urlParams?.software && urlParams.software != 'any' ? urlParams.software : '',
  //       skills: urlParams?.skill ? urlParams.skill : '',
  //       query: '',
  //     };
  //     setqueryData(qparams);
  //     setsearchType({ type: 'filter' });
  //   }


  //   if (Object.keys(urlQuery).length > 0) {
  //     
  //     qparams = {
  //       software: urlQuery?.pub_softwares && urlQuery.pub_softwares != 'any' ? urlQuery.pub_softwares : '',
  //       skills: urlQuery?.pub_skills ? urlQuery.pub_skills : '',
  //       query: '',
  //     };

  //     setqueryData(qparams);
  //     setsearchType({ type: 'filter' });
  //   }

  //   if (urlQuery?.page) {
  //     setpaginationPage({ offset: urlQuery.page });
  //   }

  //   setState({ key: !state.key });
  // }, []);

  useEffect(() => {
    // LoadFilterData();

    let qparams;
    if (Object.keys(urlParams).length > 0) {
      qparams = {
        software: urlParams?.software && urlParams.software != 'any' ? urlParams.software : '',
        skills: urlParams?.skill ? urlParams.skill : '',
        query: '',
      };
      setqueryData(qparams);
      setsearchType({ type: 'filter' });
    } else if(urlQuery?.pub_skills || urlQuery?.pub_softwares){
        qparams = {
          software: urlQuery?.pub_softwares && urlQuery?.pub_softwares != 'any' ? urlQuery.pub_softwares : '',
          skills: urlQuery?.pub_skills ? urlQuery?.pub_skills : '',
          query: '',
        };
      setqueryData(qparams);
      setsearchType({ type: 'filter' });
    }
    

    if (urlQuery?.page) {
      setpaginationPage({ offset: urlQuery.page });
    }

    setState({ key: !state.key });
  }, []);
  useDidMountEffect(() => {
    loadUsers();
  }, [state.key]);

  /***** HEANDLERS *******/
  // Przeniesienie do strony marketingowej
  const goToPrePostPage = (e, id = null, firstName = null, lastName = null) => {
    e.preventDefault();
    if (e.target.matches('.tag')) {
      let url = e.target.dataset.link;
      // history.push(url)

      let stateCon = { software: '', skills: '', query: '' };
      if (!!e.target.dataset.soft) {
        stateCon.software = e.target.dataset.soft;
      }
      if (!!e.target.dataset.skill) {
        stateCon.skills = e.target.dataset.skill;
      }

      setqueryData(stateCon);
      setloading(true);
      setpaginationPage({ offset: 0 });
      setState({ key: !state.key });
      let querytring;

      if (stateCon.software) {
        querytring = stateCon.software + '/';
      }

      if (stateCon.skills) {
        if (stateCon.software) {
          querytring = stateCon.software + '/' + stateCon.skills + '/';
        } else {
          querytring = 'any/' + stateCon.skills + '/';
        }
      }

      history.replace('/experts/' + querytring, {});
      setshowSoftware(false);
      setDisableScrolling(false);
      setshowSkills(false);
    } else {
      if (isAdmin) {
        let slug = createSlug(firstName + ' ' + lastName);
        let url = '/experts/' + slug + '/' + id;
        history.push(url);
      } else {
        history.push('/l/draft/00000000-0000-0000-0000-000000000000/new/project/quick/');
      }
    }
  };

  const HandlePagination = (e, page) => {
    setlistReload(true);
    setpaginationPage({ offset: page });
    let search = '';

    let currentlink = search.location;
    if (page !== 1) {
      search = '?page=' + page;
    }
    history.replace({
      pathname: currentlink,
      search: search,
    });
    setState({ key: !state.key });
  };

  const toggleSoftware = e => {
    if (showSoftware === false) {
      setDisableScrolling(true);
      setshowSoftware(true);
      setshowSkills(false);
    } else {
      setshowSoftware(false);
    }
  };

  const toggleSkills = e => {
    if (showSkills === false) {
      setDisableScrolling(true);
      setshowSkills(true);
      setshowSoftware(false);
    } else {
      setshowSkills(false);
    }
  };

  const toggleCloseFilter = e => {
    setshowSoftware(false);
    setshowSkills(false);
    setDisableScrolling(false);
  };

  const handelApplyFilter = event => {
    event.preventDefault();
    setloading(true);
    setpaginationPage({ offset: 0 });
    setState({ key: !state.key });

    let querytring;

    if (queryData.software) {
      querytring = queryData.software + '/';
    }

    if (queryData.skills) {
      if (queryData.software) {
        querytring = queryData.software + '/' + queryData.skills + '/';
      } else {
        querytring = 'any/' + queryData.skills + '/';
      }
    }

    history.replace('/experts/' + querytring, {});
    setshowSoftware(false);
    setDisableScrolling(false);
    setshowSkills(false);
  };

  const SelectedRadioOption = event => {
    let tempData = JSON.parse(JSON.stringify(queryData));
    tempData[event.target.dataset.target] = event.target.value;
    setqueryData(tempData);
    setsearchType({ type: 'filter' });
  };

  const handleClearFilter = event => {
    setloading(true);
    setsearchType({ type: 'all' });
    setqueryData({ software: '', skills: '', query: '' });
    setpaginationPage({ offset: 0 });
    setState({ key: !state.key });
    setshowSoftware(false);
    setshowSkills(false);
    setDisableScrolling(false);

    history.replace({
      pathname: '/experts/',
    });
  };

  //Hendler od tagow

  // const LoadFilterData = async () => {
  //   const softwareList = await getAllSelectValues({ type: 'software', isApproved: true });
  //   const skillList = await getAllSelectValues({ type: 'skill', isApproved: true });
  //   setfilterData({
  //     softwares: softwareList.data,
  //     skills: skillList.data,
  //   });
  // };

  const loadUsers = async () => {
    let searchQuery = {};

    searchQuery = {
      ...searchType,
      ...serchverified,
      limit: 10,
      ...paginationPage,
      ...queryData,
    };

    const data = await filterQueryExpert(searchQuery);
    setexpertsData(data.data);
    setpaginationMaxPage(data.total_pages);
    setloading(false);
    setlistReload(false);
  };

  const CurrentPage = () => {
    if (paginationPage.offset == 0) {
      return 1;
    } else {
      return paginationPage.offset;
    }
  };

  const category = 'expert';

  const MetaData = (category, queryParams) => {
    let metaData = {
      title:
        'SoftwareSupp Freelancers - Hire The Best Freelancers in the World in 24h',
      description:
        'SoftwareSupp is an elite network of Certified Freelancers. Hire the best freelancers in the world 24h. Use a 14-day trial. Reduce your costs by 10x.',
      schema: {
        '@context': 'http://schema.org',
        '@type': 'SearchResultsPage',
        description:
          'SoftwareSupp is an elite network of Certified Freelancers. Hire the best freelancers in the world 24h. Use a 14-day trial. Reduce your costs by 10x.',
        name:
          'SoftwareSupp Freelancers - Hire The Best Freelancers in the World in 24h',
        mainEntity:
          'SoftwareSupp is an elite network of Certified Freelancers. Hire the best freelancers in the world 24h. Use a 14-day trial. Reduce your costs by 10x.',
      },
    };

    if (category == 'expert') {
      if (queryParams?.software && queryParams?.software != 'Other' && queryParams?.software != 'any') {
        metaData.title =
          '24 Best ' +
          FilterData.productSoftware.find(item => item.key === queryParams.software)?.label +
          ' Freelancers for Hire in 24h | SoftwareSupp';

        metaData.description =
          'SoftwareSupp is an elite network of Certified Freelancers. Hire the best freelancers in the world 24h. Use a 14-day trial. Reduce your costs by 10x.';

        metaData.schema = {
          '@context': 'http://schema.org',
          '@type': 'SearchResultsPage',
          description:
            'SoftwareSupp is an elite network of Certified Freelancers. Hire the best freelancers in the world 24h. Use a 14-day trial. Reduce your costs by 10x.',
          name:
            '24 Best ' +
            FilterData.productSoftware.find(item => item.key === queryParams.software)?.label +
            ' Freelancers for Hire in 24h | SoftwareSupp',
          mainEntity:
            'SoftwareSupp is an elite network of Certified Freelancers. Hire the best freelancers in the world 24h. Use a 14-day trial. Reduce your costs by 10x.',
        };
      } else if (queryParams?.skill) {
        metaData.title =
          '24 Best ' +
          FilterData.productSkills.find(item => item.key === queryParams.skill)?.label +
          ' Freelancers for Hire in 24h | SoftwareSupp';
        metaData.description =
          'SoftwareSupp is an elite network of Certified ' +
          FilterData.productSkills.find(item => item.key === queryParams.skill)?.label +
          ' Freelancers. Hire the best freelancers in the world 24h. Use a 14-day trial. Reduce your costs by 10x.';
        metaData.schema = {
          '@context': 'http://schema.org',
          '@type': 'SearchResultsPage',
          description:
            'SoftwareSupp is an elite network of Certified ' +
            FilterData.productSkills.find(item => item.key === queryParams.skill)?.label +
            ' Freelancers. Hire the best freelancers in the world 24h. Use a 14-day trial. Reduce your costs by 10x.',
          name:
            '24 Best ' +
            FilterData.productSkills.find(item => item.key === queryParams.skill)?.label +
            ' Freelancers for Hire in 24h | SoftwareSupp',
          mainEntity:
            'SoftwareSupp is an elite network of Certified ' +
            FilterData.productSkills.find(item => item.key === queryParams.skill)?.label +
            ' Freelancers. Hire the best freelancers in the world 24h. Use a 14-day trial. Reduce your costs by 10x.',
        };
      }
    }

    return metaData;
  };

  const headerH1Generator = (category, queryParams) => {
    if (category == 'expert') {
      if (queryParams?.software && queryParams?.software != 'Other') {
        return (
          <h1>
            {' '}
            {FilterData.productSoftware.find(item => item.key === queryParams.software)?.label}{' '}
            Freelancers
          </h1>
        );
      } else if (queryParams.skills) {
        return (
          <h1>
            {' '}
            {FilterData.productSkills.find(item => item.key === queryParams.skills)?.label}{' '}
            Freelancers
          </h1>
        );
      } else {
        return <h1>SoftwareSupp Freelancers</h1>;
      }
    }
  };

  const pageHeader = headerH1Generator(category, queryData);
  const { title, description, schema } = MetaData('expert', urlParams);

  return (
    <div className={disableScrolling ? css.disableScrolling : ''}>
      <Page className={css.diableScrolling} description={description} title={title} schema={schema}>
        <TopbarContainer
          //   className={topbarClasses}
          currentPage="SearchPage"
          currentCategory={category}
          //   currentSearchParams={urlQueryParams}
          // forceRerender={this.forceRerender}
          // saveUserKeywordSearch={this.handleNewKeywordSearch}
        />

        <div className={css.SearchBar}>
          <Container>
            <Row className="pt-3 pb-4">
              <Col xs={12}>
                <div className={css.headerBig}>Find a freelancer</div>
              </Col>
              {/* <Col className="d-none d-md-block" xs={12}> */}
              {/*   <div className={css.searchBar}> */}
              {/*     <div className={css.searchInput}> */}
              {/*       <input className={css.inputStyle}></input> */}
              {/*     </div> */}
              {/*     <div> */}
              {/*       <button className={css.searchBtn}>Search</button> */}
              {/*     </div> */}
              {/*   </div> */}
              {/* </Col> */}
              <Col>
                <div className={css.filterOptions}>
                  <div className={css.filterLabel}>Filters:</div>
                  <div>
                    <button
                      className={classNames(
                        css.filterBtn,
                        queryData.software == '' ? '' : css.selected
                      )}
                      onClick={toggleSoftware}
                    >
                      {queryData.software == ''
                        ? 'Software'
                        : FilterData.productSoftware.find(
                            item => item.key.toLowerCase() === queryData.software.toLowerCase()
                          )?.label}
                    </button>
                  </div>
                  <div>
                    <button
                      className={classNames(
                        css.filterBtn,
                        queryData.skills == '' ? '' : css.selected
                      )}
                      onClick={toggleSkills}
                    >
                      {queryData.skills == ''
                        ? 'Skills'
                        : FilterData.productSkills.find(
                            item => item.key.toLowerCase() === queryData.skills.toLowerCase()
                          )?.label}
                    </button>
                  </div>
                </div>
                {showSoftware && (
                  <div className={css.floatingblock}>
                    <Container>
                      <Row>
                        <Col xs={12}>
                          <div className={css.floatingblock_header}>
                            <div className={css.floatingblock_header_header}>
                              Filter by software
                            </div>
                            <div className={css.floatingblock_header_apply}>
                              <button onClick={handelApplyFilter}>Apply filter</button>
                            </div>
                            <div className={css.floatingblock_header_clearbutton}>
                              <button onClick={handleClearFilter}>Clear filter</button>
                            </div>
                            <div className={css.floatingblock_header_clearbutton}>
                              <button onClick={toggleCloseFilter}>
                                <IconClose className={css.iconClose} />
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col className="pb-5" xs={12}>
                          <div className={classNames(css.threecolumns)}>
                            {FilterData.productSoftware.map(item => {
                              return (
                                <div className={css.radionBox}>
                                  <label>
                                    <input
                                      type="radio"
                                      value={item.key}
                                      onChange={SelectedRadioOption}
                                      data-target="software"
                                      name="radioSoftware"
                                      checked={item.key == queryData.software ? true : null}
                                    />
                                    <div>{item.label}</div>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                )}

                {showSkills && (
                  <div className={css.floatingblock}>
                    <Container>
                      <Row>
                        <Col xs={12}>
                          <div className={css.floatingblock_header}>
                            <div className={css.floatingblock_header_header}>Filter by skills</div>
                            <div className={css.floatingblock_header_apply}>
                              <button onClick={handelApplyFilter}>Apply filter</button>
                            </div>
                            <div className={css.floatingblock_header_clearbutton}>
                              <button onClick={handleClearFilter}>Clear filter</button>
                            </div>

                            <div className={css.floatingblock_header_clearbutton}>
                              <button onClick={toggleCloseFilter}>
                                <IconClose className={css.iconClose} />
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col className="pb-5" xs={12}>
                          <div className={classNames(css.container, css.threecolumns)}>
                            {FilterData.productSkills.map(item => {
                              return (
                                <div className={css.radionBox}>
                                  <label>
                                    <input
                                      type="radio"
                                      value={item.key}
                                      name="radioSkills"
                                      onChange={SelectedRadioOption}
                                      data-target="skills"
                                      checked={item.key == queryData.skills ? true : null}
                                    />
                                    <div>{item.label}</div>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </Container>

                    {/* <div className={classNames(css.container, css.threecolumns)}> */}
                    {/*   {filterData.skills.map(item => { */}
                    {/*     return ( */}
                    {/*       <div className={css.radionBox}> */}
                    {/*         <label> */}
                    {/*           <input type="radio" value={item.label} name="radioSkills" /> */}
                    {/*           <div>{item.label}</div> */}
                    {/*         </label> */}
                    {/*       </div> */}
                    {/*     ); */}
                    {/*   })} */}
                    {/* </div> */}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row className={css.rowPadding}>
            <Col xs={12} className={css.headerh1}>
              {pageHeader}
            </Col>

            {loading || listReload ? (
              <Col className="pt-8 pb-8" xs={12}>
                <div className={css.loading}>
                  <Bars
                    height="80"
                    width="80"
                    color="#3c88f8"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              </Col>
            ) : null}

            {!loading &&
              !listReload &&
              expertsData.map(item => {
                return (
                  <Col
                    className={classNames(css.itemContainer, 'mb-4')}
                    onClick={e => goToPrePostPage(e, item.id, item.firstName, item.lastName)}
                    xs={12}
                  >
                    <Row>
                      <Col xs={4} md={2}>
                        <div className={css.imageHolder}>
                          {isNotEmptyObj(item.imgs) && (
                            <img src={item.imgs['square-small'].url}></img>
                          )}

                          <span>{item.abbreviatedName}</span>
                        </div>
                        <div className={css.additionalInfo}>
                          <ExpertProfileLinks
                            linkedin={item.linkLinkedin}
                            calendly={item.linkCalendly}
                            web={item.linkWeb}
                          />
                        </div>
                      </Col>
                      <Col>
                        {/* {item.isVerified && ( */}
                        {/*   <span className={classNames('d-flex', 'd-md-none', css.verifiedMarker)}> */}
                        {/*     <VerifiedIcon  isStripeVerified={true}/> */}
                        {/*     <img className={css.iconCert} src="https://softwaresupp.com/s3/assets/certificated.png" /> */}
                        {/*   </span> */}
                        {/* )} */}
                        <div className={css.exName}>
                          {item.firstName} {item.lastName}
                          {item.isVerified && (
                            <span className={classNames('d-md-flex', css.verifiedMarker)}>
                              <VerifiedIcon isStripeVerified={true} className={'test'} />
                              {/* <img className={css.iconCert} src="https://softwaresupp.com/s3/assets/certificated.png" /> */}
                            </span>
                          )}
                        </div>
                        <div className={css.exTitle}>{item.title}</div>
                        <div className="d-none d-md-block ${css.profileInfoBlock}">
                          <div className={css.separator}></div>
                          <div>
                            <div className={css.softwareBlock}>
                              {item.softwares &&
                                item.softwares.map(soft => {
                                  let label = FilterData.productSoftware.find(
                                    item => item.key === soft
                                  )?.label;
                                  if (label) {
                                    return (
                                      <a
                                        className={'tag'}
                                        data-soft={soft}
                                        href={'/experts/' + soft}
                                      >
                                        {label}
                                      </a>
                                    );
                                  }
                                })}
                            </div>
                            <div className={css.skillsBlock}>
                              {item.skills &&
                                item.skills.map(skill => {
                                  let label = FilterData.productSkills.find(
                                    item => item.key === skill
                                  
                                  )?.label;
                                  if(label){
                                  return (
                                    <a
                                      className={'tag'}
                                      data-skill={skill}
                                      href={'/experts/any/' + skill}
                                    >
                                      {label}
                                    </a>
                                  );
                                  }
                                })}
                            </div>
                            <div className={css.induBlock}>
                              {item.industries &&
                                item.industries.map(ind => {
                                  let label = FilterData.productIndustries.find(
                                    item => item.key === ind
                                  )?.label;
                                  if (label) {
                                    return (
                                      <span data-soft={ind}>
                                        {label}
                                      </span>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-md-none ${css.profileInfoBlock}">
                          <div className={css.separator}></div>
                          <div>
                            <div className={css.softwareBlock}>
                              {item.softwares &&
                                item.softwares.map(soft => {
                                   let label = FilterData.productSoftware.find(
                                    item => item.key === soft
                                  )?.label;

                                  if(label){
                                  return (
                                    <a className={'tag'} data-soft={soft} href={'/experts/' + soft}>
                                      {label}
                                    </a>
                                  );
                                  }
                                })}
                            </div>
                            <div className={css.skillsBlock}>
                              {item.skills &&
                                item.skills.map(skill => {
                                    let label = FilterData.productSkills.find(
                                    item => item.key === skill
                                  )?.label;
                                  if(label){
                                  return (
                                    <a
                                      className={'tag'}
                                      data-skill={skill}
                                      href={'/experts/any/' + skill}
                                    >
                                      {label}
                                    </a>
                                  );
                                  }
                                })}
                            </div>
                            <div className={css.induBlock}>
                              {item.industries &&
                                item.industries.map(ind => {
                                  let label = FilterData.productIndustries.find(
                                    item => item.key === ind
                                  )?.label;
                                  if (label) {
                                    return (
                                      <span data-soft={ind}>
                                        {label}
                                      </span>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
          </Row>
        </Container>

        <div className={css.container}>
          <div className={css.itemList}>
            <div className={css.Pagination}>
              {!loading && paginationMaxPage > 1 && (
                <Pagination
                  count={paginationMaxPage}
                  defaultPage={CurrentPage}
                  siblingCount={0}
                  onChange={HandlePagination}
                />
              )}
            </div>
          </div>
        </div>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    </div>
  );
};

export default ExpertListPage;
