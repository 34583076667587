import pick from 'lodash/pick';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { LISTING_CATEGORY_TICKET } from '../../util/types';
import { LISTING_PAGE_PENDING_APPROVAL_VARIANT } from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getInvoice } from '../../util/api';
import { fetchProject } from '../../util/api';
const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ProjectBoardPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ProjectBoardPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ProjectBoardPage/SHOW_LISTING_ERROR';

export const SHOW_TICKETS_REQUEST = 'app/ProjectBoardPage/SHOW_TICKETS_REQUEST';
export const SHOW_TICKETS_SUCCESS = 'app/ProjectBoardPage/SHOW_TICKETS_SUCCESS';
export const SHOW_TICKETS_ERROR = 'app/ProjectBoardPage/SHOW_TICKETS_ERROR';

export const COMPLETE_SALE_REQUEST = 'app/ProjectBoardPage/COMPLETE_SALE_REQUEST';
export const COMPLETE_SALE_SUCCESS = 'app/ProjectBoardPage/COMPLETE_SALE_SUCCESS';
export const COMPLETE_SALE_ERROR = 'app/ProjectBoardPage/COMPLETE_SALE_ERROR';

export const FETCHING_INVOICE_REQUEST = 'app/ProjectBoardPage/FETCHING_INVOICE_REQUEST';
export const FETCHING_INVOICE_SUCCESS = 'app/ProjectBoardPage/FETCHING_INVOICE_SUCCESS';
export const FETCHING_INVOICE_ERROR = 'app/ProjectBoardPage/FETCHING_INVOICE_ERROR';
export const CLOSE_STATE_LISTING_SUCCESS = 'app/ProjectBoardPage/CLOSE_STATE_LISTING_SUCCESS';
export const SHOW_CONNECTED_EXPERT_SUCCESS = 'app/ProjectBoardPage/SHOW_CONNECTED_EXPERT_SUCCESS';
export const SHOW_CONNECTED_EXPERT_REQUEST = 'app/ProjectBoardPage/SHOW_CONNECTED_EXPERT_REQUEST';

export const FETCHING_PROJECT_TRANSACTION_REQUEST =
  'app/ProjectBoardPage/FETCHING_PROJECT_TRANSACTION_REQUEST';
export const FETCHING_PROJECT_TRANSACTION_SUCCESS =
  'app/ProjectBoardPage/FETCHING_PROJECT_TRANSACTION_SUCCESS';
export const FETCHING_PROJECT_TRANSACTION_ERROR =
  'app/ProjectBoardPage/FETCHING_PROJECT_TRANSACTION_ERROR';

export const TICKET_OPEN = 'app/ProjectBoardPage/TICKET_OPEN';
// ================ Reducer ================ //

const initialState = {
  id: null,
  fetchedAnswers: null,
  showListingError: null,
  connectedTickets: null,
  connectedExperts: [],
  projectClosed: false,
  listingData: null,
  projectTransaction: null,
  fetchingProjectTransactionError: null,
  fetchingProjectTransactionInProgress: false,
  ticketToOpen: typeof window !== 'undefined' ? window.location.search ? String(window.location.search).slice(1,String(window.location.search).length) : null : null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return {
        ...state,
        listingData: payload.data,
        fetchedAnswers: payload.data.answers,
      };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case SHOW_TICKETS_REQUEST:
      return { ...state, id: payload.id, showTicketsError: null };
    case SHOW_TICKETS_SUCCESS:
      return {
        ...state,
        connectedTickets: payload?.data?.data,
      };
    case SHOW_TICKETS_ERROR:
      return { ...state, showTicketsError: payload };

    case FETCHING_INVOICE_REQUEST:
      return { ...state, fetchingInvoiceInProgress: true };
    case FETCHING_INVOICE_SUCCESS:
      return { ...state, listingInvoiceUrl: payload, fetchingInvoiceInProgress: false };
    case FETCHING_INVOICE_ERROR:
      return { ...state, fetchingInvoiceInProgress: false, fetchingInvoiceError: payload };

    case FETCHING_PROJECT_TRANSACTION_REQUEST:
      return { ...state, fetchingProjectTransactionInProgress: true };
    case FETCHING_PROJECT_TRANSACTION_SUCCESS:
      return { ...state, projectTransaction: payload, fetchingProjectTransactionInProgress: false };
    case FETCHING_PROJECT_TRANSACTION_ERROR:
      return {
        ...state,
        fetchingProjectTransactionInProgress: false,
        fetchingProjectTransactionError: payload,
      };
    case TICKET_OPEN:
      return { ...state, ticketToOpen: payload };

    case SHOW_CONNECTED_EXPERT_SUCCESS:
      return {
        ...state,
        connectedExperts: [...state.connectedExperts, payload],
        loadingExperts: false,
      };
    case SHOW_CONNECTED_EXPERT_REQUEST:
      return { ...state, connectedExperts: [], loadingExperts: true };
    case CLOSE_STATE_LISTING_SUCCESS:
      return { ...state, projectClosed: true };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress;
};

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = data => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { data },
});
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const showTicketsRequest = id => ({
  type: SHOW_TICKETS_REQUEST,
  payload: { id },
});
export const showTicketsSuccess = data => ({
  type: SHOW_TICKETS_SUCCESS,
  payload: { data },
});
export const closeStateListingSuccess = () => ({
  type: CLOSE_STATE_LISTING_SUCCESS,
});
export const showLTicketsError = e => ({
  type: SHOW_TICKETS_ERROR,
  error: true,
  payload: e,
});

export const setOpenTicket = ticketId => ({
  type: TICKET_OPEN,
  payload: ticketId,
});

export const clearTicket = () => ({
  type: TICKET_OPEN,
  payload: null,
});

const fetchingInvoiceRequest = () => ({ type: FETCHING_INVOICE_REQUEST });
const fetchingInvoiceSuccess = payload => ({ type: FETCHING_INVOICE_SUCCESS, payload: payload });
const fetchingInvoiceError = e => ({ type: FETCHING_INVOICE_ERROR, error: true, payload: e });

const fetchProjectTransactionRequest = () => ({ type: FETCHING_PROJECT_TRANSACTION_REQUEST });
const fetchProjectTransactionSuccess = payload => ({
  type: FETCHING_PROJECT_TRANSACTION_SUCCESS,
  payload: payload,
});
const fetchProjectTransactionError = e => ({
  type: FETCHING_PROJECT_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

const showConnectedExpertSuccess = payload => ({
  type: SHOW_CONNECTED_EXPERT_SUCCESS,
  payload: payload,
});
const showConnectedExpertRequest = () => ({ type: SHOW_CONNECTED_EXPERT_REQUEST });

// ================ Thunks ================ //

export const showListing = (listingId, isOwn, fetchExperts) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.user': ['profile.firstName', 'profile.abbreviatedName', 'image.attributes'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const answers = data.data.data.attributes.publicData.answers;
      dispatch(
        showListingSuccess({
          answers: answers,
        })
      );
      const experts = data.data.data.attributes.publicData.expert;
      const state = getState()?.ProjectBoardPage;
      if (experts && fetchExperts && !state.loadingExperts) {
        //eslint-disable-next-line no-unused-vars
        for (const expertId of experts) {
          dispatch(showConnectedExpert(new UUID(expertId)));
        }
      }
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const showConnectedTickets = listingId => (dispatch, getState, sdk) => {
  const params = {
    pub_projectId: listingId.uuid,
    pub_category: LISTING_CATEGORY_TICKET,
    include: ['author', 'author.profileImage', 'author.attributes', 'images'],
    'fields.user': ['profile.firstName', 'profile.abbreviatedName', 'image.attributes'],
    'fields.image': [
      // Tickets
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };
  return sdk.listings.query(params).then(response => {
    dispatch(addMarketplaceEntities(response));
    dispatch(showTicketsSuccess(response));
    return response;
  });
};

export const showConnectedExpert = expertId => (dispatch, getState, sdk) => {
  dispatch(showConnectedExpertRequest());
  const params = {
    id: expertId,
    include: ['author.profileImage'],
    'fields.image': ['variants.square-small2x'],
  };
  return sdk.listings.show(params).then(data => {
    dispatch(showConnectedExpertSuccess(data.data));
    return data;
  });
};

export const showMessageOwner = listingId => (dispatch, getState, sdk) => {
  const params = {
    id: listingId,
    include: ['author.profileImage'],
    'fields.user': ['profile.firstName', 'profile.abbreviatedName', 'image.attributes'],
    'fields.image': [
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = sdk.listings.show(params);

  return show
    .then(data => {
      return data;
    })
    .catch(e => {});
};

export const showUser = userId => (dispatch, getState, sdk) => {
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(data => {
      return data;
    })
    .catch(e => {});
};

export const fetchListingHasInvoice = invoiceId => (dispatch, getState, sdk) => {
  dispatch(fetchingInvoiceRequest());
  getInvoice({ invoiceId: invoiceId })
    .then(invoiceResponse => {
      dispatch(fetchingInvoiceSuccess(invoiceResponse.hosted_invoice_url));
    })
    .catch(e => {
      dispatch(fetchingInvoiceError());
    });
};

export const fetchProjectTransaction = data => (dispatch, getState, sdk) => {
  dispatch(fetchProjectTransactionRequest());
  fetchProject(data)
    .then(response => {
      dispatch(fetchProjectTransactionSuccess(response));
    })
    .catch(e => {
      dispatch(fetchProjectTransactionError());
    });
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);
  const isPendingApproval = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;

  return Promise.all([
    dispatch(setInitialValues(initialState)),
    dispatch(showListing(listingId, isPendingApproval, true)),
    dispatch(showListing(listingId, true, false)),
    dispatch(showConnectedTickets(listingId)),
  ]).then(responses => {
    return responses;
  });
};

export const closeStateListing = listingId => (dispatch, getState, sdk) => {
  sdk.ownListings
    .update({ id: new UUID(listingId), publicData: { state: 'closed' } })
    .then(response => {
      dispatch(closeStateListingSuccess());
    });
};
