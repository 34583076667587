import { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { func, shape } from 'prop-types';

import { injectIntl } from '../../util/reactIntl';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { createResourceLocatorString } from '../../util/routes';

import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';

// import { TopbarContainer } from '../../containers';
import { fetchAdminService, fetchRestOfServices, loadData } from './StartProjectPage.duck';
import {
  requestCreateListingDraft,
  requestPublishListingDraft,
} from '../EditProjectPage/EditProjectPage.duck';

export const StartProjectPageComponent = props => {
  const {
    currentUser,
//    scrollingDisabled,
    params,
    history,
    onFetchRestOfServices,
    onFetchAdminService,
    pageListings,
    searchInProgress,
    location,
    noFoundServices,
    noAdminService,

    serviceCategories,
  } = props;

  if (location.pathname === '/') {
    history.push('/center/getStarted');
  }
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'quotation',
    });
  }

  const serviceList = serviceCategories
    ?.map(
      serviceCat =>
        config.custom.serviceCategoriesConfig.options.find(category => category.key === serviceCat)
          ?.label
    )
    .filter(category => !!category);

  if (serviceList) {
    const properOrder = [];
    config.custom.serviceCategoriesConfig.options.map(category => properOrder.push(category.label));
    serviceList.sort((a, b) => properOrder.indexOf(a) - properOrder.indexOf(b));
  }

  const serviceCategory = location?.state?.serviceCategory;
  const { step, referralNumber } = params;

  const [chosenService, setChosenService] = useState({});

 

  useEffect(() => {
    if (
      serviceCategory &&
      pageListings?.length === 0 &&
      (!noFoundServices || !noAdminService) &&
      step === '3' &&
      !searchInProgress
    ) {
      const foundServiceCategory = serviceList?.find(service => service === serviceCategory);
      onFetchAdminService(foundServiceCategory);
      onFetchRestOfServices(foundServiceCategory);
    }
  }, [
    noAdminService,
    serviceCategory,
    pageListings,
    step,
    searchInProgress,
    noFoundServices,
    onFetchAdminService,
    onFetchRestOfServices,
    serviceList,
  ]);

  useEffect(() => {
    if (serviceCategory && step === '3' && !chosenService.data && pageListings?.length === 1) {
      setChosenService({ id: 0, confirmed: true, data: pageListings[0] });
    }
  }, [serviceCategory, chosenService, pageListings, step]);

  //const title = intl.formatMessage({ id: 'StartProjectPage.title' });


  // const additionalLink = (
  //   <div className={css.centedLinkContainer}>
  //     {recommendLink}
  //     {joinAsExpertLink}
  //   </div>
  // );

  // const singupTooltip = (
  //   <Tooltip interactive title={<FormattedMessage id="StartProjectPage.startProjectPageTooltip" />}>
  //     <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
  //   </Tooltip>
  // );

  const onRedirectToEditPage = type => {
    const params = {
      slug: 'draft',
      id: '00000000-0000-0000-0000-000000000000',
      type: 'new',
      category: 'job',
      referralNumber: referralNumber,
    };
    const to = createResourceLocatorString(
      referralNumber ? 'QuickEditProjectPageReferral' : 'QuickEditProjectPage',
      routeConfiguration(),
      params,
      {},
      type === 'full' && !currentUser ? '#contact' : ''
    );
    if (type === 'title') {
      history.push(to);
    } else if (type === 'full') {
      const { publicData, title, description } = chosenService.data.attributes;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);
      const initialValues = {
        title: title,
        description: description,
        scope:
          publicData.scope?.map(scopeItem => {
            return { task: scopeItem.name, subtasks: scopeItem.subtasks };
          }) ?? [],
        primarySoftware: publicData.primarySoftware,
        softwares: publicData.softwares,
        referral: referralNumber,
      };
      history.push(to, { initialValues });
    }
  };

  // const ProjectLayout = (
  //   <div className={css.leftColumn}>
     
  //     <FinalForm
  //       onSubmit={values => {
  //         sendContactRequestMail(values);
  //         // values =>
  //         //   this.handleProceedWithListing(values);
  //       }}
  //       {...props}
  //       mutators={{ ...arrayMutators }}
  //       render={formRenderProps => {
  //         const { handleSubmit } = formRenderProps;
  //         return (
  //           <div className={css.restFormContainer}>
  //             <Form className={css.form} onSubmit={handleSubmit}>
  //               <h1 className={css.inputPersonalInfo}>
  //                 <FormattedMessage id="StartProjectPage.titleForm" />
  //               </h1>
  //               <div className={css.inputPersonalInfo}>
  //                 <FieldTextInput
  //                   id="description"
  //                   name="description"
  //                   key="description"
  //                   className={css.inputFieldDescription}
  //                   type="textarea"
  //                   validate={required(descriptionRequiredMessage)}
  //                   placeholder={descriptionMessage}
  //                 />
  //                 {currentUser ? null : (
  //                   <div className={css.personalInfoContainer}>
  //                     <FieldTextInput
  //                       id="email"
  //                       name="email"
  //                       key="email"
  //                       className={css.inputFieldEmail}
  //                       type="textarea"
  //                       placeholder={emailMessage}
  //                       validate={required(contactRequiredMessage)}
  //                     />
  //                     <FieldTextInput
  //                       id="phone"
  //                       name="phone"
  //                       key="phone"
  //                       className={css.inputFieldPhone}
  //                       type="textarea"
  //                       placeholder={phoneMessage}
  //                     />
  //                   </div>
  //                 )}
  //               </div>
  //               <div className={css.termsInfo}>
  //                 <FormattedMessage
  //                   id="StartProjectPage.acceptTermsAndConditions"
  //                   values={{ termsLink }}
  //                 />
  //               </div>
  //               <div className={css.submitButton}>
  //                 <PrimaryButton
  //                   disabled={false}
  //                   inProgress={mailInProgress}
  //                   className={css.createButton}
  //                 >
  //                   <FormattedMessage id="StartProjectPage.sendRequest" />
  //                 </PrimaryButton>
  //               </div>

  //               {mailSent ? (
  //                 <h2>
  //                   <FormattedMessage id="StartProjectPage.mailSent" />
  //                 </h2>
  //               ) : null}
  //             </Form>
  //           </div>
  //         );
  //       }}
  //     />
  //     <div className={css.buttonContainer}>
  //       <div className={css.buttonsBorder}>
  //         <span className={css.buttonsBorderText}>or</span>
  //       </div>

  //       <div className={css.restButtonsContainer}>
  //         <div className={css.restButton}>
  //           <a href="/pricing" className={css.nextPageLink}>
  //             <SecondaryButton className={css.createButton}>
  //               <FormattedMessage id="StartProjectPage.hourlyPlans" />
  //             </SecondaryButton>
  //           </a>
  //           {/* <div className={css.supportInfo}>
  //             <FormattedMessage id="StartProjectPage.thirdQuestionHelp" />
  //           </div> */}
  //         </div>
  //         <div className={css.restButton}>
  //           <SecondaryButton
  //             className={css.createButton}
  //             onClick={() => {
  //               window.ga('send', 'event', 'Create-from-scratch', 'fulfill');
  //               onRedirectToEditPage('title');
  //             }}
  //           >
  //             <FormattedMessage id="StartProjectPage.startProject" />
  //           </SecondaryButton>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
 
  onRedirectToEditPage('title');
  return null
  
  // return currentUser ? null : (
  //   <Page title={title} scrollingDisabled={scrollingDisabled}>
  //     <LayoutWrapperTopbar>
  //       <TopbarContainer currentPage="StartProjectPage" />
  //     </LayoutWrapperTopbar>
  //     <LayoutWrapperMain className={css.main}>
  //       <div className={css.container}>
  //         <div className={css.innerContainer}>
  //           {ProjectLayout}
  //           <div className={css.rightColumn}>
  //             <InfoSidePanel projectForm={true} />
  //             {additionalLink}
  //           </div>
  //         </div>
  //       </div>
  //     </LayoutWrapperMain>
  //     <LayoutWrapperFooter>
  //       <Footer />
  //     </LayoutWrapperFooter>
  //   </Page>
  // );
};

StartProjectPageComponent.defaultProps = {
  currentUser: null,
};

StartProjectPageComponent.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  const {
    adminListingId,
    restOfServiceIds,
    searchInProgress,
    noFoundServices,
    serviceCategories,
    noAdminService,
  } = state.StartProjectPage;

  let pageListings = getListingsById(state, [adminListingId, ...restOfServiceIds]);

  if (adminListingId) {
    pageListings = pageListings.slice(0, 10);
  }

  const onlySupportPlans =
    pageListings?.length > 0 &&
    pageListings?.filter(
      listing =>
        listing.id.uuid === process.env.REACT_APP_BASIC_SUPPORT_PLAN_ID ||
        listing.id.uuid === process.env.REACT_APP_SUPPORT_SUPPORT_PLAN_ID ||
        listing.id.uuid === process.env.REACT_APP_STARTER_SUPPORT_PLAN_ID
    )?.length === pageListings?.length;

  pageListings = pageListings?.filter(
    service =>
      service.id.uuid !== process.env.REACT_APP_BASIC_SUPPORT_PLAN_ID &&
      service.id.uuid !== process.env.REACT_APP_SUPPORT_SUPPORT_PLAN_ID &&
      service.id.uuid !== process.env.REACT_APP_STARTER_SUPPORT_PLAN_ID
  );

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    pageListings,
    searchInProgress,
    noFoundServices: onlySupportPlans || noFoundServices,
    serviceCategories,
    noAdminService,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchAdminService: data => dispatch(fetchAdminService(data)),
  onFetchRestOfServices: data => dispatch(fetchRestOfServices(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onCreateListingDraft: values => dispatch(requestCreateListingDraft(values)),
  onPublishListingDraft: listingId => dispatch(requestPublishListingDraft(listingId)),
});

const StartProjectPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(StartProjectPageComponent);

StartProjectPage.loadData = loadData;

export default StartProjectPage;
