import React, { Component } from 'react';
import { func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import {
  propTypes,
  LISTING_CATEGORY_EXPERT,
  LISTING_STATE_PENDING_APPROVAL,
} from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { ensureOwnListing, ensureListing } from '../../util/data';
import {
  sendNewReferralListingInfo,
  sendReferralCodeUsed,
  sendNewSoftOrSkillRequest,
  sendListingCreatedMail,
} from '../../util/triggerMail';
import { ListingLink } from '../../components';
import { EditExpertForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
import { default as importedLanguages } from '../../translations/languages.json';
import { addMultipleSelectValues, connectCalendarGoogle } from '../../util/api';

import css from './EditExpertWizard.css';

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const { Money } = sdkTypes;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const defaultAvailabilityPlan = {
  type: 'availability-plan/time',
  timezone: defaultTimeZone(),
  entries: [
    { dayOfWeek: 'mon', startTime: '09:00', endTime: '21:00', seats: 1 },
    { dayOfWeek: 'tue', startTime: '09:00', endTime: '21:00', seats: 1 },
    { dayOfWeek: 'wed', startTime: '09:00', endTime: '21:00', seats: 1 },
    { dayOfWeek: 'thu', startTime: '09:00', endTime: '21:00', seats: 1 },
    { dayOfWeek: 'fri', startTime: '09:00', endTime: '21:00', seats: 1 },
  ],
};

let calenadarCounter = 0;

// Create a new or edit listing through EditListingWizard
class EditExpertWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      showPayoutDetails: false,
      showDraftModal: false,
      portalRoot: null,
      initialValues: {},
      initialValuesLoaded: {},
      availabilityPlan: {},
      calendarLists: [],
    };
    this.handleProceedWithListing = this.handleProceedWithListing.bind(this);
  }

  componentDidUpdate() {
    let initialValuesLoaded = this.state.initialValuesLoaded;
    const {
      listing,
      referralNumber,
      productSoftware,
      productSkills,
      productIndustries,
    } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { geolocation, description, title, publicData, privateData } = currentListing.attributes;
    if (!initialValuesLoaded.current) {
      const {
        linkLinkedin,
        linkCalendly,
        linkWeb,
        professionalExperience,
        location,
        projects,
        softwares,
        primarySoftware,
        skills,
        industries,
        projectsCount,
        experience,
        phone,
        expertise,
        expertise2,
        uploadedFileLink
      } = publicData;

      const ensureReferralNumber = referralNumber ? referralNumber : privateData?.referral;

      const initialLanguages = publicData.languages
        ? publicData.languages.map(language => {
            const foundLanguage = Object.entries(importedLanguages).find(
              foundLanguage => foundLanguage[0] === language
            );
            if (foundLanguage) {
              return {
                value: foundLanguage[0],
                label: `${foundLanguage[1].name} (${foundLanguage[1].nativeName})`,
              };
            } else {
              return null;
            }
          })
        : null;

      const initialSoftwares = softwares
        ? softwares.map(soft => {
            const foundSoft = productSoftware.find(foundSoft => foundSoft.key === soft);
            if (foundSoft)
              return {
                value: foundSoft.key,
                label: foundSoft.label,
              };
            else
              return {
                value: soft,
                label: soft,
              };
          })
        : null;

      const initialSkills = skills
        ? skills.map(skill => {
            const foundSkill = productSkills.find(foundSkill => foundSkill.key === skill);
            if (foundSkill)
              return {
                value: foundSkill.key,
                label: foundSkill.label,
              };
            else
              return {
                value: skill,
                label: skill,
              };
          })
        : null;

      const initialIndustries = industries
        ? industries.map(industry => {
            const foundIndustry = productIndustries.find(
              foundIndustry => foundIndustry.key === industry
            );
            if (foundIndustry) {
              return {
                value: foundIndustry.key,
                label: foundIndustry.label,
              };
            }
            return {
              value: industry,
              label: industry,
            };
          })
        : null;

      const foundPrimarySoft = productSoftware.find(foundSoft => foundSoft.key === primarySoftware);

      const locationFieldsPresent = location && location.address && geolocation;
      const { address } = location ?? {};

      let projectsLoaded = false;
      let experienceLoaded = false;

      // Zamiana linkow na string
      let strFileLinks = JSON.stringify(uploadedFileLink)

      const initialValues = {
        title,
        description,
        referral: ensureReferralNumber,
        primarySoftware: foundPrimarySoft
          ? { value: foundPrimarySoft.key, label: foundPrimarySoft.label }
          : null,
        software: initialSoftwares,
        skills: initialSkills,
        industries: initialIndustries,
        linkLinkedin,
        linkCalendly,
        linkWeb,
        location: locationFieldsPresent
          ? {
              search: address,
              selectedPlace: { address, origin: geolocation },
            }
          : null,
        projects,
        professionalExperience,
        languages: initialLanguages,
        experience,
        projectsCount,
        phone,
        expertise,
        expertise2,
        uploadedFileLink: strFileLinks,
      };
      if (professionalExperience) {
        for (let i = 0; i < professionalExperience.length; i++) {
          initialValues[`experienceTitle${i}`] = professionalExperience[i].title;
          initialValues[`experienceCompany${i}`] = professionalExperience[i].company;
          initialValues[`experienceUrl${i}`] = professionalExperience[i].url;
          initialValues[`experienceDescription${i}`] = professionalExperience[i].description;
          initialValues[`experienceYears${i}`] = professionalExperience[i].years;
          initialValues[`experienceSoftware${i}`] = professionalExperience[i].software?.map(
            software => {
              const foundSoft = productSoftware.find(foundSoft => foundSoft.key === software);
              if (foundSoft)
                return {
                  value: foundSoft.key,
                  label: foundSoft.label,
                };
              else
                return {
                  value: software,
                  label: software,
                };
            }
          );
        }
        experienceLoaded = true;
      }
      if (projects) {
        for (let i = 0; i < projects.length; i++) {
          initialValues[`projectTitle${i}`] = projects[i].title;
          initialValues[`projectCompany${i}`] = projects[i].company;
          initialValues[`projectUrl${i}`] = projects[i].url;
          initialValues[`projectDescription${i}`] = projects[i].description;
          initialValues[`projectSoftware${i}`] = projects[i].software?.map(software => {
            const foundSoft = productSoftware.find(foundSoft => foundSoft.key === software);
            if (foundSoft)
              return {
                value: foundSoft.key,
                label: foundSoft.label,
              };
            else
              return {
                value: software,
                label: software,
              };
          });
        }
        projectsLoaded = true;
      }
      if ((projectsLoaded || !projects) && (experienceLoaded || !professionalExperience)) {
        initialValuesLoaded.current = true;
        this.setState({ initialValues: initialValues, initialValuesLoaded: initialValuesLoaded });
      }
    }

    if (calenadarCounter === 0) {
      // const calendarLists = [];
      // const search = window.location.search;
      // const params = new URLSearchParams(search);

      if (sessionStorage.getItem('google_code') !== null) {
        const code = sessionStorage.getItem('google_code');
        sessionStorage.removeItem('google_code');
        connectCalendarGoogle({
          resource: 'calendar',
          method: 'list',
          params: {
            code: code,
            url: window.location.origin + '/google/redirct_uri',
            userId: this.props.currentUser.id.uuid,
          },
        }).then(result => {
          window.location.href = window.location.origin + '' + window.location.pathname;
        });
      } else {
        let _userId="";
          if(this.props.currentUser?.id?.uuid){
           _userId=this.props.currentUser.id?.uuid;
          }
        connectCalendarGoogle({
          resource: 'calendar',
          method: 'list',
          params: {
            url: window.location.origin + '/google/redirct_uri',
            userId: _userId,
          },
        })
          .then(result => {
            for (const row of result) {
              this.state.calendarLists.push(row);
            }
          })
          .catch(e => {
            // No need for extra actions
          });
      }

      calenadarCounter++;
    }
  }

  parseResumeeObjects(values, type) {
    let resumeeEntries = Object.entries(values).filter(entryArray => {
      return entryArray[0].includes(type);
    });
    let resumeeeIds = resumeeEntries
      .filter(entry => {
        return entry[0].includes(`${type}Title`) && !!entry[1] && entry[1] !== '';
      })
      .map(entry => {
        return parseInt(entry[0].replace(`${type}Title`, ''));
      })
      .sort((a, b) => {
        return a - b; // integers sort
      });

    let resumeeObjects = [];
    for (let i = 0; i < resumeeeIds?.length; i++) {
      const titleEntry = resumeeEntries.find(entry =>
        entry[0].includes(`${type}Title${resumeeeIds[i]}`)
      );
      const companyEntry = resumeeEntries.find(entry =>
        entry[0].includes(`${type}Company${resumeeeIds[i]}`)
      );
      const urlEntry = resumeeEntries.find(entry =>
        entry[0].includes(`${type}Url${resumeeeIds[i]}`)
      );
      const descriptionEntry = resumeeEntries.find(entry =>
        entry[0].includes(`${type}Description${resumeeeIds[i]}`)
      );
      const softwareEntry = resumeeEntries.find(entry =>
        entry[0].includes(`${type}Software${resumeeeIds[i]}`)
      );
      const experienceYearsEntry = resumeeEntries.find(entry =>
        entry[0].includes(`${type}Years${resumeeeIds[i]}`)
      );

      resumeeObjects.push({
        title: titleEntry ? titleEntry[1] : null,
        company: companyEntry ? companyEntry[1] : null,
        url: urlEntry ? urlEntry[1] : null,
        description: descriptionEntry ? descriptionEntry[1] : null,
        software:
          softwareEntry &&
          Array.isArray(softwareEntry) &&
          softwareEntry.length > 1 &&
          softwareEntry[1] !== undefined
            ? softwareEntry[1]?.map(software => software.label)
            : null,
        softwareSuppProject: false,
        years: experienceYearsEntry ? experienceYearsEntry[1] : null,
      });
    }
    return resumeeObjects;
  }

  handleProceedWithListing(values, saveAsDraft) {
    const { currentUser, history, listing, type, referralNumber, productSoftware } = this.props;

    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;

    const {
      title,
      description,
      primarySoftware,
      price,
      location,
      linkLinkedin,
      linkCalendly,
      linkWeb,
      projectsCount,
      experience,
      phone,
      expertise,
      languages,
      uploadedFileLink,
    } = values;

    const requestedPrimarySoftware =
      !primarySoftware || productSoftware.find(soft => soft.key === primarySoftware.value)
        ? null
        : primarySoftware.value;

    const requestedOtherSoftware = values.software
      ? values.software
          .filter(soft => {
            return soft?.__isNew__;
          })
          .map(soft => {
            return soft.value;
          })
      : [];

    const primarySoftwareKey =
      requestedPrimarySoftware || !primarySoftware ? 'other' : primarySoftware.value;

    let softwareArray = values.software
      ? values.software
          .filter(isUndefined => {
            if (!isUndefined) return false;
            return true;
          })
          .map(soft => {
            if (soft?.__isNew__) {
              return createSlug(soft.value);
            }
            return soft.value;
          })
      : [primarySoftwareKey];
    if (softwareArray && !softwareArray.find(soft => soft === primarySoftwareKey)) {
      softwareArray.unshift(primarySoftwareKey);
    } else if (
      softwareArray &&
      requestedPrimarySoftware &&
      !softwareArray.find(soft => soft === 'other')
    ) {
      softwareArray.unshift('other');
    }

    const skillsArray = values.skills?.map(skill => {
      if (skill?.__isNew__) {
        return createSlug(skill.value);
      }
      return skill.value;
    });

    const requestedSkills = values.skills
      ? values.skills
          .filter(skill => {
            return skill?.__isNew__;
          })
          .map(soft => {
            return soft.value;
          })
      : null;

    if (requestedSkills?.length > 0) {
      const requestSoftwareOrSkillBody = {
        userMail: currentUser?.attributes.email,
        listingTitle: title,
        listingCategory: LISTING_CATEGORY_EXPERT,
      };
      sendNewSoftOrSkillRequest(requestSoftwareOrSkillBody, requestedSkills, false);
      const addMultipleSelectValuesBody = requestedSkills.map(skill => {
        return {
          type: 'skill',
          label: skill,
          key: createSlug(skill),
          authorId: currentUser?.id.uuid,
          authorMail: currentUser?.attributes.email,
        };
      });
      addMultipleSelectValues({ selectValues: addMultipleSelectValuesBody });
    }

    const industriesArray = values.industries?.map(industry => {
      if (industry?.__isNew__) {
        return createSlug(industry.value);
      }
      return industry.value;
    });

    const requestedIndustries = values.industries
      ? values.industries
          .filter(industry => {
            return industry?.__isNew__;
          })
          .map(soft => {
            return soft.value;
          })
      : null;

    if (requestedIndustries?.length > 0) {
      const requestSoftwareOrSkillBody = {
        userMail: currentUser?.attributes.email,
        listingTitle: title,
        listingCategory: LISTING_CATEGORY_EXPERT,
      };
      sendNewSoftOrSkillRequest(requestSoftwareOrSkillBody, requestedIndustries, true);
      const addMultipleSelectValuesBody = requestedIndustries.map(industry => {
        return {
          type: 'industry',
          label: industry,
          key: createSlug(industry),
          authorId: currentUser?.id.uuid,
          authorMail: currentUser?.attributes.email,
        };
      });
      addMultipleSelectValues({ selectValues: addMultipleSelectValuesBody });
    }


    let uploadedFilesObj = []
    //UploadedLinks 
    if(uploadedFileLink != undefined){
      uploadedFilesObj = JSON.parse(uploadedFileLink)
    }

    if (requestedPrimarySoftware || requestedOtherSoftware?.length > 0) {
      const requestSoftwareOrSkillBody = {
        userMail: currentUser?.attributes.email,
        listingTitle: title,
        listingCategory: LISTING_CATEGORY_EXPERT,
      };
      sendNewSoftOrSkillRequest(
        requestSoftwareOrSkillBody,
        [requestedPrimarySoftware, ...requestedOtherSoftware],
        true
      );
      const addMultipleSelectValuesBody = [requestedPrimarySoftware, ...requestedOtherSoftware].map(
        software => {
          return {
            type: 'software',
            label: software,
            key: createSlug(software),
            authorId: currentUser?.id.uuid,
            authorMail: currentUser?.attributes.email,
          };
        }
      );
      addMultipleSelectValues({ selectValues: addMultipleSelectValuesBody });
    }

    const address = location?.selectedPlace?.address;
    const origin = location?.selectedPlace?.origin;

    const formattedLanguages = languages?.map(language => language.value);

    const listingValues = {
      title: title ? title.trim() : title,
      description,
      publicData: {
        category: LISTING_CATEGORY_EXPERT,
        state: isNewURI ? 'open' : listing.attributes.publicData.state,
        softwares: softwareArray,
        primarySoftware: primarySoftwareKey,
        primarySoftwareSearch: primarySoftwareKey,
        private: false,
        skills: skillsArray,
        industries: industriesArray,
        location: address ? { address } : null,
        projects: this.parseResumeeObjects(values, 'project'),
        professionalExperience: this.parseResumeeObjects(values, 'experience'),
        linkLinkedin,
        linkCalendly,
        linkWeb,
        projectsCount,
        experience,
        phone,
        expertise,
        uploadedFileLink:uploadedFilesObj,
        languages: formattedLanguages,
      },
      privateData: {
        referral: referralNumber,
      },
    };

    if (isNewURI && !this.state.availabilityPlan.entries) {
      listingValues.availabilityPlan = defaultAvailabilityPlan;
    } else if (this.state.availabilityPlan.entries) {
      const availabilityPlan = {
        ...defaultAvailabilityPlan,
        timezone: this.state.availabilityPlan.timezone
          ? this.state.availabilityPlan.timezone
          : defaultAvailabilityPlan.timezone,
        entries: isNewURI
          ? this.state.availabilityPlan.entries.concat(
              defaultAvailabilityPlan?.entries.filter(
                entry =>
                  !this.state.availabilityPlan.entries.find(e => e.dayOfWeek === entry.dayOfWeek)
              )
            )
          : this.state.availabilityPlan.entries.concat(
              listing.attributes.availabilityPlan?.entries.filter(
                entry =>
                  !this.state.availabilityPlan.entries.find(e => e.dayOfWeek === entry.dayOfWeek)
              )
            ),
      };

      listingValues.availabilityPlan = availabilityPlan;
    }

    if (price) {
      listingValues.price = price;
    } else {
      listingValues.price = new Money(2500, config.currency);
    }

    if (origin) {
      listingValues.geolocation = origin;
    } else if (this.state.initialValues?.location) {
      listingValues.geolocation = null;
    }

    if (currentUser) {
      return listingValues;
    } else {
      const path = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
      history.push(path, {
        saveAsDraft: saveAsDraft,
        quickPublishValues: listingValues,
        noSignupQuestion: true,
      });
    }
  }

  render() {
    const {
      params,
      listing,
      errors,
      onManageDisableScrolling,
      currentUser,
      currentUserHasExpertListing,
      referralNumber,
      type,
      onCreateListingDraft,
      onUpdateListing,
      history,
      handlePublishListing,
      availabilityExceptions,
      fetchExceptionsInProgress,
      onAddAvailabilityException,
      onDeleteAvailabilityException,
      updateInProgress,
      currentTab,
      onGoToNextTab,
      productSkills,
      productSoftware,
      productIndustries,
    } = this.props;

    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;

    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );
    const currentListing = ensureListing(listing);
    const currentUserExists = !!currentUser;
    const { privateData, price } = currentListing.attributes;
    const ensureReferralNumber = referralNumber ? referralNumber : privateData?.referral;

    const initialPrice = price ? new Money(price.amount, config.currency) : null;
    const isAdmin =
      currentUser && currentUser.id
        ? currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID
        : null;

    const panelTitle = currentListing.id ? (
      <FormattedMessage
        id="EditExpertWizard.title"
        values={{
          listingTitle: (
            <ListingLink listing={currentListing}>
              <FormattedMessage id="EditExpertWizard.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditExpertWizard.createListingTitle" />
    );

    const isListingStatusNew = currentListing.attributes.state ? false : true;

    const onSubmit = (updateValues, saveAsDraft) => {
      
      if (isNewListingFlow) {
        const onUpsertListingDraft = isNewURI ? onCreateListingDraft : onUpdateListing;

        const upsertValues = isNewURI
          ? updateValues
          : {
              ...updateValues,
              id: currentListing.id,
            };

        onUpsertListingDraft(upsertValues)
          .then(response => {
            const listing = response.data.data;
            if (saveAsDraft) {
              history.push(
                createResourceLocatorString('StripePayoutPage', routeConfiguration(), {}, {})
              );
            } else {
              handlePublishListing(response.data.data.id).then(data => {
                if (referralNumber) {
                  sendNewReferralListingInfo(listing);
                  sendReferralCodeUsed(listing);
                }
                sendListingCreatedMail(listing, LISTING_CATEGORY_EXPERT, currentUser);
                history.push(
                  createResourceLocatorString('StripePayoutPage', routeConfiguration(), {}, {})
                );
              });
            }
          })
          .catch(e => {
            // No need for extra actions
          });
      } else {
        debugger
        console.log(updateValues);
        onUpdateListing({ ...updateValues, id: currentListing.id }).then(response => {
          const listing = response.data.data;
          const listingParams = {
            id: listing.id.uuid,
            slug: createSlug(listing.attributes.title),
            category: 'experts',
          };
          const isPendingApproval =
            currentListing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
          if (isPendingApproval) {
            listingParams.variant = LISTING_PAGE_PENDING_APPROVAL_VARIANT;
          }
          const routeName = isPendingApproval ? 'ListingPageVariant' : 'ExpertPage';
          history.push(
            createResourceLocatorString(routeName, routeConfiguration(), listingParams, {})
          );
        });
      }
    };

    // Create entries from submit values
    const createEntriesFromSubmitValues = values =>
      WEEKDAYS.reduce((allEntries, dayOfWeek) => {
        const dayValues = values[dayOfWeek] || [];
        const dayEntries = dayValues.map(dayValue => {
          const { startTime, endTime } = dayValue;
          // Note: This template doesn't support seats yet.
          return startTime && endTime
            ? {
                dayOfWeek,
                seats: 1,
                startTime,
                endTime: endTime === '24:00' ? '00:00' : endTime,
              }
            : null;
        });

        const plan = allEntries.concat(dayEntries.filter(e => !!e));
        return plan;
      }, []);

    // Create availabilityPlan from submit values
    const createAvailabilityPlan = values => {
      const availabilityPlan = {
        type: 'availability-plan/time',
        timezone: values.timezone ?? defaultAvailabilityPlan.timezone,
        entries: createEntriesFromSubmitValues(values),
      };

      this.setState({ availabilityPlan: availabilityPlan });
      return availabilityPlan;
    };

    const verifyTooltip = (
      <Tooltip interactive title={<FormattedMessage id="EditExpertWizard.verifyTooltip" />}>
        <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
      </Tooltip>
    );

    const saveActionMessage = (
      <FormattedMessage
        id={
          currentListing.id && !isNewListingFlow
            ? 'EditExpertWizard.updateAndGoTo'
            : 'EditExpertWizard.publishAndGoTo'
        }
        values={{ tooltip: verifyTooltip }}
      />
    );

    const createDraftMessage = <FormattedMessage id="EditExpertWizard.createDraftAndGoTo" />;

    return (
      <>
      <EditExpertForm
        className={css.root}
        initialValues={this.state.initialValues}
        initialPrice={initialPrice}
        productSoftware={productSoftware}
        productSkills={productSkills}
        productIndustries={productIndustries}
        name="Project"
        saveActionMsg={saveActionMessage}
        createDraftMessage={createDraftMessage}
        onSubmit={values => {
          currentUser
            ? onSubmit(this.handleProceedWithListing(values))
            : this.handleProceedWithListing(values);
        }}
        fetchErrors={errors}
        referral={ensureReferralNumber ? config.custom.referral : null}
        budget={config.custom.budget}
        category={config.custom.category}
        userId={config.custom.userId}
        currentUserExists={currentUserExists}
        currentUserHasExpertListing={currentUserHasExpertListing}
        isAdmin={isAdmin}
        isListingStatusNew={isListingStatusNew}
        messageFormatting=""
        onSaveAsDraft={values =>
          currentUser
            ? onSubmit(this.handleProceedWithListing(values), true)
            : this.handleProceedWithListing(values, true)
        }
        isNew={isNewURI}
        onManageDisableScrolling={onManageDisableScrolling}
        currentListing={currentListing}
        availabilityExceptions={availabilityExceptions}
        fetchExceptionsInProgress={fetchExceptionsInProgress}
        defaultAvailabilityPlan={defaultAvailabilityPlan}
        createAvailabilityPlan={createAvailabilityPlan}
        WEEKDAYS={WEEKDAYS}
        submittedAvailabilityPlan={this.state.availabilityPlan ?? {}}
        onAddAvailabilityException={onAddAvailabilityException}
        onDeleteAvailabilityException={onDeleteAvailabilityException}
        panelTitle={panelTitle}
        updateInProgress={updateInProgress}
        languages={importedLanguages}
        currentTab={currentTab}
        onGoToNextTab={onGoToNextTab}
        calendarLists={this.state.calendarLists}
        user={this.props.currentUser}
      />
      </>
    );
  }
}

EditExpertWizard.defaultProps = {
  className: null,
  currentUser: null,
  listing: null,
  updateInProgress: false,
};

EditExpertWizard.propTypes = {
  id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
  }).isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
  }).isRequired,

  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { productSoftware, productSkills, productIndustries } = state.marketplaceData;
  return {
    productSoftware,
    productSkills,
    productIndustries,
  };
};

export default compose(withViewport, injectIntl, connect(mapStateToProps))(EditExpertWizard);
