import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from '../../components/ExpertOverview/ExpertPage.css';
import { InlineTextButton } from '../../components';

const SectionProjects = props => {

  const { projects } = props;

  const [showMoreProjects, setShowMoreProjects] = useState(false);

  if (!projects) {
    return null;
  }

  const renderProjects = projects => {
    const projectsLength = projects?.length;
    if (projects?.length > 1 && !showMoreProjects) {
      projects = projects.slice(0, 2);
    }
    return projects.map((project, index) => {
      let projectUrl = project.url;
      if (projectUrl) {
        const protocolTokens = projectUrl.split(':');
        const includesProtocol = protocolTokens.length > 1;
        const usesHttpProtocol = includesProtocol && !!protocolTokens[0].match(/^(https?)/);
        if (!usesHttpProtocol) {
          projectUrl = 'https://' + projectUrl;
        }
      }
      return (
        <div className={css.projects} key={project.title + index}>
          <div className={css.projectSideWrapper}>
            <div className={css.projectPointer} />
            <div className={index === projects.length - 1 ? css.projectsLastSideLine : css.projectsSideLine} />
          </div>
          <div
            className={index === 1 && !showMoreProjects && projectsLength > 2 ? css.invisibleProjectWrapper : css.projectWrapper}
          >
            <div className={css.projectFirstRow}>

              <span className={css.projectTitle} >{project.title}</span>

              {project.company ? (<span><br /> {project.company}</span>) : ''}

              {project.url
                ? <InlineTextButton className={css.viewUrl} onClick={() => window.open(projectUrl)}>
                  <span className={css.viewUrlText}>
                    <FormattedMessage id="ExpertPage.viewUrl" />
                  </span>
                </InlineTextButton>
                : null}
            </div>

            <div className={css.projectDescription}>
              {project.description}
            </div>
            {index === 1 && !showMoreProjects && projectsLength > 2
              ? <div className={css.invisibleBlock} />
              : null}
          </div>
        </div>
      );
    })
  }

  return (
    <>
      {projects?.length > 0 ?
        <>
          <h2 className={css.projectsTitle}>
            <FormattedMessage id="ExpertPage.expertProjects" />
          </h2>
          <div className={css.sectionProjects}>
            <div>
              {renderProjects(projects)}
              {projects.length > 2 && !showMoreProjects ?
                <div className={css.showMoreWrapper}>
                  <InlineTextButton className={css.showMoreButton} onClick={() => setShowMoreProjects(true)}>
                    <span className={css.showMoreText}>
                      <FormattedMessage id="ExpertPage.showMore" />
                    </span>
                  </InlineTextButton>
                </div>
                : null
              }
            </div>
          </div>
        </>
        : null}
    </>
  )
};

export default SectionProjects;
