/* eslint-disable */
import { sendTemplateMail, sendMail, triggerMail } from './api';
import { formatMoney } from './currency';
import { createSlug } from './urlHelpers';
import { LISTING_CATEGORY_JOB, LISTING_CATEGORY_EXPERT, LISTING_CATEGORY_TICKET } from './types';
import { types as sdkTypes } from './sdkLoader';

const { Money } = sdkTypes;

const notificationGroup = 26291;
const messageGroup = 26727;
const transactionGroup = 26728;

const getDate = startDate => {
  const month = startDate.getUTCMonth() === 12 ? 1 : startDate.getUTCMonth() + 1;
  return (
    (startDate.getUTCDate() < 10 ? '0' + startDate.getUTCDate() : startDate.getUTCDate()) +
    '.' +
    (month < 10 ? '0' + month : month) +
    ' at ' +
    (startDate.getUTCHours() < 10 ? '0' + startDate.getUTCHours() : startDate.getUTCHours()) +
    ':' +
    (startDate.getUTCMinutes() < 10 ? '0' + startDate.getUTCMinutes() : startDate.getUTCMinutes()) +
    ' UTC'
  );
};

const getDateNoUTC = startDate => {
  const month = startDate.getMonth() === 12 ? 1 : startDate.getMonth() + 1;
  return (
    (startDate.getDate() < 10 ? '0' + startDate.getDate() : startDate.getDate()) +
    '.' +
    (month < 10 ? '0' + month : month) +
    ' at ' +
    (startDate.getHours() < 10 ? '0' + startDate.getHours() : startDate.getHours()) +
    ':' +
    (startDate.getMinutes() < 10 ? '0' + startDate.getMinutes() : startDate.getMinutes())
  );
};

const getGoogleCalendarDateNoUtc = startDate => {
  const month = startDate.getUTCMonth() === 12 ? 1 : startDate.getUTCMonth() + 1;
  const date =
    startDate.getFullYear().toString() +
    (month < 10 ? '0' + month : month) +
    (startDate.getDate() < 10 ? '0' + startDate.getDate() : startDate.getDate()) +
    'T';
  const hour = startDate.getHours() < 10 ? '0' + startDate.getHours() : startDate.getHours();
  const endHour =
    startDate.getHours() < 9 ? '0' + (startDate.getHours() + 1) : startDate.getHours() + 1;
  const minutes =
    (startDate.getMinutes() < 10 ? '0' + startDate.getMinutes() : startDate.getMinutes()) + '00';
  return date + hour + minutes + '/' + date + (hour !== '23' ? endHour : '00') + minutes;
};

const getGoogleCalendarDate = startDate => {
  const month = startDate.getUTCMonth() === 12 ? 1 : startDate.getUTCMonth() + 1;
  const date =
    startDate.getUTCFullYear().toString() +
    (month < 10 ? '0' + month : month) +
    (startDate.getUTCDate() < 10 ? '0' + startDate.getUTCDate() : startDate.getUTCDate()) +
    'T';
  const hour =
    startDate.getUTCHours() < 10 ? '0' + startDate.getUTCHours() : startDate.getUTCHours();
  const endHour =
    startDate.getUTCHours() < 9 ? '0' + (startDate.getUTCHours() + 1) : startDate.getUTCHours() + 1;
  const minutes =
    (startDate.getUTCMinutes() < 10 ? '0' + startDate.getUTCMinutes() : startDate.getUTCMinutes()) +
    '00';
  return date + hour + minutes + '/' + date + (hour !== '23' ? endHour : '00') + minutes;
};

export const sendNewListingNotification = (listing, softwares, intl, isJob) => {
  const dynamicTemplateId = isJob
    ? 'd-b1012a92170d441f88933b83b0f8dba1'
    : 'd-bd065ea080fa4fefae204f13b1026aba';
  const listingTitle = listing.attributes.title;
  const listingCategory = listing.attributes.publicData.category;
  const slug = createSlug(listingTitle);
  const listingId = listing.id.uuid;
  const subjectTitle = `: ${listingTitle}`;
  const formattedSoftwares = softwares
    .map(software => software.charAt(0).toUpperCase() + software.slice(1))
    .join(', ');
  const body = {
    software: listing.attributes.publicData.primarySoftware,
    subjectTitle,
    listingId,
    slug,
    listingCategory,
    dynamicTemplateId,
    formattedSoftwares,
    budget:
      listing.attributes.price && listing.attributes.price.amount > 0
        ? formatMoney(
            intl,
            new Money(listing.attributes.price.amount, listing.attributes.price.currency)
          )
        : 'not specified',
    type: 'newListingNotification',
  };
  triggerMail(body);
};

export const sendNewSoftOrSkillRequest = (body, requestedList, isSoft) => {
  const dynamicTemplateId = 'd-76088311647c420fb96c131faf2994d2';
  const { listingTitle, listingCategory, userMail } = body;
  const subjectTitle = 'New software request';
  const formattedItemList = requestedList
    .filter(item => !!item)
    .map(item => item.charAt(0).toUpperCase() + item.slice(1))
    .join(', ');
  const softwareRequestedMail = {
    to: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      requestedList: formattedItemList,
      listingTitle,
      listingCategory,
      userMail,
      requestType: isSoft ? 'software' : 'skills',
    },
    subject: subjectTitle,
    templateId: dynamicTemplateId,
  };
  sendTemplateMail(softwareRequestedMail)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const sendNewReferralListingInfo = listing => {
  const dynamicTemplateId = 'd-e73e7b21fa2b4b418f39b43b8ef0e013';
  const listingTitle = listing.attributes.title;
  const listingCategory = listing.attributes.publicData.category;
  const slug = createSlug(listingTitle);
  const listingId = listing.id.uuid;
  const referralCode = listing.attributes.privateData.referral;

  const body = {
    dynamicTemplateId,
    listingTitle,
    listingCategory,
    slug,
    listingId,
    referralCode,
    type: 'newReferralListingInfo',
    toAdmin: true,
  };
  triggerMail(body);
};


export const sendCvFromFreelancer = listing => {
  const dynamicTemplateId = 'd-9981cde3ee7145d7a31fe5ea68cfa1dc';

  const body = {
    to: {
      email: listing.mail,
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    templateId: dynamicTemplateId,
    dynamicTemplateData: {
      message: listing.message,
      link: listing.link
    },
  };
  sendTemplateMail(body)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const sendReferralCodeUsed = listing => {
  const listingTitle = listing.attributes.title;
  const listingCategory = listing.attributes.publicData.category;
  const slug = createSlug(listingTitle);
  const listingId = listing.id.uuid;
  const referralCode = listing.attributes.privateData.referral;
  const dynamicTemplateId =
    listingCategory === 'job'
      ? 'd-9d86cf5b0bbf4beaab85baae1b633561'
      : 'd-ac5b9622a256405caf269e8af31a18ed';

  const body = {
    dynamicTemplateId,
    listingTitle,
    listingCategory,
    slug,
    listingId,
    referralCode,
    type: 'newReferralListingInfo',
  };
  triggerMail(body);
};

export const sendOfferAccepted = (income, price, title, provider, client, expertName, intl) => {
  const dynamicTemplateId = 'd-1b8a5dbd6be2479e9c1f15343c94aae0';
  const dynamicTemplateIdClient = 'd-46bd79563bd84afcbc58441c0b513143';
  const titleExpert = ` for ${title}`;

  const incomePrice = formatMoney(intl, income);
  const quotePrice = formatMoney(intl, price);

  const body = {
    job_title: title,
    type: 'offerAccepted',
  };

  triggerMail({
    ...body,
    dynamicTemplateId,
    income: incomePrice,
    titleExpert,
    userId: client.id.uuid,
  });

  triggerMail({
    ...body,
    expertName,
    price: quotePrice,
    dynamicTemplateId: dynamicTemplateIdClient,
    userId: provider.id.uuid,
  });
};

export const sendListingClosed = (listing, author) => {
  const dynamicTemplateId = 'd-daf685bea7554aa1888e1ecaa3167dcc';
  const title = listing.attributes.title;
  const listingCategory = listing.attributes.publicData.category;
  const slug = createSlug(title);
  const listingId = listing.id.uuid;

  const body = {
    dynamicTemplateId,
    title,
    listingCategory,
    slug,
    listingId,
    type: 'listingClosed',
    authorId: author.id.uuid,
  };

  triggerMail(body);
};

export const sendListingClosedUser = (listing, author) => {
  const dynamicTemplateId = 'd-2a4ce9cb2c0a4c42a3c2ff10a5815274';
  const title = listing.attributes.title;
  const category = listing.attributes.publicData.category;
  const slug = createSlug(title);
  const listingId = listing.id.uuid;

  const body = {
    dynamicTemplateId,
    title,
    category,
    slug,
    listingId,
    name: author.attributes.profile.firstName,
    type: 'listingClosedUser',
    authorId: author.id.uuid,
  };

  triggerMail(body);
};

//an email sent to the user that he has submitted a listing
export const sendListingCreatedMail = (listing, listingType, currentUser, projectId) => {
  
  const dynamicTemplateId =
    listingType === LISTING_CATEGORY_JOB
      ? 'd-d1cabd0b926447aeaed0ec14430fa1af'
      : listingType === LISTING_CATEGORY_EXPERT
      ? 'd-b9e6f779ce854d4c964c8620a82faad1'
      : listingType === LISTING_CATEGORY_TICKET
      ? 'd-f627b0fcabf148c494a31836c3dd1f64'
      : 'd-8fa2ff6a2ca8423eb3114149b0ad011f';
  const listingTitle = listing.attributes.title;
  const listingCategory = listing.attributes.publicData.category;
  const email = currentUser ? currentUser.attributes.email : listing.author.attributes.email;
  const name = currentUser
    ? currentUser.attributes.profile.firstName
    : listing.author.attributes.profile.firstName;
  const slug = createSlug(listingTitle);
  const listingId = listing.id.uuid;
  const listingCreatedMail = {
    to: {
      email: email,
      name: name,
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      client_name: name,
      listing_link:
        listingType === LISTING_CATEGORY_TICKET
          ? `${process.env.REACT_APP_CANONICAL_ROOT_URL}/${projectId}/board?${listingId}`
          : `${process.env.REACT_APP_CANONICAL_ROOT_URL}/${listingCategory}s/${slug}/${listingId}/pending-approval`,
    },
    templateId: dynamicTemplateId,
    asm: {
      group_id: transactionGroup,
      groups_to_display: [notificationGroup, messageGroup, transactionGroup],
    },
  };
  console.log('listingcreatedmail',listingCreatedMail)
  
  sendTemplateMail(listingCreatedMail)
    .then(res => {
      console.log('sendSucces',res);
    })
    .catch(error => {
      throw error;
    });
};

export const sendListingPublishedAfterApprovalMail = (listing, listingType, userId) => {
  const dynamicTemplateId =
    listingType === LISTING_CATEGORY_JOB
      ? 'd-85e8aec3efc943128341e133e12f2efb'
      : listingType === LISTING_CATEGORY_EXPERT
      ? 'd-b47ad0cbdd034529bab42e9dce259b7d'
      : 'd-6fc1b46cf7854b2bbfafdf6e23c5f814';

  const title = listing.attributes.title;
  const category = listing.attributes.publicData.category;
  const slug = createSlug(title);
  const listingId = listing.id.uuid;

  const body = {
    dynamicTemplateId,
    category,
    slug,
    listingId,
    type: 'listingPublished',
    authorId: userId,
  };

  triggerMail(body);
};

export const payoutDetailsFilledInInfo = currentUser => {
  const email = currentUser.attributes.email;
  const name = currentUser.attributes.profile.firstName;

  const offerCommunication = {
    to: {
      email: email,
      name: name,
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      username: name,
      inbox_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/center/sales`,
    },
    templateId: 'd-110bddaca90b4d78b26760d6bac48c13',
    asm: {
      group_id: transactionGroup,
      groups_to_display: [notificationGroup, messageGroup, transactionGroup],
    },
  };

  sendTemplateMail(offerCommunication)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

//an email sent to the user that he has submitted a listing
export const sendInviteMail = (listing, clientName, invitedClientEmail) => {
  const listingTitle = listing.attributes.title;
  const listingId = listing.id.uuid;
  const inviteUserMail = {
    to: {
      email: invitedClientEmail,
      name: 'User',
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      client_name: clientName,
      listingTitle,
      board_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/${listingId}/board`,
      signup_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/signup`,
    },
    subject: 'You have been invited to a project!',
    templateId: 'd-ae59b42e455541ca81985ac12bf11a71',
    asm: {
      group_id: transactionGroup,
      groups_to_display: [notificationGroup, messageGroup, transactionGroup],
    },
  };

  sendTemplateMail(inviteUserMail)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const newAnswer = async (listing, answer, currentUser, project) => {
  const dynamicTemplateId = 'd-6d87355a6073450880b9cb31fee42121';
  const listingTitle = listing.attributes.title;
  const listingCategory = listing.attributes.publicData.category;
  const slug = createSlug(listingTitle);
  const listingId = listing.id.uuid;
  const subjectTitle = `New answer for ${listingTitle}`;

  const formattedListingCategory = listingCategory === 'job' ? 'project' : listingCategory;
  const isOwnListing = listing.author?.id.uuid === currentUser.id.uuid;
  //Send info to listing author
  let body = {
    dynamicTemplateId,
    listingTitle: `${listingTitle} ${formattedListingCategory}`,
    listingCategory,
    slug,
    listingId,
    subjectTitle,
    projectId: listingCategory === LISTING_CATEGORY_TICKET ? project.id.uuid : null,
    authorName: listing.author?.attributes.profile.displayName?.slice(0, -2) ?? 'User',
    authorId: listing.author.id.uuid,
    answer,
    expertTitle: currentUser.attributes.profile.firstName,
    type: 'newAnswer',
  };
  if (!isOwnListing) {
    triggerMail(body);
  }

  if (listingCategory === 'ticket') {
    if (
      listing.author.id.uuid !== project.author.id.uuid &&
      currentUser.id.uuid !== project.author.id.uuid
    ) {
      body = {
        dynamicTemplateId,
        listingTitle: `${listingTitle} ${formattedListingCategory}`,
        listingCategory,
        slug,
        listingId,
        subjectTitle,
        projectId: listingCategory === LISTING_CATEGORY_TICKET ? project.id.uuid : null,
        authorName: project.author?.attributes.profile.displayName?.slice(0, -2) ?? 'User',
        authorId: project.author.id.uuid,
        answer,
        expertTitle: currentUser.attributes.profile.firstName,
        type: 'newAnswer',
      };

      triggerMail(body);
    }
  }
};

export const newAnswerNotOwn = async (listing, answer, answerProvider, expertIds, project) => {
  const dynamicTemplateId = 'd-c0d6b02cc63f4d6da7c9e22218ab3fe3';
  const listingTitle = listing.attributes.title;
  const listingCategory = listing.attributes.publicData.category;
  const slug = createSlug(listingTitle);
  const listingId = listing.id.uuid;
  const subjectTitle = `New answer for ${listingTitle}`;

  expertIds = expertIds.filter(id => id !== listing.author.id.uuid);

  const body = {
    dynamicTemplateId,
    listingTitle,
    listingCategory,
    slug,
    listingId,
    subjectTitle,
    answer,
    answerProvider,
    expertIds,
    projectId: listingCategory === LISTING_CATEGORY_TICKET ? project.id.uuid : null,
    type: 'newAnswerNotOwn',
  };

  triggerMail(body);
};

export const newMeetingEmail = (
  user,
  listing,
  orderLink,
  saleLink,
  startDate,
  payinTotal,
  payoutTotal
) => {
  const dynamicTemplateId =
    payinTotal && payoutTotal
      ? 'd-0f2d3034ad444d1186074836a933ed08'
      : 'd-2fd6e810abc14f4c8f32a64b7e8c75fa';
  const dynamicTemplateIdAuthor =
    payinTotal && payoutTotal
      ? 'd-65a5a6a51510418b93a281a5cd993251'
      : 'd-688b7232e05d4c14bfa6c74c2b305b8c';
  const listingTitle = listing.attributes.title;
  const listingCategory = listing.attributes.publicData.category;
  const author = listing.author;
  const slug = createSlug(listingTitle);
  const listingId = listing.id.uuid;
  const title = ` with ${listingTitle}`;
  const date = getDate(startDate);
  const googleCalendarDate = getGoogleCalendarDate(startDate);

  const dateNoUtc = getDateNoUTC(startDate);
  const googleCalendarDateNoUtc = getGoogleCalendarDateNoUtc(startDate);

  const bodyClient = {
    dynamicTemplateId,
    listingTitle,
    listingCategory,
    name: author.attributes.profile.firstName,
    slug,
    listingId,
    title,
    date: dateNoUtc,
    googleCalendarDate,
    link: 'https://whereby.com/softwaresupp',
    userId: user.id.uuid,
    transactionLink: orderLink,
    price: payinTotal / 100,
    type: 'newMeeting',
  };

  triggerMail(bodyClient);
  console.log('bodyClient', bodyClient);
  const bodyExpert = {
    dynamicTemplateId: dynamicTemplateIdAuthor,
    client_name: user.attributes.profile.displayName.slice(0, -2),
    price: payoutTotal / 100,
    date,
    googleCalendarDate,
    link: 'https://whereby.com/softwaresupp',
    userId: author.id.uuid,
    transactionLink: saleLink,
    type: 'newMeeting',
  };

  triggerMail(bodyExpert);
};

export const sendNewUserAddedToTicketInfo = (
  listing,
  usersArray,
  isNewListing,
  currentUser,
  projectId
) => {
  const dynamicTemplateId = isNewListing
    ? 'd-c4c2852a181b4e388cd783ba303ba2c3'
    : 'd-0ff099d37feb4ddeaad3ca3431cbd7ec';
  const authorName = listing.author
    ? listing.author.attributes.profile.displayName.slice(0, -2)
    : currentUser.attributes.profile.firstName;
  const listingTitle = listing.attributes.title;
  const listingId = listing.id.uuid;
  const addedToTicketInfo = {
    to: usersArray.map(user => {
      return {
        email: user,
        name: 'User',
      };
    }),
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      ticket_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/${projectId}/board?${listingId}`,
      name: listingTitle,
      author: authorName,
    },
    templateId: dynamicTemplateId,
    asm: {
      group_id: messageGroup,
      groups_to_display: [notificationGroup, messageGroup, transactionGroup],
    },
  };
  sendTemplateMail(addedToTicketInfo)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const newNPSMail = (value, messages, listingId, userName, npsCategories) => {
  const numbers = npsCategories
    .map((category, index) => {
      if (value[index] !== undefined) return category + ': ' + value[index] + ' ';
      else return null;
    })
    .filter(submittedValue => !!submittedValue);

  const additionalInfo = Object.entries(messages)
    .filter(entry => entry[0] !== 'userName')
    .map(entry => {
      return entry[0] + ': ' + entry[1] + ' ';
    });

  //PRODUCTION
  const dynamicTemplateId = 'd-95844990decb4563a6b7749ee39d7a58';
  // TEST
  // const dynamicTemplateId = 'd-db35248949724bc0869d8cc077ce5b7b';
  const npsMail = {
    to: [
      {
        email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
        name: 'SoftwareSupp.com',
      },
      //PRODUCTION
    ],
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      message: additionalInfo,
      value: numbers,
      listingId,
      userName,
    },
    templateId: dynamicTemplateId,
  };
  // if (process.env.REACT_APP_ENV === 'production') {
  //   npsMail.to.push({
  //     email: 'nps.oqrf9m@zapiermail.com',
  //     name: 'SoftwareSupp.com',
  //   });
  // }
  sendTemplateMail(npsMail)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const recommendExpertMail = (listing, mail, name) => {
  const dynamicTemplateId = 'd-6254dd9a0df645e5a47d79a27e65accf';
  const { title, publicData } = listing.attributes;
  const expertId = publicData.expert[0];
  const projectSlug = createSlug(title);

  const recommendationRequestBody = {
    to: {
      name,
      email: mail,
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      clientName: name,
      listingTitle: title,
      recommendExpertLink: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/experts/${projectSlug}-expert/${expertId}?action=recommend`,
    },
    templateId: dynamicTemplateId,
    asm: {
      group_id: transactionGroup,
      groups_to_display: [notificationGroup, messageGroup, transactionGroup],
    },
  };

  sendTemplateMail(recommendationRequestBody)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const newUpvoteNotification = (listing, upvotingUser) => {
  const dynamicTemplateId = 'd-fa0245e19d3746bb9a16b33662464d4f';
  const authorName = listing.attributes.title;
  const slug = createSlug(authorName);
  const listingId = listing.id.uuid;
  const listingCategory = listing.attributes.publicData.category;

  const body = {
    dynamicTemplateId,
    authorName,
    slug,
    listingId,
    listingCategory,
    type: 'upvoteNotification',
    authorId: listing.author.id.uuid,
    upvotingUsername: upvotingUser.attributes.profile.firstName,
  };

  triggerMail(body);
};

export const feedbackReward = (mail, name) => {
  const dynamicTemplateId = 'd-267e2e4d815944f9a6f5e81b05f9f2bb';
  const body = {
    to: {
      name,
      email: mail,
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    templateId: dynamicTemplateId,
    asm: {
      group_id: transactionGroup,
      groups_to_display: [notificationGroup, messageGroup, transactionGroup],
    },
  };
  sendTemplateMail(body)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const newMeetingSystemEmail = ( user,
  listing,
  startDate,
  resultGoogle) => {

   //console.dir({user,listing,startDate,resultGoogle},{depth:10});
   //console.dir(resultGoogle,{depth:10});
   console.dir(user,{depth:10});
   console.dir(listing,{depth:10});
    
   // return false;

  //d-355528a1d8ee471a9ca7c3dcb22d0ba9 //gest
  //{{name}} {{client_name}}} {{listingTitle}} {{date}} {{googleCalendarDate}}

  //d-ebff1722847e4adc85f92855e974dbba //frelancer
  //{{name} {{client_name}}} {{listingTitle}} {{date}} {{googleCalendarDate}}
  
  const dataName=listing.attributes.title;
  const clientName=user.attributes.profile.displayName;

  const toArr=[{
    name:resultGoogle.organizer.email,
    email: resultGoogle.organizer.email
  }];


  
  const body = {
    to: toArr,
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    templateId: "",
    dynamicTemplateData: {
      name: dataName,
      client_name: clientName,
      listingTitle: resultGoogle.hangoutLink,
      date: resultGoogle.start.dateTime,
      googleCalendarDate: resultGoogle.start.dateTime
    },
    asm: {
      group_id: transactionGroup,
      groups_to_display: [notificationGroup, messageGroup, transactionGroup],
    },
  };

  for(const tid of ['d-ebff1722847e4adc85f92855e974dbba','d-355528a1d8ee471a9ca7c3dcb22d0ba9']){

    
    if(tid==="d-355528a1d8ee471a9ca7c3dcb22d0ba9"){
      const arrTo2=[];
      
      for(const email of resultGoogle.attendees){
        arrTo2.push({name:email.email,email:email.email})
      }
     

      body.to=arrTo2;
      
    }

    body.templateId=tid;

  sendTemplateMail(body)
    .then(res => {
       console.log(res);
    })
    .catch(error => {
      throw error;
    });

  }
};

export const newServiceFrameMail = (website, email, userId) => {
  const data = {
    to: {
      email: 'filip@softwaresupp.com',
      name: 'Filip@SoftwareSupp',
    },
    subject: 'New Service Frame Request',
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    text: `
      Website: ${website} \n
      Author email: ${email} \n
      Author userId: ${userId} \n
    `,
  };
  sendMail(data)
    .then(res => {})
    .catch(error => {
      throw error;
    });
};

export const newQuestionForExam = questionData => {
  const data = {
    to: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp',
    },
    subject: 'New Exam Question Suggestion',
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    text:
      `
      Author name: ${questionData.authorName} \n
      Author email: ${questionData.authorEmail} \n
      Author userId: ${questionData.authorId} \n
      Exam type: ${questionData.examType} \n
      Question: ${questionData.question} \n
      Answers:
    ` +
      questionData.answers.reduce((acc, v) => {
        return acc + v + '\n';
      }, ''),
  };
  sendMail(data)
    .then(res => {})
    .catch(error => {
      throw error;
    });
};

export const activateExpertCampaign = (listing, user) => {
  const dynamic24hTemplateId = 'd-e89f804220d149a995a774fee4a73b04';
  const dynamic48hTemplateId = 'd-a698b1f0627b4aafa88e3ea6aeca2e9f';
  const dynamic72hTemplateId = 'd-db7fd87baeee4e099ee1ad492b80c9fb';
  const authorName = listing.attributes.title;
  const slug = createSlug(authorName);
  const listingId = listing.id.uuid;
  const authorId = listing.author ? listing.author.id.uuid : user.id.uuid;

  const body = {
    dynamic24hTemplateId,
    dynamic48hTemplateId,
    dynamic72hTemplateId,
    authorName,
    slug,
    listingId,
    authorId,
    type: 'expertCampaign',
  };

  triggerMail(body);
};

export const sendExpertAssignedToTicket = (listing, expert, authorName, projectId) => {
  const dynamicTemplateId = 'd-7a0eadcce4ce4cfd9c9949fc3c786f56';
  const listingId = listing.id.uuid;
  const listingTitle = listing.attributes.title;
  const projectTitle = listing.attributes.publicData.projectTitle;

  const body = {
    dynamicTemplateId,
    expertId: expert,
    authorName,
    listingId,
    listingTitle,
    projectTitle,
    projectId,
    type: 'expertAssigned',
  };

  triggerMail(body);
};

export const sendTicketNotification = (project, ticketDescription, ticketName, type, listingId) => {
  const dynamicTemplateId =
    type === 'newTask'
      ? 'd-101c1096d0f0412b8236bc93886e7130'
      : 'd-682b256fa29740668c77e9a7f02701e6';
  const projectId = project.id.uuid;
  const connectedIds = project.attributes.publicData.expert
    ? project.attributes.publicData.expert.concat([projectId])
    : [projectId];
  const projectTitle = project.attributes.title;
  const listingTitle = project.attributes.publicData.projectTitle;

  const body = {
    dynamicTemplateId,
    connectedIds,
    ticketDescription,
    projectId,
    listingTitle,
    projectTitle,
    ticketName,
    listingId,
    notificationType: type,
    type: 'ticketNotification',
  };

  triggerMail(body);
};

export const sendNewTicketInvitedUserMail = (project, currentUserName, ticket) => {
  const dynamicTemplateId = 'd-28bb4f8d22cf45cf9a10001347a42176';
  const projectTitle = project.attributes.title;
  const projectId = project.id.uuid;
  const invitedUsers = project.attributes.publicData.invitedUsers.map(user => {
    return {
      name: 'SoftwareSupp User',
      email: user,
    };
  });
  const ticketId = ticket.id.uuid;
  const ticketTitle = ticket.attributes.title;

  const addedToTicketInfo = {
    to: invitedUsers,
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      projectTitle,
      ticketTitle,
      userName: currentUserName,
      projectLink: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/${projectId}/board?${ticketId}`,
    },
    templateId: dynamicTemplateId,
    asm: {
      group_id: notificationGroup,
      groups_to_display: [notificationGroup, messageGroup, transactionGroup],
    },
  };
  sendTemplateMail(addedToTicketInfo)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const sendSubscriptionInfo = (user, version) => {
  const dynamicTemplateId = 'd-99d286e02c344dc3bcfbeded6810a11a';
  const userId = user.id.uuid;
  const userName = user.attributes.profile.firstName;
  const userMail = user.attributes.email;

  const subscriptionMail = {
    to: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      user_id: userId,
      user_name: userName,
      user_mail: userMail,
      version: version,
    },
    // changing template because SoftwareSupp no longer publishing new listing for client
    templateId: dynamicTemplateId,
  };
  sendTemplateMail(subscriptionMail);
};

export const newMention = (emailList, listing, currentUser, formattedOffer, projectId) => {
  if (listing.attributes.publicData.invitedUsers !== undefined) {
    if (listing.attributes.publicData.invitedUsers.includes(emailList[0])) {
      const dynamicTemplateId = 'd-3e34eb0e42b845aebc5383001b7a0295';
      const authorName = currentUser.attributes.profile.firstName;
      const listingTitle = listing.attributes.title;
      const listingCategory = listing.attributes.publicData.category;
      const listingId = listing.id.uuid;
      const addedToTicketInfo = {
        to: emailList.map(user => {
          return {
            email: user,
          };
        }),
        from: {
          email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
          name: 'SoftwareSupp.com',
        },
        dynamicTemplateData: {
          ticket_link:
            listingCategory === LISTING_CATEGORY_TICKET
              ? `${process.env.REACT_APP_CANONICAL_ROOT_URL}/${projectId}/board?${listingId}`
              : `${process.env.REACT_APP_CANONICAL_ROOT_URL}/${projectId}/board`,
          name: listingTitle,
          author: authorName,
          offer: formattedOffer,
        },
        templateId: dynamicTemplateId,
        asm: {
          group_id: messageGroup,
          groups_to_display: [notificationGroup, messageGroup, transactionGroup],
        },
      };
      sendTemplateMail(addedToTicketInfo)
        .then(res => {
          // console.log(res);
        })
        .catch(error => {
          throw error;
        });
    } else {
      return 'Invalid user';
    }
  } else {
    return 'Invalid user';
  }
};

export const sendLastNPS = (listingId, listingTitle, user, invitedUsers) => {
  const dynamicTemplateId = 'd-78f0d7994976447fb4cb0d2b458c6597';
  let emailList = [{ email: user.attributes.email, name: user.attributes.profile.firstName }];
  if (invitedUsers) invitedUsers.map(user => emailList.push({ email: user }));
  const lastNPSMail = {
    to: emailList,
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      npsTimestamp: 'final',
      listingTitle,
      npsLink: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/${listingId}`,
    },
    templateId: dynamicTemplateId,
    asm: {
      group_id: transactionGroup,
      groups_to_display: [notificationGroup, messageGroup, transactionGroup],
    },
  };
  sendTemplateMail(lastNPSMail)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const sendAdminSummaryOffer = (listing, saleLink) => {
  const dynamicTemplateId = 'd-7c6b4cb458c040fba45ee263047e4650';
  const listingTitle = listing.attributes.title;

  const body = {
    name: listing.author.attributes.profile.displayName.slice(0, -2),
    dynamicTemplateId,
    saleLink,
    listingTitle,
    authorId: listing.author.id.uuid,
    type: 'summaryOffer',
  };

  triggerMail(body);
};

export const sendJobUpdatedMail = async listing => {
  const dynamicTemplateId = 'd-f41943e172c049d9881361564bed9cc3';
  const listingTitle = listing.attributes.title;
  const category = listing.attributes.publicData.category;
  const slug = createSlug(listingTitle);
  const listingId = listing.id.uuid;

  const body = {
    dynamicTemplateId,
    listingTitle,
    category,
    slug,
    listingId,
    type: 'jobUpdated',
  };

  triggerMail(body);
};

export const sendNewSignupAnswer = user => {
  const dynamicTemplateId = 'd-cb5104323d724e40bc19563bc1885d97';
  const name = user.name;
  const id = user.id;
  const answer = user.answer;

  const newAnswerMail = {
    to: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      user_name: name,
      user_id: id,
      answer: answer,
    },
    templateId: dynamicTemplateId,
  };

  sendTemplateMail(newAnswerMail);
};

export const sendSoftwareSuppInviteMail = mail => {
  const dynamicTemplateId = 'd-a8df5ee49abf4552a2457102c674f768';

  const softwaresuppInviteMail = {
    to: {
      email: mail,
      name: 'User',
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    templateId: dynamicTemplateId,
  };

  sendTemplateMail(softwaresuppInviteMail)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const sendQuickPublishNotCompleted = quickPublishValues => {
  const dynamicTemplateId = 'd-d1eb39512b6e48e7a282fe128a344ab1';

  if (quickPublishValues.price) {
    quickPublishValues.price.amount = quickPublishValues.price.amount / 100;
  }

  const quickPublishNotCompletedMail = {
    to: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      quickPublishValues,
    },
    templateId: dynamicTemplateId,
  };

  sendTemplateMail(quickPublishNotCompletedMail)
    .then(res => {
      // console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const sendDiscussServiceConfirmation = (user, listing, orderLink, saleLink) => {
  const dynamicTemplateId = 'd-ca3ede9c4d5c4cdab26c9a411cd13c03';
  const dynamicTemplateIdExpert = 'd-0bc01ee8e02c46179734d06ea6370a91';
  const listingTitle = listing.attributes.title;
  const listingCategory = listing.attributes.publicData.category;
  const listingId = listing.id.uuid;
  const slug = createSlug(listingTitle);

  const body = {
    listingTitle,
    listingCategory,
    slug,
    listingId,
    type: 'serviceDiscussion',
  };

  triggerMail({
    ...body,
    userId: listing.author.id.uuid,
    name: listing.author.attributes.profile.displayName.slice(0, -2),
    expert_title: user.attributes.profile.displayName.slice(0, -2),
    transaction_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}${saleLink}`,
    templateId: dynamicTemplateIdExpert,
  });

  triggerMail({
    ...body,
    saleLink,
    userId: user.id.uuid,
    name: user.attributes.profile.firstName,
    transaction_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}${orderLink}`,
    templateId: dynamicTemplateId,
  });
};

export const sendServiceDiscussionCounteroffer = (
  transaction,
  orderLink,
  saleLink,
  transactionRole
) => {
  const dynamicTemplateId = 'd-b584fdef2bd644f88718a4e7da227948';
  const otherPartyName =
    transactionRole === 'provider'
      ? transaction.customer.attributes.profile.displayName.slice(0, -2)
      : transaction.provider.attributes.profile.displayName.slice(0, -2);

  const body = {
    userId:
      transactionRole === 'provider' ? transaction.customer.id.uuid : transaction.provider.id.uuid,
    name: otherPartyName,
    transaction_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}${
      transactionRole === 'provider' ? orderLink : saleLink
    }`,
    templateId: dynamicTemplateId,
    type: 'discussionCounteroffer',
  };

  triggerMail(body);
};

export const sendDiscussionAcceptedByExpertMail = (transaction, orderLink) => {
  const dynamicTemplateId = 'd-95d0717d88894816bf5b6cd5d80a2546';
  const customerName = transaction.customer.attributes.profile.displayName.slice(0, -2);
  const expertName = transaction.listing.attributes.title;

  const body = {
    userId: transaction.customer.id.uuid,
    name: customerName,
    expertName: expertName,
    serviceTitle: transaction.attributes.protectedData.listingTitle,
    transaction_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}${orderLink}`,
    templateId: dynamicTemplateId,
    type: 'discussionAcceptedByExpert',
  };
  triggerMail(body);
};

export const sendNewMessageMail = (to, authorName, message, conversationLink) => {
  const dynamicTemplateId = 'd-78f12f17a6684166992ccfe6578af466';
  const body = {
    userId: to,
    author: authorName,
    message: message,
    conversation_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}${conversationLink}`,
    templateId: dynamicTemplateId,
    type: 'sendNewMessage',
  };
  triggerMail(body);
};

export const sendProjectCounteroffer = (transaction, saleLink, orderLink, transactionRole) => {
  const dynamicTemplateId = 'd-2c281cb63b934519af12e330b7407af1';
  const otherPartyName =
    transactionRole === 'provider'
      ? transaction.customer.attributes.profile.displayName.slice(0, -2)
      : transaction.provider.attributes.profile.displayName.slice(0, -2);

  const body = {
    userId:
      transactionRole === 'provider' ? transaction.customer.id.uuid : transaction.provider.id.uuid,
    name: otherPartyName,
    transaction_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}${
      transactionRole === 'provider' ? orderLink : saleLink
    }`,
    listingTitle: transaction.listing.attributes.title,
    templateId: dynamicTemplateId,
    type: 'projectNegotiationCounteroffer',
  };
  triggerMail(body);
};

export const sendProjectNegotiationAcceptedByExpertMail = (transaction, saleLink) => {
  const dynamicTemplateId = 'd-75d818b0f6c7483bab96ddac7aaec5c7';
  const customerName = transaction.provider.attributes.profile.displayName.slice(0, -2);
  const expertName = transaction.customer.attributes.profile.displayName.slice(0, -2);

  const body = {
    userId: transaction.provider.id.uuid,
    name: customerName,
    expertName: expertName,
    listingTitle: transaction.attributes.protectedData.listingTitle,
    transaction_link: `${process.env.REACT_APP_CANONICAL_ROOT_URL}${saleLink}`,
    templateId: dynamicTemplateId,
    type: 'projectNegotiationAcceptedByExpert',
  };
  triggerMail(body);
};

// export const verificationRequest = (user, files) => {

//   const dynamicTemplateId = 'd-a5ed4d60dff444d1a3d35979e9202c6b';

//   const body = {
//     dynamicTemplateId,
//     user_name: userName.attributes.profile.firstName,
//     type: 'listingClosedUser',
//     user_id: user.id.uuid
//   }
//   triggerMail(body);
// };

export const verificationRequest = (currentUser, files) => {
  const user_id = currentUser.id.uuid;
  const user_name = currentUser.attributes.profile.firstName;
  const fileLink1 = files[0].url;
  const fileLink2 = files[1]?.url;
  const data = {
    to: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp',
    },
    from: {
      // email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      email: 'rafal@softwaresupp.com',
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      user_name,
      user_id,
      fileLink1,
      fileLink2,
    },
    templateId: 'd-a5ed4d60dff444d1a3d35979e9202c6b',
  };
  sendTemplateMail(data)
    .then(res => {
      console.log(res);
    })
    .catch(error => {
      throw error;
    });
};

export const sendContactRequest = body => {
  const { email, description } = body.values;

  const data = {
    to: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp',
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      email,
      description,
    },
    templateId: 'd-39f1adce86cd4a33bde428d1cb6fdd27',
  };

  return sendTemplateMail(data);
};

// Mail dla anulowania subskrypcji

export const sendSubscriptionCancelation = async (currentUser) => {
    const user_id = currentUser.id.uuid;
    const user_name = currentUser.attributes.profile.firstName;
    const user_email = currentUser.attributes.email;



    console.log(user_email);    
    const data = {
      to: {
        email:'support@softwaresupp.com',
        name: 'SoftwareSupp',
      },
      from: {
        // email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
        email: 'support@softwaresupp.com',
        name: 'SoftwareSupp.com',
      },
      dynamicTemplateData: {
        user_email:user_email,
      },
      templateId: 'd-976394b5751b411aa65f54a5be3dc207',
    };

    return sendTemplateMail(data)
};

export const sendSubscriptionCancelationToClient = async (currentUser) => {
  const user_id = currentUser.id.uuid;
  const user_name = currentUser.attributes.profile.firstName;
  const user_email = currentUser.attributes.email;

  const data = {
    to: {
      // email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      email: user_email,
      name: 'SoftwareSupp',
    },
    from: {
      email: process.env.REACT_APP_ADMIN_EMAIL_ADDRESS,
      name: 'SoftwareSupp.com',
    },
    dynamicTemplateData: {
      user_email: user_email,
    },
    templateId: 'd-164f77304c1c46e1905c9250410e76f2',
  };

  return sendTemplateMail(data)
};

