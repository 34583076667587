/* eslint-disable */
import React, { Component } from 'react';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';

import { propTypes, LISTING_CATEGORY_EXPERT, LISTING_CATEGORY_JOB } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug, LISTING_PAGE_PENDING_APPROVAL_VARIANT } from '../../util/urlHelpers';
import routeConfiguration from '../../routeConfiguration';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LinkTabNavHorizontal,
  IconEmailSent,
  InlineTextButton,
  IconClose,
  InfoSidePanel,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  TermsOfService,
  SocialLoginButton,
} from '../../components';
import { LoginForm, SignupForm, NewUserForm, ConfirmSignupForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { login, authenticationInProgress, signup, signupWithIdp } from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail, fetchCurrentUserHasProjects } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import Cookies from 'js-cookie';
import { pathByRouteName } from '../../util/routes';
import { apiBaseUrl, sendNotification } from '../../util/api';
import {
  sendListingCreatedMail,
  sendNewReferralListingInfo,
  sendReferralCodeUsed,
  sendNewSignupAnswer,
  sendQuickPublishNotCompleted,
} from '../../util/triggerMail';
import {
  requestCreateListingDraft,
  requestPublishListingDraft,
  createTicketsFromListing,
} from '../EditProjectPage/EditProjectPage.duck';
import {
  requestCreateListingDraft as requestCreateExpertDraft,
  requestPublishListingDraft as requestPublishExpertDraft,
} from '../EditExpertPage/EditExpertPage.duck';
import { answerSignupQuestion } from '../../util/api';
import { GoogleLogo } from './socialLoginLogos';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './AuthenticationPage.css';
import { sendCvNotyfications } from '../../util/sendCvNotyfication';
const { Money } = sdkTypes;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SHOW_MAP_BREAKPOINT = 1024; // Map is not shown by default on screen below 1024px width.
const SHOW_SIDE_PANEL_BREAKPOINT = 1280; // InfoSidePanel is not shown on screen below 1280px width.

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

export class AuthenticationPageComponent extends Component {
  constructor(props) {
    super(props);

    this.afterPublishSequence = this.afterPublishSequence.bind(this);
    this.publishListing = this.publishListing.bind(this);

    let cvData = this.props.location?.state?.cvData ? this.props.location?.state?.cvData : null;
    this.state = {
      tosModalOpen: false,
      quickPublishInProgress: false,
      authError: Cookies.get('st-autherror')
        ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
        : null,
      authInfo: Cookies.get('st-authinfo')
        ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
        : null,
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      submitInProgress: false,

      cvData: this.props.location?.state?.simpleCvForm
        ? this.props.location.state.simpleCvForm
        : cvData,
    };

    this.quickPublishNotFinished = this.quickPublishNotFinished.bind(this);
  }

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');
    this.handleResize(); // initial state width and height
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('beforeunload', this.quickPublishNotFinished);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('beforeunload', this.quickPublishNotFinished);
  }

  afterPublishSequence(publishResponse, clearStorageMaybe) {
    const { history, currentUser } = this.props;
    if (clearStorageMaybe) {
      sessionStorage.removeItem('quickPublishValues');
    }
    this.setState({ quickPublishInProgress: false });
    const listingSlug = createSlug(publishResponse.data.data.attributes.title);
    const listing = publishResponse.data.data;
    const listingId = listing.id.uuid;
    const listingCategory = listing.attributes.publicData.category;
    const isExpert = listingCategory === LISTING_CATEGORY_EXPERT;
    const hasReferral = listing.attributes.privateData?.referral;

    if (hasReferral) {
      sendNewReferralListingInfo(listing);
      sendReferralCodeUsed(listing);
    }

    sendNotification(currentUser.attributes.profile.privateData.inboxToken, {
      toUserId: currentUser.id.uuid,
      from: process.env.REACT_APP_PROJECT_MANAGER_USER_ID, // ok
      title: `You have created a project!`, // ok
      subtitle: `What's next? Start your plan.`, // ok
      status: `What's next?`,
      redirectUrl: `\\center/getStarted?step=2`, // ok
      type: `PROCESS`, // ok
      shouldDisappear: false,
    });
    sendListingCreatedMail(listing, listing.attributes.publicData.category, currentUser);
    if (isExpert) {
      const to = createResourceLocatorString('StripePayoutPage', routeConfiguration(), {}, {});
      history.push(to);
    } else {
      const pathParams = {
        id: listingId,
        slug: listingSlug,
        variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
        category: `${listingCategory}s`,
      };
      this.setState({ isAfterPublish: true });
      const to = createResourceLocatorString(
        'ListingPageVariant',
        routeConfiguration(),
        pathParams,
        {}
      );
      history.push(to);
    }
  }

  handleBlockedNavigation = () => {
    const userExit = window.confirm(
      'This page is asking you to confirm that you want to leave — information you’ve entered may not be saved.'
    );
    if (userExit) {
      this.quickPublishNotFinished();
      return true;
    }
    return false;
  };

  publishListing = (quickPublishValues, clearStorageMaybe) => {
    const {
      submitQuickDraftCreate,
      submitQuickDraftCreateExpert,
      submitQuickDraftPublish,
      submitQuickDraftPublishExpert,
      onCreateTicketsFromListing,
      currentUser,
    } = this.props;
    const draftCreateType =
      quickPublishValues.publicData.category === LISTING_CATEGORY_JOB
        ? submitQuickDraftCreate
        : submitQuickDraftCreateExpert;

    const draftPublishType =
      quickPublishValues.publicData.category === LISTING_CATEGORY_JOB
        ? submitQuickDraftPublish
        : submitQuickDraftPublishExpert;
    if (quickPublishValues?.price === undefined) {
      quickPublishValues.price = new Money(30000, config.currency);
    }
    if (!this.state.quickPublishInProgress && quickPublishValues && !!currentUser) {
      this.setState({ quickPublishInProgress: true });
      return draftCreateType(
        quickPublishValues,
        quickPublishValues?.publicData.category === LISTING_CATEGORY_EXPERT
          ? defaultTimeZone()
          : currentUser?.attributes.email,
        currentUser?.id.uuid
      )
        .then(draftResponse => {
          if (quickPublishValues.publicData.category === LISTING_CATEGORY_JOB) {
            onCreateTicketsFromListing(currentUser.id.uuid, draftResponse.data.data.id);
          }
          if (!this.props.location?.state?.saveAsDraft) {
            draftPublishType(draftResponse.data.data.id.uuid).then(publishResponse => {
              this.afterPublishSequence(publishResponse, clearStorageMaybe);
            });
          } else {
            this.afterPublishSequence(draftResponse, clearStorageMaybe);
          }
        })
        .catch(e => {
          throw e;
        })
        .catch(e => {
          throw e;
        });
    }
  };

  quickPublishNotFinished = event => {
    const quickPublishValues = this.props.location?.state?.quickPublishValues;
    const loginValuesMaybe = this.props.location?.state?.loginValues;

    if (loginValuesMaybe?.email && !this.state.submitInProgress) {
      const body = {
        ...quickPublishValues,
        ...quickPublishValues?.publicData,
        ...loginValuesMaybe,
        primarySoftware: {
          label: quickPublishValues?.publicData.primarySoftware,
        },
        softwares: quickPublishValues?.publicData.softwares?.map(software => {
          return {
            label: software,
          };
        }),
      };

      sendQuickPublishNotCompleted(body);
      if (event) {
        event.preventDefault();
      }
    }
  };

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      location,
      loginError,
      scrollingDisabled,
      signupError,
      submitLogin,
      submitSignup,
      tab,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      onManageDisableScrolling,
      currentUserHasListings,
      fetchingListingInProgress,
      confirmError,
      submitSignupWithIdp,
      currentUserHasExpertListing,
      currentUserHasUnpublishedExpertListing,
      fetchingExpertListingInProgress,
      currentUserExpertListing,
    } = this.props;

    const isConfirm = tab === 'confirm';
    const isLogin = tab === 'login';
    const locationFrom = location.state && location.state.from ? location.state.from : null;
    const authinfoFrom =
      this.state.authInfo && this.state.authInfo.from ? this.state.authInfo.from : null;
    const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;
    const noSignupQuestion = location.state?.noSignupQuestion;

    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupEmailTakenError(confirmError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    ) : null;

    const quickPublishValues = this.props.location?.state?.quickPublishValues;
    const loginValuesMaybe = this.props.location?.state?.loginValues;
    const cvData = this.props.location?.state?.simpleCvForm;
    if (quickPublishValues) {
      quickPublishValues.publicData = {
        ...quickPublishValues.publicData,
        userData: {
          ...loginValuesMaybe,
        },
      };
    }

    const showWhyLogin = this.props.location?.state?.showWhyLogin;

    const isMobileLayout = this.state.windowWidth < MODAL_BREAKPOINT;
    const isMediumScreen = this.state.windowWidth < SHOW_MAP_BREAKPOINT;
    const isMediumLargeScreen = this.state.windowWidth < SHOW_SIDE_PANEL_BREAKPOINT;
    const shouldShowSidePanel = !isMobileLayout && !isMediumScreen && !isMediumLargeScreen;

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.
    const showEmailVerification =
      !isLogin && currentUserLoaded && !user.attributes.emailVerified && !noSignupQuestion;
    const redirectionTab = currentUserHasListings ? (
      <NamedRedirect name="NotificationsPage" />
    ) : (
      <NamedRedirect name="CenterPage" params={{ tab: 'getStarted' }} />
    );

    let quickPublishValuesAfterSocialLogin = {};
    quickPublishValuesAfterSocialLogin =
      typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem('quickPublishValues')) : {};

    //if it's quick publish after social login
    if (
      isAuthenticated &&
      quickPublishValuesAfterSocialLogin?.publicData &&
      !this.state.quickPublishInProgress
    ) {
      //if it's a project then always publish
      if (
        quickPublishValuesAfterSocialLogin?.publicData.category === LISTING_CATEGORY_JOB ||
        //if it's expert profile then publish only if user has no expert profile yet
        (quickPublishValuesAfterSocialLogin?.publicData.category === LISTING_CATEGORY_EXPERT &&
          fetchingExpertListingInProgress === false &&
          !currentUserHasExpertListing &&
          !currentUserHasUnpublishedExpertListing)
      ) {
        this.publishListing(quickPublishValuesAfterSocialLogin, true);
      }
      //if user has already an expert profile then redirect to edit expert page
      else if (
        quickPublishValuesAfterSocialLogin?.publicData.category === LISTING_CATEGORY_EXPERT &&
        fetchingExpertListingInProgress === false &&
        (currentUserHasExpertListing || currentUserHasUnpublishedExpertListing)
      ) {
        return (
          <NamedRedirect
            name="EditListingPage"
            params={{
              id: currentUserExpertListing.id.uuid,
              slug: createSlug(currentUserExpertListing.attributes.title),
              type: currentUserExpertListing.attributes.state,
              tab: 'description',
              category: 'expert',
            }}
          />
        );
      }
      // Already authenticated, redirect away from auth page
    } else if (isAuthenticated && from) {
      return <Redirect to={from} />;
    } else if (
      isAuthenticated &&
      currentUserLoaded &&
      !showEmailVerification &&
      !fetchingListingInProgress &&
      !noSignupQuestion
    ) {
      sendCvNotyfications(currentUser, this.state.cvData);
      return redirectionTab;
    }

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const fromState = { state: from ? { from } : null };

    const tabs = [
      {
        text: (
          <h1 className={css.tab}>
            <FormattedMessage id="AuthenticationPage.signupLinkText" />
          </h1>
        ),
        selected: !isLogin,
        linkProps: {
          name: 'SignupPage',
          to: fromState,
          state: {
            quickPublishValues: quickPublishValues,
            loginValues: loginValuesMaybe,
            showWhyLogin: showWhyLogin,
            cvData: this.state.cvData,
            from: from,
          },
        },
      },
      {
        text: (
          <h1 className={css.tab}>
            <FormattedMessage id="AuthenticationPage.loginLinkText" />
          </h1>
        ),
        selected: isLogin,
        linkProps: {
          name: 'LoginPage',
          to: fromState,
          state: {
            quickPublishValues: quickPublishValues,
            loginValues: loginValuesMaybe,
            cvData: this.state.cvData,
            from: from,
            showWhyLogin: showWhyLogin,
          },
        },
      },
    ];

    const handleSubmitConfirm = values => {
      const { idpToken, email, firstName, lastName, idpId } = this.state.authInfo;
      const { email: newEmail, firstName: newFirstName, lastName: newLastName } = values;

      // Pass email, fistName or lastName to Flex API only if user has edited them
      // sand they can't be fetched directly from idp provider (e.g. Facebook)

      const authParams = {
        ...(newEmail !== email && { email: newEmail }),
        ...(newFirstName !== firstName && { firstName: newFirstName }),
        ...(newLastName !== lastName && { lastName: newLastName }),
      };

      submitSignupWithIdp({
        idpToken,
        idpId,
        ...authParams,
      });
    };

    const handleSubmitSignupOrLogin = values => {
      // debugger;

      const { fname, lname, email, phone, countryCode, ...rest } = values;

      if (this.state.cvData) {
        values = { ...values, ...{ more: { cvData: this.state.cvData } } };
      }

      //adding signup param to control

      if (countryCode !== undefined) {
        var phoneNumber = countryCode.concat(phone);
      } else {
        var phoneNumber = phone;
      }
      const params = fname
        ? {
            firstName: fname.trim(),
            lastName: lname.trim(),
            email: email,
            phoneNumber: phoneNumber,
            title: quickPublishValues?.title ?? '',
            cvData: cvData,
            ...rest,
          }
        : null;

      const submitType = params && !isLogin ? submitSignup(params) : submitLogin(values);

      if (quickPublishValues) {
        return submitType.then(signupResult => {
          this.publishListing(quickPublishValues);
        });
      } else {
        if (sessionStorage.getItem('quickPublishValues') !== 'undefined') {
          sessionStorage.removeItem('quickPublishValues');
        }
        return submitType;
      }
    };

    const getDefaultRoutes = () => {
      const routes = routeConfiguration();
      const baseUrl = apiBaseUrl();

      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      const fromParam = from ? `from=${from}` : '';

      // Default route where user is returned after successfull authentication
      const defaultReturn = pathByRouteName('ConfirmPage', routes);
      const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

      // Route for confirming user data before creating a new user
      const defaultConfirm = pathByRouteName('ConfirmPage', routes);
      const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

      return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
    };

    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showSocialLogins = showFacebookLogin || showGoogleLogin;

    const googleButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
    );

    const authWithGoogle = () => {
      if (quickPublishValues) {
        sessionStorage.setItem('quickPublishValues', JSON.stringify(quickPublishValues));
      } else if (sessionStorage.getItem('quickPublishValues') !== 'undefined') {
        sessionStorage.removeItem('quickPublishValues');
      }
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
    };

    const idp = this.state.authInfo
      ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase())
      : null;

    const confirmForm = (
      <div className={css.content}>
        <h1 className={css.signupWithIdpTitle}>
          <FormattedMessage id="AuthenticationPage.confirmSignupWithIdpTitle" values={{ idp }} />
        </h1>

        <p className={css.confirmInfoText}>
          <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
        </p>
        {confirmErrorMessage}
        <ConfirmSignupForm
          className={css.form}
          onSubmit={handleSubmitConfirm}
          inProgress={authInProgress}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          authInfo={this.state.authInfo}
          idp={idp}
        />
      </div>
    );

    const socialLoginButtonsMaybe =
      showSocialLogins && isLogin ? (
        <div className={css.idpButtons}>
          <div className={css.socialButtonsOr}>
            <span className={css.socialButtonsOrText}>
              <FormattedMessage id="AuthenticationPage.or" />
            </span>
          </div>

          {/* {showFacebookLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithFacebook()}>
              <span className={css.buttonIcon}>{FacebookLogo}</span>
              {facebookButtonText}
            </SocialLoginButton>
          </div>
        ) : null} */}

          {showGoogleLogin && isLogin ? (
            <div className={css.socialButtonWrapper}>
              <SocialLoginButton onClick={() => authWithGoogle()}>
                <span className={css.buttonIcon}>{GoogleLogo}</span>
                {googleButtonText}
              </SocialLoginButton>
            </div>
          ) : null}
        </div>
      ) : null;

    const handleSignupQuestion = value => {
      const { history } = this.props;
      const answerData = {
        userId: currentUser.id.uuid,
        answer: value,
      };
      const mailData = {
        name: currentUser.attributes.profile.displayName,
        id: currentUser.id.uuid,
        answer: value,
      };
      answerSignupQuestion(answerData);
      sendNewSignupAnswer(mailData);

      // step of GetStarted page depending on answer
      let step = 1;
      if (value.includes('post project')) {
        step = 2;
      }
      if (value.includes('coworker')) {
        step = 3;
      }
      let to = createResourceLocatorString(
        'CenterPage',
        routeConfiguration(),
        { tab: 'getStarted' },
        {}
      );

      // redirect to creating a profile expert
      if (value.includes('expert')) {
        to = createResourceLocatorString(
          'NewListingExpertPage',
          routeConfiguration(),
          { tab: 'description' },
          {}
        );
      }

      history.push(to, { step: step });
    };

    const authenticationForms = (
      <div className={css.content}>
        <LinkTabNavHorizontal className={css.tabs} tabs={tabs} />
        {loginOrSignupError}

        {isLogin ? (
          <LoginForm
            className={css.form}
            onSubmit={values => handleSubmitSignupOrLogin(values, false)}
            inProgress={authInProgress}
            isQuickPublish={!!quickPublishValues}
            isExpert={quickPublishValues?.publicData.category === LISTING_CATEGORY_EXPERT}
            initialValues={loginValuesMaybe}
            showWhyLogin={showWhyLogin}
          />
        ) : (
          <SignupForm
            className={css.form}
            onSubmit={values => handleSubmitSignupOrLogin(values, true)}
            inProgress={authInProgress}
            onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
            initialValues={loginValuesMaybe}
            showWhyLogin={showWhyLogin}
          />
        )}
        {socialLoginButtonsMaybe}
      </div>
    );

    const formContent = isConfirm ? confirmForm : authenticationForms;

    const name = user.attributes.profile.firstName;

    const resendEmailLink = (
      <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
        <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
      </InlineTextButton>
    );
    const fixEmailLink = (
      <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
        <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
      </NamedLink>
    );

    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
      sendVerificationEmailError
    )
      ? 'AuthenticationPage.resendFailedTooManyRequests'
      : 'AuthenticationPage.resendFailed';
    const resendErrorMessage = sendVerificationEmailError ? (
      <p className={css.error}>
        <FormattedMessage id={resendErrorTranslationId} />
      </p>
    ) : null;

    const emailVerificationContent = (
      <div className={css.content}>
        <NamedLink className={css.verifyClose} name="CenterPage" params={{ tab: 'getStarted' }}>
          <span className={css.closeText}>
            <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
          </span>
          <IconClose rootClassName={css.closeIcon} />
        </NamedLink>
        <IconEmailSent className={css.modalIcon} />
        <p className={css.modalMessage}>
          <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
          <br />
          <FormattedMessage id="AuthenticationPage.signupQuestion" />
        </p>
        <NewUserForm onSubmit={e => handleSignupQuestion(e.question)} />
        {resendErrorMessage}

        <div className={css.bottomWrapper}>
          <p className={css.modalHelperText}>
            {sendVerificationEmailInProgress ? (
              <FormattedMessage id="AuthenticationPage.sendingEmail" />
            ) : (
              <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
            )}
          </p>
          <p className={css.modalHelperText}>
            <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
          </p>
        </div>
      </div>
    );

    const siteTitle = config.siteTitle;
    const schemaTitle = isLogin
      ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
      : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

    const topbarClasses = classNames({
      [css.hideOnMobile]: showEmailVerification,
    });

    return (
      <>
        {!this.state.quickLoginInProces ? (
          <Page
            title={schemaTitle}
            scrollingDisabled={scrollingDisabled}
            schema={{
              '@context': 'http://schema.org',
              '@type': 'WebPage',
              name: schemaTitle,
            }}
          >
            <LayoutWrapperTopbar>
              <TopbarContainer className={topbarClasses} />
            </LayoutWrapperTopbar>
            <LayoutWrapperMain className={css.layoutWrapperMain}>
              <Prompt
                message={this.handleBlockedNavigation}
                when={!!quickPublishValues && !this.state.isAfterPublish}
              />
              <div className={css.containerWrapper}>
                <div className={css.container}>
                  {showEmailVerification ? emailVerificationContent : formContent}
                </div>
                {shouldShowSidePanel ? (
                  <InfoSidePanel showLightbox={this.props.showLightbox} />
                ) : null}
              </div>
              <Modal
                id="AuthenticationPage.tos"
                isOpen={this.state.tosModalOpen}
                onClose={() => this.setState({ tosModalOpen: false })}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.termsWrapper}>
                  <h2 className={css.termsHeading}>
                    <FormattedMessage id="AuthenticationPage.termsHeading" />
                  </h2>
                  <TermsOfService />
                </div>
              </Modal>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </Page>
        ) : null}
      </>
    );
  }
}

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  confirmError: null,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  submitQuickDraftCreate: func.isRequired,
  submitQuickDraftPublish: func.isRequired,
  confirmError: propTypes.error,
  tab: oneOf(['login', 'signup', 'confirm']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.Auth;
  const {
    currentUser,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    currentUserHasListings,
    fetchingListingInProgress,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    fetchingExpertListingInProgress,
    currentUserExpertListing,
  } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    currentUserHasListings,
    fetchingListingInProgress,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    fetchingExpertListingInProgress,
    currentUserExpertListing,
  };
};

const mapDispatchToProps = dispatch => ({
  checkProjects: () => dispatch(fetchCurrentUserHasProjects()),
  submitLogin: ({ email, password, more }) => dispatch(login(email, password, more)),
  submitSignup: params => dispatch(signup(params)),
  submitQuickDraftCreate: (params, email, id) =>
    dispatch(requestCreateListingDraft(params, email, id)),
  submitSignupWithIdp: params => dispatch(signupWithIdp(params)),
  submitQuickDraftPublish: params => dispatch(requestPublishListingDraft(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  submitQuickDraftCreateExpert: (params, timeZone) =>
    dispatch(requestCreateExpertDraft(params, timeZone)),
  submitQuickDraftPublishExpert: params => dispatch(requestPublishExpertDraft(params)),
  onCreateTicketsFromListing: (userId, listingId) =>
    dispatch(createTicketsFromListing(userId, listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
