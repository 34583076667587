import React from 'react';
import { injectIntl } from '../../util/reactIntl';
import config from '../../config';
import { PricingCard } from './PricingCard';

import css from './SubscriptionPanel.css';

const SubscriptionPanel = props => {
  const {
    onRedirect,
    onSubmitSupportPlan,
    intl,
    isNewProjectPage
  } = props;


  const { basic, support, project, starter } = config.custom.pricingFeatures;

  // const servicesTitle = intl.formatMessage({ id: 'SubscriptionPage.servicesTitle' });
  // const servicesDescription = intl.formatMessage({ id: 'SubscriptionPage.servicesDescription' });
  // const servicesButtonMessage = intl.formatMessage({ id: 'SubscriptionPage.servicesButtonMessage' });
  // const servicesPrice = intl.formatMessage({ id: 'SubscriptionPage.servicesPrice' });
  

  //const taskTitle = intl.formatMessage({ id: 'SubscriptionPage.taskTitle' });
  //const taskDescription = intl.formatMessage({ id: 'SubscriptionPage.taskDescription' });
  //const taskButtonMessage = intl.formatMessage({ id: 'SubscriptionPage.taskButtonMessage' });
  //const taskPrice = intl.formatMessage({ id: 'SubscriptionPage.taskPrice' });

  const basicTitle = intl.formatMessage({ id: 'SubscriptionPage.basicTitle' });
  const basicDescription = intl.formatMessage({ id: 'SubscriptionPage.basicDescription' });
  const basicButtonMessage = intl.formatMessage({ id: 'SubscriptionPage.basicButtonMessage' });
  const basicPrice = intl.formatMessage({ id: 'SubscriptionPage.basicPrice' });



    const starterTitle = intl.formatMessage({ id: 'SubscriptionPage.starterTitle' });
    const starterDescription = intl.formatMessage({ id: 'SubscriptionPage.starterDescription' });
    const starterButtonMessage = intl.formatMessage({
      id: 'SubscriptionPage.starterButtonMessage',
    });
    const starterPrice = intl.formatMessage({ id: 'SubscriptionPage.starterPrice' });

  const supportTitle = intl.formatMessage({ id: 'SubscriptionPage.supportTitle' });
  const supportDescription = intl.formatMessage({ id: 'SubscriptionPage.supportDescription' });
  const supportButtonMessage = intl.formatMessage({ id: 'SubscriptionPage.supportButtonMessage' });
  const supportPrice = intl.formatMessage({ id: 'SubscriptionPage.supportPrice' });

  const projectTitle = intl.formatMessage({ id: 'SubscriptionPage.projectTitle' });
  const projectDescription = intl.formatMessage({ id: 'SubscriptionPage.projectDescription' });
  const projectButtonMessage = intl.formatMessage({ id: 'SubscriptionPage.projectButtonMessage' });
  const projectPrice = intl.formatMessage({ id: 'SubscriptionPage.projectPrice' });

  return (
    //<Fragment>
      //<div className={css.content}>
        //<PricingCard
          //title={taskTitle}
          //price={taskPrice}
          //description={taskDescription}
          //features={task}
          //buttonMessage={taskButtonMessage}
          //onSubmit={() => onSubmitSupportPlan('task')}
          //isTask
          //isNewProjectPage={isNewProjectPage}
        ///>
      //</div>
      <div className={css.content}>
        <PricingCard
          title={starterTitle}
          price={starterPrice}
          description={starterDescription}
          features={starter}
          buttonMessage={starterButtonMessage}
          onSubmit={() => onSubmitSupportPlan('starter')}
          isStarter
          isNewProjectPage={isNewProjectPage}
        />
        <PricingCard
          title={basicTitle}
          price={basicPrice}
          description={basicDescription}
          features={basic}
          buttonMessage={basicButtonMessage}
          onSubmit={() => onSubmitSupportPlan('basic')}
          isBasic
          isNewProjectPage={isNewProjectPage}
        />
        <PricingCard
          title={supportTitle}
          price={supportPrice}
          description={supportDescription}
          features={support}
          buttonMessage={supportButtonMessage}
          onSubmit={() => onSubmitSupportPlan('support')}
          isSupport
          isNewProjectPage={isNewProjectPage}
        />
      {!isNewProjectPage ? (
        <PricingCard
          title={projectTitle}
          price={projectPrice}
          description={projectDescription}
          features={project}
          buttonMessage={projectButtonMessage}
          onSubmit={() => onRedirect('NewListingPage')}
          isNewProjectPage={isNewProjectPage}
        />
        ) : null}
      </div>
  ///  </Fragment>
  );
};

export default injectIntl(SubscriptionPanel);
