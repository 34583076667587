import React, { Component } from 'react';
import { array, bool, func, number, object, objectOf, string, shape } from 'prop-types';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  SearchResultsPanel
} from '../../components';

import css from './SearchPageServiceFrame.css';

class MainPanelServiceFrameComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isSearchFiltersPanelOpen: false };
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      listings,
      searchInProgress,
      searchParamsAreInSync,
      onActivateListing,
      pagination,
      searchParamsForPagination,
      category,
      software,
      history,
    } = this.props;

    // With time-based availability filtering, pagination is NOT
    // supported. In these cases we get the pagination support info in
    // the response meta object, and we can use the count of listings
    // as the result count.
    //
    // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;

    const classes = classNames(rootClassName || css.searchResultContainer, className);

    const hasNoResult = listingsAreLoaded && totalItems === 0;
    if (hasNoResult && software) {
      if(history.location.pathname.includes('frame') && history.location.pathname.includes('experts')) {
        history.push(createResourceLocatorString(`SearchPage_frame_${category}s`, routeConfiguration(), {}, {pub_softwares:software}));
      }
    }

    return (
      <div className={classes}>
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            <SearchResultsPanel
              className={css.searchListingsPanel}
              listings={listings}
              pagination={listingsAreLoaded ? pagination : null}
              search={searchParamsForPagination}
              setActiveListing={onActivateListing}
              urlQueryParams={urlQueryParams}
              searchedSoftware={software}
            />
          </div>
      </div>
    );
  }
}

MainPanelServiceFrameComponent.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  primaryFilters: null,
  secondaryFilters: null,
};

MainPanelServiceFrameComponent.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  primaryFilters: objectOf(propTypes.filterConfig),
  secondaryFilters: objectOf(propTypes.filterConfig),
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const MainPanelServiceFrame = withRouter(MainPanelServiceFrameComponent);

export default MainPanelServiceFrame;
