import React, {useState, useEffect} from 'react';
import { func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { required, composeValidators, numberValueAtLeast, nonNegativeNumber } from '../../util/validators';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Button, Form, FieldCurrencyInput, NamedLink } from '../../components';
import SideValidatedFieldTextInput from './SideValidatedFieldTextInput';
import CustomOfferScopeInput from './CustomOfferScopeInput/CustomOfferScopeInput';
import css from './NegotiateOfferForm.css';

export const NegotiateOfferFormComponent = props => {

  const [isScopeLoaded, setIsScopeLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [loadedValues, setLoadedValues] = useState({});
  const [currencyInputEstimatedPriceWatcher, setCurrencyInputEstimatedPriceWatcher] = useState(0);

  const { Money } = sdkTypes;
  const { currentUserExpertListing, commission, disabled, formMinimumPriceNotReached, readerRole, transactionId, negotiationType, adminEditMode } = props;
  const { latestOfferScope, latestOfferData, currentCustomerIsAdmin } = props;
  const currentUserHasHourlyRate = !!currentUserExpertListing && !!currentUserExpertListing.attributes.price;
  const currentUserHourlyRate = currentUserExpertListing?.attributes?.price?.amount;
  const currentUserExpertProfileTitle = currentUserExpertListing?.attributes?.title?.toLowerCase();
  const currentUserExpertProfileId = currentUserExpertListing?.id?.uuid;
  const currentUserHourlyRateLink = "/l/"+currentUserExpertProfileTitle+"/"+currentUserExpertProfileId+"/edit/expert/pricing";

  // eslint-disable-next-line no-unused-vars
  const withCommission = (price) => {
    return Math.ceil(price / (1-commission));
  }

  // eslint-disable-next-line no-unused-vars
  const withoutCommission = (price) => {
    return Math.trunc(price - (price * commission));
  }

  const termsLink = (
    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
      <FormattedMessage id="JobApplicationForm.termsOfUse" />
    </NamedLink>
  );

  // eslint-disable-next-line no-unused-vars
  const priceTooltip = (
    <Tooltip interactive title={<FormattedMessage id='NewOfferForm.priceTooltip' values={{ br: <br /> }} />} >
      <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
    </Tooltip>
  );

  const sendOfferTooltip = (
    <>
      <FormattedMessage id={`NewOfferForm.sendOffer`} />
      <Tooltip interactive title={<FormattedMessage id={`JobApplicationForm.sendOfferExplanation`} values={{ br: <br />, terms: termsLink }} />} >
        <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
      </Tooltip>
    </>
  );

  useEffect(() => {
    if(props.isModalOpen) {
      loadScope();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isModalOpen]);

  const loadScope = () => {
    let initialValues = {};
    let loadedValues = {};
    // values for submit callback function
    initialValues[`commission`] = commission;
    initialValues[`negotiationType`] = negotiationType;
    initialValues[`authorRole`] = readerRole;
    initialValues[`transactionId`] = transactionId;
    initialValues[`currentCustomerIsAdmin`] = currentCustomerIsAdmin;
    const {estimatedPrice, estimatedTime} = latestOfferData;
    if(estimatedPrice && estimatedPrice?.amount > 0) {
      if(readerRole === 'expert' && negotiationType === 'service') {
        loadedValues['estimatedPriceSummary'] = new Money(withoutCommission(estimatedPrice.amount), config.currency);
      } 
      else if(readerRole === 'expert' && !currentCustomerIsAdmin) {
        loadedValues['estimatedPriceSummary'] = new Money(withoutCommission(estimatedPrice.amount), config.currency);
      } else {
        loadedValues['estimatedPriceSummary'] = new Money(estimatedPrice.amount, config.currency);
      }
    }
    if(estimatedTime && estimatedTime > 0) {
      initialValues['estimatedTime'] = estimatedTime;
    }
    for(let i = 0; i < latestOfferScope.length; i++) {
      if(latestOfferScope[i].name && latestOfferScope[i].name.length > 0) {
        initialValues[`scopeTaskNameInput${i}`] = latestOfferScope[i].name;
        loadedValues[`scopeTaskNameInput${i}`] = latestOfferScope[i].name;
      }
      if(latestOfferScope[i].time && latestOfferScope[i].time > 0) {
        initialValues[`scopeTaskTimeInput${i}`] = latestOfferScope[i].time.toString();
        loadedValues[`scopeTaskTimeInput${i}`] = latestOfferScope[i].time.toString();
      }
      if(latestOfferScope[i].price && latestOfferScope[i].price > 0) {
        if(readerRole === 'expert' && negotiationType === 'service') {
          initialValues[`scopeTaskPriceInput${i}`] = new Money(withoutCommission(latestOfferScope[i].price)*100, config.currency);
          loadedValues[`scopeTaskPriceInput${i}`] = new Money(withoutCommission(latestOfferScope[i].price)*100, config.currency);
        } else if(readerRole === 'client' && !currentCustomerIsAdmin && negotiationType !== 'service') {
          initialValues[`scopeTaskPriceInput${i}`] = new Money(withCommission(latestOfferScope[i].price*100), config.currency);
          loadedValues[`scopeTaskPriceInput${i}`] = new Money(withCommission(latestOfferScope[i].price*100), config.currency);
        } else {
          initialValues[`scopeTaskPriceInput${i}`] = new Money(latestOfferScope[i].price*100, config.currency);
          loadedValues[`scopeTaskPriceInput${i}`] = new Money(latestOfferScope[i].price*100, config.currency);
        }
      }
      if(latestOfferScope[i].subtasks && latestOfferScope[i].subtasks.length > 0) {
        for(let j = 0; j < latestOfferScope[i].subtasks.length; j++) {
          if(latestOfferScope[i].subtasks[j].name && latestOfferScope[i].subtasks[j].name.length > 0) {
            initialValues[`scopeTask${i}SubtaskNameInput${j}`] = latestOfferScope[i].subtasks[j].name;
            loadedValues[`scopeTask${i}SubtaskNameInput${j}`] = latestOfferScope[i].subtasks[j].name;
          }
          if(latestOfferScope[i].subtasks[j].time && latestOfferScope[i].subtasks[j].time > 0) {
            initialValues[`scopeTask${i}SubtaskTimeInput${j}`] = latestOfferScope[i].subtasks[j].time.toString();
            loadedValues[`scopeTask${i}SubtaskTimeInput${j}`] = latestOfferScope[i].subtasks[j].time.toString();
          }
          if(latestOfferScope[i].subtasks[j].price && latestOfferScope[i].subtasks[j].price > 0) {
            if(readerRole === 'expert' && negotiationType === 'service') {
              initialValues[`scopeTask${i}SubtaskPriceInput${j}`] = new Money(withoutCommission(latestOfferScope[i].subtasks[j].price)*100, config.currency);
              loadedValues[`scopeTask${i}SubtaskPriceInput${j}`] = new Money(withoutCommission(latestOfferScope[i].subtasks[j].price)*100, config.currency);
            } else if(readerRole === 'client' && !currentCustomerIsAdmin && negotiationType !== 'service') {
              initialValues[`scopeTask${i}SubtaskPriceInput${j}`] = new Money(withCommission(latestOfferScope[i].subtasks[j].price)*100, config.currency);
              loadedValues[`scopeTask${i}SubtaskPriceInput${j}`] = new Money(withCommission(latestOfferScope[i].subtasks[j].price)*100, config.currency);
            } else {
              initialValues[`scopeTask${i}SubtaskPriceInput${j}`] = new Money(latestOfferScope[i].subtasks[j].price*100, config.currency);
              loadedValues[`scopeTask${i}SubtaskPriceInput${j}`] = new Money(latestOfferScope[i].subtasks[j].price*100, config.currency);
            }
          }
        }
      }
    }
    setCurrencyInputEstimatedPriceWatcher(value => value+1);
    setInitialValues(initialValues);
    setLoadedValues(loadedValues);
    setIsScopeLoaded(true);
  }

  return (
    <FinalForm
      {...props}
      initialValues={initialValues}
      render={formRenderProps => {
        const {
          className,
          handleSubmit,
          intl,
          values,
          invalid,
          submitFailed,
          submitSucceeded,
        } = formRenderProps;

        const classes = classNames(css.root, className);

        const calculateSumTimeValue = (values) => {
          const timeEntries = Object.entries(values).filter((entryArray) => {
            return entryArray[0].includes('scopeTaskTimeInput');
          });
          const sumTime = timeEntries && timeEntries.length > 0 ? timeEntries.reduce((acc, item) => {
            return item[1] ? acc+(parseInt(item[1])) : acc;
          }, 0) : null;
          return sumTime;
        }

        const calculateSumPriceValue = (values) => {
          const priceEntries = Object.entries(values).filter((entryArray) => {
            return entryArray[0].includes('scopeTaskPriceInput');
          });
          const sumPrice = priceEntries && priceEntries.length > 0 ? priceEntries.reduce((acc, item) => {
            return item[1]?.amount ? acc+(parseInt(item[1]?.amount)) : acc;
          }, 0) : null;
          return sumPrice;
        }

        // eslint-disable-next-line no-unused-vars
        const calculateFinalPrice = (price) => {
          let priceWithCommission = price?.amount / (1 - commission);
          let totalPrice = !isNaN(price?.amount) ? Math.ceil(priceWithCommission/100)*100 : 0;
          return totalPrice;
        }

        let estimatedTimeRequired = required(
          intl.formatMessage({
            id: 'EditListingDescriptionForm.estimatedTimeRequired',
          })
        );
        let estimatedPriceRequired = required(
          intl.formatMessage({
            id: 'NegotiationForm.estimatedPriceRequired',
          })
        );

        const valueNotNegative = nonNegativeNumber(
          intl.formatMessage({
            id: 'CustomOfferScopeInput.valueNotNegative',
          })
        );

        const sumMinTimeValue = calculateSumTimeValue(values);
        const suggestedEstimatedTime = sumMinTimeValue > 0 ? sumMinTimeValue?.toString() : null; 
        const estimatedTimeMinValue = numberValueAtLeast(
          intl.formatMessage(
            { id: 'NewOfferForm.minimumTimeValue' },
            { min: sumMinTimeValue }
          ),
          sumMinTimeValue
        );

        const sumMinPriceValue = calculateSumPriceValue(values);
        const suggestedPriceValue = sumMinPriceValue > 0 ? sumMinPriceValue : undefined;

        // const jobBudgetAmount = props.jobListingBudget?.amount;
        // const currentPriceAmount = calculateFinalPrice(values['estimatedPriceSummary']);
        // const priceAboveAmount = currentPriceAmount - jobBudgetAmount;
        // const isPriceAboveJobBudget = priceAboveAmount > 0;

        const estimatedPriceSummaryTitle = negotiationType === 'offer' ? 'Final price for the service: '
                                            : readerRole === 'expert' ? 'Your price for the service: ' : 'Final price for the service: ';
        const formTitle = adminEditMode ? 'Edit offer' : 'Send counteroffer';

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {disabled ? (
              <p className={css.error}>
                <FormattedMessage id="NewOfferForm.jobIsClosed" />
              </p>
            ) : null}
            <>
              <h2 className={css.scopeTitle}>
                {formTitle}
              </h2>
              <div className={css.scopeCommentWrapper}>
                <span className={css.scopeCommentText}>Loaded values presents most recent offer state (last offer).</span>
              </div>
              { currentUserHasHourlyRate && 
                <div className={css.scopeCommentWrapper}>
                  <span className={css.scopeCommentText}>If you specify hours related to each task your price will be automatically calculated based on your hourly rate.<br />
                  <strong>Your current hourly rate is:</strong> {formatMoney(intl, new Money(currentUserHourlyRate ? currentUserHourlyRate : 0, config.currency))}. 
                  <a href={currentUserHourlyRateLink}> You can modify it here.</a></span>
                </div>
              }
              <CustomOfferScopeInput 
                intl={intl} 
                values={values}
                currentUserHasHourlyRate={currentUserHasHourlyRate}             
                currentUserHourlyRate={currentUserHourlyRate}
                disabled={disabled}
                scopeType={'offer'}
                loadedScope={latestOfferScope}
                isScopeLoaded={isScopeLoaded}
                loadedValues={loadedValues}
              />
              <>
                <h2 className={css.servicePriceTitle}>
                  {estimatedPriceSummaryTitle}
                </h2>
                {(negotiationType === 'service' && readerRole === 'expert') &&
                  <p className={classNames(css.smallPrint, css.smallPrintLeft, css.subtitlePrice)}>
                    <span>All SoftwareSupport projects are settled hourly and you always receive the full amount of your price.</span> {priceTooltip}
                  </p>
                }
                <FieldCurrencyInput
                  id="estimatedPriceSummary"
                  name="estimatedPriceSummary"
                  className={css.title}
                  currencyConfig={config.currencyConfig}
                  disabled={disabled}
                  suggestPriceValue={suggestedPriceValue}
                  validate={!adminEditMode ? composeValidators(estimatedPriceRequired) : null}
                  loadable // custom flag to update price when loaded from outside
                  loadedValue={loadedValues[`estimatedPriceSummary`]}
                  loadedWatcher={currencyInputEstimatedPriceWatcher}
                />
              </>
              { (negotiationType === 'service' && readerRole === 'expert') && 
                <>
                  <h2 className={css.serviceFinalPriceTitle}>
                    Final price offered to customer:
                  </h2>
                  <span className={css.servicePriceValue}>
                    { formatMoney(intl, new Money(calculateFinalPrice(values['estimatedPriceSummary']), config.currency)) }
                  </span>
                  <p className={classNames(css.smallPrint, css.smallPrintLeft)}>
                    <FormattedMessage id="NewOfferForm.offerPriceDescription" />
                    <Tooltip interactive title={<FormattedMessage id={`NewOfferForm.offerPriceTooltip`} values = {{ br: <br/>}}/>} >
                      <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }}/>
                    </Tooltip>
                  </p>
                </>
              }
            </>
            {adminEditMode ? 
              <SideValidatedFieldTextInput
                id="estimatedTime"
                name="estimatedTime"
                type="number"
                min={0}
                className={css.estimatedTimeInput}
                label={intl.formatMessage({ id: 'NewOfferForm.estimatedTimeLabel' })}
                placeholder={intl.formatMessage({ id: 'NewOfferForm.estimatedTimePlaceholder' })}
                disabled={disabled}
                triggerTrackValue={values.estimatedTime}
                triggerFieldValue={values.estimatedTime}
                defaultValue={values.estimatedTime}
              />
            :
              <SideValidatedFieldTextInput
                id="estimatedTime"
                name="estimatedTime"
                type="number"
                min={0}
                className={css.estimatedTimeInput}
                label={intl.formatMessage({ id: 'NewOfferForm.estimatedTimeLabel' })}
                placeholder={intl.formatMessage({ id: 'NewOfferForm.estimatedTimePlaceholder' })}
                validate={readerRole === 'client' || negotiationType === 'service' ? composeValidators(estimatedTimeRequired, valueNotNegative) : composeValidators(estimatedTimeRequired, valueNotNegative, estimatedTimeMinValue)}
                triggerTrackValue={sumMinTimeValue}
                triggerFieldValue={suggestedEstimatedTime}
                disabled={disabled}
                defaultValue={values.estimatedTime}
              />
            }
            {((submitFailed || submitSucceeded) && (invalid || formMinimumPriceNotReached)) && 
              <span className={css.serviceError}>
                <FormattedMessage id="NewOfferForm.formInvalid" />
              </span>
            }
            <Button
              className={css.submitButton}
              type="submit"
              disabled={disabled}
            >
              {sendOfferTooltip}
            </Button>
          </Form>
        );
      }}
    />
  );
}

NegotiateOfferFormComponent.defaultProps = { 
    className: null,
};

NegotiateOfferFormComponent.propTypes = {
    className: string,
    onSubmit: func.isRequired,
    intl: intlShape.isRequired,
};

export default compose(injectIntl)(NegotiateOfferFormComponent);
// based on EditListingPricingForm