/* eslint-disable */
/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes, LISTING_CATEGORY_EXPERT } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { createSlug, LISTING_PAGE_PARAM_TYPE_EDIT } from '../../util/urlHelpers';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { Container, Row, Col } from 'react-bootstrap';
import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  PrimaryButton,
} from '../../components';

import css from './TopbarMobileMenu.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserExpertListing,
    currentUser,
    shouldDisplayNotificationDot,
    onLogout,
    userHasBalance,
    history
  } = props;

  const [browseVisible, setBrowseVisible] = useState(false);
  const user = ensureCurrentUser(currentUser);

  const expertListingId = currentUserExpertListing ? currentUserExpertListing.id.uuid : null;
  const expertListingSlug = currentUserExpertListing
    ? createSlug(currentUserExpertListing.attributes.title)
    : 'No Expert Listing';

  const browseShow = (
    <div className={css.aboutShow} onClick={() => setBrowseVisible(!browseVisible)}>
      <FormattedMessage id="TopbarMobile.browse" />
      <FaChevronDown className={css.icon} size={'0.5em'} />
    </div>
  );

  const jobs = (
    <NamedLink name="SearchPage_jobs" className={css.navigationLink}>
      <FormattedMessage id="TopbarMobileMenu.job" />
    </NamedLink>
  );

  const experts = (
    <NamedLink name="SearchPage_experts" className={css.navigationLink}>
      <FormattedMessage id="TopbarMobileMenu.expert" />
    </NamedLink>
  );

  const softwaresuppServices = (
    <NamedLink name="SearchPage_services" className={css.navigationLinkSmall}>
      <FormattedMessage id="TopbarMobileMenu.services" />
    </NamedLink>
  );
  const userIsAdmin = currentUser?.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

  const browseLinks = (
    <>
      {userIsAdmin ||
      currentUser?.attributes.profile.publicData?.isPremiumUser ||
      (currentUserHasExpertListing)
        ? jobs
        : null}
      {userIsAdmin || currentUser?.attributes.profile.publicData?.isPremiumUser ? experts : null}
      {/* {softwaresuppServices} */}
    </>
  );

  const pricingLogged = (
    <NamedLink className={css.navigationLink} name="SubscriptionPage">
      <FormattedMessage id="TopbarMobile.pricing" />
    </NamedLink>
  );

  const pricingUnlogged = (
    <a href="https://softwaresupp.com/pricing" className={css.navigationLink}>
      <FormattedMessage id="TopbarDesktop.pricing" />
    </a>
  );

  const bookCall = () => {
    const { history } = props;
    history.push(
      createResourceLocatorString(
        'ExpertPage',
        routeConfiguration(),
        {
          id: process.env.REACT_APP_ADMIN_BOOKING_LISTING_ID,
          slug: createSlug('Michael'),
          category: 'experts',
        },
        {}
      ),
      { openBookModal: true }
    );
  };

  const addService =
    currentUserHasExpertListing || currentUserHasUnpublishedExpertListing ? (
      <NamedLink className={css.navigationLink} name="NewServicePage">
        <span className={css.navigationLink}>
          <FormattedMessage id="TopbarDesktop.createService" />
        </span>
      </NamedLink>
    ) : null;

  const addExpert = (
    <NamedLink name="NewListingExpertPage" className={css.navigationLink}>
      <FormattedMessage id="TopbarMobileMenu.newListingLinkExpert" />
    </NamedLink>
  );

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    const listingTypes = (
      <span className={css.browseLinks}>
        <FormattedMessage
          id="TopbarMobileMenu.listingTypes"
          values={{ services: softwaresuppServices, br: <br /> }}
        />
      </span>
    );
    

    const bookLink = !isAuthenticated ? (
      
         <a
        className={classNames(css.navigationLink)}
        onClick={() => {
          window.open('https://calendly.com/softwaresupp/software-consultation', '_blank');
        }}
      >
        <FormattedMessage id="TopbarDesktop.book" />
      </a>
  ) : (
  <span onClick={() => bookCall()} className={css.navigationLink}>
            <FormattedMessage id="TopbarDesktop.book" />
          </span>

  );


    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>

          {pricingLogged}

          {bookLink}        

          
          {addExpert}
         
        </div>
        <div className={css.footer}>
          <div name="NewListingPage" className={css.footerButton}>
            <PrimaryButton onClick={() => {
          window.open('https://calendly.com/softwaresupp/software-consultation', '_blank');
        }}>
              Hire Talent
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }

  const notificationCountBadge = shouldDisplayNotificationDot ? (
    <NotificationBadge className={css.notificationBadge} />
  ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenuExpertListingLink =
    !currentUserHasExpertListing && !currentUserHasUnpublishedExpertListing ? (
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('NewListingExpertPage'))}
        name="NewListingExpertPage"
      >
        <FormattedMessage id="TopbarMobileMenu.newListingLinkExpert" />
      </NamedLink>
    ) : (
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('NewListingExpertPage'))}
        name="EditListingPage"
        params={{
          id: expertListingId,
          slug: expertListingSlug,
          type: LISTING_PAGE_PARAM_TYPE_EDIT,
          tab: 'description',
          category: LISTING_CATEGORY_EXPERT,
        }}
        category={LISTING_CATEGORY_EXPERT}
      >
        <FormattedMessage id="TopbarMobileMenu.editListingLinkExpert" />
      </NamedLink>
    );

  const currentUserHasStripeAccounts =
    currentUser?.attributes.profile.privateData.stripeAccount || currentUser?.stripeCustomer;

  const billing_page = currentUserHasStripeAccounts ? (
    <NamedLink
      className={classNames(css.navigationLink)}
      name="BillingPage"
      params={{ tab: 'billingPage' }}
    >
      <FormattedMessage id="BillingPage.title" />
    </NamedLink>
  ) : null;
  return (
    <div className={css.root}>
      <Container className={css.main_container}>
        <Row>
          <Col xs={4}>
            <AvatarLarge className={css.avatar} user={currentUser} disableProfileLink />
          </Col>
          <Col xs={8}>
            <div className={css.greeting}>
              <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
            </div>
            <div>
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
          <ul className={css.ListMobileMenu}>
            <li>
              <NamedLink className={classNames(css.center, currentPageClass('CenterPage'))}
              name="NotificationsPage">
              <FormattedMessage id="TopbarMobileMenu.centerLink" />
              {notificationCountBadge}
              </NamedLink>
            </li>
            
            {userIsAdmin ||
        currentUser?.attributes.profile.publicData?.isPremiumUser ||
        currentUserHasExpertListing
          ? <li>{jobs}</li> : null}
            
        {userIsAdmin || currentUser?.attributes.profile.publicData?.isPremiumUser ? <li>experts</li>: null}
              <li>
                {billing_page}
              </li>
            <li>
              {pricingLogged}
            </li>
            <li>
              <NamedLink className={css.navigationLink} name="ReferralSystemPage">
                <FormattedMessage id="TopbarMobileMenu.referral" />
              </NamedLink>
            </li>
            <li>
              <NamedLink
                className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
                name="ProfileSettingsPage"
                >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
            </li>
            <li>
            <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        </li>
        <li>
        {profileMenuExpertListingLink}
        </li>
          </ul>
                   </Col>
        </Row>
      </Container>
      <div className={css.content}>
      </div>
      <div className={css.footer}>
        <NamedLink name="NewListingPage" className={css.footerButton}>
          <PrimaryButton>
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </PrimaryButton>
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
