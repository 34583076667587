import React from 'react';
import { RiSafe2Line } from 'react-icons/ri';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Modal, NamedLink, PrimaryButton } from '../../components';

import css from './DepositModal.css';

const DepositModal = props => {
  const {
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    alreadyBought,
    listingId
  } = props;

  return (
    <Modal
      id={id}
      containerClassName={css.root}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <h1 className={css.modalTitle}>
        <FormattedMessage id="DepositModal.title" />
      </h1>
      <p className={css.description}>
        <FormattedMessage id={alreadyBought ? "DepositModal.alreadyBoughtInfo" : "DepositModal.info"} />
      </p>
      <NamedLink
        name="SubscriptionPage"
        className={css.buttonLink}
        to={{search: `depositListingId=${listingId}`}}
      >
        <PrimaryButton disabled={alreadyBought}>
          <RiSafe2Line className={css.buttonIcon} />
          <FormattedMessage id="DepositModal.buttonText" />
        </PrimaryButton>
      </NamedLink>

    </Modal>
  );
};

export default injectIntl(DepositModal);
