import React, { useState, useEffect, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { readExamListing } from './ExamPage.duck';
import {
  Sidebar,
  Page,
  PrimaryButton,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  NamedLink,
  Footer,
} from '../../components';
import { MdTimelapse } from 'react-icons/md';
import { GoDashboard } from 'react-icons/go';
import { GiTargeted } from 'react-icons/gi';
import { TopbarContainer, AuthenticationPage } from '../../containers';
import { examSaveAttempt, examSaveResult } from '../../util/api';
import ExamStartInfo from './ExamStartInfo/ExamStartInfo';
import ExamQuestion from './ExamQuestion/ExamQuestion';
import css from './ExamPage.css';

const convertTimeToSeconds = (time) => {
  if(!(time > 0)) {
    return 0;
  }
  let examTime = time;
  if(examTime < 60) {
    examTime = examTime * 60;
  } else if(examTime >= 60) {
    examTime = (Math.floor(examTime/60))*60*60 + (examTime%60)*60;
  }
  return examTime;
}

const formatToHHMMSS = (timeInSeconds) => {
  if(!(timeInSeconds > 0)) {
    return '00:00';
  }
  return new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
}

const areEqual = (arr1, arr2) => {
  if(arr1.length !== arr2.length) {
    return false;
  }
  for(let i = 0; i < arr1.length; i++) {
    if(arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

const calcSummaryResults = (exam, userAnswers) => {
  const totalQuestions = exam?.attributes.publicData.questions.length;
  let result = 0;
  for(let i = 0; i < userAnswers.length; i++) {
    if(areEqual(exam?.attributes.publicData.questions[i].correctAnswers, userAnswers[i])) {
      result += 1;
    }
  }
  return Math.floor((result/totalQuestions)*100);
}


export const ExamPageComponent = props => {

  const {
    params,
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled,
    onReadExam,
    onRefreshUser
  } = props;
 
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isExamStarted, setIsExamStarted] = useState(false);
  const [isExamSummary, setIsExamSummary] = useState(false);
  const [isExamFinished, setIsExamFinished] = useState(false);
  const [examLoadError, setExamLoadError] = useState(false);
  const [loadingExamInProgress, setLoadingExamInProgress] = useState(true);
  const [loadedExam, setLoadedExam] = useState();
  const [examTimeLeft, setExamTimeLeft] = useState();
  const [questionNumber, setQuestionNumber] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [timeLeftOnFinish, setTimeLeftOnFinish] = useState('');
  const [examResult, setExamResult] = useState();
  const [isExamPassed, setIsExamPassed] = useState(false);
  const [intervalRef, setIntervalRef] = useState();

  useEffect(() => {
    if (!params?.id) {
      setExamLoadError(true);
      return;
    }
    onRefreshUser();
    loadCurrentExam(params?.id, onReadExam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, onReadExam]);

  const pageSafetySet = () => {
    if(typeof window !== undefined) {
      window.onbeforeunload = (event) => {
          const e = event || window.event;
          e.preventDefault();
          if (e) {
            e.returnValue = ''
          }
          return '';
      };
    }
  }

  const pageSafetyUnset = () => {
    if(typeof window !== undefined) {
      window.onbeforeunload = null;
    }
  }

  useEffect(() => {
    let timerInterval;
    if(isExamStarted) {
      pageSafetySet();
      timerInterval = setInterval(() => {
        setExamTimeLeft(time => {
          if(time > 0) {
            return time-1;
          } else if(time === 0) {
            setIsExamFinished(true);
            clearInterval(timerInterval);
            return 0;
          }
        });
      }, 1000);
      setIntervalRef(timerInterval);
    }
    return () => {
      clearInterval(timerInterval);
      pageSafetyUnset();
    };
  }, [isExamStarted]);

  useEffect(() => {
    if(isExamFinished) {
      onExamFinished(examTimeLeft, questionNumber, loadedExam, currentUser, userAnswers);
      pageSafetyUnset();
    }
  }, [isExamFinished, examTimeLeft, questionNumber, loadedExam, currentUser, userAnswers]);

  const onExamFinished = async (savedTimeLeft, savedQuestionNumber, loadedExam, currentUser, userAnswers) => {
    if(!loadedExam || !currentUser) {
      return;
    }
    const summaryResult = calcSummaryResults(loadedExam, userAnswers);
    setExamResult(summaryResult);
    const examPassStatus = !!(summaryResult >= loadedExam.attributes.publicData.config.passThreshold);
    setIsExamPassed(examPassStatus);
    setTimeLeftOnFinish(savedTimeLeft);
    const lastQuestionNumber = (savedQuestionNumber+1)+'/'+(loadedExam.attributes.publicData.questions?.length);
    await examSaveResult({
      userId: currentUser.id.uuid,
      examId: loadedExam.id.uuid,
      certificationName: loadedExam.attributes.publicData.config.certificationName,
      result: summaryResult,
      threshold: loadedExam.attributes.publicData.config.passThreshold,
      isPassed: examPassStatus,
      timeLeft: formatToHHMMSS(savedTimeLeft),
      lastQuestion: lastQuestionNumber,
      date: (new Date()).toUTCString()
    });
    setIsExamSummary(true);
  }

  const loadCurrentExam = async (examId, onReadExam) => {
    setLoadingExamInProgress(true);
    const { data } = await onReadExam(examId);
    if(!!data) {
      setLoadedExam(data);
      setLoadingExamInProgress(false);
    } else {
      setExamLoadError(true);
    }
  }

  const onStartExam = async () => {
    if(!loadedExam || !currentUser) {
      return;
    }
    // set exam timer
    setExamTimeLeft(convertTimeToSeconds(loadedExam.attributes.publicData.config.examTime));
    // send data to integration api
    const saveAttemptResult = await examSaveAttempt({
      userId: currentUser.id.uuid,
      examId: loadedExam.id.uuid
    });
    // start with exam questions
    if(saveAttemptResult?.success) {
      setIsExamStarted(true);
    }
  }

  const onNextQuestion = useCallback((answers) => {
    // save answers per question
    setUserAnswers(userAnswers => {
      userAnswers[questionNumber] = answers;
      return userAnswers;
    });
    const isLastQuestion = !!(loadedExam?.attributes.publicData.questions?.length === questionNumber+1);
    if(isLastQuestion) {
      // finish exam
      setIsExamFinished(true);
      clearInterval(intervalRef);
      return;
    }
    // go to next question
    setQuestionNumber(number => number+1);
  },[loadedExam, questionNumber, intervalRef]);

  if(examLoadError) {
    return <AuthenticationPage />;
  }

  const title = `Exams`;
  const isAdmin = currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="ExamPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}>
          <Sidebar 
            tab={'examPage'}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
        {/* {error} */}
        <div className={css.mainWrapper}>
          {loadingExamInProgress && 
            <div>
              {'Exam is loading...'}
            </div>
          }
          {(!isExamStarted && !loadingExamInProgress) && 
            <ExamStartInfo 
              loadedExam={loadedExam}
              currentUser={currentUser}
              onStartExam={onStartExam} />
          }
          {(isExamStarted && !isExamSummary) &&
            <>
              <div className={css.examHeader}>
                <div className={css.examTitleWrapper}>
                  <div className={css.examTitle}>
                    {loadedExam?.attributes.title}
                  </div>
                  <div className={css.examQuestionCounter}>
                    {'Question: '+(questionNumber+1)+'/'+(loadedExam?.attributes.publicData.questions?.length)}
                  </div>
                </div>
                <div className={css.examTimer}>
                  <div>
                    {'Time left'}
                  </div>
                  <div>
                    {formatToHHMMSS(examTimeLeft)}
                  </div>
                </div>
              </div>
              <div className={css.examMain}>
                <ExamQuestion 
                  questionNumber={questionNumber}
                  data={loadedExam?.attributes.publicData.questions[questionNumber]} 
                  isLast={!!(loadedExam?.attributes.publicData.questions?.length === questionNumber+1)} 
                  onNextQuestion={onNextQuestion}
                />
              </div>
            </>
          }
          {(isExamStarted && isExamSummary) &&
            <>
              <div className={css.summaryTextTitle}>
                {'Exam Summary'}
              </div>
              <div>
                {(timeLeftOnFinish === 0) &&
                  <p className={css.summaryTextRegular}>
                    Your exam was closed automatically (time limit exceeded).
                  </p>
                }
                <p className={css.summaryTextRegular}>
                  <MdTimelapse />{'\u00A0'}{'\u00A0'}
                  Time left: {formatToHHMMSS(timeLeftOnFinish)}
                </p>
                <p className={css.summaryTextRegular}>
                  <GoDashboard />{'\u00A0'}{'\u00A0'}
                  Your result: {examResult+'%'}
                </p>
                <p className={css.summaryTextRegular}>
                  <GiTargeted />{'\u00A0'}{'\u00A0'}
                  Threshold to pass the exam: {loadedExam?.attributes.publicData.config.passThreshold+'%'}
                </p>
                <br />
                {isExamPassed ?
                  <p className={css.summaryTextBold}>Congratulations! You passed the exam.</p>
                  :
                  <p className={css.summaryTextBold}>You did not pass the exam.</p>
                }
                <div className={css.buttonWrapper}>
                  <NamedLink name={"ExamsListPage"}>
                    <PrimaryButton className={css.createButton}>
                      <FormattedMessage id="ExamPage.goBackButton" />
                    </PrimaryButton>
                  </NamedLink>
                </div>
              </div>

            </>
          }
        </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;
  return {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onReadExam: (examId) => dispatch(readExamListing(examId)),
  onRefreshUser: () => dispatch(fetchCurrentUser()),
});

const ExamPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ExamPageComponent);

export default ExamPage;