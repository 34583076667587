import React, {useState, useEffect} from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { TiTick } from 'react-icons/ti';
import { Form, SecondaryButton } from '../../components';

import css from './SubscriptionForm.css';

const capitalize = word => {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const SubscriptionForm = props => {
  
  const [, setTime] = useState(new Date());

  useEffect(() => {
    let timerID = setInterval( () => setTime(new Date()), 1000 );
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  return (
        <FinalForm
        {...props}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            handleSubmit,
            subscriptionPlan,
            setVersion,
          } = formRenderProps;

          const freeTrial = subscriptionPlan?.isTrial;
          const subscriptionVersion = subscriptionPlan?.version;
          const subscriptionConfirmed = subscriptionPlan?.confirmed;
          const subscriptionVersionCapitalized = subscriptionVersion ? capitalize(subscriptionVersion) : null;
          const calculateTimeLeft = () => {
            let difference = Date.parse(subscriptionPlan.end) - +new Date();
            let timeLeft = {};
            if (difference > 0) {
              timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
              }
            }
            if (difference > 0) {
              return `Your free trial ends in ${timeLeft.days} days ${timeLeft.hours} h ${timeLeft.minutes} min ${timeLeft.seconds} s`;
            }
            else {
              return 'Your trial has expired. Buy Support Plan now!'
          }
          }

          const basicFeatures = [
            'Dedicated support expert',
            '10h of software support',
            'Upfront estimate and approval for the tasks to be performed',
            '24h response time',
            '48h fix time',
            'Individual support',
          ];

          const basicFeaturesMore = [
            'Remote sessions with your dedicated support expert',
            'Free estimate for larger development projects',
          ];

          const basicVersionFeatures = basicFeatures.map((feature, index) => {
            return <span key={`basicFeature${index}`} className={css.features}><TiTick className={css.tickIcon}/> {feature}</span>
          });

          const basicVersionFeaturesMore = basicFeaturesMore.map((feature, index) => {
            return <span key={`basicFeature${index}`} className={css.features}><TiTick className={css.tickIcon}/> {feature}</span>
          });

          const fullFeatures = [
            'Dedicated support expert',
            '20h of software support',
            'Upfront estimate and approval for the tasks to be performed',
            '1h response time',
            '24h fix time',
            'Individual support',
          ];

          const fullFeaturesMore = [
            'Remote sessions with your dedicated support expert',
            'Free estimate for larger development projects',
            'Training/onboarding',
            'Team support',
            'Up to 10 team members',
          ];

          const fullVersionFeatures = fullFeatures.map((feature, index) => {
            return <span key={`fullFeature${index}`}  className={css.features}><TiTick className={css.tickIcon}/> {feature}</span>
          });

          const fullVersionFeaturesMore = fullFeaturesMore.map((feature, index) => {
            return <span key={`fullFeature${index}`}  className={css.features}><TiTick className={css.tickIcon}/> {feature}</span>
          });

          const ticketsFeature = (
            <span className={css.features}>
              <TiTick className={css.tickIcon}/> 
              <div>
                Task and meeting system, including:<br/>
                • task prioritization<br/>
                • task collaboration<br/>
                • time to delivery<br/>
                + more<br/>
              </div>
            </span>
          );

          const integrationFeature = (
            <span className={css.features}>
              <TiTick className={css.tickIcon}/> Integration and setup support:<br/>
              • software configuration<br/>
              • software integration<br/>
              • software support<br/>
              + more<br/>
            </span>
          );

          return (
            <Form className={css.form} onSubmit={handleSubmit}>
                {freeTrial ?
                <p className={css.freeTrialEnd}>{calculateTimeLeft()}</p> 
                : null }
                {!subscriptionConfirmed ? 
                  <p className={css.freeTrialEnd}>Your payment is waiting for SoftwareSupp confirmation</p> 
                  : null}
                {((subscriptionVersion === 'basic' || subscriptionVersion === 'support') && subscriptionConfirmed) ?
                <h2 className={css.fullVersionActive}>You have active {subscriptionVersionCapitalized} subscription</h2>
                : null} 
                <div className={css.radioButtonsContainer}>
                  
                  <div className={css.fieldContainer}>
                    <div className={css.pricingAccentRed}/>
                    <div>
                      <p className={css.versionTitle}>Basic</p>
                      <p className={css.priceValue}>$500</p>
                      <p className={css.priceText}>for 10h of support</p>
                      {basicVersionFeatures}
                      {ticketsFeature}
                      {integrationFeature}
                      {basicVersionFeaturesMore}
                    </div>
                    <SecondaryButton
                      onClick={() => setVersion('basic')}
                      className={css.submitButton}
                      name="version"
                      value="basic"
                      type="submit">
                        Get started $500
                    </SecondaryButton>
                  </div>

                  <div className={css.fieldContainer} >
                    <div className={css.pricingAccentBlue}/>
                    <div>
                      <p className={css.versionTitle}>Support</p>
                      <p className={css.priceValue}>$1000</p>
                      <p className={css.priceText}>for 20h of support</p>
                      {fullVersionFeatures}
                      {ticketsFeature}
                      {integrationFeature}
                      {fullVersionFeaturesMore}
                    </div>
                    <SecondaryButton
                      onClick={() => setVersion('support')}
                      className={css.submitButton}
                      name="version"
                      value="support"
                      type="submit">
                        Get started $1000
                    </SecondaryButton>
                  </div>
                </div>
                <a href="/software-support" className={css.readMoreLink}>Read more about SoftwareSuppp Support Plans</a>
            </Form>
          );
        }}
      />);
}

export default SubscriptionForm
