import React from 'react';
import {
  AuthenticationPage,
  AuthenticationPageAutoLogin,
  AdminBulkMessagePage,
  ContactDetailsPage,
  ConversationPage,
  EditExpertPage,
  EditProjectPage,
  EditServicePage,
  EmailVerificationPage,
  ManageListingsPage,
  ReferralSystemPage,
  CenterPage,
  IFramePage,
  IFrameServicePage,
  ListingPage,
  ExpertPage,
  ExpertPageNew,
  ExamPage,
  ExamSuggestQuestionPage,
  ExamsAdminPage,
  ExamsListPage,
  ServicePage,
  NewOfferPage,
  NotFoundPage,
  MessagesPage,
  NotificationsPage,
  PasswordChangePage,
  PasswordRecoveryPage,
  PasswordResetPage,
  StripePayoutPage,
  PaymentMethodsPage,
  GenerateAPIKeyPage,
  PrivacyPolicyPage,
  ProfilePage,
  ProfileSettingsPage,
  SearchPage,
  SearchPageFrame,
  SearchPageServiceFrame,
  StyleguidePage,
  SubscriptionPage,
  StartProjectPage,
  TermsOfServicePage,
  TransactionPage,
  NPSSurveyPage,
  ProjectBoardPage,
  ProductItemsApprovalPage,
  SubscriptionSettingsPage,
  BillingPage,
  BillPage,
  ExpertListPage,
  JobsListPage,
  NewOfferCvPage,
  InfoToFormPage,
} from './containers';

import {
  LISTING_CATEGORY_EXPERT,
  LISTING_CATEGORY_SERVICE,
  LISTING_CATEGORY_TICKET,
} from './util/types';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect, LightboxWrapper } from './components';

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'GenerateAPIKeyPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="SearchPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.

const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'SearchPage',
      component: props => <StartProjectPage {...props} />,
      loadData: StartProjectPage.loadData,
    },
    {
      path: '/app',
      name: 'SearchPage',
      component: props => <StartProjectPage {...props} />,
      loadData: StartProjectPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/adminBulkMessage',
      name: 'AdminBulkMessagePage',
      auth: true,
      authPage: 'SignupPage',
      component: props => <AdminBulkMessagePage {...props} />,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order-details/:id',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/view-details/:id',
      name: 'ViewDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="viewer" />,
    },
    {
      path: '/order/:id/details',
      name: 'OldOrderDetailsPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale-details/:id',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/sale/:id/details',
      name: 'OldSaleDetailsPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    // {
    //   path: '/jobs/',
    //   name: 'SearchPage_jobs',
    //   component: props => (
    //     <LightboxWrapper>
    //       <SearchPage {...props} />
    //     </LightboxWrapper>
    //   ),
    //   loadData: SearchPage.loadData,
    // },
    // {
    //   path: '/jobs/:software/',
    //   name: 'SearchPage_jobs_software',
    //   component: props => (
    //     <LightboxWrapper>
    //       <SearchPage {...props} />
    //     </LightboxWrapper>
    //   ),
    //   loadData: SearchPage.loadData,
    // },
    {
      path: '/experts/:slug/:id([0-9a-z.-]{36})',
      name: 'ExpertPage',
      authPage: 'SignupPage',
      component: props => <ExpertPage {...props} />,
      loadData: ExpertPage.loadData,
    },
    {
      path: '/experts/:software/:skill/',
      name: 'expertlist',
      component: props => <ExpertListPage {...props} />,
      // loadData: SearchPage.loadData,
    },
    {
      // must be above software/skill
      path: '/jobs/:slug/:id([0-9a-z.-]{36})',
      name: 'JobPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/jobs/:software/:skill/',
      name: 'SearchPage_jobs_skills',
      component: props => <JobsListPage {...props} />,
      // loadData: SearchPage.loadData,
    },
    {
      path: '/jobs/',
      name: 'SearchPage_jobs',
      component: props => <JobsListPage {...props} />,
      // loadData: SearchPage.loadData,
    },
 {
      path: '/jobs/:software/',
      name: 'SearchPage_jobs_software',
      component: props => <JobsListPage {...props} />,
      // loadData: SearchPage.loadData,
    },   // {
    //   path: '/experts/',
    //   name: 'SearchPage_experts',
    //   component: props => (
    //     <LightboxWrapper>
    //       <SearchPage {...props} />
    //     </LightboxWrapper>
    //   ),
    //   loadData: SearchPage.loadData,
    // },
    {
      path: '/experts/:software/',
      name: 'SearchPage_experts_software',
      component: props => (
        <LightboxWrapper>
          <ExpertListPage {...props} />
        </LightboxWrapper>
      ),
      loadData: SearchPage.loadData,
    },
    {
      path: '/jobInfo/',
      name: 'jobInfo',
      component: props => <InfoToFormPage {...props} />,
      // loadData: SearchPage.loadData,
    },
    // {
    //   path: '/jobs/:software/',
    //   name: 'SearchPage_jobs_software',
    //   component: props => (
    //     <LightboxWrapper>
    //       <SearchPage {...props} />
    //     </LightboxWrapper>
    //   ),
    //   loadData: SearchPage.loadData,
    // },
    {
      path: '/experts/',
      name: 'SearchPage_experts',
      component: props => (
        <LightboxWrapper>
          <ExpertListPage {...props} />
        </LightboxWrapper>
      ),
      // loadData: SearchPage.loadData,
    },
    {
      path: '/services/',
      name: 'SearchPage_services',
      component: props => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/frame/services/:authorid/',
      name: 'SearchPage_frame_services',
      component: props => <SearchPageServiceFrame {...props} />,
      loadData: SearchPageServiceFrame.loadData,
    },
    {
      path: '/:id/nps',
      name: 'NPSSurveyPage',
      component: props => <NPSSurveyPage {...props} />,
      loadData: NPSSurveyPage.loadData,
    },
    {
      path: '/:id/board',
      name: 'ProjectBoardPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProjectBoardPage {...props} />,
      loadData: ProjectBoardPage.loadData,
    },
    {
      path: '/:id/board/:variant',
      name: 'ProjectBoardPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProjectBoardPage {...props} />,
      loadData: ProjectBoardPage.loadData,
    },
    {
      path: '/frame/experts/',
      name: 'SearchPage_frame_experts',
      component: props => <SearchPageFrame {...props} />,
      loadData: SearchPageFrame.loadData,
    },
    {
      path: '/experts/:software/',
      name: 'SearchPage_experts_software',
      component: props => <ExpertListPage {...props} />,
      // loadData: SearchPage.loadData,
    },
    {
      path: '/tickets/:software/',
      name: 'SearchPage_tickets_software',
      component: () => <NamedRedirect name="SearchPage_tickets" />,
    },
    {
      path: '/services/:software/',
      name: 'SearchPage_services_software',
      component: props => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/frame/experts/:software/',
      name: 'SearchPage_frame_experts_software',
      component: props => <SearchPageFrame {...props} />,
      loadData: SearchPageFrame.loadData,
    },
    {
      path: '/s/filters',
      name: 'SearchFiltersPage',
      component: props => (
        <LightboxWrapper>
          <SearchPage {...props} tab="filters" />
        </LightboxWrapper>
      ),
      loadData: SearchPage.loadData,
    },
    {
      path: '/search/listings',
      name: 'SearchListingsPage',
      component: props => <SearchPage {...props} tab="listings" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/search/map',
      name: 'SearchMapPage',
      component: props => (
        <LightboxWrapper>
          <SearchPage {...props} tab="map" />
        </LightboxWrapper>
      ),
      loadData: SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/newOffer/:id',
      name: 'NewOfferPage',
      auth: true,
      component: props => <NewOfferPage {...props} />,
    },

    {
      path: '/listings',
      name: 'ManageListingsBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect name="ManageListingsPage" params={{ tab: 'jobs', page: 1 }} />
      ),
    },
    {
      path: '/listings/:tab/:page',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
    },


    {
      path: '/examsAdmin',
      name: 'ExamsAdminPage',
      auth: true,
      authPage: 'SignupPage',
      component: props => <ExamsAdminPage {...props} />,
    },
    {
      path: '/exams',
      name: 'ExamsListPage',
      auth: true,
      authPage: 'SignupPage',
      component: props => <ExamsListPage {...props} />,
    },
    {
      path: '/exams/suggestQuestion',
      name: 'ExamSuggestQuestionPage',
      auth: true,
      authPage: 'SignupPage',
      component: props => <ExamSuggestQuestionPage {...props} />,
    },
    {
      path: '/exams/:id',
      name: 'ExamPage',
      auth: true,
      authPage: 'SignupPage',
      component: props => <ExamPage {...props} />,
    },
    {
      path: '/itemApprovals',
      name: 'ProductItemsApprovalPage',
      auth: true,
      authPage: 'SignupPage',
      component: props => <ProductItemsApprovalPage {...props} />,
      loadData: ProductItemsApprovalPage.loadData,
    },
    {
      path: '/services/:slug/:id',
      name: 'ServicePage',
      component: props => <ServicePage {...props} />,
      loadData: ServicePage.loadData,
    },
    {
      path: '/:category/:slug/:id/:variant',
      name: 'ListingPageVariant',
      component: props => {
        if (props.params.category === `${LISTING_CATEGORY_TICKET}s`) {
          return (
            <NamedRedirect
              name="ManageListingsPage"
              params={{ tab: 'projects-ongoing', page: 1 }}
            />
          );
        }
        if (props.params.category === `${LISTING_CATEGORY_EXPERT}s`) {
          return <ExpertPage {...props} />;
        } else if (props.params.category === `${LISTING_CATEGORY_SERVICE}s`) {
          return <ServicePage {...props} />;
        }
        return <ListingPage {...props} />;
      },
      auth: true,
      authPage: 'LoginPage',
      loadData: (params, search, pathname) => {
        if (params.category === `${LISTING_CATEGORY_EXPERT}s`) {
          return ExpertPage.loadData(params, search, pathname);
        } else if (params.category === `${LISTING_CATEGORY_SERVICE}s`) {
          return ServicePage.loadData(params, search, pathname);
        }
        return ListingPage.loadData(params, search, pathname);
      },
    },
    {
      path: '/referral',
      name: 'ReferralSystemPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ReferralSystemPage {...props} />,
    },
    {
      path: '/subscribe',
      name: 'SubscriptionPage',
      component: props => <SubscriptionPage {...props} />,
      loadData: SubscriptionPage.loadData,
    },
    // {
    //   path: '/billing2',
    //   name: 'BillingPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => {
    //     return <BillingPage {...props} />;
    //   },
    //   loadData: BillingPage.loadData,
    // },
    {
      path: '/billing',
      name: 'BillingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        return <BillPage {...props} />;
      },
      loadData: BillPage.loadData,
    },
    {
      path: '/l/newExpert',
      name: 'NewListingExpertPage',
      component: () => (
        <NamedRedirect
          name="QuickEditExpertPage"
          params={{
            slug: draftSlug,
            id: draftId,
            type: 'new',
            tab: 'description',
            category: 'expert',
          }}
          category="expert"
        />
      ),
    },
    {
      path: '/l/newTicket',
      name: 'NewTicketPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect
          name="ProjectBoardPage"
          params={{ tab: 'tickets', page: 1 }}
          search={`?edit=true&id=${draftId}`}
        />
      ),
    },
    {
      path: '/l/newService',
      name: 'NewServicePage',
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{
            slug: draftSlug,
            id: draftId,
            type: 'new',
            tab: 'description',
            category: 'service',
          }}
          category="service"
        />
      ),
    },
    {
      path: '/l/newJob',
      name: 'NewListingPage',
      component: () => <NamedRedirect name="NewListingPageDefault" params={{ step: 2 }} />,
    },
    {
      path: '/l/new-project/:step/referral/:referralNumber',
      name: 'NewListingPageReferral',
      component: props => <StartProjectPage {...props} />,
      loadData: StartProjectPage.loadData,
    },
    {
      path: '/l/new-project/:step',
      name: 'NewListingPageDefault',
      component: props => <StartProjectPage {...props} />,
      loadData: StartProjectPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/project',
      name: 'EditProjectPage',
      auth: true,
      component: props => <EditProjectPage {...props} />,
      loadData: EditProjectPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/expert',
      name: 'EditExpertPage',
      auth: true,
      component: props => <EditExpertPage {...props} />,
      loadData: EditExpertPage.loadData,
    },
    {
      path: '/google/redirct_uri',
      name: 'EditExpertPage',
      auth: true,
      component: props => <EditExpertPage {...props} />,
      loadData: EditExpertPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/project/quick',
      name: 'QuickEditProjectPage',
      component: props => <EditProjectPage {...props} />,
      loadData: EditProjectPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/project/quick/:referralNumber',
      name: 'QuickEditProjectPageReferral',
      component: props => <EditProjectPage {...props} />,
      loadData: EditProjectPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/expert/quick',
      name: 'QuickEditExpertPage',
      component: props => <EditExpertPage {...props} quickForm={true}/>,
      loadData: EditExpertPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/expert/quick/:referralNumber',
      name: 'QuickEditExpertPageReferral',
      component: props => <EditExpertPage {...props} quickForm={true} />,
      loadData: EditExpertPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/service',
      name: 'EditServicePage',
      component: props => <EditServicePage {...props} />,
      loadData: EditServicePage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:category',
      name: 'EditListingPage',
      // removing auth to enable EditListingWizard access for non-registered users
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        if (props.params.category === LISTING_CATEGORY_EXPERT) {
          return <EditExpertPage {...props} />;
        } else if (props.params.category === LISTING_CATEGORY_SERVICE) {
          return <EditServicePage {...props} />;
        }
        return <EditProjectPage {...props} />;
      },
      loadData: (params, search, pathname) => {
        if (params.category === LISTING_CATEGORY_EXPERT) {
          return EditExpertPage.loadData(params, search, pathname);
        } else if (params.category === LISTING_CATEGORY_SERVICE) {
          return EditServicePage.loadData(params, search, pathname);
        }
        return EditProjectPage.loadData(params, search, pathname);
      },
    },
    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/:category/:slug/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: props => (
        <LightboxWrapper>
          <AuthenticationPage {...props} tab="login" />
        </LightboxWrapper>
      ),
    },
    {
      path: '/loginAuto',
      name: 'AutoLoginPage',
      component: props => (
        <LightboxWrapper>
          <AuthenticationPageAutoLogin {...props} tab="login" />
        </LightboxWrapper>
      ),
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: props => <AuthenticationPage {...props} tab="confirm" />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: props => (
        <LightboxWrapper>
          <AuthenticationPage {...props} tab="signup" />
        </LightboxWrapper>
      ),
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/inbox',
      name: 'OldInboxBasePage',
      component: () => <NamedRedirect name="CenterPage" params={{ tab: 'orders' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'OldInboxPage',
      component: props => <NamedRedirect name="CenterPage" params={{ ...props.params }} />,
    },
    {
      path: '/center',
      name: 'CenterBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="CenterPage" params={{ tab: 'orders' }} />,
    },
    {
      path: '/center/:tab',
      name: 'CenterPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <CenterPage {...props} />,
      loadData: CenterPage.loadData,
    },

    {
      path: '/center/:tab',
      name: 'TestPage',
      component: props => <CenterPage {...props} />,
      loadData: CenterPage.loadData,
    },

    {
      path: '/conversation/:id',
      name: 'ConversationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ConversationPage {...props} />,
    },
    {
      path: '/groupconversation/:id',
      name: 'GroupConversationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ConversationPage {...props} />,
    },
    {
      path: '/messages',
      name: 'MessagesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <MessagesPage {...props} />,
    },
    {
      path: '/notifications',
      name: 'NotificationsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NotificationsPage {...props} />,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      // Ustawienia > Subskrypcji
      path: '/account/subscription-settings',
      name: 'SubscriptionSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SubscriptionSettingsPage {...props} />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ContactDetailsPage {...props} />,
      loadData: ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PasswordChangePage {...props} />,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-account',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} />,
    },
    {
      path: '/account/generate-api-key',
      name: 'GenerateAPIKeyPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <GenerateAPIKeyPage {...props} />,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: props => <TermsOfServicePage {...props} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },
    {
      path: '/getiframe',
      name: 'iFramePage',
      component: props => <IFramePage {...props} />,
    },
    {
      path: '/getserviceframe',
      name: 'iFrameServicePage',
      component: props => <IFrameServicePage {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },
  ];
};

export default routeConfiguration;
