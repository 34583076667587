/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { func, shape } from 'prop-types';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import UserBalancePanel from './UserBalancePanel';
import UserInvoicesPanel from './UserInvoicesPanel';
import UserOngoingProjectsPanel from './UserOngoingProjectsPanel';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { fetchOngoingProjects, fetchProjects } from './BillingPage.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { ClientPathModal } from '../../components';
import { IconSpinner } from '../../components';

import { fetchUserPayouts } from '../../ducks/stripe.duck';
import { fetchUserInvoices } from '../../ducks/stripe.duck';
import { fetchCurrentUserStripeAccounts } from '../../ducks/user.duck';
import { compareData, createUpdateBody } from '../../util/stripe';
import { Alert } from '@material-ui/lab';
import {
  updateExistingStripeCustomer,
  pay,
  createNewStripeCustomer,
} from '../../ducks/stripe.duck';
import {
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  PaymentModal,
  NamedLink,
  SecondaryButton,
  PrimaryButton,
} from '../../components';
import { TopbarContainer } from '..';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { adjustBalance, stripeInfo } from '../../util/api';
import css from './BillingPage.css';
import PayoutsPanel from './PayoutsPanel';

export const BillingPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    onFetchUserInvoices,
    userInvoices,
    onFetchUserPayouts,
    userPayouts,
    history,

    onFetchCurrentUserStripeAccounts,
    currentUserStripeAccounts,
    onManageDisableScrolling,
    projects,
    projectsInProgress,
    ongoingProjects,
    ongoingProjectsInProgress,
    createStripeCustomerInProgress,
    onFetchOngoingProjects,
    onFetchProjects,
    createStripePaymentInProgress,

  } = props;


 const [paymentUrl, setPaymentUrl] = useState("");
  let [isOpen, setIsOpen] = useState(props.location.state?.payment);
  const [paymentModalOpen, setPaymentModalOpen] = useState(props.location.state?.paymentModalOpen);
  const [unpaidInvoice, setUnpaidInvoice] = useState(props.location.state?.unpaidInvoice);
  const [reload, setReload] = useState(false);
  const [stripeNextInvoiceDate, setstripeNextInvoiceDate] = useState(null)

  useEffect(() => {
    if (
      (currentUser &&
        !ongoingProjectsInProgress &&
        ongoingProjects?.length === 0 &&
        !projectsInProgress &&
        projects?.length === 0) ||
      reload
    ) {
      onFetchOngoingProjects(currentUser);
      onFetchProjects(currentUser);
    }
    if (currentUserStripeAccounts) {
      let ids = currentUserStripeAccounts.map(account => {
        return account.id;
      });
      if (ids.length > 0 && userInvoices === null) {
        onFetchUserInvoices({ customers: ids });
        onFetchUserPayouts({ customers: ids });
      }
    }

  }, [currentUserStripeAccounts, userInvoices, currentUser, reload, userPayouts]);

  useEffect(() => {

    if (currentUser) {
      onFetchCurrentUserStripeAccounts(currentUser);
    }

    if ((currentUser && !ongoingProjectsInProgress && ongoingProjects?.length === 0) || reload) {
      onFetchOngoingProjects(currentUser);
    }
    if (currentUserStripeAccounts) {
      let ids = currentUserStripeAccounts.map(account => {
        return account.id;
      });
      if (ids.length > 0) {
        onFetchUserInvoices({ customers: ids });
        onFetchUserPayouts({ customers: ids });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  useEffect(()=> {

    const fetchStripeData = stripeInfo({userId:currentUser?.id?.uuid})
    
    fetchStripeData.then((res) => {
       console.log(res)
       if(res.nextInvoice.next_payment_attempt){
        let nextInvoiceDate = new Date(res.nextInvoice.next_payment_attempt * 1000).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"}) 
        setstripeNextInvoiceDate(nextInvoiceDate)
       }
    }).catch((error) => {
        // No subscription
    })
 
  },[currentUser])

  const currentUserHasStripeAccounts =
    currentUser?.attributes.profile.privateData.stripeAccount || currentUser?.stripeCustomer;

  const title = intl.formatMessage({ id: 'BillingPage.title' });

  // const link = <a href={'/listings/projects-posted/1'}>link</a>;

  const onSubmitSupportPlan = async values => {
    const { onUpdateStripeCustomer, onPay, onCreateStripeCustomer } = props;

    const { depositBalance } = values;

    let priceAmount =
      values.currency === 'pln'
        ? (depositBalance / config.plnUsdFactor) * 100
        : depositBalance * 100;

    // by default Safari blocks open window requests if there's a long timestamp between user click and window open handler

    if (!createStripeCustomerInProgress && !createStripePaymentInProgress) {
      let stripeAccount = currentUserStripeAccounts ?? null;
      if (stripeAccount && !Array.isArray(stripeAccount)) {
        stripeAccount = [stripeAccount];
      }

      const invoiceData = [
        {
          price: priceAmount,
          projectName: intl.formatMessage({
            id: 'SubscriptionPage.depositInvoiceTitle',
          }),
        },
      ];

      const paymentBody = {
        data: {
          currency: values.currency ?? 'usd',
          invoiceData,
          userId: currentUser.id.uuid,
          addEmail: values.addEmail ?? "",
          isDeposit: true,
        },
      };

      let currentUserMatchingAccount = stripeAccount?.find(
        user => user.currency === (values.currency ?? 'usd') || user.currency === null
      );

      let currentUserUsdAccount = stripeAccount?.find(user => user.currency === 'usd');
      const usdBalance = currentUserUsdAccount?.balance;

      if (currentUserUsdAccount) {
        if (currentUserUsdAccount.balance > 0) {

          await adjustBalance(currentUser.attributes.profile.privateData.inboxToken, {
            amount: -usdBalance,
            currency: 'usd',
            customer: currentUserUsdAccount.id,
          });
        }
      }
      if (currentUserMatchingAccount) {
        if (compareData(currentUserMatchingAccount, currentUser, values)) {
          currentUserMatchingAccount = await onUpdateStripeCustomer({
            customer: currentUserMatchingAccount.id,
            ...createUpdateBody(values, currentUser),
          });
          if (stripeAccount.length > 1) {
            const secondAccount = stripeAccount?.find(
              user => user.currency !== (values.currency ?? 'usd') && user.currency !== null
            );
            await onUpdateStripeCustomer({
              customer: secondAccount.id,
              ...createUpdateBody(values, currentUser),
            });
          }
        }
        paymentBody.data.customer = currentUserMatchingAccount.id;
        paymentBody.data.isPoland = currentUserMatchingAccount.address?.country === 'PL';
        paymentBody.data.previousBalance = currentUserMatchingAccount.balance;
        onPay(paymentBody).then(url => {
          if(url!==undefined){
            window.open(url, '_blanc');
          }
          if (currentUserUsdAccount) {
            if (currentUserUsdAccount.balance > 0) {
              adjustBalance(currentUser.attributes.profile.privateData.inboxToken, {
                amount: usdBalance,
                currency: 'usd',
                customer: currentUserUsdAccount.id,
              });
            }
          }
          setPaymentUrl(url);
        });
      } else {
        const body = createUpdateBody(values, currentUser);
        onCreateStripeCustomer(body, stripeAccount).then(response => {
          paymentBody.data.customer = response.id;
          paymentBody.data.isPoland = response.address?.country === 'PL';
          onPay(paymentBody).then(url => {
            if(url!==undefined){
              window.open(url, '_blanc');
            }
            if (currentUserUsdAccount) {
              if (currentUserUsdAccount.balance > 0) {
                adjustBalance(currentUser.attributes.profile.privateData.inboxToken, {
                  amount: usdBalance,
                  currency: 'usd',
                  customer: currentUserUsdAccount.id,
                });
              }
            }
            setPaymentUrl(url);
          });
        });
      }
    }
  };

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutWrapperTopbar>
        <TopbarContainer currentPage="BillingPage" />
        {/* <UserNav currentUser={currentUser} selectedPageName="BillingPage" /> */}
      </LayoutWrapperTopbar>

      {userInvoices && ongoingProjects && projects && currentUserHasStripeAccounts && userPayouts ? (
        <LayoutWrapperMain className={css.layoutWrapper}>
          <div className={css.mainContainer}>
            <div className={css.leftColumn}>
              <UserBalancePanel
                currentUserHasStripeAccounts={currentUserHasStripeAccounts}
                userStripeAccounts={currentUserStripeAccounts}
                ongoingProjects={ongoingProjects}
              />
              <NamedLink key="subscriptionPageLink" name="SubscriptionPage" params={{}}>
                <PrimaryButton className={css.button}>
                  <FormattedMessage id="BillingPage.getHourlyPlan" />
                </PrimaryButton>
              </NamedLink>
              <SecondaryButton
                onClick={() => setPaymentModalOpen(true)}
                className={css.submitButton}
                name="version"
                value="depositBalance"
                type="submit"
              >
                <FormattedMessage id={'BillingPage.increaseBalance'}/>
              </SecondaryButton>

              {/* Data nastepnej faktury */}
              { stripeNextInvoiceDate && 
                    <Alert severity="info">
                       Next invoice: {stripeNextInvoiceDate}
                    </Alert>
              }       
              
                

              <div className={css.stripeAccountsData}>
                <UserInvoicesPanel userInvoices={userInvoices}/>
                {typeof userPayouts === 'object' && userPayouts.length ? <PayoutsPanel payouts={userPayouts}/> : null}
              </div>
            </div>
            <div className={css.rightColumn}>
              <UserOngoingProjectsPanel
                onFetchCurrentUserStripeAccounts={onFetchCurrentUserStripeAccounts}
                history={history}
                ongoingProjects={ongoingProjects}
                projects={projects}
                reloa={reload}
                increaseBalance={setPaymentModalOpen}
                ongoingProjectsInProgress={ongoingProjectsInProgress}
                onManageDisableScrolling={onManageDisableScrolling}
                currentUser={currentUser}
                setReload={setReload}
                currentUserHasStripeAccounts={currentUserHasStripeAccounts}
                userStripeAccounts={currentUserStripeAccounts}
              />
            </div>
          </div>
          <ClientPathModal
            id="BillingPage.clientPath"
            step={2}
            isOpen={isOpen}
            onCloseModal={() => setIsOpen(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            history={history}
            usePortal
          />

          {/* <Modal
          id="BillingModal"
          isOpen={true}
          onClose={() => setIsOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          {props.location.state?.link ? (
            <FormattedMessage
              id="BillingPage.invoiceGeneratedWithProject"
              values={{ br: <br />, link: link }}
            />
          ) : (
            <FormattedMessage
              id="BillingPage.invoiceGeneratedWithoutProject"
              values={{ br: <br /> }}
            />
          )}
        </Modal> */}
        </LayoutWrapperMain>
      ) : (
        <div className={css.mainSpinner}>
          <IconSpinner className={css.mainSpinner} />
        </div>
      )}

      <PaymentModal
        id="PaymentModal"
        version="depositBalance"
        contentClassName={css.enquiryModalContent}
        isOpen={paymentModalOpen}
        amount={props.location.state?.amount}
        onCloseModal={() => setPaymentModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={values => onSubmitSupportPlan(values)}
        paymentUrl={paymentUrl}
        setPaymentUrl={setPaymentUrl}
        userInvoices={userInvoices}
        unpaidInvoice={unpaidInvoice}
        currentUser={currentUser}
        currentUserStripeAccounts={currentUserStripeAccounts}
        createStripePaymentInProgress={createStripePaymentInProgress}
        createStripeCustomerInProgress={createStripeCustomerInProgress}
      />
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </Page>
  );
};

BillingPageComponent.defaultProps = {
  currentUser: null,
};
BillingPageComponent.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserStripeAccounts } = state.user;
  const { userInvoices, userPayouts } = state.stripe;
  const {
    ongoingProjects,
    ongoingProjectsInProgress,
    projects,
    projectsInProgress,
  } = state.BillingPage;

  const { createStripeCustomerInProgress, createStripePaymentInProgress } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserStripeAccounts,
    userInvoices,
    userPayouts,
    ongoingProjectsInProgress,
    ongoingProjects,
    projectsInProgress,
    projects,
    createStripeCustomerInProgress,
    createStripePaymentInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchCurrentUserStripeAccounts: currentUser =>
    dispatch(fetchCurrentUserStripeAccounts(currentUser)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onFetchUserInvoices: body => dispatch(fetchUserInvoices(body)),
  onFetchUserPayouts: body => dispatch(fetchUserPayouts(body)),
  onFetchProjects: user => dispatch(fetchProjects(user)),
  onFetchOngoingProjects: user => dispatch(fetchOngoingProjects(user)),
  onUpdateStripeCustomer: body => dispatch(updateExistingStripeCustomer(body)),
  onPay: body => dispatch(pay(body)),

  onCreateStripeCustomer: (body, stripeAccount) =>
    dispatch(createNewStripeCustomer(body, stripeAccount)),
});

const BillingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BillingPageComponent);

BillingPage.loadData = () => {
  return fetchCurrentUser();
};

export default BillingPage;
