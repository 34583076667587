/* eslint-disable */
import PropTypes from 'prop-types'
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { Component, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from '../../util/reactIntl';
import {
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperAccountSettingsSideNav,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    Page,
    UserNav,
    PrimaryButton,
  } from '../../components';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { generateAPIToken } from './GenerateAPIKeyPage.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import css from './GenerateAPIKeyPage.css';
import AuthModal from '../../components/AuthModal/AuthModal';
import { func } from 'prop-types';
import { Form } from 'react-final-form';
import { FaLessThanEqual } from 'react-icons/fa';
const GenerateAPIKeyPageComponent = props => {
    const {
        currentUser,
        currentUserListing,
        scrollingDisabled,
        inProgress,
        loginError,
        ready,
        generateAPIKey,
        onManageDisableScrolling,
        onRefreshUser,
        intl,
    } = props;
    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [alreadyGenerated, setAlreadyGenerated] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const fetchUser = async () =>{
        const data = await onRefreshUser();
    }
    useEffect(()=>{
        setApiKey(currentUser?.attributes.profile.privateData.apiKey);
    })
    useEffect(()=>{
        fetchUser()
        setApiKey(currentUser?.attributes.profile.privateData.apiKey);
    }, [authModalOpen])
    const title = intl.formatMessage({ id: 'GenerateAPIKeyPage.title' });
    const loginErrorMessage = (
        <div className={css.error}>
          <FormattedMessage id="AuthForm.loginFailed" />
        </div>
      );
    async function handleSubmit(loginValues){ 
        let keyValues = {...{ email: currentUser.attributes.email, userId: currentUser.id.uuid}, ...loginValues}

        return await generateAPIKey(keyValues).then(()=>{
            if(alreadyGenerated){
            setApiKey(currentUser?.attributes.profile.privateData.apiKey);
            setAuthModalOpen(false)
            }
            else{
                setAlreadyGenerated(true)
                generateAPIKey(keyValues).then(()=>{
                setApiKey(currentUser?.attributes.profile.privateData.apiKey);
                fetchUser()
                setAuthModalOpen(false)
            })
            }
        }
        )
        

    }
    const tokenLabel = intl.formatMessage({ id: 'GenerateAPIKeyPage.tokenLabel' });
    const msg = `authorization` + <br/> +
    `headers: {` + <br/> +
        `'X-API-KEY': XXXXXXXXXXXXXX` + <br/> +
      '}' + <br/> +
    `endpoints list` + <br/> +
    `GET https://softwaresupp.com/rest-api/taskNew` + <br/> +
    `GET https://softwaresupp.com/rest-api/taskUpdate` + <br/> +
    `GET https://softwaresupp.com/rest-api/commentNew`
    
    const errorMessage = (error, message) => (error?.status ? message : null);
    const loginAuthError = errorMessage(loginError, loginErrorMessage)
    return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
            <LayoutSideNavigation>
                <LayoutWrapperTopbar>
                    <TopbarContainer
                    currentPage="GenerateAPIKeyPage"
                    desktopClassName={css.desktopTopbar}
                    mobileClassName={css.mobileTopbar}
                    />
                    <UserNav
                    currentUser={currentUser}
                    selectedPageName="GenerateAPIKeyPage"
                    listing={currentUserListing}
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperAccountSettingsSideNav currentTab="GenerateAPIKeyPage" />
                <LayoutWrapperMain>
                <div className={css.content}>
                    <h1 className={css.title}>
                        <FormattedMessage id="GenerateAPIKeyPage.heading" />
                    </h1>
                        <label for="apiKey" id="apiKeyLabel">{tokenLabel}</label>
                        <textarea
                            id={"apiKey"}
                            className={css.apiKey}
                            rows="2"
                            label={tokenLabel}
                            disabled
                            value={apiKey}
                        />
                    <PrimaryButton
                        type="submit"
                        onClick={() => setAuthModalOpen(true)}
                        inProgress={inProgress}
                        ready={ready}
                    >
                        {(apiKey===null ? <FormattedMessage id="GenerateAPIKeyPage.generateButton" />
                        : <FormattedMessage id="GenerateAPIKeyPage.revokeButton" />)}
                    </PrimaryButton>
                    {loginAuthError}
                    {(apiKey!==null ? <code className={css.apiGuide}>
                        {'authorization'} <br/>
                        {`headers: {`} <br/> 
                            {`'X-API-KEY': ${apiKey}`}  <br/> 
                        {'}'}  <br/> 
                        {`endpoints list`}  <br/> 
                        {`GET https://softwaresupp.com/rest-api/taskNew`}  <br/> 
                        {`GET https://softwaresupp.com/rest-api/taskUpdate`}  <br/> 
                        {`GET https://softwaresupp.com/rest-api/commentNew`}
    
                        </code> : null)}
                </div>
                </LayoutWrapperMain>
            </LayoutSideNavigation>
            <AuthModal
            id="AuthModal"
            isOpen={authModalOpen}
            currentUser={currentUser}
            onCloseModal={() => {setAuthModalOpen(false)}}
            onManageDisableScrolling={onManageDisableScrolling}
            onSubmit={values => handleSubmit(values, false)}
            />
            <LayoutWrapperFooter>
            <Footer />
            </LayoutWrapperFooter>
        </Page>
    )
}

const { bool } = PropTypes;

GenerateAPIKeyPageComponent.propTypes = {
  
    // from injectIntl
    intl: intlShape.isRequired,
    inProgress: bool,
    ready: bool.isRequired,
    generateAPIKey: func.isRequired
};
const mapDispatchToProps = dispatch => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
    generateAPIKey: ({email, userId, password}) => dispatch(generateAPIToken(email, userId, password)),
    onRefreshUser: () => dispatch(fetchCurrentUser()),
})
const mapStateToProps = state => {
    const{
        currentUser,
        currentUserListing,
    } = state.user
    const { loginError, } = state.Auth;
    return {
        currentUser,
        currentUserListing,
        scrollingDisabled: isScrollingDisabled(state),
        loginError
    };
};
const GenerateAPIKeyPage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(GenerateAPIKeyPageComponent);

GenerateAPIKeyPage.loadData = () => {
    return fetchCurrentUser();
};
  

export default GenerateAPIKeyPage;
