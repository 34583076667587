import * as custom from './marketplace-custom-config.js';
import defaultLocationSearches from './default-location-searches';
import { defaultMCC, stripePublishableKey, stripeCountryDetails } from './stripe-config';
import { currencyConfiguration } from './currency-config';

const env = process.env.REACT_APP_ENV;
const dev = process.env.REACT_APP_ENV === 'development';

// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale = 'en';
const i18n = {
  /*
    0: Sunday
    1: Monday
    ...
    6: Saturday
  */
  firstDayOfWeek: 0,
};

// Should search results be ordered by distance to origin.
// NOTE: If this is set to true add parameter 'origin' to every location in default-location-searches.js
// Without the 'origin' parameter, search will not work correctly
// NOTE: Keyword search and ordering search results by distance can't be used at the same time. You can turn keyword
// search off by changing the keywordFilterConfig parameter active to false in marketplace-custom-config.js
const sortSearchByDistance = false;
const skills = [
  {
    id: '0538637d-cd8b-4603-97fb-f6e5970e23e3',
    authorId: null,
    authorMail: null,
    key: 'onboarding',
    label: 'Onboarding',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '17127ac3-d9ae-40b6-a206-014b8bf4b288',
    authorId: null,
    authorMail: null,
    key: 'telephonySystems',
    label: 'Telephony systems',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: '33b641ad-2dde-4688-8a9f-e23b01a204f6',
    authorId: null,
    authorMail: null,
    key: 'database',
    label: 'Database',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '354b481a-4951-4661-a70b-d3e3c2ea389c',
    authorId: null,
    authorMail: null,
    key: 'liquidDevelopment',
    label: 'Liquid development',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '39357d31-4108-4ce4-8077-de01812733d8',
    authorId: null,
    authorMail: null,
    key: 'consulting',
    label: 'Consulting',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '3c894f1d-9f13-44bc-9ff3-55c58aa53900',
    authorId: null,
    authorMail: null,
    key: 'storeSetup',
    label: 'Store setup',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '4a976740-2b4b-4b53-a9e8-5fbc58a09f49',
    authorId: null,
    authorMail: null,
    key: 'ecommerce',
    label: 'E-commerce',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '4b725510-eb8e-4da4-ae90-20aaf36a2a58',
    authorId: null,
    authorMail: null,
    key: 'dataAnalytics',
    label: 'Data analytics',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '4ee92053-534d-43ab-bcd7-4d41986ea8c6',
    authorId: null,
    authorMail: null,
    key: 'visualForce',
    label: 'Visualforce',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '55edd7e4-60ad-4666-9f23-a2a0833f8a91',
    authorId: null,
    authorMail: null,
    key: '3rdPartyIntegrations',
    label: '3rd party integrations',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '5b3b402a-b71c-41cc-8ca1-3f52f8befb78',
    authorId: null,
    authorMail: null,
    key: 'analytics',
    label: 'Analytics',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '62f05820-1024-4630-aef1-05202bf2af03',
    authorId: null,
    authorMail: null,
    key: 'automation',
    label: 'Automation',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '67692c76-6289-4c9c-be9f-f922f3e66bc1',
    authorId: null,
    authorMail: null,
    key: 'webdesign',
    label: 'Web design',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '677db5f2-9671-451e-8f4e-8e023ea6372a',
    authorId: null,
    authorMail: null,
    key: 'domainSetup',
    label: 'Domain setup',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: '6d4324ee-083d-483b-92d6-8f30c65e7fde',
    authorId: null,
    authorMail: null,
    key: 'businessAnalysis',
    label: 'Business analysis',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: '765fcd48-20d9-424c-9336-621856fbb96e',
    authorId: null,
    authorMail: null,
    key: 'websiteIntegration',
    label: 'Website integration',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '7858b6bb-9918-4035-b17f-0f77bc340a4e',
    authorId: null,
    authorMail: null,
    key: 'abDesign',
    label: 'A/B design',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '871209dc-bb78-4817-975d-72a30c9e3a2f',
    authorId: null,
    authorMail: null,
    key: 'emailSystems',
    label: 'Email systems',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '889da2f3-2a73-42c0-a831-4a573f08bc84',
    authorId: null,
    authorMail: null,
    key: 'dataVisualisation',
    label: 'Data visualisation',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '8afa2749-72a9-47d7-b6ec-e30f8b14fe18',
    authorId: null,
    authorMail: null,
    key: 'api',
    label: 'API',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '8b4fe25f-adc8-475e-861c-d9bd42d88a27',
    authorId: null,
    authorMail: null,
    key: 'pageSpeedOptimization',
    label: 'Page speed optimization',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '8e99abaf-c792-4a9c-b3d8-c07990058e65',
    authorId: null,
    authorMail: null,
    key: 'webApplication',
    label: 'Web application',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: '991a990d-a60a-4ceb-9eb6-d37d0f1489b0',
    authorId: null,
    authorMail: null,
    key: 'crm',
    label: 'CRM',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '991c3b05-235d-4e92-ace3-9a1fdc981218',
    authorId: null,
    authorMail: null,
    key: 'dom',
    label: 'DOM',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: '9b12f52e-6981-4b4b-98a7-bf3e726df798',
    authorId: null,
    authorMail: null,
    key: 'marketingAutomation',
    label: 'Marketing automation',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: '9e18d244-3eb1-486f-9b60-7c3c28c16f81',
    authorId: null,
    authorMail: null,
    key: 'financialAnalysis',
    label: 'Financial analysis',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: '9e6cfd2e-f0ef-48fb-908f-f46bcec6faea',
    authorId: null,
    authorMail: null,
    key: 'implementation',
    label: 'Implementation',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: 'a65bd8aa-8415-432d-ac42-6d4d2fd6b4b8',
    authorId: null,
    authorMail: null,
    key: 'training',
    label: 'Training',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: 'a96480f7-b928-42f5-a7b2-cc21824e11d0',
    authorId: null,
    authorMail: null,
    key: 'theme',
    label: 'Theme',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: 'b271b34f-93f4-4c9b-a766-2cb8725a8470',
    authorId: null,
    authorMail: null,
    key: 'noCode',
    label: 'No code',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: 'b65a1117-f104-4b51-807a-4925cdf2321e',
    authorId: null,
    authorMail: null,
    key: 'customDevelopment',
    label: 'Custom development',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: 'bfa6d2a8-0085-4a7b-883d-8e9738c582e9',
    authorId: null,
    authorMail: null,
    key: 'projectManagement',
    label: 'Project management',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: 'cedb12c6-b3e0-44c3-8738-59e7adb71230',
    authorId: null,
    authorMail: null,
    key: 'callCenter',
    label: 'Call center',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: 'cef901fe-685c-45f4-a39c-931afd985412',
    authorId: null,
    authorMail: null,
    key: 'migration',
    label: 'Migration',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: 'e422aef5-f6a8-42d7-952f-2446167a5a2d',
    authorId: null,
    authorMail: null,
    key: 'erp',
    label: 'ERP',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: 'e953413e-fa15-495f-bab5-766ecd3784d2',
    authorId: null,
    authorMail: null,
    key: 'uxDesign',
    label: 'UX design',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: 'ec131b9f-9939-44a1-b781-d14c5bfdef35',
    authorId: null,
    authorMail: null,
    key: 'customAppDevelopment',
    label: 'Custom app development',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: 'ef47aa2d-2500-4216-a20b-f485a23567a7',
    authorId: null,
    authorMail: null,
    key: 'support',
    label: 'Support',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: 'efd02d98-399f-47f0-93c2-e26601b6fda9',
    authorId: null,
    authorMail: null,
    key: 'ui',
    label: 'UI',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:03.000Z',
    updatedAt: '2021-04-27T13:53:03.000Z',
  },
  {
    id: 'f440a710-f851-4d71-a932-37dc7bb3c287',
    authorId: null,
    authorMail: null,
    key: 'dataMigration',
    label: 'Data migration',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: 'f5622d90-6be6-44b2-91a0-9a43c01a0cbd',
    authorId: null,
    authorMail: null,
    key: 'integration',
    label: 'Integration',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
  {
    id: 'f73100cb-29fe-4608-9132-8567ffe0fb18',
    authorId: null,
    authorMail: null,
    key: 'conversionRateOptimization',
    label: 'Conversion rate optimization',
    type: 'skill',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:02.000Z',
    updatedAt: '2021-04-27T13:53:02.000Z',
  },
];
const softwares = [
  {
    id: '05f756c5-12aa-4f80-96b8-fd90cb28d90b',
    authorId: null,
    authorMail: null,
    key: 'java',
    label: 'Java',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-06-09T12:36:52.000Z',
    updatedAt: '2021-06-09T12:37:35.000Z',
  },
  {
    id: '06e762eb-9d5c-4f20-9b28-e3205da71708',
    authorId: null,
    authorMail: null,
    key: 'googleAnalytics',
    label: 'Google Analytics',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '0dd054bb-a388-4485-8f92-4a29a1ae6b4f',
    authorId: null,
    authorMail: null,
    key: 'clickup',
    label: 'ClickUp',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '0dfa4bc1-99e8-499c-bad0-ecc66fda7e6a',
    authorId: null,
    authorMail: null,
    key: 'reacjs',
    label: 'ReactJS',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-06-09T12:36:52.000Z',
    updatedAt: '2021-06-09T12:37:47.000Z',
  },
  {
    id: '19b9c170-a2a0-4045-b832-dfcebc7f9f36',
    authorId: null,
    authorMail: null,
    key: 'php',
    label: 'PHP',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-06-09T12:36:52.000Z',
    updatedAt: '2021-06-09T12:37:55.000Z',
  },
  {
    id: '1a31fe91-89ee-462e-8d4f-c9f239f79512',
    authorId: null,
    authorMail: null,
    key: 'msDynamics',
    label: 'MS Dynamics',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '1ca3e247-00b3-4a06-b2b8-ca52b961de19',
    authorId: null,
    authorMail: null,
    key: 'adobeIllustrator',
    label: 'Adobe Illustrator',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '1f46e3c5-a6d1-46b4-9b70-a4a397873caf',
    authorId: null,
    authorMail: null,
    key: 'googleDocs',
    label: 'Google Docs',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '229f04bb-2a39-46d3-b4c5-4a83ec9210e0',
    authorId: null,
    authorMail: null,
    key: 'googleAds',
    label: 'Google Ads',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '23aefea2-60bb-41b9-9c87-c10ae9fa590a',
    authorId: null,
    authorMail: null,
    key: 'other',
    label: 'Other',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '28952da6-d81a-4061-8f49-c5fb30789eae',
    authorId: null,
    authorMail: null,
    key: 'intercom',
    label: 'Intercom',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '2b3af856-6c0d-49ad-b416-ddc0aae71dd6',
    authorId: null,
    authorMail: null,
    key: 'matomo',
    label: 'Matomo',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '3112ea4c-2438-4df5-b8ff-c61802622dc8',
    authorId: null,
    authorMail: null,
    key: 'tableau',
    label: 'Tableau',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '32f5598b-d958-4603-8f31-57de0586ba46',
    authorId: null,
    authorMail: null,
    key: 'aws',
    label: 'AWS',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '42f6a8c1-4ace-4df4-8129-b9dc20f6731e',
    authorId: null,
    authorMail: null,
    key: 'vouchery',
    label: 'Vouchery',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '44bd1a56-80bc-4571-964c-45b00ad3e011',
    authorId: null,
    authorMail: null,
    key: 'office',
    label: 'MS Office',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '45044ffe-3075-42f1-8f7e-b8f7372a9bdc',
    authorId: null,
    authorMail: null,
    key: 'figma',
    label: 'Figma',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '47166379-3241-4513-99ca-e7af77786ad8',
    authorId: null,
    authorMail: null,
    key: 'facebookAds',
    label: 'Facebook Ads',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '47b00ad8-94fd-467a-9b25-1d99675048b9',
    authorId: null,
    authorMail: null,
    key: 'pipedrive',
    label: 'Pipedrive',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '4d7f615c-a571-45f8-ba34-8d763e241fb7',
    authorId: null,
    authorMail: null,
    key: 'paperform',
    label: 'Paperform',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '4e8fd48e-e908-4f1a-be60-668501a48534',
    authorId: null,
    authorMail: null,
    key: 'python',
    label: 'Python',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-06-09T12:36:52.000Z',
    updatedAt: '2021-06-09T12:38:01.000Z',
  },
  {
    id: '4e911446-702d-4ce4-96f1-ee694480b7c8',
    authorId: null,
    authorMail: null,
    key: 'magento',
    label: 'Magento',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '557ab849-f738-4ae9-8d02-393b47db13eb',
    authorId: null,
    authorMail: null,
    key: 'powerBI',
    label: 'Power BI',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '559d86e6-3d72-4c46-8fae-52b30bf2bd43',
    authorId: null,
    authorMail: null,
    key: 'woocommerce',
    label: 'WooCommerce',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '5aecce0a-0bd7-47dd-ac49-5fb2eadd915d',
    authorId: null,
    authorMail: null,
    key: 'gsuite',
    label: 'G Suite',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '5e89f38c-6dfe-4392-9500-0a676ce1d781',
    authorId: null,
    authorMail: null,
    key: 'shopify',
    label: 'Shopify',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '60d9ae1d-9d83-48ce-90c0-36e26624bd78',
    authorId: null,
    authorMail: null,
    key: 'sketch',
    label: 'Sketch',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '628c7fbb-88e1-49ba-b3d1-623c40c108ca',
    authorId: null,
    authorMail: null,
    key: 'slack',
    label: 'Slack',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '636bbf2a-e22a-405a-8771-d0088233d9b0',
    authorId: null,
    authorMail: null,
    key: 'marketo',
    label: 'Marketo',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '640d1102-1558-413f-878f-e2fd2ab4a3ab',
    authorId: null,
    authorMail: null,
    key: 'twilio',
    label: 'Twilio',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '6569abff-de0e-4e4d-b671-fba80f6450d7',
    authorId: null,
    authorMail: null,
    key: 'piwik',
    label: 'Piwik',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '66c5e0f7-f99c-4b70-a55f-fe895e9d1a67',
    authorId: null,
    authorMail: null,
    key: 'mailchimp',
    label: 'MailChimp',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '676efb88-be57-4642-9424-96c2a538e51f',
    authorId: null,
    authorMail: null,
    key: 'wordpress',
    label: 'WordPress',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '67fd45c1-0031-4833-a0e5-d470980eb6e8',
    authorId: null,
    authorMail: null,
    key: 'woodpecker',
    label: 'Woodpecker',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '69790019-a155-4439-93dc-627d688e4355',
    authorId: null,
    authorMail: null,
    key: 'asana',
    label: 'Asana',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '6d0af2c4-f44f-46b1-8c2c-e1b5b06bc163',
    authorId: null,
    authorMail: null,
    key: 'adobexd',
    label: 'Adobe Xd',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '6d64e3a7-b7b1-4940-9e4f-f95fcf70260e',
    authorId: null,
    authorMail: null,
    key: 'prestashop',
    label: 'PrestaShop',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '73e5f46e-56ca-4c91-9e29-abd183f168eb',
    authorId: null,
    authorMail: null,
    key: 'invision',
    label: 'Invision',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '7bc936f9-e249-4b71-8cf4-54ac48539f6a',
    authorId: null,
    authorMail: null,
    key: 'adobePhotoshop',
    label: 'Adobe Photoshop',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '7d2048e8-8246-44a8-b2cc-5496a23ad942',
    authorId: null,
    authorMail: null,
    key: 'livechat',
    label: 'LiveChat',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '81093f13-359d-40d9-bb82-f318e1005f75',
    authorId: null,
    authorMail: null,
    key: 'zapier',
    label: 'Zapier',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '81d9f6f0-f305-4186-8c66-03421d0714b9',
    authorId: null,
    authorMail: null,
    key: 'django',
    label: 'Django',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-06-09T12:36:52.000Z',
    updatedAt: '2021-06-09T12:38:14.000Z',
  },
  {
    id: '863b525e-79fd-427c-ac8a-72e6a6242653',
    authorId: null,
    authorMail: null,
    key: 'monday',
    label: 'Monday',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '878190ea-6a44-4692-b115-68f2bb06edf2',
    authorId: null,
    authorMail: null,
    key: 'sap',
    label: 'SAP',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: '8854d6f0-f65c-4b05-b8d3-b366edbaf880',
    authorId: null,
    authorMail: null,
    key: 'atlassian',
    label: 'Atlassian',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '8bb64707-e335-4ddb-a291-c1658537d11e',
    authorId: null,
    authorMail: null,
    key: 'notion',
    label: 'Notion',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: '8da77438-caba-4d2c-b97b-52a66c1666d6',
    authorId: '5f1a9a4a-dc8d-40bd-a1cc-e6442f3f4db8',
    authorMail: 'robert@softwaresupp.com',
    key: 'react',
    label: 'React',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-28T13:04:06.000Z',
    updatedAt: '2021-04-28T13:04:21.000Z',
  },
  {
    id: '984e2cd7-7e7f-4471-94ad-802b0e80ac2a',
    authorId: null,
    authorMail: null,
    key: 'activeCampaign',
    label: 'ActiveCampaign',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: 'a03a592b-454f-47c5-8530-6aac8f568097',
    authorId: null,
    authorMail: null,
    key: 'salesforce',
    label: 'Salesforce',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: 'ada4efb9-0a26-46ee-a288-36e751b14552',
    authorId: null,
    authorMail: null,
    key: 'zoho',
    label: 'Zoho',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: 'ae28c97c-bf00-4811-9cd3-5dddcd22f2eb',
    authorId: null,
    authorMail: null,
    key: 'jira',
    label: 'Jira',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: 'affe0524-dc6f-499b-b772-48639f418399',
    authorId: null,
    authorMail: null,
    key: 'pandaDoc',
    label: 'PandaDoc',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: 'bbb5ca2b-af25-44d8-9e18-4c706d956b71',
    authorId: null,
    authorMail: null,
    key: 'sql',
    label: 'SQL',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-06-09T12:36:52.000Z',
    updatedAt: '2021-06-09T12:38:26.000Z',
  },
  {
    id: 'bc2dfc64-1947-4f7f-9b29-fc560922b51a',
    authorId: null,
    authorMail: null,
    key: 'webflow',
    label: 'Webflow',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: 'c0b5214f-3892-4440-8d79-d8f03603babf',
    authorId: '5f1a9a4a-dc8d-40bd-a1cc-e6442f3f4db8',
    authorMail: 'robert@softwaresupp.com',
    key: 'nodejs',
    label: 'Node.js',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-28T13:04:06.000Z',
    updatedAt: '2021-04-28T13:04:23.000Z',
  },
  {
    id: 'c1208f5b-e4c9-48d7-ba0f-da8249afff4c',
    authorId: null,
    authorMail: null,
    key: 'bigCommerce',
    label: 'BigCommerce',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: 'c8765397-266f-4d77-a71d-5334361fe784',
    authorId: null,
    authorMail: null,
    key: 'sharetribe',
    label: 'Sharetribe',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: 'ca923a41-706a-43e2-bb58-f7738894831b',
    authorId: null,
    authorMail: null,
    key: 'airtable',
    label: 'Airtable',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: 'cae27ae2-2ff2-4254-8fbf-37f8050e8a2f',
    authorId: null,
    authorMail: null,
    key: 'microsoftAzure',
    label: 'Microsoft Azure',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: 'd6a1a39e-c610-4f98-8d35-656f180fcd3d',
    authorId: null,
    authorMail: null,
    key: 'autopilot',
    label: 'Autopilot',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: 'db65ca77-0aeb-4e8c-982f-c2d5709cfeb0',
    authorId: null,
    authorMail: null,
    key: 'mongodb',
    label: 'MongoDB',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-06-09T12:36:52.000Z',
    updatedAt: '2021-06-09T12:38:32.000Z',
  },
  {
    id: 'e023cd66-b4fc-48a2-a491-25466ebd731d',
    authorId: null,
    authorMail: null,
    key: 'integromat',
    label: 'Integromat',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: 'e23d620e-1c61-46af-95cb-6920f48f4b91',
    authorId: null,
    authorMail: null,
    key: 'klaviyo',
    label: 'Klaviyo',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: 'e653d766-56eb-4a2d-bf70-b1c12322c874',
    authorId: '5f1a9a4a-dc8d-40bd-a1cc-e6442f3f4db8',
    authorMail: 'robert@softwaresupp.com',
    key: 'javascript',
    label: 'JavaScript',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-28T13:04:06.000Z',
    updatedAt: '2021-04-28T13:04:26.000Z',
  },
  {
    id: 'e98201e9-1e0e-4399-b240-7fcb4425ee33',
    authorId: null,
    authorMail: null,
    key: 'mautic',
    label: 'Mautic',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: 'ed5a9a53-43ec-4b44-98ca-beefb92f7700',
    authorId: null,
    authorMail: null,
    key: 'linkedInAds',
    label: 'LinkedIn Ads',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: 'f19a91e5-1e36-4e26-8d49-8706006aad2f',
    authorId: null,
    authorMail: null,
    key: 'chatbot',
    label: 'ChatBot',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: 'f9a3b54b-5aa1-4d46-86b5-69b721120733',
    authorId: null,
    authorMail: null,
    key: 'hubSpot',
    label: 'HubSpot',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:00.000Z',
    updatedAt: '2021-04-27T13:53:00.000Z',
  },
  {
    id: 'f9d0db65-b206-43cf-99cf-1e8f981c2175',
    authorId: null,
    authorMail: null,
    key: 'typeform',
    label: 'Typeform',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: 'fa6b8f54-c682-427a-888b-d08f622c9ec8',
    authorId: null,
    authorMail: null,
    key: 'ringOver',
    label: 'Ringover',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
  {
    id: 'fd5ad148-25c4-46e4-b186-cac3f518a6d1',
    authorId: null,
    authorMail: null,
    key: 'livestorm',
    label: 'Livestorm',
    type: 'software',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
];
const industries = [
  {
    id: 'fd5ad148-25c4-46e4-b186-cac3f518a6d1',
    authorId: null,
    authorMail: null,
    key: 'hardware',
    label: 'Hardware',
    type: 'industry',
    isApproved: 1,
    createdAt: '2021-04-27T13:53:01.000Z',
    updatedAt: '2021-04-27T13:53:01.000Z',
  },
]
// API supports custom processes to be used in booking process.
// We need to specify it when we are initiating a new order
// (or fetching price information by calling 'initiateSpeculative' endpoint).
//
// In a way, 'processAlias' defines which transaction process (or processes)
// this particular web application is able to handle.
const bookingProcessAlias = 'client-path/release-1';
const preBookingProcessAlias = 'expert-path/release-1';

// The transaction line item code for the main unit type in bookings.
//
// Possible values: ['line-item/night', 'line-item/day', 'line-item/units';]
//
// Note: translations will use different translation keys for night, day or unit
// depending on the value chosen.
const bookingUnitType = 'line-item/units';

// Should the application fetch available time slots (currently defined as
// start and end dates) to be shown on listing page.
const enableAvailability = process.env.REACT_APP_AVAILABILITY_ENABLED === 'true';

// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. Also note that available time slots can only
// be fetched for 180 days in the future.
const dayCountAvailableForBooking = 14;

// To pass environment variables to the client app in the build
// script, react-scripts (and the sharetribe-scripts fork of
// react-scripts) require using the REACT_APP_ prefix to avoid
// exposing server secrets to the client side.
const sdkClientId = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
const sdkBaseUrl = process.env.REACT_APP_SHARETRIBE_SDK_BASE_URL;
const sdkTransitVerbose = process.env.REACT_APP_SHARETRIBE_SDK_TRANSIT_VERBOSE === 'true';

const currency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY;

// Currency formatting options.
// See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
const currencyConfig = currencyConfiguration(currency);

// Listing minimum price in currency sub units, e.g. cents.
// 0 means no restriction to the price
const listingMinimumPriceSubUnits = 0;
const minimumProjectBudget = 10000;
const expertOfferMinimumPriceSubUnits = 0;
const expertServiceMinimumPriceSubUnits = 15000;

const plnUsdFactor = process.env.REACT_APP_DEFAULT_CONVERSION_VALUE;

// Sentry DSN (Data Source Name), a client key for authenticating calls to Sentry
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

// If webapp is using SSL (i.e. it's behind 'https' protocol)
const usingSSL = process.env.REACT_APP_SHARETRIBE_USING_SSL === 'true';

// Address information is used in SEO schema for Organization (http://schema.org/PostalAddress)
const addressCountry = 'PL';
const addressRegion = 'Wrocław';
const postalCode = '51-649';
const streetAddress = 'Bulevardi 14';

// Canonical root url is needed in social media sharing and SEO optimization purposes.
const canonicalRootURL = process.env.REACT_APP_CANONICAL_ROOT_URL;
const shareRootURL =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_CANONICAL_ROOT_URL
    : process.env.REACT_APP_SHARE_ROOT_URL;

// Site title is needed in meta tags (bots and social media sharing reads those)
const siteTitle = 'SoftwareSupp';

// Twitter handle is needed in meta tags (twitter:site). Start it with '@' character
const siteTwitterHandle = '@/softwaresupp';

// Facebook page is used in SEO schema (http://schema.org/Organization)
const siteFacebookPage = 'https://www.facebook.com/softwaresupp/';

const siteLinkedInPage = 'https://www.linkedin.com/company/softwaresupp';

const siteYouTubePage =
  'https://www.youtube.com/channel/UCYHGUxJ0mqV557yMVdVQPKw?sub_confirmation=1';

const softwaresuppEmail = 'mailto:payments@softwaresupp.com';

// Social logins & SSO

// Note: Facebook app id is also used for tracking:
// Facebook counts shares with app or page associated by this id
// Currently it is unset, but you can read more about fb:app_id from
// https://developers.facebook.com/docs/sharing/webmasters#basic
// You should create one to track social sharing in Facebook
const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

const defaultPrivateTicketExpertList = [
  '5f1a9a4a-403e-437c-8d9f-59206f54c33f',
  '5f1a9a4a-01f2-47eb-850b-dc1f843f8ea1',
];

const maps = {
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

  // The location search input can be configured to show default
  // searches when the user focuses on the input and hasn't yet typed
  // anything. This reduces typing and avoids too many Geolocation API
  // calls for common searches.
  search: {
    // When enabled, the first suggestion is "Current location" that
    // uses the browser Geolocation API to query the user's current
    // location.
    suggestCurrentLocation: process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true',

    // Distance in meters for calculating the bounding box around the
    // current location.
    currentLocationBoundsDistance: 1000,

    // Example location can be edited in the
    // `default-location-searches.js` file.
    defaults:
      process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true' ? defaultLocationSearches : [],

    // Limit location autocomplete to a one or more countries
    // using ISO 3166 alpha 2 country codes separated by commas.
    // If you want to limit the autocomplete, uncomment this value:
    // countryLimit: ['AU'],
  },

  // When fuzzy locations are enabled, coordinates on maps are
  // obfuscated randomly around the actual location.
  //
  // NOTE: This only hides the locations in the UI level, the actual
  // coordinates are still accessible in the HTTP requests and the
  // Redux store.
  fuzzy: {
    enabled: false,

    // Amount of maximum offset in meters that is applied to obfuscate
    // the original coordinates. The actual value is random, but the
    // obfuscated coordinates are withing a circle that has the same
    // radius as the offset.
    offset: 500,

    // Default zoom level when showing a single circle on a Map. Should
    // be small enough so the whole circle fits in.
    defaultZoomLevel: 13,

    // Color of the circle on the Map component.
    circleColor: '#c0392b',
  },

  // Custom marker image to use in the Map component.
  //
  // NOTE: Not used if fuzzy locations are enabled.
  customMarker: {
    enabled: false,

    // Publicly accessible URL for the custom marker image.
    //
    // The easiest place is /public/static/icons/ folder, but then the
    // marker image is not available while developing through
    // localhost.
    url: encodeURI(`${canonicalRootURL}/static/icons/favicon-32x32.png`),

    // Dimensions of the marker image.
    width: 32,
    height: 32,

    // Position to anchor the image in relation to the coordinates,
    // ignored when using Mapbox.
    anchorX: 16,
    anchorY: 32,
  },
};

// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets in this file.
const config = {
  softwares,
  env,
  dev,
  locale,
  bookingProcessAlias,
  preBookingProcessAlias,
  bookingUnitType,
  enableAvailability,
  dayCountAvailableForBooking,
  i18n,
  sdk: {
    clientId: sdkClientId,
    baseUrl: sdkBaseUrl,
    transitVerbose: sdkTransitVerbose,
  },
  sortSearchByDistance,
  currency,
  currencyConfig,
  minimumProjectBudget,
  listingMinimumPriceSubUnits,
  expertOfferMinimumPriceSubUnits,
  expertServiceMinimumPriceSubUnits,
  plnUsdFactor,
  stripe: {
    defaultMCC: defaultMCC,
    publishableKey: stripePublishableKey,
    supportedCountries: stripeCountryDetails,
  },
  canonicalRootURL,
  shareRootURL,
  address: {
    addressCountry,
    addressRegion,
    postalCode,
    streetAddress,
  },
  siteTitle,
  siteFacebookPage,
  siteYouTubePage,
  siteLinkedInPage,
  siteTwitterHandle,
  softwaresuppEmail,
  facebookAppId,
  sentryDsn,
  usingSSL,
  maps,
  skills,
  industries,
  custom,
  defaultPrivateTicketExpertList,
};

export default config;
