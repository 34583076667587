import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { connect } from 'react-redux';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  maxLength,
  required,
  composeValidators,
  moneySubUnitAmountAtLeast,
} from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldCurrencyInput,
  FieldTextInput,
  MultilevelScopeInput,
  SecondaryButton,
  AddImages,
  ValidationError,
} from '../../components';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import css from './EditServiceForm.css';

const TITLE_MAX_LENGTH = 120;
const { Money } = sdkTypes;
const ACCEPT_IMAGES = 'image/*';

const EditProjectFormComponent = props => {
  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);
  const [imageUploadRequested, setImageUploadRequested] = useState(false);
  // error messages

  useEffect(() => {
    if (typeof window !== undefined) {
      setShouldRenderReactSelect(true);
    }
  }, []);



  const onImageUploadHandler = file => {
    const { onImageUpload } = props;
    if (file) {
      setImageUploadRequested(true);
      onImageUpload({
        id: `${file.name}_${Date.now()}`,
        file,
      })
        .then(()=> {
          setImageUploadRequested(false);
        })
        .catch(() => {
          setImageUploadRequested(false);
        });
    }
  };

  const setValue = (args, state, { setIn, changeValue }) => {
    const fieldName = args[0];
    const fieldValue = args[1];
    const field = state.fields[fieldName];
    if (!!field) {
      field.change(fieldValue);
    }
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators, setValue }}
      render={formRenderProps => {
        const {
          className,
          ready,
          handleSubmit,
          intl,
          submitFailed,
          pristine,
          saveActionMsg,
          createDraftMessage,
          updated,
          fetchErrors,
          currentUserExists,
          values,
          initialValues,
          onSaveAsDraft,
          isNew,
          currentUserExpertListing,
          panelTitle,
          updateInProgress,
          productSoftware,
          onRemoveImage,
          currentTab,
          images,
          currentUser,
          form,
        } = formRenderProps;

        const classes = classNames(css.root, className);
        // const classes =  classNames
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;

        const allSofts = productSoftware.map(soft => {
          return {
            value: soft.key,
            label: soft.label,
          };
        });

        const allServiceCategories = config.custom.serviceCategoriesConfig.options.map(soft => {
          return {
            value: soft.key,
            label: soft.label,
          };
        });

        // error messages
        const { updateListingError, createListingDraftError, showListingsError, uploadImageError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        const chooseImageText = (
          <span className={css.chooseImageText}>
            <span className={css.chooseImage}>
              <FormattedMessage id="EditProjectForm.chooseImage" />
            </span>
            <span className={css.imageTypes}>
              <FormattedMessage id="EditProjectForm.imageTypes" />
            </span>
          </span>
        );

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        //title messages
        const titleMessage = intl.formatMessage({
          id: 'EditServiceForm.title',
        });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditServiceForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditServiceForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

        // description messages
        const descriptionMessage = intl.formatMessage({
          id: 'EditServiceForm.description',
        });
        const descriptionPlaceholder = intl.formatMessage({
          id: 'EditServiceForm.descriptionPlaceholder',
        });
        const descriptionRequired = intl.formatMessage({
          id: 'EditServiceForm.descriptionRequired',
        });

        //software messages
        const softwareLabel = intl.formatMessage({
          id: 'EditServiceForm.softwareTitle',
        });
        const softwarePlaceholder = intl.formatMessage({
          id: 'EditServiceForm.softwarePlaceholder',
        });
        const primarySoftwareLabel = intl.formatMessage({
          id: 'EditServiceForm.primarySoftwareTitle',
        });

        // service categories messages
        const serviceCategoriesLabel = intl.formatMessage({
          id: 'EditServiceForm.serviceCategoriesLabel',
        });
        const serviceCategoriesPlaceholder = intl.formatMessage({
          id: 'EditServiceForm.serviceCategoriesPlaceholder',
        });

        //estimated time messages
        const estimatedTimeRequired = required(
          intl.formatMessage({
            id: 'EditServiceForm.estimatedTimeRequired',
          })
        );

        // short description messages
        const shortDescriptionMessage = intl.formatMessage({
          id: 'EditServiceForm.shortDescription',
        });
        const shortDescriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditServiceForm.shortDescriptionPlaceholder',
        });
        const shortDescriptionRequiredMessage = intl.formatMessage({
          id: 'EditServiceForm.shortDescriptionRequired',
        });

        //pricing messages
        const priceLabel = intl.formatMessage({
          id: 'EditServiceForm.priceLabel',
        });
        const priceErrorMessage = intl.formatMessage(
          {
            id: 'EditServiceForm.priceErrorMessage',
          },
          {
            value: formatMoney(
              intl,
              new Money(config.expertServiceMinimumPriceSubUnits, config.currency)
            ),
          }
        );
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);
        let uploadImageFailed = null;
        if (uploadOverLimit) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditProjectForm.imageUploadFailed.uploadOverLimit" />
            </p>
          );
        } else if (uploadImageError) {
          uploadImageFailed = (
            <p className={css.error}>
              <FormattedMessage id="EditProjectForm.imageUploadFailed.uploadFailed" />
            </p>
          );
        }
        if (shouldRenderReactSelect) {
          var isMobile = false;
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            isMobile = true;
          }
          const { default: CreatableSelect } = require('react-select/creatable');
          const { default: Select } = require('react-select');
          var ReactCreatableSelectAdapter = ({ input, ...rest }) => (
            <CreatableSelect {...input} {...rest} searchable />
          );
          var ReactSelectAdapter = ({ input, ...rest }) => (
            <Select {...input} {...rest} searchable />
          );
          var primarySoftwareField = (
            <Field
              name="primarySoftware"
              component={ReactCreatableSelectAdapter}
              options={allSofts}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
              placeholder={softwarePlaceholder}
              className={css.software}
            />
          );
          var softwareField = (
            <Field
              name="software"
              isMulti
              component={ReactCreatableSelectAdapter}
              closeMenuOnSelect={false}
              options={allSofts}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
              placeholder={softwarePlaceholder}
              className={css.software}
            />
          );
          var serviceCategoriesField = (
            <Field
              name="serviceCategories"
              isMulti
              component={ReactSelectAdapter}
              options={allServiceCategories}
              placeholder={serviceCategoriesPlaceholder}
              blurInputOnSelect={false}
              isSearchable={!isMobile}
            />
          );
        }

        const currentUserHasHourlyRate =
          !!currentUserExpertListing && !!currentUserExpertListing.attributes.price;
        const currentUserHourlyRate = currentUserExpertListing?.attributes?.price?.amount;
        const currentUserExpertProfileTitle = currentUserExpertListing?.attributes?.title?.toLowerCase();
        const currentUserExpertProfileId = currentUserExpertListing?.id?.uuid;
        const currentUserHourlyRateLink =
          '/l/' + currentUserExpertProfileTitle + '/' + currentUserExpertProfileId + '/edit/expert';

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <h1 className={css.panelTitle}>
              {panelTitle}
              <Tooltip
                title={
                  <div className={css.creatingServiceInfo}>
                    <FormattedMessage id="EditServiceForm.creatingService" />
                  </div>
                }
              >
                <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 20 }} />
              </Tooltip>
            </h1>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}

            <table className={css.sections}>
              <tbody>
                <tr id="description">
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.descriptionNav" />
                    <Tooltip title={<FormattedMessage id="EditServiceForm.sectionRequired" />}>
                      <span className={css.required}>*</span>
                    </Tooltip>
                  </td>
                  <td className={css.sectionContent}>
                    <FieldTextInput
                      id="EditProjectForm.title"
                      name="title"
                      className={css.title}
                      type="text"
                      label={titleMessage}
                      placeholder={titlePlaceholderMessage}
                      maxLength={TITLE_MAX_LENGTH}
                      validate={composeValidators(
                        required(titleRequiredMessage),
                        maxLength60Message
                      )}
                      autoFocus
                      onClick={() => {
                        if (!currentUserExists) {
                          window.ga('send', 'event', 'Title', 'fulfill');
                        }
                      }}
                    />

                    <FieldTextInput
                      id="EditProjectForm.description"
                      name="description"
                      className={css.description}
                      type="textarea"
                      label={descriptionMessage}
                      placeholder={descriptionPlaceholder}
                      validate={composeValidators(required(descriptionRequired))}
                      isTicket
                      isDescription
                      onClick={() => {
                        if (!currentUserExists) {
                          window.ga('send', 'event', 'Description', 'fulfill');
                        }
                      }}
                    />

                    <FieldTextInput
                      id="shortDescription"
                      name="shortDescription"
                      className={css.description}
                      type="text"
                      label={shortDescriptionMessage}
                      placeholder={shortDescriptionPlaceholderMessage}
                      maxLength={TITLE_MAX_LENGTH}
                      validate={composeValidators(
                        required(shortDescriptionRequiredMessage),
                        maxLength60Message
                      )}
                      onClick={() => {
                        if (!currentUserExists) {
                          window.ga('send', 'event', 'DescriptionShort', 'fulfill');
                        }
                      }}
                    />
                    <FieldTextInput
                      id="estimatedTime"
                      name="estimatedTime"
                      type="number"
                      min={0}
                      className={css.priceInput}
                      label={intl.formatMessage({
                        id: 'EditServiceForm.estimatedTimeLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'EditServiceForm.estimatedTimePlaceholder',
                      })}
                      validate={estimatedTimeRequired}
                    />
                  </td>
                </tr>

                <tr id="scope">
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.scopeNav" />
                    <Tooltip title={<FormattedMessage id="EditServiceForm.sectionRequired" />}>
                      <span className={css.required}>*</span>
                    </Tooltip>
                  </td>
                  <td className={css.sectionContent}>
                    <>
                      <h2 className={css.scopeTitle}>Describe scope of service</h2>
                      {currentUserHasHourlyRate && (
                        <div className={css.scopeCommentWrapper}>
                          <span className={css.scopeCommentText}>
                            If you specify hours related to each task your price will be
                            automatically calculated based on your hourly rate.
                            <br />
                            <strong>Your current hourly rate is:</strong>{' '}
                            {formatMoney(
                              intl,
                              new Money(
                                currentUserHourlyRate ? currentUserHourlyRate : 0,
                                config.currency
                              )
                            )}
                            .<a href={currentUserHourlyRateLink}> You can modify it here.</a>
                          </span>
                        </div>
                      )}
                      <MultilevelScopeInput
                        intl={intl}
                        values={values}
                        currentUserHasHourlyRate={currentUserHasHourlyRate}
                        currentUserHourlyRate={currentUserHourlyRate}
                        initialValues={initialValues.scope}
                      />
                      <FieldCurrencyInput
                        id="EditServiceForm.budget"
                        name="price"
                        className={css.priceInput}
                        label={priceLabel}
                        placeholder={formatMoney(
                          intl,
                          new Money(
                            Object.entries(values)
                              .filter(entryArray => {
                                return entryArray[0].includes('scopeTaskPriceInput');
                              })
                              .reduce((acc, item) => {
                                if (item[1]) {
                                  return acc + item[1].amount;
                                } else {
                                  return acc;
                                }
                              }, 0),
                            config.currency
                          )
                        )}
                        currencyConfig={config.currencyConfig}
                        validate={composeValidators(
                          moneySubUnitAmountAtLeast(
                            priceErrorMessage,
                            config.expertServiceMinimumPriceSubUnits
                          )
                        )}
                      />
                      {/* {formMinimumPriceNotReached &&
                        <span className={css.formError}>
                          <FormattedMessage id="EditServiceForm.minimumPriceNotReached" values={{ price: formatMoney(intl, new Money(config.expertServiceMinimumPriceSubUnits, config.currency)) }} />
                        </span>
                      } */}
                      {/* <p className={css.servicePriceTitle}>
                        <FormattedMessage id="EditServiceForm.finalPriceOffered"/>
                      </p>
                      <span className={css.servicePriceValue}>
                        {formatMoney(intl, new Money(values.price ? values.price.amount * 2 : 0, config.currency))}
                      </span>
                      <p className={classNames(css.smallPrint, css.smallPrintLeft)}>
                        <FormattedMessage id="EditServiceForm.servicePriceDescription" />
                        <Tooltip interactive title={<FormattedMessage id={`EditServiceForm.servicePriceTooltip`} values={{ br: <br /> }} />} >
                          <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
                        </Tooltip>
                      </p> */}
                    </>
                  </td>
                </tr>

                <tr id="category">
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.categoryNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <div className={css.software}>{serviceCategoriesLabel}</div>
                    {serviceCategoriesField}
                  </td>
                </tr>

                <tr id="software">
                  <td className={css.sectionTitle}>
                    <FormattedMessage id="EditPageSidebar.softwareNav" />
                  </td>
                  <td className={css.sectionContent}>
                    <div>
                      <div className={css.software} id="EditProjectForm.software">
                        {primarySoftwareLabel}
                      </div>
                      {primarySoftwareField}
                      <div className={css.software}>{softwareLabel}</div>
                      {softwareField}
                    </div>
                  </td>
                </tr>

                {currentUser ? (
                  <tr id="photos" className={currentTab === 'photos' ? null : css.hiddenTab}>
                    {!isMobile ? (
                      <td className={css.sectionTitle}>
                        <FormattedMessage id="EditPageSidebar.photosNav" />
                      </td>
                    ) : null}
                    <td className={css.sectionContent}>
                      <AddImages
                        className={css.imagesField}
                        images={images}
                        thumbnailClassName={css.thumbnail}
                        savedImageAltText={intl.formatMessage({
                          id: 'EditProjectForm.savedImageAltText',
                        })}
                        onRemoveImage={onRemoveImage}
                      >
                        <Field
                          id="photos"
                          name="addImage"
                          accept={ACCEPT_IMAGES}
                          form={null}
                          label={chooseImageText}
                          type="file"
                          disabled={imageUploadRequested}
                        >
                          {fieldprops => {
                            const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                            
                            const { name, type } = input;
                            const onChange = e => {
                              const file = e.target.files[0];
                              form.change(`addImage`, file);
                              form.blur(`addImage`);

                              onImageUploadHandler(file);
                            };
                            const inputProps = {
                              accept,
                              id: name,
                              name,
                              onChange,
                              type,
                            };
                            
                            return (
                              <div className={css.addImageWrapper}>
                                <div className={css.aspectRatioWrapper}>
                                  {fieldDisabled ? null : (
                                    <input {...inputProps} className={css.addImageInput} />
                                  )}
                                  <label htmlFor={name} className={css.addImage}>
                                    {label}
                                  </label>
                                </div>
                              </div>
                            );
                          }}
                        </Field>
                        <Field
                          component={props => {
                            const { input, meta } = props;
                            return (
                              <div className={css.imageRequiredWrapper}>
                                <input {...input} />
                                <ValidationError fieldMeta={meta} />
                              </div>
                            );
                          }}
                          name="images"
                          type="hidden"
                          //validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                        />
                      </AddImages>
                      {uploadImageFailed}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>

            {submitFailed && (
              <span className={css.formError}>
                <FormattedMessage id="NewOfferForm.formInvalid" />
              </span>
            )}

            <div className={css.buttonGroup} id="save">
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                ready={submitReady}
              >
                {saveActionMsg}
              </PrimaryButton>
              {isNew ? (
                <SecondaryButton
                  className={css.submitButton}
                  ready={submitReady}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSaveAsDraft(values);
                  }}
                >
                  {createDraftMessage}
                </SecondaryButton>
              ) : null}
            </div>
          </Form>
        );
      }}
    />
  );
};

const mapStateToProps = state => {
  const { currentUser, currentUserExpertListing, fetchingExpertListingInProgress } = state.user;
  return {
    currentUser,
    currentUserExpertListing,
    fetchingExpertListingInProgress,
  };
};

EditProjectFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  isAdmin: false,
};

EditProjectFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  currentUserExists: bool.isRequired,
};

export default compose(connect(mapStateToProps), injectIntl)(EditProjectFormComponent);
