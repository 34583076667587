import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import {  injectIntl } from '../../util/reactIntl';
import { SendMessageFileForm } from '../../forms';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { createExamTemplate, readExistingExamListings } from './AdminBulkMessagePage.duck';
import { 
  inboxGetUserDisplayNames,
  sendBulkMessageText,
  sendBulkMessageData
} from '../../util/api';
import {
  NamedRedirect,
  Sidebar,
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import css from './AdminBulkMessagePage.css';

import { RECEIVERS_OPTIONS } from './receiversData';

export const AdminBulkMessagePageComponent = props => {
  
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled
  } = props;
 
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [chosenReceivers, setChosenReceivers] = useState();
  const [authorOptions, setAuthorOptions] = useState([]);
  const [chosenAuthor, setChosenAuthor] = useState();
  const [inboxToken, setInboxToken] = useState();
  const [sendMessageInProgress, setSendMessageInProgress] = useState(false);
  const [sendingStatusMessage, setSendingStatusMessage] = useState("");
  const [refreshFormAttachments, setRefreshFormAttachments] = useState(0); // counter

  const [shouldRenderReactSelect, setShouldRenderReactSelect] = useState(false);
  useEffect(() => {
    if((typeof window) !== 'undefined') {
      setShouldRenderReactSelect(true);
    }
  },[]);
  if(shouldRenderReactSelect) {
    var { default: Select } = require('react-select');
  }

  useEffect(() => {
    if(!!currentUser) {
      loadAuthorOptions(currentUser);
    }
  }, [currentUser]);
  
  const loadAuthorOptions = async (currentUser) => {
    const bulkAuthorIds = currentUser.attributes.profile.publicData.bulkAuthors;
    const currentUserToken = currentUser.attributes.profile.privateData.inboxToken;
    setInboxToken(currentUserToken);
    const bulkAuthorProfiles = await inboxGetUserDisplayNames(currentUserToken, {userIds: bulkAuthorIds});
    setAuthorOptions(bulkAuthorProfiles.map((el) => {
      return {
        value: el.userId,
        label: el.displayName
      };
    }));
  }

  const onMessageSubmit = async (values, form) => {
    setSendingStatusMessage("");
    setSendMessageInProgress(true);
    const message = !!(values?.message) ? values.message.trim() : null;
    const isSendingFiles = values?.files?.length > 0;
    if (!message && !(isSendingFiles)) {
      setSendMessageInProgress(false);
      return;
    }
    if(!!inboxToken && chosenReceivers?.length > 0 && !!chosenAuthor?.value) {
      let result;
      if(isSendingFiles) {
        result = await sendBulkMessageData(inboxToken, values.files, {
          toReceiversLabels: chosenReceivers.map((el) => el.value), 
          from: chosenAuthor?.value, 
          content: message,
          filesCount: values.files.length
        });
      } else {
        result = await sendBulkMessageText(inboxToken, {
          toReceiversLabels: chosenReceivers.map((el) => el.value), 
          from: chosenAuthor?.value, 
          content: message
        });
      }
      if(result?.success) {
        setSendingStatusMessage(`Message sent. ${result?.messagesCount} receivers.`);
      } else {
        setSendingStatusMessage("Sending message failed.");
      }
      setTimeout(() => {
        form.reset();
        setRefreshFormAttachments(prev => prev+1);
        setSendMessageInProgress(false);
      }, 100);
    } else {
      setSendingStatusMessage("Error. Form data or token incorrect.");
      setTimeout(() => {
        form.reset();
        setRefreshFormAttachments(prev => prev+1);
        setSendMessageInProgress(false);
      }, 100);
    }
  }

  const title = `Bulk Message`;
  const isAdmin = currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;
  const sendMessagePlaceholder = `Message`;

  if (currentUser?.id && !isAdmin) {
    return <NamedRedirect name="NotificationsPage" params={{ tab: 'notifications' }} />;
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="AdminBulkMessagePage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}>
          <Sidebar 
            tab={'adminBulkMessage'}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
        {/* {error} */}
        <div className={css.mainWrapper}>
          <h3 className={css.pageTitle}>Send Bulk Message</h3>
          {shouldRenderReactSelect && 
            <>
              <div className={css.receiversSelectWrapper}>
                <p>Select receiver groups: </p>
                <Select
                  options={RECEIVERS_OPTIONS}
                  isMulti  
                  value={chosenReceivers}
                  onChange={(v) => {setChosenReceivers(v)}} 
                />
              </div>
              <div className={css.authorSelectWrapper}>
                <p>Select message author: </p>
                <Select 
                  options={authorOptions} 
                  value={chosenAuthor}
                  onChange={(v) => {setChosenAuthor(v)}} 
                />
              </div>
            </>
          }
          <div className={css.messageWrapper}>
            <SendMessageFileForm
              formId={'AdminBulkMessagePage.SendMessageForm'}
              rootClassName={css.sendMessageForm}
              messagePlaceholder={sendMessagePlaceholder}
              inProgress={sendMessageInProgress}
              onFocus={() => {}}
              onBlur={() => {}}
              onSubmit={onMessageSubmit}
              refreshFormAttachments={refreshFormAttachments}
            />
            {!!sendingStatusMessage && 
              <p>{sendingStatusMessage}</p>
            }
          </div>

          {/* <div className={css.mainButtonsWrapper}>
            <PrimaryButton className={css.createButton}
              disabled={false}
              inProgress={creatingExamInProgress}
              onClick={onCreateExam}
            >
              <FormattedMessage id="ExamsAdminPage.createExamTemplate" />
            </PrimaryButton>
          </div>
          <div className={css.creatingExamMessageWrapper}>
            {creatingExamMessage}
          </div>
          <div className={css.examsListWrapper}>
            {'Approved exams list: '}
            <ul className={css.examsList}>
              {existingExams.map((examItem) => {
                return (
                  <li>
                    <FaRegDotCircle />{'\u00A0'}
                    {examItem.attributes.title + "(id: " + examItem.id.uuid + ")"}
                  </li>
                )
              })}
            </ul>
          </div> */}
        </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;
  return {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateExamTemplate: (description, config, questions) => dispatch(createExamTemplate(description, config, questions)),
  onReadExistingExams: () => dispatch(readExistingExamListings()),
});

const AdminBulkMessagePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AdminBulkMessagePageComponent);

export default AdminBulkMessagePage;