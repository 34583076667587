import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, SecondaryButton } from '../../components';
import { required, composeValidators } from '../../util/validators';
import { emailFormatValid } from '../../util/validators';

import css from './NPSSurveyForm.css';

const npsNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const NPSSurveyFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        chosenNumber,
        changeChosenNumber,
        submitted,
        npsCategories,
        version,
        intl,
        isLoggedIn,
        currentUserFetched,
        onChangeNPSVersion,
        values
      } = fieldRenderProps;

      if (submitted)
        return null;

      const isBasic = version === 'basic';
      const isExtended = version === 'extended';

      const versionChooser =
        <div className={version ? css.versionChosen : css.versionChoosing}>
          <div className={css.buttonWrapper}>
            <SecondaryButton
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                onChangeNPSVersion('basic')
              }} 
            className={isBasic ? css.buttonSelected : css.button}>
              <FormattedMessage id="NPSSurveyPage.basicVersion" />
            </SecondaryButton>
            <Tooltip interactive title={<FormattedMessage id={'NPSSurveyForm.buttonExtendedTooltip'} />} >
              <div className={css.buttonExtended}>
                <SecondaryButton
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    onChangeNPSVersion('extended')
                  }}
                  className={isExtended ? css.buttonSelected : css.button}>
                  <FormattedMessage id="NPSSurveyPage.extendedVersion" />
                </SecondaryButton>
              </div>
            </Tooltip>
          </div>
        </div>

      if (!version)
        return versionChooser

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      const formattedCategories = isBasic ? npsCategories.slice(0, 1) : npsCategories;

      const asterisk = <span className={css.asterisk}>*</span>

      const emailLabel = intl.formatMessage({ id: 'LoginForm.emailLabel'});
      const emailPlaceholder = intl.formatMessage({ id: 'LoginForm.emailPlaceholder' });
      const emailInvalidMessage = intl.formatMessage({ id: 'SignupForm.emailInvalid' });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            {versionChooser}
            <h1>
              <FormattedMessage id="NPSSurveyForm.title" />
            </h1>
            {formattedCategories.map((category, index) => (
              <div className={css.categories} key={index}>
                <FormattedMessage id={`NPSSurveyForm.numbersLabel${category}`} />
                <div className={css.npsContainer}>
                  <div className={css.npsNumbersContainer}>
                    {
                      npsNumbers.map(number => {
                        return <div
                          className={chosenNumber && number === chosenNumber[index] ? css.selectedNumberWrapper : css.numberWrapper}
                          key={`number${number}`}
                          onClick={() => changeChosenNumber(number, index)}
                        >
                          <div className={css.number}>
                            {number}
                          </div>
                        </div>
                      }
                      )}
                  </div>
                  <div className={css.likeliness}>
                    <p className={css.left}>{index === 0 ? 'Very unlikely' : 'Poor'}</p>
                    <p className={css.right}>{index === 0 ? 'Very likely' : 'Great'}</p>
                  </div>
                  <FieldTextInput
                    className={css.field}
                    type="textarea"
                    name={`${category}`}
                    id={`${category}`}
                    label={intl.formatMessage({ id: 'NPSSurveyForm.feedbackLabel' })}
                  />
                </div>

              </div>))}

            {!isLoggedIn && currentUserFetched
              ? <FieldTextInput
                className={css.field}
                type="text"
                name="userName"
                id="userName"
                label={intl.formatMessage({ id: 'NPSSurveyForm.userNotAuthenticatedLabel' }, {asterisk})}
                validate={composeValidators(required(intl.formatMessage({ id: 'NPSSurveyForm.userNameRequired' })))}
              /> : null}

            {!isLoggedIn && currentUserFetched
              ? <FieldTextInput
                className={css.field}
                type="email"
                id={'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={values.email ? emailFormatValid(emailInvalidMessage) : null}
              />
              : null}

            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="NPSSurveyForm.submit" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

NPSSurveyFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

NPSSurveyFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const NPSSurveyForm = compose(injectIntl)(NPSSurveyFormComponent);

NPSSurveyForm.displayName = 'NPSSurveyForm';

export default NPSSurveyForm;
