import React from 'react';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import classNames from 'classnames';

import { SecondaryButton} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const SectionLinkJobsMaybe = props => {
  const { projectBoardAllowed, onOpenProjectBoard, className } = props;

  const projectBoardButton = (
    projectBoardAllowed
      ? <SecondaryButton className={css.jobLinkButton}
        onClick={onOpenProjectBoard}>
        <AiOutlineFundProjectionScreen size={28} />{'\u00A0'}{'\u00A0'}
        <FormattedMessage id="ListingPage.goToProjectBoard" />
      </SecondaryButton>
      : null
  );

  return (
    <div className={classNames(css.sectionJobLinks, className)}>
      {projectBoardButton}
    </div>
  );
};

export default SectionLinkJobsMaybe;