import React from 'react';
import css from './PanelExpertPage.css';

import PanelExpertContent from './PanelExpertContent';

const PanelExpertPage = props => {

    const { pageNumber, fadeIn, fadeOut, fadeDirection } = props;

    return (
        <div className={css.mainWrapper}>
            <div className={css.centerPanel}>
                <div className={css.sliderTitleWrapper}>
        
                </div>
                <PanelExpertContent pageNumber={pageNumber} fadeIn={fadeIn} fadeOut={fadeOut} fadeDirection={fadeDirection} />
               
            </div>
        </div>
    );
};

export default PanelExpertPage;