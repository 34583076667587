import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './AuthForm.css';

const RESET_TIMEOUT = 800;

class AuthFormComponent extends Component {
  constructor(props) {
    super(props);
    this.resetTimeoutId = null;
    this.submittedValues = {};
  }
  componentWillUnmount() {
    window.clearTimeout(this.resetTimeoutId);
  }
  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            currentUser,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            pristine,
            ready,
            form,
            values,
          } = fieldRenderProps;
          const user = ensureCurrentUser(currentUser);
          if (!user.id) {
            return null;
          }
          // password
          const passwordLabel = intl.formatMessage({
            id: 'AuthForm.passwordLabel',
          });
          const passwordPlaceholder = intl.formatMessage({
            id: 'AuthForm.passwordPlaceholder',
          });

          const passwordRequired = validators.requiredStringNoTrim();

          const confirmClasses = classNames(css.confirmChangesSection, {
            [css.confirmChangesSectionVisible]: !pristine,
          });


          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || inProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e)
                  .then(() => {
                    this.resetTimeoutId = window.setTimeout(form.reset, RESET_TIMEOUT);
                  })
                  .catch(() => {
                    // Error is handled in duck file already.
                  });
              }}
            >

              <div className={confirmClasses}>
                <FieldTextInput
                  className={css.password}
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={validators.composeValidators(
                    passwordRequired,
                  )}
                />
              </div>

              <div className={css.bottomWrapper}>
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={ready}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="AuthForm.submit" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

AuthFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  changePasswordError: null,
  inProgress: false,
  formId: null,
};

const { bool, string } = PropTypes;

AuthFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  changePasswordError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  formId: string,
};

const AuthForm = compose(injectIntl)(AuthFormComponent);
AuthForm.displayName = 'AuthForm';

export default AuthForm;
