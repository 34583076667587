import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../../components';

import css from './SectionImages.css';

const SectionImages = props => {
  const {
    title,
    ticket,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const imagesCount = ticket?.images.length;
  const hasImages = imagesCount > 0;
  // if (!hasImages) {
  //   return null;
  // }
  const firstImage = hasImages ? ticket.images[0] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel

  const viewPhotosButton = hasImages ? (
    <div className={css.viewPhotos}>
      <FormattedMessage
        id="TicketModal.viewImagesButton"
        values={{ count: imagesCount }}
      />
    </div>
  ) : null;

  return (
    <div className={css.sectionImages}>
      <p className={css.attachmentsTitle}>
        <FormattedMessage id="TicketModal.attachmentsTitle" />
      </p>
      {firstImage ? (
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
            <div className={css.singleImageContainer}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={[
                  'landscape-crop',
                  'landscape-crop2x',
                  'landscape-crop4x',
                  'landscape-crop6x',
                ]}
              />
            </div>
            {/* <div className={css.singleImageContainer}>
              <FormattedMessage id="TicketModal.imagesCount" values={{count: imagesCount - 1}}/>
            </div> */}
            {viewPhotosButton}
          </div>
        </div>
      ) 
      : <p className={css.noAttachments}><FormattedMessage id="TicketModal.noAttachments" /></p>
      }
      
      <Modal
        id="TicketModal.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={ticket.images} />
      </Modal>
    </div>);
};

export default SectionImages;
