import React from 'react';
import { BiPlus } from 'react-icons/bi';
import { TiMessages, TiAttachment } from 'react-icons/ti';
import { FormattedMessage } from '../../util/reactIntl';
import Chip from '../../components/Chip/Chip';
import { richText } from '../../util/richText';

import { Avatar, ProgressBar } from '../../components'
import { Tooltip } from '@material-ui/core';
import { parseTime } from '../../util/helpers'
import css from './ProjectBoardPage.css';

const SectionTickets = props => {
  const {
    tickets,

    isSolved,
    onSetOpenTicket,
  
    setEdit,
  } = props;

  const ticketsAuthors = tickets?.included?.filter(element => element.type === 'user');
  const ticketsAuthorsAvatars = tickets?.included?.filter(element => element.type === 'image');

  const ticketSection = (type) => {
    const sectionTickets = tickets?.data.filter(ticket => ticket.attributes.publicData.state === type);
    const sectionCount = sectionTickets?.length;
    const sectionTitle = (
      <div className={css.sectionTitle}>
        <FormattedMessage id={`ProjectBoardPage.${type}SectionTitle`} values={{ count: sectionCount }} />
      </div>
    );

    const createNewTicket = () => {
      setEdit(true) 
      onSetOpenTicket('00000000-0000-0000-0000-000000000000')
    }

    return (
      <div className={css.singleTicketSection}>
        {sectionTitle}
        {sectionTickets?.map(ticket => {
          const ticketAuthorId = ticket.relationships.author.data.id.uuid;
          const ticketAuthor = ticketsAuthors?.find(user => user.id.uuid === ticketAuthorId);
          const ticketAuthorImgId = ticketAuthor?.relationships?.profileImage?.data?.id.uuid;
          const ticketAuthorAvatar = ticketsAuthorsAvatars?.find(
            img => img.id.uuid === ticketAuthorImgId
          );
          const ticketAuthorAvatarUrl =
            ticketAuthorAvatar?.attributes.variants['square-small'].url;
          const ticketAnswers = ticket.attributes.publicData.answers;
          const estimationHours = ticket.attributes.publicData.estimationHours;
          const completionHours = ticket.attributes.publicData.completionHours ?? 0;

          let answersCount = 0;
          ticketAnswers &&
            ticketAnswers.forEach(answer => {
              if (answer.solution) {
                answersCount++;
              }
            });

          let attachmentsCount = ticket.relationships.images?.data?.length || 0;

          return (
            <div
              onClick={() => onSetOpenTicket(ticket.id.uuid)}
              className={css.singleTicketWrapper}
              key={ticket.id.uuid}
            >
              {/* <NamedLink
                          className={css.singleSectionContent}
                          name={ticketPathName}
                          params={ticketPathParams}
                          to={{
                            search: stringify({
                              id: ticket.id.uuid,
                              status:
                                ticket?.attributes.state !== 'published'
                                  ? ticket?.attributes.state
                                  : null,
                            }),
                          }}
                        > */}
              <div className={css.ticket}>
                <Tooltip title={ticket.attributes.title}>
                  <span className={css.ticketTitle}>{ticket.attributes.title}</span>
                </Tooltip>

                <span className={css.priority}>{/*ticket.attributes.publicData.priority*/
                  <Chip className={css[`priority${ticket.attributes.publicData.priority}`]}>
                    <span>
                      {richText(ticket.attributes.publicData.priority, {
                        longWordMinLength: 20,
                      })}
                    </span>
                  </Chip>}
                </span>
                {completionHours ? (
                  <>
                    <div className={css.ticketProgressInfo}>
                      <span className={css.progressBar}>
                        <ProgressBar
                          currentValue={parseTime(completionHours)}
                          maxValue={
                            estimationHours === undefined
                              ? parseTime(completionHours)
                              : parseTime(estimationHours)
                          }
                        />
                      </span>
                      <span className={css.hours}>{`${completionHours}`}</span>
                    </div>
                  </>
                ) : null}
                <div className={css.ticketDetails}>
                  <div>{ticket.attributes.createdAt.toString().slice(4, 10)}</div>
                  <div>
                    <TiMessages className={css.buttonIcon} />
                    {answersCount}
                  </div>
                  <div>
                    <TiAttachment className={css.buttonIcon} />
                    {attachmentsCount}
                  </div>
                  {ticketAuthorAvatarUrl ? (
                    <Avatar
                      onlyImgUrl={ticketAuthorAvatarUrl}
                      className={css.ticketAuthorAvatar}
                      initialsClassName={css.initialsDesktop}
                      disableProfileLink
                    />
                  ) : ticketAuthor ? (
                    <Avatar
                      title="bezprofilowki"
                      user={ticketAuthor}
                      className={css.ticketAuthorAvatar}
                      initialsClassName={css.initialsDesktop}
                      disableProfileLink
                    />
                  ) : null}
                </div>
              </div>
              {/* </NamedLink> */}
            </div>
          );
        })}

        {!isSolved ? (
          <div
            onClick={() =>  createNewTicket() }
            className={css.singleSectionContent}
          >
            <div className={css.addTicket}>
              <span className={css.plusIcon}>
                <BiPlus />
              </span>
              <span>
                <FormattedMessage
                  id={
                    sectionTickets?.length > 0
                      ? 'ProjectBoardPage.addAnotherTicket'
                      : 'ProjectBoardPage.addTicket'
                  }
                />
              </span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className={css.ticketSection}>
      {ticketSection('open')}
      {ticketSection('inProgress')}
      {ticketSection('closed')}
    </div>
  )
};

export default SectionTickets;
