/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import { default as languages } from './translations/languages.json';

const formattedLanguages = Object.entries(languages).map(language => {
  return {
    key: language[0],
    label: `${language[1].name} (${language[1].nativeName})`,
  };
});

export const filters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      options: [
        { key: 'job', label: 'Job' },
        { key: 'expert', label: 'Expert' },
      ],
    },
  },
  {
    id: 'experience',
    label: 'Experience',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_experience'],
    config: {
      searchMode: 'has_all',
      options: [
        { key: 'below 1 year', label: '<1 year' },
        { key: '1 to 2 years', label: '1-2 years' },
        { key: '3 to 5 years', label: '3-5 years' },
        { key: '6 to 10 years', label: '6-10 years' },
        { key: 'above 10 years', label: '>10 years' },
      ],
    },
  },
  {
    id: 'projectsCount',
    label: 'projectsCount',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_projectsCount'],
    config: {
      options: [
        { key: 'below 5 projects', label: '<5 projects' },
        { key: '5 to 10 projects', label: '5-10 projects' },
        { key: '11 to 25 projects', label: '11-25 projects' },
        { key: '26 to 50 projects', label: '26-50 projects' },
        { key: 'above 50 projects', label: '>50 projects' },
      ],
    },
  },
];

const allSoftware = [
  { key: 'pipedrive', label: 'Pipedrive' },
  { key: 'zoho', label: 'Zoho' },
  { key: 'hubSpot', label: 'HubSpot' },
  { key: 'shopify', label: 'Shopify' },
  { key: 'magento', label: 'Magento' },
  { key: 'powerBI', label: 'Power BI' },
  { key: 'monday', label: 'Monday' },
  { key: 'livechat', label: 'LiveChat' },
  { key: 'woodpecker', label: 'Woodpecker' },
  { key: 'zapier', label: 'Zapier' },
  { key: 'salesforce', label: 'Salesforce' },
  { key: 'msDynamics', label: 'MS Dynamics' },
  { key: 'bigCommerce', label: 'BigCommerce' },
  { key: 'sap', label: 'SAP' },
  { key: 'office', label: 'MS Office' },
  { key: 'tableau', label: 'Tableau' },
  { key: 'googleAnalytics', label: 'Google Analytics' },
  { key: 'gsuite', label: 'G Suite' },
  { key: 'matomo', label: 'Matomo' },
  { key: 'piwik', label: 'Piwik' },
  { key: 'twilio', label: 'Twilio' },
  { key: 'klaviyo', label: 'Klaviyo' },
  { key: 'integromat', label: 'Integromat' },
  { key: 'autopilot', label: 'Autopilot' },
  { key: 'sharetribe', label: 'Sharetribe' },
  { key: 'webflow', label: 'Webflow' },
  { key: 'invision', label: 'Invision' },
  { key: 'adobexd', label: 'Adobe Xd' },
  { key: 'figma', label: 'Figma' },
  { key: 'wordpress', label: 'WordPress' },
  { key: 'googleAds', label: 'Google Ads' },
  { key: 'typeform', label: 'Typeform' },
  { key: 'airtable', label: 'Airtable' },
  { key: 'clickup', label: 'ClickUp' },
  // { key: 'nocrm', label: 'noCRM'},
  { key: 'paperform', label: 'Paperform' },
  // { key: 'hyperise', label: 'Hyperise'},
  { key: 'woocommerce', label: 'WooCommerce' },
  { key: 'prestashop', label: 'PrestaShop' },
  // { key: 'addicnet', label: 'Addicnet' },
  // { key: 'satismeter', label: 'Satismeter'},
  { key: 'vouchery', label: 'Vouchery' },
  { key: 'chatbot', label: 'ChatBot' },
  { key: 'ringOver', label: 'Ringover' },
  // { key: 'harvestr', label: 'Harvestr'},
  { key: 'mailchimp', label: 'MailChimp' },
  { key: 'livestorm', label: 'Livestorm' },
  { key: 'activeCampaign', label: 'ActiveCampaign' },
  { key: 'slack', label: 'Slack' },
  { key: 'sketch', label: 'Sketch' },
  { key: 'pandaDoc', label: 'PandaDoc' },
  { key: 'intercom', label: 'Intercom' },
  { key: 'facebookAds', label: 'Facebook Ads' },
  { key: 'notion', label: 'Notion' },
  { key: 'asana', label: 'Asana' },
  { key: 'atlassian', label: 'Atlassian' },
  { key: 'jira', label: 'Jira' },
  { key: 'aws', label: 'AWS' },
  { key: 'adobeIllustrator', label: 'Adobe Illustrator' },
  { key: 'adobePhotoshop', label: 'Adobe Photoshop' },
  { key: 'googleDocs', label: 'Google Docs' },
  { key: 'linkedInAds', label: 'LinkedIn Ads' },
  { key: 'microsoftAzure', label: 'Microsoft Azure' },
  { key: 'mautic', label: 'Mautic' },
  { key: 'marketo', label: 'Marketo' },
  { key: 'other', label: 'Other' },
];

const softwareKeys = allSoftware
  .map(soft => {
    return soft.key;
  })
  .sort();

export const softwares = softwareKeys.map(soft => {
  return {
    key: soft,
    label: allSoftware.find(s => s.key === soft).label,
  };
});

const allSkills = [
  { key: 'analytics', label: 'Analytics' },
  { key: 'api', label: 'API' },
  { key: 'automation', label: 'Automation' },
  { key: 'crm', label: 'CRM' },
  { key: 'database', label: 'Database' },
  { key: 'ecommerce', label: 'E-commerce' },
  { key: 'Email systems', label: 'Email systems' },
  { key: 'marketing-automation', label: 'Marketing automation' },
  { key: 'migration', label: 'Migration' },
  { key: 'no-code', label: 'No code' },
  { key: 'support', label: 'Support' },
  { key: 'webdesign', label: 'Web design' },
  { key: 'consulting', label: 'Consulting' },
  { key: 'implementation', label: 'Implementation' },
  { key: 'integration', label: 'Integration' },
  { key: 'training', label: 'Training' },
  { key: 'dataAnalytics', label: 'Data analytics' },
  { key: 'dataVisualisation', label: 'Data visualisation' },
  { key: 'websiteIntegration', label: 'Website integration' },
  { key: 'dataMigration', label: 'Data migration' },
  { key: 'onboarding', label: 'Onboarding' },
  { key: 'customAppDevelopment', label: 'Custom app development' },
  { key: '3rdPartyIntegrations', label: '3rd party integrations' },
  { key: 'visualForce', label: 'Visualforce' },
  { key: 'storeSetup', label: 'Store setup' },
  { key: 'theme', label: 'Theme' },
  { key: 'liquidDevelopment', label: 'Liquid development' },
  { key: 'pageSpeedOptimization', label: 'Page speed optimization' },
  { key: 'uxDesign', label: 'UX design' },
  { key: 'abDesign', label: 'A/B design' },
  { key: 'conversionRateOptimization', label: 'Conversion rate optimization' },
  { key: 'businessAnalysis', label: 'Business analysis' },
  { key: 'financialAnalysis', label: 'Financial analysis' },
  { key: 'dom', label: 'DOM' },
  { key: 'domainSetup', label: 'Domain setup' },
  { key: 'ui', label: 'UI' },
  { key: 'erp', label: 'ERP' },
  { key: 'callCenter', label: 'Call center' },
  { key: 'webApplication', label: 'Web application' },
  { key: 'Telephony systems', label: 'Telephony systems' },
  { key: 'Project management', label: 'Project management' },
  { key: 'Custom Development', label: 'Custom development' },
];

const skillKeys = allSkills
  .map(skill => {
    return skill.key;
  })
  .sort();

export const skills = skillKeys.map(skill => {
  return {
    key: skill,
    label: allSkills.find(s => s.key === skill).label,
  };
});

export const certificate = [
  { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
  { key: '200h', label: 'Registered yoga teacher 200h' },
  { key: '500h', label: 'Registered yoga teacher 500h' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 10000,
  step: 100,
};

export const projectsCountFilterConfig = {
  min: 0,
  max: 100,
  step: 5,
};

export const experienceFilterConfig = {
  min: 0,
  max: 50,
  step: 2,
};

export const paidFilterConfig = {
  min: 0,
  max: 600,
  step: 50,
};

export const estimatedTimeFilterConfig = {
  min: 1,
  max: 28,
  step: 1,
};

export const serviceCategoriesConfig = {
  active: true,
  options: [
    { key: 'CRM', label: 'CRM' },
    { key: 'Websites', label: 'Website' },
    { key: 'E-commerce', label: 'E-commerce store' },
    { key: 'Marketing', label: 'Marketing automation' },
    { key: 'Integration', label: 'Integration' },
    { key: 'Project management', label: 'Project management' },
    { key: 'Analytics', label: 'Analytics' },
    { key: 'Email systems', label: 'Email systems' },
    { key: 'Telephony systems', label: 'Telephony systems' },
    { key: 'Custom Development', label: 'Custom Development' },
    { key: 'Other', label: 'Other' },
  ],
};

export const languageConfig = {
  active: true,
  options: formattedLanguages,
};

export const scale = {
  active: true,
};

export const linkLinkedin = {
  active: true,
};

export const linkCalendly = {
  active: true,
};

export const linkWeb = {
  active: true,
};

export const linkJobDetails = {
  active: true,
};

export const portfolio = {
  active: true,
};

export const category = [
  { key: 'job', label: 'Job' },
  { key: 'expert', label: 'Expert' },
];

export const userId = {
  active: true,
};

export const referral = {
  active: true,
};

export const company = {
  active: true,
};

export const budget = {
  active: true,
  type: 'Money',
};

// Activate booking dates filter on search page
export const dateRangeLengthFilterConfig = {
  active: true,

  // A global time zone to use in availability searches. As listings
  // can be in various time zones, we must decide what time zone we
  // use in search when looking for available listings within a
  // certain time interval.
  //
  // If you have all/most listings in a certain time zone, change this
  // config value to that.
  //
  // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  searchTimeZone: 'Etc/UTC',

  // Options for the minimum duration of the booking
  options: [
    { key: '0', label: 'Any length' },
    { key: '60', label: '1 hour', shortLabel: '1h' },
    { key: '120', label: '2 hours', shortLabel: '2h' },
  ],
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest budget' },
    { key: 'price', label: 'Highest budget' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const priority = {
  active: true,
  options: [
    { key: 'low', label: 'Low - resolve within 1 week' },
    { key: 'medium', label: 'Medium - resolve within 72 hours' },
    { key: 'high', label: 'High - resolve within 24 hours' },
    { key: 'critical', label: 'Critical - resolve within 3 hours' },
  ],
};

export const currentCustomersMails = [
  'sunroof.se',
  'bm.pl',
  'directair.co.uk',
  'jm.pl',
  'futurecollars.com',
  'mobilexpense.com',
  'grenadehub.com',
  'autopay.pl',
];

export const scopeSuggestionGroups = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'crm',
    label: 'CRM',
  },
  {
    value: 'ecommerce',
    label: 'E-commerce',
  },
  {
    value: 'websiteBuilding',
    label: 'Website Building',
  },
  {
    value: 'marketingAutomation',
    label: 'Marketing Automation',
  },
  {
    value: 'integration',
    label: 'Integration',
  },
];

export const scopeSuggestions = {
  crm: [
    'CRM setup',
    'CRM audit and defining areas of improvement',
    'Defining initial CRM setup (pipelines, custom fields, stages, labels)',
    'Importing data to CRM',
    'Integrating CRM with lead sources (Google Ads, Web Form etc.)',
    'Integrating CRM with email',
    'Setting up reminders and alerts',
    'Setting up analytics and reports',
    'Offers automation',
    'Email automation',
    'Setting up products feature',
    'CRM team training',
    'Integration with calling system',
    'Integrating CRM with marketing tools (MailChimp, Woodpecker etc.)',
    'Integration with calendar',
    'Setting up user permissions and visibility settings',
    'Integration with communicators (Slack, Teams, etc.)',
    'Integration with digital signage tool',
    'Integration with e-commerce platform',
    'Integration with ERP',
    'Integration with invoicing tool',
  ],
  ecommerce: [
    'E-commerce store setup',
    'Domain connection',
    'UX design',
    'UI design',
    'Custom theme design',
    'Storefront integration',
    'Theme adjustment',
    'Liquid development',
    'Products’ upload',
    'Products’ sync',
    'Multi-currency display',
    'Multi-language setup',
    'Setting shipping rates',
    'Delivery options setup',
    'ERP integration',
    'API update',
    'Amazon integration',
    'Ebay integration',
    'Etsy integration',
    'Conversion optimization',
    'SEO optimization',
    'URL optimization',
    'Page speed improvement',
    'Filtering setup',
    'Image size reduction',
    'API requests optimization',
    'Google Analytics integration',
    'UX analytics integration',
    'Meta titles optimization',
    'Meta description optimization',
    'Shopify Flow automation',
    'Gift cards setup',
    'Checkout optimization',
    'Payment integration',
    'Product reviews setup',
    'Google Structured Data setup and integration',
    'CDN setup',
    'Customer service integration',
    'CRM integration',
    'Marketing automation',
    'Graphics design',
    'Social media integration',
    'Blog setup',
    'A/B testing',
    'Social proof setup',
    'Product versioning',
    'Automatic waybills generation',
    'On site pickup',
  ],
  websiteBuilding: [
    'Website building',
    'UI design',
    'Payments integration',
    'E-commerce setup',
    'Hosting setup',
    'Domain connection',
    'SEO optimization',
    'URL optimization',
    'Page speed improvement',
    'Image size reduction',
    'Google Analytics integration',
    'UX analytics integration',
    'Google Structured Data setup and integration',
    'Social media integration',
    'CDN setup',
    'Customer service integration',
    'CRM integration',
    'Marketing automation',
    'Graphics design',
    'Social media integration',
    'Blog setup',
    'A/B testing',
    'Social proof setup',
    'Meta titles optimization',
    'Meta description optimization',
    'ERP integration',
    'Domain migration',
    'Platform migration',
    'HTML edit',
    'CSS styling',
  ],
  marketingAutomation: [
    'Marketing software setup',
    'CRM integration',
    'SMS communication',
    'Email automation',
    'Marketing funnels setup',
    'Marketing analytics',
    'Website integration',
    'Mailing list sync',
    'Data enrichment',
    'Push notifications setup',
    'Site tracking',
    'Signup forms setup',
    'Lead scoring',
    'Bant lead scoring',
    'Miller Heiman lead qualification',
    'Attribution',
    'Goal tracking',
    'Automation testing',
    'Event tracking',
    'Auto response setup',
    'Ecommerce integration',
    'Audience segmentation',
    'Lead nurturing',
    'Email planning',
    'Landing pages setup',
    'Social media automation',
    'Link tracking',
    'Geotracking',
    'Image hosting',
    'Chat integration',
    'Dynamic forms setup',
    'Dynamic content setup',
  ],
  integration: [
    'Website integration',
    'ERP integration',
    'Data sync integration',
    'Marketing automation integration',
    'CRM integration',
    'Forms integration',
    'Social media integration',
    'Project management integration',
    'Email integration',
    'Payments integration',
    'Database integration',
    'Drive/storage integration',
    'Calling system integration',
    'Customer service integration',
    'Accounting integration',
    'Invoicing integration',
    'Time tracking integration',
    'Amazon integration',
    'SMS integration',
    'Cloud integration',
    'Cold mailing integration',
    'Database integration',
  ],
};

export const pricingFeatures = {
  services: [
    'Your certified expert',
    'Browse through predefined CRM, ecommerce, website and marketing automation packages',
    'Tailor the scope of the service to your needs',
    'Safe payment',
    'Request refund before you accept your project',
  ],
  task:[
    'Deposit for work',
    'Hire candidates or estimate project',
    '1h response time',
    'Weekly reporting',
    'Pay-as-you-go billing monthly',
    'Safe payment',
    '14-day trial period',
  ],
  starter: [
    'Deposit for work',
    'Hire candidates or estimate project',
    '1h response time',
    'Weekly reporting',
    'Pay-as-you-go monthly project billing',

    'Meeting and project estimate',
    'Safe payment',
    'Trial period',
  ],
  basic: [
    'Deposit for work',
    'Hire candidates or estimate project',
    '1h response time',
    'Weekly reporting',
    'Pay-as-you-go monthly project billing',

    'Meeting and project estimate',
    'Safe payment',
    'Trial period',
    'Training',
    'API access',
  ],
  support: [
    'Deposit for work',
    'Hire candidates or estimate project',
    '1h response time',
    'Weekly reporting',
    'Pay-as-you-go monthly project billing',

    'Meeting and project estimate',
    'Safe payment',
    'Trial period',
    'Training',
    'API access',
    'Team support',
    '10 team members',

    'Build on-demand team',
  ],
  project: [
    'Custom budget',
    'Hire candidates or estimate project',
    '1h response time',
    'Weekly reporting',
    'Pay-as-you-go monthly project billing',

    'Meeting and project estimate',
    'Safe payment',
    'Trial period',
    'Training',
    'API access',
    'Team support',
    'Unlimited team members',
    'Build on-demand team',
    'Dedicated Project Manager',

    'Quality monitoring',
  ],
};
