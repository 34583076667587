export const sortConversationByDate = (array, order = 'ASC') => {
  if(order === 'DESC') {
    array.sort(function(a,b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  } else if(order === 'ASC') {
    array.sort(function(a,b) {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
  }
  return array;
}