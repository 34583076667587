import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { RiHandCoinLine } from 'react-icons/ri';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import css from './ServicePage.css';

const SectionPriceTime = props => {
  const {
    formattedPrice,
    time, 
    isAdmin,
    isPremiumUser,
    isOwnListing,
  } = props; // formattedPrice, publicData.estimatedTime

  


  const formattedTime = time && time > 1 ? time + ' hours' : time ? time + ' hour' : undefined;
  if (isAdmin || isPremiumUser || isOwnListing) {
    return (
      <div className={css.sectionPriceTime}>
        {formattedPrice ? (
          <div className={css.priceTimeWrapper}>
            <RiHandCoinLine fill="none" className={css.priceTimeIcon} size={20} />
            <Tooltip title={<FormattedMessage id="ServicePage.netPriceInfo" />}>
              <p className={css.priceTimeHeading}>
                <FormattedMessage
                  id="ServicePage.servicePrice"
                  values={{ icon: <InfoOutlinedIcon style={{ fontSize: 14 }} /> }}
                />
              </p>
            </Tooltip>
            <p className={css.priceTimeValue}>
              {formattedPrice ? (
                formattedPrice
              ) : (
                <FormattedMessage id="ListingCard.unknownBudget" />
              )}
            </p>
          </div>
        ) : null}
        {formattedTime ? (
          <div className={css.priceTimeWrapper}>
            <AiOutlineClockCircle className={css.priceTimeIcon} size={20} />
            <p className={css.priceTimeHeading}>
              <FormattedMessage id="ServicePage.estimation" />
            </p>
            <p className={css.priceTimeValue}>{formattedTime}</p>
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default SectionPriceTime;
