/* eslint-disable */
import React from 'react';
import { Page, Footer, LayoutWrapperFooter, PrimaryButton } from '../../components';
import { TopbarContainer } from '../../containers';
import { useHistory } from 'react-router-dom';


const InfoToFormPage = () => {

  let history = useHistory()

  const goToForm = () => {
    history.push('/l/draft/00000000-0000-0000-0000-000000000000/new/project/quick')
  }

  return (
    <Page>
      <TopbarContainer />

        <button onClick={goToForm}>Zrob swoje pierwsze zgloszenie</button>

      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>

    </Page>
  );
}

export default InfoToFormPage;
