import React from 'react';
import { MapPin } from 'react-feather';
import { richText } from '../../util/richText';
import classNames from 'classnames';

import css from './ListingCard.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionLocationMaybe = props => {
  const { location } = props;
  const strArr = location && location.address ? location.address.split(", ") : null;
  const country = strArr ? strArr[strArr.length - 1] : null;
  const wrapperClassName = classNames(props.wrapperClassName);

  return country ? (
    <div className={wrapperClassName}>
      <MapPin className={css.mapIcon}/>
      <p className={css.location}>
        {richText(country, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
};

export default SectionLocationMaybe;