import React from 'react';
import css from './ProgressBarCircle.css';

const ProgressBarCircle = (props) => {
  const {
    currentValue,
    maxValue,
    segments = 10,
    margin = 0.1,
    width = 15,
    radius = 45,
    svgSize = 100,
  } = props;

  const numbersRatio = maxValue ? currentValue / maxValue : 0;

  let allSegments = [];

  const polarToCartesian = (x, y, r, degrees) => {
    const radians = degrees * Math.PI / 180.0;
    return [x + (r * Math.cos(radians)),
    y + (r * Math.sin(radians))]
  }

  const segmentPath = (x, y, r0, r1, d0, d1) => {
    const arc = Math.abs(d0 - d1) > 180 ? 1 : 0
    const point = (radius, degree) =>
      polarToCartesian(x, y, radius, degree)
        .map(n => n.toPrecision(5))
        .join(',')
    return [
      `M${point(r0, d0)}`,
      `A${r0},${r0},0,${arc},1,${point(r0, d1)}`,
      `L${point(r1, d1)}`,
      `A${r1},${r1},0,${arc},0,${point(r1, d0)}`,
      'Z',
    ].join('')
  }

  const segment = (n) => {
    const center = svgSize / 2;
    const degrees = 360 / segments;
    const start = degrees * n;
    const end = (degrees * (n + 1 - margin) + (margin === 0 ? 1 : 0));
    const path = segmentPath(center, center, radius, radius - width, start - 90, end - 90);
    // const fill = chroma.lch(80,150,start + degrees/2).alpha(1)
    const isFilledOut = end < numbersRatio * 360;
    return <path key={n} d={`${path}`} className={isFilledOut ? css.segmentFilledOut : css.segment} />
  }

  for (let i = 0; i < segments; i++) {
    allSegments.push(segment(i))
  }

  return (
    <div className={css.root}>
      <svg className={css.progressBarContainer} width={svgSize} height={svgSize}>
        {allSegments}
        <text className={css.textCircle} x="50%" y="50%" dy=".3em">
          {`${Math.round(numbersRatio * 100)}%`}
        </text>
      </svg>
    </div>
  );
};

export default ProgressBarCircle;
