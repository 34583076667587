import React from 'react';
import { BsFlag } from 'react-icons/bs';
import Chip from '../../components/Chip/Chip';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './TicketModal.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDeliveryDateMaybe = props => {
  const { priority } = props;
  return priority ? (
    <div className={css.sectionPriority}>
      <div>
        <BsFlag />
        <span className={css.priorityTitle}>
          <FormattedMessage id="TicketModal.priority" />
        </span>
      </div>
      <Chip className={css[`priority${priority}`]}>
        <span>
          {richText(priority, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
            longWordClass: css.longWord,
          })}
        </span>
      </Chip>
    </div>
  ) : null;
};

export default SectionDeliveryDateMaybe;
