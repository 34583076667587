import {
  inboxReadMessageSummary,
  inboxReadConversation,
  inboxSendMessage,
  inboxCreateGroupAddMembers,
  inboxSendDataMessage,
  getNotificationSummaryForUser,
  markNotificationAsOpened,
  sendNotification,
  getUserHasUnreadNotifications
} from '../api';

export default class InboxSDK {
  constructor(userId, userToken, counterpartId, conversationType) {
    this.userId = userId;
    this.userToken = userToken;
    this.counterpartId = counterpartId;
    this.conversationType = conversationType;
  }

  async readMessageSummary(pageNumber, howMany) {
    let messages = await inboxReadMessageSummary(this.userToken, {
      userId: this.userId, 
      pageNumber, 
      howMany
    });
    return new Promise((resolve, reject) => {
      resolve(messages);
    });
  }

  async readConversation(pageNumber, howMany) {
    let counterpartId = null;
    let groupId = null;
    if(this.conversationType === 'singular') {
      counterpartId = this.counterpartId;
    } else if(this.conversationType === 'group') {
      groupId = this.counterpartId;
    }
    let messages = await inboxReadConversation(this.userToken, {
      userId: this.userId, 
      counterpartId: counterpartId,
      groupId: groupId, 
      pageNumber, 
      howMany
    });
    return new Promise((resolve, reject) => {
      resolve(messages);
    });
  }

  async sendTextMessage(content) {
    return this.sendMessage('text', {content});
  }

  async sendDataMessage(files, filesCount, content) {
    return this.sendMessage('data', {files, filesCount, content});
  }

  async sendMessage(type, body) {
    let toUserId = null;
    let toGroupId = null;
    if(this.conversationType === 'singular') {
      toUserId = this.counterpartId;
    } else if(this.conversationType === 'group') {
      toGroupId = this.counterpartId;
    }
    let requestBody = {
      toUserId: toUserId,
      toGroupId: toGroupId,
      from: this.userId,
      content: body.content
    };
    if(type === 'text') {
      return await inboxSendMessage(this.userToken, requestBody);
    } else if(type === 'data') {
      requestBody['filesCount'] = body.filesCount;
      return await inboxSendDataMessage(this.userToken, body.files, requestBody);
    }
  }

  async createGroupAddMembers(members) {
    let result = await inboxCreateGroupAddMembers(this.userToken, {
      groupCreatorId: this.userId,
      members: [...members, this.userId]
    });
    return new Promise((resolve, reject) => {
      resolve(result);
    });
  }

  async readNotifications(pageNumber, howMany) {
    let result = await getNotificationSummaryForUser(this.userToken, {
      userId: this.userId,
      pageNumber,
      howMany
    });
    return result;
  }

  async userHasUnreadNotifications() {
    const result = await getUserHasUnreadNotifications(this.userToken, {userId: this.userId});
    return result.data;
  }

  async markNotificationAsOpened(notificationId) {
    let result = await markNotificationAsOpened(this.userToken, {
      notificationId: notificationId
    });
    return result;
  }

  async sendSingularMessageNotification(messageAuthorName) {
    await sendNotification(this.userToken, {
      toUserId: this.counterpartId, 
      from: this.userId, 
      title: `New message from ${messageAuthorName}`, 
      status: `New message`,
      redirectUrl: `\\conversation\\${this.userId}`, 
      type: `SINGULAR_MESSAGE`, 
      shouldDisappear: true, 
    });
  }

  async sendGroupMessageNotification(toUserId, groupName) {
    await sendNotification(this.userToken, {
      toUserId: toUserId, 
      from: this.userId, 
      title: `New message in group conversation (${groupName})`, 
      status: `New message`, 
      redirectUrl: `\\groupconversation\\${this.counterpartId}`, 
      type: `GROUP_MESSAGE ${this.counterpartId}`, 
      shouldDisappear: true, 
    });
  }
}