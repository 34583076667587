import React from 'react';
import classNames from 'classnames';
import {
  // AiOutlineMessage,
  // AiOutlineCalendar,
  // AiOutlineFundProjectionScreen,
  AiOutlineBell,
} from 'react-icons/ai';
import { RiSafe2Line } from 'react-icons/ri';
import { Tooltip, Grid } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import GavelIcon from '@material-ui/icons/Gavel';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { LISTING_PAGE_PENDING_APPROVAL_VARIANT } from '../../util/urlHelpers';
import { Modal, NamedLink, PrimaryButton, SecondaryButton } from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import freelancerSet from '../../components/GetStarted/media/jobs_pack_mobile.png';
import expertPack from './media/jobs_pack.png'
import acceptOffer from './media/clientmodal_accept.jpg';
import { createSlug } from '../../util/urlHelpers';
import css from './ClientPathModal.css';

const Step = props => {
  const { title, completed, current, position } = props;

  let pointStatus = null
  let lineStatus = null
  let lineAdd = null

  if (current) {
    pointStatus = css.pointCurrent
    lineStatus = css.lineStatusCurrent
    
  } else if (completed) {
    pointStatus = css.pointCompleted
    lineStatus = css.lineStatusComplete
  }


  if(position === 1){
    lineAdd = css.lineright
  } else if( position === 2){
    lineAdd = css.lineboth
  } else if(position === 3){
    lineAdd = css.lineleft
  }

  return (
    <div className={css.step}>
      <div className={classNames(css.pointedLine, lineAdd, lineStatus)}>
        <div className={classNames(css.point, pointStatus)} ><CheckIcon /></div>
      </div>
      <div className={classNames(css.stepTitle, current && css.stepTitleCurrent)}>{title}</div>
      {/* <div className={classNames(css.line, lineStatus)} /> */}

    </div>
  );
};

// This modal is used on 2 stages of client's path:
// 1. When user's project pending approval
// 2. When a deposit has been paid, but user hasn't receive any offers yet
const ClientPathModal = props => {
  const {
    id,
    step,
    listingId,
    isPendingApproval,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    listingInvoice,
    allExperts,
    softwareExperts,
    primarySoftware,
  } = props;

  // const expertsLink = allExperts + ' experts';

  // const softwareLink =
  //   // <NamedLink name="SearchPage_experts_software" params={{ software: primarySoftware }}>
  //   softwareExperts + (softwareExperts > 1 ? ' experts' : ' expert');
  // </NamedLink>;

  // const projectBoardPathName = isPendingApproval ? 'ProjectBoardPageVariant' : 'ProjectBoardPage';

  let projectBoardParams = step === 1 ? { id: listingId } : {};

  const isProjectClose = step === 1 ? true : false

  if (isPendingApproval) {
    projectBoardParams.variant = LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  }


  

  const notificationLink = (
    <NamedLink name="NotificationsPage" params={{}}>
      <AiOutlineBell className={css.bellIcon} />
      <FormattedMessage id="ClientPathModal.notifications" />
    </NamedLink>
  );

  // const depositMessage = listingInvoice ? (
  //   <p className={css.mainInfo}>
  //     <FormattedMessage id="ClientPathModal.depositWaiting" />
  //   </p>
  // ) : null;

  const onSubmitDeposit = () => {
    if (listingInvoice?.url) {
      window.open(listingInvoice.url);
    } else {
      const { history } = props;
      const path = createResourceLocatorString('SubscriptionPage', routeConfiguration(), {});
      history.push(path, {});
    }
  };

  const pmCallTrigger = () => {
    const { history } = props;
    history.push(
      createResourceLocatorString(
        'ExpertPage',
        routeConfiguration(),
        {
          id: process.env.REACT_APP_ADMIN_BOOKING_LISTING_ID,
          slug: createSlug('Michael'),
          category: 'experts',
        },
        {}
      ),
      { openBookModal: true }
    );
  };

  const depositButton = ( isPendingApproval || isProjectClose ) ? (
    <PrimaryButton onClick={onSubmitDeposit} className={css.bigButton}>
      <RiSafe2Line className={css.buttonIcon} />
      <FormattedMessage id="DepositModal.buttonText" />
      <Tooltip
        interactive
        title={<FormattedMessage id="ClientPathModal.depositInfo" values={{ br: <br /> }} />}
      >
        <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
      </Tooltip>
    </PrimaryButton>
  ) : null;

  const MessageBlock = () => {
    if(isPendingApproval || isProjectClose){
      return (
        <div className={css.modalMessage}>
          <p><FormattedMessage id="ClientPathModal.mainInfo_s1" /></p>
          <div className={css.showOnMobile}><img alt="" src={freelancerSet}></img></div>
          <p><FormattedMessage id="ClientPathModal.mainInfo_s2" /></p>
          <p><FormattedMessage id="ClientPathModal.mainInfo_s4" /></p>
          <p><FormattedMessage id="ClientPathModal.mainInfo_s3" /></p>
        </div>
        )
    } else if (step === 2){
      return (
        <div className={[css.modalMessage, css.modalMessageStyling].join(" ")}>
       <FormattedMessage
              id="GetStarted.step3Description"
              values={{ link: notificationLink, br: <br /> }}
            />
        </div>
        
      )
    } else {
      return null;
    }
  }

  // Blok z ikonami
  const iconBlock = (isPendingApproval || isProjectClose) ? (
      <>
        <div className={css.iconContainer}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div style={{ textAlign: "center", color: "#3c88f8" }}>
                        <HowToRegIcon style={{ fontSize: 40 }} />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{textAlign: "center"}}>
                      <span className={css.iconBold}>{primarySoftware === 'other' ? allExperts : softwareExperts }</span><br />
                      <span className={css.iconDesc}><FormattedMessage id="ClientPathModal.freelancersAvailable" /></span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div style={{ textAlign: "center", color: "#3c88f8" }}>
                        <GavelIcon style={{ fontSize: 40 }} />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{textAlign: "center"}}>
                      <span className={css.iconBold}><FormattedMessage id="ClientPathModal.legalCoverage" /></span><br />
                      <span className={css.iconDesc}><FormattedMessage id="ClientPathModal.forAllEngagements" /></span>
                    </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div style={{ textAlign: "center", color: "#3c88f8" }}>
                        <VerifiedUserIcon style={{ fontSize: 40 }} />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                    <div style={{textAlign: "center"}}>
                      <span className={css.iconBold}>100%</span><br />
                      <span className={css.iconDesc}><FormattedMessage id="ClientPathModal.safePaymentOnDelivery" /></span>
                    </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div style={{ textAlign: "center", color: "#3c88f8" }}>
                        <NewReleasesIcon style={{ fontSize: 40 }} />
                      </div>
                    </Grid>
                    <Grid item xs={12}><div style={{textAlign: "center"}}>

                      <span className={css.iconBold}>24 hours</span><br />
                      <span className={css.iconDesc}><FormattedMessage id="ClientPathModal.toWorkWithYourFreelancer" /></span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              </div>
            </>
  ) : null;

  const NotificationButton = (step === 2) ?(
    <NamedLink name="NotificationsPage" params={{}}>
      <PrimaryButton className={css.bigButton}>
        {/* <AiOutlineCalendar className={css.buttonIcon} /> */}
        <FormattedMessage id="GetStarted.step3CTA" />
      </PrimaryButton>
    </NamedLink>
  ) : null;

    // const SideImages = () => {
    //   if( isPendingApproval || isProjectClose ){
    //     return(
    //       <div className={[css.HideOnMobile, css.borderRight].join(" ")}>
    //       <Grid container>
    //         <Grid item xs={12}>
    //           <img className={css.ModalSideImage} src={expert1}></img>
    //         </Grid>
    //         <Grid item xs={6}>
    //           <img className={css.ModalSideImage} src={expert2}></img>
    //         </Grid>
    //         <Grid item xs={6}>
    //           <img src={expert3}></img>
    //         </Grid>
    //       </Grid>
    //     </div>
    //     )
    //   } else if(step == 2){
    //     return (
    //       <div className={[css.HideOnMobile, css.borderRight].join(" ")}>
    //          <img src={acceptOffer}></img>
    //     </div>
    //     )
    //   } else {
    //     return null;
    //   }
    // }

    const ModalTileBlock = () =>{
      if(isPendingApproval || isProjectClose){
        return (
          <h1 className={css.nModalTitle}>
          <FormattedMessage id="ClientPathModal.succeseCreate" /><br />
          <FormattedMessage id="ClientPathModal.OneMoreStep" /><br />
        </h1>
        )
      } else if( step === 2){
        return(
          <h1 className={css.nModalTitle}>
          <FormattedMessage id="ClientPathModal.title" /><br />
          <FormattedMessage id="ClientPathModal.title2" /><br />
        </h1>
        )

      } else {
        return null;
      }
    }

    const leftImage = () => {
      if(isPendingApproval || isProjectClose){
        return(
          <img alt="" src={expertPack}/>
        )
      } else if(step === 2){
        return(
          <img alt="" src={acceptOffer}/>
        )
      } else {
        return null;
      }
    }

  return (
    <>
      <Modal
        id={id}
        containerClassName={css.root}
        contentClassName={css.modalContent}
        isOpen={isOpen}
        onClose={onCloseModal}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >

        <Grid container>

          <Grid item xs={0} sm={0} lg={5}>

            <div className={[css.HideOnMobile, css.borderRight].join(" ")}>
              {leftImage()}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={7}>
            {ModalTileBlock()}          

            <div className={css.stepsContainer}>

              <Grid container>
                <Grid item xs={4}>
                  <Step
                    title={<FormattedMessage id="ClientPathModal.step1" />}
                    current={step === 0}
                    completed={step >= 0}
                    position={1}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Step
                    title={<FormattedMessage id="ClientPathModal.step2WithoutTooltip" />}
                    current={step === 1}
                    completed={step > 0}
                    position={2}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Step
                    title={<FormattedMessage id="ClientPathModal.step3" />}
                    current={step === 2}
                    completed={step > 1}
                    position={3}
                  />
                </Grid>
              </Grid>
            </div>

  { MessageBlock() }
  {iconBlock}
            
            <div className={css.modalContactSection}>
              <div className={css.modalMessage}>
                <SecondaryButton onClick={pmCallTrigger} className={css.bigButton}>
                  <FormattedMessage id="ClientPathModal.callButton" />
                </SecondaryButton>
              </div>
            </div>

            <div>
              {depositButton}
            </div>

            
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default injectIntl(ClientPathModal);
