import React, { Component } from 'react';
import { ResponsiveImage, NamedLink } from '../../../components';
import { lazyLoadWithDimensions } from '../../../util/contextHelpers';
import { ensureOwnListing } from '../../../util/data';
import css from './ExamListingCard.css';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });
// Render hints for responsive image
const panelWidth = 62.5;
const renderSizes = [
  `(max-width: 767px) 100vw`,
  `(max-width: 1920px) ${panelWidth / 2}vw`,
  `${panelWidth / 3}vw`,
].join(', ');

const ExamListingCard = (props) => {

  const listing = props.data;
  const currentListing = ensureOwnListing(listing);
  const examId = currentListing.id.uuid;
  const examTitle = currentListing.attributes.title;
  const examDescription = currentListing.attributes.publicData.description;
  const examSoftware = currentListing.attributes.publicData.software;

  return (
    <div className={css.examCardRoot}>
      <div className={css.firstColumnWrapper}>
        <div className={css.aspectWrapper}>
          <NamedLink name="ExamPage" params={{ id: examId }}>
            {/* without software prop provided will display default SoftwareSupp logo */}
            <LazyImage
              rootClassName={css.rootForImage}
              alt={examTitle}
              variants={['square-small']}
              sizes={renderSizes}
              software={examSoftware}
            />
          </NamedLink>
        </div>
      </div>
      <div className={css.secondColumnWrapper}>
        <div className={css.titleWrapper}>
        <NamedLink className={css.link} name="ExamPage" params={{ id: examId }}>
            {examTitle}
            </NamedLink>
        </div>
        <div className={css.descriptionWrapper}>
          {examDescription}
        </div>
      </div>
    </div>
  );
}


export default ExamListingCard;