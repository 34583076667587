import React, { useState } from 'react';
import { bool, func, object, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PARAM_TYPES,
  createSlug,
  getListingType,
} from '../../util/urlHelpers';
import { LISTING_STATE_DRAFT, propTypes, LISTING_CATEGORY_EXPERT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  EditExpertWizard,
  NamedRedirect,
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  EditPageSidebar,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
  requestAddAvailabilityException,
  requestDeleteAvailabilityException,
  requestCreateListingDraft,
  requestPublishListingDraft,
  requestUpdateListing,
  loadData,
} from './EditExpertPage.duck';

import css from './EditExpertPage.css';

// import { connectCalendarGoogle } from '../../util/api';

const { UUID } = sdkTypes;

// N.B. All the presentational content needs to be extracted to their own components
export const EditExpertPageComponent = props => {


  const {
    currentUser,
    currentUserHasExpertListing,
    getOwnListing,
    history,
    intl,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onCreateListingDraft,
    onPublishListingDraft,
    onUpdateListing,
    onManageDisableScrolling,
    page,
    params,
    scrollingDisabled,
    currentUserExpertListing,
    currentUserHasUnpublishedExpertListing,
    quickForm
  } = props;


  
  let tabs = [
    'description',
    'projects',
    'software',
    'experience',
    'pricing',
    'attachments'
  ];

  let fullList = [
    'skills',
    'industries',
    'links',
    'availability',
    'location',
    'contact',
  ]
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  
  if(!quickForm){
    tabs = [...tabs, ...fullList]
  }
  const handleClick = id => {
    let foundId = null;
    if (!id) {
      foundId = tabs.indexOf(currentTab) + 1;
    }
    window.scrollTo(0, 0);
    setCurrentTab(tabs[id ?? foundId]);
  };

  const { id, type, category, referralNumber } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const listingId =
    (page.submittedListingId?.uuid === id ? page.submittedListingId : null) ||
    (id ? new UUID(id) : null);
  const listing = getOwnListing(listingId);
  const currentListing = ensureOwnListing(listing);
  const { state: currentListingState, title } = currentListing.attributes;
  const currentCategory = currentListing.attributes.publicData
    ? currentListing.attributes.publicData.category
    : null;
  const listingCategory = props.location.state
    ? props.location.state.category
    : currentCategory
    ? currentCategory
    : category;
  const listingSlug = currentListingState ? createSlug(title) : 'draft';

  const showForm = isNewURI || currentListing.id;


  try{
    const search = window.location.search;
    const paramsUrl = new URLSearchParams(search);
    

    if (paramsUrl.get('code') !== null) {
      sessionStorage.setItem('google_code', paramsUrl.get('code'));
    }
   }catch(e){

   }

  if (
    (currentUserHasExpertListing || currentUserHasUnpublishedExpertListing) &&
    currentUserExpertListing.id.uuid !== currentListing?.id?.uuid
  ) {
    // If we allow only one listing per provider, we need to redirect to correct listing.
    return (
      <NamedRedirect
        name="EditListingPage"
        params={{
          id: currentUserExpertListing.id.uuid,
          slug: createSlug(currentUserExpertListing.attributes.title),
          type: LISTING_PAGE_PARAM_TYPE_EDIT,
          variant: currentUserExpertListing.attributes.state,
          tab: 'description',
          category: LISTING_CATEGORY_EXPERT,
        }}
        category={LISTING_CATEGORY_EXPERT}
      />
    );
  } else if (showForm) {
    const {
      createListingDraftError = null,
      publishListingError = null,
      updateListingError = null,
      showListingsError = null,
      fetchExceptionsError = null,
      addExceptionError = null,
      deleteExceptionError = null,
    } = page;
    const errors = {
      createListingDraftError,
      publishListingError,
      updateListingError,
      showListingsError,
      fetchExceptionsError,
      addExceptionError,
      deleteExceptionError,
    };
    // TODO: is this dead code? (shouldRedirect is checked before)
    const newListingPublished =
      isDraftURI && currentListing && currentListingState !== LISTING_STATE_DRAFT;

    // Show form if user is posting a new listing or editing existing one
    const disableForm = page.redirectToListing && !showListingsError;

    const title = isNewListingFlow
      ? intl.formatMessage({ id: 'EditExpertPage.titleCreateListing' })
      : intl.formatMessage({ id: 'EditExpertPage.titleEditListing' });

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={css.topbar}
              mobileRootClassName={css.mobileTopbar}
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav className={css.navigation}>
            <EditPageSidebar
              slug={listingSlug}
              id={listingId.uuid}
              type={getListingType(type)}
              tabArray={tabs}
              handleScroll={handleClick}
              currentTabIndex={tabs.indexOf(currentTab)}
              disabledPagesIndex={null}
            />
          </LayoutWrapperSideNav>
          <LayoutWrapperMain className={css.main}>
            <div className={css.container}>
              
              <EditExpertWizard
                id="EditListingWizard"
                className={css.wizard}
                params={params}
                disabled={disableForm}
                errors={errors}
                newListingPublished={newListingPublished}
                history={history}
                listing={currentListing}
                listingCategory={listingCategory}
                onAddAvailabilityException={onAddAvailabilityException}
                onDeleteAvailabilityException={onDeleteAvailabilityException}
                onUpdateListing={values => {
                  return onUpdateListing(values).then(response => {
                    return response;
                  });
                }}
                onCreateListingDraft={onCreateListingDraft}
                handlePublishListing={onPublishListingDraft}
                currentUser={currentUser}
                currentUserHasExpertListing={currentUserHasExpertListing}
                onManageDisableScrolling={onManageDisableScrolling}
                updatedTab={page.updatedTab}
                updateInProgress={page.updateInProgress || page.createListingDraftInProgress}
                fetchExceptionsInProgress={page.fetchExceptionsInProgress}
                availabilityExceptions={page.availabilityExceptions}
                referralNumber={referralNumber}
                type={type}
                currentTab={currentTab}
                onGoToNextTab={handleClick}
              />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  } else {
    // If user has come to this page through a direct linkto edit existing listing,
    // we need to load it first.
    const loadingPageMsg = {
      id: 'EditExpertPage.loadingListingData',
    };
    return (
      <Page title={intl.formatMessage(loadingPageMsg)} scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
        <div className={css.placeholderWhileLoading} />
      </Page>
    );
  }
};

EditExpertPageComponent.defaultProps = {
  currentUser: null,
  currentUserHasOrders: null,
  listing: null,
  listingDraft: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

EditExpertPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  getOwnListing: func.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onPublishListingDraft: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onUpdateListing: func.isRequired,
  page: object.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
  }).isRequired,
  scrollingDisabled: bool.isRequired,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const page = state.EditExpertPage;

  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasExpertListing,
    currentUserExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;

  const getOwnListing = id => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);

    return listings.length === 1 ? listings[0] : null;
  };
  return {
    currentUser,
    currentUserListing,
    currentUserHasExpertListing,
    currentUserListingFetched,
    getOwnListing,
    page,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserExpertListing,
    currentUserHasUnpublishedExpertListing,
  };
};

const mapDispatchToProps = dispatch => ({
  onAddAvailabilityException: params => dispatch(requestAddAvailabilityException(params)),
  onDeleteAvailabilityException: params => dispatch(requestDeleteAvailabilityException(params)),
  onUpdateListing: (tab, values) => dispatch(requestUpdateListing(tab, values)),
  onCreateListingDraft: (values, timeZone) => dispatch(requestCreateListingDraft(values, timeZone)),
  onPublishListingDraft: listingId => dispatch(requestPublishListingDraft(listingId)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EditExpertPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(EditExpertPageComponent));

EditExpertPage.loadData = loadData;

export default EditExpertPage;
