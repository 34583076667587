import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Tooltip } from '@material-ui/core';
import { Reviews } from '../../components';

import css from '../../components/ExpertOverview/ExpertPage.css';

const SectionReviews = props => {
  const { reviews, voteButton, fetchReviewsError, upvotesCounter, voteButtonErrorMessage } = props;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ExpertPage.reviewsError" />
    </h2>
  );

  return (
    <>
    {upvotesCounter > 0 || reviews?.length > 0 ? 
    <div id="recommendations" >
      <h2 className={css.reviewsHeading}>
        <FormattedMessage id="ExpertPage.reviewsHeading" values={{ count: reviews.length + upvotesCounter }} />
        <Tooltip title={<FormattedMessage id="ExpertPage.sendRecommendationExplanation" />} >
          {voteButton}
        </Tooltip>
        {voteButtonErrorMessage !== "" &&
          <div className={css.plusButtonErrorMessage}>
            <FormattedMessage id={voteButtonErrorMessage} />
          </div>
        }
      </h2>
      {fetchReviewsError ? reviewsError : null}

      <div className={css.sectionReviews}>
        {reviews?.length > 0 && <Reviews reviews={reviews} />}
        
      </div>
    </div>
    : null}
    </>
  );
};

export default SectionReviews;
