import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import css from './SectionPriceTimeMaybe.css';

const SectionPriceTimeMaybe = props => {
  const {
    estimatedPrice,
    estimatedTime,
    isAdminOffer,
    isFirstPosition,
    estimatedPriceRange,
    estimatedTimeRange,
    hourlyRateExpert,
    negotiationReaderRole,
  } = props;
  const displayedPrice =
    isAdminOffer && isFirstPosition
      ? estimatedPriceRange
      : negotiationReaderRole === 'expert'
      ? '$ ' + estimatedPrice
      : '$ ' + estimatedPrice;
  const displayedTime =
    isAdminOffer && isFirstPosition ? estimatedTimeRange : estimatedTime + ' hours';
  const sectionTitlePrice = 'TransactionPanel.sectionPrice';
  const sectionTitleTime = `TransactionPanel.sectionTime`;
  const shouldDisplaySection = !!estimatedPrice || !!estimatedTime;

  return shouldDisplaySection ? (
    <div className={css.mainWrapper}>
      {!!estimatedPrice && (
        <div className={css.priceWrapper}>
          <Tooltip title={<FormattedMessage id="TransactionPanel.netPriceInfo" />}>
            <h2 className={css.title}>
              <FormattedMessage
                id={sectionTitlePrice}
                values={{ icon: <InfoOutlinedIcon style={{ fontSize: 16 }} /> }}
              />
            </h2>
          </Tooltip>

          <div className={css.contentContainer}>{displayedPrice}</div>
        </div>
      )}
      {!!estimatedTime && (
        <div className={css.timeWrapper}>
          <h2 className={css.title}>
            <FormattedMessage id={sectionTitleTime} />
          </h2>
          <div className={css.contentContainer}>{displayedTime}</div>
        </div>
      )}
      {hourlyRateExpert !== 'Not specified' && (
        <div className={css.priceWrapper}>
          
            <h2 className={css.title}>
              <FormattedMessage
                id={"TransactionPanel.expertPrice"}
                
              />
            </h2>


          <div className={css.contentContainer}>{hourlyRateExpert}</div>
        </div>
      )}
    </div>
  ) : null;
};

export default SectionPriceTimeMaybe;
