import React, { useState } from 'react';
import classNames from 'classnames';
import { fadeInRight } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, PrimaryButton } from '..';

import css from './GetStarted.css';

// images for slides
import project from './media/project.png';
import hourlyPlan from './media/hourlyPlan.png';
import service from './media/service.png';
import board from './media/board.png';
import { AiOutlineBell } from 'react-icons/ai';
import billing from './media/billing.png';
const animationStyles = {
  fadeInRight: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeInRight, 'fadeInRight'),
  },
};

const GetStarted = props => {
  const { initialStep } = props;

  const [step, setStep] = useState(initialStep ? initialStep : 1);
  const notificationLink = (
    <NamedLink name="NotificationsPage" params={{}}>
      <AiOutlineBell className={css.bellIcon} />
      <FormattedMessage id="ClientPathModal.notifications" />
    </NamedLink>
  );
  return (
    <div className={css.container}>
      <div className={css.switchButtonsContainer}>
        <FormattedMessage id="GetStarted.steps" />
        <div
          className={classNames(css.switchButton, step === 1 && css.switchButtonChecked)}
          onClick={() => setStep(1)}
        >
          <FormattedMessage id="GetStarted.switchButtons1" />
        </div>
        <div
          className={classNames(css.switchButton, step === 2 && css.switchButtonChecked)}
          onClick={() => setStep(2)}
        >
          <FormattedMessage id="GetStarted.switchButtons2" />
        </div>
        <div
          className={classNames(css.switchButton, step === 3 && css.switchButtonChecked)}
          onClick={() => setStep(3)}
        >
          <FormattedMessage id="GetStarted.switchButtons3" />
        </div>
        <div
          className={classNames(css.switchButton, step === 4 && css.switchButtonChecked)}
          onClick={() => setStep(4)}
        >
          <FormattedMessage id="GetStarted.switchButtons4" />
        </div>
        <FormattedMessage id="GetStarted.questions" />
        <div
          className={classNames(css.switchButton, step === 5 && css.switchButtonChecked)}
          onClick={() => setStep(5)}
        >
          <FormattedMessage id="GetStarted.switchButtons5" />
        </div>
      </div>
      <div className={css.content}>
        {step === 1 ? (
          <StyleRoot>
            <div style={animationStyles.fadeInRight}>
              <h2 className={css.stepTitle}>
                <FormattedMessage id="GetStarted.step1Title" />
              </h2>
              <p className={css.ticketsDescription}>
                <FormattedMessage id="GetStarted.step1Description" />
              </p>
              <div className={css.imageSliderContainer}>
                <img src={project} width="100%" alt="Posting project" />
              </div>
              <div className={css.ctaButtonContainer}>
                <NamedLink name="NewListingPage" className={css.namedLinkButton}>
                  <PrimaryButton className={css.ctaButton}>
                    <FormattedMessage id="GetStarted.step1CTA" />
                  </PrimaryButton>
                </NamedLink>
              </div>
            </div>
          </StyleRoot>
        ) : null}
        {step === 2 ? (
          <StyleRoot>
            <div style={animationStyles.fadeInRight}>
              <h2 className={css.stepTitle}>
                <FormattedMessage id="GetStarted.step2Title" />
              </h2>
              <p className={css.ticketsDescription}>
                <FormattedMessage id="GetStarted.step2Description" values={{ br: <br /> }} />
              </p>
              <div className={css.imageSliderContainer}>
                <img src={hourlyPlan} width="100%" alt="Support plan" />
              </div>
              <div className={css.ctaButtonContainer}>
                <NamedLink name="SubscriptionPage" className={css.namedLinkButton}>
                  <PrimaryButton name="SubscriptionPage" className={css.ctaButton}>
                    <FormattedMessage id="GetStarted.step2CTA" />
                  </PrimaryButton>
                </NamedLink>
              </div>
            </div>
          </StyleRoot>
        ) : null}
        {step === 3 ? (
          <StyleRoot>
            <div style={animationStyles.fadeInRight}>
              <h2 className={css.stepTitle}>
                <FormattedMessage id="GetStarted.step3Title" />
              </h2>
              <p className={css.ticketsDescription}>
                <FormattedMessage
                  id="GetStarted.step3Description"
                  values={{ br: <br />, link: notificationLink }}
                />
              </p>
              <div className={css.imageSliderContainer}>
                <img src={service} width="100%" alt="Service" />
              </div>

              <div className={css.ctaButtonContainer}>
                <NamedLink name="NotificationsPage" className={css.namedLinkButton}>
                  <PrimaryButton className={css.ctaButton}>
                    <FormattedMessage id="GetStarted.step3CTA" />
                  </PrimaryButton>
                </NamedLink>
              </div>
            </div>
          </StyleRoot>
        ) : null}
        {step === 4 ? (
          <StyleRoot>
            <div style={animationStyles.fadeInRight}>
              <h2 className={css.stepTitle}>
                <FormattedMessage id="GetStarted.step4Title" />
              </h2>
              <p className={css.ticketsDescription}>
                <FormattedMessage id="GetStarted.step4Description" values={{ br: <br /> }} />
              </p>
              <div className={css.imageSliderContainer}>
                <img src={board} width="100%" alt="Board" />
              </div>

              {/* <div className={css.ctaButtonContainer}>
                  <NamedLink name="NotificationsPage" className={css.namedLinkButton}>
                    <PrimaryButton className={css.ctaButton}>
                      <FormattedMessage id="GetStarted.step4CTA" />
                    </PrimaryButton>
                  </NamedLink>
                </div> */}
            </div>
          </StyleRoot>
        ) : null}

        {step === 5 ? (
          <StyleRoot>
            <div style={animationStyles.fadeInRight}>
              <h2 className={css.stepTitle}>
                <FormattedMessage id="GetStarted.step5Title" />
              </h2>
              <p>Pay-as-you-go billing</p>
              <p className={css.ticketsDescription}>
                <FormattedMessage id="GetStarted.step5DescriptionPayAsYouGo" values={{ br: <br /> }} />
              </p>
              <p>Billing rules</p>
              <p className={css.ticketsDescription}>
                <FormattedMessage id="GetStarted.step5DescriptionRenewal" values={{ br: <br /> }} />
              </p>
              <p>Upgrade rules</p>
              <p className={css.ticketsDescription}>
                <FormattedMessage id="GetStarted.step5DescriptionUpgrade" values={{ br: <br /> }} />
              </p>
              <p>Termination rules</p>
              <p className={css.ticketsDescription}>
                <FormattedMessage
                  id="GetStarted.step5DescriptionTermination"
                  values={{ br: <br /> }}
                />
              </p>
              <p>Refund policy</p>
              <p className={css.ticketsDescription}>
                <FormattedMessage id="GetStarted.step5DescriptionRefund" values={{ br: <br /> }} />
              </p>
              <div className={css.imageSliderContainer}>
                <img src={billing} width="100%" alt="Board" />
              </div>

              {/* <div className={css.ctaButtonContainer}>
                  <NamedLink name="NotificationsPage" className={css.namedLinkButton}>
                    <PrimaryButton className={css.ctaButton}>
                      <FormattedMessage id="GetStarted.step4CTA" />
                    </PrimaryButton>
                  </NamedLink>
                </div> */}
            </div>
          </StyleRoot>
        ) : null}
      </div>
    </div>
  );
};

export default GetStarted;
