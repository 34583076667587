import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { injectIntl } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Sidebar,
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  Footer,
  PaginationLinks,
  Modal,
  SecondaryButton
} from '../../components';
import InboxSDK from '../../util/inbox/InboxSDK';
import { TopbarContainer } from '../../containers';
import { NotificationItem } from './NotificationItem';
import { fetchCurrentUserHasUnreadEntities, fetchCurrentUser } from '../../ducks/user.duck';
import { sendSoftwareSuppInviteMail } from '../../util/triggerMail';
import { InviteUserForm } from '../../forms';
import css from './NotificationsPage.css';
const HOW_MANY_PER_PAGE = 8;

export const NotificationsPageComponent = props => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    intl,
    scrollingDisabled,
    history,
    params,
    location,
    onFetchCurrentUser,
    onFetchUserHasUnreadNotifications,
    onManageDisableScrolling
  } = props;

  const { search } = location;

  const page = search ? parse(search).page : 0;

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
  const [notifications, setNotifications] = useState();
  const [inbox, setInbox] = useState();
  const [readingNotifications, setReadingNotifications] = useState(false)
  const [inviteOpen, setInviteOpen] = useState(false);
  const [successfullyInvited, setSuccessfullyInvited] = useState(false);
  const pagination = {
    totalItems: totalNotificationsCount,
    totalPages: !!totalNotificationsCount && Math.ceil(totalNotificationsCount / HOW_MANY_PER_PAGE),
    page: page === 0 ? 1 : page,
    perPage: HOW_MANY_PER_PAGE
  }

  useEffect(() => {
    if (currentUser && !currentUser.attributes.profile?.privateData?.inboxToken) {
      onFetchCurrentUser()
    }
    if (!!currentUser && !!currentUser.attributes.profile?.privateData?.inboxToken) {
        let userId = currentUser.id.uuid;
        let userToken = currentUser.attributes.profile.privateData.inboxToken;
        let inboxSdk = new InboxSDK(userId, userToken);
        setInbox(inboxSdk);
        readNotifications(inboxSdk, page > 0 ? page - 1 : page);
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, page]);

  const readNotifications = async (inboxSdk, pageNumber) => {
    setReadingNotifications(true)
    const notifications = await inboxSdk.readNotifications(pageNumber, HOW_MANY_PER_PAGE);
    setTotalNotificationsCount(notifications?.data.totalItems);
    setNotifications(notifications?.data.notifications);
    setReadingNotifications(false);
  }

  const linkOpenedHandler = async (wasOpened, notificationId, redirectUrl) => {
    if (!wasOpened) {
      inbox.markNotificationAsOpened(notificationId).then(() => {
        const userToken = currentUser.attributes.profile.privateData.inboxToken;
        onFetchUserHasUnreadNotifications(userToken, currentUser.id.uuid).then(() =>
          history.push(redirectUrl.replace(/\\/g, "/"))
        )
      })
        .catch(e =>
          console.error(e)
        )
    }
    else {
      history.push(redirectUrl.replace(/\\/g, "/"))
    }
  }

  const parseNotifications = (notifications) => {
    return notifications;
  }

  const title = intl.formatMessage({ id: 'NotificationsPage.pageTitle' });

  const renderNotifications = (notifications) => {
    return notifications.map((notification, index) => {
      return renderNotificationItem(notification, index);
    });
  }

  const renderNotificationItem = (item, index) => {
    if (item?.counterpartProfile !== null) {
      return (
        <li key={item.createdAt + index} className={css.listItem}>
          <NotificationItem data={item} linkOpenedHandler={linkOpenedHandler} />
        </li>
      );
    }
    return null
    
  }
    const onSubmitInvite = async (values, form) => {
      setSuccessfullyInvited(false);
      setTimeout(form.reset);
      sendSoftwareSuppInviteMail(values.allowedUserEmail);
      setSuccessfullyInvited(true);
    };
  // const error = false ? (
  //   <p className={css.error}>
  //     <FormattedMessage id="InboxPage.fetchFailed" />
  //   </p>
  // ) : null;

  const isAdmin = currentUser && currentUser.id && currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="NotificationsPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav
          className={classNames(css.navigation, !sidebarVisible ? css.navigationHidden : null)}
        >
          <Sidebar
            tab={'notifications'}
            isAdmin={isAdmin}
            isExpert={currentUserHasExpertListing}
            isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
            isVisible={sidebarVisible}
            setVisibility={setSidebarVisible}
          />
          <SecondaryButton className={css.inviteButton} onClick={() => setInviteOpen(true)}>
            <FormattedMessage id="ManageListingsPage.inviteUsersToSoftwareSupp" />
          </SecondaryButton>
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
          {/* {error} */}
          <div className={css.messagesPageWrapper}>
            {notifications?.length > 0 && !readingNotifications ? (
              <div>
                <ul>{renderNotifications(parseNotifications(notifications))}</ul>
              </div>
            ) : notifications?.length === 0 && !readingNotifications ? (
              <div className={css.centeredLoadingMessage}>
                <div>You don't have any notifications right now.</div>
              </div>
            ) : (
              <div className={css.centeredLoadingMessage}>
                <div>Loading notifications...</div>
              </div>
            )}
            {totalNotificationsCount > HOW_MANY_PER_PAGE && (
              <PaginationLinks
                className={css.pagination}
                pageName="NotificationsPage"
                pagePathParams={params}
                pagination={pagination}
              />
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      <Modal
        id="InviteUserModal"
        contentClassName={css.enquiryModalContent}
        isOpen={inviteOpen}
        onClose={() => {
          setSuccessfullyInvited(false);
          setInviteOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <InviteUserForm
          id="InviteUserForm"
          onSubmit={onSubmitInvite}
        
          userSuccessfullyInvited={successfullyInvited}
          isAdmin={isAdmin}
          clearInvitation={() => setSuccessfullyInvited(false)}
        ></InviteUserForm>
      </Modal>
    </Page>
  );
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
  } = state.user;
  return {
    currentUser,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchUserHasUnreadNotifications: (token, id) =>
    dispatch(fetchCurrentUserHasUnreadEntities(token, id)),
  onFetchCurrentUser: () => dispatch(fetchCurrentUser()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const NotificationsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(NotificationsPageComponent);

export default NotificationsPage;