/* eslint-disable */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { ArrowLeft } from 'react-feather';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { AiOutlineStock, AiFillPlusCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ExpertModal, SecondaryButton, Modal } from '../../components';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperSideNav,
  LayoutWrapperMain,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  Footer,
  BookingTimeModal,
  InlineTextButton,
  ReviewModal,
  Sidebar,
} from '../../components';
import { TopbarContainer, AuthenticationPage } from '..';
import {
  loadData,
  setInitialValues,
  fetchTimeSlots,
  requestBooking,
  sendRecommendation,
} from './ExpertPage.duck';
import SectionAvatar from '../../components/ExpertOverview/SectionAvatar';

import { Container, Row, Col } from 'react-bootstrap';
import SectionExamResultsMaybe from '../../components/ExpertOverview/SectionExamResultsMaybe';
import Navbar from 'react-bootstrap/Navbar';
import SectionTagsMaybe from '../../components/ExpertOverview/SectionTagsMaybe';
import SectionLanguagesMaybe from './SectionLanguagesMaybe';
import SectionVerifiedSoftwaresMaybe from '../../components/ExpertOverview/SectionVerifiedSoftwaresMaybe';
import SectionDescriptionMaybe from '../../components/ExpertOverview/SectionDescriptionMaybe';
import SectionExperienceAndProjectsMaybe from '../../components/ExpertOverview/SectionExperienceAndProjectsMaybe';
import SectionExpertiseAndNameMaybe from '../../components/ExpertOverview/SectionExpertiseAndNameMaybe';
import SectionLinksMaybe from '../../components/ExpertOverview/SectionLinksMaybe';
import SectionReviews from './SectionReviews';
import SectionMapMaybe from './SectionMapMaybe';
import AttachmentPanel from './AttachmentPanel';
import SectionExpertServicesMaybe from './SectionExpertServicesMaybe';
import SectionProjects from './SectionProjects';
import SectionExperience from './SectionExperience';
import SectionShareMaybe from '../../components/SectionShareMaybe/SectionShareMaybe';
import VendorVerificationPanel from '../../components/ExpertOverview/VendorVerificationPanel';
import SideActionBarMaybe from './SideActionBarMaybe';
import css from '../../components/ExpertOverview/ExpertPage.css';
import css2 from './ExpertPage.css';
import { sendUpvoteToExpert, queryAllowedUsers, connectCalendarGoogle } from '../../util/api';
import {
  newMeetingSystemEmail,
  newMeetingEmail,
  newUpvoteNotification,
} from '../../util/triggerMail';

const { UUID } = sdkTypes;

export class ExpertPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      currentListing: {},
      pageClassNames: [],
      offerRejectedModalOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      expertProfileModalOpen: false,
      isPlusButtonActive: true,
      localPlusValue: 0,
      voteButtonErrorMessage: '',
      listingState: null,
      verifiedSoftwares: [],
      deliveryDate: null,
      reviewModalOpen: false,
      newExpertModalOpen: false,
      conversationItems: [],
      sidebarVisible: false,
      paymentSuccessModalSeen: false,
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      modalOpen: false,
    };

    this.onContactUser = this.onContactUser.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleRequestBooking = this.handleRequestBooking.bind(this);
    this.verifiedSoftwaresCallback = this.verifiedSoftwaresCallback.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.handleSidebarVisibility = this.handleSidebarVisibility.bind(this);

    //console.dir(this.props.isAuthenticated,{depth:10});
  }

  arrayEquals(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  componentDidMount() {
    const { params, location, currentUserStripeAccounts } = this.props;
    const { action } = parse(location.search);

    if (action === 'recommend') {
      this.setState({ reviewModalOpen: true });
    }
    const body = {
      listingId: params.id,
    };

    let usdAccount = currentUserStripeAccounts?.find(user => user.currency === 'usd');
    let plnAccount = currentUserStripeAccounts?.find(user => user.currency === 'pln');
    const userHasBalance = usdAccount?.balance || plnAccount?.balance;
    this.setState({ openModal: !userHasBalance });
    queryAllowedUsers(body).then(response => {
      if (response?.length > 0) this.setState({ allowedUsers: response });
    });

    this.setState({ isOpenModalshare: false });
    const search = window.location.search;
    const paramsUrl = new URLSearchParams(search);
    if (paramsUrl.get('call') == 'true') {
      this.setState({ openBookModal: true });
    }

    this.setState({ openModal: true });
  }

  async componentDidUpdate(prevProps) {
    // load verifiedSoftwares
    if (prevProps !== this.props) {
      const listingId = new UUID(this.props.params.id);
      const isPendingApprovalVariant =
        this.props.params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
      const isDraftVariant = this.props.params.variant === LISTING_PAGE_DRAFT_VARIANT;
      const currentListing =
        isPendingApprovalVariant || isDraftVariant
          ? ensureOwnListing(this.props.getOwnListing(listingId))
          : ensureListing(this.props.getListing(listingId));
      if (
        !this.arrayEquals(
          this.state.verifiedSoftwares,
          currentListing.attributes.publicData.verifiedSoftwares
        )
      ) {
        this.setState({
          verifiedSoftwares: currentListing.attributes.publicData.verifiedSoftwares,
        });
      }
    }
  }

  verifiedSoftwaresCallback(softwares, action) {
    let newSoftwares = [];
    if (action === 'add') {
      newSoftwares = [
        ...(!!this.state.verifiedSoftwares ? this.state.verifiedSoftwares : []),
        ...softwares,
      ];
      this.setState({ verifiedSoftwares: newSoftwares });
    } else if (action === 'remove') {
      newSoftwares = this.state.verifiedSoftwares.filter(soft => {
        return !softwares.includes(soft);
      });
      this.setState({ verifiedSoftwares: newSoftwares });
    }
  }

  goBack(listing) {
    const { history } = this.props;
    const routes = routeConfiguration();

    if (history.length > 1) {
      history.goBack();
    } else
      history.push(
        createResourceLocatorString('SearchPage', routes, {}, { pub_category: 'expert' })
      );
  }

  onContactUser() {
    const { history, params, getListing } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const authorId = listing?.author.id.uuid;
    if (!!authorId) {
      history.push(
        createResourceLocatorString('ConversationPage', routeConfiguration(), { id: authorId }, {})
      );
    }
  }

  handleRequestBooking(values, freeMeeting) {
    const {
      history,
      getListing,
      params,
      onRequestBooking,
      // callSetInitialValues,
      currentUser,
      location,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const routes = routeConfiguration();
    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      const { bookingStartTime, bookingEndTime, ...restOfValues } = values;
      const bookingStart = timestampToDate(bookingStartTime);
      const bookingEnd = timestampToDate(bookingEndTime);

      const bookingData = {
        quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
        ...restOfValues,
      };

      const initialValues = {
        listing,
        bookingData,
        bookingDates: {
          bookingStart,
          bookingEnd,
        },
        confirmPaymentError: null,
        negotiatedTotal: freeMeeting ? null : listing.attributes.price,
      };

      let title = '';
      if (this.props?.currentUserListing?.attributes?.title) {
        title = this.props.currentUserListing.attributes.title;
      }

      connectCalendarGoogle({
        resource: 'events',
        method: 'insert',
        params: {
          url: window.location.origin + '' + window.location.pathname,
          userId: listing.author.id.uuid,
          bookingStart: bookingStart,
          bookingEnd: bookingEnd,
          timezone: listing.attributes.availabilityPlan.timezone,
          projectName: title,
          email: currentUser.attributes.email,
        },
      }).then(result => {
        newMeetingSystemEmail(currentUser, listing, bookingStart, result);
        sessionStorage.setItem('urlMeeting', result.hangoutLink);
      });

      onRequestBooking(initialValues).then(transaction => {
        const orderLink = createResourceLocatorString(
          'OrderDetailsPage',
          routes,
          { id: transaction.id.uuid },
          {}
        );
        const saleLink = createResourceLocatorString(
          'SaleDetailsPage',
          routes,
          { id: transaction.id.uuid },
          {}
        );

        //newMeetingEmail(currentUser, listing, orderLink, saleLink, bookingStart);
        //Redirect to OrderDetailsPage
        history.push(orderLink);
      });
    }
  }

  onSubmitReview(values) {
    const {
      onSendRecommendation,
      params: rawParams,
      currentUser,
      getListing,
      location,
      history,
    } = this.props;
    const listingId = new UUID(rawParams.id);
    const { action } = parse(location.search);
    const isRecommendAction = action === 'recommend';

    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    const currentListing = ensureListing(getListing(listingId));
    if (!reviewContent) {
      const plusData = {
        receiverListingId: listingId.uuid,
        senderUserId: currentUser.id.uuid,
      };
      sendUpvoteToExpert(plusData)
        .then(() => {
          this.setState({ localPlusValue: 1, reviewModalOpen: false });
        })
        .catch(error => {
          this.setState({ voteButtonErrorMessage: 'ExpertPage.recommendationErrorTurnedOff' });
          return;
        });
      newUpvoteNotification(currentListing, currentUser);
    } else
      onSendRecommendation(listingId, rating, reviewContent)
        .then(r => {
          this.setState({ reviewModalOpen: false });
          newUpvoteNotification(currentListing, currentUser);
        })
        .catch(e => {
          // Do nothing.
        });
    if (isRecommendAction) {
      const profileTitle = currentListing.attributes.title;
      const currentExpertPath = createResourceLocatorString('ExpertPage', routeConfiguration(), {
        id: rawParams.id,
        slug: createSlug(profileTitle),
        category: 'experts',
      });
      history.push(currentExpertPath);
    }
  }

  handleSidebarVisibility(state) {
    this.setState({ sidebarVisible: state });
  }

  render() {
    const {
      unitType,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      currentUserHasExpertListing,
      currentUserHasUnpublishedExpertListing,
      monthlyTimeSlots,
      onFetchTimeSlots,
      fetchedExpertServices,
      expertServicesLoaded,
      sendReviewError,
      sendReviewInProgress,
      reviews,
      fetchReviewsError,
      history,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');

    // SoftwareSupp Exam Certification
    const expertExamResults = currentListing?.author?.attributes?.profile?.publicData?.examResults;

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = 'description';

    const params = { slug: listingSlug, category: 'experts', ...rawParams };
    const {
      description = '',
      geolocation = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const isSolved = publicData.state === 'closed';
    const isAwaiting = publicData.state === 'awaiting';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ExpertPage" params={params} search={location.search} />;
    }

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <AuthenticationPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ExpertPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain className={css.layoutWrapperMain}>
              <p className={css.errorText}>
                <FormattedMessage id="ExpertPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing
      const loadingTitle = intl.formatMessage({
        id: 'ExpertPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ExpertPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
          </LayoutSingleColumn>
        </Page>
      );
    }
    const capitalize = function(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const isAdmin =
      currentUser && currentUser.id
        ? currentUser.id.uuid === process.env.REACT_APP_ADMIN_USER_ID
        : null;
    const isUser =
      userAndListingAuthorAvailable &&
      (!!currentListing.attributes.publicData.allowedUser?.find(
        userMail => userMail === currentUser.attributes.email
      ) ||
        this.state.allowedUsers?.find(userMail => userMail === currentUser.attributes.email));
    const isUserPrivilegedWithCommunicationFlag = currentUser?.attributes?.profile?.publicData?.adminPrivileges?.includes(
      'openCommunication'
    );
    const showContactUser =
      (isUser || isAdmin || isUserPrivilegedWithCommunicationFlag) &&
      authorAvailable &&
      (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));

    const schemaTitle =
      currentListing.attributes.publicData.primarySoftware !== 'other'
        ? intl.formatMessage(
            { id: 'ExpertPage.schemaTitle' },
            {
              name: title,
              primarySoftware: capitalize(currentListing.attributes.publicData.primarySoftware),
            }
          )
        : intl.formatMessage(
            { id: 'ExpertPage.schemaOtherTitle' },
            {
              name: title,
              primarySoftware: capitalize(currentListing.attributes.publicData.primarySoftware),
            }
          );
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handlePlusButton = async () => {
      if (!currentUser) {
        await sleep(1500);
      }
      // verify if adding value is feasible
      let isAuthorTheSame = currentUser?.id.uuid === currentListing.author.id.uuid;
      //currentUserHasListings
      let votesArray = currentListing.attributes.publicData.upvoteSendersIds;
      let hasUserAlreadyVoted =
        (votesArray && votesArray.includes(currentUser?.id?.uuid)) ||
        reviews.find(review => review.author.id.uuid === currentUser?.id.uuid);
      if (!currentUser) {
        // signup and return back to listingPage.
        this.props.history.push(
          createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
          this.state
        );
      } else if (isAuthorTheSame) {
        this.setState({ voteButtonErrorMessage: 'ExpertPage.recommendationErrorOwnProfile' });
      } else if (!this.props.currentUserHasListings) {
        this.setState({ voteButtonErrorMessage: 'ExpertPage.recommendationErrorNoActiveListings' });
      } else if (hasUserAlreadyVoted) {
        this.setState({
          voteButtonErrorMessage: 'ExpertPage.recommendationErrorAlreadyRecommended',
        });
      } else {
        this.setState({ reviewModalOpen: true });
      }
      this.setState({ isPlusButtonActive: false });
    };

    let votesArray = currentListing.attributes.publicData.upvoteSendersIds;
    const upvotesCounter = votesArray
      ? votesArray?.length + this.state.localPlusValue
      : this.state.localPlusValue;
    const sidebarVisible = this.state.sidebarVisible;

    const voteButton = this.state.isPlusButtonActive ? (
      <div
        className={css.plusButtonWrapper}
        onClick={() => {
          handlePlusButton();
        }}
      >
        <AiFillPlusCircle
          size={24}
          className={css.plusButtonActive}
          style={{ marginRight: '10px' }}
        />{' '}
        Add Recommendation
      </div>
    ) : (
      <div className={css.plusButtonWrapper}>
        <AiFillPlusCircle
          size={24}
          className={css.plusButtonActive}
          style={{ marginRight: '10px' }}
        />{' '}
        Add Recommendation
      </div>
    );

    let isOwn = false;

    if (currentUser?.id?.uuid === currentListing?.author?.id?.uuid) {
      isOwn = true;
    }

    // Sprawdzanie czy osoba jest isVerified, jak jest True to wtedy ustawia
    // zmienną na false i blokuje renderowanie komponentu Expert Modal.

    let expertModalForVerificated = true;

    try {
      if (!currentUseroattributes.profile.publicData?.isVerified) {
        expertModalForVerificated = true;
      } else {
        expertModalForVerificated = false;
      }
    } catch (e) {}

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSideNavigation className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperSideNav
            className={classNames(
              currentUser ? css.navigation : css.navigationNone,
              !sidebarVisible && css.navigationHidden
            )}
          >
            <Sidebar
              tab={isOwnListing ? 'expert' : null}
              isAdmin={isAdmin}
              isExpert={currentUserHasExpertListing}
              isPendingApprovalExpert={currentUserHasUnpublishedExpertListing}
              isVisible={this.state.sidebarVisible}
              setVisibility={this.handleSidebarVisibility}
            />
          </LayoutWrapperSideNav>
          <LayoutWrapperMain className={css.main}>
            <div className={css.mainWrapper}>
              <Container>
                <Row>
                  <Col xs={12} className={css2.goBack}>
                    <InlineTextButton
                      className={css.goBackButton}
                      onClick={() => this.goBack(currentListing)}
                    >
                      <ArrowLeft className={css.goBackIcon} />
                      Back
                    </InlineTextButton>
                  </Col>
                </Row>
                <Row className={classNames(css2.rowBorder)}>
                  <Col
                    className={classNames('text-center', css2.sectionPadding, css2.borderRight)}
                    xs={12}
                    md={3}
                  >
                    <Row>
                      <Col xs={5} md={12}>
                        <SectionAvatar user={currentAuthor} />
                        <SectionLinksMaybe
                          linkedin={publicData ? publicData.linkLinkedin : null}
                          calendly={publicData ? publicData.linkCalendly : null}
                          web={publicData ? publicData.linkWeb : null}
                        />
                      </Col>
                      <Col xs={7} md={12}>
                        <div className={css2.expertTitle}>{title}</div>
                        <div className={css2.expertise}>
                          {publicData?.expertise ? publicData.expertise : ''}
                        </div>
                        <div className={classNames(css2.location, 'text-center')}>
                          {publicData?.location ? publicData.location.address : ''}
                        </div>
                        <div>
                          <SectionExperienceAndProjectsMaybe
                            experience={publicData ? publicData.experience : null}
                            projectsCount={publicData ? publicData.projectsCount : null}
                          />
                        </div>
                        <div className={'d-none d-medium-block'}>
                          <SectionShareMaybe
                            isApproved={isApproved}
                            listingTitle={title}
                            listingId={currentListing.id.uuid}
                            listingType={'expert'}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className={classNames(css2.sectionPadding, css2.mobileBorder)}
                    xs={12}
                    md={6}
                  >
                    <div className={css.smallPrintHeading}>About freelancer</div>
                    <SectionDescriptionMaybe description={description} />

                    <SectionTagsMaybe
                      softwares={publicData.softwares}
                      skills={publicData.skills}
                      industries={publicData.industries}
                    />
                    <SectionVerifiedSoftwaresMaybe
                      verifiedSoftwares={this.state.verifiedSoftwares}
                    />
                    <SectionExamResultsMaybe examResults={expertExamResults} />
                    <AttachmentPanel uploadedFileLink={publicData.uploadedFileLink} />
                  </Col>
                  <Col xs={12} md={3} className={'d-none d-md-block'}>
                    <SideActionBarMaybe
                      isOwnListing={isOwnListing}
                      listing={currentListing}
                      editParams={{
                        id: listingId.uuid,
                        slug: listingSlug,
                        type: listingType,
                        tab: listingTab,
                        category: 'expert',
                      }}
                      publicData={publicData}
                      currentUser={currentUser}
                      isAdmin={isAdmin}
                      isSolved={isSolved}
                      isAwaiting={isAwaiting}
                      showContactUser={showContactUser}
                      onContactUser={this.onContactUser}
                      windowWidth={this.state.windowWidth}
                    />

                    {/* Booking modal */}

        

                    <div className={css.sideBar}>
                      {this.props.isAuthenticated && isOwn ? (
                        <>
                          <SecondaryButton
                            className={css.editButton}
                            onClick={() => {
                              this.setState({ isOpenModalshare: true });
                            }}
                          >
                            <FormattedMessage id="ExpertPage.shareCalendar" />
                          </SecondaryButton>
                        </>
                      ) : (
                        ''
                      )}
                    </div>

                    {/*        <SideActionBarMaybe */}
                    {/*   isOwnListing={isOwnListing} */}
                    {/*   listing={currentListing} */}
                    {/*   editParams={{ */}
                    {/*     id: listingId.uuid, */}
                    {/*     slug: listingSlug, */}
                    {/*     type: listingType, */}
                    {/*     tab: listingTab, */}
                    {/*     category: 'expert', */}
                    {/*   }} */}
                    {/*   publicData={publicData} */}
                    {/*   currentUser={currentUser} */}
                    {/*   isAdmin={isAdmin} */}
                    {/*   isSolved={isSolved} */}
                    {/*   isAwaiting={isAwaiting} */}
                    {/*   showContactUser={showContactUser} */}
                    {/*   onContactUser={this.onContactUser} */}
                    {/*   windowWidth={this.state.windowWidth} */}
                    {/*   isMobile */}
                    {/* /> */}
                  </Col>
                </Row>
                {/* experience */}
                <Row>
                  {publicData.projects.length > 0 ? (
                    <Col xs={12} className={css2.rowBorder}>
                      <SectionProjects projects={publicData.projects} />
                    </Col>
                  ) : null}

                  {publicData.professionalExperience.length > 0 ? (
                    <Col xs={12} className={css2.rowBorder}>
                      <SectionExperience experience={publicData.professionalExperience} />
                    </Col>
                  ) : null}
                  <Col xs={12} className={css2.rowBorder}>
                    <Row>
                      {upvotesCounter === 0 && reviews?.length === 0 ? (
                        <>
                          <Col xs={4} md={9} className={css2.reviewHeaderCorrection}>
                            Recomendations
                          </Col>
                          <Col xs={8} md={3} className={css2.textRight}>
                            {voteButton}
                          </Col>
                        </>
                      ) : null}
                      <Col xs={12}>
                        <SectionReviews
                          upvotesCounter={upvotesCounter}
                          handlePlusButton={handlePlusButton}
                          reviews={reviews}
                          fetchReviewsError={fetchReviewsError}
                          voteButton={voteButton}
                          voteButtonErrorMessage={this.state.voteButtonErrorMessage}
                        />
                      </Col>
                    </Row>
                    {/* <div className={css2.sectionTitle}> */}
                    {/* </div> */}
                    {/* <span> */}
                    {/*   <AiOutlineStock size={20} /> */}
                    {/*     <a href="#recommendations" className={css.recommendationsLink}> */}
                    {/*       <InlineTextButton className={css.viewRecommendations}> */}
                    {/*         <span className={css.recommendationsText}> */}
                    {/*           <FormattedMessage id="ExpertPage.viewRecommendations" /> */}
                    {/*         </span> */}
                    {/*         {upvotesCounter > 0 ? `(${reviews?.length + upvotesCounter})` : null} */}
                    {/*       </InlineTextButton> */}
                    {/*       <span className={css.recommendationTooltip}> */}
                    {/*         <Tooltip */}
                    {/*           title={ */}
                    {/*             <FormattedMessage id="ExpertPage.sendRecommendationExplanation" /> */}
                    {/*           } */}
                    {/*         > */}
                    {/*           <div className={css.tooltipIcon}> */}
                    {/*             <AiOutlineInfoCircle style={{ fontSize: 16 }} /> */}
                    {/*           </div> */}
                    {/*         </Tooltip> */}
                    {/*       </span> */}
                    {/*     </a> */}
                    {/* </span> */}
                    {/* {upvotesCounter === 0 && */}
                    {/*   reviews?.length === 0 && */}
                    {/*   this.state.voteButtonErrorMessage !== '' && ( */}
                    {/*     <div className={css.plusButtonErrorMessage}> */}
                    {/*       <FormattedMessage id={this.state.voteButtonErrorMessage} /> */}
                    {/*     </div> */}
                    {/*   )} */}
                  </Col>
                </Row>
                <Navbar
                  fixed="bottom"
                  className={'justify-content-center d-flex d-md-none'}
                  expand="xs"
                  variant="light"
                  bg="light"
                >
                  <SideActionBarMaybe
                    isOwnListing={isOwnListing}
                    listing={currentListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                      category: 'expert',
                    }}
                    publicData={publicData}
                    currentUser={currentUser}
                    isAdmin={isAdmin}
                    isSolved={isSolved}
                    isAwaiting={isAwaiting}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    windowWidth={this.state.windowWidth}
                  />
                </Navbar>
              </Container>

              <div className={css2.mapWrapper}>
                <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                />
              </div>

              <VendorVerificationPanel
                currentUser={currentUser}
                currentListing={currentListing}
                expertVerifiedSoftwares={this.state.verifiedSoftwares}
                verifiedSoftwaresCallback={this.verifiedSoftwaresCallback}
                isOwnListing={isOwnListing}
              />
              {/* <SectionLanguagesMaybe initialLanguages={publicData.languages} /> */}
            </div>
            <ReviewModal
              id="ReviewOrderModal"
              isOpen={this.state.reviewModalOpen && currentListing.id}
              onCloseModal={() => this.setState({ reviewModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              onSubmitReview={this.onSubmitReview}
              revieweeName={currentListing.attributes.title}
              sendReviewInProgress={sendReviewInProgress}
              sendReviewError={sendReviewError}
              currentUser={currentUser}
              currentListing={currentListing}
              reviews={reviews}
              currentUserHasListings={this.props.currentUserHasListings}
            />
            <BookingTimeModal
              listing={currentListing}
              isOwnListing={isOwnListing}
              onManageDisableScrolling={onManageDisableScrolling}
              unitType={unitType}
              onRequestBooking={this.handleRequestBooking}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              currentUser={currentUser}
              isAdmin={isAdmin}
              history={history}
              openBookModal={
                location?.state?.openBookModal
                  ? location.state.openBookModal
                  : this.state.openBookModal
              }
              isFreeBooking={!!location?.state?.freeBooking}
              isListingPage
              isPaidBooking
            />
            {typeof window !== 'undefined' ? (
              
              <Modal
                containerClassName={css.modalContainer}
                id="calendarShareModal"
                onClose={() => {
                  this.setState({ isOpenModalshare: false });
                }}
                onManageDisableScrolling={onManageDisableScrolling}
                isOpen={this.state.isOpenModalshare}
              >
                <div>
                  <div className={css.shareCalendarText}>
                    <FormattedMessage id="ExpertPage.shareCalendarText" />
                  </div>
                  <div>
                    <code className={css.code}>
                      {window.location.origin + window.location.pathname + '?call=true'}
                    </code>
                  </div>
                </div>
              </Modal>
            ) : null }

          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>

        {expertModalForVerificated && (
          <ExpertModal
            id="ListingPage.clientPath"
            step={1}
            listingId=""
            isOpen={isOwnListing && this.state.openModal}
            onCloseModal={() => this.setState({ openModal: false })}
            onManageDisableScrolling={onManageDisableScrolling}
            history={history}
          />
        )}
      </Page>
    );
  }
}

ExpertPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
};

ExpertPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  currentUserHasListings: bool,
};

const mapStateToProps = state => {
  const { isAuthenticated, authScopes } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    enquiryModalOpenForListingId,
    openBookingPanelModal,
    fetchedExpertProfileForService,
    fetchedExpertServices,
    expertServicesLoaded,
    expert,
    expertLoaded,
    expertLoadingInProgress,
  } = state.ExpertPage;
  const {
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserStripeAccounts,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    openBookingPanelModal,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    fetchedExpertProfileForService,
    fetchedExpertServices,
    expertServicesLoaded,
    authScopes,
    expert,
    expertLoaded,
    expertLoadingInProgress,
    currentUserStripeAccounts,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onRequestBooking: orderParams => dispatch(requestBooking(orderParams)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onSendRecommendation: (listingId, rating, content) =>
    dispatch(sendRecommendation(listingId, rating, content)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ExpertPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ExpertPageComponent);

ExpertPage.setInitialValues = initialValues => setInitialValues(initialValues);
ExpertPage.loadData = loadData;

export default ExpertPage;
