/* eslint-disable */
import React from 'react';
import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendSubscriptionCancelation, sendSubscriptionCancelationToClient } from '../../util/triggerMail'
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import { stripeInfo } from '../../util/api';
import { TopbarContainer } from '../../containers';
import { Alert } from '@material-ui/lab';
import css from './SubscriptionSettingsPage.css'
import { SecondaryButton } from '../../components';




export const SubscriptionSettingsPageComponent = props => {


  const {
    currentUser,
    scrollingDisabled,
    intl,
  } = props;

  const [stripeNextInvoiceDate, setstripeNextInvoiceDate] = useState(null)
  const [stripeCacelationRequest, setstripeCacelationRequest] = useState('')
  const [sendCancelation, setsendCancelation] = useState(false)
  const [hasPlan, sethatPlan] = useState(false)
  const [dataloaded, setdataloaded] = useState(false)


  const title = intl.formatMessage({ id: 'SubscriptionSettingsPage.heading' });
  useEffect(() => {
      //const fetchStripeData = stripeInfo({userId:"62ce9ab7-200f-412f-a4e2-2d538e3831fd"})
      const fetchStripeData = stripeInfo(currentUser?.attributes?.profile.privateData.inboxToken, { userId: currentUser?.id?.uuid })

      fetchStripeData.then((res) => {
        console.log('res', res)
       if(res.account.subscriptions?.total_count > 0){
        sethatPlan(true)
        setdataloaded(true) 
        }
        let nextInvoiceDate = new Date(res.nextInvoice.next_payment_attempt * 1000).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "long", day: "numeric" })
        setstripeNextInvoiceDate(nextInvoiceDate)
      
      }).catch((error) => {
        setdataloaded(true) 
        // No subscription
      })
  }, [currentUser])


  // Akcja wysyłania maila
  
  const SendCaneclationMail = (e) => {
    e.preventDefault();
    const mail = sendSubscriptionCancelation(currentUser);
    const mailToClient = sendSubscriptionCancelationToClient(currentUser)

    mail.then(res => {
      if(res.response == "success"){
        setsendCancelation(true);
      }})
    .then(() => {
      mailToClient.then(res => {
        // Wysylamy drugiego maila  
      })
    })

  }


  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage=""
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav currentUser={currentUser} selectedPageName="SubscriptionSettingsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="SubscriptionSettingsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="SubscriptionSettingsPage.heading" />
            </h1>
            <div className={css.smallParagraf}>
              {/* <h3>Your Subscription</h3> */}
              { !dataloaded && 
                  <p> Loading data ...</p>
              }


              {!hasPlan && dataloaded &&
                <p>
                  <FormattedMessage id="SubscriptionSettingsPage.planInformation" />
                </p>
              }
                 {hasPlan && dataloaded &&
                <p>
                  <FormattedMessage id="SubscriptionSettingsPage.activePlanInfo" /><a href='mailto:payments@softwaresupp.com'>payments@softwaresupp.com</a>
                </p>
              }
            </div>


            {/* <div className={css.sectionSeparator}></div> */}

            {hasPlan &&
              <>
                <div>
                  <h3><FormattedMessage id="SubscriptionSettingsPage.subscriptionCanelation" /></h3>
                </div>
                <div>
                  {!sendCancelation &&
                    <SecondaryButton onClick={SendCaneclationMail}>Cancel your subscription</SecondaryButton>
                  }

                  {sendCancelation &&
                    <div className={css.smallParagraf}>
                      <Alert severity="info">
                        <FormattedMessage id="SubscriptionSettingsPage.cancelationSended" />
                      </Alert>
                      <p><FormattedMessage id="SubscriptionSettingsPage.cancelationInformation" /></p>
                    </div>
                  }


                </div>
              </>
            }



          </div>
        </LayoutWrapperMain>

      </LayoutSideNavigation>

      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </Page>
  );
};


// To jest Hydrowanie komponentu poprsami

const mapStateToProps = state => {
  // Topbar needs user info.
  const {
    changePasswordError,
    changePasswordInProgress,
    passwordChanged,
  } = state.PasswordChangePage;
  const { currentUser } = state.user;
  return {
    changePasswordError,
    changePasswordInProgress,
    currentUser,
    passwordChanged,
    scrollingDisabled: isScrollingDisabled(state),
  };
};


const SubscriptionSettingsPage = compose(
  connect(
    mapStateToProps,
  ),
  injectIntl
)(SubscriptionSettingsPageComponent);



export default SubscriptionSettingsPage;
