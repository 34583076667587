import React from 'react';
import moment from 'moment';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryChip } from '../../components/Chip/Chip';

import css from './ListingCard.css';

const SectionNewMaybe = props => {
  const { createdAt } = props;
  const daysPassed = moment().diff(createdAt, 'days');
  const isNew = daysPassed < 5;
  
  return isNew ? (
    <PrimaryChip className={css.newChip}>
      <FormattedMessage id="ListingCard.new" />
    </PrimaryChip>
  ) : null;
};

export default SectionNewMaybe;