export const createExamTemplate = (description, config, questions) => (dispatch, getState, sdk) => {
  const newExamRandomTitle = "ExamTemplate" + (new Date()).getTime();
  return sdk.ownListings.create({
    title: newExamRandomTitle,
    publicData: {
      description,
      config,
      questions,
      category: "exam"
    }
  }).then(res => {
    return res.data;
  });
}

export const readExistingExamListings = () => (dispatch, getState, sdk) => {
  return sdk.listings.query({
    pub_category: 'exam'
  }).then(res => {
    return res.data;
  });
}