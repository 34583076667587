import React from 'react';
import { array, bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { sendVerificationEmail, hasCurrentUserErrors } from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { fetchProductSoftware, fetchProductSkills } from '../../ducks/marketplaceData.duck';
import { Topbar } from '../../components';

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserListing,
    currentUserExpertListing,
    currentUserListingFetched,
    currentUserHasOrders,
    currentCategory,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    hasUnreadNotifications,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    forceRerender,
    saveUserKeywordSearch,
    logoutInProgress,
    productSoftware,
    productSkills,
    onFetchSkills,
    onFetchSoftware,
    ...rest
  } = props;

  // const [fetchingSoftwareAndSkillsInProgress, setFetchingSoftwareAndSkillsInProgress] = useState(
  //   false
  // );
  // useEffect(() => {
  //   let isMounted = false; // note mutable flag
  //   if (isMounted) {
  //     if (
  //       (productSoftware.length === 0 || productSkills.length === 0) &&
  //       !fetchingSoftwareAndSkillsInProgress
  //     ) {
  //       setFetchingSoftwareAndSkillsInProgress(true);
  //       const skills = onFetchSkills();
  //       const software = onFetchSoftware();
  //       Promise.all([skills, software]).then(() => setFetchingSoftwareAndSkillsInProgress(false));
  //     }
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [
  //   fetchingSoftwareAndSkillsInProgress,
  //   onFetchSkills,
  //   onFetchSoftware,
  //   productSoftware,
  //   productSkills,
  // ]);

  return (
    <Topbar
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentSearchParams={currentSearchParams}
      currentUser={currentUser}
      currentUserHasListings={currentUserHasListings}
      currentUserHasExpertListing={currentUserHasExpertListing}
      currentUserHasUnpublishedExpertListing={currentUserHasUnpublishedExpertListing}
      currentUserListing={currentUserListing}
      currentUserExpertListing={currentUserExpertListing}
      currentUserListingFetched={currentUserListingFetched}
      currentUserHasOrders={currentUserHasOrders}
      currentCategory={currentCategory}
      history={history}
      isAuthenticated={isAuthenticated}
      authScopes={authScopes}
      location={location}
      shouldDisplayNotificationDot={hasUnreadNotifications}
      onLogout={onLogout}
      onManageDisableScrolling={onManageDisableScrolling}
      onResendVerificationEmail={onResendVerificationEmail}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      showGenericError={hasGenericError}
      forceRerender={forceRerender}
      saveUserKeywordSearch={saveUserKeywordSearch}
      logoutInProgress={logoutInProgress}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  currentUserListing: null,
  authScopes: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool,
  currentUserHasExpertListing: bool,
  currentUserListingFetched: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserExpertListing: oneOfType([propTypes.listing, propTypes.ownListing]),
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes, logoutInProgress } = state.Auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserListing,
    currentUserUnpublishedListing,
    currentUserHasUnpublishedListing,
    currentUserExpertListing,
    currentUserListingFetched,
    currentUserHasOrders,
    hasUnreadNotifications,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    currentUserHasProjects,
    currentUserHasLegacyProjects,
    userHasProjectInvitations,
    currentUserStripeAccounts,
  } = state.user;
  const { productSoftware, productSkills } = state.marketplaceData;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasExpertListing,
    currentUserHasUnpublishedExpertListing,
    currentUserListing,
    currentUserUnpublishedListing,
    currentUserHasUnpublishedListing,
    currentUserExpertListing,
    currentUserListingFetched,
    currentUserHasOrders,
    hasUnreadNotifications,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    logoutInProgress,
    currentUserHasProjects,
    currentUserHasLegacyProjects,
    userHasProjectInvitations,
    currentUserStripeAccounts,
    productSoftware,
    productSkills,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onFetchSkills: () => dispatch(fetchProductSkills()),
  onFetchSoftware: () => dispatch(fetchProductSoftware()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TopbarContainerComponent);

export default TopbarContainer;
