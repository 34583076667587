import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton } from '../../components';

import css from './TransactionPanel.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    completeInProgress,
    setPaymentModalOpen
  } = props;

  const buttonsDisabled = completeInProgress;

  const classes = classNames(rootClassName || css.actionButtons, className);

  const acceptButton = !!showButtons && (
    <PrimaryButton
      inProgress={completeInProgress}
      disabled={buttonsDisabled}
      onClick={setPaymentModalOpen}
    >
      <FormattedMessage id={`TransactionPanel.${showButtons}PaymentButton`} />
    </PrimaryButton>
  )

  return (
    <div className={classes}>
      <div className={css.actionButtonWrapper}>
        {showButtons ? acceptButton : null}
      </div>
    </div>
  )
};

export default SaleActionButtonsMaybe;
