import React, { useState } from 'react';
import css from './ProjectBoardPage.css';
import { FormattedMessage } from '../../util/reactIntl';
import { Switch } from '@material-ui/core';

const SectionNotifications = ({currentProject, switchNotifications, currentUserId}) => {
  const getNotificationsStatusForType = type => {
    const userNotifications = currentProject.attributes.publicData.disabledNotifications?.find(element => element.userId === currentUserId);
    if (userNotifications) {
      return !!userNotifications[type];
    }
    return false;
  }
  const [NewTaskNotificationsDisabled, setNewTaskNotificationsDisabled] = useState(getNotificationsStatusForType('newTask'));
  const [TaskUpdateNotificationsDisabled, setTaskUpdateNotificationsDisabled] = useState(getNotificationsStatusForType('taskUpdate'));
  const [NewAnswerNotificationsDisabled, setNewAnswerNotificationsDisabled] = useState(getNotificationsStatusForType('newAnswer'));

  const NewTaskNotifications = (
    <div className={css.notificationsSwitch}>
      <FormattedMessage
        id="ProjectBoardPage.notificationSwitchLabel"
        defaultMessage="New task"
      />
      <Switch color={'primary'} onChange={async (e) => {
        const target = e.target;
        target.disabled = true;
        const status = await switchNotifications(currentProject, 'newTask');
        if (status === 'success') {
          setNewTaskNotificationsDisabled(!NewTaskNotificationsDisabled);
        }
        target.disabled = false;

      }} checked={!NewTaskNotificationsDisabled}/>
    </div>
  );

  const UpdateTaskNotifications = (
    <div className={css.notificationsSwitch}>
      <FormattedMessage
        id="ProjectBoardPage.notificationSwitchLabel"
        defaultMessage="Task update"
      />
      <Switch color={'primary'} onChange={async (e) => {
        const target = e.target;
        target.disabled = true;
        const status = await switchNotifications(currentProject, 'taskUpdate');
        if (status === 'success') {
          setTaskUpdateNotificationsDisabled(!TaskUpdateNotificationsDisabled);
        }
        target.disabled = false;
      }} checked={!TaskUpdateNotificationsDisabled}/>
    </div>
  );
  
  const NewAnswerNotifications = (
    <div className={css.notificationsSwitch}>
      <FormattedMessage
        id="ProjectBoardPage.notificationSwitchLabel"
        defaultMessage="New answer"
      />
      <Switch color={'primary'} onChange={async (e) => {
        const target = e.target;
        target.disabled = true;
        const status = await switchNotifications(currentProject, 'newAnswer');
        if (status === 'success') {
          setNewAnswerNotificationsDisabled(!NewAnswerNotificationsDisabled);
        }
        target.disabled = false;
      }} checked={!NewAnswerNotificationsDisabled}/>
    </div>
  );
  return (
    <div className={css.notificationsWrapper}>
      <p>Send notifications:</p>
      {NewTaskNotifications}
      {UpdateTaskNotifications}
      {NewAnswerNotifications}
    </div>
  )
}

export default SectionNotifications;