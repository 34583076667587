import React from 'react';
import classNames from 'classnames';
import css from './CustomButton.css';

const CustomButton = props => {

    const { label, value, onClickHandler, active } = props;

    const buttonStyle = active ? classNames(css.mainWrapper, css.buttonActive) : classNames(css.mainWrapper);

    return (
        <div className={buttonStyle} onClick={() => onClickHandler(value)}>
            <span className={css.buttonTitle}>
                {label}
            </span>
        </div>
    );
}

export default CustomButton;