import React from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';

import { SecondaryButton } from '../../components';
import { formatMoney } from '../../util/currency';

import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BillingPage.css';
import { BiDetail } from 'react-icons/bi';
const { Money } = sdkTypes;

const InvoiceItem = props => {
  const { intl, invoice } = props;

  const amount = formatMoney(intl, new Money(invoice.amount_due, invoice.currency.toUpperCase()));
  const status = invoice.status;

  return (
    <div className={css.listWrapper}>
      <div className={css.invoiceItemContainer}>
        <div className={css.chartContainer}>
          <BiDetail size={50} />
          <div className={css.invoiceInfo}>
            <div>
              <FormattedMessage
                id={'BillingPage.invoiceNumber'}
                values={{ number: invoice.number }}
              />
            </div>
            <div>
              <FormattedMessage id={'BillingPage.amountOfInvoice'} values={{ amount }} />
            </div>
            <div>
              <FormattedMessage id={'BillingPage.statusOfInvoice'} values={{ status }} />
            </div>
          </div>
          
        </div>
        <div key={invoice.id} href={invoice.hosted_invoice_url} className={css.invoiceItem}>
          <SecondaryButton
            className={css.button}
            onClick={() => (window.location.href = invoice.hosted_invoice_url)}
          >
            <FormattedMessage
              id={
                invoice.status === 'paid'
                  ? 'BillingPage.InvoiceDetails'
                  : 'BillingPage.PayTheInvoice'
              }
            />
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(InvoiceItem);
