import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import axios from 'axios';
import {
  required,
  composeValidators,
  numberValueAtLeast,
  moneyValueAtLeast,
  nonNegativeNumber,
} from '../../util/validators';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Button,
  Form,
  FieldTextInput,
  FieldCurrencyInput,
  NamedLink,
  MultilevelScopeInput,
} from '../../components';
import { BsCloudDownload } from 'react-icons/bs';
import SideValidatedFieldTextInput from './SideValidatedFieldTextInput';
import css from './NewOfferForm.css';
import { apiBaseUrl } from '../../util/api';
import { Bars } from 'react-loader-spinner';

export const NewOfferFormComponent = props => {

  const { Money } = sdkTypes;
  const {
    currentUserExpertListing,
    commission,
    disabled,
    formMinimumPriceNotReached,
    jobScope,
    currentUser,
    isJobPremium,
  } = props;

  const [isJobScopeLoaded, setIsJobScopeLoaded] = useState(false);
  const [jobScopeLoadingError, setJobScopeLoadingError] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [defaultAmount, setdefaultAmount] = useState(0);
  const [fileUrl, setFileUrl] = useState('');
  const [uploading, setFileUploating] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileValidation, setFileValidation] = useState(null);
  const [FormValidation, setFormValidation] = useState(null);
  const [userFileList, setUserFileList] = useState([])

  let userFiles = currentUserExpertListing?.attributes?.publicData?.uploadedFileLink

    const currentUserHasHourlyRate =
    !!currentUserExpertListing && !!currentUserExpertListing.attributes.price;
  const currentUserHourlyRate = currentUserExpertListing?.attributes?.price?.amount;
  const currentUserExpertProfileTitle = currentUserExpertListing?.attributes?.title?.toLowerCase();
  const currentUserExpertProfileId = currentUserExpertListing?.id?.uuid;
  const currentUserHourlyRateLink =
    '/l/' +
    currentUserExpertProfileTitle +
    '/' +
    currentUserExpertProfileId +
    '/edit/expert/pricing';


  const uploadToS3 = async (data) => {

    const url = `${apiBaseUrl()}/api/upload`;
    let formData = new FormData();
    formData.append("cvfile", data.target.files[0])
    formData.append("name", "cvfile")
    formData.append('prefix', 'cv')

    const axiosRes = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })

    return axiosRes
}


  const termsLink = (
    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
      <FormattedMessage id="JobApplicationForm.termsOfUse" />
    </NamedLink>
  );

  const handelTest = (form) => {
    form.change("test","wartosc testowa")
  }

  const offerTooltip = (
    <Tooltip
      interactive
      title={
        <FormattedMessage
          id={'JobApplicationForm.offerTooltip'}
          values={{ br: <br />, terms: termsLink }}
        />
      }
    >
      <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
    </Tooltip>
  );

  const priceTooltip = (
    <Tooltip
      interactive
      title={<FormattedMessage id="NewOfferForm.priceTooltip" values={{ br: <br /> }} />}
    >
      <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
    </Tooltip>
  );

  const sendOfferTooltip = (
    <>
      <FormattedMessage id={`NewOfferForm.sendOffer`} />
      <Tooltip
        interactive
        title={
          <FormattedMessage
            id={`JobApplicationForm.sendOfferExplanation`}
            values={{ br: <br />, terms: termsLink }}
          />
        }
      >
        <InfoOutlinedIcon className={css.infoIcon} style={{ fontSize: 16 }} />
      </Tooltip>
    </>
  );

  const loadJobScope = values => {
    if (!jobScope || jobScope.length === 0) {
      setJobScopeLoadingError('Project scope is empty. Please try to define scope on your own.');
      return;
    }
    let initialValues = {};
    for (let i = 0; i < jobScope.length; i++) {
      initialValues[`scopeTaskNameInput${i}`] = jobScope[i].task;
      if (jobScope[i]?.subtasks?.length > 0) {
        for (let j = 0; j < jobScope[i].subtasks.length; j++) {
          initialValues[`scopeTask${i}SubtaskNameInput${j}`] = jobScope[i].subtasks[j].name;
        }
      }
    }
    initialValues[`offer`] = values[`offer`];
    setInitialValues(initialValues);
    setIsJobScopeLoaded(true);
  };
  
  const handleFileUpload = async (e, form) => {
    let validationError = null;
    setFileValidation(false);
    setFileUploaded(false);

    if (e.target.files.length > 0) {
      setFileUploating(true);

      if (e.target.files[0].type != 'application/pdf') {
        validationError = 'Wrong file format';
      }

      if (e.target.files[0].size > 200000000) {
        validationError = 'File is to large';
      }

      if (!validationError) {
        const resdata = await uploadToS3(e);
        form.change('uploadedFileLink', resdata.data.url)
        setFileUploating(false);
        setFileUploaded(true);
      }

      if (validationError) {
        form.change('uploadedFileLink','' )
        setFileValidation(validationError);
        setFileUploating(false);
      }
    }
  };
  return (
    <FinalForm
      {...props}
      initialValues={initialValues}
      render={formRenderProps => {
        const {
          className,
          form,
          handleSubmit,
          intl,
          values,
          invalid,
          submitFailed,
          submitSucceeded,
          inProgress: submitInProgress,
        } = formRenderProps;

        const classes = classNames(css.root, className);

        const calculateSumTimeValue = values => {
          const timeEntries = Object.entries(values).filter(entryArray => {
            return entryArray[0].includes('scopeTaskTimeInput');
          });
          const sumTime =
            timeEntries && timeEntries.length > 0
              ? timeEntries.reduce((acc, item) => {
                  return item[1] ? acc + parseInt(item[1]) : acc;
                }, 0)
              : null;
          return sumTime;
        };

        const withoutCommission = (price, commission) => {
          return Math.trunc(price - price * commission);
        };

        const calculateSumPriceValue = values => {
          const priceEntries = Object.entries(values).filter(entryArray => {
            return entryArray[0].includes('scopeTaskPriceInput');
          });
          const sumPrice =
            priceEntries && priceEntries.length > 0
              ? priceEntries.reduce((acc, item) => {
                  return item[1]?.amount ? acc + parseInt(item[1]?.amount) : acc;
                }, 0)
              : null;
          return sumPrice;
        };



        let offerMessage = intl.formatMessage({ id: `NewOfferForm.offerMessage` });
        let offerPlaceholderMessage = intl.formatMessage({
          id: `NewOfferForm.offerPlaceholder`,
        });

        let estimatedTimeRequired = required(
          intl.formatMessage({
            id: 'EditListingDescriptionForm.estimatedTimeRequired',
          })
        );
        let estimatedPriceRequired = required(
          intl.formatMessage({
            id: 'NegotiationForm.estimatedPriceRequired',
          })
        );
        const valueRequired = required(
          intl.formatMessage({
            id: 'CustomOfferScopeInput.valueRequired',
          })
        );

        const valueNotNegative = nonNegativeNumber(
          intl.formatMessage({
            id: 'CustomOfferScopeInput.valueNotNegative',
          })
        );

        const sumMinTimeValue = calculateSumTimeValue(values);
        const suggestedEstimatedTime = sumMinTimeValue > 0 ? sumMinTimeValue?.toString() : null;
        const estimatedTimeMinValue = numberValueAtLeast(
          intl.formatMessage({ id: 'NewOfferForm.minimumTimeValue' }, { min: sumMinTimeValue }),
          sumMinTimeValue
        );

        const sumMinPriceValue = calculateSumPriceValue(values);



        const suggestedPriceValue = sumMinPriceValue > 0 ? sumMinPriceValue : undefined;
        const estimatedPriceMinValue = moneyValueAtLeast(
          intl.formatMessage(
            { id: 'NewOfferForm.minimumPriceValue' },
            { min: sumMinPriceValue / 100 }
          ),
          sumMinPriceValue
        );

        const jobBudgetAmount = props.jobListingBudget?.amount;
        const currentPriceAmount = values['estimatedPriceSummary']?.amount;
        let priceAboveAmount =
          currentPriceAmount - withoutCommission(jobBudgetAmount, 0);
        const isPriceAboveJobBudget = priceAboveAmount > 0;
      
       priceAboveAmount=jobBudgetAmount;
      setdefaultAmount(priceAboveAmount)

      if (props.isAdminOffer) {

         

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {disabled ? (
                <p className={css.error}>
                  <FormattedMessage id="NewOfferForm.jobIsClosed" />
                </p>
              ) : null}
              {/* DESCRIPTION */}
              <FieldTextInput
                id="offer"
                name="offer"
                className={css.title}
                type="textarea"
                label={offerMessage}
                placeholder={offerPlaceholderMessage}
                disabled={disabled}
                tooltip={offerTooltip}
              />
              {/* SCOPE */}
              <>
                <h2 className={css.scopeTitle}>Describe scope</h2>
                <div className={css.loadJobScopeLink} onClick={() => loadJobScope(values)}>
                  <BsCloudDownload />
                  {'\u00A0'}
                  Load scope from project description.
                </div>
                {jobScopeLoadingError !== '' && (
                  <span className={css.jobScopeLoadingError}>{jobScopeLoadingError}</span>
                )}
                <MultilevelScopeInput
                  intl={intl}
                  values={values}
                  currentUserHasHourlyRate={false}
                  disabled={disabled}
                  scopeType={'offer'}
                  isAdminOffer={true}
                  jobScope={jobScope}
                  isJobScopeLoaded={isJobScopeLoaded}
                />
              </>
              {/* MILESTONES */}
              {/* <>
                <h2 className={css.scopeTitle}>
                  Specify milestones
                </h2>
                <CustomMilestonesAdminInput 
                  intl={intl} 
                  values={values}
                  disabled={disabled}
                />
              </> */}
              {/* ESTIMATED TIME FROM-TO */}
              <h2 className={css.scopeTitle}>Estimated time of realization range (in hours)</h2>
              <div className={css.estimatedTimeAdminWrapper}>
                <FieldTextInput
                  id="estimatedTimeMin"
                  name="estimatedTimeMin"
                  placeholder="7"
                  type="number"
                  min={0}
                  className={css.estimatedTimeInputAdmin}
                  label={intl.formatMessage({ id: 'NewOfferForm.estimatedTimeLabelMin' })}
                  disabled={disabled}
                />
                <FieldTextInput
                  id="estimatedTimeMax"
                  name="estimatedTimeMax"
                  placeholder="14"
                  type="number"
                  min={0}
                  className={css.estimatedTimeInputAdmin}
                  label={intl.formatMessage({ id: 'NewOfferForm.estimatedTimeLabelMax' })}
                  disabled={disabled}
                />
              </div>
              {/* PRICE FROM-TO */}
              <h2 className={css.scopeTitle}>Estimated price range ($)</h2>
              <div className={css.estimatedTimeAdminWrapper}>
                <FieldCurrencyInput
                  id="estimatedPriceMin"
                  name="estimatedPriceMin"
                  className={css.estimatedTimeInputAdmin}
                  currencyConfig={config.currencyConfig}
                  label={'Minimum price ($)'}
                  validate={composeValidators(estimatedPriceRequired)}
                  disabled={disabled}
                />
                <FieldCurrencyInput
                  id="estimatedPriceMax"
                  name="estimatedPriceMax"
                  className={css.estimatedTimeInputAdmin}
                  currencyConfig={config.currencyConfig}
                  label={'Maximum price ($)'}
                  disabled={disabled}
                />
              </div>
              {/* OFFER VALIDITY TIME */}
              <FieldTextInput
                id="offerValidity"
                name="offerValidity"
                className={css.title}
                type="textarea"
                label={'Offer validity time'}
                placeholder={'e.g. "Offer valid until 30.11.2020"'}
                disabled={disabled}
              />
              {/* Payment details */}
              <FieldTextInput
                id="offerPaymentDetails"
                name="offerPaymentDetails"
                className={css.title}
                type="textarea"
                label={'Information about payment'}
                placeholder={
                  'e.g. "50/50 or “by invoice”, “by credit card”, etc. - especially for bigger projects"'
                }
                disabled={disabled}
              />
              {/* Contract Info */}
              <FieldTextInput
                id="offerContractInfo"
                name="offerContractInfo"
                className={css.title}
                type="textarea"
                label={'Contract'}
                placeholder={
                  'when left empty will display default msg: "Before purchasing SoftwareSupp service you will be ask to accept our Terms&Conditions (link). Accepting Terms&Conditions constitutes legally binding contract between you and SoftwareSupp."'
                }
                disabled={disabled}
              />
              {/* Contact Person */}
              <FieldTextInput
                id="offerContact"
                name="offerContact"
                className={css.title}
                type="textarea"
                label={'Contact Person'}
                placeholder={'e.g. "Agnieszka Mutwicka"'}
                disabled={disabled}
              />
              <FieldTextInput
                id="offerContactMail"
                name="offerContactMail"
                className={css.title}
                type="textarea"
                label={'Contact E-mail'}
                placeholder={'e.g. "mail@domain.com"'}
                disabled={disabled}
              />
              <FieldTextInput
                id="offerContactPhone"
                name="offerContactPhone"
                className={css.title}
                type="textarea"
                label={'Contact Phone Number'}
                placeholder={'e.g. "+48 000 000 000"'}
                disabled={disabled}
              />
              <Button
                className={css.submitButton}
                type="submit"
                disabled={disabled || submitInProgress}
              >
                {sendOfferTooltip}
              </Button>
            </Form>
          );
        }
        // OFFER FORM FOR EXPERTS
        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {disabled ? (
              <p className={css.error}>
                <FormattedMessage id="NewOfferForm.jobIsClosed" />
              </p>
            ) : null}
            <FieldTextInput
              id="offer"
              name="offer"
              className={css.title}
              type="textarea"
              label={offerMessage}
              placeholder={offerPlaceholderMessage}
              disabled={disabled}
              tooltip={offerTooltip}
              validate={valueRequired}
            />
            <p className={css.smallPrint}>
              <FormattedMessage id="NewOfferForm.offerSmallPrint" />
            </p>
            <>
              <h2 className={css.scopeTitle}>Describe scope of your offer</h2>
              {currentUserHasHourlyRate && (
                <div className={css.scopeCommentWrapper}>
                  <span className={css.scopeCommentText}>
                    If you specify hours related to each task your price will be automatically
                    calculated based on your hourly rate.
                    <br />
                    <strong>Your current hourly rate is:</strong>{' '}
                    {formatMoney(intl, new Money(currentUserHourlyRate ?? 0, config.currency))}.
                    <a href={currentUserHourlyRateLink}> You can modify it here.</a>
                  </span>
                </div>
              )}
              <div className={css.loadJobScopeLink} onClick={() => loadJobScope(values)}>
                <BsCloudDownload />
                {'\u00A0'}
                Load scope from project description.
              </div>
              {jobScopeLoadingError !== '' && (
                <span className={css.jobScopeLoadingError}>{jobScopeLoadingError}</span>
              )}
              <MultilevelScopeInput
                intl={intl}
                values={values}
                currentUserHasHourlyRate={currentUserHasHourlyRate}
                currentUserHourlyRate={currentUserHourlyRate}
                disabled={disabled}
                scopeType={'offer'}
                isAdminOffer={false}
                jobScope={jobScope}
                isJobScopeLoaded={isJobScopeLoaded}
                hidePriceInComponent = {true} 
              />



              <h2 className={css.servicePriceTitle}>Attachment:</h2>

              <div>


              { !!userFiles ? (
                <> 
                <h3>Select from uploaded files:</h3>

                  <ul class={css.attachemntList}>
                    {userFiles.map((item)=>{
                      return (
                      <li>
                        <span>
                        <Field
                  name="selectedFiles"
                  component="input"
                  type="checkbox"
                  value={item.url}
                /></span>
                <span> {item.name}</span>
                </li>
                )

                })}
                  </ul>
                  </>
              ): <h3>No files attached to Your profile</h3>
              }
                       
              </div>
              
              <h3>or attach file from you computer:</h3>
              <span className={css.reqirements} >* Allowed formats *.PDF, max. 20 MB</span> 
              <div> 

              <Field className={css.hideField} name="uploadedFileLink" component="input" />

              <label>
                  <input
                    className={css.fileField}
                    name="cvfile"
                    type="file"
                    onChange={e => handleFileUpload(e,form)}
                  ></input>

                  {uploading ? (
                  <div className={css.inprogress}>
                    {' '}
                    <Bars
                      height="30"
                      width="30"
                      color="#000000"
                      ariaLabel="bars-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                    <div>Uploading file</div>
                  </div>
                ) : null}
                {fileUploaded ? <div className={css.success}>File uploaded</div> : null}
                {fileValidation ? <div className={css.error}>{fileValidation}</div> : null}
                </label>   
              </div>


              <h2 className={css.servicePriceTitle}>Your price for the service:</h2>
              
              <div style={{visibility:"hidden",height:"10px"}}>
              <p className={classNames(css.smallPrint, css.smallPrintLeft, css.subtitlePrice)}>
                <span>All SoftwareSupport projects are settled hourly and you always receive the full amount of your price.</span> {priceTooltip}
              </p>
              <FieldCurrencyInput
                id="estimatedPriceSummary"
                name="estimatedPriceSummary"
                className={css.title}
                currencyConfig={config.currencyConfig}
                disabled={disabled}
                suggestPriceValue={suggestedPriceValue}
                //validate={composeValidators(estimatedPriceRequired,estimatedPriceMinValue)}
                defaultAmount={defaultAmount/100}
              />
              </div>
              {formMinimumPriceNotReached && (
                <span className={css.serviceError}>
                  <FormattedMessage
                    id="NewOfferForm.minimumPriceNotReached"
                    values={{
                      price: formatMoney(
                        intl,
                        new Money(config.expertOfferMinimumPriceSubUnits, config.currency)
                      ),
                    }}
                  />
                </span>
              )}
              {isPriceAboveJobBudget && (
                <span className={css.serviceWarning}>
                  <FormattedMessage
                    id="NewOfferForm.priceAboveJobBudget"
                    values={{
                      above: formatMoney(intl, new Money(priceAboveAmount, config.currency)),
                      // budget: formatMoney(intl, new Money(jobBudgetAmount, config.currency))
                    }}
                  />
                </span>
              )}
            </>
            <SideValidatedFieldTextInput
              id="estimatedTime"
              name="estimatedTime"
              type="number"
              className={css.estimatedTimeInput}
              label={intl.formatMessage({ id: 'NewOfferForm.estimatedTimeLabel' })}
              placeholder={intl.formatMessage({ id: 'NewOfferForm.estimatedTimePlaceholder' })}
              //validate={composeValidators(estimatedTimeRequired,valueNotNegative,estimatedTimeMinValue)}
              triggerTrackValue={sumMinTimeValue}
              triggerFieldValue={values.estimatedTime}
              disabled={disabled}
              defaultValue={suggestedEstimatedTime}
            />
            {(submitFailed || submitSucceeded) && (invalid || formMinimumPriceNotReached) && (
              <span className={css.serviceError}>
                <FormattedMessage id="NewOfferForm.formInvalid" />
              </span>
            )}
            
            {!currentUser?.attributes.profile.publicData?.isVerified && isJobPremium && (
              <span className={css.serviceError}>
                <FormattedMessage id="NewOfferForm.expertNotVerified" />
              </span>
            )}
            {/* {!currentUser?.attributes.profile.publicData?.isVerified && isJobPremium && ( */}
            {/*   <span className={css.serviceError}> */}
            {/*     <FormattedMessage id="NewOfferForm.expertNotVerified" /> */}
            {/*   </span> */}
            {/* )} */}

                  <Button
              className={css.submitButton}
              type="submit"
              disabled={
                currentUser?.attributes.profile.publicData?.isVerified ? false : isJobPremium ? true : false ||             
                disabled ||
                submitInProgress ||
                isPriceAboveJobBudget
              }
            >
              {sendOfferTooltip}
            </Button>
          </Form>
        );
      }}
    />
  );
};

NewOfferFormComponent.defaultProps = {
  className: null,
};

NewOfferFormComponent.propTypes = {
  className: string,
  onSubmit: func.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(NewOfferFormComponent);
// based on EditListingPricingForm
