/* eslint-disable */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import { BiPlus, BiMinus } from 'react-icons/bi';
import { HiArrowNarrowRight } from 'react-icons/hi';

import config from '../../config';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FieldTextInput, FieldCurrencyInput, FieldSuggestTextInput } from '../../components';
import { required, composeValidators, moneyValueAtLeast, moneyValuePrecise, nonNegativeNumber } from '../../util/validators';
import css from './MultilevelScopeInput.css';

import ButtonGroup from './ButtonGroup';

const createEmptyScope = () => {
  return [0,1,2].map((value) => { 
    return {
      id: value,
      subtasksSumPrice: 0,
      containsEmptySubtaskPrice: false,
      subtasks: []
    }
  })
}

const mapInitialValuesToScope = (initialValues) => {
  return initialValues.map((item, index) => {
    let itemSubtasks = item?.subtasks?.length > 0 ? item.subtasks.map((subtask, id) => {
      return {
        id: id
      };
    }) : [];
    return {
      id: index,
      subtasksSumPrice: 0,
      containsEmptySubtaskPrice: false,
      subtasks: itemSubtasks
    };
  });
}

const MultilevelScopeInput = props => {
  
  const { intl, form, values, disabled, currentUserHasHourlyRate, currentUserHourlyRate, isAdminOffer, jobScope, initialValues, scopeType, suggestValues, hidePriceInComponent } = props;
  const [scopeItems, setScopeItems] = useState(createEmptyScope());
  const [refreshMarker, setRefreshMarker] = useState(0);
  const initialValuesLoaded = useRef(false);
  const [scopeSuggestions, setScopeSuggestions] = useState([]);
  const [chosenSuggestionGroups, setChosenSuggestionGroups] = useState([]);
  // trigger refresh events for FieldCurrencyInputs
  const [currencyInputTaskWatcher, setCurrencyInputTaskWatcher] = useState(0);
  const [currencyInputSubtaskWatcher, setCurrencyInputSubtaskWatcher] = useState(0);

  useLayoutEffect(() => {
    if(!initialValuesLoaded.current && initialValues?.length > 0) {
      setScopeItems(mapInitialValuesToScope(initialValues));
      initialValuesLoaded.current = true;
      refresh('task');
    }
  }, [initialValues]);

  useEffect(() => {
    setScopeItems(currentScopeItems => {
      let newScopeItems = [...currentScopeItems];
      for(let i = 0; i < currentScopeItems.length; i++) {
        newScopeItems[i]['subtasksSumPrice'] = calculateSubtaskSumPrice(i, props.values);
        newScopeItems[i]['containsEmptySubtaskPrice'] = checkIfContainsEmptySubtaskPrice(i, props.values);
      }
      return newScopeItems;
    })
  }, [props.values, refreshMarker]);

  useEffect(() => {
    if(props.isJobScopeLoaded) {
      let newScopeItems = jobScope.map((jobItem, index) => {
        return {
          id: index,
          subtasksSumPrice: 0,
          containsEmptySubtaskPrice: false,
          subtasks: !!jobItem.subtasks ? jobItem.subtasks.map((jobSubtask, index) => {
            return {
              id: index
            }
          }) : []
        };
      });
      setScopeItems(newScopeItems);
      refresh('all');
    }
  },[props.isJobScopeLoaded, jobScope]);

  const [canRenderSelect, setCanRenderSelect] = useState(false);
  useEffect(() => {
    if (typeof window !== undefined) {
      setCanRenderSelect(true);
    }
  },[]);

  useEffect(() => {
    if(suggestValues) {
      setScopeSuggestions(calculateSuggestions(values, chosenSuggestionGroups));
    }
  }, [values, suggestValues, chosenSuggestionGroups]);

  const calculateSuggestions = (values, chosenSuggestionGroups) => {
    let scopeSuggestionsAll = [];
    if(chosenSuggestionGroups?.length > 0) {
      const suggestOptions = chosenSuggestionGroups.map((option) => {
        return option;
      });
      if(suggestOptions.includes("all")) {
        for (const [key, value] of Object.entries(config.custom.scopeSuggestions)) { //eslint-disable-line no-unused-vars
          for(let i = 0; i < value.length; i++) {
            if(!scopeSuggestionsAll.includes(value[i])) {
              scopeSuggestionsAll.push(value[i]);
            }
          }
        }
      } else {
        for(const opt of suggestOptions) { //eslint-disable-line no-unused-vars
          for(let i = 0; i < config.custom.scopeSuggestions[opt].length; i++) {
            if(!scopeSuggestionsAll.includes(config.custom.scopeSuggestions[opt][i])) {
              scopeSuggestionsAll.push(config.custom.scopeSuggestions[opt][i]);
            }
          }
        }
      }
    }
    // filter those that were already picked up
    const currentTasks = Object.entries(values).filter((entryArray) => {
      return (entryArray[0].includes("scopeTaskNameInput") || entryArray[0].includes("SubtaskNameInput"));
    }).map((entryArray) => {
      return entryArray[1]
    });
    scopeSuggestionsAll = scopeSuggestionsAll.filter((sugg) => {
      return !currentTasks.includes(sugg);
    });
    return scopeSuggestionsAll;
  }

  const getSuggestions = (value) => {
    const inputValue = value?.trim()?.toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? scopeSuggestions : scopeSuggestions.filter(suggestion =>
      suggestion.toLowerCase().includes(inputValue)
    );
  };

  const refresh = (type) => {
    setRefreshMarker(value => value+1);
    if(type === 'task') {
      setCurrencyInputTaskWatcher(value => value+1);
    } else if(type === 'subtask') {
      setCurrencyInputSubtaskWatcher(value => value+1);
    } if(type === 'all') {
      setCurrencyInputTaskWatcher(value => value+1);
      setCurrencyInputSubtaskWatcher(value => value+1);
    }
  }

  const calculateSubtaskSumPrice = (taskId, values) => {
    let subtaskPrices = Object.entries(values).filter((entryArray) => {
      return entryArray[0].includes("scopeTask"+taskId+"SubtaskPriceInput");
    });
    let subtaskSumPrice = !!subtaskPrices?.length > 0 ? subtaskPrices.reduce((acc, entry) => {
      return entry[1]?.amount > 0 ? acc + entry[1]?.amount : acc;
    }, 0) : 0;
    return subtaskSumPrice;
  }

  const checkIfContainsEmptySubtaskPrice = (taskId, values) => {
    let scopeSubtaskEntries = Object.entries(values).filter((entry) => {
      return entry[0].includes('scopeTask'+taskId+'Subtask');
    });
    let scopeSubtaskIds = scopeSubtaskEntries.filter((entry) => {
      return entry[0].includes('scopeTask'+taskId+'SubtaskNameInput') && entry[1] !== '';
    }).map((entry) => {
      return parseInt(entry[0].replace('scopeTask'+taskId+'SubtaskNameInput', ""));
    }).sort((a,b) => {
      return a - b; // integers sort
    });
    let containsEmpty = false;
    for(let i = 0; i < scopeSubtaskIds.length; i++) {
      let priceEntry = scopeSubtaskEntries.find((entry) => {
        return entry[0].includes('scopeTask'+taskId+'SubtaskPriceInput'+scopeSubtaskIds[i]);
      });
      if(!!priceEntry === false || !!priceEntry[1] === false) {
        containsEmpty = true;
      }
    }
    return containsEmpty;
  }

  const taskLabel = "Milestone name";
  const taskPlaceholder = "What will be done?";
  const taskTimeLabel = "Estimated time";
  const taskTimePlaceholder = "Time (hours)";
  const taskPriceLabel = "Estimated price";
  const taskPricePlaceholder = "Price ($)";

  const subtaskNamePlaceholder = "Task name";
  const subtaskTimePlaceholder = "Time (hours)";
  const subtaskPricePlaceholder = "Price ($)";

  const scopeTaskNameStyle = scopeType !== 'job' ? css.scopeTaskName : css.scopeTaskNameJob;
  const scopeSubtaskNameStyle = scopeType !== 'job' ? css.scopeSubtaskName : css.scopeSubtaskNameJob;
  const scopeTaskWrapperStyle = scopeType !== 'job' ? css.scopeTaskWrapper : css.scopeTaskWrapperJob;
  const scopeSubtaskWrapperStyle = scopeType !== 'job' ? css.scopeSubtaskWrapper : css.scopeSubtaskWrapperJob;

  const valueRequired = required(
    intl.formatMessage({
      id: 'CustomOfferScopeInput.valueRequired',
    })
  );

  const valueNotNegative = nonNegativeNumber(
    intl.formatMessage({
      id: 'CustomOfferScopeInput.valueNotNegative',
    })
  );

  const { Money } = sdkTypes;

  const changeValue = (taskId, subtaskId) => {
    values["scopeTask"+taskId+"SubtaskPriceInput" + subtaskId] 
      = new Money((values["scopeTask"+taskId+"SubtaskTimeInput" + subtaskId] * currentUserHourlyRate), config.currency);
    return null;
  }

  const reorderValuesSubtaskAdded = (taskId, subtaskId, lastSubtaskId) => {
    // move task values from subtaskId by one id up
    let newSubtaskIndex = subtaskId+1;
    for(let i = lastSubtaskId; i >= newSubtaskIndex; i--) {
      // override subtask with new values
      values["scopeTask"+taskId+"SubtaskNameInput"+(i+1)] = values["scopeTask"+taskId+"SubtaskNameInput"+(i)];
      values["scopeTask"+taskId+"SubtaskTimeInput"+(i+1)] = values["scopeTask"+taskId+"SubtaskTimeInput"+(i)];
      values["scopeTask"+taskId+"SubtaskPriceInput"+(i+1)] = values["scopeTask"+taskId+"SubtaskPriceInput"+(i)];
    }
    // clear task at position newTaskIndex
    values["scopeTask"+taskId+"SubtaskNameInput"+(newSubtaskIndex)] = "";
    values["scopeTask"+taskId+"SubtaskTimeInput"+(newSubtaskIndex)] = null;
    values["scopeTask"+taskId+"SubtaskPriceInput"+(newSubtaskIndex)] = null;
  }

  const addScopeSubtaskHandler = (taskId, subtaskId) => {
    // reorder values
    const subtasksLength = scopeItems[taskId].subtasks.length;
    const lastSubtaskId = subtasksLength > 0 ? scopeItems[taskId].subtasks[subtasksLength-1].id : -1;
    reorderValuesSubtaskAdded(taskId, subtaskId, lastSubtaskId);
    // insert new item in input-following data object
    scopeItems[taskId].subtasks.splice(subtaskId+1, 0, {
      id: subtaskId+1
    });
    // reorder ids for subtasks
    for(let i = 0; i < scopeItems[taskId].subtasks.length; i++) {
      scopeItems[taskId].subtasks[i].id = i;
    }
    setScopeItems([...scopeItems]);
    refresh('subtask');
  }

  const reorderValuesSubtaskRemoved = (taskId, subtaskId, lastSubtaskId) => {
    for(let i = subtaskId+1; i <= lastSubtaskId; i++) {
      // override subtask with new values
      values["scopeTask"+taskId+"SubtaskNameInput"+(i-1)] = values["scopeTask"+taskId+"SubtaskNameInput"+(i)];
      if(!!form?.mutators) {
        form.mutators.setValue(`scopeTask${(taskId)}SubtaskNameInput${(i-1)}`, values["scopeTask"+taskId+"SubtaskNameInput"+(i)]);
      }
      values["scopeTask"+taskId+"SubtaskTimeInput"+(i-1)] = values["scopeTask"+taskId+"SubtaskTimeInput"+(i)];
      values["scopeTask"+taskId+"SubtaskPriceInput"+(i-1)] = values["scopeTask"+taskId+"SubtaskPriceInput"+(i)];
    }
    // clear values from last transferred subtask
    values["scopeTask"+taskId+"SubtaskNameInput"+lastSubtaskId] = "";
    if(!!form?.mutators) {
      form.mutators.setValue(`scopeTask${(taskId)}SubtaskNameInput${(lastSubtaskId)}`, "");
    }
    values["scopeTask"+taskId+"SubtaskTimeInput"+lastSubtaskId] = null;
    values["scopeTask"+taskId+"SubtaskPriceInput"+lastSubtaskId] = null;
  }

  const removeScopeSubtaskHandler = (taskId, subtaskId) => {
    //const subtaskId = scopeItems[taskId].subtasks.length - 1;
    const lastSubtaskId = scopeItems[taskId].subtasks.length-1;
    reorderValuesSubtaskRemoved(taskId, subtaskId, lastSubtaskId);
    scopeItems[taskId].subtasks.splice(subtaskId, 1);
    for(let i = 0; i < scopeItems[taskId].subtasks.length; i++) {
      scopeItems[taskId].subtasks[i].id = i;
    }
    setScopeItems([...scopeItems]);
    refresh('subtask');
  }

  const renderSubtasks = (taskId, subtasks) => {
    return !!subtasks ? subtasks.map((item) => {
      const shouldDisplaySubtaskPlus = true;
      const shouldDisplaySubtaskMinus = true;
      return (
        generateSubtaskInput(taskId, item, addScopeSubtaskHandler, removeScopeSubtaskHandler, shouldDisplaySubtaskPlus, shouldDisplaySubtaskMinus)
      );
    }) : null;
  }

  let hidePrice = hidePriceInComponent === true ? css.HidePriceTask : ""

  const generateSubtaskInput = (taskId, {id, name, time, price}, addScopeSubtaskHandler, removeScopeSubtaskHandler, shouldDisplaySubtaskPlus, shouldDisplaySubtaskMinus) => {
    return (
      <div key={"scopeSubtaskInput"+id} className={scopeSubtaskWrapperStyle}>
        {/* SCOPE SUBTASK POINTER */}
        <div className={css.scopeSubtaskPointer}>
          <HiArrowNarrowRight />
        </div>
        {/* SCOPE SUBTASK NAME */}
        {suggestValues ?
          <FieldSuggestTextInput
            id={"scopeTask"+taskId+"SubtaskNameInput"+id}
            name={"scopeTask"+taskId+"SubtaskNameInput"+id}
            className={scopeSubtaskNameStyle}
            type="text"
            placeholder={taskPlaceholder}
            validate={values["scopeTask"+taskId+"SubtaskTimeInput"+id]?.length > 0 
              || values["scopeTask"+taskId+"SubtaskPriceInput"+id]?.amount >= 0 
              ? composeValidators(valueRequired) : null}
            disabled={disabled}
            getSuggestions={getSuggestions}
          />
        :
          <FieldTextInput
            id={"scopeTask"+taskId+"SubtaskNameInput"+id}
            name={"scopeTask"+taskId+"SubtaskNameInput"+id}
            className={scopeSubtaskNameStyle}
            type="text"
            placeholder={subtaskNamePlaceholder}
            /* validate={values["scopeTask"+taskId+"SubtaskTimeInput"+id]?.length > 0 
                      || values["scopeTask"+taskId+"SubtaskPriceInput"+id]?.amount >= 0 
                      ? composeValidators(valueRequired) : null} */
            disabled={disabled}
          />
        }
        {/* SCOPE SUBTASK TIME */}
        {scopeType !== 'job' && 
          <FieldTextInput
            id={"scopeTask"+taskId+"SubtaskTimeInput"+id}
            name={"scopeTask"+taskId+"SubtaskTimeInput"+id}
            className={css.scopeSubtaskTime}
            type="number"
            placeholder={subtaskTimePlaceholder}
            disabled={disabled}
            /*validate={valueNotNegative} */
          />
        }
        {/* SCOPE SUBTASK PRICE */}
        {scopeType !== 'job' && false ? 
          (currentUserHasHourlyRate && !!values["scopeTask"+taskId+"SubtaskTimeInput"+id] && values["scopeTask"+taskId+"SubtaskTimeInput"+id]>0) ?
          <div className={css.scopeSubtaskPrice}>
            <div className={css.scopeInputValue}>
              {formatMoney(intl, new Money((values["scopeTask"+taskId+"SubtaskTimeInput"+id]*currentUserHourlyRate),config.currency))}
              {changeValue(taskId, id)}
            </div>
          </div>
          :
          <FieldCurrencyInput
            id={"scopeTask"+taskId+"SubtaskPriceInput"+id}
            name={"scopeTask"+taskId+"SubtaskPriceInput"+id}
            className={[css.scopeSubtaskPrice].join(" ")}
            currencyConfig={config.currencyConfig}
            placeholder={subtaskPricePlaceholder}
            disabled={disabled}
            loadable
            loadedValue={values["scopeTask"+taskId+"SubtaskPriceInput"+id]}
            loadedWatcher={currencyInputSubtaskWatcher}
          />
        : null }
        {/* BUTTONS */}
        {(shouldDisplaySubtaskPlus || shouldDisplaySubtaskMinus) ?
          <div className={css.scopeSubtaskButtonsWrapper}>
            {shouldDisplaySubtaskPlus ? 
              <div className={classNames(css.scopeSubtaskButton, css.scopeTaskButtonMin)} onClick={() => addScopeSubtaskHandler(taskId, id)}>
                <BiPlus size={"1.1em"} />
              </div>
              :
              <div className={classNames(css.scopeSubtaskButton, css.scopeTaskButtonMin)}>
              </div>
            }
            {shouldDisplaySubtaskMinus ?
              <div className={classNames(css.scopeSubtaskButton, css.scopeTaskButtonMin)} onClick={() => removeScopeSubtaskHandler(taskId, id)}>
                <BiMinus size={"1.1em"} />
              </div>
            :
              <div className={classNames(css.scopeSubtaskButton, css.scopeTaskButtonMin)}>
              </div>
            }
          </div>
          :
          <div className={css.scopeSubtaskButtonsWrapper}>
          </div>
        }
      </div>
    );
  }

  const checkIfSubtaskNameExists = (taskId) => {
    let subtaskNames = Object.entries(values).filter((entryArray) => {
      return entryArray[0].includes("scopeTask"+taskId+"SubtaskNameInput");
    });
    return subtaskNames?.length > 0 ? true : false;
  }

  const generateScopeInput = ({id, name, time, subtasks}, addScopeTaskHandler, removeScopeTaskHandler, shouldDisplayTaskPlus, shouldDisplayTaskMinus, shouldDisplayAddSubtask) => {
    const sumPriceValue = scopeItems[id]['subtasksSumPrice'];
    const containsEmptySubtaskPrice = scopeItems[id]['containsEmptySubtaskPrice'];
    const estimatedTaskPriceMinValue = moneyValueAtLeast(
      intl.formatMessage(
        { id: 'NewOfferForm.minimumTaskPriceValue' },
        { min: sumPriceValue/100 }
      ),
      sumPriceValue
    );
    const estimatedTaskPricePreciseValue = moneyValuePrecise(
      intl.formatMessage(
        { id: 'NewOfferForm.preciseTaskPriceValue' },
        { min: sumPriceValue/100 }
      ),
      sumPriceValue
    );
    const estimatedPriceValidator = containsEmptySubtaskPrice ? composeValidators(estimatedTaskPriceMinValue) 
                                    : sumPriceValue > 0 ? composeValidators(valueRequired, estimatedTaskPricePreciseValue) : null;
    const estimatedPriceValidatorName = containsEmptySubtaskPrice ? "minVal" : sumPriceValue > 0 ? "preciseVal" : "requiredVal";

    let hidePrice = hidePriceInComponent === true ? css.HidePriceTask : ""

      return (
        <div key={"scopeTask"+id} className={css.scopeWrapper}>
          <div key={"scopeTaskInput"+id} className={scopeTaskWrapperStyle}>
              {/* SCOPE TASK NAME */}
              {suggestValues ? 
                <FieldSuggestTextInput
                  id={"scopeTaskNameInput"+id}
                  name={"scopeTaskNameInput"+id}
                  className={scopeTaskNameStyle}
                  type="text"
                  label={taskLabel}
                  placeholder={taskPlaceholder}
                  /* validate={values['scopeTaskTimeInput'+id]?.length > 0 || checkIfSubtaskNameExists(id) ? composeValidators(valueRequired) : null} */
                  disabled={disabled}
                  getSuggestions={getSuggestions}
                />
              :
                <FieldTextInput
                    id={"scopeTaskNameInput"+id}
                    name={"scopeTaskNameInput"+id}
                    className={scopeTaskNameStyle}
                    type="text"
                    label={taskLabel}
                    placeholder={taskPlaceholder}
/* validate={values['scopeTaskTimeInput'+id]?.length > 0 || checkIfSubtaskNameExists(id) ? composeValidators(valueRequired) : null} */
                    disabled={disabled}
                />
              } 
              {/* SCOPE TASK TIME */}
              {scopeType !== 'job' && 
                <FieldTextInput
                  id={"scopeTaskTimeInput"+id}
                  name={"scopeTaskTimeInput"+id}
                  className={css.scopeTaskTime}
                  type="number"
                  label={taskTimeLabel}
                  placeholder={taskTimePlaceholder}
                  /* validate={valueNotNegative} */
                  disabled={disabled}
                />
              }
              {/* SCOPE TASK PRICE */}
              {scopeType !== 'job' && 
                <FieldCurrencyInput
                  id={"scopeTaskPriceInput"+id}
                  name={"scopeTaskPriceInput"+id}
                  className={[css.scopeTaskPrice, hidePrice].join(" ")}
                  currencyConfig={config.currencyConfig}
                  label={taskPriceLabel}
                  placeholder={taskPricePlaceholder}
                  /* validate={isAdminOffer ? null : values['scopeTaskNameInput'+id]?.length > 0 ? estimatedPriceValidator : null} */
                  disabled={disabled}
                  loadable
                  loadedValue={values["scopeTaskPriceInput"+id]}
                  loadedWatcher={currencyInputTaskWatcher}
                  priceValidity={{sumPriceValue, containsEmptySubtaskPrice, estimatedPriceValidatorName}} // passed to trigger re-render
                />
              }
            {/* BUTTONS  */}
            {(shouldDisplayTaskPlus || shouldDisplayTaskMinus || shouldDisplayAddSubtask) ?
              <div className={css.scopeTaskButtonsWrapper}>
                <div className={css.scopeTaskButtonsFirstLine}>
                  <>
                    {shouldDisplayTaskPlus &&
                      <div className={classNames(css.scopeTaskButton, css.scopeTaskButtonMin)} onClick={() => { addScopeTaskHandler(id) }}>
                        <BiPlusCircle size={"1.3em"} />
                      </div>
                    }
                    {shouldDisplayTaskMinus ? 
                      <div className={classNames(css.scopeTaskButton, css.scopeTaskButtonMin)} onClick={() => { removeScopeTaskHandler(id) }}>
                        <BiMinusCircle size={"1.3em"} />
                      </div>
                    :
                      <div className={classNames(css.scopeTaskButton, css.scopeTaskButtonMin)}>
                      </div>
                    }
                  </>
                </div>
                {shouldDisplayAddSubtask &&
                  <div className={css.scopeTaskButtonsSecondLine} onClick={() => addScopeSubtaskHandler(id)}>
                    <p className={css.addSubtaskTextButton}>
                      <FormattedMessage id="CustomOfferScopeInput.AddSubtask" />
                    </p>
                  </div>
                }
              </div>
              :
              <div className={css.scopeTaskButton}></div>
            }
          </div>
          {renderSubtasks(id, subtasks)}
        </div>
    );
  }

  const renderScopes = (scopes, addScopeTaskHandler, removeScopeTaskHandler) => {
    return scopes.map((item) => {
      const shouldDisplayTaskPlus = true;
      const shouldDisplayTaskMinus = scopes.length > 1;
      const shouldDisplayAddSubtask = true;
      return (
        generateScopeInput(item, addScopeTaskHandler, removeScopeTaskHandler, shouldDisplayTaskPlus, shouldDisplayTaskMinus, shouldDisplayAddSubtask)
      );
    });
  }

  const reorderValuesTaskAdded = (taskId, lastTaskId, scopeItems) => {
    // move task values from taskId by one id up
    let newTaskIndex = taskId+1;
    for(let i = lastTaskId; i >= newTaskIndex; i--) {
      // clear subtasks
      if((i+1) <= lastTaskId) {
        let numberOfSubtasks = scopeItems[i+1].subtasks.length;
        for(let j = 0; j < numberOfSubtasks; j++) {
          values["scopeTask"+(i+1)+"SubtaskNameInput"+j] = '';
          values["scopeTask"+(i+1)+"SubtaskTimeInput"+j] = null;
          values["scopeTask"+(i+1)+"SubtaskPriceInput"+j] = null;
        }
      }
      // override task with new values
      values["scopeTaskNameInput" + (i+1)] = values["scopeTaskNameInput" + (i)];
      values["scopeTaskTimeInput" + (i+1)] = values["scopeTaskTimeInput" + (i)];
      values["scopeTaskPriceInput" + (i+1)] = values["scopeTaskPriceInput" + (i)];
      let numberOfSubtasks = scopeItems[i].subtasks.length;
      for(let k = 0; k < numberOfSubtasks; k++) {
        values["scopeTask"+(i+1)+"SubtaskNameInput"+k] = values["scopeTask"+(i)+"SubtaskNameInput"+k];
        values["scopeTask"+(i+1)+"SubtaskTimeInput"+k] = values["scopeTask"+(i)+"SubtaskTimeInput"+k];
        values["scopeTask"+(i+1)+"SubtaskPriceInput"+k] = values["scopeTask"+(i)+"SubtaskPriceInput"+k];
      }
    }
    // clear task at position newTaskIndex
    values["scopeTaskNameInput" + (newTaskIndex)] = '';
    values["scopeTaskTimeInput" + (newTaskIndex)] = null;
    values["scopeTaskPriceInput" + (newTaskIndex)] = null;
    for(let i = 0; i < scopeItems[newTaskIndex]?.subtasks?.length; i++) {
      values["scopeTask"+(newTaskIndex)+"SubtaskNameInput"+i] = '';
      values["scopeTask"+(newTaskIndex)+"SubtaskTimeInput"+i] = null;
      values["scopeTask"+(newTaskIndex)+"SubtaskPriceInput"+i] = null;
    }
  }

  const addScopeTaskHandler = (taskId) => {
    // reorder values
    const lastTaskId = scopeItems.length - 1;
    reorderValuesTaskAdded(taskId, lastTaskId, scopeItems);
    // insert new item in input-following data object
    scopeItems.splice(taskId+1, 0, {
      id: taskId+1,
      subtasksSumPrice: 0,
      containsEmptySubtaskPrice: false,
      subtasks: []
    });
    // reorder ids for scopeItems
    for(let i = 0; i < scopeItems.length; i++) {
      scopeItems[i].id = i;
    }
    setScopeItems([...scopeItems]);
    refresh('task');
  };

  const reorderValues = (taskId, lastTaskId, scopeItems) => {
    for(let i = taskId+1; i <= lastTaskId; i++) {
      let numberOfSubtasks = scopeItems[i-1].subtasks.length;
      // clear subtasks
      for(let j = 0; j < numberOfSubtasks; j++) {
        values["scopeTask"+(i-1)+"SubtaskNameInput"+j] = '';
        if(!!form?.mutators) {
          form.mutators.setValue(`scopeTask${(i-1)}SubtaskNameInput${j}`, '');
        }
        values["scopeTask"+(i-1)+"SubtaskTimeInput"+j] = null;
        values["scopeTask"+(i-1)+"SubtaskPriceInput"+j] = null;
      }
      // override task with new values
      values['scopeTaskNameInput' + (i-1)] = values['scopeTaskNameInput' + (i)];
      if(!!form?.mutators) {
        form.mutators.setValue(`scopeTaskNameInput${(i-1)}`, values[`scopeTaskNameInput${i}`]);
      }
      values['scopeTaskTimeInput' + (i-1)] = values['scopeTaskTimeInput' + (i)];
      values['scopeTaskPriceInput' + (i-1)] = values['scopeTaskPriceInput' + (i)];
      numberOfSubtasks = scopeItems[i].subtasks.length;
      for(let k = 0; k < numberOfSubtasks; k++) {
        values["scopeTask"+(i-1)+"SubtaskNameInput"+k] = values["scopeTask"+(i)+"SubtaskNameInput"+k];
        if(!!form?.mutators) {
          form.mutators.setValue(`scopeTask${(i-1)}SubtaskNameInput${k}`, values["scopeTask"+(i)+"SubtaskNameInput"+k]);
        }
        values["scopeTask"+(i-1)+"SubtaskTimeInput"+k] = values["scopeTask"+(i)+"SubtaskTimeInput"+k];
        values["scopeTask"+(i-1)+"SubtaskPriceInput"+k] = values["scopeTask"+(i)+"SubtaskPriceInput"+k];
      }
    }
    // clear values from last transferred task
    values['scopeTaskNameInput' + (lastTaskId)] = '';
    if(!!form?.mutators) {
      form.mutators.setValue(`scopeTaskNameInput${lastTaskId}`, '');
    }
    values['scopeTaskTimeInput' + (lastTaskId)] = null;
    values['scopeTaskPriceInput' + (lastTaskId)] = null;
    for(let i = 0; i < scopeItems[lastTaskId].subtasks.length; i++) {
      values["scopeTask"+(lastTaskId)+"SubtaskNameInput"+i] = '';
      if(!!form?.mutators) {
        form.mutators.setValue(`scopeTask${(lastTaskId)}SubtaskNameInput${i}`, '');
      }
      values["scopeTask"+(lastTaskId)+"SubtaskTimeInput"+i] = null;
      values["scopeTask"+(lastTaskId)+"SubtaskPriceInput"+i] = null;
    }
  }

  const removeScopeTaskHandler = (taskId) => {
    const lastTaskId = scopeItems.length - 1;
    reorderValues(taskId, lastTaskId, scopeItems);
    scopeItems.splice(taskId, 1);
    for(let i = 0; i < scopeItems.length; i++) {
      scopeItems[i].id = i;
    }
    setScopeItems([...scopeItems]);
    refresh('task');
  }

  const renderSuggestionsSelector = () => {
    return (
      <div className={css.jobScopeSuggestWrapper}>
        {canRenderSelect && 
          <>
            <label className={css.suggestionsLabel}>
              Customize suggestions for scoping tool below
            </label>
            <ButtonGroup 
              options={config.custom.scopeSuggestionGroups}
              values={chosenSuggestionGroups}
              setValues={setChosenSuggestionGroups}
            />
          </>
        }
      </div>
    )
  }

  return (
    <>
      { suggestValues && 
        <div>
          {renderSuggestionsSelector()}
        </div>
      }
      <div className={css.mainWrapper}>
        {renderScopes(scopeItems, addScopeTaskHandler, removeScopeTaskHandler)}
      </div>
    </>
  );
};

export default MultilevelScopeInput;
