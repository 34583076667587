import { createStripeCustomer, updateStripeCustomer } from '../../util/api';

// ================ Action types ================ //

export const CREATE_STRIPE_CUSTOMER_REQUEST = 'app/TransactionPage/CREATE_STRIPE_CUSTOMER_REQUEST';
export const CREATE_STRIPE_CUSTOMER_SUCCESS = 'app/TransactionPage/CREATE_STRIPE_CUSTOMER_SUCCESS';
export const CREATE_STRIPE_CUSTOMER_ERROR = 'app/TransactionPage/CREATE_STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {};

export default function payoutMethodsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_STRIPE_CUSTOMER_REQUEST:
      return { ...state, createStripeCustomerInProgress: true, createStripeCustomerError: null };
    case CREATE_STRIPE_CUSTOMER_SUCCESS:
      return { ...state, createStripeCustomerInProgress: false };
    case CREATE_STRIPE_CUSTOMER_ERROR:
      return {
        ...state,
        createStripeCustomerInProgress: false,
        createStripeCustomerError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const createStripeCustomerRequest = () => ({ type: CREATE_STRIPE_CUSTOMER_REQUEST });
const createStripeCustomerSuccess = () => ({ type: CREATE_STRIPE_CUSTOMER_SUCCESS });
const createStripeCustomerError = e => ({
  type: CREATE_STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const createNewStripeCustomer = (body, stripeAccount) => (dispatch, getState, sdk) => {
  dispatch(createStripeCustomerRequest());
  const { currency, ...rest } = body;
  return createStripeCustomer(rest)
    .then(response => {
      console.log(stripeAccount);
      if (Array.isArray(stripeAccount)) {
        stripeAccount = stripeAccount[0].id ? stripeAccount[0].id : stripeAccount[0];
      }

      console.log(response.id, stripeAccount);
      response.currency = currency;
      const updateBody = {
        privateData: {
          stripeAccount: stripeAccount ? [stripeAccount, response.id] : [response.id],
        },
      };
      return sdk.currentUser.updateProfile(updateBody, { expand: true }).then(() => {
        dispatch(createStripeCustomerSuccess());
        return response;
      });
    })
    .catch(error => {
      dispatch(createStripeCustomerError());
    });
};

export const updateExistingStripeCustomer = body => (dispatch, getState, sdk) => {
  dispatch(createStripeCustomerRequest());
  return updateStripeCustomer(body)
    .then(response => {
      dispatch(createStripeCustomerSuccess());
      return response;
    })
    .catch(error => {
      dispatch(createStripeCustomerError());
    });
};
