import React from 'react';
import css from './ButtonGroup.css';

import CustomButton from './CustomButton';

const ButtonGroup = props => {

    const {options, values, setValues} = props;

    const buttonOnClickHandler = (value) => {
        if(value === 'all' && !values.includes('all')) {
            setValues(['all']);
            return;
        }
        if(values.includes(value)) {
            const newValues = values.filter((v) => {
                return v !== value;
            });
            setValues([...newValues]);
        } else {
            setValues([
                ...values,
                value
            ]);
        }
    }

    const generateButtons = (options) => {
        return options.map((option) => {
            return (
                <CustomButton
                    key={option.label}
                    label={option.label}
                    value={option.value}
                    onClickHandler={buttonOnClickHandler}
                    active={values.includes(option.value)}
                />
            );
        })
    }

    return (
        <div className={css.mainWrapper}>
            {generateButtons(options)}    
        </div>
    );
}

export default ButtonGroup;