import React from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { IconSpinner } from '../../components';
import { types } from '../../util/sdkLoader';

import { formatMoney } from '../../util/currency';
import { Container, Row, Col, Tabs, Tab, Accordion } from 'react-bootstrap';
import dayjs from 'dayjs';
import InvoiceItem from './InvoiceItem';
import css from './BillingPage.css';
const { Money } = types;

const UserInvoicesPanel = props => {
  const { userInvoices, userPayouts, intl } = props;


  console.log(userInvoices)
  console.log()

  const invoicePanel = (
    <>
      {!userInvoices ? (
        <div>
          <div className={css.stripeAccountsContent}>
            <IconSpinner />
          </div>

          <p className={css.loadingInfo}>
            <FormattedMessage id="BillingPage.loadingInvoice" />
          </p>
        </div>
      ) : (
        <>
          {userInvoices.length === 0 ? (
            <p className={css.loadingInfo}>
              <FormattedMessage id="BillingPage.noInvoices" />
            </p>
          ) : (
          <>
          <div>
          <h3>Invoices</h3>
          </div>
          <ul className={css.invoiceList}>
            {userInvoices.map(invoice => {
              return (
                <li key={invoice.id} className={css.invoiceItem}>
                  <InvoiceItem invoice={invoice} />
                </li>
              );
            })}
          </ul>
          </>)}
        </>
      )}
    </>
  );

  const payoutsPanel = (
    <>
      {!userPayouts ? null  : (
        <>
          {userPayouts.length === 0 ? null 
           : (
          <>
          <div>
          <h3>Payouts</h3>
          </div>
          <ul className={css.invoiceList}>
             {userPayouts.map(payout => {
          const amount = formatMoney(intl, new Money(payout.amount * -1, payout.currency.toUpperCase()));
          return (
            <li className={css.invoiceItem}>
            <Container>
              <Row>
                <Col xs={6} md={8}>
                 Date: {dayjs.unix(payout.created).format('MMM D, YYYY')}
                </Col>
                <Col xs={6} md={4} className={css.payoutItem}>
                  Amount: {amount}
                </Col>
              </Row>
            </Container>
            </li>
          );
        })}
          </ul>
          </>)}
        </>
      )}
    </>
  );
  return <div className={css.stripeAccountsContent}>{invoicePanel}
  {payoutsPanel}
  </div>;
};

export default injectIntl(UserInvoicesPanel);
