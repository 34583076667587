import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import { Form, FieldTextInput, PrimaryButton, IconSpinner } from '../../components';
import { propTypes } from '../../util/types';
import Dropzone from 'react-dropzone';
import { IoMdRefresh, IoMdAttach, IoMdPaperPlane } from 'react-icons/io'
import { MdAddCircleOutline, MdDeleteForever } from 'react-icons/md';
import { BsUpload } from 'react-icons/bs';

import css from './SendMessageFileForm.css';

const BLUR_TIMEOUT_MS = 100;

class SendMessageFileFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.openFilesZone = this.openFilesZone.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.deleteAllFiles = this.deleteAllFiles.bind(this);
    this.addOtherFiles = this.addOtherFiles.bind(this);
    this.blurTimeoutId = null;
    this.dropzoneRef = React.createRef();
    this.state = {
      files: [],
      addFilesZone: false,
      filesErrorMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.refreshFormAttachments !== this.props.refreshFormAttachments) {
      this.deleteAllFiles();
    }
  }

  openFilesZone() {
    // when there are some files only opening is enabled
    // when no files attached both opening and closing is enabled
    if(this.state.files?.length > 0) {
      this.setState({addFilesZone: true});
    } else {
      this.setState(prevState => { return {addFilesZone: !prevState.addFilesZone} })
    }
  }

  onDrop(newFiles) {
    this.setState(prevState => {
      return {
        files: removeDuplicates([
          ...prevState.files,
          ...newFiles
        ])
      }
    });
  }

  addOtherFiles() {
    this.dropzoneRef.current.open();
  }

  deleteAllFiles() {
    this.setState({filesErrorMessage: ''});
    this.setState({files: []});
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
            values,
            formId,
            shouldDisplayRefresh,
            refreshInProgress,
            onRefresh,
          } = formRenderProps;

          const files = this.state.files.map(file => (
            <li key={file.name}>
              {file.name} - {formatFileSize(file.size)}
            </li>
          ));

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;

          const validateFiles = () => {
            let isValid = true;
            if(this.state.files?.length > 0) {
              if(this.state.files?.length > 100) {
                this.setState({filesErrorMessage: 'Maximum number of attachments is 100.'});
                isValid = false;
              } else {
                let filesSize = this.state.files.reduce((acc, v) => {
                  return acc + v.size;
                }, 0);
                let maxFilesSize = 25 * 1000 * 1000;
                if(filesSize > maxFilesSize) {
                  this.setState({filesErrorMessage: 'Maximum size of all attachments is 25MB.'});
                  isValid = false;
                }
              }
            }
            if(isValid) {
              this.setState({filesErrorMessage: ''});
            }
            return isValid;
          }

          const submitDisabled = invalid || submitInProgress || (!values.message && this.state.files?.length === 0);

          return (
            <Form className={classes} onSubmit={(event) => {
                if(validateFiles()) {
                  formRenderProps.values['files'] = this.state.files;              
                  handleSubmit(event, form);
                } else {
                  event.preventDefault();
                }
              }}>
              <FieldTextInput
                inputRootClass={css.textarea}
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                placeholder={messagePlaceholder}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onKeyDown={event => {
                    if (event.keyCode === 13 && event.shiftKey === false) {
                      event.stopPropagation();
                      if(validateFiles()) {
                        formRenderProps.values['files'] = this.state.files;              
                        handleSubmit(event, form);
                      } else {
                        event.preventDefault();
                      }
                    }
                  } 
                }
              />
              {!!this.state.addFilesZone && 
                <div className={css.filesZoneWrapper} 
                  id={'files'}
                  name={'files'}
                  value={this.state.files}
                >
                  <Dropzone ref={this.dropzoneRef} onDrop={this.onDrop}>
                    {({getRootProps, getInputProps}) => (
                      <section className={css.filesZoneContainer}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className={css.filesDragDropTitle}>
                            <BsUpload className={css.actionIcon}/>
                            <span>Add attachments by <u>clicking here</u> or drag and drop files. </span>
                          </div>
                        </div>
                        {(files?.length > 0) &&
                          <aside>
                            <div className={css.filesTitle}>
                              Attachments
                              <Tooltip interactive title={<FormattedMessage id={`SendMessageFileForm.tooltipAddOtherFiles`} />}>
                                <div className={css.inlineIconWrapper}>
                                  <MdAddCircleOutline onClick={this.addOtherFiles} />
                                </div>
                              </Tooltip>
                              <Tooltip interactive title={<FormattedMessage id={`SendMessageFileForm.tooltipDeleteAllFiles`} />}>
                                <div className={css.inlineIconWrapper}>
                                  <MdDeleteForever onClick={this.deleteAllFiles} />
                                </div>
                              </Tooltip>
                            </div>
                            <ul className={css.filesList}>{files}</ul>
                          </aside>
                        }
                        {(this.state.filesErrorMessage?.length > 0) && 
                          <div className={css.filesErrorMessage}>
                            {this.state.filesErrorMessage}
                          </div>
                        }
                      </section>
                    )}
                  </Dropzone>
                </div>
              }
              <div id={'submitContainer'} className={css.submitContainer}>
                <div className={css.errorContainer}>
                  {sendMessageError ? (
                    <p className={css.error}>
                      <FormattedMessage id="SendMessageForm.sendFailed" />
                    </p>
                  ) : null}
                </div>
                
                {(shouldDisplayRefresh && !refreshInProgress) ?
                  <Tooltip interactive title={<FormattedMessage id={`SendMessageFileForm.tooltipRefresh`} />}>
                    <div className={css.sideIcon}>
                      <IoMdRefresh className={css.actionIcon} onClick={onRefresh} />
                    </div>
                  </Tooltip>
                : (shouldDisplayRefresh && refreshInProgress) ?
                  <div className={css.sideIcon}>
                    <IconSpinner className={css.refreshSpinner} />
                  </div>
                : null
                }
                <Tooltip interactive title={<FormattedMessage id={`SendMessageFileForm.tooltipOpenFilesZone`} />}>
                  <div className={css.sideIcon}>
                    <IoMdAttach className={css.actionIcon} onClick={this.openFilesZone} />
                  </div>
                </Tooltip>
                <PrimaryButton
                  rootClassName={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                >
                  <IoMdPaperPlane className={css.actionIcon}/>
                  <FormattedMessage id="SendMessageForm.sendMessage" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SendMessageFileFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFileFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SendMessageFileForm = compose(injectIntl)(SendMessageFileFormComponent);

SendMessageFileForm.displayName = 'SendMessageFileForm';

export default SendMessageFileForm;

const formatFileSize = (number) => {
  const sizeInMB = truncateDecimals(parseBytes(number), 2);
  const sizeInKB = truncateDecimals(parseBytes(number, 'KB'), 2);
  return (sizeInMB >= 0.1) ? (sizeInMB + ' MB') : (sizeInKB + ' KB');
}

const parseBytes = (number, mode = 'MB') => {
  if(mode === 'MB') {
    return (number/1000/1000);
  } else if(mode === 'KB') {
    return (number/1000);    
  } else {
    return number;
  }
}

// helpers
const truncateDecimals = (number, digits) => {
  var multiplier = Math.pow(10, digits),
      adjustedNum = number * multiplier,
      truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
};

const removeDuplicates = (array) => {
  return array.filter((v,i,a) => {
      return i === a.findIndex((t) => ((t.name === v.name) && (t.size === v.size)));
  });
}